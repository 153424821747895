import React, { useState, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { LoginPage } from "./pages/login/LoginPage";
import { Payments } from "./Payments";
import { Auth } from "./pages/auth";
import PageLoading from "./components/PageLoading";
import { firestore, auth } from "./services/firebase";
import "./App.scss";
import { TrackPage, rudderAnalytics } from "./services/trackers";
import moment from "moment";

export function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    auth().onAuthStateChanged(async (user) => {
      if (user && user.uid) {
        //let querySnapshot = await firestore('users').where('email', '==', user.email).limit(1).get();
        //if (!(querySnapshot.docs && querySnapshot.docs.length)) {
        //    querySnapshot = await firestore('users').where('uid', '==', user.uid).limit(1).get();
        //}
        console.log(user.uid);
        let querySnapshot = await firestore("users")
          .where("uid", "==", user.uid)
          .limit(1)
          .get();
        let userDetails = {};

        if (querySnapshot && querySnapshot.docs && querySnapshot.docs.length) {
          const user = querySnapshot.docs[0];
          userDetails = user.data();
          userDetails.id = user.id;
        }
        console.log(userDetails);
        if (userDetails) {
          localStorage.setItem("adminDetails", JSON.stringify(userDetails));

          if (userDetails.role && userDetails.role.includes("nutritionist")) {
            var _userDetails = JSON.parse(localStorage.getItem("userDetails"));
            if (!_userDetails || !_userDetails.name) {
              localStorage.setItem("userDetails", JSON.stringify(userDetails));
            }
          }
        }
        const traitData = {};
        if (userDetails.name) {
          traitData.name = userDetails.name;
        }
        if (userDetails.birthday) {
          traitData.birthday = userDetails.birthday;
          traitData.age = moment().diff(userDetails.birthday, "years");
        }
        if (userDetails.gender) {
          traitData.gender = userDetails.gender;
        }
        if (userDetails.email) {
          traitData.email = userDetails.email;
        }
        if (userDetails.country) {
          traitData.country = userDetails.country;
        }
        if (userDetails.targetWeight) {
          traitData.targetWeight = Number(userDetails.targetWeight);
        }

        if (userDetails.currentWeight) {
          traitData.currentWeight = Number(userDetails.weight);
        }
        if (userDetails.goal) {
          traitData.goal = userDetails.goal;
        }
        if (userDetails.lastOpened) {
          traitData.appLastOpened = userDetails.lastOpened;
        }
        if (userDetails.healthInfo && userDetails.healthInfo.exerciseType) {
          traitData.exerciseLocation =
            userDetails.healthInfo && userDetails.healthInfo.exerciseType;
        }

        if (userDetails.healthInfo && userDetails.healthInfo.dietType) {
          traitData.dietType =
            userDetails.healthInfo && userDetails.healthInfo.dietType;
        }
        rudderAnalytics.identify(user.uid, traitData);
      }
      setIsSignedIn(!!user);
      setIsLoading(false);
    });
    return () => {};
  }, []);
  return (
    <div className="d-flex" id="wrapper">
      {isLoading ? (
        <PageLoading />
      ) : isSignedIn ? (
        <Auth />
      ) : (
        <Router>
          <TrackPage />
          <Switch>
            <Route exact path="/payments" component={Payments} />
            <Route exact path="/login" component={LoginPage} />
            <Redirect to="/login" />
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default App;
