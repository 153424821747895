/** @format */

import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";

import {
  Spinner,
  Form,
  Col,
  Row,
  Button,
  Card,
  Table,
  Accordion,
} from "react-bootstrap";
import { firestore } from "../../services/firebase";
import moment from "moment";
import * as _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faUsers,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";

import User from "../../assets/icons/user.svg";
import Group from "../../assets/icons/group.svg";
import Kgs from "../../assets/icons/kgs-lost.svg";
import Likes from "../../assets/icons/likes.svg";
import Chat from "../../assets/icons/Chat.svg";
import CupWater from "../../assets/icons/cup-water.svg";
import Meals from "../../assets/icons/meals.svg";
import Exercise from "../../assets/icons/exercise.svg";
import Question from "../../assets/icons/alert-que.svg";
import First from "../../assets/icons/first.svg";
import Second from "../../assets/icons/second.svg";
import Third from "../../assets/icons/third.svg";
import "./Stats.css";
import { Header } from "../../components/Header";
import MapChart from "./users/MapChart";
import * as countriesList from "./users/countries.json";
import MealPlansChart from "./users/MealPlansChart";
import WorkoutPlansChart from "./users/WorkoutPlansChart";
import firebase from "firebase/compat/app";

const countriesObj = {};
countriesList["default"].map((item) => {
  countriesObj[item.abbreviation] = item.country;
});
export function Stats(props) {
  const [selectedUser, setSelectedUser] = useState();
  const [loader, setLoader] = useState(true);
  const [days, setDays] = useState();
  const [daysUser, setDaysUser] = useState();
  const [board, setBoard] = useState([]);
  const [stats, setStats] = useState({});
  const [challenge, setChallenge] = useState();
  const [challenges, setChallenges] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState("");
  const [challengesLoading, setChallengesLoading] = useState(false);
  const [ignore, setIgnore] = useState({});
  const [rounds, setRounds] = useState(false);
  const [orderPoints, setOrderPoints] = useState(false);
  const [orderActivity, setOrderActivity] = useState(false);
  const [showNotActive, setShowNotActive] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [usersLoader, setUsersLoader] = useState(true);
  const [countries, setCountries] = useState({});
  const [sortBy, setSortBy] = useState("points");

  const [showAllUsers, setShowAllUsers] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [loaderAll, setLoaderAll] = useState(true);
  const [challengeAll, setChallengeAll] = useState();
  const [retention, setRetention] = useState();
  const [avgRetention, setAvgRetention] = useState();
  const [userPhone, setUserPhone] = useState();
  const [formatMonth, setFormatMonth] = useState("MMM YY");
  const [challengeUsers, setChallengeUsers] = useState({});
  // const [notActive, setNotActive] = useState([]);
  const [userRounds, setUserRounds] = useState();
  const [demographics, setDemographics] = useState();
  const [completionRate, setCompletionRate] = useState();
  const [userInfoWeight, SetUserInfoWeight] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: moment()
      .subtract(1, "years")
      .subtract(1, "months")
      .startOf("month"),
    endDate: moment().subtract(1, "months").endOf("month"),
    key: "selection",
  });
  const [mealplansLoader, setMealplansLoader] = useState(true);
  const [mealPlans, setMealPlans] = useState([]);
  const [mealPlansUsers, setMealPlansUsers] = useState({});
  const [workoutPlans, setWorkoutPlans] = useState([]);
  const [workoutsLoader, setWorkoutsLoader] = useState(true);
  const [workoutUsers, setWorkoutUsers] = useState({});

  useEffect(() => {
    getChallenges();
    getMealPlans();
    getWorkoutPlans();
  }, []);

  useEffect(() => {
    if (challenge && challenge.key && challenge.roundsIds) {
      setRounds(challenge.roundsIds);
    }
  }, [challenge]);

  useEffect(() => {
    if (!selectedUser || !selectedUser.id) return;
    setUserRounds();
    SetUserInfoWeight();
    setUserPhone();
    firestore("users")
      .doc(selectedUser.userId)
      .get()
      .then((doc) => {
        var phone;
        if (doc.data().mobileNumber)
          phone = "+" + doc.data().dielCode + " " + doc.data().mobileNumber;
        if (doc.data().phone)
          phone =
            "+" + doc.data().phone.callingCode + " " + doc.data().phone.number;

        if (phone) setUserPhone(phone);

        getWeightUpdates(selectedUser.userId);

        var rounds = 0;
        if (challenge && challenge.previousRounds)
          challenge.previousRounds.map((id) => {
            if (
              doc.data().joinedChallengeIds &&
              doc.data().joinedChallengeIds.includes(id)
            )
              rounds++;
          });

        setUserRounds(rounds);
      });
    loadActivityUser();

    return () => {};
  }, [selectedUser]);

  useEffect(() => {
    var unsubscribes = "";
    async function fetch() {
      if (!selectedChallenge) return;
      setLoader(true);
      await getchallengeUsers(selectedChallenge);
      unsubscribes = getCollections();
    }
    fetch();
    return () => {
      if (unsubscribes) {
        unsubscribes.map((unsubscribe) => unsubscribe());
      }
    };
  }, [selectedChallenge]);

  useEffect(() => {
    var notActive = [];
    var boardObj = {};
    board.map((d) => {
      boardObj[d.userId] = d;
    });
    for (var key in challengeUsers) {
      if (!boardObj[key]) {
        const user = challengeUsers[key];

        challengeUsers[key] = {
          ...challengeUsers[key],
          points: 0,
          userId: key,
          userName: user.name,
          userImage: user.image,
        };

        // var phone = "";
        // if (user.mobileNumber)
        //   phone = "+" + user.dielCode + " " + user.mobileNumber;
        // if (user.phone)
        //   phone = "+" + user.phone.callingCode + " " + user.phone.number;

        // notActive.push({
        //   userId: key,
        //   userName: user.name,
        //   userImage: user.image,
        //   phone: phone,
        // });
      } else {
        challengeUsers[key] = { ...challengeUsers[key], ...boardObj[key] };
      }
    }
    // setNotActive(notActive);

    return () => {};
  }, [board, challengeUsers]);

  useEffect(() => {
    if (!showAll) return;

    //setLoader(true)
    var unsubscribesAll = getCollectionsAll();

    getPayments();

    return () => {
      console.log("Do some cleanup");
      unsubscribesAll.map((unsubscribe) => unsubscribe());
    };
  }, [showAll]);

  function getWeightUpdates(userId) {
    firestore("users")
      .doc(userId)
      .collection("weight")
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((documentSnapshot, i) => {
          return {
            ...documentSnapshot.data(),
          };
        });

        var startDate = moment(challenge.date);
        var endDate = moment(startDate).add(
          challenge.duration,
          challenge.durationType.toLowerCase()
        );

        data = data.filter(
          (d) =>
            moment(d.dateTime) >= startDate && moment(d.dateTime) <= endDate
        );
        data = _.orderBy(
          _.clone(data),
          (item) => moment(item.dateTime).format(),
          "asc"
        );
        data = data.map((d) => {
          d.day = moment(d.dateTime).diff(startDate, "days");
          return d;
        });

        SetUserInfoWeight(data);
      });
  }

  function sortByName(a, b) {
    if (
      (a.name || "").toLowerCase().trim() < (b.name || "").toLowerCase().trim()
    )
      return -1;
    if (
      (a.name || "").toLowerCase().trim() > (b.name || "").toLowerCase().trim()
    )
      return 1;
    return 0;
  }

  var calculateAge = function (birthday) {
    // birthday is a date
    var ageDifMs = Date.now() - new Date(birthday).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  var calculateBmr = function (user) {
    var bmr;

    if (user.gender === "male")
      bmr = 66 + 13.7 * user.weight + 5 * user.height - 6.8 * user.age;
    if (user.gender === "female")
      bmr = 655 + 9.6 * user.weight + 1.8 * user.height - 4.7 * user.age;

    /*if (user.healthInfo && user.healthInfo.selectedFitness) {

            if (user.healthInfo.selectedFitness.includes('Beginner ')) bmr *= 1
            if (user.healthInfo.selectedFitness.includes('Intermediate ')) bmr *= 1.2
            if (user.healthInfo.selectedFitness.includes('Professional ')) bmr *= 1.375
        }*/

    bmr += 200;

    return bmr || 0;
  };

  async function getchallengeUsers(challengeId) {
    setWorkoutsLoader(true);
    setMealplansLoader(true);
    setUsersLoader(true);
    await firestore("users")
      .where("joinedChallengeIds", "array-contains", challengeId)
      .get()
      .then(async (snapshot) => {
        var challengeUsers = {};
        var mealPlansUsers = {},
          unassigned = 0;
        var workoutUsers = {},
          unassignedW = 0;
        var demographics = {
          male: 0,
          female: 0,
          bmr: 0,
          weight: 0,
          age: 0,
        };
        var countries = {};
        const chngData = challenges.find((v) => {
          return v.key === selectedChallenge;
        });
        await Promise.all(
          snapshot.docs.map(async (d) => {
            var userData = d.data();
            const userChallenge = await firestore("user_challenges")
              .where("userId", "==", d.data().id)
              .where("challengeId", "==", challengeId)
              .orderBy("created", "desc")
              .limit(1)
              .get();
            challengeUsers[d.data().id] = {
              ...d.data(),
              lastActive:
                userChallenge.docs[0]?.data()?.created ||
                firebase.firestore.Timestamp.fromDate(
                  moment(chngData.date).toDate()
                ),
              ...(d.data().phone && {
                phoneNumber:
                  d.data().phone.formattedNumber ||
                  `+${
                    d.data().phone.callingCode ? d.data().phone.callingCode : ""
                  }${d.data().phone.number ? d.data().phone.number : ""}`,
              }),
            };
            if (userData.gender === "male") demographics.male++;
            if (userData.gender === "female") demographics.female++;
            userData.age = calculateAge(userData.birthday);
            userData.bmr = +calculateBmr(userData).toFixed(2);
            demographics.age += parseFloat(userData.age || 0);
            demographics.bmr += parseFloat(userData.bmr || 0);
            demographics.weight += parseFloat(
              (!isNaN(userData.weight) && userData.weight) || 0
            );
            var countryName = countriesObj[userData.country];
            if (!countries[countryName]) {
              countries[countryName] = {
                count: 0,
                abbreviation: userData.country,
              };
            }
            countries[countryName].count++;
            const challengeDoc = await d.ref
              .collection("selectedChallenges")
              .doc(selectedChallenge)
              .get();
            const challengeData = challengeDoc.data();
            if (challengeData) {
              userData.mealPlanId = challengeData.mealPlanId;
              userData.workoutPlanId = challengeData.workoutPlanId;
            }
            if (!mealPlansUsers[userData.mealPlanId])
              mealPlansUsers[userData.mealPlanId] = 0;
            mealPlansUsers[userData.mealPlanId]++;
            if (!userData.mealPlanId) unassigned++;
            if (!workoutUsers[userData.workoutPlanId])
              workoutUsers[userData.workoutPlanId] = 0;
            workoutUsers[userData.workoutPlanId]++;
            if (!userData.workoutPlanId) unassignedW++;
          })
        );
        demographics.age /= snapshot.size;
        demographics.bmr /= snapshot.size;
        demographics.weight /= snapshot.size;
        mealPlansUsers["unassigned"] = unassigned;
        setMealPlansUsers(mealPlansUsers);
        workoutUsers["unassigned"] = unassignedW;
        setWorkoutUsers(workoutUsers);
        setDemographics(demographics);
        setUsersLoader(false);
        setCountries(countries);
        setChallengeUsers(challengeUsers);
        setWorkoutsLoader(false);
        setMealplansLoader(false);
      });
  }

  function getMealPlans() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    return (
      firestore("meal_plans_essentials")
        //.where('nutritionistId', '==', userDetails.id)
        .where(
          "nutritionistId",
          "in",
          [userDetails.id].concat(userDetails.collaborators || [])
        )
        .get()
        .then((querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });
          setMealPlans(data.sort(sortByName).filter((d) => d.name));
        })
    );
  }

  function getWorkoutPlans() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    return (
      firestore("workout_plans_essentials")
        //.where('nutritionistId', '==', userDetails.id)
        .where(
          "nutritionistId",
          "in",
          [userDetails.id].concat(userDetails.collaborators || [])
        )
        .get()
        .then((querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });
          setWorkoutPlans(data.sort(sortByName).filter((d) => d.name));
        })
    );
  }

  function getChallenges() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (!userDetails || !userDetails.id) {
      setChallenges([]);
      setChallengesLoading(false);
      return;
    }

    setChallengesLoading(false); //true
    return firestore("challenges")
      .where("nutritionistId", "==", userDetails.id)
      .get()
      .then(
        (querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });
          //_.orderBy(groups, 'userCount', 'desc')
          //data = _.orderBy(data, 'price', 'desc')

          data = _.orderBy(
            data,
            (item) => moment.utc(item.date).format(),
            "desc"
          );

          var grouped = [],
            ignore = {},
            firstRound = {};
          data.map((d) => {
            if (d.previousRounds) {
              d.rounds = d.previousRounds.length + 1;
              d.previousRounds.map((id, i) => {
                ignore[id] = true;

                if (i === d.previousRounds.length - 1) firstRound[id] = true;
              });
              d.roundsIds = [d.key].concat(d.previousRounds);
            }

            if (firstRound[d.key]) {
              d.rounds = 1;
              d.roundsIds = [d.key];
            }
          });

          setIgnore(ignore);
          setChallenges(data);
          setChallengesLoading(false);

          var storageChallenge = localStorage.getItem("challengeId");
          if (
            storageChallenge &&
            data.filter((c) => c.key === storageChallenge).length
          ) {
            var d = data.filter((c) => c.key === storageChallenge)[0];
            var rounds = data.filter(
              (c) => c.roundsIds && c.roundsIds.includes(d.key)
            );
            setRounds(rounds.length ? rounds[0].roundsIds : false);
            setSelectedChallenge(d.key);
          } else if (data.length) {
            setRounds(data[0].roundsIds);
            setSelectedChallenge(data[0].key);
            localStorage.setItem("challengeId", data[0].key);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  function getCollections() {
    var ref = "";
    const subscribeChallenge = selectedChallenge
      ? firestore("challenges")
          .doc(selectedChallenge)
          .onSnapshot({ includeMetadataChanges: true }, function (snapshot) {
            //.get().then(function(snapshot){

            setChallenge(snapshot.data());
          })
      : () => {};

    const subscribePoints = firestore("user_points")
      .where("challengeId", "==", selectedChallenge)
      .orderBy("points", "desc")
      //.limit(10)
      .onSnapshot({ includeMetadataChanges: true }, function (snapshot) {
        //.get().then(function(snapshot){

        var board = snapshot.docs.length
          ? snapshot.docs.map((d) => {
              var obj = d.data();
              obj.id = d.id;
              return obj;
            })
          : [];

        if (board.length) {
          /*firestore("users_badges")

                    .where('userId', 'in', board.slice(0,10).map((d)=>d.userId))
                    .orderBy('position', 'desc')
                    //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
                    .get().then(function(snapshot){

                    var userBadges = {}
                    snapshot.docs.map((d)=>{

                        const badge = d.data();

                        if(userBadges[badge.userId]){

                            var fields = userBadges[badge.userId].map((badge)=>badge.field)

                            if(!fields.includes(badge.field)){

                                userBadges[badge.userId].push(_.clone(badge))
                            }
                        } 
                        else{
                            userBadges[badge.userId] = [_.clone(badge)]
                        }
                    })

                    board.map((user)=>{ user.badges = userBadges[user.userId] || [] })

                    setBoard(board);

                    setLoader(false);

                    loadActivity();
                    loadStats(board.map((d)=>d.userId));
                })*/

          setBoard(board);

          setLoader(false);

          loadActivity();

          loadStats(board.map((d) => d.userId));
        } else {
          setBoard([]);

          setLoader(false);

          loadActivity();
        }
      });

    var loadActivity = function () {
      const subscribeFeeds = firestore("user_challenges")
        .where("challengeId", "==", selectedChallenge)
        //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
        .get()
        .then(function (snapshot) {
          const user_challenges = snapshot.docs.length
            ? snapshot.docs.map((d) => {
                var obj = d.data();
                obj.id = d.id;
                return obj;
              })
            : [];

          var pointsByDay = {};

          user_challenges.map((d) => {
            const day = parseFloat(d.dietDay);

            if (day <= 0) return;

            if (!pointsByDay[day])
              pointsByDay[day] = {
                day: day,
                user: {},
                users: 0,
                meals: 0,
                workout: 0,
                water: 0,
                waterDone: 0,
                recipes: 0,
                recipeViewed: 0,
              };
            pointsByDay[day].user[d.userId] = true;
            pointsByDay[day].water += d.isDrinkWaterCompleted ? 1 : 0;
            pointsByDay[day].workout += d.isWorkoutCompleted ? 1 : 0;
            pointsByDay[day].waterDone += d.glassOfWaterCount;
            pointsByDay[day].recipes +=
              d.recipeViewed && d.recipeViewed.length ? 1 : 0;
            pointsByDay[day].recipeViewed += d.recipeViewed
              ? d.recipeViewed.length
              : 0;

            if (d.Breakfast) pointsByDay[day].meals++;
            if (d.Dinner) pointsByDay[day].meals++;
            if (d.Lunch) pointsByDay[day].meals++;
            if (d.Snack) pointsByDay[day].meals++;
            if (d.Snack2) pointsByDay[day].meals++;
            if (d.Sohour) pointsByDay[day].meals++;
            if (d.Iftar) pointsByDay[day].meals++;
          });

          for (var day in pointsByDay) {
            pointsByDay[day].users = Object.keys(pointsByDay[day].user).length;
          }

          const completionRate = 0;
          var days = _.orderBy(Object.values(pointsByDay), "day", "asc").map(
            (d) => {
              d.day = "day " + d.day;
              return d;
            }
          );
          if (days[days.length - 1])
            setCompletionRate(
              (days[days.length - 1].users / days[0].users) * 100
            );

          setDays(days);
        });
    };

    var loadStats = function (userIds) {
      const subscribeFeeds = firestore("user_statistics")
        .where("challengeId", "==", selectedChallenge)
        //.where('userId', 'in', userIds)
        //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
        .get()
        .then(function (snapshot) {
          const user_statistics = snapshot.docs.length
            ? snapshot.docs.map((d) => {
                var obj = d.data();
                obj.id = d.id;
                return obj;
              })
            : [];

          var stats = {};

          user_statistics.map((d) => {
            stats[d.userId] = d;
          });

          setStats(stats);
        });
    };

    return [subscribeChallenge, subscribePoints /*, subscribeFeeds*/];
  }

  var loadActivityUser = function () {
    const subscribeFeeds = firestore("user_challenges")
      .where("challengeId", "==", selectedChallenge)
      .where("userId", "==", selectedUser.userId)
      //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
      .get()
      .then(function (snapshot) {
        const user_challenges = snapshot.docs.length
          ? snapshot.docs.map((d) => {
              var obj = d.data();
              obj.id = d.id;
              return obj;
            })
          : [];

        var pointsByDay = {};

        user_challenges.map((d) => {
          const day = parseFloat(d.dietDay);

          if (day <= 0) return;

          if (!pointsByDay[day])
            pointsByDay[day] = {
              day: day,
              user: {},
              users: 0,
              meals: 0,
              workout: 0,
              water: 0,
              waterDone: 0,
              recipes: 0,
              recipeViewed: 0,
            };
          pointsByDay[day].user[d.userId] = true;
          pointsByDay[day].water += d.isDrinkWaterCompleted ? 1 : 0;
          pointsByDay[day].workout += d.isWorkoutCompleted ? 1 : 0;
          pointsByDay[day].waterDone += d.glassOfWaterCount;
          pointsByDay[day].recipes +=
            d.recipeViewed && d.recipeViewed.length ? 1 : 0;
          pointsByDay[day].recipeViewed += d.recipeViewed
            ? d.recipeViewed.length
            : 0;

          if (d.Breakfast) pointsByDay[day].meals++;
          if (d.Dinner) pointsByDay[day].meals++;
          if (d.Lunch) pointsByDay[day].meals++;
          if (d.Snack) pointsByDay[day].meals++;
          if (d.Snack2) pointsByDay[day].meals++;
          if (d.Sohour) pointsByDay[day].meals++;
          if (d.Iftar) pointsByDay[day].meals++;
        });

        for (var day in pointsByDay) {
          pointsByDay[day].users = Object.keys(pointsByDay[day].user).length;
        }

        var days = _.orderBy(Object.values(pointsByDay), "day", "asc").map(
          (d) => {
            d.day = "day " + d.day;
            return d;
          }
        );

        setDaysUser(days);
      });
  };

  function getPayments() {
    const subscribePayments = firestore("payments") //.orderBy('datetime', 'asc')
      .where("datetime", ">", moment(selectionRange.startDate).format())
      .where("datetime", "<", moment(selectionRange.endDate).format()) //ignore to enable relatime updates
      //.onSnapshot(function(snapshot){
      .get()
      .then(function (snapshot) {
        var challengesIds = {};
        challenges.map((d) => {
          challengesIds[d.key] = true;
        });

        const payments = snapshot.docs.length
          ? snapshot.docs
              .map((d) => {
                var obj = d.data();
                obj.id = d.id;
                return obj;
              })
              .filter((d) => challengesIds[d.challengeId])
          : [];

        var bundles = [];
        payments
          .filter((d) => !d.declined)
          .map((d) => {
            if (d.rounds == 2) {
              bundles.push({
                datetime: moment(d.datetime).add(1, "months"),
                userId: d.userId,
              });
            }

            if (d.rounds == 3) {
              bundles.push({
                datetime: moment(d.datetime).add(1, "months"),
                userId: d.userId,
              });
              bundles.push({
                datetime: moment(d.datetime).add(2, "months"),
                userId: d.userId,
              });
            }
          });

        var months = {};

        payments
          .concat(bundles)
          .filter((d) => !d.declined)
          .map((d) => {
            if (!months[moment(d.datetime).format(formatMonth)])
              months[moment(d.datetime).format(formatMonth)] = {};
            months[moment(d.datetime).format(formatMonth)][d.userId] = true;
          });

        var a = moment(selectionRange.startDate);
        var b = moment(selectionRange.endDate);
        var days = [];

        for (var m = moment(a); m.isBefore(b); m.add(1, "months")) {
          days.push(m.format(formatMonth));
        }

        var retention = [];
        days.map((day, i) => {
          if (!months[day]) months[day] = {};

          var returning = 0;

          Object.keys(months[day]).map((d) => {
            if (days[i - 1] && months[days[i - 1]][d]) returning++;
          });

          if (i)
            retention.push({
              day: day,
              all: Object.keys(months[day]).length,
              returning: returning,
              retention: +(
                (returning / Object.keys(months[days[i - 1]]).length) *
                100
              ).toFixed(2),
              retn: +(
                (returning / Object.keys(months[days[i - 1]]).length) *
                100
              ),
            });
          if (i)
            console.log(
              day,
              "all",
              Object.keys(months[day]).length,
              "returning",
              returning,
              "retention",
              (
                (returning / Object.keys(months[days[i - 1]]).length) *
                100
              ).toFixed(2) + "%"
            );
        });

        setRetention(retention);
        setAvgRetention({
          year_1:
            retention.map((d) => d.retn).reduce((sum, a) => sum + a, 0) / 12,
          months_3:
            retention
              .map((d) => d.retn)
              .slice(-3)
              .reduce((sum, a) => sum + a, 0) / 3,
          months_6:
            retention
              .map((d) => d.retn)
              .slice(-6)
              .reduce((sum, a) => sum + a, 0) / 6,
        });
      });
  }

  function getCollectionsAll() {
    //const subscribeChallenge = firestore("challenges")

    //.onSnapshot({includeMetadataChanges: true}, function(snapshot){

    var globalChallenge = false;
    //snapshot.docs.map(d => {
    challenges.map((d) => {
      var challenge = JSON.parse(JSON.stringify(d)); //.data()
      challenge.count = 1;

      if (!globalChallenge) {
        globalChallenge = challenge;
      } else {
        Object.keys(globalChallenge).map((key) => {
          globalChallenge[key] += challenge[key];
        });
      }
    });
    setChallengeAll(globalChallenge);

    setLoaderAll(false);

    //})

    return [
      /*subscribeChallenge, subscribePoints, subscribeFeeds*/
    ];
  }

  function fbImage(imageLink) {
    if (!imageLink) return imageLink;
    var query = imageLink.split("asid=")[1];
    if (!query) return imageLink;
    var fbId = query.split("&")[0];
    if (!fbId) return imageLink;

    return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
  }

  function numberWithCommas(x) {
    if (!x) return;
    return x.toLocaleString(); //.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function addNote(note) {
    // Replace 'documentId' with the actual ID of the document
    const documentRef = firestore("user_points").doc(selectedUser.id);

    // Query the document to get its current data
    documentRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        const data = docSnapshot.data();

        // Filter out the element with the specific object property value
        const updatedItems = data.notes || [];

        updatedItems.push({ note: note, datetime: new Date().toISOString() });

        // Update the document with the modified array
        documentRef
          .update({ notes: updatedItems })
          .then(() => {
            console.log("Element removed successfully.");
          })
          .catch((error) => {
            console.error("Error removing element:", error);
          });
      } else {
        console.log("Document not found.");
      }
    });
  }

  function removeNote(note) {
    // Replace 'documentId' with the actual ID of the document
    const documentRef = firestore("user_points").doc(selectedUser.id);

    // Query the document to get its current data
    documentRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        const data = docSnapshot.data();

        // Filter out the element with the specific object property value
        const updatedItems = data.notes.filter((item) => item.note !== note);

        // Update the document with the modified array
        documentRef
          .update({ notes: updatedItems })
          .then(() => {
            console.log("Element removed successfully.");
          })
          .catch((error) => {
            console.error("Error removing element:", error);
          });
      } else {
        console.log("Document not found.");
      }
    });
  }

  return (
    <div className="stats-page">
      <Header header="Stats" />
      <hr />
      {/* <Breadcrumb className="pb-3">
				<Breadcrumb.Item active>Statistics</Breadcrumb.Item>

			* */}
      <div className="stats">
        <div className="">
          {challengesLoading ? (
            <div>
              <Spinner animation="border" size={"lg"} role="status" />
            </div>
          ) : challenges.length ? (
            <>
              <Row
                className="challenge-row"
                style={!rounds ? { justifyContent: "initial" } : {}}
              >
                <Col md={5} xl={6}>
                  <h4>Challenge</h4>
                  <Form.Control
                    required
                    className="select-challenge"
                    as="select"
                    placeholder="Select Challenge"
                    value={
                      ignore[selectedChallenge]
                        ? challenges.filter(
                            (c) =>
                              c.previousRounds &&
                              c.previousRounds.includes(selectedChallenge)
                          )[0].key
                        : selectedChallenge
                    }
                    onChange={(e) => {
                      challenges.map((c) => {
                        if (c.key === e.target.value) {
                          setRounds(false);
                          setChallenge(c);
                        }
                      });
                      setSelectedChallenge(e.target.value);
                      setCollapse(false);
                      localStorage.setItem("challengeId", e.target.value);
                      setShowAll(false);
                    }}
                  >
                    {challenges
                      .filter((c) => !ignore[c.key])
                      .map((obj, i) => {
                        return (
                          <option
                            value={obj.key}
                            key={obj.key}
                            //hidden={ignore[obj.key]}
                          >
                            {obj.name.replace(/Round(.*)- /, "")}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Col>
                {rounds && (
                  <>
                    <Col xl={4} md={4}>
                      <h4>Round</h4>

                      <Form.Control
                        required
                        className="select-round"
                        as="select"
                        placeholder="Select Round"
                        style={{
                          cursor: "pointer",
                          width: "auto",
                          display: "inline-block",
                        }}
                        value={selectedChallenge}
                        onChange={(e) => {
                          challenges.map((c) => {
                            if (c.key === e.target.value) setChallenge(c);
                          });
                          setSelectedChallenge(e.target.value);
                          setCollapse(false);
                          localStorage.setItem("challengeId", e.target.value);
                          setShowAll(false);
                        }}
                      >
                        {challenges
                          .filter((d) => rounds.includes(d.key))
                          .map((obj, i) => {
                            return (
                              <option value={obj.key} key={obj.key}>
                                {obj.previousRounds
                                  ? "round " + (obj.previousRounds.length + 1)
                                  : "round 1"}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Col>
                  </>
                )}
                <Col xl={2} md={3}>
                  <Button
                    className="button button-disabled"
                    id="button-disabled"
                    onClick={() => {
                      setShowAll(!showAll);
                    }}
                  >
                    {showAll ? "Selected Challenge" : "All Challenges"}
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <div className="text-center">Add at Least One Challenge</div>
          )}
        </div>
      </div>
      {!showAll && (
        <div
          id="stats"
          className="pt-2 h-100 justify-content-left align-self-left"
        >
          <div>
            {loader ? (
              <div className="loader text-center">
                <Spinner animation="border" size={"sm"} role="status" />
              </div>
            ) : challenge ? (
              <div className="challenge" key={challenge.id}>
                <Card style={{ marginTop: "1.5rem" }}>
                  <Card.Body>
                    <div className="challenge-name">
                      <h5>
                        {challenge.name}{" "}
                        {challenge.duration +
                          " " +
                          (challenge.durationType &&
                            challenge.durationType.toLowerCase())}
                      </h5>
                    </div>
                    <div className="stats-card">
                      <Col md={12} xl={3}>
                        <div className="stats">
                          <Row style={{ flexWrap: "nowrap" }}>
                            <div className="text-center">
                              <img src={User} alt="user" />
                              <h4>{numberWithCommas(challenge.userCount)}</h4>
                              users
                            </div>
                            <div className="text-center">
                              <img src={Group} alt="group" />
                              <h4>{numberWithCommas(challenge.groupCount)}</h4>
                              groups
                            </div>
                            <div className="text-center">
                              <img src={Kgs} alt="kgs" />
                              <h4>
                                {challenge.weightLost &&
                                  (+challenge.weightLost).toFixed(2)}
                              </h4>
                              kg lost
                            </div>
                          </Row>
                          <Row style={{ flexWrap: "nowrap" }}>
                            <div className="text-center">
                              <img src={Kgs} alt="avg weight" />
                              <h4>
                                {challenge.weightLost &&
                                  challenge.userCount &&
                                  (
                                    +challenge.weightLost / +challenge.userCount
                                  ).toFixed(2)}
                              </h4>
                              avg <br />
                              kg lost
                            </div>
                            <div className="text-center">
                              <img src={Exercise} alt="exercise" />
                              <h4>
                                {numberWithCommas(challenge.exerciseCount)}
                              </h4>
                              exercises
                              <br /> played
                            </div>
                            <div className="text-center">
                              <img src={CupWater} alt="water" />
                              <h4>
                                {numberWithCommas(challenge.waterCupCount)}
                              </h4>
                              water <br />
                              cups
                            </div>
                          </Row>
                          <Row style={{ flexWrap: "nowrap" }}>
                            <div className="text-center">
                              <img src={Meals} alt="meal" />
                              <div>
                                <h4>{numberWithCommas(challenge.mealCount)}</h4>
                                meals <br />
                                uploaded
                              </div>
                            </div>
                            <div className="text-center">
                              <img src={Question} alt="question" />
                              <h4>
                                {numberWithCommas(challenge.questionCount)}
                              </h4>
                              questions <br />
                              asked
                            </div>
                            <div className="text-center">
                              <img src={Likes} alt="likes" />
                              <h4>{numberWithCommas(challenge.likeCount)}</h4>
                              <p>likes</p>
                            </div>
                          </Row>
                          <Row style={{ flexWrap: "nowrap" }}>
                            <div className="text-center">
                              <img src={Chat} alt="comments" />
                              <h4>
                                {numberWithCommas(challenge.commentCount)}
                              </h4>
                              <p>comments</p>
                            </div>
                          </Row>
                        </div>
                      </Col>

                      <Col
                        md={12}
                        xl={8}
                        style={{
                          marginTop: "20px",
                          marginLeft: "30px",
                          marginBottom: "3em",
                          paddingLeft: "50px",
                          paddingRight: "50px",
                          height: "400px",
                        }}
                      >
                        {days ? (
                          <>
                            <ResponsiveContainer>
                              <LineChart data={days}>
                                <XAxis dataKey="day" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <CartesianGrid
                                  stroke="#eee"
                                  strokeDasharray="5 5"
                                />
                                <Line
                                  type="monotone"
                                  dataKey="users"
                                  stroke="#8884d8"
                                  name="users"
                                />
                                <Line
                                  type="monotone"
                                  dataKey="meals"
                                  stroke="#82ca9d"
                                  name="meals"
                                />
                                <Line
                                  type="monotone"
                                  dataKey="water"
                                  stroke="#0088FE"
                                  name="water"
                                />
                                <Line
                                  type="monotone"
                                  dataKey="workout"
                                  stroke="#FF8042"
                                  name="workouts"
                                />
                                <Line
                                  type="monotone"
                                  dataKey="recipes"
                                  stroke="#deb887"
                                  name="recipes"
                                />
                              </LineChart>
                            </ResponsiveContainer>
                            {completionRate && (
                              <span
                                style={{
                                  fontSize: "12px",
                                  display: "block",
                                  textAlign: "center",
                                  paddingTop: "20px",
                                }}
                              >
                                Completion rate. {completionRate.toFixed(2)}%
                              </span>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "400px",
                              textAlign: "center",
                              paddingTop: "200px",
                            }}
                          >
                            <Spinner
                              animation="border"
                              size={"lg"}
                              role="status"
                            />
                          </div>
                        )}
                      </Col>
                    </div>
                  </Card.Body>
                </Card>
                <h4 style={{ margin: "25px 0" }}>
                  {!showNotActive ? "Top users" : "Inactive users"}
                  {!showNotActive && (
                    <Button
                      size="sm"
                      variant="info"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        setSortBy("points");
                        setOrderPoints(!orderPoints);
                      }}
                    >
                      sort by points {orderPoints ? "↑" : "↓"}
                    </Button>
                  )}
                  {!showNotActive && (
                    <Button
                      size="sm"
                      variant="info"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        setSortBy("activity");
                        setOrderActivity(!orderActivity);
                      }}
                    >
                      sort by activity {orderActivity ? "↑" : "↓"}
                    </Button>
                  )}
                  {/*!showNotActive  && <Button size='sm' variant="info" style={{pointerEvents: order? 'none': 'auto', fontWeight: order? 'bold': 'normal', marginLeft: '10px'}} onClick={()=>{
										setOrder(true)
									}}>sort by Least Active ↓</Button>*/}

                  {/* <Button
                    size="sm"
                    variant="warning"
                    style={{ float: "right" }}
                    onClick={() => {
                      setShowNotActive(!showNotActive);
                      //window.scroll({top: document.getElementById('not-active').offsetTop - 50, left: 0, behavior: 'smooth' });
                    }}
                  >
                    Inactive {showNotActive && " X"}
                  </Button> */}
                </h4>
                <Accordion defaultActiveKey="0">
                  <Card className="userboard-card">
                    <Accordion.Toggle
                      as={Button}
                      eventKey="0"
                      size="sm"
                      variant="info"
                      style={{
                        margin: "25px",
                        width: "80px",
                        backgroundColor: "grey",
                      }}
                      onClick={() => {
                        setCollapse((prev) => {
                          return !prev;
                        });
                      }}
                    >
                      {!collapse ? "Collapse" : "Spread"}
                    </Accordion.Toggle>
                    {!showNotActive && (
                      <Accordion.Collapse eventKey="0">
                        <Card.Body
                          style={
                            board.length === 0
                              ? {
                                  height: "100px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }
                              : {}
                          }
                        >
                          {board.length > 0 ? (
                            <div
                              className="userboard"
                              style={{ overflow: "auto" }}
                            >
                              <Table>
                                <thead>
                                  <tr>
                                    <td></td>
                                    <td>User</td>
                                    <td></td>
                                    <td></td>
                                    <td>Active</td>
                                    <td>Phone</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {challengeUsers &&
                                  Object.keys(challengeUsers).length
                                    ? Object.values(challengeUsers)
                                        .filter((user, i) =>
                                          showAllUsers ? true : i < 10
                                        )
                                        .sort((a, b) => {
                                          if (sortBy === "points") {
                                            return orderPoints
                                              ? a.points - b.points
                                              : b.points - a.points;
                                          } else {
                                            return orderActivity
                                              ? a.lastActive.seconds -
                                                  b.lastActive.seconds
                                              : b.lastActive.seconds -
                                                  a.lastActive.seconds;
                                          }
                                        })
                                        .map((user, i) => (
                                          <tr
                                            className="user"
                                            key={i}
                                            onClick={() =>
                                              setSelectedUser(user)
                                            }
                                          >
                                            <>
                                              <td>
                                                {!orderPoints &&
                                                  sortBy === "points" &&
                                                  i === 0 && (
                                                    <img
                                                      src={First}
                                                      alt=""
                                                      width="20"
                                                      id="badge-img"
                                                    />
                                                  )}
                                                {!orderPoints &&
                                                  sortBy === "points" &&
                                                  i === 1 && (
                                                    <img
                                                      src={Second}
                                                      alt="second"
                                                      width="20"
                                                      id="badge-img"
                                                    />
                                                  )}
                                                {!orderPoints &&
                                                  sortBy === "points" &&
                                                  i === 2 && (
                                                    <img
                                                      src={Third}
                                                      alt="third"
                                                      width="20"
                                                      id="badge-img"
                                                    />
                                                  )}
                                              </td>
                                              <td>
                                                <img
                                                  src={
                                                    fbImage(user.userImage) ||
                                                    "https://firebasestorage.googleapis.com/v0/b/diet-challenge-amr.appspot.com/o/images%2Fdefault-image.png?alt=media&token=4e702cb6-9c5b-438b-b542-d9b3be9275de"
                                                  }
                                                  alt="user"
                                                  style={{
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </td>
                                              <td className="name">
                                                <h4>{user.userName}</h4>
                                                <div
                                                  style={{
                                                    color: "#909090",
                                                  }}
                                                >
                                                  {user.points} points
                                                </div>
                                              </td>

                                              <td
                                                style={{
                                                  color: "chocolate",
                                                }}
                                              >
                                                {user.notes &&
                                                  user.notes.length > 0 &&
                                                  user.notes.length +
                                                    (user.notes.length === 1
                                                      ? " note"
                                                      : " notes")}
                                              </td>

                                              <td className="name">
                                                <div
                                                  style={{
                                                    color: "black",
                                                  }}
                                                >
                                                  {user.lastActive &&
                                                    moment(
                                                      challengeUsers[
                                                        user.userId
                                                      ]?.lastActive.toDate()
                                                    ).fromNow()}
                                                </div>
                                              </td>
                                              <td className="name">
                                                <div
                                                  style={{
                                                    color: "black",
                                                  }}
                                                >
                                                  {user.phoneNumber ||
                                                    "noPhone"}
                                                </div>
                                              </td>
                                            </>
                                          </tr>
                                        ))
                                    : null}
                                </tbody>
                              </Table>
                            </div>
                          ) : (
                            <div>No users Yet</div>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    )}

                    {/* {showNotActive && (
                      <Accordion.Collapse eventKey="0">
                        <Card.Body
                          style={
                            notActive.length === 0
                              ? {
                                  height: "100px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }
                              : {}
                          }
                        >
                          {notActive.length > 0 ? (
                            <div
                              className="userboard"
                              style={{ overflow: "auto" }}
                            >
                              <Table>
                                <thead>
                                  <tr>
                                    <td></td>
                                    <td>User</td>
                                    <td></td>
                                    <td>Active</td>
                                    <td>Phone</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {notActive && notActive.length
                                    ? notActive.map((user, i) => (
                                        <tr
                                          className="user"
                                          key={i}
                                          style={{ cursor: "default" }}
                                          //onClick={() => setSelectedUser(user)}
                                        >
                                          <td></td>
                                          <td>
                                            <img
                                              src={
                                                fbImage(user.userImage) ||
                                                "https://firebasestorage.googleapis.com/v0/b/diet-challenge-amr.appspot.com/o/images%2Fdefault-image.png?alt=media&token=4e702cb6-9c5b-438b-b542-d9b3be9275de"
                                              }
                                              alt="user"
                                              style={{ objectFit: "cover" }}
                                            />
                                          </td>
                                          <td className="name">
                                            <h4>{user.userName}</h4>
                                            <div style={{ color: "#909090" }}>
                                              {0} points
                                            </div>
                                          </td>
                                          <td className="name">
                                            <div
                                              style={{
                                                color: "black",
                                              }}
                                            >
                                              {challengeUsers[user.userId]
                                                ?.lastActive &&
                                                moment(
                                                  challengeUsers[
                                                    user.userId
                                                  ]?.lastActive.toDate()
                                                ).fromNow()}
                                            </div>
                                          </td>
                                          <td>{user.phone}</td>
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              </Table>
                            </div>
                          ) : (
                            <div>No users Yet</div>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    )} */}
                  </Card>
                </Accordion>
                <br />
                <br />

                <Row>
                  <Col xl={6}>
                    <Card className="doughnut-card">
                      <h4 className="m-0 d-flex justify-content-between">
                        Meal plans (
                        {mealplansLoader
                          ? "-"
                          : mealPlans.filter(
                              (mealplan) => mealPlansUsers[mealplan.key]
                            ).length}
                        )
                      </h4>
                      {mealplansLoader ? (
                        <div className="text-center w-100">
                          <Spinner
                            animation="border"
                            size={"sm"}
                            role="status"
                          />
                        </div>
                      ) : Object.keys(challengeUsers).length !== 0 ? (
                        mealPlans.length ? (
                          <div style={{ width: "100%", margin: "0 auto" }}>
                            <MealPlansChart
                              mealPlans={mealPlans}
                              users={Object.values(challengeUsers)}
                              mealPlansUsers={mealPlansUsers}
                            />
                          </div>
                        ) : (
                          <div className="text-center users-page-error-message">
                            No Meal plans found
                          </div>
                        )
                      ) : (
                        <div className="users-page-error-message">
                          No users Assigned to Meal plans
                        </div>
                      )}
                    </Card>
                  </Col>
                  <Col xl={6}>
                    <Card className="doughnut-card">
                      <h4 className="m-0 d-flex justify-content-between">
                        Workout plans (
                        {workoutsLoader
                          ? "-"
                          : workoutPlans.filter(
                              (workout) => workoutUsers[workout.key]
                            ).length}
                        )
                      </h4>

                      {workoutsLoader ? (
                        <div className="text-center w-100">
                          <Spinner
                            animation="border"
                            size={"sm"}
                            role="status"
                          />
                        </div>
                      ) : Object.keys(challengeUsers).length !== 0 ? (
                        workoutPlans.length ? (
                          <div style={{ width: "100%", margin: "0 auto" }}>
                            <WorkoutPlansChart
                              workoutPlans={workoutPlans}
                              users={Object.values(challengeUsers)}
                              workoutUsers={workoutUsers}
                            />
                          </div>
                        ) : (
                          <div className="text-center  users-page-error-message">
                            No Workout plans found
                          </div>
                        )
                      ) : (
                        <div className="users-page-error-message">
                          No users assigned to Workout plans{" "}
                        </div>
                      )}
                    </Card>
                  </Col>
                </Row>

                {Object.keys(challengeUsers).length ? (
                  <>
                    <h4 style={{ marginLeft: "25px", marginTop: "20px" }}>
                      Demographics
                    </h4>
                    <Row>
                      <Col xl={8}>
                        <Card className="my-2" style={{ height: "400px" }}>
                          <Card.Body>
                            <div className="text-center w-100 demographics">
                              {usersLoader ? (
                                <div className="justify-content-md-center">
                                  <Spinner
                                    animation="border"
                                    size={"sm"}
                                    role="status"
                                  />
                                </div>
                              ) : (
                                <Row>
                                  <Col xs={16} className="countries">
                                    <MapChart
                                      data={countries}
                                      total={Object.keys(challengeUsers).length}
                                    />
                                  </Col>

                                  <Col
                                    xs={4}
                                    style={{
                                      overflowY: "scroll",
                                      height: "350px",
                                    }}
                                  >
                                    <div className="countries-header d-flex">
                                      <div>Countries</div>
                                      <div>Users</div>
                                    </div>
                                    <hr
                                      style={{
                                        margin: "0.3em",
                                        color: "#f0f0f0",
                                      }}
                                    />

                                    <div className="countries-text">
                                      {_.orderBy(
                                        Object.keys(countries),
                                        (key) => countries[key].count,
                                        "desc"
                                      ).map((key, i) => (
                                        <div
                                          key={key}
                                          className="country"
                                          // onHover={(e) => {}}
                                          style={{
                                            opacity:
                                              0.6 +
                                              0.4 *
                                                ((Object.keys(countries)
                                                  .length -
                                                  i) /
                                                  Object.keys(countries)
                                                    .length),
                                          }}
                                        >
                                          <span>
                                            {countries[key].abbreviation}
                                          </span>
                                          <span className="span-countries">
                                            <b>{countries[key].count}</b>
                                          </span>{" "}
                                        </div>
                                      ))}
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={4}>
                        <Card className="my-2">
                          <div className="text-center w-100 demographics">
                            {usersLoader ? (
                              <div className="justify-content-md-center">
                                <Spinner
                                  animation="border"
                                  size={"sm"}
                                  role="status"
                                />
                              </div>
                            ) : (
                              <div>
                                <div className="demographics-category">
                                  <div className="category">Category</div>
                                  <div className="avg-num">Number</div>
                                </div>
                                <hr
                                  style={{
                                    margin: "0.3em",
                                    color: "#f0f0f0",
                                  }}
                                />
                                {Object.keys(demographics).map((key) => (
                                  <div key={key} className="property">
                                    <span className="key">
                                      {/* {console.log(key === "male")} */}
                                      {key !== "male" && key !== "female"
                                        ? "avg."
                                        : ""}{" "}
                                      {key}
                                    </span>
                                    <span className="value">
                                      {Number.isInteger(demographics[key])
                                        ? demographics[key]
                                        : demographics[key].toFixed(2)}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </>
                ) : null}

                {!showAllUsers && (
                  <div>
                    <Button
                      variant="light"
                      onClick={() => {
                        setShowAllUsers(!showAllUsers);
                      }}
                      style={{ marginLeft: "5em" }}
                    >
                      <FontAwesomeIcon
                        icon={faUsers}
                        size="1x"
                        className="mr-2"
                      />
                      Show all
                    </Button>
                  </div>
                )}

                {daysUser ? (
                  <div
                    style={{
                      marginTop: "20px",
                      marginLeft: "30px",
                      marginBottom: "3em",
                    }}
                    className="activity-chart"
                  >
                    <div
                      onClick={() => {
                        setDaysUser();
                        setUserPhone();
                      }}
                      className="chart-close"
                    >
                      X
                    </div>
                    <div className="chart-name">
                      <img
                        height="30px"
                        src={
                          fbImage(selectedUser.userImage) ||
                          "https://firebasestorage.googleapis.com/v0/b/diet-challenge-amr.appspot.com/o/images%2Fdefault-image.png?alt=media&token=4e702cb6-9c5b-438b-b542-d9b3be9275de"
                        }
                        alt="user"
                        style={{ objectFit: "cover", borderRadius: "15px" }}
                      />

                      <span style={{ display: "inline-block" }}>
                        {selectedUser.userName}
                      </span>
                      <span>{selectedUser.points} points</span>

                      {userRounds > 0 && (
                        <span>({userRounds}) previous rounds</span>
                      )}
                    </div>

                    <div style={{ paddingLeft: "63px", color: "chocolate" }}>
                      {(
                        board.filter((d) => d.userId === selectedUser.userId)[0]
                          ?.notes || []
                      ).map((note) => (
                        <div>
                          <span
                            style={{ fontWeight: "bold", cursor: "pointer" }}
                            onClick={() => {
                              removeNote(note.note);
                            }}
                          >
                            X
                          </span>
                          <span
                            style={{ color: "#aaa", margin: "0 10px 0 10px" }}
                          >
                            {moment(note.datetime)
                              .fromNow()
                              .replace("a few seconds ago", "now")}
                          </span>
                          {note.note}
                        </div>
                      ))}
                    </div>

                    <div>
                      <Button
                        variant="info"
                        size="sm"
                        className="btn-primary"
                        onClick={() => {
                          const note = prompt();
                          if (!note) return;
                          addNote(note);
                        }}
                        style={{ margin: "15px 0 15px 63px" }}
                      >
                        <FontAwesomeIcon
                          icon={faStickyNote}
                          size="1x"
                          className="mr-2"
                        />
                        Add note
                      </Button>
                      {userPhone && (
                        <span style={{ marginLeft: "20px" }}>
                          <span style={{ opacity: 0.5 }}>phone: </span>
                          {userPhone}
                        </span>
                      )}
                    </div>

                    {userInfoWeight && userInfoWeight.length && (
                      <div style={{ margin: "10px 0 25px 63px" }}>
                        <div
                          style={{
                            fontSize: ".9em",
                            opacity: ".5",
                            padding: ".5em 0",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faChartLine}
                            size="1x"
                            className=""
                            style={{ opacity: ".3" }}
                          />{" "}
                          weight change{" "}
                          <span
                            style={{
                              marginLeft: "10px",
                              color:
                                userInfoWeight[userInfoWeight.length - 1]
                                  .weight -
                                  Number(userInfoWeight[0].weight) <
                                0
                                  ? "green"
                                  : "initial",
                            }}
                          >
                            {userInfoWeight && userInfoWeight.length
                              ? +(
                                  userInfoWeight[userInfoWeight.length - 1]
                                    .weight - Number(userInfoWeight[0].weight)
                                ).toFixed(2) + " kg."
                              : 0}
                          </span>
                        </div>
                        <div>
                          {userInfoWeight && userInfoWeight.length
                            ? userInfoWeight.map((d) => (
                                <div>
                                  day {d.day}{" "}
                                  <span
                                    style={{
                                      fontSize: ".9em",
                                      opacity: ".5",
                                    }}
                                  >
                                    ({moment(d.dateTime).format("MMM Do")})
                                  </span>{" "}
                                  ⇢ {Number(d.weight)}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    )}

                    <AreaChart width={600} height={500} data={daysUser || []}>
                      <defs>
                        <linearGradient
                          id="colorMeals"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#8884d8"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#8884d8"
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient
                          id="colorWater"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#82ca9d"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#82ca9d"
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient
                          id="colorWorkout"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#FF8042"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#FF8042"
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient
                          id="colorRecipes"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#deb887"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#deb887"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="day" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="meals"
                        stroke="#8884d8"
                        fillOpacity={1}
                        fill="url(#colorMeals)"
                      />
                      <Area
                        type="monotone"
                        dataKey="waterDone"
                        stroke="#82ca9d"
                        fillOpacity={1}
                        fill="url(#colorWater)"
                      />
                      <Area
                        type="monotone"
                        dataKey="workout"
                        stroke="#FF8042"
                        fillOpacity={1}
                        fill="url(#colorWorkout)"
                      />
                      <Area
                        type="monotone"
                        dataKey="recipeViewed"
                        stroke="#deb887"
                        fillOpacity={1}
                        fill="url(#colorRecipes)"
                      />
                    </AreaChart>
                  </div>
                ) : null}
              </div>
            ) : (
              <span>no challenge</span>
            )}
          </div>
        </div>
      )}
      {showAll && (
        <Card style={{ margin: "30px 0" }} id="analytics-card">
          <div
            id="analytics"
            className=" h-100 d-flex justify-content-left align-self-left"
          >
            <div>
              {loaderAll ? (
                <div className="loader text-center">
                  <Spinner animation="border" size={"sm"} role="status" />
                </div>
              ) : (
                challengeAll && (
                  <div className="challenge" key={challengeAll.id}>
                    <div className="challenge-name">
                      <h4>{challengeAll.count} challenges</h4>
                    </div>
                    <div>
                      <div className="analytics">
                        <Row>
                          <div className="text-center">
                            <img src={User} alt="user" />
                            <h4>{numberWithCommas(challengeAll.userCount)}</h4>
                            users
                          </div>
                          <div className="text-center">
                            <img src={Group} alt="group" />
                            <h4>{numberWithCommas(challengeAll.groupCount)}</h4>
                            groups
                          </div>
                          <div className="text-center">
                            <img src={Kgs} alt="weight" />
                            <h4>
                              {challengeAll.weightLost &&
                                (+challengeAll.weightLost).toFixed(2)}
                            </h4>
                            kg lost
                          </div>
                        </Row>
                        <Row>
                          <div className="text-center">
                            <img src={Kgs} alt="avg" />
                            <h4>
                              {challengeAll.weightLost &&
                                challengeAll.userCount &&
                                (
                                  +challengeAll.weightLost /
                                  +challengeAll.userCount
                                ).toFixed(2)}
                            </h4>
                            avg kg
                            <br /> lost
                          </div>
                          <div className="text-center">
                            <img src={Exercise} alt="exercise" />
                            <h4>
                              {numberWithCommas(challengeAll.exerciseCount)}
                            </h4>
                            exercises
                            <br /> played
                          </div>
                          <div className="text-center">
                            <img src={CupWater} alt="water" />
                            <h4>
                              {numberWithCommas(challengeAll.waterCupCount)}
                            </h4>
                            water
                            <br /> cups
                          </div>
                        </Row>
                        <Row>
                          <div className="text-center">
                            <img src={Meals} alt="meals" />
                            <h4>{numberWithCommas(challengeAll.mealCount)}</h4>
                            meals
                            <br /> uploaded
                          </div>
                          <div className="text-center">
                            <img src={Question} alt="question" />
                            <h4>
                              {numberWithCommas(challengeAll.questionCount)}
                            </h4>
                            questions
                            <br /> asked
                          </div>
                          <div className="text-center">
                            <img src={Likes} alt="likes" />
                            <h4>{numberWithCommas(challengeAll.likeCount)}</h4>
                            likes
                          </div>
                        </Row>
                        <Row>
                          <div className="text-center">
                            <img src={Chat} alt="comments" />
                            <h4>
                              {numberWithCommas(challengeAll.commentCount)}
                            </h4>
                            comments
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div
              style={{ marginTop: "5em", marginBottom: "2em", flexGrow: "1" }}
            >
              {retention ? (
                <div
                  style={{
                    marginTop: "20px",
                    marginLeft: "30px",
                    marginBottom: "3em",
                    paddingRight: "100px",
                    height: "550px",
                  }}
                >
                  <ResponsiveContainer>
                    <LineChart data={retention}>
                      <XAxis dataKey="day" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                      <Line
                        type="monotone"
                        dataKey="all"
                        stroke="#0088FE"
                        name="users"
                      />
                      <Line
                        type="monotone"
                        dataKey="returning"
                        stroke="#228c39"
                        name="returning"
                      />
                      <Line
                        type="monotone"
                        dataKey="retention"
                        stroke="#FF8042"
                        name="retention"
                      />
                      {/*<Line type="monotone" dataKey="workout" stroke="#FF8042" name="workouts" />*/}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "550px",
                    textAlign: "center",
                    paddingTop: "200px",
                  }}
                >
                  <Spinner animation="border" size={"lg"} role="status" />
                </div>
              )}

              {avgRetention && (
                <div style={{ textAlign: "center", color: "#FF8042" }}>
                  1 year avg. retention{" "}
                  <b>{avgRetention.year_1.toFixed(2)} %</b>
                  <br />6 months avg. retention{" "}
                  <b>{avgRetention.months_6.toFixed(2)} %</b>
                  <br />3 months avg. retention{" "}
                  <b>{avgRetention.months_3.toFixed(2)} %</b>
                  <br />
                </div>
              )}
            </div>
          </div>
        </Card>
      )}
    </div>
  );
}
