
import React, {useEffect, useState} from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';

import { Spinner, Breadcrumb, Form, Col } from "react-bootstrap";
import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";


import Profile from '../../assets/images/Profile.png';
import people from '../../assets/images/people.png';
import weight from '../../assets/images/weight.png';
import weight2 from '../../assets/images/weight2.png';
import exercise from '../../assets/images/exercise.png';
import glassFilled from '../../assets/images/glass-filled.png';
import meal from '../../assets/images/meal.png';
import questionmark from '../../assets/images/questionmark.png';
import dcLike from '../../assets/images/dc-like.png';
import comments from '../../assets/images/comments.png';

import logoImg from '../../assets/icons/icon.png';
import './Analytics.css';


export function Analytics(props) {

    const [loader, setLoader] = useState(true);
    const [days, setDays] = useState([]);
    const [board, setBoard] = useState([]);
    const [challenge, setChallenge] = useState();
    const [challenges, setChallenges] = useState([]);
    const [selectedChallenge, setSelectedChallenge] = useState('');
    const [challengesLoading, setChallengesLoading] = useState(false);
    const [ignore, setIgnore] = useState({});
    const [rounds, setRounds] = useState(false);

    const [retention, setRetention] = useState([]);
    const [avgRetention, setAvgRetention] = useState();
    const [formatMonth, setFormatMonth] = useState('MMM YY');
    const [selectionRange, setSelectionRange] = useState({
        startDate: moment().subtract(1, 'years').subtract(1, 'months').startOf('month'),
        endDate: moment().subtract(1, 'months').endOf('month'),
        key: 'selection',
    });

    useEffect(() => {

        //getChallenges();

    }, []);

    useEffect(() => {

        setLoader(true)
        var unsubscribes = getCollections();

        getPayments()

        return () => {

            console.log('Do some cleanup');
            unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);


    function getChallenges() {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if(!userDetails || !userDetails.id){

            setChallenges([]);
            setChallengesLoading(false);
            return
        }

        setChallengesLoading(false); //true
        return firestore('challenges').where('nutritionistId', '==', userDetails.id)
            .get().then((querySnapshot) => {
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id,
                    };
                });
                //_.orderBy(groups, 'userCount', 'desc')
                //data = _.orderBy(data, 'price', 'desc')



                data = _.orderBy(data, item => moment.utc(item.date).format(), 'desc')

                var grouped = [], ignore = {}, firstRound = {}
                data.map(d => {

                    if(d.previousRounds){
                        d.rounds = d.previousRounds.length+1
                        d.previousRounds.map((id, i) => {

                            ignore[id] = true

                            if(i === d.previousRounds.length-1) firstRound[id] = true
                        })
                        d.roundsIds = [d.key].concat(d.previousRounds)
                    }

                    if(firstRound[d.key]) {
                        d.rounds = 1
                        d.roundsIds = [d.key]
                    }
                })

                setIgnore(ignore)

                setChallenges(data);
                setChallengesLoading(false);

                var storageChallenge = localStorage.getItem('challengeId')
                if(storageChallenge && data.filter(c => c.key === storageChallenge).length){

                    var d = data.filter(c => c.key === storageChallenge)[0]
                    var rounds = data.filter(c => c.roundsIds && c.roundsIds.includes(d.key))
                    setRounds(rounds.length? rounds[0].roundsIds : false); 
                    setSelectedChallenge(d.key)
                }
                else if(data.length){

                    setRounds(data[0].roundsIds); 
                    setSelectedChallenge(data[0].key)
                    localStorage.setItem('challengeId', selectedChallenge);
                }
                
            }, error => {
                console.log('error', error)
            });
    }

    function getPayments(){

        const subscribePayments = firestore("payments")//.orderBy('datetime', 'asc')
        .where("datetime", ">", moment(selectionRange.startDate).format())
        .where('datetime', '<', moment(selectionRange.endDate).format())  //ignore to enable relatime updates
        //.onSnapshot(function(snapshot){
        .get().then(function(snapshot){

            const payments = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            var bundles = []
            payments.filter(d => !d.declined).map((d) => {

                if(d.rounds == 2){

                    bundles.push({datetime: moment(d.datetime).add(1, 'months'), userId: d.userId})
                }

                if(d.rounds == 3){

                    bundles.push({datetime: moment(d.datetime).add(1, 'months'), userId: d.userId})
                    bundles.push({datetime: moment(d.datetime).add(2, 'months'), userId: d.userId})
                }
            })

            var months = {}

            payments.concat(bundles).filter(d => !d.declined).map((d) => {

                if(!months[moment(d.datetime).format(formatMonth)]) months[moment(d.datetime).format(formatMonth)] = {}
                  months[moment(d.datetime).format(formatMonth)][d.userId] = d.challengeId || true  //fallback for one to one and box
            })
                
            console.log(months)

            var a = moment(selectionRange.startDate);
            var b = moment(selectionRange.endDate);
            var days = []

            for (var m = moment(a); m.isBefore(b); m.add(1, 'months')) {
                
                days.push(m.format(formatMonth))
            }

            console.log(days)

            var retention = []
            days.map((day, i) => {

                if(!months[day]) months[day] = {}

                //console.log(i, day, months[day])
                var returning = 0, challenges = {}

                Object.keys(months[day]).map(d => {

                    if(!challenges[months[day][d]]) challenges[months[day][d]] = 0
                    challenges[months[day][d]] ++ 

                  if(days[i-1] && months[days[i-1]][d]) returning++
                })

                if(i) retention.push({day: day, challenges: Object.keys(challenges).length, all: Object.keys(months[day]).length, returning: returning, retention: +(returning/Object.keys(months[days[i-1]]).length*100).toFixed(2), retn: +(returning/Object.keys(months[days[i-1]]).length*100)})
                if(i) console.log(day, 'all', Object.keys(months[day]).length, 'challenges', Object.keys(challenges).length, 'returning', returning, 'retention', (returning/Object.keys(months[days[i-1]]).length*100).toFixed(2)+'%')
            })

            setRetention(retention)
            setAvgRetention({
                year_1: retention.map(d => d.retn).reduce((sum, a) => sum + a, 0) / 12,
                months_3: retention.map(d => d.retn).slice(-3).reduce((sum, a) => sum + a, 0) / 3,
                months_6: retention.map(d => d.retn).slice(-6).reduce((sum, a) => sum + a, 0) / 6,
            })
        })
    }

    function getCollections(){

        const subscribeChallenge = firestore("challenges")

            .onSnapshot({includeMetadataChanges: true}, function(snapshot){
            //.get().then(function(snapshot){

            var globalChallenge = false
            snapshot.docs.map(d => {

                var challenge = d.data()
                challenge.count = 1

                if(!globalChallenge){

                    globalChallenge = challenge
                }
                else {

                    Object.keys(globalChallenge).map(key => {

                        globalChallenge[key] += challenge[key]
                    })
                }
            })
            setChallenge(globalChallenge)
                    
            setLoader(false);

        })


        /*const subscribePoints = firestore("user_points")

            .where('challengeId', '==', selectedChallenge)
            .orderBy('points', 'desc')
            .limit(10)
            .onSnapshot({includeMetadataChanges: true}, function(snapshot){
            //.get().then(function(snapshot){

            var board = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            if(board.length){

                firestore("users_badges")

                    .where('userId', 'in', board.map((d)=>d.userId))
                    .orderBy('position', 'desc')
                    //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
                    .get().then(function(snapshot){

                    var userBadges = {}
                    snapshot.docs.map((d)=>{

                        const badge = d.data();

                        if(userBadges[badge.userId]){

                            var fields = userBadges[badge.userId].map((badge)=>badge.field)

                            if(!fields.includes(badge.field)){

                                userBadges[badge.userId].push(_.clone(badge))
                            }
                        } 
                        else{
                            userBadges[badge.userId] = [_.clone(badge)]
                        }
                    })

                    board.map((user)=>{ user.badges = userBadges[user.userId] || [] })

                    setBoard(board);

                    setLoader(false);

                    //loadActivity();
                })
            }
            else{

                setBoard([]);

                setLoader(false);

                //loadActivity();
            }
        })*/

        var loadActivity = function(){

            const subscribeFeeds = firestore("user_challenges")

                .where('challengeId', '==', selectedChallenge)
                //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
                .get().then(function(snapshot){

                const user_challenges = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

                var pointsByDay = {}

                user_challenges.map((d) => {

                    const day = parseFloat(d.dietDay)

                    if(day <= 0) return

                    if(!pointsByDay[day]) pointsByDay[day] = {day: day, user: {}, users: 0, meals: 0, workout: 0, water: 0, waterDone: 0}
                    pointsByDay[day].user[d.userId] = true
                    pointsByDay[day].water += d.isDrinkWaterCompleted? 1 : 0
                    pointsByDay[day].workout += d.isWorkoutCompleted? 1 : 0
                    pointsByDay[day].waterDone += d.glassOfWaterCount
                    if(d.Breakfast) pointsByDay[day].meals ++ 
                    if(d.Dinner) pointsByDay[day].meals ++ 
                    if(d.Lunch) pointsByDay[day].meals ++ 
                    if(d.Snack) pointsByDay[day].meals ++
                    if(d.Snack2) pointsByDay[day].meals ++
                    if(d.Sohour) pointsByDay[day].meals ++ 
                    if(d.Iftar) pointsByDay[day].meals ++
                })

                for(var day in pointsByDay){

                    pointsByDay[day].users = Object.keys(pointsByDay[day].user).length
                }

                var days = _.orderBy(Object.values(pointsByDay), 'day', 'asc').map((d)=>{d.day = 'day '+d.day; return d})

                setDays(days);
            })
        }

        return [subscribeChallenge/*, subscribePoints, subscribeFeeds*/]
    }

    function fbImage(imageLink){
        
        if(!imageLink) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    function numberWithCommas(x) {
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (<>

            <Breadcrumb className="pb-3">
                <Breadcrumb.Item active>Analytics</Breadcrumb.Item>

            {/*challengesLoading ? <div><Spinner animation="border" size={'lg'} role="status" /></div> : challenges.length ?
                <>

                    <Breadcrumb.Item active>Challenge</Breadcrumb.Item>
                    
                    <Breadcrumb.Item active>
                        <div className="selectWrapper" style={{position: 'absolute', top: '5px'}}>
                            <Form.Control required as="select" placeholder="Select Challenge" style={{cursor: 'pointer', width: 'auto', display: 'inline-block'}}
                                value={ignore[selectedChallenge] ? challenges.filter(c => c.previousRounds && c.previousRounds.includes(selectedChallenge))[0].key : selectedChallenge}
                                onChange={(e) => {
                                    challenges.map(c => {
                                        if(c.key == e.target.value) {setRounds(c.roundsIds); setChallenge(c)}
                                    })
                                    setSelectedChallenge(e.target.value);
                                    localStorage.setItem('challengeId', e.target.value);
                                }}>
                                {challenges.filter(c => !ignore[c.key]).map((obj, i) => {
                                    return (<option value={obj.key} key={obj.key}>{obj.name.replace(/Round(.*)- /,'')}</option>)
                                })}
                            </Form.Control>
                            {rounds && <Form.Control required as="select" placeholder="Select Round" style={{cursor: 'pointer', width: 'auto', display: 'inline-block', marginLeft: '5px'}}
                                value={selectedChallenge}
                                onChange={(e) => {
                                    challenges.map(c => {
                                        if(c.key == e.target.value) setChallenge(c)
                                    })
                                    setSelectedChallenge(e.target.value);
                                    localStorage.setItem('challengeId', e.target.value);
                                }}>
                                {challenges.filter(d => rounds.includes(d.key)).map((obj, i) => {
                                    return (<option value={obj.key} key={obj.key}>{obj.previousRounds? 'round '+(obj.previousRounds.length+1):'round 1'}</option>)
                                })}
                            </Form.Control>}
                        </div>
                    </Breadcrumb.Item>
                </> : 
            <div></div>*/}


            </Breadcrumb>




        <div id="analytics" className="pt-5 h-100 d-flex justify-content-space-between align-self-left">
            <div>
                {loader ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 

                    challenge && <div className="challenge" key={challenge.id}>

                        <div>

                            <div className="analytics">

                                <div className="challenge-name">

                                    <b>{challenge.count}</b>  challenges

                                </div>

                                <div><img src={Profile}/><b>{numberWithCommas(challenge.userCount)}</b>users</div>
                                <div><img src={people}/><b>{numberWithCommas(challenge.groupCount)}</b>groups</div>
                                <div><img src={weight}/><b>{challenge.weightLost&&(+challenge.weightLost).toFixed(2)}</b>kg lost</div>
                                <div><img src={weight2}/><b>{challenge.weightLost&&challenge.userCount&&(+challenge.weightLost/+challenge.userCount).toFixed(2)}</b>avg kg lost</div>
                                <div><img src={exercise}/><b>{numberWithCommas(challenge.exerciseCount)}</b>exercises played</div>
                                <div><img src={glassFilled}/><b>{numberWithCommas(challenge.waterCupCount)}</b>water cups</div>
                                <div><img src={meal}/><b>{numberWithCommas(challenge.mealCount)}</b>meals uploaded</div>
                                <div><img src={questionmark}/><b>{numberWithCommas(challenge.questionCount)}</b>questions asked</div>
                                <div><img src={dcLike}/><b>{numberWithCommas(challenge.likeCount)}</b>likes</div>
                                <div><img src={comments}/><b>{numberWithCommas(challenge.commentCount)}</b>comments</div>

                            </div> 

                            <div className="userboard">

                                {board&&board.length? board.map((user, i) => 

                                    <div className="user" key={user.id}>
                                        <div><img src={fbImage(user.userImage)||"https://firebasestorage.googleapis.com/v0/b/diet-challenge-amr.appspot.com/o/images%2Fdefault-image.png?alt=media&token=4e702cb6-9c5b-438b-b542-d9b3be9275de"}/></div>
                                        <div className="name">
                                            <b>{user.userName}</b>
                                            <div>{user.points} points</div>
                                        </div>

                                        <div className="badges">

                                            {user.badges&&user.badges.length? user.badges.slice(0,6).map((badge, i)=><div key={badge.id+String(i)}>

                                                <div className="badge"><img src={badge.image} title={badge.description}/><div>{badge.description}</div></div>

                                            </div>) : <div></div>}
                                        </div>
                                    </div>
                                )

                                 : null}

                            </div> 

                        </div> 

                        {days.length? <div style={{marginTop: '20px', marginLeft: '30px', marginBottom: '3em'}}>
                            <LineChart width={600} height={300} data={days}>
                                <XAxis dataKey="day"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                                <Line type="monotone" dataKey="users" stroke="#8884d8" name="users" />
                                <Line type="monotone" dataKey="meals" stroke="#82ca9d" name="meals" />
                                {/*<Line type="monotone" dataKey="water" stroke="#0088FE" name="water" />*/}
                                {/*<Line type="monotone" dataKey="workout" stroke="#FF8042" name="workouts" />*/}
                            </LineChart>
                        </div> : null}
                    </div>
                }
            </div>



            <div style={{marginTop: '5em', marginBottom: '2em', flexGrow: '1'}}>

                {retention.length? <div style={{marginTop: '20px', marginLeft: '30px', marginBottom: '3em', paddingRight: '100px', height: '550px'}}>
                    <ResponsiveContainer>
                        <LineChart data={retention}>
                            <XAxis dataKey="day"/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                            <Line type="monotone" dataKey="challenges" stroke="#bb5588" name="challenges" />
                            <Line type="monotone" dataKey="all" stroke="#0088FE" name="users" />
                            <Line type="monotone" dataKey="returning" stroke="#228c39" name="returning" />
                            <Line type="monotone" dataKey="retention" stroke="#FF8042" name="retention" />
                            {/*<Line type="monotone" dataKey="workout" stroke="#FF8042" name="workouts" />*/}
                        </LineChart>
                    </ResponsiveContainer>
                </div> : <div style={{width: '100%', height: '550px', textAlign: 'center', paddingTop: '200px'}}><Spinner animation="border" size={"lg"} role="status" /></div>}

                {avgRetention && <div style={{textAlign: 'center', color: '#FF8042'}}>
                    1 year avg. retention <b>{avgRetention.year_1.toFixed(2)} %</b><br/>
                    6 months avg. retention <b>{avgRetention.months_6.toFixed(2)} %</b><br/>
                    3 months avg. retention <b>{avgRetention.months_3.toFixed(2)} %</b><br/>

                </div>}

            </div>

        </div>
    </>)
}
