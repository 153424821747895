/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Spinner, Col, Form } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAngleUp,
	faAngleDown,
	faInfoCircle,
	faCheckSquare,
	faCommentDots,
	faHeadset,
	faImage,
	faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import imageCompression from "browser-image-compression";
import { firestore, storage } from "../services/firebase";
import StarRatings from "react-star-ratings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from 'firebase/compat/app';
//import { ReactComponent as Support } from '../../assets/icons/support.svg';
import * as _ from "lodash";

import moment from "moment";

export function Thread(props) {
	const totalDays = 30;
	const [isImageChanged, setIsImageChanged] = useState(false);
	const [questionImage, setquestionImage] = useState("");
	const [imageFile, setImageFile] = useState(false);
	const [videoFile, setVideoFile] = useState(false);
	const [imagePreview, setImagePreview] = useState(false);
    const [loader, setLoader] = useState(false);
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [isCloseLoader, setIsCloseLoader] = useState(false);
	const [expand, setExpand] = useState(true);
	const [showThread, setShowThread] = useState(false);
	const [imageError, setImageError] = useState("");
	const [newMessage, setNewMessage] = useState("");
	const [message, setMessage] = useState({title: '', body: '', type: 'FollowUp', replyable: true});
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);
    const [types, setTypes] = useState({

        //Welcome: {replyable: false}, 
        //Purchase: {replyable: false}, 
        FollowUp: {replyable: true}, 
        Promotion: {replyable: false}, 
        Reminder: {replyable: false}, 
        Feedback: {replyable: true}
    });
	const initFormValue = {
		challengeId: "",
		challengeName: "",
		question: "",
		answer: "",
	};
	const [formValue, setFormValue] = useState(initFormValue);
	const fileInput = useRef(null);

	useEffect(() => {
		var unsub = setFormData();

		setTimeout(() => {
			if (document.getElementById("user-info"))
				document.getElementById("user-info").style.display = "block";
		}, 1000);
		setTimeout(() => {
			if (document.getElementById("user-info"))
				document.getElementById("user-info").style.left = 0;
		}, 2000);

		return () => {
			//unsub()
		};
	}, []);

	useEffect(() => {
		scrollBottom();

		return () => {};
	}, [expand, formValue]);

	function scrollBottom() {
		var objDiv = document.getElementById("ticket-thread");
		if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;

		setTimeout(() => {
			var objDiv = document.getElementById("ticket-thread");
			if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
		}, 1000);

		setTimeout(() => {
			var objDiv = document.getElementById("ticket-thread");
			if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
		}, 3000);
	}

	function addMessage(message) {
		console.log(message);
		setNewMessage({
			message: message,
			isDashboard: true,
		});
	}

	async function setFormData() {
		if (!user || !user.id) return;
		// console.log(user)
		var thread = await firestore("users_support").doc(user.id).get();
		var questionData = thread.exist ? thread.data() : {};
		questionData.id = user.id;

		//var snapshot = await firestore('users_support').doc(user.id).collection('messages').get()
		//questionData.messages = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

		var msgs = firestore("users_support")
			.doc(user.id)
			.collection("messages")
			.onSnapshot(function (snapshot) {
				questionData.messages = snapshot.docs.length
					? snapshot.docs.map((d) => {
							var obj = d.data();
							obj.id = d.id;
							return obj;
					  })
					: [];
				const method = function (item) {
					return moment.utc(item.datetime).format();
				};
				questionData.messages = _.orderBy(questionData.messages, method, "asc");

				//setQuestionData(questionData);
				setFormValue(questionData);
				addMessage("");
				scrollBottom();
			});

		//var snapshot = await firestore('users').doc(user.id).get()
		//if(snapshot.exists) questionData.user = snapshot.data()

		return msgs;
	}

	async function closeTicket() {
		setIsSubmitLoader(true);

		await firestore("users_support")
			.doc(props.questionData.key)
			.update({ closed: true });

		setIsCloseLoader(false);
		props.handleClose(true);
	}

	async function submitForm(e) {
		e.stopPropagation();
		e.preventDefault();

		setIsSubmitLoader(true);

		var image = ''
		var video = ''

		if(imageFile){

			const uploaded = await storage()
				.ref()
				.child(`/messages/${moment().unix()}`)
				.put(imageFile, { cacheControl: "public,max-age=31536000" });
			const child = await storage().ref().child(uploaded.metadata.fullPath);
			const url = await child.getDownloadURL();

			image = url;
		}


		if(videoFile){

			const uploaded = await storage()
				.ref()
				.child(`/messages/${moment().unix()}`)
				.put(imageFile, { cacheControl: "public,max-age=31536000" });
			const child = await storage().ref().child(uploaded.metadata.fullPath);
			const url = await child.getDownloadURL();

			video = image;
		}

		var html = `<!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Your Rich Content Page</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 0;
                        }
                        header {
                            background-color: #333;
                            color: #fff;
                            text-align: center;
                            padding: 1rem;
                        }
                        h1 {
                            font-size: 2rem;
                        }
                        main {
                            padding: 1rem;
                        }
                        section {
                            margin-bottom: 2rem;
                        }
                        .container {
                            max-width: 100%;
                            padding: 0 1rem;
                        }
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                        iframe {
                            max-width: 100%;
                        }
                        article {
                            background-color: #f7f7f7;
                            padding: 1rem;
                        }
                        @media (min-width: 768px) {
                            .container {
                                max-width: 768px;
                                margin: 0 auto;
                            }
                        }
                    </style>
                </head>
                <body>
                    <header>
                        <h1>${message.title}</h1>
                    </header>
                    <main>
                        <section>
                            <div class="container">
                                `+(image ? `<img src="${image}" alt="A beautiful photo 1">` : '')+`
                            </div>
                            <article>
                                <p>${message.body}</p>
                            </article>
                        </section>
                        `+(video ? `<section>
                            <div class="container">
                                <iframe width="560" height="315" src="${video}" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </section>` : '')+`
                    </main>
                </body>
                </html>`;

        console.log(props.recipients)
        await firestore('messages').add({
			challengeId: props.challengeId || '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            datetime: new Date().toISOString(), 
            title: message.title, 
            body: message.body, 
            image: image || '',
            video: video || '',
            html: html,
            type: message.type,
            replyable: message.replyable,
            recipients: props.recipients.map(d => {return {id:d.id, name:d.name, image:d.image}}),
            recipientsIds: props.recipients.map(d => d.id),
            nutritionist: {id:user.id, name:user.name, image:user.image}
        })

		setIsSubmitLoader(false);
		props.close(true);

		// } else {
		//     setImageError('image is required');
		// }
	}

	async function handleFileUpload(e) {
		e.stopPropagation();
		e.preventDefault();

		setIsSubmitLoader(true);

		console.log(e.target.files[0].name);

		const fileName = e.target.files[0].name + "-" + moment().unix();
		const imageFile = (e.target.files || e.dataTransfer.files)[0];

		setImageFile(imageFile);

		const reader = new FileReader();

		reader.onloadend = function() {
			const base64 = reader.result;
			setImagePreview(base64);
		};

      	reader.readAsDataURL(imageFile);

		setIsSubmitLoader(false);
	}

	function fbImage(imageLink) {
		if (!imageLink) return imageLink;
		var query = imageLink.split("asid=")[1];
		if (!query) return imageLink;
		var fbId = query.split("&")[0];
		if (!fbId) return imageLink;

		return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
	}

	return (
		<>
			{/*<div
				className="contact-support"
				onClick={() => {
					setExpand(!expand);
				}}
			>
				<FontAwesomeIcon icon={expand ? faAngleDown : faHeadset} size="1x" />
			</div>*/}
			{expand && (
				<div className={"support-float thread" + (expand ? " expanded" : "")}>
					<Modal.Header
						closeButton={false /*expand*/}
						onClick={() => {
							//setExpand(!expand);
							props.close()
						}}
					>
						<Modal.Title className="h6">{expand && "New Message"}</Modal.Title>
						{expand && "X"}
					</Modal.Header>
					{showThread && <Modal.Body id="ticket-thread">
						{formValue.user && (
							<div
								id="user-info"
								style={{
									transition: ".3s",
									display: "none",
									left: "-400px",
									position: "fixed",
									top: "0",
									padding: "2em",
									background: "beige",
									borderBottomRightRadius: "2em",
									border: "1px solid #aaa",
								}}
							>
								<div
									style={{ fontSize: ".9em", opacity: ".5", padding: ".5em 0" }}
								>
									<FontAwesomeIcon
										icon={faInfoCircle}
										size="1x"
										className=""
										style={{ opacity: ".3" }}
									/>{" "}
									user info{" "}
								</div>
								<div>
									{/*<img src={fbImage(formValue.user.image)} style={{maxWidth:'60px', maxHeight:'60px'}}/>*/}
									{formValue.user.name}
								</div>
								<div>{formValue.user.email}</div>
								<div>
									{formValue.user.dielCode}
									{formValue.user.mobileNumber}
								</div>

								<br />
								{formValue.user.deviceDetails && (
									<>
										<div
											style={{
												fontSize: ".9em",
												opacity: ".5",
												padding: ".5em 0",
											}}
										>
											<FontAwesomeIcon
												icon={faInfoCircle}
												size="1x"
												className=""
												style={{ opacity: ".3" }}
											/>{" "}
											device info{" "}
										</div>
										<div>
											{formValue.user.deviceDetails.brand} (
											{formValue.user.deviceDetails.version})
										</div>
									</>
								)}
							</div>
						)}
                        {loader ? <Spinner animation="border" size={"sm"} role="status" style={{margin: '100px auto', display: 'block'}} /> : 
						<Form className="form">
							{formValue.messages && formValue.messages.length
								? formValue.messages.map((message) => (
										<Form.Group
											key={message.id}
											className={
												message.isSupport
													? "support-msg from-support"
													: "support-msg"
											}
											//style={!message.isSupport?
											//{borderRadius: '1em', background:'#e0e0e0', padding:'1em', marginRight:'3em'} :
											//{borderRadius: '1em', background:'#42A2F9', padding:'1em', marginLeft:'3em', color: '#fff'}}
										>
											<div className="datetime">
												{moment
													.utc(message.datetime)
													.fromNow()
													.replace("a few seconds ago", "now")}
											</div>
											<div>{message.message}</div>
											{message.image && (
												<img
													src={message.image}
													style={{ maxWidth: "100%", marginTop: "1em" }}
												/>
											)}
										</Form.Group>
								  ))
								: null}
						</Form>}
					</Modal.Body>}
					<Modal.Footer>
						<Form
							className="form"
							onSubmit={(e) => {
								submitForm(e);
							}}
						>
							<div style={{ display: "flex" }}>
								<div style={{ width: '100%'}}>
								<Form.Control
									as="textarea"
									rows="1"
									required
									style={{ borderColor: "#eee", borderRadius: "10px" }}
									placeholder={"title"}
									value={message.title}
									onChange={(e) => {
										const clone = _.clone(message)
										clone.title = e.target.value
										setMessage(clone);
									}}
								/>
								<Form.Control
									as="textarea"
									rows="2"
									required
									style={{ borderColor: "#eee", borderRadius: "10px", marginTop: '5px' }}
									placeholder={"body"}
									value={message.body}
									onChange={(e) => {
										const clone = _.clone(message)
										clone.body = e.target.value
										setMessage(clone);
									}}
								/>
								</div>

								<input
									ref={fileInput}
									onChange={handleFileUpload}
									type="file"
									style={{ display: "none" }}
									multiple={false}
								/>

								<Button
									variant="light"
									className="float-left"
									onClick={() => fileInput.current.click()}
									style={{ border: "1px solid #ddd", width: '20%' }}
								>
									<>
										{imagePreview? <img src={imagePreview} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>:
										<FontAwesomeIcon icon={faImage} size="1x" />
										}
									</>
								</Button>

								<Button
									variant="light"
									type="submit"
									disabled = {!(message.title.length && message.body.length)}
									className={
										"float-right submit-button" +
										(message.title.length && message.body.length
											? " show wide"
											: " show")
									}
								>
									{isSubmitLoader ? (
										<Spinner animation="border" size={"sm"} role="status" />
									) : (
										<>
											<FontAwesomeIcon
												icon={faPaperPlane}
												size="1x"
												className=""
											/>
										</>
									)}
								</Button>
							</div>


                            <div style={{maxWidth: 'initial', whiteSpace: 'normal', paddingTop: '2em', fontSize: '.8em', marginTop: '0px'}} 
                                >
                                <span>
                            		{Object.keys(types).map(value => <span 
                                        className={"plan-tag"+(message.type == value?' selected':'')} 
                                        style={{
                                        	background: message.type == value? 'brown': '#ddd',
                                        	cursor: 'pointer', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', display: 'inline-block'}} 
                                        onClick={(e)=>{

											const clone = _.clone(message)
											clone.type = value
											clone.replyable = types[value].replyable
											setMessage(clone);
										}}>
                                        {value} 
                                	</span>)}
                            	</span>
                            </div>
						</Form>
					</Modal.Footer>
				</div>
			)}
		</>
	);
}

export default Thread;
