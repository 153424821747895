import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Form,
    Modal,
    ButtonToolbar,
    Table,
    DropdownButton,
    Dropdown,
    Spinner,
    Breadcrumb
} from "react-bootstrap";
import Masonry from 'react-masonry-css'

import firebase from 'firebase/compat/app';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faFilter, faPencilAlt, faCopy, faTrashAlt, faArrowUp, faArrowDown, faUndo, faCheck, faTimes, faHeadset, faArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { confirm } from "../../components/Confirmation";
import { firestore } from "../../services/firebase";
import AddPromocode from '../../components/AddPromocode';
import * as _ from "lodash";
import DeleteAlert from '../../components/DeleteAlert';
import moment from 'moment';
import { NavLink, useHistory } from "react-router-dom";

library.add(faPlus, faFilter);

export function Receipts() {
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [receiptsLoader, setReceiptsLoader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');
    const [sort, setSort] = useState({
        field: 'datetime',
        direction: 'desc'
    });

    const tableHeaders = [{
        name: 'Name',
        filterField: 'name',
    //}, {
    //    name: '',
    //    filterField: 'none',
    }, {
        name: 'Date',
        filterField: 'datetime',
    }, {
        name: 'Type',
        filterField: 'type',
    }, {
        name: 'Kcal',
        filterField: 'calories',
    //}, {
    //    name: 'Actions',
    //    filterField: 'none',
    }];
    const [transactions, setTransactions] = useState([]);
    const [receipts, setReceipts] = useState([]);
    const [promocodeData, setPromocodeData] = useState({});
    const [filterData, setFilterData] = useState({});
    const [showPromocode, setShowPromocode] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [promocodeIdToName, setPromocodeIdToName] = useState();
    const [challengeIdToName, setChallengeIdToName] = useState();

    useEffect(() => {
        const ReceiptsSubscription = getReceipts();
        return () => {
            ReceiptsSubscription();
        };
    }, []);


    function getReceipts() {
        setReceiptsLoader(true);
        return firestore('receipts')
            .limit(500)
            //.where('nutritionist', 'in', ['pending', 'approved', 'rejected'])
            .orderBy('datetime', 'desc')
            .onSnapshot((querySnapshot) => {
                var promocodeIdToName = {}
                let data = []
                var receiptsBy = {}
                querySnapshot.docs.map((documentSnapshot, i) => {
                    var unique = (documentSnapshot.data().challengeId || documentSnapshot.data().orderId) + documentSnapshot.data().userId //+ documentSnapshot.data().image
                    if(receiptsBy[unique]) return
                    receiptsBy[unique] = true
                    data.push({
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    });
                });

                setReceiptsLoader(false);
                //data.map(d => {if(d.nutritionistDatetime) d.datetime = d.nutritionistDatetime; return d})
                setReceipts(data);
            });
    }

    async function addUser(userId, userName, challengeId, challengeName, oneToOne, cb){

        firestore("users").doc(userId)
            //.where("challengeId", "==", challengeId)
            //.where("userId", "==", userId)
            .get().then(function(doc){

                if(doc.data().joinedChallengeIds && doc.data().joinedChallengeIds.includes(challengeId)) return alert('already In');
                //if(doc.data().joinedOneToOneIds && doc.data().joinedOneToOneIds.includes(challengeId)) return alert('already In');


                var months;
                var rounds;
                var amount;

                if(oneToOne){

                    var msg = 'please enter no. of months'+'\n\n'
                    const values = [1, 2, 3]

                    msg += values.map((doc, i) => String(i+1)+' '+' month'+(i>=1?'s':'')+' ('+((i+1)*4)+ ' weeks)').join('\n')

                    months = parseInt(prompt(msg, '1'));

                    if(!values.includes(months)) return alert('entered value is not available, \nplease choose one of the values : '+values.join(', '))
                }
                else{

                    var msg = 'please enter no. of rounds'+'\n\n'
                    const values = [1, 2, 3, 6, 12]

                    msg += values.map((doc, i) => /*String(i+1)*/doc+' '+' round'+(i>=1?'s':'')).join('\n')

                    rounds = parseInt(prompt(msg, '1'));

                    if(!values.includes(rounds)) return alert('entered value is not available, \nplease choose one of the values : '+values.join(', '))
                }

                amount = parseInt(prompt('please enter amount in (EGP)', '0'));

                if(isNaN(amount)) return alert("no amount")


                doc.ref.update(
                    !oneToOne?
                    {challengeId: challengeId, joinedChallengeIds: firebase.firestore.FieldValue.arrayUnion(challengeId)} :
                    {challengeId: challengeId, joinedOneToOneIds: firebase.firestore.FieldValue.arrayUnion(challengeId), 
                        [`joinedOneToOne.${challengeId}.from`]: firebase.firestore.Timestamp.fromDate(moment().toDate()),
                        [`joinedOneToOne.${challengeId}.to`]: firebase.firestore.Timestamp.fromDate(moment().add(+months*4, 'weeks').add(2, 'days').toDate())
                    }
                    ).then(()=>{

                    doc.ref.collection('selectedChallenges').doc(challengeId).set({joinDate: new Date().toISOString()})
                        .then(function(doc) {


                            var payment = {

                                provider: 'vodafone',
                                datetime: new Date().toISOString(),
                                created: firebase.firestore.FieldValue.serverTimestamp(),
                                userId: userId,
                                challengeId: challengeId,
                                amount: amount || 0,//request.body.data.object.amount / 100,
                                currency: 'EGP',//request.body.data.object.currency.toUpperCase(),
                            };

                            if(rounds){

                                payment.rounds = +rounds;
                                payment.roundsJoined = [challengeId];
                            }

                            if(months){

                                payment.months = +months;
                            }

                            firestore("payments").add(payment);


                            alert(userName+' joined '+challengeName)

                            cb()

                            return
                        })
                        .catch(function(error) {
                            
                            console.error("Error finding/updating user document: ", error);
                        });
                })

            return
        })
        .catch(function(error) {

            console.error("Error finding user: ", error);
        });
    }

    async function removeUser(userId, userName, challengeId, challengeName, oneToOne, cb){

        firestore("users").doc(userId)
            //.where("challengeId", "==", challengeId)
            //.where("userId", "==", userId)
            .get().then(async function(doc){

                if(
                    (!doc.data().joinedChallengeIds || !doc.data().joinedChallengeIds.includes(challengeId)) &&
                    (!doc.data().joinedOneToOneIds || !doc.data().joinedOneToOneIds.includes(challengeId))
                ){
                    console.log('user is not in the challenge'); 
                    return cb();
                }


                if (await confirm(
                    'remove user',
                    'kickout '+userName+' of '+ challengeName
                    , 'kickout')){


                    doc.ref.update(
                        !oneToOne?
                        {challengeId: firebase.firestore.FieldValue.delete(), joinedChallengeIds: firebase.firestore.FieldValue.arrayRemove(challengeId)} :
                        {challengeId: firebase.firestore.FieldValue.delete(), joinedOneToOneIds: firebase.firestore.FieldValue.arrayRemove(challengeId), [`joinedOneToOne.${challengeId}`]: firebase.firestore.FieldValue.delete()}
                        ).then(()=>{

                        doc.ref.collection('selectedChallenges').doc(challengeId).delete()
                            .then(function(doc) {

                                //alert(userInfo.name+' kicked out of challenge '+challenge.name)

                                return
                            })
                            .catch(function(error) {
                                
                                console.error("Error finding/updating user document: ", error);
                            });
                    })


                    //refund for cash payments 
                    firestore("payments")
                        .where("challengeId", "==", challengeId)
                        .where("userId", "==", userId)
                        .get().then(function(docs){

                            var payments = []

                            docs.forEach(function(doc){

                                if(!doc.data().declined) payments.push({ ...doc.data(), key: doc.id })
                            })

                            if(!payments.length) alert('no payments for this user')
                            else{

                                if(payments.length>1) alert('there are several payments for this user')

                                payments.map(function(payment){

                                    if(payment.provider === 'vodafone'){

                                        firestore("payments").doc(payment.key).update({refunded: true})

                                        alert('it is a vodafone payment, payment has been flagged as "refunded" at billing, its amount will not be counted')
                                    }
                                    //if(payment.provider === 'apple') alert('it is an apple payment, please let the user request the refund themselves from apple portal')
                                    //else prompt("refund at stripe, link : ", 'https://dashboard.stripe.com/search?query='+payment.userId+'%20'+payment.challengeId);
                                })
                            }

                            cb();

                        return
                    })
                    .catch(function(error) {

                        console.error("Error finding payment: ", error);
                    });
                }

            return
        })
        .catch(function(error) {

            console.error("Error finding user: ", error);
        });
    }

    async function archive(d){

        if (await confirm(
            'Archive'+' '+d.userName.split(' ')[0]+"'s receipt",
            'are you sure you want to '+'archive'+' '+d.userName.split(' ')[0]+"'s receipt ?"
            , 'archive')){

            await firestore('receipts').doc(d.key).update({status: 'archived'});
        }
    }

    async function approve(d){

        if (await confirm(
            'Approve'+' '+d.userName.split(' ')[0]+"'s receipt",
            'are you sure you want to '+'approve'+' '+d.userName.split(' ')[0]+"'s receipt ?"
            , 'approve')){

            if(d.boxes){

                var msg = 'please enter no. of boxes'+'\n\n'
                var values = [1, 2, 4]
                msg += values.map((doc, i) => String(doc)+' '+' box'+(i>=1?'es':'')).join('\n')
                var boxes = parseInt(prompt(msg, d.boxes));
                if(!values.includes(boxes)) return alert('entered value is not available, \nplease choose one of the values : '+values.join(', '))

                var values = ['Standard', 'Keto']
                var choice = values[parseInt(prompt('please enter box type'+'\n\n'+values.map((d, i) => `${i+1}. ${d}`).join('\n'), values.indexOf(d.boxType)+1 )) - 1];
                if(!values.includes(choice)) return alert('entered value is not available, \nplease choose one of the values : '+ values.map((d, i)=>`${i+1} for ${d}`).join(', '))
                var boxType = choice
            
                var amount = parseInt(prompt('please enter amount in (EGP)', d.amount));
                if(isNaN(amount)) return alert("no amount")


                var payment = {

                    provider: 'vodafone',
                    datetime: new Date().toISOString(),
                    userId: d.userId,
                    amount: amount,
                    currency: d.currency,
                    boxes : boxes,
                    boxType : boxType,
                    orderId : d.orderId,
                };

                var payments = await firestore("payments").where("orderId", "==", d.orderId).where("userId", "==", d.userId).get()

                if(!payments.size) await firestore("payments").add(payment);

                await firestore('receipts').doc(d.key).update({status: 'approved'});

                //send reply message from support
                var newMessage = {}
                newMessage.message = "أهلاً بيك يا فندم 🌺\r\nحضرتك دلوقتي طلبت ويلنس بوكس بنجاح 💪🏻\r\nسعداء إنك معانا في ويلنس و موجودين معاك دايماً لو عندك أي استفسار!"
                newMessage.isSupport = true;
                newMessage.datetime = moment.utc().format();
                newMessage.created = firebase.firestore.FieldValue.serverTimestamp();
                await firestore('users_support').doc(d.userId).collection('messages').add(newMessage);

                return
            }

            addUser(d.userId, d.userName, d.challengeId, d.challengeName, d.oneToOne, async()=>{

                await firestore('receipts').doc(d.key).update({status: 'approved'});

                //send reply message from support
                var newMessage = {}
                newMessage.message = "أهلاً بيك يا فندم 🌺\r\nحضرتك دلوقتي اشتركت معانا في البرنامج بنجاح 💪🏻\r\nسعداء إنك معانا في ويلنس و موجودين معاك دايماً لو عندك أي استفسار!"
                newMessage.isSupport = true;
                newMessage.datetime = moment.utc().format();
                newMessage.created = firebase.firestore.FieldValue.serverTimestamp();
                await firestore('users_support').doc(d.userId).collection('messages').add(newMessage);
            })
        }
    }

    async function reject(d){

        if (await confirm(
            'Reject'+' '+d.userName.split(' ')[0]+"'s receipt",
            'are you sure you want to '+'reject'+' '+d.userName.split(' ')[0]+"'s receipt ?"
            , 'reject')){

            if(d.boxes){

                var payments = await firestore("payments").where("orderId", "==", d.orderId).where("userId", "==", d.userId).get()

                payments.forEach(function(doc){

                    doc.ref.delete()
                })

                await firestore('receipts').doc(d.key).update({status: 'rejected'});

                //send reply message from support
                var newMessage = {}
                newMessage.message = "عذرا برجاء إرسال صورة إيصال الدفع واضحة وصحيحة 🙏"
                newMessage.isSupport = true;
                newMessage.datetime = moment.utc().format();
                newMessage.created = firebase.firestore.FieldValue.serverTimestamp();
                await firestore('users_support').doc(d.userId).collection('messages').add(newMessage);

                return
            }

            removeUser(d.userId, d.userName, d.challengeId, d.challengeName, d.oneToOne, async()=>{
            
                await firestore('receipts').doc(d.key).update({status: 'rejected'});

                //send reply message from support
                var newMessage = {}
                newMessage.message = "عذرا برجاء إرسال صورة إيصال الدفع واضحة وصحيحة 🙏"
                newMessage.isSupport = true;
                newMessage.datetime = moment.utc().format();
                newMessage.created = firebase.firestore.FieldValue.serverTimestamp();
                await firestore('users_support').doc(d.userId).collection('messages').add(newMessage);
            })
        }
    }

    return (
        <>
            <Breadcrumb className="pb-3">
                <Breadcrumb.Item active>Receipts</Breadcrumb.Item>
            </Breadcrumb>
            <div className="pt-5">
                <Card style={{borderRadius:"initial",padding:"0"}}>
                    <Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            Receipts ({receipts.length})
                            {/*<Button size='sm' variant="info" className="text-uppercase" onClick={() => {
                                addMealPlan();
                            }}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />Add promo code
                            </Button>*/}
                        </h3>
                    </Card.Header>
                    <Card.Body style={{overflow:"auto",padding:"0.75em"}}>

                        {receiptsLoader ? <div className="text-center" style={{padding: '100px'}}><Spinner animation="border" size={'lg'} role="status" style={{height: '100px', width: '100px'}}/></div> : 
                            <table id="receipts" class="table components" style={{fontSize: '1em', marginTop: '2em', marginBottom: '0'}}>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                {/*<th>Title</th>*/}
                                <th>Nutritionist</th>
                                <th>Item</th>
                                <th>Amount</th>
                                <th>Receipt</th>
                                <th>Status</th>
                                <th colSpan="4" style={{textAlign: 'right'}}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {receipts.map(d => <tr style={(d.status === 'approved' || d.status === 'rejected' || d.status === 'archived')?{color: '#bbb', height: '50px'}:{height: '50px'}}>
                                <td>{d.datetime.split('T')[0]}</td>
                                <td>{d.userName}</td>
                                {/*<td className="request-title">{d.type}</td>*/}
                                <td>{d.nutritionistName}</td>
                                <td>{d.boxes? `${d.boxes} ${d.boxType} boxes` : d.challengeName}</td>
                                <td>{d.amount && `${d.amount} ${d.currency}`}</td>
                                <td className="request-certificates"><img src={d.image} height="auto" alt="" style={{height:'50px', cursor: 'pointer', marginRight: '7px', borderRadius: '5px'}} onClick={()=>window.open(d.image)}/></td>
                                <td style={d.status === 'approved'?{color: 'green'}:d.status === 'rejected'?{color: 'red'}:d.status === 'archived'?{color: '#bd8a2d'}:{color: 'rgb(187, 187, 187)'}}>{d.status}</td>
                                <td style={{paddingRight: '1em', paddingLeft: '1em'}}><NavLink target="_blank" key={'/support'} to={'/support/'+d.userId}><span style={{cursor: 'pointer', opacity:'.5'}} className="contact"><FontAwesomeIcon style={{color: '#aaa'}} icon={faHeadset} /></span></NavLink></td>
                                <td style={d.status === 'archived'?{textAlign: 'right', color: '#bd8a2d', pointerEvents: 'none'}:{opacity:0.1, transition: '.3s', textAlign: 'right', color: '#bd8a2d', cursor: 'pointer'}} className="remove" onClick={()=>{archive(d)}}><FontAwesomeIcon icon={faArchive} /></td>
                                <td style={d.status === 'rejected'?{textAlign: 'right', color: 'red', pointerEvents: 'none'}:{opacity:0.1, transition: '.3s', textAlign: 'right', color: 'red', cursor: 'pointer'}} className="remove" onClick={()=>{reject(d)}}><FontAwesomeIcon icon={faTimes} /></td>
                                <td style={d.status === 'approved'?{textAlign: 'right', color: 'green', pointerEvents: 'none'}:{opacity:0.1, transition: '.3s', textAlign: 'right', color: 'green', cursor: 'pointer'}} className="remove" onClick={()=>{approve(d)}}><FontAwesomeIcon icon={faCheck} /></td>
                            </tr>)}
                        </tbody>
                        </table>}

                    </Card.Body>
                </Card>
                {/*<Modal show={showPromocode} onHide={handleCloseMealPlan}>
                    <AddPromocode handleClose={handleCloseMealPlan} promocodeData={promocodeData} Receipts={Receipts} />
                </Modal>*/}
            </div>
            {/*<Modal show={showDeleteModal} onHide={handleDeleted}>
                <DeleteAlert onHide={handleDeleted} title={'Delete Meal Plan'} message={'Are you sure you want to delete meal plan?'} warning={'All the related recipes will be unassigned.'} docId={docId} collectionName={'meal_plans'} />
            </Modal>*/}
        </>
    )
}
