

import React, {useEffect, useState} from 'react';
import { Spinner, Breadcrumb, Image, Button, ToggleButton } from "react-bootstrap";
import Chart from "react-apexcharts";
import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file



function numberWithCommas(x) {
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

var barTotal = {}
const initData = {
  
    series: [/*{
      name: 'Marine Sprite',
      data: [44, 55, 41, 37, 22, 43, 21]
    }, {
      name: 'Striking Calf',
      data: [53, 32, 33, 52, 13, 43, 32]
    }, {
      name: 'Tank Picture',
      data: [12, 17, 11, 9, 15, 11, 20]
    }, {
      name: 'Bucket Slope',
      data: [9, 7, 5, 8, 6, 9, 4]
    }, {
      name: 'Reborn Kid',
      data: [25, 12, 19, 32, 25, 24, 10]
    }*/],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      stroke: {
        width: 1,
        colors: ['#464646']
      },
      //title: {
      //  text: 'Challenges'
      //},
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'center', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return (val/1000).toFixed(0)//+'k'
          },
          //offsetY: -20,
          style: {
            fontWeight: 'bold',
            fontSize: '9px',
            colors: ["#304758"]
          }
        },
      xaxis: {
        categories: [/*2008, 2009, 2010, 2011, 2012, 2013, 2014*/],
        labels: {
          //formatter: function (val) {
          //  return val + "K"
          //}
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        x: {
          //formatter: function (val) {
          //  return val + ' ..... ' + numberWithCommas(barTotal[val].toFixed(0))
          //}
        },
        y: {
          formatter: function (val) {
            return numberWithCommas(val.toFixed(0))
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
    },
  
  
  };



export function Customers(props) { 

    const [uniqueUsers, setUniqueUsers] = useState(false);
    const [users, setUsers] = useState(false);
    const [monthly, setMonthly] = useState(false);
    const [days, setDays] = useState([]);
    const [data, setData] = useState(initData);
    const [total, setTotal] = useState(0);
    const [loader, setLoader] = useState(true);
    const [payments, setPayments] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [challengeById, setChallengeById] = useState({});
    const [challengeNutritionist, setChallengeNutritionist] = useState({});
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [challengesLoading, setChallengesLoading] = useState(true);
    const [format, setFormat] = useState('MMM DD');  //YYYY-MM-DD
    const [formatMonth, setFormatMonth] = useState('MMM');  //YYYY-MM-DD
    const [nutritionists, setNutritionists] = useState({
      options: {},
      series: [],
    });


    const [selectionRange, setSelectionRange] = useState({
      startDate: monthly? moment('2020-04-01').toDate() : moment().subtract(45, "days").startOf('day').toDate(), //moment('2020-04-19').toDate()
      endDate: new Date(),
      key: 'selection',
    });
    const [preSelectionRange, setPreSelectionRange] = useState({
      startDate: monthly? moment('2020-04-01').toDate() : moment().subtract(45, "days").startOf('day').toDate(), //moment('2020-04-19').toDate()
      endDate: new Date(),
      key: 'selection',
    });

    useEffect(() => {

        setSelectionRange({
          startDate: monthly? moment('2020-04-01').toDate() : moment().subtract(45, "days").startOf('day').toDate(),
          endDate: new Date(),
          key: 'selection',
        })
        setPreSelectionRange({
          startDate: monthly? moment('2020-04-01').toDate() : moment().subtract(45, "days").startOf('day').toDate(),
          endDate: new Date(),
          key: 'selection',
        })

        return () => { }

    }, [monthly]);

    useEffect(() => {

        //getChallenges();

    }, []);

    useEffect(() => {

        setLoader(true)
        var unsubscribes = getCollections();

        return () => {

            console.log('Do some cleanup');
            //unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, [/*challengeById, selectionRange, monthly, users, uniqueUsers*/]);


    function getChallenges() {
        setChallengesLoading(true);
        return firestore('challenges')
            .get().then((querySnapshot) => {
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id,
                    };
                });
                //_.orderBy(groups, 'userCount', 'desc')
                //data = _.orderBy(data.filter(d => d.isPublished && !d.isFree), 'price', 'desc')

                var include = []
                data.map(d => {if(d.isPublished && !d.isFree && d.previousRounds) include = include.concat(d.previousRounds); return d})

                data = _.orderBy(data.filter(d => (d.isPublished || include.includes(d.key)) && !d.isFree), item => moment.utc(item.date).format(), 'desc')
                
                var challengeById = {}, challengeNutritionist = {}
                data.map(d => {

                    challengeById[d.key] = d
                    challengeNutritionist[d.key] = d.nutritionistName
                })
                setChallengeById(challengeById)
                setChallengeNutritionist(challengeNutritionist)

                setChallenges(data);
                setChallengesLoading(false);
                //if (data && data.length && data[0].key) {
                //    setSelectedChallenge(data[0].key)
                //}
            }, error => {
                console.log('error', error)
            });
    }


    function getCollections(){

        setPaymentsLoading(true)

        const subscribePayments = firestore("users")//.orderBy('datetime', 'asc')
            //.where('joinedChallengeIds', "array-contains-any", [])
            //.where('datetime', '>', moment(selectionRange.startDate).format())
            //.where('datetime', '<', moment(selectionRange.endDate).format())  //ignore to enable relatime updates
            //.onSnapshot(function(snapshot){
            .get().then(function(snapshot){

            const users = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            var total = 0
            var paymentsByDay = {}
            var paymentsByChallenge = {}
            var paymentsByNutritionist = {}

            var userIds = {}

            var usersByChallenges = {}

            users.map((d) => {

              if(d.role && d.role.length){

                if(d.role.includes('admin')) return
                if(d.role.includes('nutritionist')) return
                if(d.role.includes('collaborator')) return
                if(d.role.includes('assistant')) return
              }

                var nChallenges = (d.joinedChallengeIds && d.joinedChallengeIds.length)? d.joinedChallengeIds.length : 0

                if(!usersByChallenges[nChallenges]) usersByChallenges[nChallenges] = 0;
                usersByChallenges[nChallenges] ++
            })


            console.log(usersByChallenges)


            setNutritionists({...nutritionists, ...{
              options: {
                labels: Object.keys(usersByChallenges).map(d => d+' challenges'),
                tooltip: { y: { formatter: val => numberWithCommas(val.toFixed(0)) + ' users' }},
                legend: { formatter: val => val + ' : ' + numberWithCommas(usersByChallenges[val.split(' ')[0]].toFixed(0)) + ' users' }
              },
              series: Object.keys(usersByChallenges).map(d => usersByChallenges[d])
            }})

            setPaymentsLoading(false)

        })

        return //[subscribePayments]
    }

    const getSeriesDataSumByCategoryIndex = (series, categoryIndex) => {
      return series.reduce((acc, cur) => acc + (cur.data[categoryIndex] || 0), 0);
    };
    const addAnnotations = (series, categories) => {

      try {
        var points = []
        categories.forEach((category, index) => {
          const seriesDataSum = getSeriesDataSumByCategoryIndex(series, index);

          if(seriesDataSum) points.push(
            {
              y: seriesDataSum,
              x: category,
              label: {
                text: users? seriesDataSum.toString() : (seriesDataSum/1000).toFixed(0)+'k',
                offsetY: 10,
                style:{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  colors: ["#304758"],
                  border: 'none',
                  background: 'none'
                }
              }
            }
          );
        });
        return {points: points}
      } catch (error) {
        console.log(`Add point annotation error: ${error.message}`);
      }
    };


   return (<>
        <Breadcrumb className="pb-3">
            <Breadcrumb.Item active>Customer lifetime value</Breadcrumb.Item>
            {total>0 && <div className="no-users">{numberWithCommas(+total.toFixed(0))}</div>}
        </Breadcrumb>
        <div className="pt-5 h-100 align-self-center">

            {paymentsLoading ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
            <div style={{position: 'relative', margin: '100px'}}>
                <div className="donut" style={{display: 'inline-block'}}>
                  <Chart options={nutritionists.options} series={nutritionists.series} type="donut" width="500" height="500" />
                </div>
            </div>}
        </div>
    </>);
}