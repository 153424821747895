/** @format */

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faClone,
	faEdit,
	faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import "./ChallengesComponents/ChallengesComponent.scss";
import _ from "lodash";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import altImage from "../assets/images/bg-icon.png";
import {
	Dropdown,
	DropdownButton,
	Form,
	FormControl,
	InputGroup,
} from "react-bootstrap";

export default function WorkoutCard({
	image,
	name,
	date,
	muscle,
	editExercise,
	cloneExercise,
	onDeleteClick,
	exercise,
	sideMenu,
	ind,
	index,
	item,
	inSuperSet,
	isSuperSet,
	setState,
	state,
	ex,
}) {
	const repsArr = [
		{ unit: "c", value: "reps" },
		{ unit: "s", value: "seconds" },
		{ unit: "m", value: "meters" },
	];

	let unitVal = "reps";
	if (ind && ind > 0) {
		if (item.unit !== undefined) {
			unitVal = repsArr.map((r) => {
				if (r.unit === item.unit) return r.value;
			});
		}
	}

	const [reps, setReps] = useState(unitVal);
	return (
		<>
			<div
				className={
					(ind > 0 ? "workout-plan-card" : null, "challenges-card recipes-card")
				}
				style={
					ind > 0
						? {
								display: "grid",
								gridTemplateColumns: "1fr 3fr",
								gap: "9px",
								backgroundColor: "#fff",
								border:
									isSuperSet[ind + "_" + index] || inSuperSet[ind + "_" + index]
										? "1px solid #909090"
										: "",
								borderBottom:
									inSuperSet[ind + "_" + index] &&
									!isSuperSet[ind + "_" + index]
										? "1px solid #909090"
										: "",

								borderRadius: isSuperSet[ind + "_" + index]
									? "0"
									: inSuperSet[ind + "_" + index]
									? "0 0 8px 8px"
									: "8px",
								marginTop:
									!inSuperSet[ind + "_" + index] &&
									isSuperSet[ind + "_" + index]
										? "63px"
										: "",
						  }
						: {}
				}
			>
				<div className="challenge-thumbnail exercise-image">
					<div
						style={{ backgroundImage: `url(${image || altImage})` }}
						className={ind > 0 ? "workout-thumbnail-plan" : ""}
					></div>
				</div>

				<div
					className="workout-container"
					style={!ind ? { justifyContent: "Center" } : {}}
				>
					<div
						className={(ind > 0 ? "workout-plan-name" : {}, "challenge-name")}
						// style={{ display: "flex", alignItems: "center" }}
						style={ind > 0 ? { display: "flex" } : {}}
						onClick={() => {
							if (!ind) editExercise(exercise);
						}}
					>
						<span>
							{" "}
							{ind > 0 && name.length > 14 ? name.slice(0, 14) + "..." : name}
						</span>
						{ind && ind > 0 ? (
							<div
								className="challenge-controls"
								style={{ margin: "0 0 0 auto" }}
							>
								<div
									className="remove-btn"
									onClick={() => {
										const newState = [...state];
										var clonedItem = _.clone(item);
										newState[ind].splice(index, 0, clonedItem);
										setState(newState);
									}}
								>
									<div
										className="circle"
										style={{ backgroundColor: "#0e5171" }}
									>
										<FontAwesomeIcon
											icon={faClone}
											className="remove"
											style={{ color: "#fff" }}
										/>
									</div>
								</div>

								<div
									className="remove-btn"
									onClick={() => {
										const newState = [...state];
										newState[ind].splice(index, 1);
										setState(
											newState //.filter((group,i) => i<newState.length-1 || group.length)
										);
									}}
								>
									<div className="circle">
										<FontAwesomeIcon icon={faTrashAlt} className="remove" />
									</div>
								</div>
							</div>
						) : (
							""
						)}
						{(ind === 0 || !ind) && (
							<div className="challenge-calories">
								<span style={{ display: "block" }}>
									{muscle ? `Muscle: ${muscle}` : ""}
								</span>
								<span>{date} </span>
							</div>
						)}
					</div>
					<div className="workout-details">
						{ind > 0 && (
							<div
								style={{
									display: inSuperSet[ind + "_" + index] ? "none" : "flex",
									color: "#222",
									textAlign: "right",
									width:
										!inSuperSet[ind + "_" + index] &&
										isSuperSet[ind + "_" + index]
											? "100.7%"
											: "27%",
									flexBasis: "23%",
									height: "fit-content",
									position:
										!inSuperSet[ind + "_" + index] &&
										isSuperSet[ind + "_" + index]
											? "absolute"
											: "relative",
									top: isSuperSet[ind + "_" + index] ? "-5.46em" : "",
									left: isSuperSet[ind + "_" + index] ? "-1px" : "",
									// backgroundColor: isSuperSet[ind + "_" + index]
									// 	? "#f0f0f0"
									// 	: "",
									padding:
										!inSuperSet[ind + "_" + index] &&
										isSuperSet[ind + "_" + index]
											? "1.2em"
											: "",
									borderRadius:
										!inSuperSet[ind + "_" + index] &&
										isSuperSet[ind + "_" + index]
											? "8px 8px 0 0"
											: "",
									marginTop:
										!inSuperSet[ind + "_" + index] &&
										isSuperSet[ind + "_" + index]
											? "5px"
											: "",
									border:
										!inSuperSet[ind + "_" + index] &&
										isSuperSet[ind + "_" + index]
											? "1px solid #909090"
											: "",

									justifyContent: isSuperSet[ind + "_" + index] ? "center" : "",
									alignItems: "baseline",
								}}
							>
								<div>
									{!inSuperSet[ind + "_" + index] &&
										isSuperSet[ind + "_" + index] && (
											<h5 style={{ fontWeight: "700", padding: "0 5px" }}>
												Sets{" "}
											</h5>
										)}
								</div>
								<Form.Control
									type={"number"}
									min="1"
									placeholder="1"
									className="exercise-sets"
									title="no of sets"
									style={{
										display: inSuperSet[ind + "_" + index] ? "none" : "flex",
										backgroundColor: isSuperSet[ind + "_" + index]
											? "#fff"
											: inSuperSet[ind + "_" + index]
											? "#fff"
											: "#f0f0f0",
										border: isSuperSet[ind + "_" + index]
											? "1px solid #9a9a9a"
											: inSuperSet[ind + "_" + index]
											? ""
											: "#f0f0f0",
										width: isSuperSet[ind + "_" + index] ? "20%" : "100%",
									}}
									onChange={(e) => {
										const newState = [...state];
										newState[ind][index].sets = +e.target.value;
										setState(newState);
									}}
									value={item.sets}
								/>

								<div
									style={{
										opacity: isSuperSet[ind + "_" + index] ? "0.9" : ".9",
										margin: "0 0px",
										position: isSuperSet[ind + "_" + index] ? "relative" : "",
									}}
								>
									<div
										className="icon"
										style={
											isSuperSet[ind + "_" + index]
												? {
														position: "",
														left: "",
														top: "",
														margin: "0 5px ",
												  }
												: {
														position: "absolute",
														left: "6px",
														top: "15%",
														margin: "",
												  }
										}
									>
										<FontAwesomeIcon
											icon={faTimes}
											size="xs"
											style={{ color: "#333" }}
										/>
									</div>
								</div>
							</div>
						)}
						{ind > 0 && (
							<div
								style={{
									flexBasis: "23%",
									display: isSuperSet[ind + "_" + index] ? "none" : "",
									order: inSuperSet[ind + "_" + index] ? "4" : "",
									marginLeft: inSuperSet[ind + "_" + index] ? "auto" : "",
								}}
							>
								<Form.Control
									style={{
										display: isSuperSet[ind + "_" + index] ? "none" : "flex",

										backgroundColor: "#f0f0f0",
										border: "none",
									}}
									className="exercise-rest"
									placeholder="0:0"
									title="rest between sets in seconds"
									onChange={(e) => {
										const newState = [...state];
										newState[ind][index].rest = e.target.value;

										setState(newState);
									}}
									value={item.rest || ""}
								/>
							</div>
						)}
						{ind > 0 && (
							<div style={{ flexBasis: "20%", width: "20%" }}>
								<InputGroup className="inputWithDropDown">
									<Form.Control
										style={{
											height: "2.3em",
											padding: "5px !important",
											border:
												inSuperSet[ind + "_" + index] ||
												isSuperSet[ind + "_" + index]
													? "1px solid #f0f0f0"
													: "none",
											backgroundColor: "#f0f0f0",
										}}
										value={item.reps}
										placeholder="1"
										onChange={(e) => {
											const newState = [...state];
											newState[ind][index].reps = e.target.value;
											setState(newState);
										}}
									/>
									<DropdownButton
										as={InputGroup.Append}
										style={{
											width: "4%",
											minWidth: "auto",
											height: "2.3em",
											borderColor: "#f0f0f0",
											borderRadius: "0 5px 5px 0",
										}}
										variant="outline-secondary"
										title={reps}
										size="xs"
										drop={"up"}
										id="input-group-dropṇṇdown-2"
									>
										{repsArr.map((r) => {
											return (
												<Dropdown.Item
													key={r.unit}
													onClick={() => {
														const newState = [...state];
														newState[ind][index].unit = r.unit;
														setState(newState);
														setReps(r.value);
													}}
													href="#"
												>
													{r.value}
												</Dropdown.Item>
											);
										})}
									</DropdownButton>
								</InputGroup>
							</div>
						)}
					</div>

					{!sideMenu && (
						<div className="add-meal-plan-card">
							<div className="challenge-controls">
								<div
									className="add-btn"
									onClick={() => {
										editExercise(exercise);
									}}
								>
									<div className="circle">
										<FontAwesomeIcon icon={faEdit} className="add" size="sm" />
									</div>
								</div>

								<div
									className="remove-btn"
									onClick={() => {
										cloneExercise(exercise);
									}}
								>
									<div className="circle">
										<FontAwesomeIcon icon={faClone} className="remove" />
									</div>
								</div>

								<div
									className="remove-btn"
									onClick={() => {
										onDeleteClick(exercise.key);
									}}
								>
									<div className="circle">
										<FontAwesomeIcon icon={faTrashAlt} className="remove" />
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
