

import React, {useEffect, useState} from 'react';
import { Spinner, Breadcrumb, Image, Button, ToggleButton, Col, Row, Form, Modal } from "react-bootstrap";
import Chart from "react-apexcharts";
import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";
import { Input } from '@material-ui/core';

import { confirm } from "../../components/Confirmation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMinus, faPlus, faFolder, faFolderPlus, faTrashAlt, faClone, faShare, faShareSquare, faArrowUp, faArrowDown} from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import AddRecipe from "../../components/AddRecipe";
import DeleteAlert from '../../components/DeleteAlert';


function numberWithCommas(x) {
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

var currentCategory

export function Recipes(props) { 

    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [recipes, setRecipes] = useState([]);
    const [categories, setCategories] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [itemForm, setItemForm] = useState();
    const [categoryForm, setCategoryForm] = useState();
    const [moveForm, setMoveForm] = useState();
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [searchKey, setSearchKey] = useState();
    const [allergies, setAllergies] = useState();
    const [selectedAllergies, setSelectedAllergies] = useState();

    const [levels, setLevels] = useState();
    const [selectedLevel, setSelectedLevel] = useState();
    const [muscles, setMuscles] = useState();
    const [selectedMuscle, setSelectedMuscle] = useState();
    const [types, setTypes] = useState();
    const [selectedType, setSelectedType] = useState();

    const [docId, setDocId] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [recipeData, setRecipeData] = useState({});
    const [show, setShow] = useState(true);
    const [recipeTypes, setRecipeTypes] = useState([]);
    const [recipeTags, setRecipeTags] = useState(['Affordable', 'Gourmet']);
    const [sort, setSort] = useState({
        field: 'datetime',
        direction: 'desc'
    });
    const tableHeaders = [{
        name: 'Type',
        filterField: 'type',
    }, {
        name: 'Recipe',
        filterField: 'name',
    }, {
        name: 'Calories',
        filterField: 'Calories',
    }, {
        name: 'Created',
        filterField: 'Created',
    }, {
        name: '',
        filterField: '',
    }];

    function sortBy(filterField) {

        let sortClone = _.clone(sort);

        if (sortClone.field === filterField) {
            sortClone.direction = sortClone.direction === 'asc' ? 'desc' : 'asc';
        } else {
            sortClone.field = filterField;
            sortClone.direction = 'asc';
        }

        var method = false

        if (sortClone.field == 'datetime') method = function (item) { return moment.utc(item.datetime).format() }
        if (sortClone.field == 'type') method = function (item) { return item['type'].toLowerCase() }
        if (sortClone.field == 'Calories') method = function (item) { return item['Calories'] ? parseFloat(item['Calories']) : 0 }

        if(method) sortClone.method = method

        setSort(sortClone);
    }


    const handleClose = () => {/*setShow(false); setRecipeData('')*/};
    const handleShow = () => setShow(true);

    useEffect(() => {

        currentCategory = selectedCategory

    }, [selectedCategory]);

    useEffect(() => {

        setLoader(true)
        var unsubscribes = getCollections();
        getRecipeTypes();

        return () => {

            console.log('Do some cleanup');
            unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);

    useEffect(() => {
        setTimeout(getFood, 0);
        return () => {
        };
    }, []);


    async function getRecipeTypes() {
        const recipeTypesDoc = await firestore('constants').doc('recipeTypes').get();
        setRecipeTypes(recipeTypesDoc.data().value);
    }

    function getFood(){

        firestore('food').get().then(function(snapshot){

            const items = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []
            setItems(_.sortBy(items, ["category", "food"]))
        })
    }


    function getCollections(){

        /*var insertItem = function(item){

            console.log(item)
            firestore("food").get(item)
        }

        food[0].map((d, i) => {

            var item = {
                allergies: d['Allergy tag']? d['Allergy tag'].split(', ') : [],
                calories_unit: d['Calories per gram or per ml'],
                calories_serving: d['Calories per serving'],
                category: d['Category'],
                category_ar: d['Category (AR)'],
                food: d['Food'],
                food_ar: d['Food (AR)'],
                serving: d['Serving'],
                unit: d['Unit'],
                unit_serving: d['gm or ml/ item'],
            }
            setTimeout(function(){ insertItem(item); }, i*1000)
        })
        console.log(food[0].length)
        */

        setLoading(true)

        const subscribe = firestore("recipes")
            .where('nutritionistId', '==', 'welnes')
            .onSnapshot(function(snapshot){
            //.get().then(function(snapshot){

            const items = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; obj.key = d.id; return obj}) : []

            var categories = {}, levels = {}, muscles = {}, types = {}
            items.map(d=> {
                //if(!categories[d.muscle]) categories[d.muscle] = true;
                //if(!levels[d.level]) levels[d.level] = true;
                //if(!muscles[d.muscle]) muscles[d.muscle] = true;
                if(!types[d.type]) types[d.type] = true;
            })

            //var allergies = {}
            //items.map(d=> {if(d.allergies) d.allergies.map(a => {if(!allergies[a]) allergies[a] = true})})
            //setAllergies(Object.keys(allergies).map(a => {return { value: a, label: a }}))

            setRecipes(_.sortBy(items, ["name"]))
            //setCategories(_.sortBy(Object.keys(categories).map(d => {return {category: d}}), "category"))
            //if(!currentCategory || !categories[currentCategory]) setSelectedCategory(_.sortBy(items, ["category"])[0].category)


            //setLevels(_.sortBy(Object.keys(levels)))
            //setMuscles(_.sortBy(Object.keys(muscles)))
            setTypes(_.sortBy(Object.keys(types)))

            setLoading(false)
        })

        return [subscribe]
    }

    async function key_up(e, d, field, field_title){
        var enterKey = 13; //Key Code for Enter Key
        if (e.which == enterKey){
            var food_name = d.food
            var org = d[field]
            d[field] = typeof d[field] === 'number'? +e.target.value : e.target.value
            console.log('change', food_name, field,'to', e.target.value)
            e.persist()
            if (await confirm(
                'Edit'+' '+'field',
                'are you sure you want to '+'change'+' '+food_name+' '+field_title+' to ' + e.target.value+' ?'
                , 'save')){

                var update = {}
                update[field] = field === 'allergies'? (e.target.value?e.target.value.split(', '):[]) : typeof d[field] === 'number'? +e.target.value : e.target.value
                await firestore('food').doc(d.id).update(update);
            }
            else{

                d[field] = org
            }
        }
    }

    async function addCategory(e){

        e.stopPropagation();
        e.preventDefault();

        var category = e.target.category.value//prompt("Please enter Category name"); if(!category) return
        var category_ar = e.target.category_ar.value//prompt("Please enter Category arabic name"); if(!category_ar) return
        var food = e.target.food.value//prompt("Please enter Ingredient name"); if(!food) return
        var food_ar = e.target.food_ar.value//prompt("Please enter arabic name"); if(!food_ar) return
        var serving = e.target.serving.value//prompt("Please enter serving", 'tsp, cup, etc..'); if(!serving) return
        var unit = e.target.unit.value//prompt("Please enter unit", 'g, ml, etc..'); if(!unit) return
        var unit_serving = e.target.unit_serving.value//prompt(unit+" per "+serving); if(!unit_serving) return
        var calories_unit = e.target.calories_unit.value//prompt("calories per "+unit); if(!calories_unit) return
        var calories_serving = e.target.calories_serving.value//prompt("calories per "+serving); if(!calories_serving) return
        var allergies = selectedAllergies//e.target.allergies.value//prompt("allergies (if any)");

        //if(!food) return alert('no name has been entered, discarding..')
        //if(isNaN(calories_unit)) return alert('calories per unit is not a number, discarding..')
        //if(isNaN(calories_serving)) return alert('calories per serving is not a number, discarding..')
        //if(isNaN(unit_serving)) return alert(unit+' per serving is not a number, discarding..')

        var item = {
            allergies: allergies?allergies.map(d => d.value):[],
            calories_unit: parseFloat(calories_unit),
            calories_serving: parseFloat(calories_serving),
            category: category.split(' ').map(d => d[0].toUpperCase()+d.slice(1)).join(' '),
            category_ar: category_ar,
            food: food.split(' ').map(d => d[0].toUpperCase()+d.slice(1)).join(' '),
            food_ar: food_ar,
            serving: serving,
            unit: unit,
            unit_serving: parseFloat(unit_serving),
        }

        if(recipes.filter(d => d.food === item.food).length) return alert('An ingredient with the same name already exists')

        var added = await firestore('food').add(item);

        var goToAddedEl = function(){

            var cat = document.getElementById(item.category)

            if(cat){

                setSelectedCategory(item.category)

                var div = document.getElementById(added.id)

                if(div){

                    div.classList.add("new");
                    window.scroll({top: div.offsetTop - 50, left: 0, behavior: 'smooth' });

                    setTimeout(function(){

                        div.classList.remove("new");

                    }, 3000)
                }
                else{

                    setTimeout(goToAddedEl, 1000)
                }
            }
            else{

                setTimeout(goToAddedEl, 1000)
            }
        }

        setCategoryForm(false)
        goToAddedEl()
    }

    async function addItem(e){

        e.stopPropagation();
        e.preventDefault();

        var food = e.target.food.value//prompt("Please enter Ingredient name"); if(!food) return
        var food_ar = e.target.food_ar.value//prompt("Please enter arabic name"); if(!food_ar) return
        var serving = e.target.serving.value//prompt("Please enter serving", 'tsp, cup, etc..'); if(!serving) return
        var unit = e.target.unit.value//prompt("Please enter unit", 'g, ml, etc..'); if(!unit) return
        var unit_serving = e.target.unit_serving.value//prompt(unit+" per "+serving); if(!unit_serving) return
        var calories_unit = e.target.calories_unit.value//prompt("calories per "+unit); if(!calories_unit) return
        var calories_serving = e.target.calories_serving.value//prompt("calories per "+serving); if(!calories_serving) return
        var allergies = selectedAllergies//e.target.allergies.value//prompt("allergies (if any)");

        //if(!food) return alert('no name has been entered, discarding..')
        //if(isNaN(calories_unit)) return alert('calories per unit is not a number, discarding..')
        //if(isNaN(calories_serving)) return alert('calories per serving is not a number, discarding..')
        //if(isNaN(unit_serving)) return alert(unit+' per serving is not a number, discarding..')

        var item = {
            allergies: allergies?allergies.map(d => d[0].toUpperCase()+d.slice(1)):[],
            calories_unit: parseFloat(calories_unit),
            calories_serving: parseFloat(calories_serving),
            category: selectedCategory,
            category_ar: categories.filter(d => d.category===selectedCategory)[0].category_ar,
            food: food.split(' ').map(d => d[0].toUpperCase()+d.slice(1)).join(' '),
            food_ar: food_ar,
            serving: serving,
            unit: unit,
            unit_serving: parseFloat(unit_serving),
        }

        if(recipes.filter(d => d.food === item.food).length) return alert('An ingredient with the same name already exists')

        var added = await firestore('food').add(item);

        var goToAddedEl = function(){

            var div = document.getElementById(added.id)

            if(div){

                div.classList.add("new");
                window.scroll({top: div.offsetTop - 50, left: 0, behavior: 'smooth' });

                setTimeout(function(){

                    item.id = added.id
                    item.key = added.id
                    setRecipeData(item);

                }, 1000)

                setTimeout(function(){

                    div.classList.remove("new");

                }, 3000)
            }
            else{

                setTimeout(goToAddedEl, 1000)
            }
        }

        setItemForm(false)
        goToAddedEl()
    }

    async function cloneItem(d){
        var food_name = d.name
        console.log('clone', food_name)

        var newName = prompt("Please enter new Recipe name", d.name + ' (cloned)'); if(!newName) return

        if(food_name === newName) return alert('An ingredient with the same name already exists')

        if (await confirm(
            'Clone'+' '+'Recipe',
            'create a clone of '+food_name+' named '+newName+' ?'
            , 'clone')){

            var item = _.clone(d)
            item.name = newName
            delete item.id

            var added = await firestore('recipes').add(item);

            var goToAddedEl = function(){

                var div = document.getElementById(added.id)

                if(div){

                    div.classList.add("new");
                    window.scroll({top: div.offsetTop - 50, left: 0, behavior: 'smooth' });

                    setTimeout(function(){

                        item.id = added.id
                        item.key = added.id
                        setRecipeData(item);

                    }, 1000)

                    setTimeout(function(){

                        div.classList.remove("new");

                    }, 3000)
                }
                else{

                    setTimeout(goToAddedEl, 1000)
                }
            }

            goToAddedEl()
        }
    }

    async function moveItem(e){

        e.stopPropagation();
        e.preventDefault();

        setMoveForm(false)

        var c = categories.filter(d => d.category === e.target.category.value)[0]//prompt("Please enter Ingredient name"); if(!food) return
        var d = _.clone(moveForm)

        var food_name = d.food
        console.log('move', food_name)

        //var category = categories[parseInt(prompt("Please select new category \n" + categories.map((c, i) => String(i+1)+'. '+c.category+' ('+c.category_ar+')').join('\n'), '1')) - 1];
        //if(!category) return

        if (await confirm(
            'Move'+' '+'Ingredient',
            'are you sure you want to '+'move'+' '+food_name+' to '+c.category+' ?'
            , 'move')){

            await firestore('food').doc(d.id).update({category: c.category, category_ar: c.category_ar});

            var goToAddedEl = function(){

                var cat = document.getElementById(c.category)

                if(cat){

                    setSelectedCategory(c.category)

                    var div = document.getElementById(d.id)

                    if(div){

                        div.classList.add("new");
                        window.scroll({top: div.offsetTop - 50, left: 0, behavior: 'smooth' });

                        setTimeout(function(){

                            div.classList.remove("new");

                        }, 3000)
                    }
                    else{

                        setTimeout(goToAddedEl, 1000)
                    }
                }
                else{

                    setTimeout(goToAddedEl, 1000)
                }
            }

            goToAddedEl()
        }
    }

    async function removeItem(d){
        var food_name = d.food
        console.log('remove', food_name)
        if (await confirm(
            'Remove'+' '+'Ingredient',
            'are you sure you want to '+'remove'+' '+food_name+' ?'
            , 'remove')){

            await firestore('food').doc(d.id).delete();
        }
    }

    function isNumberKey(evt){

        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 190) return evt.preventDefault();

        return true;
    }

    function filterFn(d){
        
        if(searchKey) return d.name.toLowerCase().includes(searchKey.toLowerCase()) 

        return (

            //(selectedLevel? d.level === selectedLevel : true) &&
            //(selectedMuscle? d.muscle === selectedMuscle : true) &&
            (selectedType? d.type === selectedType : true)
        )
    }


    async function onDeleteClick(key) {

        setDocId(key);
        setShowDeleteModal(true);
    }

    function editRecipe(recipe = {}) {

        setRecipeData(recipe);
        setShow(true);
    }

    function cloneRecipe(recipe = {}) {

        delete recipe.key
        setRecipeData(recipe);
        setShow(true);
    }

    return (<>

        <Breadcrumb className="pb-3">
            <Breadcrumb.Item active>Recipes</Breadcrumb.Item>

            {types &&
                <>
                    
                    <Breadcrumb.Item active>
                        <div className="selectWrapper" style={{position: 'absolute', top: '5px', marginLeft: '10px'}}>

                            <Form.Control required as="select" style={{cursor: 'pointer', width: 'auto', margin: '0 3px', display: 'inline-block', opacity: searchKey? 0.1 : 1, pointerEvents: searchKey? 'none' : 'auto'}}
                                defaultValue={''}
                                onChange={(e) => {
                                    setSelectedType(e.target.value);
                                }}>
                                <option value="" disabled>Type</option>
                                <option value={''} key={'all'} id={'all'}> -- All -- </option>
                                {types.map((obj, i) => {
                                    return (<option value={obj} key={obj} id={obj}>{obj}</option>)
                                })}
                            </Form.Control>

                            <Form.Control placeholder="search" style={{cursor: 'pointer', width: 'auto', display: 'inline-block', marginLeft: '10px'}}
                                value={searchKey}
                                onChange={(e) => {
                                    setSearchKey(e.target.value);
                                }}>
                            </Form.Control>

                            <span style={{width: 'auto', display: 'inline-block', marginLeft: '10px'}}>
                                ({recipes.filter(filterFn).length})
                            </span>
                        </div>
                    </Breadcrumb.Item>

                    <div className="no-users">
                        {/*<div style={{opacity: .8, cursor: 'pointer', display:'inline-block', marginRight:'30px'}} onClick={()=>{setSelectedAllergies(); setCategoryForm(true)}}>Add Category<FontAwesomeIcon style={{}} icon={faFolderPlus} className="ml-2"/></div>*/}
                        <div style={{opacity: .8, cursor: 'pointer', display:'inline-block'}} onClick={()=> editRecipe()}>Add Recipe<FontAwesomeIcon style={{}} icon={faPlus} className="ml-2"/></div>
                    </div>
                </>
            }

        </Breadcrumb>


        {loading? <div className="text-center" style={{paddingRight: '500px'}}><Spinner animation="border" size={'sm'} role="status" style={{marginTop: '10em'}}/></div> :
        <div style={{paddingRight: '500px'}}>
        <table id="transactions" className="table components" style={{marginTop: '5em'}}>
            <thead>
            <tr>

                {tableHeaders && tableHeaders.map((o, i) => {
                    return <th style={{ cursor: o.filterField? 'pointer' : 'default' }} key={i} onClick={() => o.filterField && sortBy(o.filterField)}
                        className="text-capitalize">{o.name} {sort && sort.field === o.filterField ?
                            <span style={{ position: 'absolute' }}>
                                <FontAwesomeIcon icon={sort.direction === 'asc' ? faArrowUp : faArrowDown} className="ml-1" />
                            </span> : ''}
                    </th>
                })}

                {/*
                <th title="recipe type">Type</th>
                <th title="recipe name">Recipe</th>
                <th title="recipe calories">Calories</th>
                <th></th>
                <th></th>*/}
            </tr>
            </thead>
            <tbody>
            {_.orderBy(recipes.filter(filterFn), sort.method || sort.field, sort.direction).map(d => <tr key={d.id} id={d.id} className="food-item" style={{cursor: 'pointer', fontWeight: recipeData.key === d.key? 'bold' : 'normal', background: recipeData.key === d.key? '#fafafa' : 'none'}} onClick={() => editRecipe(d)}>

                <td style={{opacity:0.6}}>{d.type}</td>
                <td><img src={d.image} width="40px" height="40px" style={{float: 'left', marginRight: '10px', borderRadius: '10px', objectFit: 'cover'}}/><span style={{lineHeight: '40px'}}>{d.name}</span></td>
                <td style={{fontFamily: 'cursive', opacity: .7}}>{d.Calories || d.calories}</td>
                {d.datetime && <td style={{fontFamily: 'cursive', opacity: .7}}>{moment(d.datetime).format('HH:MM:SS DD/MM/YYYY') || 'noDate'}</td>}
                <td style={{opacity:0.1, transition: '.3s'}} className="remove" onClick={()=>{cloneItem(d);}}><FontAwesomeIcon icon={faClone} /></td>
                {/*<td style={{opacity:0.1, transition: '.3s'}} className="remove" onClick={()=>{setMoveForm(d)}}><FontAwesomeIcon icon={faShareSquare} /></td>*/}
                <td style={{opacity:0.1, transition: '.3s'}} className="remove" onClick={()=>{onDeleteClick(d.key);}}><FontAwesomeIcon icon={faTrashAlt} /></td>
            </tr>)}
        </tbody>
        </table>
        </div>}

        <Modal show={itemForm} onHide={() => setItemForm(false)} size="sm">

            <Form style={{padding: '5%', fontSize: '.9em'}} onSubmit={(e) => {addItem(e)}} className="food-form">

                <label style={{display: 'block', width: '100%', color: '#777'}}>Ingredient
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="food" placeholder="Apple"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Ingredient (Arabic)
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="food_ar" placeholder="تفاحة"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Serving
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="serving" placeholder="Cup, tbsp, item .. etc."/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Unit
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="unit" placeholder="g or ml"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many g/ml per serving
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="unit_serving" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many calories per g/ml
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="calories_unit" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many calories per serving?
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="calories_serving" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Allergies (if any)
                    {/*<Input style={{display: 'block', width: '100%'}} name="allergies"/>*/}
                    <Select options={allergies} className="select" isMulti placeholder="none" value={selectedAllergies} onChange={(e) => {setSelectedAllergies(e)}}/>
                </label>

                <Button variant="info" type="submit" className="float-right submit-button" style={{display: 'block', width: '100%'}}>
                    {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : 'Save'}
                </Button>
            </Form>
        </Modal>

        <Modal show={categoryForm} onHide={() => setCategoryForm(false)} size="sm">

            <Form style={{padding: '5%', fontSize: '.9em'}} onSubmit={(e) => {addCategory(e)}} className="food-form">

                <label style={{display: 'block', width: '100%', color: '#777'}}>Category
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="category"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Category (Arabic)
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="category_ar"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Ingredient
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="food" placeholder="Apple"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Ingredient (Arabic)
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="food_ar" placeholder="تفاحة"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Serving
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="serving" placeholder="Cup, tbsp, item .. etc."/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Unit
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="unit" placeholder="g or ml"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many g/ml per serving
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="unit_serving" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many calories per g/ml
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="calories_unit" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many calories per serving?
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="calories_serving" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Allergies (if any)
                    {/*<Input style={{display: 'block', width: '100%'}} name="allergies"/>*/}
                    <Select options={allergies} className="select" isMulti placeholder="none" value={selectedAllergies} onChange={(e) => {setSelectedAllergies(e)}}/>
                </label>

                <Button variant="info" type="submit" className="float-right submit-button" style={{display: 'block', width: '100%'}}>
                    {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : 'Save'}
                </Button>
            </Form>
        </Modal>

        <Modal show={moveForm} onHide={() => setMoveForm(false)}>

            <Modal.Header style={{borderBottom: 'none'}} closeButton>
                <Modal.Title style={{fontSize: '20px', textAlign: 'center', width: '100%'}}>Move ingredient to another category</Modal.Title>
            </Modal.Header>

            <Form style={{padding: '5%', fontSize: '.9em'}} onSubmit={(e) => {moveItem(e)}}>

                <Form.Control required as="select" placeholder="Select Category" style={{cursor: 'pointer', width: '100%', display: 'block', marginBottom: '10px'}}
                    name="category"
                    //value={newCategory}
                    //onChange={(e) => {
                    //    setSelectedCategory(e.target.value);
                    //}}
                    >
                    {categories && categories.map((obj, i) => {
                        return (<option value={obj.category} key={obj.category} id={obj.category}>{obj.category} ({obj.category_ar})</option>)
                    })}
                </Form.Control>

                <Button variant="info" type="submit" className="float-right submit-button" style={{display: 'block', width: '100%'}}>
                    {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : 'Move'}
                </Button>
            </Form>
        </Modal>


                {show && <div className="dialogue-fixed-right">
                    <AddRecipe handleClose={handleClose} recipeData={recipeData} recipeTypes={recipeTypes} recipeTags={recipeTags} ingredients={items} welnes={true} width={12}/>
                </div>}
                <Modal show={showDeleteModal} onHide={() => {setShowDeleteModal(false); setRecipeData({});}}>
                    <DeleteAlert onHide={() => {setShowDeleteModal(false); setRecipeData({});}} title={'Delete Recipe'} message={'Are you sure you want to delete recipe?'} docId={docId} collectionName={'recipes'} />
                </Modal>

    </>);
}