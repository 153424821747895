
import React, {useEffect, useState} from 'react';

import { Spinner, Breadcrumb, Image, Modal } from "react-bootstrap";
import firebase from 'firebase/compat/app';
import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useHistory } from "react-router-dom";
import { ReactComponent as Support } from '../../assets/icons/support.svg';
import logoImg from '../../assets/icons/icon.png';
import './Admin.css';


export function Admin(props) {

    const [editors, setEditors] = useState({});
    const [loader, setLoader] = useState(true);
    var [logs, setLogs] = useState([]);
    const [usersCnt, setUsersCnt] = useState([]);

    const [challengeIdToChallengeName, setChallengeIdToChallengeName] = useState({});
    const [challengeNameToChallengeId, setChallengeNameToChallengeId] = useState({});
    const [followupNameToChallengeId, setFollowupNameToChallengeId] = useState({});
    const [followupIdToFollowupName, setFollowupIdToFollowupName] = useState({});
    const [userIdToUserName, setUserIdToUserName] = useState({});
    const [userNameToUserIds, setUserNameToUserIds] = useState({});
    const [userIdToRole, setUserIdToRole] = useState({});
    const [userIdToPhone, setUserIdToPhone] = useState({});
    const [userIdToEmail, setUserIdToEmail] = useState({});

    const [showPayments, setShowPayments] = useState(false);

    useEffect(() => {

        var unsubscribes = getCollections([{
            collection: "users",
            name: "user",
            includeMetadataChanges: false
        }, {
            collection: "challenges",
            name: "challenge",
            includeMetadataChanges: false
        }, {
            collection: "one_to_one",
            name: "one to one",
            includeMetadataChanges: false
        }, {
        //    collection: "groups",
        //    name: "group",
        //    includeMetadataChanges: false
        //}, {
            collection: "exercises",
            name: "exercise",
            includeMetadataChanges: true
        }, {
            collection: "recipes",
            name: "recipe",
            includeMetadataChanges: true
        }, {
        //    collection: "meal_plans",
        //    name: "meal plan",
        //    includeMetadataChanges: true
        //}, {
        //    collection: "workout_plans",
        //    name: "workout plan",
        //    includeMetadataChanges: false
        //}, {
            collection: "payments",
            name: "payment",
            includeMetadataChanges: false
        }]);

        return () => {

            console.log('Do some cleanup');
            unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);


    function checkNotifications(cb){

        if (!window.Notification) {
        
            //Notification Not supported in this Browser
        }
        else {
            if (Notification.permission !== "granted") {

                Notification.requestPermission(function(p) {
                    
                    if (p === 'denied') console.log('denied Notification');
                    else cb();
                });
            } 
            else {
                cb();
            }
        }
    }

    var notify = function(event, onClick){

        checkNotifications(function(){

            var notification = new Notification((event.role? event.role + ' | ' : '') + event.user, {

                body: event.action + ' ' + event.item + ' ' + event.name,
                icon: logoImg
            });

            notification.onclick = function () {

                //parent.focus();
                window.focus(); //older browsers
                this.close();
                onClick();
            };
        });
    }

    var addEvent = function(event, realtime){

        if(!event.date) return //console.log('no date for event', event)

        //if(logs.filter((log)=>log.date == event.date).length) return console.log('event exists, same event identifier: date')

        if(event.action == 'modified' && event.item == 'user') return    //gotta specify what is the change

        //console.log(event)

        if(event.realtime) notify(event, function(){console.log('notification click')})

        return event
    }

    var special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
    var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

    function stringifyNumber(n) {
      if (n < 20) return special[n];
      if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
      return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
    }

    var commands = function(){

        var cmds = {

            join: async ()=>{

                var findBy = parseInt(prompt('find user by'+'\n\n'+'1. name'+'\n'+'2. email', '1')) === 1? 'name' : 'email';
                var userName = prompt("Please enter "+findBy);
                var challengeName = Object.keys(challengeNameToChallengeId)[parseInt(prompt("Please select challenge \n\n" + Object.keys(challengeNameToChallengeId).map((name, i) => String(i+1)+'. '+name).join('\n'), '1')) - 1];

                if(!userName || !challengeName) return
                    
                const userDocs = await firestore("users").where(findBy, '==', userName).get();

                var userIndex = 0
                if(userDocs.size>1) {

                    var msg = '('+userDocs.size+') users exist with the same '+findBy+': '+userName+'\n\n'

                    msg += userDocs.docs.map((doc, i) => String(i+1)+'. '+' phone no. '+doc.data().mobileNumber+ ' email: '+doc.data().email).join('\n')

                    userIndex = parseInt(prompt(msg, '1')) - 1;
                }

                if(!userDocs.size) return alert('no user with that '+findBy)

                var userId = userDocs.docs[userIndex].id
                var challengeId = challengeNameToChallengeId[challengeName]

                if(!userId) return alert("no user")
                if(!challengeId) return alert("challenge doesn't exist")

                firestore("users").doc(userId)
                    //.where("challengeId", "==", challengeId)
                    //.where("userId", "==", userId)
                    .get().then(function(doc){

                        if(doc.data().joinedChallengeIds && doc.data().joinedChallengeIds.includes(challengeId)) return alert('already In');

                        doc.ref.update({challengeId: challengeId, joinedChallengeIds: firebase.firestore.FieldValue.arrayUnion(challengeId)}).then(()=>{

                            doc.ref.collection('selectedChallenges').doc(challengeId).set({joinDate: new Date().toISOString()})
                                .then(function(doc) {

                                    alert(userName+' joined challenge '+challengeName)

                                    return
                                })
                                .catch(function(error) {
                                    
                                    console.error("Error finding/updating user document: ", error);
                                });
                        })

                    return
                })
                .catch(function(error) {

                    console.error("Error finding user: ", error);
                });
            }, 
            kickout: async ()=>{

                var findBy = parseInt(prompt('find user by'+'\n\n'+'1. name'+'\n'+'2. email', '1')) === 1? 'name' : 'email';
                var userName = prompt("Please enter "+findBy);
                var challengeName = Object.keys(challengeNameToChallengeId)[parseInt(prompt("Please select challenge \n\n" + Object.keys(challengeNameToChallengeId).map((name, i) => String(i+1)+'. '+name).join('\n'), '1')) - 1];

                if(!userName || !challengeName) return
                    
                const userDocs = await firestore("users").where(findBy, '==', userName).get();

                var userIndex = 0
                if(userDocs.size>1) {

                    var msg = '('+userDocs.size+') users exist with the same '+findBy+': '+userName+'\n\n'

                    msg += userDocs.docs.map((doc, i) => String(i+1)+'. '+' phone no. '+doc.data().mobileNumber+ ' email: '+doc.data().email).join('\n')

                    userIndex = parseInt(prompt(msg, '1')) - 1;
                }

                if(!userDocs.size) return alert('no user with that '+findBy)

                var userId = userDocs.docs[userIndex].id
                var challengeId = challengeNameToChallengeId[challengeName]

                if(!userId) return alert("no user")
                if(!challengeId) return alert("challenge doesn't exist")

                firestore("users").doc(userId)
                    //.where("challengeId", "==", challengeId)
                    //.where("userId", "==", userId)
                    .get().then(function(doc){

                        if(!doc.data().joinedChallengeIds || !doc.data().joinedChallengeIds.includes(challengeId)) return alert('user is not in the challenge');

                        doc.ref.update({challengeId: firebase.firestore.FieldValue.delete(), joinedChallengeIds: firebase.firestore.FieldValue.arrayRemove(challengeId)}).then(()=>{

                            doc.ref.collection('selectedChallenges').doc(challengeId).delete()
                                .then(function(doc) {

                                    alert(userName+' kicked out of challenge '+challengeName)

                                    return
                                })
                                .catch(function(error) {
                                    
                                    console.error("Error finding/updating user document: ", error);
                                });
                        })

                    return
                })
                .catch(function(error) {

                    console.error("Error finding user: ", error);
                });
            },
            refund: async ()=>{

                var findBy = parseInt(prompt('find user by'+'\n\n'+'1. name'+'\n'+'2. email', '1')) === 1? 'name' : 'email';
                var userName = prompt("Please enter "+findBy);
                var programNameToId = parseInt(prompt('1. challenge'+'\n'+'2. one to one', '1')) === 1? challengeNameToChallengeId : followupNameToChallengeId;
                var challengeName = Object.keys(programNameToId)[parseInt(prompt(/*"Please select challenge \n\n" +*/ Object.keys(programNameToId).map((name, i) => String(i+1)+'. '+name).join('\n'), '1')) - 1];

                if(!userName || !challengeName) return
                    
                const userDocs = await firestore("users").where(findBy, '==', userName).get();

                var userIndex = 0
                if(userDocs.size>1) {

                    var msg = '('+userDocs.size+') users exist with the same '+findBy+': '+userName+'\n\n'

                    msg += userDocs.docs.map((doc, i) => String(i+1)+'. '+' phone no. '+doc.data().mobileNumber+ ' email: '+doc.data().email).join('\n')

                    userIndex = parseInt(prompt(msg, '1')) - 1;
                }

                if(!userDocs.size) return alert('no user with that '+findBy)

                var userId = userDocs.docs[userIndex].id
                var challengeId = programNameToId[challengeName]

                if(!userId) return alert("no user")
                if(!challengeId) return alert("challenge doesn't exist")

                firestore("payments")
                    .where("challengeId", "==", challengeId)
                    .where("userId", "==", userId)
                    .get().then(function(docs){

                        var payments = []

                        docs.forEach(function(doc){

                            if(!doc.data().declined) payments.push(doc.data())
                        })

                        if(!payments.length) alert('no payments for this user')
                        if(payments.length>1) alert('there are several payments for this user')

                        payments.map(function(payment){

                            if(payment.provider === 'stripe') prompt("refund at stripe, link : ", 'https://dashboard.stripe.com/search?query='+payment.userId+'%20'+payment.challengeId);
                            if(payment.provider === 'apple') alert('it is an apple payment, please let the user request the refund themselves from apple portal')
                            if(payment.provider === 'manual') alert('it is a manual payment, please send cash to user, then "kickout" from challenge at "users')
                            if(payment.provider === 'vodafone') alert('it is a vodafone payment, please send cash to user, then "kickout" from challenge at "users')
                        })

                    return
                })
                .catch(function(error) {

                    console.error("Error finding payment: ", error);
                });
            },
            duplicate_payments: async ()=>{

                var challengeName = Object.keys(challengeNameToChallengeId)[parseInt(prompt("Please select challenge \n\n" + Object.keys(challengeNameToChallengeId).map((name, i) => String(i+1)+'. '+name).join('\n'), '1')) - 1];

                if(!challengeName) return
                    
                var challengeId = challengeNameToChallengeId[challengeName]

                if(!challengeId) return alert('no userId or challengeId')


                firestore("payments")
                    .where("challengeId", "==", challengeId)
                    .get().then(function(docs){

                        var payments = []

                        docs.forEach(function(doc){

                            if(!doc.data().declined) payments.push(doc.data())
                        })

                        var users = {}, userNames = {}
                        payments.map(function(payment){

                            if(users[payment.userId]) {console.log(payment); userNames[userIdToUserName[payment.userId]] = true}
                            users[payment.userId] = true
                        })

                        var names = Object.keys(userNames)
                        alert(names.length? names.join('\n') : 'no duplicates')
                        console.log(names.join('\n'))

                    return
                })
                .catch(function(error) {

                    console.error("Error finding payment: ", error);
                });
            },
            users_without_payments: async ()=>{

                var challengeName = Object.keys(challengeNameToChallengeId)[parseInt(prompt("Please select challenge \n\n" + Object.keys(challengeNameToChallengeId).map((name, i) => String(i+1)+'. '+name).join('\n'), '1')) - 1];

                if(!challengeName) return
                    
                var challengeId = challengeNameToChallengeId[challengeName]

                if(!challengeId) return alert('no userId or challengeId')


                firestore("payments")
                    .where("challengeId", "==", challengeId)
                    .get().then(function(docs){

                        var payments = []

                        docs.forEach(function(doc){

                            if(!doc.data().declined) payments.push(doc.data())
                        })

                        var paid = {}
                        payments.map(function(payment){

                            paid[payment.userId] = true
                        })

                        var notPaid = []
                        firestore('users').where('joinedChallengeIds', 'array-contains', challengeId).get().then(function(docs){

                            docs.forEach(function(doc){

                                if(!paid[doc.id]){

                                    notPaid.push(doc.data().name)
                                    console.log(doc.data().name)
                                }
                            })

                            alert(notPaid.length? notPaid.join('\n') : 'no users')
                        })

                    return
                })
                .catch(function(error) {

                    console.error("Error finding payment: ", error);
                });
            },
            user_payments: async ()=>{

                var findBy = parseInt(prompt('find user by'+'\n\n'+'1. name'+'\n'+'2. email', '1')) === 1? 'name' : 'email';
                var userName = prompt("Please enter "+findBy);
                //var challengeName = Object.keys(challengeNameToChallengeId)[parseInt(prompt("Please select challenge \n\n" + Object.keys(challengeNameToChallengeId).map((name, i) => String(i+1)+'. '+name).join('\n'), '1')) - 1];

                if(!userName /*|| !challengeName*/) return
                    
                const userDocs = await firestore("users").where(findBy, '==', userName).get();

                var userIndex = 0
                if(userDocs.size>1) {

                    var msg = '('+userDocs.size+') users exist with the same '+findBy+': '+userName+'\n\n'

                    msg += userDocs.docs.map((doc, i) => String(i+1)+'. '+' phone no. '+doc.data().mobileNumber+ ' email: '+doc.data().email).join('\n')

                    userIndex = parseInt(prompt(msg, '1')) - 1;
                }

                if(!userDocs.size) return alert('no user with that '+findBy)

                var userId = userDocs.docs[userIndex].id
                //var challengeId = challengeNameToChallengeId[challengeName]

                if(!userId) return alert("no user")
                //if(!challengeId) return alert("challenge doesn't exist")

                firestore("payments")
                    //.where("challengeId", "==", challengeId)
                    .where("userId", "==", userId)
                    .get().then(function(docs){

                        var payments = []

                        docs.forEach(function(doc){

                            payments.push(doc.data())
                        })

                        if(!payments.length) return alert('no payments for this user')
                        //if(payments.length>1) alert('there are several payments for this user')

                        payments = _.orderBy(payments, d=>moment.utc(d.datetime).format(), 'asc')

                        /*var index = parseInt(prompt(userName+' payment attempts ('+payments.length+') '+'\n\n'+ 
                            payments.map(function(payment, i){
                                return String(i+1)+'. '+stringifyNumber(i+1)+ ' '+ challengeIdToChallengeName[payment.challengeId]+ ' attempt ==> '+ (payment.declined||'Successful')
                            }).join('\n')+ '\n\n check payment at accept for:'
                            
                            , '1'

                        )) - 1

                        prompt("accept: merchant_order_id:", payments[index].userId+'_'+payments[index].challengeId+'_'+payments[index].type+'_'+payments[index].amount);*/

                        setShowPayments({
                            userName: userName,
                            nPayments: payments.length,
                            payments: payments
                        })

                    return
                })
                .catch(function(error) {

                    console.error("Error finding payment: ", error);
                });
            },
        }

        var cmd = prompt(Object.keys(cmds).map((id, i) => String(i+1)+'. '+id).join('\n'), '1');

        if(!cmd) return

        var selectedCommand = parseInt(cmd) - 1;

        cmds[Object.keys(cmds)[selectedCommand]]()
    }

    window.onkeypress = function(event) {

       if (event.keyCode == 10) {

            commands()
       }
    }

    var _userNameToUserIds = {}
    var _userIdToUserName = {}
    var _userIdToRole = {}
    var _userIdToPhone = {}
    var _userIdToEmail = {}
    var _challengeNameToChallengeId = {}
    var _challengeIdToChallengeName = {}
    var _followupNameToChallengeId = {}
    var _followupIdToFollowupName = {}

    function getCollections(collections){

        firestore("users")
            .where('role', "array-contains-any", ["admin", "nutritionist", "collaborator", "assistant"])
            //.onSnapshot(function(snapshot){
            .get().then(function(snapshot){

            const users = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            var editors = {}

            users.map((d) => {

                if(d.role.includes('admin')) editors[d.name] = 'admin'
                else if(d.role.includes('nutritionist')) editors[d.name] = 'nutritionist'
                else if(d.role.includes('collaborator')) editors[d.name] = 'collaborator'
                else if(d.role.includes('assistant')) editors[d.name] = 'assistant'
            })

            setEditors(editors)
        })

        const unsubscribes = collections.map((c) => {

            const collection = c.collection

            return firestore(collection).orderBy('datetime', 'desc').limit(500).onSnapshot({includeMetadataChanges: c.includeMetadataChanges}, function(snapshot){

                if(collection == "users"){

                    _userNameToUserIds = {}
                    _userIdToUserName = {}
                    _userIdToRole = {}
                    _userIdToPhone = {}
                    _userIdToEmail = {}

                    snapshot.docs.map((doc)=>{

                        const user = doc.data()
                        _userIdToUserName[doc.id] = user.name

                        if(_userNameToUserIds[user.name]){   //user with same name exists
                            
                            _userNameToUserIds[user.name].push(doc.id)
                        }
                        else _userNameToUserIds[user.name] = [doc.id]

                        _userIdToRole[doc.id] = user.role && user.role.length? user.role.includes('admin')? 'admin' : user.role.includes('nutritionist')? 'nutritionist' : '' : ''
                        _userIdToPhone[doc.id] = user.mobileNumber
                        _userIdToEmail[doc.id] = user.email
                    })

                    setUsersCnt(snapshot.docs.length)

                    setUserIdToUserName(_userIdToUserName)
                    setUserNameToUserIds(_userNameToUserIds)
                    setUserIdToRole(_userIdToRole)
                    setUserIdToPhone(_userIdToPhone)
                    setUserIdToEmail(_userIdToEmail)
                }

                if(collection == "challenges"){

                    _challengeNameToChallengeId = {}
                    _challengeIdToChallengeName = {}

                    var docs = snapshot.docs.map(doc => { var obj = doc.data(); obj.id = doc.id; return obj })

                    docs = _.orderBy(docs, item => moment.utc(item.date).format(), 'desc')
                    docs.filter(d=>d.isPublished)/*.sort((a,b)=> (b.previousRounds?b.previousRounds.length:0) - (a.previousRounds?a.previousRounds.length:0))*/.map((challenge)=>{

                        _challengeIdToChallengeName[challenge.id] = challenge.name
                        _challengeNameToChallengeId[challenge.name] = _challengeNameToChallengeId[challenge.name] || challenge.id
                    })

                    setChallengeIdToChallengeName(_challengeIdToChallengeName)
                    setChallengeNameToChallengeId(_challengeNameToChallengeId)
                }

                if(collection == "one_to_one"){

                    _followupNameToChallengeId = {}
                    _followupIdToFollowupName = {}

                    var docs = snapshot.docs.map(doc => { var obj = doc.data(); obj.id = doc.id; return obj })

                    docs = _.orderBy(docs, item => moment.utc(item.date).format(), 'desc')
                    docs.filter(d=>d.isPublished)/*.sort((a,b)=> (b.previousRounds?b.previousRounds.length:0) - (a.previousRounds?a.previousRounds.length:0))*/.map((challenge)=>{

                        _followupIdToFollowupName[challenge.id] = challenge.name || challenge.nutritionistName
                        _followupNameToChallengeId[challenge.name || challenge.nutritionistName] = challenge.id
                    })

                    setFollowupIdToFollowupName(_followupIdToFollowupName)
                    setFollowupNameToChallengeId(_followupNameToChallengeId)
                }

                snapshot.metadata.fromCache = snapshot.metadata.fromCache || snapshot.docChanges().length > 1

                //if(snapshot.metadata.fromCache) return

                var _logs = []

                snapshot.docChanges().forEach(function(change){

                    var date, role, user, action, item, name, device, provider

                    if (change.type === "added") {
                        //console.log("New user: ", change.doc.data());
                    }
                    if (change.type === "modified") {
                        //console.log("Modified user: ", change.doc.data());

                        return; //skip all modifications

                        if(collection == "payments") return
                        if(collection == "challenges") return
                        if(collection == "one_to_one") return
                    }
                    if (change.type === "removed") {
                        //console.log("Removed user: ", change.doc.data());
                    }

                    if(!change.doc.data().datetime) return //console.log('no datetime for doc', change.doc.data())


                    var doc = change.doc.data()

                    //add user select challenge event
                    if(collection == "payments"){

                        if(!doc.userName) return

                        date = doc.datetime
                        role = _userIdToRole[doc.userId]
                        user = doc.userName
                        action = 'paid'
                        item = doc.amount + ' ' + (doc.currency || '')
                        name = _challengeIdToChallengeName[doc.challengeId] || _followupIdToFollowupName[doc.challengeId]
                        device = doc.declined || 'Successful'
                        provider = doc.provider || 'noProvider'
                    }

                    var event = {

                        realtime: !snapshot.metadata.fromCache,
                        date: date || doc.datetime,
                        role: role || (collection == "users"? _userIdToRole[doc.id] : _userIdToRole[doc.nutritionistId] || _userIdToRole[doc.userId]),
                        user: user || (collection == "users"? doc.name || doc.email : doc.nutritionistName || _userIdToUserName[doc.userId]),
                        action: action || ((collection == "users" && change.type === "added") ? 'created' : change.type),
                        item: item || (change.type == 'selected'? 'challenge' : collection.slice(0, -1)),
                        name: name || (collection == "users"? (doc.web? 'web':'app') : doc.name),
                        device: device || (collection == "users"? doc.deviceDetails? doc.deviceDetails.brand || '' : '' : ''),
                        provider: provider,
                    }

                    event.userId = doc[{payments: 'userId', users: 'id', }[collection] || 'nutritionistId']

                    var log = addEvent(event)
                    if(log) _logs.push(log)


                    //add user select challenge event
                    /*if(collection == "users" && doc.challengeId && doc.selectedChallenge){

                        date = moment.utc(doc.selectedChallenge.datetime).format()
                        action = 'selected'
                        item = 'challenge'
                        name = doc.selectedChallenge.challenge.name


                        var event = {

                            realtime: !snapshot.metadata.fromCache,
                            date: date || (snapshot.metadata.fromCache? moment.utc(doc.datetime).format() : moment().format()),
                            role: role || (collection == "users"? _userIdToRole[doc.id] : _userIdToRole[doc.nutritionistId] || _userIdToRole[doc.userId]),
                            user: user || (collection == "users"? (doc.name || doc.email) : _userIdToUserName[doc.nutritionistId] || _userIdToUserName[doc.userId]),
                            action: action || (collection == "users" && change.type === "added" ? 'created' : change.type),
                            item: item || (change.type == 'selected'? 'challenge' : collection.slice(0, -1)),
                            name: name || (collection == "users"? '' : doc.name),
                            device: device || (collection == "users"? doc.deviceDetails? doc.deviceDetails.brand || '' : '' : '')
                        }

                        addEvent(event)
                    }*/
                });

                if(loader) setLoader(false);

                //refresh UI
                logs = snapshot.metadata.fromCache? _.orderBy(logs.concat(_logs), d=>moment.utc(d.date).format(), 'desc').slice(0, 500) : _logs.concat(logs)

                setLogs(logs);
            })
        })

        return unsubscribes
    }

    return (<>
        <Breadcrumb className="pb-3">
            <Breadcrumb.Item active>listening to server activity..</Breadcrumb.Item>
            <span style={{marginLeft: '60px', opacity:'.7', cursor: 'pointer'}} onClick={commands}>&lt;/&gt;</span>

            {/*<NavLink key="/support" className="d-flex list-group-item list-group-item-action" style={{position:'fixed', right:'100px', width: 'auto', top: '-1px'}}
                to="/support" exact
                active ClassName={'link-active'}>
                <Support className="link-icon" style={{height: '20px', margin: 'auto 10px'}}/>
                <div className="link-text">Support</div>
            </NavLink>*/}

            {/*<div className="no-users">{usersCnt}</div>*/}
        </Breadcrumb>

        <div className="pt-5 h-100 d-flex justify-content-center align-self-center">

            <table className="m-0 logs" responsive="xl">
                {/*<thead>
                    <tr>
                        <th>date</th>
                        <th>collection</th>
                        <th>change</th>
                        <th>item</th>
                    </tr>
                </thead>*/}
                <tbody>

                    {loader ? <tr className="no-border">
                        <td className="text-center" colSpan="5">
                            <Spinner animation="border" size={'sm'} role="status" />
                        </td>
                    </tr> : logs.length? logs.map((log, i) => <tr key={log.date+log.user}>
                        <td>{moment(log.date).fromNow().replace('a few seconds ago', 'now')}</td>
                        {/*<td>{log.role}</td>*/}
                        <td title={log.user} className={editors[log.user]}>{log.user}</td>
                        <td title={log.action}>{log.action}</td>
                        <td title={log.item}>{log.item}</td>
                        <td title={log.name}>{log.name}</td>
                        <td title={log.device} style={log.action=='paid'?log.device=='Successful'?{color:'green'}:{color:'red'}:{}}>{log.device}</td>
                        <td title={log.provider}>{log.provider}</td>
                        <td>
                            <NavLink key={"/support"} to={"/support/" + log.userId}>
                                <span
                                    style={{
                                        cursor: "pointer",
                                        //opacity: ".3",
                                    }}
                                    className="contact"
                                >
                                    <FontAwesomeIcon
                                        style={{ color: "#ddd" }}
                                        icon={faHeadset}
                                    />
                                </span>
                            </NavLink>
                        </td>
                    </tr>) : <tr>
                            <td className="text-center" colSpan="5">listening to server activity..</td>
                    </tr>}
                </tbody>
            </table>
        </div>


        {showPayments && <Modal show={showPayments} onHide={() => setShowPayments(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Payments | {showPayments.userName} ({showPayments.nPayments})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="m-0 logs" responsive="xl" style={{width: '100%'}}>
                    <tbody>
                        {showPayments.payments.map(d => 
                            <tr>
                                <td style={{maxWidth: 'auto !important'}}>{challengeIdToChallengeName[d.challengeId]}</td>
                                <td style={{maxWidth: 'auto !important'}}>{moment(d.datetime).fromNow().replace('a few seconds ago', 'now')}</td>
                                <td style={{maxWidth: 'auto !important'}}>{(d.declined||'Successful')}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>}
    </>)
}
