/** @format */

import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
// import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend);

function WorkoutPlansChart({ workoutPlans, workoutUsers, users }) {
  let workoutData = [];
  let workoutLabels = workoutPlans
    .filter((workout) => workoutUsers[workout.key])
    .sort((a, b) => workoutUsers[b.key] - workoutUsers[a.key])
    .concat([{ key: "unassigned", name: "unassigned" }])
    .map((workout) => {
      workoutData.push(workoutUsers[workout.key]);
      return `${workout.name} (${workoutUsers[workout.key]}) ${(
        (workoutUsers[workout.key] / users.length) *
        100
      ).toFixed(0)}%`;
    });
  // console.log(workoutLabels.at(-1));
  let colors = [
    "#EB705d",
    "#EDC437",
    "#F79745",
    "#ce5270",
    "#20336e",
    "#2588ad",
    "#86ddd4",
    "#00b59c",
    "#f73131",
    "#F9C257",
    "#b84333",
    "#b5899d",
    "#2f8a8b",
  ];

  if (workoutData.length) {
    let unassignedLabel = workoutData.lastIndexOf(
      workoutData[workoutData.length - 1]
    );
    colors[unassignedLabel] = "#a8a8a8";
  }

  var donutData = {
    series: Object.values(workoutData),
    options: {
      chart: {
        type: "donut",
      },
      labels: workoutLabels,
      plotOptions: {
        pie: {
          donut: {
            size: "48%",
          },
        },
      },
      stroke: {
        show: false,
        colors: ["#000"],
      },
      theme: {
        mode: "light",
        palette: "palette3",
        monochrome: {
          enabled: false,
          color: "#EB705D",
          shadeTo: "light",
          shadeIntensity: 1,
        },
      },
      responsive: [
        {
          //breakpoint: 480,
          options: {
            legend: {
              //position: 'bottom'
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <ReactApexChart
        options={donutData.options}
        series={donutData.series}
        type="donut"
      />
    </>
  );
}
export default WorkoutPlansChart;
