import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { firestore } from "../services/firebase";

export function DeleteAlert(props) {
  const [isOkLoader, setIsOkLoader] = useState(false);
  async function deleteFunction() {
    if (props.docId) {
      setIsOkLoader(true);
      console.log("props.collectionName", props.collectionName);
      console.log("props.docId", props.docId);
      const res = await firestore(props.collectionName)
        .doc(props.docId)
        .delete();
      if (props.collectionName === "workout_plans") {
        firestore("workout_plans_essentials").doc(props.docId).delete();
      }
      if (props.collectionName === "meal_plans") {
        firestore("meal_plans_essentials").doc(props.docId).delete();
      }
      console.log("res", res);
      setIsOkLoader(false);
      props.onHide("deleted");
    }
  }
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{props.message}</p>
        {props.warning && (
          <p className="text-warning">Warning: {props.warning}</p>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="button button-cancel"
          variant="secondary"
          onClick={() => props.onHide(false)}
        >
          {props.cancelText ? props.cancelText : "Cancel"}
        </Button>
        <Button
          className="button button-delete"
          variant="danger"
          onClick={() => deleteFunction()}
        >
          {isOkLoader ? (
            <Spinner animation="border" size={"sm"} role="status" />
          ) : props.okText ? (
            props.okText
          ) : (
            "Delete"
          )}
        </Button>
      </Modal.Footer>
    </>
  );
}

export default DeleteAlert;
