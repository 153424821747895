/** @format */

import React, { useEffect, useState } from "react";
import "./Rules.scss";

import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Card,
	Dropdown,
	DropdownButton,
	Form,
	Modal,
	Spinner,
	Table,
	Col,
	Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFilter,
	faArrowUp,
	faArrowDown,
	faInfoCircle,
	faUserPlus,
	faPlus,
	faUserTimes,
	faHeadset,
	faListAlt,
	faExchangeAlt,
	faStreetView,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { firestore } from "../../services/firebase";
import * as _ from "lodash";
import AddGroup from "../../components/AddGroup";
import UserFilter from "../../components/UserFilter";
import { confirm } from "../../components/Confirmation";
import { NavLink, useHistory } from "react-router-dom";
import Filter from "../../assets/icons/filter.svg";
import Star from "../../assets/icons/Star.svg";
import Arrow from "../../assets/icons/arrow.svg";
import moment from "moment";
import { Header } from "../../components/Header";


import * as countriesList from "./users/countries.json";

const countriesObj = {};
countriesList["default"].map((item) => {
	countriesObj[item.abbreviation] = item.country;
});


function sortByName(a, b){
	if((a.name||"").toLowerCase().trim() < (b.name||"").toLowerCase().trim()) return -1; 
	if((a.name||"").toLowerCase().trim() > (b.name||"").toLowerCase().trim()) return 1; 
	return 0;
}



function getRandomColor() {
  var letters = '0123456789AB';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}
function setColors(tags) {
  var obj = {}
  tags.map(tag =>{
  	obj[tag] = getRandomColor()
  })
  return obj;
}
function hexToRGBA(hex, opacity) {
  hex = hex.replace('#', ''); // remove the # if present
  var r = parseInt(hex.substring(0,2), 16);
  var g = parseInt(hex.substring(2,4), 16);
  var b = parseInt(hex.substring(4,6), 16);
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
}


export function Rules(props) {
	const [tags, setTags] = useState({});
	const [tagsEx, setTagsEx] = useState({});
	const [types, setTypes] = useState({});
	const [filterData, setFilterData] = useState({});
	const [sort, setSort] = useState({
		field: "joinDate",
		direction: "desc",
	});
	const [allergies, setAllergies] = useState([]);
	const [medicalConditions, setMedicalConditions] = useState([]);

	const [assignmentsLoader, setAssignmentsLoader] = useState(true);

	const [showFilter, setShowFilter] = useState(false);
	const [settingRule, setSettingRule] = useState(false);
	const [showAddRule, setShowAddRule] = useState(false);
	const [autoAssignmments, setAutoAssignmments] = useState([]);
	const [formValue, setFormValue] = useState({});
	const [countries, setCountries] = useState({});

	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);
	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);


	useEffect(() => {
		getRecipeTags();
		getExerciseTags();
		return () => {};
	}, []);


    async function getRecipeTags() {
        const recipeTypesDoc = await firestore('constants').doc('recipeTypes').get();
        var types = {
        	'meals per day': {
        		values: recipeTypesDoc.data().value.map(d=> d.toLowerCase()),
        		multi: true
        	},
        }
        for(var type in types) {
        	types[type].color = '#eee'
        	types[type].Color = '#464646'
        }
        setTypes(types)

        const recipeTagsDoc = await firestore('constants').doc('recipeTags').get();
        var tags = {
        	...recipeTagsDoc.data()
        }
        for(var tag in tags) {
        	const color = getRandomColor(tag)
        	tags[tag].color = hexToRGBA(color, 0.1)
        	tags[tag].Color = color
        }
        setTags(tags)
    }

    async function getExerciseTags() {
        const exerciseTagsDoc = await firestore('constants').doc('exerciseTags').get();
        var tags = {
        	...exerciseTagsDoc.data()
        }
        for(var tag in tags) {
        	const color = getRandomColor(tag)
        	tags[tag].color = hexToRGBA(color, 0.1)
        	tags[tag].Color = color
        }
        setTagsEx(tags)
    }

	const assignments = {};



	useEffect(() => {
		const assignmentsSubscription = getAssignmentRules();
		return () => {
			if (assignmentsSubscription) {
				assignmentsSubscription();
			}
		};
	}, []);


	function getAssignmentRules() {
		setAssignmentsLoader(true);
		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		return firestore("rules")
			.onSnapshot((querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot, i) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});

				setAutoAssignmments(_.orderBy(data, "datetime", "asc"));
				setAssignmentsLoader(false);
			});
	}


	function removeFilter(key) {
		const filter = _.clone(filterData);
		delete filter[key];
		setFilterData(filter);
	}


	async function removeAssignmentRule(rule) {
		if (
			await confirm(
				"auto assignment",
				"remove " + rule.assignment + " auto assignment rule ?",
				"Remove"
			)
		) {
			await firestore("rules")
				.doc(rule.key)
				.delete();
		}
	}

	async function autoAssignmmentAlgorithm(assignment) {
		if (!Object.keys(filterData).length) return;

		var conditions = JSON.parse(JSON.stringify(filterData));
		delete conditions.groupId;
		delete conditions.mealPlanId;
		delete conditions.workoutPlanId;
		if (!Object.keys(conditions).length) return;

		var tags = assignment === 'meal plan' ? formValue.tags : formValue.tagsEx;
		var types = assignment === 'meal plan' ? formValue.types['meals per day'] : null;

		if (
			await confirm(
				"auto assignment",
				"use this assignment algorithm to\n auto assign new joining members ?\n\n" +
					Object.keys(conditions)
						.map(
							(key) =>
								"if user " +
								key +
								" " +
								"is" +
								" " +
								conditions[key] +
								" ==>  assign " +
								assignment +
								" : " +
								"\n" +
								Array.prototype.concat(Object.values(tags)).join(', ')
						)
						.join("\n"),
				"Save"
			)
		) {

			await firestore("rules").add({ assignment/*, ...target && {target: target}*/, conditions, datetime: moment().format(), ...tags && {tags: tags}, ...types && {types: types} });
		}
	}


	async function addRule() {
		setShowAddRule(true);

		//if(assign === 'group') autoAssignmmentAlgorithm('group', groupId, displayGroup(groupId))
		//if(assign === 'meal plan') autoAssignmmentAlgorithm('meal plan', selectedPlan, displayMealPlan(selectedPlan))
		//if(assign === 'workout plan') autoAssignmmentAlgorithm('workout plan', selectedPlan, displayWorkOutPlan(selectedPlan))
	}



    async function toggleTag(property, tag, value, multi) {

        var selected = formValue[property] && formValue[property][tag] && formValue[property][tag].includes(value)

				const formValueClone = _.clone(formValue);
				if(!formValueClone[property]) formValueClone[property] = {}

				if(selected){//remove

					formValueClone[property][tag] = formValueClone[property][tag].filter(item => item !== value)
					if(!formValueClone[property][tag].length) delete formValueClone[property][tag]
				}
				else{//add

					if(multi) {
						if(formValueClone[property][tag]) formValueClone[property][tag].push(value)
						else formValueClone[property][tag] = [value]
					}
					else{
						formValueClone[property][tag] = [value]
					}
				}
				setFormValue(formValueClone);
    }

	return (
		<>
			<Header header="Rules" />

			<div className="pt-2 user-page-section">

					<div>

						<Card className="my-4 assignment-card">
							<h4
								className="m-0 d-flex justify-content-between"
								style={{ marginLeft: "25px" }}
							>
								Assignment rules ({autoAssignmments.length})
								<ButtonToolbar className="ml-auto">
									<ButtonGroup className="mr-2" aria-label="First group">
										<Button
											// variant="info"

											className="button button-secondary"
											onClick={() => {
												setSettingRule(true);
												setShowFilter(true);
											}}
										>
											<FontAwesomeIcon
												icon={faListAlt}
												size="1x"
												className="mr-2"
											/>
											Add Rule
										</Button>
									</ButtonGroup>
								</ButtonToolbar>
							</h4>

							<Card.Body className="mt-2 mr-3">
								<Row>
									<div className="text-center w-100 assignment-rules">
										{assignmentsLoader ? (
											<div className="text-center w-100">
												<Spinner animation="border" size={"sm"} role="status" />
											</div>
										) : autoAssignmments.length ? (
											autoAssignmments.map((rule) => {
												return (
													<>
														<div key={rule.key} className="rule">
															{/*<span>if user</span>*/}
															<span>
																{Object.keys(rule.conditions).map((key) => (
																	<>
																		<div id="rule-key">
																			{key}
																		</div>

																		<div className="rule-condition">
																			{rule.conditions[key]}
																		</div>
																	</>
																))}
																<img
																	style={{ width: "25px", marginRight: "10px" }}
																	src={Arrow}
																	alt="arrow"
																/>
																{rule.assignment}
																{/*rule.assignment === "group" && (
																	<span className="target">
																		{displayGroup(rule.target)}
																	</span>
																)*/}
																{rule.assignment === "meal plan" && (
																	<span className="target">
																		{Array.prototype.concat(Object.values(rule.tags)).join(', ')}
																	</span>
																)}
																{rule.assignment === "workout plan" && (
																	<span className="target">
																		{Array.prototype.concat(Object.values(rule.tags)).join(', ')}
																	</span>
																)}
															</span>
															<Button
																className="button circle"
																onClick={() => {
																	removeAssignmentRule(rule);
																}}
															>
																<FontAwesomeIcon icon={faTrashAlt} />
															</Button>
														</div>
														<hr
															style={{ borderColor: "#f0f0f0", margin: "15px" }}
														/>
													</>
												);
											})
										) : (
											<div className="text-center users-page-error-message">
												No Auto assignment rules
											</div>
										)}
									</div>
								</Row>
							</Card.Body>
						</Card>
						
						<Modal show={showAddRule} onHide={() => setShowAddRule(false)} size="lg">
							<Modal.Header closeButton>
								<Modal.Title>Select Assignment</Modal.Title>
							</Modal.Header>
							<Modal.Body style={{ padding: "5em 3em", margin: "0 auto" }}>

								<div style={{marginTop: '10px'}}>
									
									<Form.Label style={{fontWeight: 'bold'}}>Meal plan </Form.Label><Form.Label style={{marginLeft: '10px'}}>recipes tags</Form.Label>

									<div style={{}}></div>
									
		                        <div style={{maxWidth: 'initial', whiteSpace: 'normal', fontSize: '.8em', marginTop: '30px', verticalAlign: 'top'}}>
                                <div style={{marginTop: '1em'}}>
                                    {Object.keys(types).map(tag => 
                                        <div style={{marginBottom: '0px', display: 'flex'}}>
                                        	<span style={{marginRight: '7px', fontSize: '12px', padding: '3px 8px', whiteSpace: 'nowrap'}}>{tag} : </span> 
                                        	<span>
                                        		{types[tag].values.map(value => <span 
		                                            className={"plan-tag"+(formValue.types&&formValue.types[tag]&&formValue.types[tag].includes(value)?' selected':'')} 
		                                            style={{
		                                            	background: formValue.types&&formValue.types[tag]&&formValue.types[tag].includes(value)? types[tag].Color: types[tag].color,
		                                            	cursor: 'pointer', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', display: 'inline-block'}} 
		                                            onClick={(e)=>{toggleTag('types', tag, value, types[tag].multi)}}>
		                                            {value} 
	                                        	</span>)}
                                        	</span>
                                        </div>
                                    )}
                                    {/*
                                    <span style={{border: '1px solid #ddd', color: '#222', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', cursor: 'pointer', display: 'inline-block'}} onClick={()=>{addTag()}}>+ new tag</span>
                                    <span style={{border: '1px solid #ddd', color: '#222', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', cursor: 'pointer', display: 'inline-block'}} onClick={()=>{removeTag()}}>- remove tag</span>
                                	*/}
                                </div>
                            </div>


		                        <div style={{maxWidth: 'initial', whiteSpace: 'normal', fontSize: '.8em', marginTop: '30px', verticalAlign: 'top'}}>
                                <div style={{marginTop: '1em'}}>
                                    {Object.keys(tags).map(tag => 
                                        <div style={{marginBottom: '0px', display: 'flex'}}>
                                        	<span style={{marginRight: '7px', fontSize: '12px', padding: '3px 8px', whiteSpace: 'nowrap'}}>{tag} : </span> 
                                        	<span>
                                        		{tags[tag].values.map(value => <span 
		                                            className={"plan-tag"+(formValue.tags&&formValue.tags[tag]&&formValue.tags[tag].includes(value)?' selected':'')} 
		                                            style={{
		                                            	background: formValue.tags&&formValue.tags[tag]&&formValue.tags[tag].includes(value)? tags[tag].Color: tags[tag].color,
		                                            	cursor: 'pointer', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', display: 'inline-block'}} 
		                                            onClick={(e)=>{toggleTag('tags', tag, value, tags[tag].multi)}}>
		                                            {value} 
	                                        	</span>)}
                                        	</span>
                                        </div>
                                    )}
                                    {/*
                                    <span style={{border: '1px solid #ddd', color: '#222', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', cursor: 'pointer', display: 'inline-block'}} onClick={()=>{addTag()}}>+ new tag</span>
                                    <span style={{border: '1px solid #ddd', color: '#222', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', cursor: 'pointer', display: 'inline-block'}} onClick={()=>{removeTag()}}>- remove tag</span>
                                	*/}
                                </div>
                            </div>

									<Button
										className="button"
										variant="light"
										disabled={!formValue.types || !Object.keys(formValue.types).length || !formValue.tags || !Object.keys(formValue.tags).length}
										style={{ borderRadius: '5px', fontSize: '12px', color: '#222', background: '#eee', marginTop: '50px' }}
										onClick={() => {
											
											autoAssignmmentAlgorithm("meal plan");
										}}
									>
										<FontAwesomeIcon
											icon={faListAlt}
											size="1x"
											className="mr-2"
										/>
										Submit assignment rule
									</Button>

								</div>
								<div style={{marginTop: '100px'}}>
									
									<Form.Label style={{fontWeight: 'bold'}}>Workout plan</Form.Label><Form.Label style={{marginLeft: '10px'}}>exercises tags</Form.Label>

									<div style={{}}></div>

		                        <div style={{maxWidth: 'initial', whiteSpace: 'normal', fontSize: '.8em', marginTop: '30px', verticalAlign: 'top'}}>
                                <div style={{marginTop: '1em'}}>
                                    {Object.keys(tagsEx).map(tag => 
                                        <div style={{marginBottom: '0px', display: 'flex'}}>
                                        	<span style={{marginRight: '7px', fontSize: '12px', padding: '3px 8px', whiteSpace: 'nowrap'}}>{tag} : </span> 
                                        	<span>
                                        		{tagsEx[tag].values.map(value => <span 
		                                            className={"plan-tag"+(formValue.tagsEx&&formValue.tagsEx[tag]&&formValue.tagsEx[tag].includes(value)?' selected':'')} 
		                                            style={{
		                                            	background: formValue.tagsEx&&formValue.tagsEx[tag]&&formValue.tagsEx[tag].includes(value)? tagsEx[tag].Color: tagsEx[tag].color,
		                                            	cursor: 'pointer', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', display: 'inline-block'}} 
		                                            onClick={(e)=>{toggleTag('tagsEx', tag, value, tagsEx[tag].multi)}}>
		                                            {value} 
	                                        	</span>)}
                                        	</span>
                                        </div>
                                    )}
                                    {/*
                                    <span style={{border: '1px solid #ddd', color: '#222', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', cursor: 'pointer', display: 'inline-block'}} onClick={()=>{addTag()}}>+ new tag</span>
                                    <span style={{border: '1px solid #ddd', color: '#222', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', cursor: 'pointer', display: 'inline-block'}} onClick={()=>{removeTag()}}>- remove tag</span>
                                	*/}
                                </div>
                            </div>

									<Button
										className="button"
										variant="light"
										disabled={!formValue.tagsEx || !Object.keys(formValue.tagsEx).length}
										style={{ borderRadius: '5px', fontSize: '12px', color: '#222', background: '#eee', marginTop: '50px' }}
										onClick={() => {
											
											autoAssignmmentAlgorithm("workout plan");
										}}
									>
										<FontAwesomeIcon
											icon={faListAlt}
											size="1x"
											className="mr-2"
										/>
										Submit assignment rule
									</Button>

								</div>
							</Modal.Body>
						</Modal>

						<Modal
							show={showFilter}
							onHide={() => {
								setSettingRule(false);
								setShowFilter(false);
							}}
						>
							<UserFilter
								handleClose={(dismissData) => {
									setShowFilter(false);
									if (dismissData.filterData) {
										setFilterData(dismissData.filterData);
									}

									if (settingRule) {
										setSettingRule(false);

										var conditions = JSON.parse(
											JSON.stringify(dismissData.filterData)
										);
										delete conditions.groupId;
										delete conditions.mealPlanId;
										delete conditions.workoutPlanId;

										if (Object.keys(conditions).length) {
											addRule();
										} else {
											alert("no conditions have been selected");
										}
									}
								}}
								filterData={filterData}
								mealPlans={[]}
								workoutPlans={[]}
								groups={[]}
								challenge={{}}
								settingRule={settingRule}
								allergies={allergies}
								medicalConditions={medicalConditions}
								countries={countries}
								countriesObj={countriesObj}
							/>
						</Modal>
					</div>

			</div>
		</>
	);
}
