/** @format */

import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import { firestore, storage } from "../../services/firebase";

import userImg from "../../assets/images/user.png";
import BioCard from "../../components/BioCard";
import OneToOneComponent from "../../components/ChallengesComponents/OneToOneComponent";
import ChallengesComponent from "../../components/ChallengesComponents/ChallengesComponent";
import Programs from "../../components/Programs/Programs";
import { fbImage, numberWithCommas } from "../../functions/helpers.js";
import "./Profile.scss";
import { Header } from "../../components/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faExternalLink,
} from "@fortawesome/free-solid-svg-icons";

export function Profile(props) {
	const initFormValue = {
		nutritionistTitle: "",
		nutritionistBio: "",
		assistantName: "",
	};

	const [formValue, setFormValue] = useState(initFormValue);
	const [user, setUser] = useState(JSON.parse(localStorage.getItem("userDetails")));
	const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem("adminDetails")).role.includes("admin"));

	const [loader, setLoader] = useState(false);
	const [loading, setLoading] = useState(false);
	const [followers, setFollowers] = useState([]);
	const [allFollowers, setAllFollowers] = useState([]);
	const [challengeMembers, setchallengeMembers] = useState(0);
	const [programMembers, setProgramMembers] = useState(0);
	const [page, setPage] = useState(1);
	const [moreLoader, setMoreLoader] = useState(false);
	const [lastVisible, setLastVisible] = useState();

	async function getUserData() {
		const querySnapshot = await firestore("users").doc(user.id).get();

		setUser(querySnapshot.data());
		setFormValue({
			nutritionistTitle: querySnapshot.data().nutritionistTitle,
			nutritionistBio: querySnapshot.data().nutritionistBio,
			assistantName: querySnapshot.data().assistantName,
		});
	}
	function getAllFollowers() {
		setLoading(true);

		const userDetails = JSON.parse(localStorage.getItem("userDetails"));

		const subscribe = firestore("users")
			.doc(user.id)
			.collection("followers")
			//.where('role', "array-contains-any", ["admin"])
			//.onSnapshot(function(snapshot){
			.get()
			.then(function (snapshot) {
				const users = snapshot.docs.length
					? snapshot.docs.map((d) => {
							var obj = d.data();
							obj.id = d.id;
							return /*obj*/ d.id;
					  })
					: [];

				if (users.length > 0) setAllFollowers(users);
				else {
					setLoader(false);
					setLoading(false);
				}
			});

		return [subscribe];
	}

	function getFollowers() {

		let perPage = [];
		let pageSize = 100;

		const subscribe = firestore("users")
			.doc(user.id)
			.collection("followers")
			//.where('role', "array-contains-any", ["admin"])
			//.onSnapshot(function(snapshot){
			.orderBy('datetime', 'desc')
			//.startAfter(lastVisible || null)
			.limit(pageSize)
			.get()
			.then(function (snapshot) {
				const followers = snapshot.docs.length
					? snapshot.docs.map((d) => {
							var obj = d.data();
							obj.id = d.id;
							return obj;
					  })
					: [];

				if(snapshot.docs.length) setLastVisible(snapshot.docs[snapshot.docs.length-1]);

				setFollowers((Followers) => Followers.concat(followers) || 0);

				setLoader(false);
				setMoreLoader(false);
			});

		return;
	}


	const listInnerRef = useRef();

	const onScroll = () => {
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight === scrollHeight) {
				console.log("reached bottom");
				setPage(page + 1)
			}
		}
	};


	useEffect(() => {
		getUserData();
		return () => {};
	}, []);

	useEffect(() => {
		setchallengeMembers();
		return () => {};
	}, []);

	useEffect(() => {
		setMoreLoader(true);
		getFollowers();
		//getAllFollowers();

		return () => {
			console.log("Do some cleanup");
			//unsubscribes.map((unsubscribe) => unsubscribe())
		};
	}, []);

	useEffect(() => {
		if (page > 1) {
			setMoreLoader(true);
			getFollowers();
		}
	}, [page]);

	useEffect(() => {
		if (allFollowers.length) getFollowers();
		else setLoader(false);
	}, [allFollowers]);

	return (
		<div className="profile-wrapper">
			<Header header="Profile" />
			<hr />
			<div className="main-section">
				<div>
					<h4>Welcome Back, {user.name.split(" ").slice(0, 1).join("")}</h4>
					{challengeMembers > 0 && (
						<div className="number-count" style={{ marginBottom: "10px" }}>
							You have
							<span> {challengeMembers} </span>
							Challenge Members
						</div>
					)}

					<BioCard
						image={fbImage(user.image)}
						name={user.name}
						title={formValue.nutritionistTitle}
						assistantName={formValue.assistantName}
						bio={formValue.nutritionistBio}
						getUserData={getUserData}
					/>
				</div>

				<div>
					{loading ? (
						<div className="loader text-center">
							<Spinner animation="border" size={"sm"} role="status" />
						</div>
					) : (
						<>
							{challengeMembers > 0 ? (
								<>
									<h4>Followers</h4>
									<div className="number-count">
										You have
										<span>
											{" "+numberWithCommas(user.followersCount)+"  "}
										</span>
										followers
									{/*<FontAwesomeIcon icon={faExternalLink} size="1x" className="mr-2" style={{float: 'right'}}/>*/}
									{/*<span style={{textDecoration: 'underline', float: 'right', cursor: 'pointer', fontWeight: 'normal', color: '#999'}} onClick={()=>{window.open('/followers')}}>see all</span>*/}
									</div>
								</>
							) : (
								<h4>
									Followers (
									<b>
										{" "+numberWithCommas(user.followersCount)+"  "}
									</b>
									)
									{/*<span style={{textDecoration: 'underline', float: 'right', cursor: 'pointer', fontWeight: 'normal', color: '#999'}} onClick={()=>{window.open('/followers')}}>see all</span>*/}
								</h4>
							)}
							<div
								className="followers"
								style={challengeMembers > 0 ? { marginTop: "35px" } : {}}
								onScroll={onScroll}
								ref={listInnerRef}
							>
								{followers.length > 0 ? (
									followers.map((d) => (
										<div key={d.id}>
											<div className="followers-container" key={d.id}>
												<div
													className="background-img"
													style={{
														backgroundImage: `url(${
															d.userImage ? fbImage(d.userImage) : userImg
														})`,
													}}
												></div>

												<span>{d.userName}</span>
											</div>
										</div>
									))
								) : (
									<div style={{ textAlign: "center" }}>No followers yet</div>
								)}
							</div>
						</>
					)}

					{moreLoader && (
						<div className="loader text-center" style={{ padding: "10em" }}>
							<Spinner animation="border" size={"lg"} role="status" />
						</div>
					)}
				</div>
			</div>
			<hr />

			<div className="one-to-one-followup">
				<section className="one-to-one">
					<OneToOneComponent />
				</section>
			</div>
			<hr />

			<ChallengesComponent setchallengeMembers={setchallengeMembers} />
			<hr />

			{isAdmin && <Programs setProgramMembers={setProgramMembers}/>}
		</div>
	);
}
