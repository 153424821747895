/** @format */

import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import { Spinner, Card, Form, Modal, Button } from "react-bootstrap";
import firebase from 'firebase/compat/app';
import { firestore, storage } from "../../services/firebase";
import moment from "moment";
import * as _ from "lodash";

import imageCompression from "browser-image-compression";
import { ReactComponent as VideosIcon } from "../../assets/icons/camera.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faComment } from "@fortawesome/free-solid-svg-icons";
import logoImg from "../../assets/icons/icon.png";
import "./Videos.css";
import { Header } from "../../components/Header";

export function Tutorials(props) {
	const [loader, setLoader] = useState(true);
	const [groups, setGroups] = useState([]);
	const [videos, setVideos] = useState([]);

	const [isVideoChanged, setIsVideoChanged] = useState(false);
	const [videoFile, setVideoFile] = useState(false);
	const [videoBlob, setVideoBlob] = useState(false);
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [videoPosted, setVideoPosted] = useState(false);
	const [VideoError, setVideoError] = useState("");
	const [ignore, setIgnore] = useState({});
	const [rounds, setRounds] = useState(false);
	const [comments, setComments] = useState([]);
	const [showComments, setShowComments] = useState(false);
	const [newMessage, setNewMessage] = useState("");
	const [isSubmitCommentLoader, setIsSubmitCommentLoader] = useState(false);
	const [caption, setCaption] = useState();

	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);
	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);

	useEffect(() => {
		var unsubscribes = getCollections();

		return () => {
			console.log("Do some cleanup");
			//unsubscribes.map((unsubscribe) => unsubscribe())
		};
	}, []);

	useEffect(() => {
		if (showComments) {
			firestore("tutorials")
				.doc(showComments)
				.collection("comments")
				.orderBy("datetime", "asc")
				.get()
				.then((snapshot) => {
					var comments = snapshot.docs.length
						? snapshot.docs.map((d) => {
								var obj = d.data();
								obj.id = d.id;
								return obj;
						  })
						: [];

					comments.map((d) => {
						if (user.id === d.userId || admin.id === d.userId) d.ans = true;
						return d;
					});

					setComments(comments);
				});
		}

		return () => {
			console.log("Do some cleanup");
			//unsubscribes.map((unsubscribe) => unsubscribe())
		};
	}, [showComments]);

	function addMessage(message) {
		console.log(message);

		setNewMessage({
			comment: message,
			nutritionistId: user.id,
			userId: admin.id,
			userName: admin.name,
			userImage: admin.image,
		});
	}

	async function submitComment(e) {
		e.stopPropagation();
		e.preventDefault();

		setIsSubmitCommentLoader(true);

		newMessage.datetime = moment.utc().format();
		await firestore("tutorials")
			.doc(showComments)
			.collection("comments")
			.add(newMessage);

		setIsSubmitCommentLoader(false);
		setShowComments(false);
	}

	function getCollections() {
		const subscribeVideos = firestore("tutorials")
			//.where('userId', '==', user.id)
			//.where('challengeId', '==', selectedChallenge)
			//.where('type', '==', "Nutritionist Video")
			//.orderBy('datetime', 'desc')
			//.limit(10)
			.onSnapshot({ includeMetadataChanges: true }, function (snapshot) {
				var videos = _.orderBy(
					snapshot.docs.length
						? snapshot.docs.map((d) => {
								var obj = d.data();
								obj.id = d.id;
								return obj;
						  })
						: [],
					"datetime",
					"desc"
				);

				setVideos(videos);

				if (loader) setLoader(false);
			});

		return [subscribeVideos];
	}

	async function postVideo() {
		//var caption = prompt("Please enter caption");

		setIsSubmitLoader(true);

		const fileName = user.id + "_" + Number(new Date());
		const uploaded = await storage()
			.ref()
			.child(`/tutorials/${fileName}`)
			.put(videoFile, { cacheControl: "public,max-age=31536000" });
		const child = await storage().ref().child(uploaded.metadata.fullPath);
		const videoLink = await child.getDownloadURL();
		const fullPath = uploaded.metadata.fullPath;

		var postBy = "nutritionist"; //admin

		if (user.id === admin.id) postBy = "nutritionist";
		else if (user.collaborators && user.collaborators.includes(admin.id))
			postBy = "collaborator";
		else if (user.assistants && user.assistants.includes(admin.id))
			postBy = "assistant";

		var post = {
			//challengeName: challenge.name,
			//challengeId: challenge.id,
			userId: admin.id,
			userName: admin.name,
			type: "video",
			videoLink: videoLink,
			//fileType: 'video',
			//likesCount: 0,
			//groupIds: ['none'],
			userImage: admin.image,
			created: firebase.firestore.FieldValue.serverTimestamp(),
			datetime: new Date().toISOString(),
			//postBy: postBy
		};

		//Array.prototype.push.apply(post.groupIds, groups.map((d)=>d.id))

		//var targetGroups = [];
		//groups.map((group) => {if(targetGroups.includes(group.id)) {targetGroups.push(group.id)}})
		//post.groups = groups.filter((group) => )

		if (caption) post.caption = caption;

		console.log(post);

		firestore("tutorials")
			.add(post)
			.then(function (doc) {
				setIsSubmitLoader(false);
				//setVideoPosted('video posted to '+ post.challengeName + ' feed')
				setVideoPosted("posted to home feed.");
				setTimeout(function () {
					setVideoPosted(false);
				}, 10000);

				return;
			})
			.catch(function (error) {
				console.error("Error finding/updating user document: ", error);
			});
	}

	function onVideoUpload(e) {
		fileSelectHandler(e);
	}

	function fileDragHover(e) {
		var fileDrag = document.getElementById("file-drag");
		e.stopPropagation();
		e.preventDefault();
		fileDrag.className =
			e.type === "dragover" ? "hover" : "modal-body file-upload";
	}

	async function fileSelectHandler(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;
		// Cancel event and hover styling
		fileDragHover(e);

		// Process all File objects
		for (var i = 0, file; (file = files[i]); i++) {
			var options = {
				maxSizeMB: 50,
				//maxWidthOrHeight: 400
			};
			try {
				setVideoFile(file);
				setVideoBlob(URL.createObjectURL(file));
				parseFile(file);
			} catch (error) {
				console.error("error fileSelectHandler", error);
				setVideoError("please upload a valid video file");
			}
		}
		setIsVideoChanged(true);

		//postVideo()
	}

	function parseFile(file) {
		output("<strong>" + encodeURI(file.name) + "</strong>");
	}

	function output(msg) {
		// Response
		var m = document.getElementById("messages");
		m.innerHTML = msg;
	}

	function fbImage(imageLink) {
		if (!imageLink) return imageLink;
		var query = imageLink.split("asid=")[1];
		if (!query) return imageLink;
		var fbId = query.split("&")[0];
		if (!fbId) return imageLink;

		return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
	}

	return (
		<div className="tutorials-page">
			<Header header="Tutorials" />
			<hr />
			<h4>Tutorials</h4>
			<Card className="my-3">
				<div id="videos" className="h-100">
					<div>
						{loader ? (
							<div className="loader text-center">
								<Spinner animation="border" size={"sm"} role="status" />
							</div>
						) : (
							<div className="challenge">
								<div>
									<div className="videos">
										{admin.role && admin.role.includes("admin") && (
											<div>
												<div
													className="videos-uploader"
													style={{ height: "100%" }}
												>
													<div className="uploader">
														<input
															id="file-upload"
															type="file"
															name="fileUpload"
															accept="video/*"
															onChange={(e) => onVideoUpload(e)}
														/>
														<label
															htmlFor="file-upload"
															id="file-drag"
															onDragOver={(e) => {
																fileDragHover(e);
															}}
															onDragLeave={(e) => {
																fileDragHover(e);
															}}
															onDrop={(e) => {
																fileSelectHandler(e);
															}}
														>
															{videoBlob ? (
																<ReactPlayer
																	className="video-file-container"
																	id="file-video"
																	controls
																	url={videoBlob}
																	alt="video preview"
																/>
															) : (
																<div className="video-file-container-empty">
																	<VideosIcon />
																</div>
															)}
															<div id="start">
																<div>Select a video or drag here</div>
															</div>
															<div id="response" className="hidden">
																<div id="messages"></div>
															</div>
														</label>

														{
															videoFile ? (
																isSubmitLoader ? (
																	<Spinner
																		animation="border"
																		size={"sm"}
																		role="status"
																	/>
																) : (
																	<>
																		<Form.Control
																			as="textarea"
																			rows="2"
																			id="caption"
																			onInput={(e) =>
																				setCaption(e.target.value)
																			}
																			placeholder="enter title"
																			style={{
																				borderColor: "#ddd",
																				width: "80%",
																				margin: "0 auto",
																				marginBottom: "1em",
																			}}
																		/>
																		<span
																			style={{ padding: "8px 25px" }}
																			id="file-upload-btn"
																			className=" btn-primary button button-primary"
																			onClick={(e) => {
																				postVideo();
																			}}
																		>
																			post video
																		</span>
																	</>
																)
															) : (
																<span
																	style={{ opacity: 0, pointerEvents: "none" }}
																>
																	upload video
																</span>
															)
															//<span id="file-upload-btn" className="btn btn-primary" style={{background: '#aaa', cursor: 'default'}}>post video</span>
															//<FontAwesomeIcon icon={faPlus} size="4x" className="mr-2" style={{color: '#aaa', cursor: 'pointer'}}/>
														}
													</div>

													{videoPosted ? <b>{videoPosted}</b> : ""}
												</div>
											</div>
										)}

										{videos && videos.length ? (
											videos.map((video, i) => (
												<div className="video" key={video.id}>
													<ReactPlayer
														className="video-container"
														url={video.videoLink}
														controls
													/>
													<div
														style={{
															fontSize: ".9em",
															color: "#888",
															marginBottom: "8px",
															padding: "12px",
														}}
													>
														{video.caption}
														<span
															style={{
																// marginLeft: "3em",
																fontSize: ".85em",
																color: "#c3c3c3",
															}}
														>
															<div></div>
															{moment(video.datetime)
																.fromNow()
																.replace("a few seconds ago", "now")}
														</span>
													</div>
												</div>
											))
										) : (
											<div className="no-videos text-center">no videos</div>
										)}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</Card>

			<Modal show={showComments} onHide={() => setShowComments(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Comments</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						className="form"
						style={{}}
						onSubmit={(e) => {
							submitComment(e);
						}}
					>
						{comments && comments.length
							? comments.map((message) => (
									<Form.Group
										key={message.id}
										style={
											message.ans
												? {
														borderRadius: "1em",
														background: "rgb(214 241 226)",
														padding: "1em",
														marginLeft: "0em",
														marginRight: "0em",
														color: "#464646",
												  }
												: {
														borderRadius: "1em",
														background: "#eee",
														padding: "1em",
														marginLeft: "0em",
														marginRight: "0em",
												  }
										}
									>
										<div
											style={{
												fontSize: "0.9em",
												opacity: "0.9",
												float: "right",
											}}
										>
											{moment
												.utc(message.datetime)
												.fromNow()
												.replace("a few seconds ago", "now")}
										</div>

										<div>
											<img
												src={fbImage(message.userImage)}
												height="40"
												width="auto"
												style={{ borderRadius: "20px", marginRight: "10px" }}
											/>
											<span>{message.userName}</span>
										</div>

										<div>{message.comment}</div>
									</Form.Group>
							  ))
							: null}

						<br />
						<Form.Control
							as="textarea"
							rows="3"
							required
							placeholder={"post comment"}
							value={newMessage.message}
							onChange={(e) => {
								addMessage(e.target.value);
							}}
						/>

						<Button
							variant="dark"
							type="submit"
							className="float-right submit-button"
						>
							{isSubmitCommentLoader ? (
								<Spinner animation="border" size={"sm"} role="status" />
							) : (
								<>
									<FontAwesomeIcon
										icon={faComment}
										size="1x"
										className="mr-2"
									/>
									Post Comment
								</>
							)}
						</Button>

						<br style={{ clear: "both" }} />
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
}
