/** @format */

import React, { useEffect, useState } from "react";
import { SideMenu } from "../../components/SideMenu/SideMenu";
import {
	Route,
	BrowserRouter as Router,
	Switch,
	Redirect,
} from "react-router-dom";
import { Profile } from "./Profile";
import { Stats } from "./Stats";
import { Analytics } from "./Analytics";
import { Capsules } from "./Capsules";
import { Videos } from "./Videos";
import { MealPlansPage } from "./MealPlansPage";
import { UsersPage } from "./users/UsersPage";
import { Questions } from "./Questions";
import { Messages } from "./Messages";
import { Reviews } from "./Reviews";
import { Support } from "./Support";
import { Rules } from "./Rules";
import { Rooms } from "./Rooms";
import { Admin } from "./Admin";
import { Roles } from "./Roles";
import { Payments } from "./Payments";
import { Signups } from "./Signups";
import { Funnel } from "./Funnel";
import { Notifications } from "./Notifications";
import { Tutorials } from "./Tutorials";
import { Billing } from "./Billing";
import { Followers } from "./Followers";
import { Promocodes } from "./Promocodes";
import { Content } from "./Content";
import { Lifetime } from "./Lifetime";
import { Customers } from "./Customers";
import { Food } from "./Food";
import { Recipes } from "./Recipes";
import { Exercises } from "./Exercises";
import { Feed } from "./Feed";
import { Feedback } from "./Feedback";
import { Followup } from "./Followup";
import { Receipts } from "./Receipts";
import { Requests } from "./Requests";
import { Coaches } from "./Coaches";
import { Orders } from "./Orders";
import { Refunds } from "./Refunds";
// import { ChallengesPage } from "./challenges/ChallengesPage";
import { WorkoutPlans } from "./WorkoutPlans";
import { SelectNutritionist } from "./SelectNutritionist";
import ContactAdmin from "../../components/ContactAdmin";
import { NotFound } from "./NotFound";

import ContactSupport from "../../components/Support";

import "./authMain.scss";
import { AddEditChallenge } from "./challenges/addEditChallenge/AddEditChallenge";
import { AddEditOneToOne } from "./challenges/addEditChallenge/AddEditOneToOne";
import { AddEditProgram } from "./programs/AddEditProgram";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHamburger } from "@fortawesome/free-solid-svg-icons";
import { TrackPage } from "../../services/trackers";

library.add(faHamburger);

export function Auth() {
	const [userDetails, setUserDetails] = useState({});
	const [adminDetails, setAdminDetails] = useState({});
	const [role, setRole] = useState({});
	useEffect(() => {
		const userDetailsStorage = JSON.parse(localStorage.getItem("userDetails"));
		const adminDetailsStorage = JSON.parse(
			localStorage.getItem("adminDetails")
		);

		if (userDetailsStorage) setUserDetails(userDetailsStorage);
		if (adminDetailsStorage) setAdminDetails(adminDetailsStorage);

		if (adminDetailsStorage && adminDetailsStorage.role) {
			var _role = {};
			if (adminDetailsStorage.role.includes("assistant"))
				_role.assistant = true;
			if (adminDetailsStorage.role.includes("collaborator"))
				_role.collaborator = true;
			if (adminDetailsStorage.role.includes("nutritionist"))
				_role.nutritionist = true;
			if (adminDetailsStorage.role.includes("admin")) _role.admin = true;
			setRole(_role);
		}
	}, []);

	return (
		<>
			<Router>
				<TrackPage />
				{/*{ (userDetails.role === 'nutritionist') &&*/}
				<section id="sidebar-wrapper" className="border-right">
					<SideMenu />
				</section>
				{/*}*/}
				<section id="page-content-wrapper">
					{/*<FontAwesomeIcon icon={faHamburger} size="4x" className="mr-2"/>*/}
					<div className="container-fluid">
						<div>
							{Object.keys(role).length ? (
								<Switch>
									{(role.admin || role.collaborator || role.nutritionist || role.assistant) && (
										<Route path="/profile" component={Profile} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route path="/stats" component={Stats} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route path="/capsules" component={Capsules} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route path="/videos" component={Videos} />
									)}
									{(role.admin || role.collaborator || role.assistant) && (
										<Route
											path="/select-nutritionist"
											component={SelectNutritionist}
										/>
									)}
									{role.admin || role.collaborator || role.assistant ? (
										<Redirect from="/login" to="/select-nutritionist" />
									) : (
										<Redirect from="/login" to="/stats" />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route exact path="/meal-plans" component={MealPlansPage} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route
											exact
											path="/workout-plans"
											component={WorkoutPlans}
										/>
									)}
									{/* {(role.admin || role.nutritionist || role.assistant) && <Route exact path="/challenges" component={ChallengesPage} />} */}
									{(role.admin || role.nutritionist || role.assistant) && (
										<Route
											exact
											path="/add-challenge"
											component={AddEditChallenge}
										/>
									)}
									{(role.admin || role.nutritionist || role.assistant) && (
										<Route
											exact
											path="/edit-challenge/:id"
											component={AddEditChallenge}
										/>
									)}
									{(role.admin || role.nutritionist || role.assistant) && (
										<Route
											exact
											path="/add-program"
											component={AddEditProgram}
										/>
									)}
									{(role.admin || role.nutritionist || role.assistant) && (
										<Route
											exact
											path="/edit-program/:id"
											component={AddEditProgram}
										/>
									)}
									{(role.admin || role.nutritionist || role.assistant) && (
										<Route
											exact
											path="/add-oneToOne"
											component={AddEditOneToOne}
										/>
									)}
									{(role.admin || role.nutritionist || role.assistant) && (
										<Route
											exact
											path="/edit-oneToOne/:id"
											component={AddEditOneToOne}
										/>
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route exact path="/users" component={UsersPage} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route exact path="/feed" component={Feed} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route exact path="/questions" component={Questions} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route exact path="/questions/:id" component={Questions} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route exact path="/messages" component={Messages} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route exact path="/reviews" component={Reviews} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route exact path="/tutorials" component={Tutorials} />
									)}
									{(role.admin || role.collaborator || role.nutritionist) && (
										<Route exact path="/billing" component={Billing} />
									)}
									{(role.admin ||
										role.collaborator ||
										role.nutritionist ||
										role.assistant) && (
										<Route exact path="/followers" component={Followers} />
									)}
									{role.admin && (
										<Route exact path="/admin" component={Admin} />
									)}
									{role.admin && (
										<Route exact path="/support" component={Support} />
									)}
									{role.admin && (
										<Route exact path="/support/:id" component={Support} />
									)}
									{role.admin && (
										<Route exact path="/rules" component={Rules} />
									)}
									{role.admin && (
										<Route exact path="/rooms" component={Rooms} />
									)}
									{role.admin && (
										<Route exact path="/roles" component={Roles} />
									)}
									{role.admin && (
										<Route exact path="/analytics" component={Analytics} />
									)}
									{role.admin && (
										<Route exact path="/payments" component={Payments} />
									)}
									{role.admin && (
										<Route exact path="/signups" component={Signups} />
									)}
									{role.admin && (
										<Route exact path="/funnel" component={Funnel} />
									)}
									{role.admin && (
										<Route
											exact
											path="/notifications"
											component={Notifications}
										/>
									)}
									{role.admin && (
										<Route exact path="/promocodes" component={Promocodes} />
									)}
									{role.admin && (
										<Route exact path="/content" component={Content} />
									)}
									{role.admin && (
										<Route exact path="/lifetime" component={Lifetime} />
									)}
									{role.admin && (
										<Route exact path="/customers" component={Customers} />
									)}
									{(role.admin ||
										adminDetails.id === "RUlIkcTovJ1CWgXk8Vv4") && (
										<Route exact path="/food" component={Food} />
									)}
									{(role.admin ||
										adminDetails.id === "uAJZL3wl9f8J3hbL4Rsl") && (
										<Route exact path="/recipes" component={Recipes} />
									)}
									{role.admin && (
										<Route exact path="/exercises" component={Exercises} />
									)}
									{role.admin && (
										<Route exact path="/feedback" component={Feedback} />
									)}
									{role.admin && (
										<Route exact path="/followup" component={Followup} />
									)}
									{role.admin && (
										<Route exact path="/receipts" component={Receipts} />
									)}
									{role.admin && (
										<Route exact path="/requests" component={Requests} />
									)}
									{role.admin && (
										<Route exact path="/coaches" component={Coaches} />
									)}
									{role.admin && (
										<Route exact path="/orders" component={Orders} />
									)}
									{role.admin && (
										<Route exact path="/refunds" component={Refunds} />
									)}
									<Route path="/404" component={NotFound} />
									{userDetails.name ? (
										<Redirect from="/" to="/stats" />
									) : (
										<Redirect from="/" to="/select-nutritionist" />
									)}
									<Redirect from="*" to="/404" />
								</Switch>
							) : (
								<ContactAdmin />
							)}
						</div>
					</div>
				</section>
			</Router>
			{role.nutritionist && <ContactSupport />}
		</>
	);
}

export default Auth;
