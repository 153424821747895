/** @format */

import React from "react";

export const Header = ({ header, subHeader }) => {
	return (
		<div style={{ marginTop: "1rem", color: "#909090", display: "flex" }}>
			<div className="main-header">{header} </div>
			{subHeader && (
				<div className="sub-header">
					&nbsp; / <span style={{ color: "#faa620" }}>{subHeader}</span>
				</div>
			)}
		</div>
	);
};
