/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Spinner, Col, Form } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faPlusCircle,
	faMinusCircle,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { firestore, storage } from "../services/firebase";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import * as _ from "lodash";
import "./AddRecipe.scss";
import firebase from 'firebase/compat/app';

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faPlusCircle, faMinusCircle);


function getRandomColor() {
  var letters = '0123456789AB';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}
function setColors(tags) {
  var obj = {}
  tags.map(tag =>{
  	obj[tag] = getRandomColor()
  })
  return obj;
}
function hexToRGBA(hex, opacity) {
  hex = hex.replace('#', ''); // remove the # if present
  var r = parseInt(hex.substring(0,2), 16);
  var g = parseInt(hex.substring(2,4), 16);
  var b = parseInt(hex.substring(4,6), 16);
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
}

export function AddExercise(props) {
	// const totalDays = 30;
	const [tags, setTags] = useState({});
	const [isImageChanged, setIsImageChanged] = useState(false);
	const [exerciseImage, setexerciseImage] = useState("");
	const [imageFile, setImageFile] = useState(false);
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [imageError, setImageError] = useState("");
	const inputImg = useRef(null);

	const initFormValue = {
		name: "",
		duration: "",
		description: "",
		videoLink: "",
		image: "",
	};
	const [formValue, setFormValue] = useState(initFormValue);
	useEffect(() => {
		setFormData();
		return () => {};
	}, []);

	function setFormData() {
		if (document.getElementById("file-image"))
			document.getElementById("file-image").classList.remove("hidden");
		if (document.getElementById("start"))
			document.getElementById("start").classList.remove("hidden");
		if (document.getElementById("response"))
			document.getElementById("response").classList.add("hidden");
		if (document.getElementById("file-upload-form"))
			document.getElementById("file-upload-form").reset();
		setImageFile(false);
		setexerciseImage("");
		setIsImageChanged(false);
		let formValueClone = initFormValue;
		if (props.exerciseData) {
			formValueClone = props.exerciseData;
			setexerciseImage(props.exerciseData.image);
			document.getElementById("file-image").classList.remove("hidden");
		} else {
			setexerciseImage("");
		}
		setFormValue(_.clone(formValueClone));
	}


	useEffect(() => {
		getExerciseTags();
		return () => {};
	}, []);

    async function getExerciseTags() {
        const exerciseTagsDoc = await firestore('constants').doc('exerciseTags').get();
        var tags = {
        	...exerciseTagsDoc.data()
        }
        for(var tag in tags) {
        	const color = getRandomColor(tag)
        	tags[tag].color = hexToRGBA(color, 0.1)
        	tags[tag].Color = color
        }
        setTags(tags)
    }


	function onImageUpload(e) {
		fileSelectHandler(e);
	}

	async function submitForm(e) {
		e.stopPropagation();
		e.preventDefault();
		if (isImageChanged && imageFile) {
			setIsSubmitLoader(true);
			const fileName = moment().format();
			const uploaded = await storage()
				.ref()
				.child(`/exercises/${fileName}`)
				.put(imageFile, { cacheControl: "public,max-age=31536000" });
			const child = await storage().ref().child(uploaded.metadata.fullPath);
			formValue.image = await child.getDownloadURL();
			formValue.fullPath = uploaded.metadata.fullPath;
		} else if (props.exerciseData.key) {
			setIsSubmitLoader(true);
			setIsSubmitLoader(false);
			props.handleClose(true);
		} else if (!props.exerciseData.key && !props.exerciseData.image) {
			//setImageError('image is required');
			//return;
		}

		setIsSubmitLoader(true);

		if (
			["start", "rest", "end"].includes(formValue.name.trim().toLowerCase())
		) {
			formValue.isCard = true;
			formValue.name = formValue.name.trim().toLowerCase();
			props.setCardSize("md");
		}

		if (props.exerciseData.key) {
			formValue.datetime = moment().format();
			await firestore("exercises")
				.doc(props.exerciseData.key)
				.update(formValue);
		} else {
			const userDetails = JSON.parse(localStorage.getItem("userDetails"));
			formValue.nutritionistId = userDetails.id;
			formValue.nutritionistName = userDetails.name;
			formValue.datetime = moment().format();
			await firestore("exercises").add(formValue);
		}
		setIsSubmitLoader(false);
		props.handleClose(true);
		// } else {
		//     setImageError('image is required');
		// }
	}

	function fileDragHover(e) {
		var fileDrag = document.getElementById("file-drag");
		e.stopPropagation();
		e.preventDefault();
		fileDrag.className =
			e.type === "dragover" ? "hover" : "modal-body file-upload";
	}

	async function fileSelectHandler(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;
		// Cancel event and hover styling
		fileDragHover(e);

		// Process all File objects
		for (var i = 0, f; (f = files[i]); i++) {
			// var options = {
			// 	maxSizeMB: 0.3,
			// 	maxWidthOrHeight: 400,
			// };
			try {
				const compressedFile = f; //await imageCompression(f, options);
				setImageError("");
				setImageFile(compressedFile);
				parseFile(compressedFile);
			} catch (error) {
				console.error("error fileSelectHandler", error);
				setImageError("please upload a valid image file");
			}
		}
		setIsImageChanged(true);
	}

	function parseFile(file) {
		output("<strong>" + encodeURI(file.name) + "</strong>");

		// var fileType = file.type;
		var imageName = file.name;

		var isGood = true///\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
		if (isGood) {
			document.getElementById("start").classList.add("hidden");
			document.getElementById("response").classList.remove("hidden");
			// Thumbnail Preview
			document.getElementById("file-image").classList.remove("hidden");
			document.getElementById("file-image").src = URL.createObjectURL(file);
			setexerciseImage(URL.createObjectURL(file));
		} else {
			document.getElementById("file-image").classList.add("hidden");
			document.getElementById("start").classList.remove("hidden");
			document.getElementById("response").classList.add("hidden");
			document.getElementById("file-upload-form").reset();
		}
	}

	function output(msg) {
		// Response
		var m = document.getElementById("messages");
		m.innerHTML = msg;
	}
    
    async function toggleTag(property, tag, value, multi) {

        var selected = formValue[property] && formValue[property][tag] && formValue[property][tag].includes(value)

		const formValueClone = _.clone(formValue);
		if(!formValueClone[property]) formValueClone[property] = {}

		if(selected){//remove

			formValueClone[property][tag] = formValueClone[property][tag].filter(item => item !== value)
			if(!formValueClone[property][tag].length) delete formValueClone[property][tag]
		}
		else{//add

			if(multi) {
				if(formValueClone[property][tag]) formValueClone[property][tag].push(value)
				else formValueClone[property][tag] = [value]
			}
			else{
				formValueClone[property][tag] = [value]
			}
		}
		setFormValue(formValueClone);
    }

    async function addTag() {

        if(tags[tag]) return alert('Tags already has that tag')

        var tag = prompt("Please enter new tag");

        if(!tag) return

		const clone = _.clone(tags);
		clone[tag] = getRandomColor();
		setTags(clone);

        await firestore('constants').doc('recipeTags').update({value: firebase.firestore.FieldValue.arrayUnion(tag)});
    }

    async function removeTag() {

        var tag = prompt("Please enter tag you want to remove");

        if(!tag) return

        if(!tags[tag]) return alert('Tags do not have that tag')

		const formValueClone = _.clone(formValue);
		if(!formValueClone.tags) formValueClone.tags = []
		formValueClone.tags = formValueClone.tags.filter(item => item !== tag)
		setFormValue(formValueClone);

		const clone = _.clone(tags);
		delete clone[tag]
		setTags(clone);

		await firestore('constants').doc('recipeTags').update({value: firebase.firestore.FieldValue.arrayRemove(tag)});
    }

    
	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title style={{ textTransform: "capitalize" }}>
					{formValue.isCard
						? "Set " + formValue.name
						: props.exerciseData.key
						? "Edit Exercise"
						: "Add Exercise"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					className="form"
					id="file-upload-form"
					ref={inputImg}
					onSubmit={(e) => {
						submitForm(e);
					}}
				>
					<Form.Row className="add-workout-container">
						{!formValue.isCard && (
							<Col xs={6}>
								<Form.Group>
									<Form.Label>Exercise</Form.Label>

									{/*<Form.Control required placeholder="e.g squats" value={formValue.name} disabled={formValue.isCard} onChange={e => {
                                const formValueClone = _.clone(formValue);
                                formValueClone.name = e.target.value;
                                setFormValue(formValueClone);
                            }} />*/}

									<Autocomplete
										id="combo-box-demo"
										freeSolo={false}
										style={{ width: "auto" }}
										size="small"
										options={props.exercises}
										value={{ name: formValue.name } || {}}
										getOptionSelected={(option, value) =>
											option.name === value.name
										}
										getOptionLabel={(option) =>
											option.name ? option.name : ""
										}
										//groupBy={(option) => option.muscle}
										//className={d.food? '': 'new'}
										//style={{ width: d.food? '40%' : '40%' }}
										renderInput={(params) => (
											<TextField
												style={{ fontSize: "14px" }}
												{...params}
												variant="outlined"
												placeholder="Add Exercise"
											/>
										)}
										ListboxProps={{
											style: {
												padding: "0",
												maxWidth: "354px",
											},
										}}
										onChange={(e, value) => {
											const formValueClone = _.clone(formValue);
											if (!value) value = {};
											if (typeof value === "string")
												formValueClone.name = value;
											if (value.name) formValueClone.name = value.name;
											if (value.muscle) formValueClone.muscle = value.muscle;
											if (value.level) formValueClone.level = value.level;
											if (value.type) formValueClone.type = value.type;
											if (value.equipment)
												formValueClone.equipment = value.equipment;
											if (value.videoLink)
												formValueClone.videoLink = value.videoLink;
											setFormValue(formValueClone);
										}}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Row>
										<Col xs={6}>
											<Form.Label style={{ fontSize: ".8rem" }}>
												Level
											</Form.Label>
											<Form.Control
												style={{ fontSize: ".8rem" }}
												disabled
												value={formValue.level || ""}
												placeholder="level"
											/>
										</Col>
										<Col xs={6}>
											<Form.Label style={{ fontSize: ".8rem" }}>
												Muscle
											</Form.Label>
											<Form.Control
												style={{ fontSize: ".8rem" }}
												disabled
												value={formValue.muscle || ""}
												placeholder="muscle"
											/>
										</Col>
									</Form.Row>
								</Form.Group>
								<Form.Group>
									<Form.Row>
										<Col xs={6}>
											<Form.Label style={{ fontSize: ".8rem" }}>
												Type
											</Form.Label>
											<Form.Control
												style={{ fontSize: ".8rem" }}
												disabled
												value={formValue.type || ""}
												placeholder="type"
											/>
										</Col>
										<Col xs={6}>
											<Form.Label style={{ fontSize: ".8rem" }}>
												Equipment
											</Form.Label>
											<Form.Control
												style={{ fontSize: ".8rem" }}
												disabled
												value={formValue.equipment || ""}
												placeholder="equipment"
											/>
										</Col>
									</Form.Row>
								</Form.Group>
							</Col>
						)}
						<Col xs={formValue.isCard ? 12 : 6}>
							{exerciseImage && !formValue.isCard && (
								<div
									className="remove-img"
									onClick={() => {
										setIsImageChanged(false);
										setexerciseImage("");
										setImageFile(false);
										inputImg.current.reset();
									}}
								>
									<div className="icon">
										<FontAwesomeIcon icon={faTimes} size="sm" />
									</div>
								</div>
							)}

							<div className="d-flex photo-container">
								<div
									className="uploader"
									style={
										formValue.isCard
											? {
													height: "300px",
													display: "flex",
													justifyContent: "center",
											  }
											: {}
									}
								>
									<input
										id="file-upload"
										type="file"
										name="fileUpload"
										accept="image/*"
										onChange={(e) => onImageUpload(e)}
									/>
									<label
										htmlFor="file-upload"
										style={formValue.isCard ? { width: "" } : {}}
										id="file-drag"
										onDragOver={(e) => {
											fileDragHover(e);
										}}
										onDragLeave={(e) => {
											fileDragHover(e);
										}}
										onDrop={(e) => {
											fileSelectHandler(e);
										}}
									>
										<img
											id="file-image"
											alt=""
											src={exerciseImage}
											className="hidden"
											style={{ borderRadius: "7px" }}
										/>
										<div
											id="start"
											className="d-flex"
											style={formValue.isCard ? { marginTop: "10%" } : {}}
										>
											{!exerciseImage && (
												<>
													<div>Select an image or drag here</div>
													<span
														id="file-upload-btn"
														className="button button-secondary"
														style={{ margin: "1em 0px" }}
													>
														Select an image
													</span>
												</>
											)}
										</div>
										<div id="response" className="hidden">
											<div style={{ display: "none" }} id="messages"></div>
										</div>
										{imageError && (
											<div className="m-2 text-center text-danger">
												{imageError}
											</div>
										)}
									</label>
								</div>
							</div>
						</Col>
					</Form.Row>
					{!formValue.isCard && (
						<>
							<Form.Row>
								<Col xs={12}>
									<Form.Label>Description</Form.Label>
									<Form.Control
										as="textarea"
										rows="3"
										placeholder="Description"
										value={formValue.description}
										onChange={(e) => {
											const formValueClone = _.clone(formValue);
											formValueClone.description = e.target.value;
											setFormValue(formValueClone);
										}}
									/>
								</Col>
							</Form.Row>
							<Form.Row>
								<Col xs={12}>
									<Form.Label>Video Link</Form.Label>
									<Form.Control
										placeholder="Youtube link"
										type="url"
										value={formValue.videoLink}
										onChange={(e) => {
											const formValueClone = _.clone(formValue);
											formValueClone.videoLink = e.target.value.trim();
											setFormValue(formValueClone);
										}}
									/>
								</Col>
							</Form.Row>
						</>
					)}

					<Form.Row style={{display: 'none'}}>
						<Col xs="12">
							<Form.Label>Tags</Form.Label>

                            <div style={{maxWidth: 'initial', whiteSpace: 'normal', fontSize: '.8em', marginTop: '0px'}} 
                                >
                                <div style={{marginTop: '1em'}}>
                                    {Object.keys(tags).map(tag => 
                                        <div style={{marginBottom: '0px', display: 'flex'}}>
                                        	<span style={{marginRight: '7px', fontSize: '12px', padding: '3px 8px', whiteSpace: 'nowrap'}}>{tag} : </span> 
                                        	<span>
                                        		{tags[tag].values.map(value => <span 
		                                            className={"plan-tag"+(formValue.tags&&formValue.tags[tag]&&formValue.tags[tag].includes(value)?' selected':'')} 
		                                            style={{
		                                            	background: formValue.tags&&formValue.tags[tag]&&formValue.tags[tag].includes(value)? tags[tag].Color: tags[tag].color,
		                                            	cursor: 'pointer', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', display: 'inline-block'}} 
		                                            onClick={(e)=>{toggleTag('tags', tag, value, tags[tag].multi)}}>
		                                            {value} 
	                                        	</span>)}
                                        	</span>
                                        </div>
                                    )}
                                    {/*
                                    <span style={{border: '1px solid #ddd', color: '#222', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', cursor: 'pointer', display: 'inline-block'}} onClick={()=>{addTag()}}>+ new tag</span>
                                    <span style={{border: '1px solid #ddd', color: '#222', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', cursor: 'pointer', display: 'inline-block'}} onClick={()=>{removeTag()}}>- remove tag</span>
                                	*/}
                                </div>
                            </div>
						</Col>
					</Form.Row>

					<div style={{ margin: "2em 0" }}>
						<Button
							variant="primary"
							type="submit"
							style={{ margin: "auto", display: "flex" }}
							className=" submit-button button button-primary"
						>
							{isSubmitLoader ? (
								<Spinner animation="border" size={"sm"} role="status" />
							) : props.exerciseData.key ? (
								"Save"
							) : (
								"Add"
							)}
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</>
	);
}

export default AddExercise;
