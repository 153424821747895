import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { firestore } from "../services/firebase";
import DeleteAlert from "./DeleteAlert";
import * as _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { faCalendarPlus, faCalendarMinus, faPlus, faTrash, faClone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



export function AddPromocode(props) {
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [isCloneLoader, setIsCloneLoader] = useState(false);
    const [promocodeName, setPromocodeName] = useState('');
    const [promocodeCount, setPromocodeCount] = useState(0);
    const [promocodeLimit, setPromocodeLimit] = useState(1000);
    const [promocodeDiscount, setPromocodeDiscount] = useState(0);
    const [promocodeNutritionist, setPromocodeNutritionist] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');

    useEffect(() => {
        if (props.promocodeData && props.promocodeData.code) setPromocodeName(props.promocodeData.code);
        if (props.promocodeData && props.promocodeData.count) setPromocodeCount(props.promocodeData.count);
        if (props.promocodeData && props.promocodeData.limit) setPromocodeLimit(props.promocodeData.limit);
        if (props.promocodeData && props.promocodeData.discount) setPromocodeDiscount(props.promocodeData.discount);
        if (props.promocodeData && props.promocodeData.nutritionist) setPromocodeNutritionist(props.promocodeData.nutritionist);
    }, []);

    function handleDeleted(dismissModal) {
        return dismissModal === 'deleted' ? props.handleClose(true) : setShowDeleteModal(false);
    }

    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();
        if (promocodeName) {
            setIsSubmitLoader(true);

            if (props.promocodeData.key) {
                const addPromocode = {
                    code: promocodeName.toLowerCase().trim(),
                    count: +promocodeCount,
                    limit: +promocodeLimit,
                    discount: +promocodeDiscount,
                    nutritionist: promocodeNutritionist,
                }
                await firestore('promocodes').doc(props.promocodeData.key).update(addPromocode);
            } else {
                const addPromocode = {
                    code: promocodeName.toLowerCase().trim(),
                    count: +promocodeCount,
                    limit: +promocodeLimit,
                    discount: +promocodeDiscount,
                    nutritionist: promocodeNutritionist,
                }
                await firestore('promocodes').add(addPromocode);
            }
            setIsSubmitLoader(false);
            props.handleClose(true);
        }
    }

    async function clonePlan() {
        if (promocodeName) {
            setIsCloneLoader(true);

            var new_promocodeName = prompt("Enter name for new (cloned) promo code", promocodeName + ' (2)');

            const addPromocode = {
                    code: new_promocodeName.toLowerCase().trim(),
                    count: +promocodeCount,
                    limit: +promocodeLimit,
                    discount: +promocodeDiscount,
                    nutritionist: promocodeNutritionist,
            }
            await firestore('promocodes').add(addPromocode);

            setIsCloneLoader(false);
            props.handleClose(true);
        }
    }

    async function deletePromocode() {
        setDocId(props.promocodeData.key);
        setShowDeleteModal(true);
    }


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{props.promocodeData.key ? 'Edit' : 'Add'} Promo code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="form" onSubmit={(e) => {
                    submitForm(e)
                }}>
                    <Form.Group>
                    <Form.Row>
                        <Col xs={12}>
                          <Form.Label>Code</Form.Label>
                          <Form.Control placeholder="Enter code" defaultValue={props.promocodeData.code} onChange={e => {setPromocodeName(e.target.value);}} required/>
                          <br/>
                        </Col>
                        <Col xs={12}>
                          <Form.Label>Count</Form.Label>
                          <Form.Control type="number" placeholder="Count" defaultValue={props.promocodeData.count} onChange={e => {setPromocodeCount(e.target.value);}} required/>
                          <br/>
                        </Col>
                        <Col xs={12}>
                          <Form.Label>Limit</Form.Label>
                          <Form.Control type="number" placeholder="Limit" defaultValue={props.promocodeData.limit} onChange={e => {setPromocodeLimit(e.target.value);}} required/>
                          <br/>
                        </Col>
                        <Col xs={12}>
                          <Form.Label>Discount</Form.Label>
                          <Form.Control type="number" placeholder="Discount %" defaultValue={100-props.promocodeData.discount} onChange={e => {setPromocodeDiscount(100-e.target.value);}} required/>
                          <br/>
                        </Col>

                        <Col xs={12}>
                          <Form.Label>Nutritionist</Form.Label>
                                    <ButtonGroup
                                        aria-label="First group"
                                        style={{ display: "block", marginTop: '10px', height: '300px', overflowY: 'scroll' }}
                                    >
                                        {props.nutritionists
                                            .sort((a,b)=>{
                                                 if (props.nutritionistIdToName[a].toLowerCase().trim() < props.nutritionistIdToName[b].toLowerCase().trim()) {
                                                    return -1;
                                                  }
                                                  if (props.nutritionistIdToName[a].toLowerCase().trim() > props.nutritionistIdToName[b].toLowerCase().trim()) {
                                                    return 1;
                                                  }
                                                  return 0;
                                            })
                                            .map((c) => (
                                                <Button
                                                    style={{
                                                        width: "100%",
                                                        marginBottom: ".3em",
                                                        fontSize: ".9em",
                                                        backgroundColor: promocodeNutritionist === c? '#cadfbe': '#f8f9fa'
                                                    }}
                                                    variant="light"
                                                    onClick={() => {
                                                        setPromocodeNutritionist(c)
                                                    }}
                                                >
                                                    <b>{props.nutritionistIdToName[c]}</b> {/*<b>{props.nutritionistIdToName[c]}</b>*/}{" "}

                                                </Button>
                                            ))}
                                    </ButtonGroup>
                        </Col>

                    </Form.Row>
                    </Form.Group>


                    <div className="float-right">
                        {props.promocodeData.key &&
                            <Button variant="danger" className="submit-button mr-3" onClick={() => {
                                deletePromocode()
                            }}>
                               <FontAwesomeIcon icon={faTrash} size="1x" className="" /> Delete
                            </Button>}

                        {props.promocodeData.key &&<Button variant="dark" className="submit-button mr-3" onClick={()=>{clonePlan()}}>
                            
                            {isCloneLoader ? <Spinner animation="border" size={'sm'} role="status" /> : <FontAwesomeIcon icon={faClone} size="1x" className="" />} Clone
                        </Button>}

                        <Button variant="info" type="submit" className="submit-button">
                            {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : 'Submit'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
            <Modal show={showDeleteModal} onHide={handleDeleted}>
                <DeleteAlert onHide={handleDeleted} title={'Delete Promo code'} message={'Are you sure you want to delete promo code'} warning={'Users won\'t be able to use it anymore.'} docId={docId} collectionName={'promocodes'} />
            </Modal>
        </>
    );
}

export default AddPromocode
