/** @format */

import React, { useEffect, useState } from "react";
import "./AddEditChallenge.scss";
import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Dropdown,
	DropdownButton,
	Modal,
	Form,
	InputGroup,
	Row,
	Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imageCompression from "browser-image-compression";
import DatePicker from "react-datepicker";
import * as _ from "lodash";
import moment from "moment";
import { NavLink } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { firestore, storage } from "../../../../services/firebase";
import {
	faPlusCircle,
	faMinusCircle,
	faSave,
} from "@fortawesome/free-solid-svg-icons/index";
import { Header } from "../../../../components/Header";

export function AddEditOneToOne(props) {
	const [role, setRole] = useState({});
	const [isImageChanged, setIsImageChanged] = useState(false);
	const [isImageChanged2, setIsImageChanged2] = useState(false);
	const totalDays = 30;
	const durationTypes = ["Days", "Weeks", "Months"];
	const graceTypes = ["Days"];
	const actions = ["Add any meal", "Do any workout", "Drink any water"];

	const features = [
		{ key: "mealPlan", title: "Meal plan", required: false },
		{ key: "workoutPlan", title: "Workout plan", required: false },
		{ key: "chatSupport", title: "Direct chat", required: true },
		{ key: "liveCalls", title: "Live calls", required: true },
		{ key: "statistics", title: "Progress tracking", required: true },
	];

	const dietTypes = [
		{ key: "Standard", title: "Standard" },
		{ key: "Plant Based", title: "Plant Based" },
		{ key: "Pescatarian", title: "Pescatarian" },
		{ key: "Low Carb", title: "Low Carb" },
		{ key: "Keto", title: "Keto" },
	];

	const exerciseTypes = [
		{ key: "home", title: "Home workout" },
		{ key: "gym", title: "Gym workout" },
	];

	const goalOptions = [
		{ key: "Weight loss", title: "Weight loss" },
		{ key: "Muscle gain", title: "Muscle gain" },
		{ key: "Healthy lifestyle", title: "Healthy lifestyle" },
	];

	const packages = [
		{ key: 1, discount: 0, title: "1 Month", required: true },
		{ key: 2, discount: 15, title: "2 Months", required: false },
		{ key: 3, discount: 27, title: "3 Months", required: false },
	];

	const [imageFile, setImageFile] = useState(false);
	const [imageError, setImageError] = useState("");
	const [challengeImage, setChallengeImage] = useState("");

	const [imageFile2, setImageFile2] = useState(false);
	const [imageError2, setImageError2] = useState("");
	const [sponsorImage, setSponsorImage] = useState("");
	// const [challengeDetail, setChallengeDetail] = useState('');

	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
	const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
	const [showBundleConfirmation, setShowBundleConfirmation] = useState(false);
	const initFormValue = {
		videoLink: "",
		name: "",
		duration: "",
		durationType: "Weeks",
		grace: 0,
		image: "",
		price: null,
		price_usd: null,
		price_usd_iap: null,
		equipmentPrice: null,
		features: {},
		packages: {},
		dietTypes: {},
		exerciseTypes: {},
		reminders: [],
		questions: [],
		faq: [],
		isPublished: false,
		isPrivate: false,
		date: new Date(),
		max: 30,
	};
	const [formValue, setFormValue] = useState(initFormValue);
	const [challengeId, setChallengeId] = useState("");
	const [webLink, setWebLink] = useState();

	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);

	useEffect(() => {
		const adminDetailsStorage = JSON.parse(
			localStorage.getItem("adminDetails")
		);
		if (adminDetailsStorage && adminDetailsStorage.role) {
			var _role = {};
			if (adminDetailsStorage.role.includes("assistant"))
				_role.assistant = true;
			if (adminDetailsStorage.role.includes("collaborator"))
				_role.collaborator = true;
			if (adminDetailsStorage.role.includes("nutritionist"))
				_role.nutritionist = true;
			if (adminDetailsStorage.role.includes("admin")) _role.admin = true;
			setRole(_role);
		}

		if (props.match.params && props.match.params.id) {
			setChallengeId(props.match.params.id);
			getChallengeData(props.match.params.id);
		} else {
			setFormData();
		}
		return () => {};
	}, []);

	useEffect(() => {
		firestore("constants")
			.doc("app")
			.get()
			.then((doc) => {
				setWebLink(
					{ welnes: "https://welnes.app", gorilla: "https://gorilla.fitness" }[
						doc.data().value
					]
				);
			});

		return () => {};
	}, []);

	async function getChallengeData(challengeId) {
		let challengeDetails;
		const querySnapshot = await firestore("one_to_one").doc(challengeId).get();
		challengeDetails = querySnapshot.data();
		await getFaq(challengeId, challengeDetails);
		await getQuestions(challengeId, challengeDetails);
		await getReminders(challengeId, challengeDetails);
	}

	async function getReminders(challengeId, challengeDetail) {
		firestore("one_to_one")
			.doc(challengeId)
			.collection("reminders")
			.onSnapshot(async (querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				challengeDetail.reminders = data;
				// setChallengeDetail(challengeDetail);
				setFormData(challengeDetail);
			});
	}

	async function getFaq(challengeId, challengeDetail) {
		firestore("one_to_one")
			.doc(challengeId)
			.collection("faq")
			.onSnapshot(async (querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				challengeDetail.faq = data;
				// setChallengeDetail(challengeDetail);
				setFormData(challengeDetail);
			});
	}

	async function getQuestions(challengeId, challengeDetail) {
		firestore("one_to_one")
			.doc(challengeId)
			.collection("questions")
			.onSnapshot(async (querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				challengeDetail.questions = data;
				// setChallengeDetail(challengeDetail);
				setFormData(challengeDetail);
			});
	}

	function setFormData(challengeDetail) {
		let formValueClone = challengeDetail ? challengeDetail : initFormValue;

		setFormValue(_.clone(formValueClone));
	}
	function numberWithCommas(x) {
		return x.toLocaleString(); //.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	function onImageUpload(e) {
		fileSelectHandler(e);
	}
	function onImageUpload2(e) {
		fileSelectHandler2(e);
	}

	async function submitForm(e) {
		e.stopPropagation();
		e.preventDefault();

		setIsSubmitLoader(true);

		const dataToSubmit = _.cloneDeep(formValue);
		// if (isImageChanged && imageFile) {
		/*
        const fileName = dataToSubmit.name.replace(/[^a-zA-Z ]/g, "").replaceAll(' ','_')+'-'+moment().unix();
        if (isImageChanged && imageFile) {
            const uploaded = await storage().ref().child(`/one_to_one/${fileName}`).put(imageFile, {cacheControl: 'public,max-age=31536000'});
            const child = await storage().ref().child(uploaded.metadata.fullPath);
            dataToSubmit.image = await child.getDownloadURL();
            dataToSubmit.fullPath = uploaded.metadata.fullPath;
        } else if (!challengeId) {
            setImageError('image is required');
            setIsSubmitLoader(false);
            return;
        }
        const fileName2 = 'sponsor-'+moment().format();
        if (isImageChanged2 && imageFile2) {
            const uploaded = await storage().ref().child(`/one_to_one/${fileName2}`).put(imageFile2, {cacheControl: 'public,max-age=31536000'});
            const child = await storage().ref().child(uploaded.metadata.fullPath);
            dataToSubmit.sponsor = await child.getDownloadURL();
            //dataToSubmit.sponsorFullPath = uploaded.metadata.fullPath;
        }
        else{   //remove sponsor image
            dataToSubmit.sponsor = ''
        }
        */

		var atLeastOne = false;
		for (var key in dataToSubmit.goal)
			if (dataToSubmit.goal[key] === true) atLeastOne = true;
		if (!atLeastOne) {
			setIsSubmitLoader(false);
			return alert("at least one supported user goal is required");
		}

		var atLeastOne = false;
		for (var key in dataToSubmit.dietTypes)
			if (dataToSubmit.dietTypes[key] === true) atLeastOne = true;
		if (dataToSubmit.features.mealPlan && !atLeastOne) {
			setIsSubmitLoader(false);
			return alert("at least one supported diet type is required");
		}

		var atLeastOne = false;
		for (var key in dataToSubmit.exerciseTypes)
			if (dataToSubmit.exerciseTypes[key] === true) atLeastOne = true;
		if (dataToSubmit.features.workoutPlan && !atLeastOne) {
			setIsSubmitLoader(false);
			return alert("at least one supported exercise type is required");
		}

		if (!dataToSubmit.price && !admin.role.includes("admin")) {setIsSubmitLoader(false); return alert("please fill in challenge price");}
		if (Object.keys(dataToSubmit.goal).length === 0) {setIsSubmitLoader(false); return alert("at least one goal must be supported");}
		if (!dataToSubmit.features.mealPlan && !dataToSubmit.features.workoutPlan) {setIsSubmitLoader(false); alert("either a meal plan or a workout plan must be supported"); return; }

		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		dataToSubmit.name = userDetails.name;
		dataToSubmit.nutritionistId = userDetails.id;
		dataToSubmit.nutritionistName = userDetails.name;
		dataToSubmit.nutritionistImage = userDetails.image;
		dataToSubmit.oneToOne = true;
		dataToSubmit.isFree = !dataToSubmit.price ? true : false;
		dataToSubmit.price = dataToSubmit.price ? Number(dataToSubmit.price) : 0;
		dataToSubmit.price_usd = dataToSubmit.price_usd ? Number(dataToSubmit.price_usd) : 0;
		dataToSubmit.price_usd_iap = dataToSubmit.price_usd_iap ? Number(dataToSubmit.price_usd_iap) : 0;
		//dataToSubmit.price_usd = dataToSubmit.price ? Math.floor(Number(dataToSubmit.price)/16.5) : 0;
		dataToSubmit.equipmentPrice = dataToSubmit.equipmentPrice
			? Number(dataToSubmit.equipmentPrice)
			: 0;
		if (!dataToSubmit.packages || !Object.keys(dataToSubmit.packages).length)
			dataToSubmit.packages = { 1: true };

		dataToSubmit.date = moment(dataToSubmit.date).format("YYYY-MM-DDT00:00:00");

		const durationType = dataToSubmit.durationType
			.replace("s", "")
			.toLowerCase();
		dataToSubmit.endDatetime = moment(dataToSubmit.date)
			.add(dataToSubmit.duration, durationType)
			.format("YYYY-MM-DDT00:00:00");
		const reminders = _.clone(dataToSubmit.reminders);
		const questions = _.clone(dataToSubmit.questions);
		const faq = _.clone(dataToSubmit.faq);
		let newChallengeId = _.clone(challengeId);
		if (dataToSubmit.reminders) {
			delete dataToSubmit.reminders;
		}
		if (dataToSubmit.questions) {
			delete dataToSubmit.questions;
		}
		if (dataToSubmit.faq) {
			delete dataToSubmit.faq;
		}
		if (challengeId) {
			await firestore("one_to_one").doc(userDetails.id).update(dataToSubmit);
		} else {
			dataToSubmit.userCount = 0;
			dataToSubmit.groupCount = 0;
			dataToSubmit.commentCount = 0;
			dataToSubmit.weightLost = 0;
			dataToSubmit.likeCount = 0;
			dataToSubmit.mealCount = 0;
			dataToSubmit.questionCount = 0;
			dataToSubmit.exerciseCount = 0;
			dataToSubmit.waterCupCount = 0;
			dataToSubmit.datetime = moment().format();
			await firestore("one_to_one").doc(userDetails.id).set(dataToSubmit);
			newChallengeId = userDetails.id;
		}
		if (reminders && reminders.length) {
			const reminderRef = firestore("one_to_one")
				.doc(newChallengeId)
				.collection("reminders");
			await Promise.all(
				reminders.map(async (reminder) => {
					if (reminder.key) {
						await reminderRef.doc(reminder.key).update(reminder);
					} else {
						await reminderRef.add(reminder);
					}
				})
			);
		}
		if (faq && faq.length) {
			const questionRef = firestore("one_to_one")
				.doc(newChallengeId)
				.collection("faq");
			await Promise.all(
				faq.map(async (question) => {
					if (question.key) {
						await questionRef.doc(question.key).update(question);
					} else {
						await questionRef.add(question);
					}
				})
			);
		}
		if (questions && questions.length) {
			const questionRef = firestore("one_to_one")
				.doc(newChallengeId)
				.collection("questions");
			await Promise.all(
				questions.map(async (question) => {
					if (question.key) {
						await questionRef.doc(question.key).update(question);
					} else {
						console.log(question);
						await questionRef.add(question);
					}
				})
			);
		}
		setIsSubmitLoader(false);
		props.history.push("/profile");
	}

	function addMoreReminders() {
		const formValueClone = _.clone(formValue);
		formValueClone.reminders[formValueClone.reminders.length] = {
			action: "",
			day: "",
			message: "",
			ar: {
				message: "",
			},
		};
		setFormValue(formValueClone);
	}

	function addMoreFaq() {
		const formValueClone = _.clone(formValue);
		if (!formValueClone.faq) formValueClone.faq = [];
		formValueClone.faq[formValueClone.faq.length] = {
			question: "",
			answer: "",
		};
		setFormValue(formValueClone);
	}

	function addMoreQuestions() {
		const formValueClone = _.clone(formValue);
		if (!formValueClone.questions) formValueClone.questions = [];
		formValueClone.questions[formValueClone.questions.length] = {
			question: "",
			answer: "",
		};
		setFormValue(formValueClone);
	}

	/* function removeRemindersClicked(i) {
         const formValueClone = _.clone(formValue);
         formValueClone.reminders.splice(i, 1);
         console.log('formValueClone', formValueClone);
         setFormValue(formValueClone);
     }*/

	function fileDragHover(e) {
		var fileDrag = document.getElementById("file-drag");
		e.stopPropagation();
		e.preventDefault();
		fileDrag.className =
			e.type === "dragover" ? "hover" : "modal-body file-upload";
	}
	function fileDragHover2(e) {
		var fileDrag = document.getElementById("file-drag2");
		e.stopPropagation();
		e.preventDefault();
		fileDrag.className =
			e.type === "dragover" ? "hover" : "modal-body file-upload";
	}

	function output(msg) {
		// Response
		var m = document.getElementById("messages");
		m.innerHTML = msg;
	}
	function output2(msg) {
		// Response
		var m = document.getElementById("messages2");
		m.innerHTML = msg;
	}

	function parseFile(file) {
		output("<strong>" + encodeURI(file.name) + "</strong>");

		// var fileType = file.type;
		var imageName = file.name;

		var isGood = true; ///\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
		if (isGood) {
			document.getElementById("start").classList.add("hidden");
			document.getElementById("response").classList.remove("hidden");
			// Thumbnail Preview
			document.getElementById("file-image").classList.remove("hidden");
			document.getElementById("file-image").src = URL.createObjectURL(file);
		} else {
			document.getElementById("file-image").classList.add("hidden");
			document.getElementById("start").classList.remove("hidden");
			document.getElementById("response").classList.add("hidden");
			document.getElementById("file-upload-form").reset();
		}
	}

	function parseFile2(file) {
		output2("<strong>" + encodeURI(file.name) + "</strong>");

		// var fileType = file.type;
		var imageName = file.name;

		var isGood = true; ///\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
		if (isGood) {
			document.getElementById("start2").classList.add("hidden");
			document.getElementById("response2").classList.remove("hidden");
			// Thumbnail Preview
			document.getElementById("file-image2").classList.remove("hidden");
			document.getElementById("file-image2").src = URL.createObjectURL(file);
		} else {
			document.getElementById("file-image2").classList.add("hidden");
			document.getElementById("start2").classList.remove("hidden");
			document.getElementById("response2").classList.add("hidden");
			document.getElementById("file-upload-form2").reset();
		}
	}

	async function fileSelectHandler(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;
		// Cancel event and hover styling
		fileDragHover(e);

		// Process all File objects
		for (var i = 0, f; (f = files[i]); i++) {
			var options = {
				maxSizeMB: 0.3,
				maxWidthOrHeight: 400,
			};
			try {
				const compressedFile = f; //await imageCompression(f, options);
				setImageError("");
				setImageFile(compressedFile);
				parseFile(compressedFile);
			} catch (error) {
				console.log("error fileSelectHandler", error);
				setImageError("please upload a valid image file");
			}
		}
		setIsImageChanged(true);
	}

	async function fileSelectHandler2(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;
		// Cancel event and hover styling
		fileDragHover2(e);

		// Process all File objects
		for (var i = 0, f; (f = files[i]); i++) {
			var options = {
				maxSizeMB: 0.3,
				maxWidthOrHeight: 400,
			};
			try {
				const compressedFile = f; //await imageCompression(f, options);
				setImageError2("");
				setImageFile2(compressedFile);
				parseFile2(compressedFile);
			} catch (error) {
				console.log("error fileSelectHandler2", error);
				setImageError2("please upload a valid sponsor image file");
			}
		}
		setIsImageChanged2(true);
	}

	async function removeReminder(i, key) {
		if (challengeId && key) {
			const reminderRef = firestore("one_to_one")
				.doc(challengeId)
				.collection("reminders");
			await reminderRef.doc(key).delete();
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.reminders.splice(i, 1);
			setFormValue(formValueClone);
		}
	}

	async function removeFaq(i, key) {
		if (challengeId && key) {
			const reminderRef = firestore("one_to_one")
				.doc(challengeId)
				.collection("faq");
			await reminderRef.doc(key).delete();
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.faq.splice(i, 1);
			setFormValue(formValueClone);
		}
	}

	async function removeQuestion(i, key) {
		if (challengeId && key) {
			const reminderRef = firestore("one_to_one")
				.doc(challengeId)
				.collection("questions");
			await reminderRef.doc(key).delete();
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.questions.splice(i, 1);
			setFormValue(formValueClone);
		}
	}

	async function onSelectPublish(checkBoxValue) {
		if (checkBoxValue) {
			setShowPublishConfirmation(true);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isPublished = false;
			setFormValue(formValueClone);
		}
	}

	async function onSelectClose(checkBoxValue) {
		if (checkBoxValue) {
			setShowCloseConfirmation(true);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isPrivate = false;
			setFormValue(formValueClone);
		}
	}

	async function onSelectBundle(checkBoxValue) {
		if (checkBoxValue) {
			setShowBundleConfirmation(true);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isBundleable = false;
			setFormValue(formValueClone);
		}
	}

	/* Publish challenge after confirmation */
	async function handleConfirmation(callback) {
		if (callback) {
			const formValueClone = _.clone(formValue);
			formValueClone.isPublished = callback;
			console.log("called : ", formValueClone.isPublished);

			setFormValue(formValueClone);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isPublished = false;
			console.log("called : ", formValueClone.isPublished);
			setFormValue(formValueClone);
		}
		setShowPublishConfirmation(false);
	}

	/* Close challenge after confirmation */
	async function handleConfirmationClose(callback) {
		if (callback) {
			const formValueClone = _.clone(formValue);
			formValueClone.isPrivate = callback;
			console.log("called : ", formValueClone.isPrivate);

			setFormValue(formValueClone);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isPrivate = false;
			console.log("called : ", formValueClone.isPrivate);
			setFormValue(formValueClone);
		}
		setShowCloseConfirmation(false);
	}

	/* Bundle challenge after confirmation */
	async function handleConfirmationBundle(callback) {
		if (callback) {
			const formValueClone = _.clone(formValue);
			formValueClone.isBundleable = callback;
			console.log("called : ", formValueClone.isBundleable);

			setFormValue(formValueClone);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isBundleable = false;
			console.log("called : ", formValueClone.isBundleable);
			setFormValue(formValueClone);
		}
		setShowBundleConfirmation(false);
	}

	return (
		<>
			<Header
				header="Challenges"
				subHeader={formValue.goal ? "Edit Program" : "Add Program"}
			/>
			<hr />
			<div>
				<h4>1 to 1 follow up program</h4>
				<Form className="form" onSubmit={(e) => submitForm(e)}>
					<Form.Row>
						<Form.Group as={Col} md="12" controlId="videoLink">
							<Form.Label>Video</Form.Label>
							<Form.Control
								className="form-input"
								placeholder="YouTube link"
								type="url"
								value={formValue.videoLink}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.videoLink = e.target.value.trim();
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="12" controlId="description">
							<Form.Label>Description</Form.Label>
							<Form.Control
								placeholder="description"
								as="textarea"
								rows={features.length - 1}
								value={formValue.description}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.description = e.target.value;
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="3" style={{ paddingLeft: "30px" }}>
							<Form.Label>Features</Form.Label>
							{features.map((feature) => (
								<Form.Check
									key={feature.key}
									size="sm"
									type="checkbox"
									disabled={feature.required}
									checked={
										feature.required ||
										(formValue.features
											? formValue.features[feature.key]
											: false)
									}
									label={feature.title}
									className="text-left"
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										if (!formValueClone.features) formValueClone.features = {};
										features.map((feature) => {
											if (feature.required)
												formValueClone.features[feature.key] = true;
										});
										formValueClone.features[feature.key] = e.target.checked;
										setFormValue(formValueClone);
									}}
								/>
							))}
						</Form.Group>
						<Form.Group as={Col} md="3" style={{ paddingLeft: "30px" }}>
							<Form.Label>Goal</Form.Label>
							{goalOptions.map((feature) => (
								<Form.Check
									style={{ fontSize: ".95em" }}
									key={feature.key}
									size="sm"
									type="checkbox"
									disabled={feature.required}
									checked={
										feature.required ||
										(formValue.goal ? formValue.goal[feature.key] : false)
									}
									label={feature.title}
									className="text-left"
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										if (!formValueClone.goal) formValueClone.goal = {};
										goalOptions.map((feature) => {
											if (feature.required)
												formValueClone.goal[feature.key] = true;
										});
										formValueClone.goal[feature.key] = e.target.checked;
										setFormValue(formValueClone);
									}}
								/>
							))}
						</Form.Group>
						<Form.Group
							as={Col}
							md="3"
							style={
								formValue.features.mealPlan !== true
									? { opacity: 0.5, pointerEvents: "none" }
									: {}
							}
						>
							<Form.Label>Diet types</Form.Label>
							{dietTypes.map((feature) => (
								<Form.Check
									style={{ fontSize: ".95em" }}
									key={feature.key}
									size="sm"
									type="checkbox"
									disabled={feature.required}
									checked={
										feature.required ||
										(formValue.dietTypes
											? formValue.dietTypes[feature.key]
											: false)
									}
									label={feature.title}
									className="text-left"
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										if (!formValueClone.dietTypes)
											formValueClone.dietTypes = {};
										dietTypes.map((feature) => {
											if (feature.required)
												formValueClone.dietTypes[feature.key] = true;
										});
										formValueClone.dietTypes[feature.key] = e.target.checked;
										setFormValue(formValueClone);
									}}
								/>
							))}
						</Form.Group>
						<Form.Group
							as={Col}
							md="3"
							style={
								formValue.features.workoutPlan !== true
									? { opacity: 0.5, pointerEvents: "none" }
									: {}
							}
						>
							<Form.Label>Exercise types</Form.Label>
							{exerciseTypes.map((feature) => (
								<Form.Check
									style={{ fontSize: ".95em" }}
									key={feature.key}
									size="sm"
									type="checkbox"
									disabled={feature.required}
									checked={
										feature.required ||
										(formValue.exerciseTypes
											? formValue.exerciseTypes[feature.key]
											: false)
									}
									label={feature.title}
									className="text-left"
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										if (!formValueClone.exerciseTypes)
											formValueClone.exerciseTypes = {};
										exerciseTypes.map((feature) => {
											if (feature.required)
												formValueClone.exerciseTypes[feature.key] = true;
										});
										formValueClone.exerciseTypes[feature.key] =
											e.target.checked;
										setFormValue(formValueClone);
									}}
								/>
							))}
						</Form.Group>
						<Form.Group as={Col} md="4" controlId="price">
							<Form.Label>Price in EGP</Form.Label>
							<Form.Control
								placeholder="Price in EGP"
								min={admin.role.includes("admin")? "0" : "100"}
								type={"number"}
								value={formValue.price}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.price = e.target.value;
									formValueClone.price_usd = Math.ceil(formValueClone.price/49.35);
									formValueClone.price_usd_iap = Math.ceil(formValueClone.price/49.00);
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="4" controlId="price_usd">
							<Form.Label>USD Price</Form.Label>
							<Form.Control
								placeholder="Price in USD"
								type={"number"}
								value={formValue.price_usd}
								//disabled={true}
								//style={{opacity: .65}}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.price_usd = e.target.value;
									formValueClone.price = Math.floor(formValueClone.price_usd*49.35);
									formValueClone.price_usd_iap = Math.ceil(formValueClone.price_usd*49.35/49.00);
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="4" style={{ paddingLeft: "30px" }}>
							<Form.Label>Packages</Form.Label>
							{packages.map((feature) => (
								<Form.Check
									key={feature.key}
									size="sm"
									type="checkbox"
									disabled={feature.required}
									checked={
										feature.required ||
										(formValue.packages
											? formValue.packages[feature.key]
											: false)
									}
									label={
										feature.title +
										" " +
										numberWithCommas(
											Math.round(
												((100 - feature.discount) / 100) *
													feature.key *
													formValue.price
											)
										) +
										" EGP" +
										(feature.discount
											? " (" + feature.discount + "% discount)"
											: "")
									}
									className="text-left"
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										if (!formValueClone.packages) formValueClone.packages = {};
										packages.map((feature) => {
											if (feature.required)
												formValueClone.packages[feature.key] = true;
										});
										formValueClone.packages[feature.key] = e.target.checked;
										setFormValue(formValueClone);
									}}
								/>
							))}
						</Form.Group>
					</Form.Row>
					<Col sm="12">
						<hr />
						<Form.Group style={{ display: "flex" }}>
							<Form.Label>
								<div style={{ fontWeight: "500" }}>
									Frequently asked questions (Outside challenge - before
									joining) ({formValue.faq ? formValue.faq.length : 0})
								</div>
							</Form.Label>
							<Button
								className="button button-secondary"
								style={{
									marginLeft: "auto",
								}}
								onClick={() => {
									addMoreFaq();
								}}
							>
								<FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
								Add Question
							</Button>
						</Form.Group>

						{formValue.faq &&
							formValue.faq.map((a, i) => {
								return (
									<div key={i}>
										<Form.Row>
											<Row className="w-100">
												<Form.Group as={Col} sm="5">
													<Form.Label>Question*</Form.Label>
													<Form.Control
														as="textarea"
														required
														rows="3"
														placeholder="How many kilograms will i lose in this challenge?"
														value={formValue.faq[i].question}
														onChange={(e) => {
															const formValueClone = _.clone(formValue);
															formValueClone.faq[i].question = e.target.value;
															setFormValue(formValueClone);
														}}
													/>
												</Form.Group>
												<Form.Group as={Col} sm="6">
													<Form.Label>Answer*</Form.Label>
													<Form.Control
														as="textarea"
														required
														rows="3"
														placeholder="Each person has their own metabolic rate, genes, we can't put a specific number on that"
														value={formValue.faq[i].answer}
														onChange={(e) => {
															const formValueClone = _.clone(formValue);
															formValueClone.faq[i].answer = e.target.value;
															setFormValue(formValueClone);
														}}
													/>
												</Form.Group>
												<Col
													xs={1}
													className="text-center mb-4 align-self-center text-danger"
												>
													<FontAwesomeIcon
														icon={faMinusCircle}
														size="2x"
														className="ma button"
														style={{ cursor: "pointer", color: "#000" }}
														onClick={() => {
															removeFaq(i, formValue.faq[i].key);
														}}
													/>
												</Col>
											</Row>
										</Form.Row>
									</div>
								);
							})}
					</Col>
					{/*<Col sm="12">
						<hr />
						<Form.Group style={{ display: "flex" }}>
							<Form.Label>
								<div style={{ fontWeight: "500" }}>
									Frequently asked questions (Inside challenge - program FAQs) (
									{formValue.questions ? formValue.questions.length : 0})
								</div>
							</Form.Label>
							<button
								className="button button-secondary"
								style={{
									marginLeft: "auto",
								}}
								onClick={() => {
									addMoreQuestions();
								}}
							>
								<FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
								Add Question
							</button>
						</Form.Group>
						{formValue.questions &&
							formValue.questions.map((a, i) => {
								return (
									<div key={i}>
										<Form.Row>
											<Row className="align-self-center mt-3 w-100">
												<Form.Group as={Col} sm="5">
													<Form.Label>Question*</Form.Label>
													<Form.Control
														as="textarea"
														required
														rows="3"
														placeholder="How many kilograms will i lose in this challenge?"
														value={formValue.questions[i].question}
														onChange={(e) => {
															const formValueClone = _.clone(formValue);
															formValueClone.questions[i].question =
																e.target.value;
															setFormValue(formValueClone);
														}}
													/>
												</Form.Group>
												<Form.Group as={Col} sm="6">
													<Form.Label>Answer*</Form.Label>
													<Form.Control
														as="textarea"
														required
														rows="3"
														placeholder="Each person has their own metabolic rate, genes, we can't put a specific number on that"
														value={formValue.questions[i].answer}
														onChange={(e) => {
															const formValueClone = _.clone(formValue);
															formValueClone.questions[i].answer =
																e.target.value;
															setFormValue(formValueClone);
														}}
													/>
												</Form.Group>
												<Col
													xs={1}
													className="text-center mb-4 align-self-center text-danger"
												>
													<FontAwesomeIcon
														icon={faMinusCircle}
														size="2x"
														className="ma button"
														style={{ cursor: "pointer", color: "#000" }}
														onClick={() => {
															removeQuestion(i, formValue.questions[i].key);
														}}
													/>
												</Col>
											</Row>
										</Form.Row>
									</div>
								);
							})}
						<hr />
					</Col>*/}
					<Col sm="10" style={{ display: "flex", marginTop: "1em" }}>
						<Form.Group
							controlId="formBasicCheckbox"
							style={{ marginTop: "0.5rem" }}
						>
							<Form.Check
								type="checkbox"
								label="Publish for live users"
								checked={formValue.isPublished}
								onChange={async (e) => {
									await onSelectPublish(e.target.checked);
									// const formValueClone = _.clone(formValue);
									// formValueClone.isPublished = e.target.checked;
									// setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group
							controlId="max"
							style={{
								marginLeft: "3em",
								display: "flex",
								whiteSpace: "nowrap",
							}}
						>
							<Form.Label style={{ marginTop: "0.5rem" }}>
								Maximum users
							</Form.Label>
							<Form.Control
								type={"number"}
								style={{
									marginLeft: "1em",
									paddingTop: 0,
									paddingBottom: 0,
									width: "100px",
									borderColor: "#f0f0f0",
								}}
								value={formValue.max}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.max = +e.target.value;
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
					</Col>

					<Row>
						<Col md="12">
							<Button
								type="submit"
								className="button button-primary button-hover"
								style={{ width: "100%", marginBottom: "1em" }}
							>
								{isSubmitLoader ? (
									<Spinner animation="border" size={"sm"} role="status" />
								) : (
									<span>Save</span>
								)}
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
			<Modal
				show={showPublishConfirmation}
				onHide={() => handleConfirmation(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Are you sure ?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					One to One program will be live for users on save.
				</Modal.Body>
				<Modal.Footer>
					<Button
						style={{ borderRadius: "22px" }}
						className="button button-cancel"
						variant="warning"
						onClick={() => handleConfirmation(false)}
					>
						Cancel
					</Button>
					<Button
						className="button button-save button-primary"
						variant="primary"
						onClick={() => handleConfirmation(true)}
					>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showCloseConfirmation}
				onHide={() => handleConfirmationClose(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Are you sure ?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					This challenge will be available for users with the link on save.
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="warning"
						onClick={() => handleConfirmationClose(false)}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => handleConfirmationClose(true)}
					>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showBundleConfirmation}
				onHide={() => handleConfirmationBundle(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Are you sure ?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					This challenge will be bundleable on save
					<br />
					users will be able to pay for up to 3 rounds upfront
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="warning"
						onClick={() => handleConfirmationBundle(false)}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => handleConfirmationBundle(true)}
					>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
