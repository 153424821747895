import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Form,
    Modal,
    ButtonToolbar,
    Table,
    DropdownButton,
    Dropdown,
    Spinner,
    Breadcrumb
} from "react-bootstrap";
import Masonry from 'react-masonry-css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faFilter, faPencilAlt, faCopy, faTrashAlt, faArrowUp, faArrowDown, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { firestore } from "../../services/firebase";
import AddRoom from '../../components/AddRoom';
import * as _ from "lodash";
import DeleteAlert from '../../components/DeleteAlert';
import moment from 'moment';

library.add(faPlus, faFilter);

export function Rooms() {
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [roomsLoader, setRoomsLoader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');
    const [sort, setSort] = useState({
        field: 'datetime',
        direction: 'desc'
    });

    const tableHeaders = [{
        name: 'Name',
        filterField: 'name',
    //}, {
    //    name: '',
    //    filterField: 'none',
    }, {
        name: 'Date',
        filterField: 'datetime',
    }, {
        name: 'Type',
        filterField: 'type',
    }, {
        name: 'Kcal',
        filterField: 'calories',
    //}, {
    //    name: 'Actions',
    //    filterField: 'none',
    }];
    const [transactions, setTransactions] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [promocodeData, setRoomData] = useState({});
    const [filterData, setFilterData] = useState({});
    const [showRoom, setShowRoom] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [promocodeIdToName, setRoomIdToName] = useState();
    const [challengeIdToName, setChallengeIdToName] = useState();
    const [oneToOneIdToName, setOneToOneIdToName] = useState();

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setRoomData({});
        setShowRoom(false);
    };
    useEffect(() => {

        const roomsSubscription = getRooms();

        return () => { roomsSubscription(); };
    }, []);


    function getRooms() {
        setRoomsLoader(true);
        return firestore('rooms')
            .onSnapshot((querySnapshot) => {
                var promocodeIdToName = {}
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    promocodeIdToName[documentSnapshot.id] = documentSnapshot.data().code
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });
                setRoomsLoader(false);
                setRooms(_.orderBy(data, 'name', 'asc'));
                setRoomIdToName(promocodeIdToName);
            });
    }

    function getChallenges() {
        return firestore('challenges')
            .get().then((querySnapshot) => {
            //.onSnapshot((querySnapshot) => {
                var challengeIdToName = {}
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    challengeIdToName[documentSnapshot.id] = documentSnapshot.data().name
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });;
                setChallengeIdToName(challengeIdToName);
            });
    }

    function getOneToOnes() {
        return firestore('one_to_one')
            .get().then((querySnapshot) => {
            //.onSnapshot((querySnapshot) => {
                var challengeIdToName = {}
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    challengeIdToName[documentSnapshot.id] = documentSnapshot.data().nutritionistName
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });;
                setOneToOneIdToName(challengeIdToName);
            });
    }

    function addRoom() {
        setRoomData({});
        setShowRoom(true);
    }

    function editRoom(room) {
        setRoomData(room);
        setShowRoom(true);
    }

    return (
        <>
            <Breadcrumb className="pb-3">
                <Breadcrumb.Item active>Rooms</Breadcrumb.Item>
            </Breadcrumb>
            <div className="pt-5">
                <Card style={{borderRadius:"initial",padding:"initial"}}>
                    <Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            Rooms ({rooms.length})
                            <Button size='sm' variant="info" className="text-uppercase" onClick={() => {
                                addRoom();
                            }}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />Add room
                            </Button>
                        </h3>
                    </Card.Header>
                    <Card.Body>
                        {roomsLoader ?
                            <div className="text-center"><Spinner animation="border" size={'sm'} role="status" />
                            </div> : rooms.length ? rooms.map((room) => {
                                return (<Button key={room.key} disabled={false} style={{}} onClick={() => {
                                    editRoom(room);
                                }} className="rounded-pill mr-3 mb-3" variant="outline-secondary"
                                    size='sm'>{room.name}{
                                        <span style={{background: 'wheat',color: 'black',marginLeft: '10px',boxShadow: '0 0 3px 5px wheat',borderRadius: '5px',fontVariant: 'petite-caps',fontWeight: 'bold'}}>
                                            ({room.userCount})<b style={{opacity:.5}}> members</b>
                                        </span>}
                                    </Button>)
                            }) : <div className="text-center">No rooms found</div>}
                    </Card.Body>
                </Card>
                <Modal show={showRoom} onHide={handleClose} size="lg">
                    <AddRoom handleClose={handleClose} roomData={promocodeData} rooms={rooms} />
                </Modal>
            </div>
        </>
    )
}
