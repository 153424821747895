import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Form,
    Modal,
    ButtonToolbar,
    Table,
    DropdownButton,
    Dropdown,
    Spinner,
    Breadcrumb
} from "react-bootstrap";
import Masonry from 'react-masonry-css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faFilter, faPencilAlt, faCopy, faTrashAlt, faArrowUp, faArrowDown, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { firestore } from "../../services/firebase";
import AddPromocode from '../../components/AddPromocode';
import * as _ from "lodash";
import DeleteAlert from '../../components/DeleteAlert';
import moment from 'moment';

library.add(faPlus, faFilter);

export function Promocodes() {
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [promocodesLoader, setPromocodesLoader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');
    const [sort, setSort] = useState({
        field: 'datetime',
        direction: 'desc'
    });

    const tableHeaders = [{
        name: 'Name',
        filterField: 'name',
    //}, {
    //    name: '',
    //    filterField: 'none',
    }, {
        name: 'Date',
        filterField: 'datetime',
    }, {
        name: 'Type',
        filterField: 'type',
    }, {
        name: 'Kcal',
        filterField: 'calories',
    //}, {
    //    name: 'Actions',
    //    filterField: 'none',
    }];
    const [transactions, setTransactions] = useState([]);
    const [promocodes, setPromocodes] = useState([]);
    const [promocodeData, setPromocodeData] = useState({});
    const [filterData, setFilterData] = useState({});
    const [showPromocode, setShowPromocode] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [promocodeIdToName, setPromocodeIdToName] = useState();
    const [challengeIdToName, setChallengeIdToName] = useState();
    const [oneToOneIdToName, setOneToOneIdToName] = useState();
    const [nutritionistIdToName, setNutritionistIdToName] = useState();
    const [nutritionists, setNutritionists] = useState([]);

    const handleShow = () => setShow(true);
    const handleCloseMealPlan = () => {
        setPromocodeData({});
        setShowPromocode(false);
    };
    useEffect(() => {
        const promocodesSubscription = getPromocodes();
        const paymentsSubscription = getPayments();
        getNutritionists();
        getChallenges();
        getOneToOnes();
        return () => {
            promocodesSubscription();
            paymentsSubscription();
        };
    }, []);


    function getPromocodes() {
        setPromocodesLoader(true);
        return firestore('promocodes')
            .onSnapshot((querySnapshot) => {
                var promocodeIdToName = {}
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    promocodeIdToName[documentSnapshot.id] = documentSnapshot.data().code
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });
                setPromocodesLoader(false);
                setPromocodes(_.orderBy(data, 'name', 'asc'));
                setPromocodeIdToName(promocodeIdToName);
            });
    }

    function getNutritionists() {
        return firestore('users').where('role', 'array-contains', 'nutritionist')
            .get().then((querySnapshot) => {
            //.onSnapshot((querySnapshot) => {
                var nutritionistIdToName = {}, nutritionists = []
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    nutritionistIdToName[documentSnapshot.id] = documentSnapshot.data().name
                    nutritionists.push(documentSnapshot.id)
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });
                setNutritionists(nutritionists);
                setNutritionistIdToName(nutritionistIdToName);
            });
    }

    function getChallenges() {
        return firestore('challenges')
            .get().then((querySnapshot) => {
            //.onSnapshot((querySnapshot) => {
                var challengeIdToName = {}
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    challengeIdToName[documentSnapshot.id] = documentSnapshot.data().name
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });;
                setChallengeIdToName(challengeIdToName);
            });
    }

    function getOneToOnes() {
        return firestore('one_to_one')
            .get().then((querySnapshot) => {
            //.onSnapshot((querySnapshot) => {
                var challengeIdToName = {}
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    challengeIdToName[documentSnapshot.id] = documentSnapshot.data().nutritionistName
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });;
                setOneToOneIdToName(challengeIdToName);
            });
    }

    function getPayments() {

        setPaymentsLoading(true)

        const subscribePayments = firestore("payments")//.orderBy('datetime', 'asc')
            //.where('challengeId', '==', selectedChallenge)
            .orderBy('promocode')
            .orderBy('datetime', 'desc')
            //.where('datetime', '>=', moment('2020-08-01').format())
            //.where('datetime', '<=', moment('2020-09-01').format())
            //.limit(300)
            .onSnapshot(function(snapshot){
            //.get().then(function(snapshot){

            const payments = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            var total = 0
            var nPayments = 0
            var commission = 0
            var paymentsByDay = {}
            var paymentsByChallenge = {}
            var paymentsByNutritionist = {}

            var userIds = {}

            var transactions = []

            payments.filter(d => !d.declined/*&&!d.refunded*/).map((d) => {

                transactions.push({
                    date: d.datetime,
                    user: d.userName,
                    //currency: (d.type == "credits"? 'USD' : d.currency),
                    //amount: d.amount,
                    challenge: d.challengeId,
                    promocode: d.promocode,
                    //gateway: d.provider,
                    //commission: d.type == "credits" ? (0.32*d.amount) : ((0.029*d.amount) + (d.currency === 'USD'? 0.3 : 0.3 * 16)),
                    //net: (d.amount - (d.type == "credits"? (0.32*d.amount) : ((0.029*d.amount) + (d.currency === 'USD'? 0.3 : 0.3 * 16))))
                })
            })
            
            setTransactions(_.orderBy(transactions, d=>moment.utc(d.date).format(), 'desc'))

            setPaymentsLoading(false)
        })

        return subscribePayments
    }

    function addMealPlan() {
        setPromocodeData({});
        setShowPromocode(true);
    }

    function editPromocode(code) {
        setPromocodeData(code);
        setShowPromocode(true);
    }

    const onSelect = (key, isSelect) => {
        const selectedRows = [...selectedRecords];
        if (isSelect) {
            selectedRows.push(key);
        } else {
            const index = selectedRows.findIndex((o) => o === key);
            if (index > -1) {
                selectedRows.splice(index, 1);
            }
        }
        setSelectedRecords(selectedRows);
    };

    async function onDeleteClick(key) {
        setDocId(key);
        setShowDeleteModal(true);
    }

    function checkIsSelected(key) {
        if (selectedRecords.length) {
            const index = selectedRecords.findIndex((o) => o === key);
            return index > -1;
        }
        return false;
    }

    function removeFilter(key) {
        const filter = _.clone(filterData);
        delete filter[key];
        setFilterData(filter);
    }

    function displayFilter(filterKey) {
        if (filterKey === 'mealPlanId') {
            const index = promocodes.findIndex(o => o.key === filterData[filterKey]);
            if (index > -1) {
                return promocodes[index].name;
            }
        }
        return filterData[filterKey];
    }

    return (
        <>
            <Breadcrumb className="pb-3">
                <Breadcrumb.Item active>Promo codes</Breadcrumb.Item>
            </Breadcrumb>
            <div className="pt-5">
                <Card style={{borderRadius:"initial",padding:"initial"}}>
                    <Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            Promo codes ({promocodes.length})
                            {nutritionists.length && <Button size='sm' variant="info" className="text-uppercase" onClick={() => {
                                addMealPlan();
                            }}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />Add promo code
                            </Button>}
                        </h3>
                    </Card.Header>
                    <Card.Body>
                        {promocodesLoader ?
                            <div className="text-center"><Spinner animation="border" size={'sm'} role="status" />
                            </div> : promocodes.length ? promocodes.map((code) => {
                                return (<Button key={code.key} disabled={false} style={{}} onClick={() => {
                                    editPromocode(code);
                                }} className="rounded-pill mr-3 mb-3" variant="outline-secondary"
                                    size='sm'>{code.code}{
                                        <span style={{background: 'wheat',color: 'black',marginLeft: '10px',boxShadow: '0 0 3px 5px wheat',borderRadius: '5px',fontVariant: 'petite-caps',fontWeight: 'bold'}}>
                                            {100-code.discount} <b style={{opacity:.5}}>%</b>
                                        </span>}
                                    </Button>)
                            }) : <div className="text-center">No Promo codes found</div>}
                    </Card.Body>
                </Card>
                <Modal show={showPromocode} onHide={handleCloseMealPlan}>
                    <AddPromocode handleClose={handleCloseMealPlan} promocodeData={promocodeData} promocodes={promocodes} nutritionists={nutritionists} nutritionistIdToName={nutritionistIdToName} />
                </Modal>
            </div>



            {!paymentsLoading && <table id="transactions" className="table table-striped" style={{marginTop: '5em'}}>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>User</th>
                    {/*<th>Amount</th>
                    <th>Gateway</th>
                    <th>Commission</th>
                    <th>Total</th>*/}
                    <th>Challenge</th>
                    <th>Promocode</th>
                </tr>
                </thead>
                <tbody>
                {transactions.map((d, i) => <tr className={d.refunded && 'refunded'} key={i}>
                    <td>{d.date.split('T')[0]}</td>
                    <td>{d.user}{d.refunded && <FontAwesomeIcon icon={faUndo} size="1x" className="mr-2" style={{float:'right'}}/>}</td>
                    {/*<td>{d.amount.toFixed(2)} {d.currency}</td>
                    <td>{d.gateway}</td>
                    <td>{d.commission.toFixed(2)} {d.currency}</td>
                    <td>{d.net.toFixed(2)} {d.currency}</td>*/}
                    <td>{(challengeIdToName && challengeIdToName[d.challenge]) || (oneToOneIdToName && oneToOneIdToName[d.challenge]) || d.challenge}</td>
                    <td>{promocodeIdToName? promocodeIdToName[d.promocode] : d.promocode}</td>
                </tr>)}
            </tbody>
            </table>}
        </>
    )
}
