/** @format */

import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Spinner,
  Form,
  Col,
  Nav,
  ToggleButton,
} from "react-bootstrap";
import * as _ from "lodash";
import moment from "moment";
import "./AddMealPlan.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  faTrash,
  faCheck,
  faCheckSquare,
  faSquare,
  faChevronCircleDown,
  faChevronCircleUp,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firestore } from "../services/firebase";
import Welnes from "../assets/icons/welnes.svg";
import { ReactComponent as Filter } from "../assets/icons/filter.svg";
import RecipeCard from "./RecipeCard";
import DeleteAlert from "./DeleteAlert";
import firebase from 'firebase/compat/app';

/* =============== drag and drop ======================== */
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;
const width = 150;

const getItemStyle = (isDragging, draggableStyle, isWelnesMenuItem) => ({
  direction: "ltr",
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  //padding: grid + "px",
  textAlign: "center",
  fontSize: ".9em",
  // fontVariant: "small-caps",
  // margin: `0 0 ${grid}px 0`,
  borderRadius: `${grid}px`,
  marginBottom: "10px",

  color: isWelnesMenuItem ? "#1F516F" : "inherit",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : isWelnesMenuItem ? "#F76E0E" : "",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#fff",
  padding: grid + "px",
  position: "relative",
  marginTop: "20px",
  paddingTop: "55px",
  width: width + "px",
  display: "inline-block",
  verticalAlign: "top",
  borderRadius: "10px",
});
const dayStyle = () => ({
  margin: grid + "px",
  borderRadius: "8px",
  textAlign: "center",
  height: "37px",
  lineHeight: "37px",
  fontWeight: "bold",
  color: "#0E5171",
  backgroundColor: "#EFFAFF",
  //marginBottom: "10px",
  display: "block",
  position: "absolute",
  margin: grid + "px",
  top: "0px",
  left: 0,
  right: 0,
});

const getMenuStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? "lightblue" : "#fff",
  background: "#fff",
  borderRight: "1px solid #aaa",
  borderLeft: "1px solid #aaa",
  padding: grid + "px",
  paddingTop: "50px",
  width: width /*+ 20*/ + "px",
  transition: ".3s left",
  position: "fixed",
  top: "0",
  bottom: "0",
  left: -(width + 30) + "px",
  overflowX: "visible",
  overflowY: "scroll",
  boxSizing: "content-box",
  direction: "rtl",
  display: "block",
  opacity: 0,
  zIndex: 100,
});
/* ========================================================== */

export function AddMealPlan2(props) {
  const [currentWeek, setCurrentWeek] = useState(
    Math.ceil(moment().date() / 7)
  );
  const [nItems, setNItems] = useState(20);
  const [scrollTop, setScrollTop] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState(moment().week());
  const [showCalories, setShowCalories] = useState(true);
  const [showIngredient, setShowIngredient] = useState(true);
  const [isSubmitLoader, setIsSubmitLoader] = useState(false);
  const [isCloneLoader, setIsCloneLoader] = useState(false);
  const [mealPlanName, setMealPlanName] = useState("");
  const [mealPlanWeeks, setMealPlanWeeks] = useState("");
  const [mealPlanDescription, setMealPlanDescription] = useState("");
  const [caloriePerDay, setCaloriePerDay] = useState("");
  const [shoppingList, setShoppingList] = useState({});
  const [recipes, setRecipes] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [docId, setDocId] = useState("");
  const [state, setState] = useState([
    /*_.uniqBy(props.recipes, 'name')*/ props.recipes.map(
      (d) => d.key + "_menu"
    ),
  ]);
  const [recipesById, setRecipesById] = useState({});
  const [selectedType, setSelectedType] = useState();
  const [selectedTag, setSelectedTag] = useState();
  const [hideWelnesRecipes, setHideWelnesRecipes] = useState();
  const [searchKey, setSearchKey] = useState();
  const [arabic, setArabic] = useState(
    props.mealPlanData && props.mealPlanData.hasOwnProperty("arabic")
      ? props.mealPlanData.arabic
      : JSON.parse(localStorage.getItem("arabic"))
  );
  // const [showMenuForId, setShowMenuForId] = useState({});
  const [showMenuForName, setShowMenuForName] = useState({});

  const [sort, setSort] = useState({
    field: "datetime",
    direction: "desc",
  });
  const [isActive, setIsActive] = useState(false);

  const tableHeaders = [
    {
      //    name: 'Type',
      //    filterField: 'type',
      //}, {
      //    name: 'Recipe',
      //    filterField: 'name',
      //}, {
      name: "Cal",
      filterField: "Calories",
    },
  ];
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    if (props.mealPlanData) setHideWelnesRecipes(true);
    if (props.mealPlanData && props.mealPlanData.name)
      setMealPlanName(props.mealPlanData.name);
    if (props.mealPlanData && props.mealPlanData.weeks)
      setMealPlanWeeks(props.mealPlanData.weeks);
    if (props.mealPlanData && props.mealPlanData.caloriePerDay)
      setCaloriePerDay(props.mealPlanData.caloriePerDay);
    if (props.mealPlanData && props.mealPlanData.hasOwnProperty("showCalories"))
      setShowCalories(props.mealPlanData.showCalories);
    if (
      props.mealPlanData &&
      props.mealPlanData.hasOwnProperty("showIngredient")
    )
      setShowIngredient(props.mealPlanData.showIngredient);
    if (props.mealPlanData && props.mealPlanData.description)
      setMealPlanDescription(props.mealPlanData.description);
    if (props.mealPlanData && props.mealPlanData.shoppingList)
      setShoppingList(props.mealPlanData.shoppingList);
    if (props.mealPlanData /*&& props.mealPlanData.recipes*/) {
      var arr = [];
      arr[0] = props.recipes.map((d) => d.key + "_menu");
      var availableRecipes = props.recipes.map((d) => d.key);
      for (var i = 1; i <= 31; i++) {
        if (
          props.mealPlanData.recipes &&
          props.mealPlanData.recipes[i] &&
          props.mealPlanData.recipes[i].length
        )
          arr[i] = props.mealPlanData.recipes[i].filter((r) =>
            availableRecipes.includes(r)
          );
        else arr[i] = [];
      }
      setState(arr);
    }

    //setTimeout(()=>{ if(document.getElementById("day-0")) document.getElementById("day-0").style.display='block'; }, 0)
    setTimeout(() => {
      if (document.getElementById("day-0"))
        document.getElementById("day-0").style.left = 0;
      if (document.getElementById("day-0"))
        document.getElementById("day-0").style.opacity = 1;
    }, 1000);

    weeks().map((week) => {
      if (week.key === currentWeek) setSelectedWeek(week);
    });
  }, []);

  useEffect(() => {
    if (props.recipes) {
      var obj = {};
      props.recipes.map((ex) => {
        obj[ex.key] = ex;
      });
      setRecipesById(obj);
    }
  }, [props.recipes]);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.scrollTop);

      if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
        // you're at the bottom of the page
        setNItems((nItems) => nItems + 20);
      }
    };

    document.getElementById("day-0").addEventListener("scroll", onScroll);

    return () =>
      document.getElementById("day-0").removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (
      hideWelnesRecipes !== undefined ||
      selectedType !== undefined ||
      selectedTag !== undefined ||
      searchKey !== undefined
    ) {
      const newState = [...state];
      newState[0] = _.orderBy(
        props.recipes,
        sort.method || sort.field,
        sort.direction
      )
        .map((d) => d.key + "_menu")
        .filter(filterFn);
      setState(newState);
    }

    return () => {};
  }, [hideWelnesRecipes, selectedType, selectedTag, searchKey, sort]);

  useEffect(() => {
    var _shoppingList = {};
    weeks(mealPlanWeeks).map((week) => {
      _shoppingList[week] = [];
      var weekIngredients = {};
      for (var ind = (week - 1) * 7 + 1; ind <= week * 7; ind++) {
        if (state[ind])
          state[ind].map((recipeId) => {
            const recipe = recipesById[recipeId.split("_")[0]];

            (recipe.Ingredients || []).map((ing) => {
              if (!weekIngredients[ing.category])
                weekIngredients[ing.category] = {};
              if (!weekIngredients[ing.category][ing.food])
                weekIngredients[ing.category][ing.food] = _.clone(ing);
              else
                weekIngredients[ing.category][ing.food].quantity +=
                  ing.quantity;
            });
          });
      }

      Object.keys(weekIngredients).map((category) => {
        _shoppingList[week].push({ label: category });

        Object.values(weekIngredients[category]).map((ing) => {
          _shoppingList[week].push({
            item: ing.food + (arabic ? " (" + ing.food_ar + ")" : ""),
            amount:
              String(Number(ing.quantity.toFixed(2))) +
              " " +
              ing.serving +
              " " +
              "(" +
              String(
                Number(
                  Math.max(
                    1,
                    Math.round(ing.quantity * ing.unit_serving)
                  ).toFixed(2)
                )
              ) +
              " " +
              ing.unit +
              ")",
          });
        });
      });
    });

    setShoppingList(_shoppingList);

    return () => {};
  }, [state, mealPlanWeeks, arabic]);

  function _setShoppingList(week, list) {
    var clone = _.clone(shoppingList);
    clone[week] = list
      .split(/\r?\n/)
      .map((line) => {
        if (line && line[0] && line[0] === "#")
          return { label: line.replace(/#/g, "") }; //label
        var components = line.split(/\s+/).filter((d) => d);
        return components.length > 1
          ? {
              item: components.slice(0, -1).join(" "),
              amount: components[components.length - 1],
            }
          : null;
      })
      .filter((d) => d);
    setShoppingList(clone);
  }
  function displayShoppingList(list) {
    return list && list.map
      ? list
          .map((d) => (d.label ? "#" + d.label : d.item + "\t" + d.amount))
          .join("\n")
      : "";
  }
  function nRows(list) {
    return list && list.map && list.length ? list.length + 1 : 3;
  }
  function handleDeleted(dismissModal) {
    return dismissModal === "deleted"
      ? props.handleClose(true)
      : setShowDeleteModal(false);
  }

  function onDragEnd(result) {
    const { source, destination } = result;

    const newState = [...state];
    newState[0] = /*_.uniqBy(props.recipes, 'name')*/ _.orderBy(
      props.recipes,
      sort.method || sort.field,
      sort.direction
    )
      .map((d) => d.key + "_menu")
      .filter(filterFn);

    // dropped outside the list
    if (!destination) {
      setState(newState);
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(
        newState /*.filter((group,i) => i<newState.length-1 || group.length)*/
      );
    }
  }
  function onBeforeDragStart(result) {
    //return
    const { source } = result;

    const sInd = +source.droppableId;

    if (sInd === 0) {
      const newState = [...state];
      //newState[sInd].splice(source.index, 0, state[0][source.index])
      newState[sInd].splice(source.index, 0, state[0][source.index]);
      setState(newState);
    }
  }

  async function submitForm(e) {
    e.stopPropagation();
    e.preventDefault();
    if (mealPlanName) {
      setIsSubmitLoader(true);

      for (var i in state) {
        if (i > 0) {
          if (state[i] && state[i].length > 10) {
            alert("day " + i + " : max no of recipes per day is 10");
            setIsSubmitLoader(false);
            return;
          }
        }
      }

      var obj = {};
      state.map((arr, i) => {
        if (i > 0 && arr)
          obj[i] = arr.filter((d) => d).map((d) => d.split("_")[0]);
      });

      if (props.mealPlanData.key) {
        const addMealPlan = {
          name: mealPlanName || "",
          weeks: mealPlanWeeks,
          description: mealPlanDescription || "",
          caloriePerDay: caloriePerDay,
          showCalories: showCalories,
          showIngredient: showIngredient,
          shoppingList: shoppingList,
          arabic: arabic,
          recipes: obj,
          new: true,
        };
        await firestore("meal_plans")
          .doc(props.mealPlanData.key)
          .update(addMealPlan);
        firestore("meal_plans_essentials")
          .doc(props.mealPlanData.key)
          .update({
            name: mealPlanName || "",
            weeks: mealPlanWeeks,
            description: mealPlanDescription || "",
          });
      } else {
        const addMealPlan = {
          nutritionistId: userDetails.id,
          name: mealPlanName,
          weeks: mealPlanWeeks,
          description: mealPlanDescription,
          caloriePerDay: caloriePerDay,
          showCalories: showCalories,
          showIngredient: showIngredient,
          shoppingList: shoppingList,
          arabic: arabic,
          recipes: obj,
          new: true,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        };
        const res = await firestore("meal_plans").add(addMealPlan);
        firestore("meal_plans_essentials").doc(res.id).set({
          nutritionistId: userDetails.id,
          name: mealPlanName,
          weeks: mealPlanWeeks,
          description: mealPlanDescription,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      setIsSubmitLoader(false);
      props.handleClose(true);
    }
  }

  async function clonePlan() {
    if (mealPlanName) {
      setIsCloneLoader(true);

      var obj = {};
      state.map((arr, i) => {
        if (i > 0) obj[i] = arr.filter((d) => d).map((d) => d.split("_")[0]);
      });

      var new_mealPlanName = prompt(
        "Enter name for new (cloned) meal plan",
        mealPlanName + " (2)"
      );

      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const addMealPlan = {
        nutritionistId: userDetails.id,
        name: new_mealPlanName,
        weeks: mealPlanWeeks,
        description: mealPlanDescription,
        caloriePerDay: caloriePerDay,
        showCalories: showCalories,
        showIngredient: showIngredient,
        shoppingList: shoppingList,
        arabic: arabic,
        recipes: obj,
        new: true,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      };
      const res = await firestore("meal_plans").add(addMealPlan);
      firestore("meal_plans_essentials").doc(res.id).set({
        nutritionistId: userDetails.id,
        name: new_mealPlanName,
        weeks: mealPlanWeeks,
        description: mealPlanDescription,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setIsCloneLoader(false);
      props.handleClose(true);
    }
  }

  async function deleteMealPlan() {
    setDocId(props.mealPlanData.key);
    setShowDeleteModal(true);
  }

  function getRecipes() {
    let recipeRef = firestore("recipes").where(
      "mealPlanId",
      "==",
      props.mealPlanData.key
    );
    return recipeRef.onSnapshot(
      async (querySnapshot) => {
        let data = querySnapshot.docs.map((documentSnapshot) => {
          return {
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          };
        });
        // return data;
        setRecipes(data);
      },
      (error) => {
        console.error("error : ", error);
      }
    );
  }

  var special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
  ];
  var deca = [
    "twent",
    "thirt",
    "fort",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet",
  ];

  function stringifyNumber(n) {
    if (n < 20) return special[n];
    if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
    return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
  }
  function weeks(n) {
    var month = moment().format("MMMM");

    var arr = [];
    for (var i = 1; i <= 5; i++) {
      arr.push({
        key: i,
        from: (i - 1) * 7 + 1,
        to: i * 7 - (i === 5 ? 4 : 0),
        month: month,
        week: i,
      });
    }

    return arr;
  }

  function calories(ind) {
    var cal = 0;
    state[ind].map((d) => {
      if (recipesById[d.split("_")[0]])
        cal += parseFloat(
          recipesById[d.split("_")[0]].portions > 1
            ? recipesById[d.split("_")[0]].Calories /
                recipesById[d.split("_")[0]].portions
            : recipesById[d.split("_")[0]].Calories ||
                recipesById[d.split("_")[0]].calories
        );
    });
    return cal ? cal.toFixed(0) + " Kcal" : "";
  }

  function filterFn(recipeId) {
    const recipe = recipesById[recipeId.replace("_menu", "")];

    return (
      (hideWelnesRecipes
        ? recipe.nutritionistId === userDetails.id
        : recipe.nutritionistId === "welnes") &&
      (selectedType ? recipe.type === selectedType : true) &&
      (selectedTag ? recipe.tag === selectedTag : true) &&
      (searchKey
        ? recipe.name.toLowerCase().includes(searchKey.toLowerCase())
        : true)
    );
  }

  function sortBy(filterField) {
    let sortClone = _.clone(sort);

    if (sortClone.field === filterField) {
      sortClone.direction = sortClone.direction === "asc" ? "desc" : "asc";
    } else {
      sortClone.field = filterField;
      sortClone.direction = "asc";
    }

    var method = false;

    if (sortClone.field == "datetime")
      method = function (item) {
        return moment.utc(item.datetime).format();
      };
    if (sortClone.field == "type")
      method = function (item) {
        return item["type"].toLowerCase();
      };
    if (sortClone.field == "Calories")
      method = function (item) {
        return item["Calories"] ? parseFloat(item["Calories"]) : 0;
      };

    if (method) sortClone.method = method;

    setSort(sortClone);
  }

  return (
    <>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Row style={{ justifyContent: "end" }}>
            <h4 style={{ flex: 1 }}>
              {props.mealPlanData.key ? "Edit" : "Add"} Meal Plan
            </h4>
            <label
              type="button"
              className="clone btn btn-primary btn-block"
              variant="danger"
              onClick={() => {
                const fromPrompt = prompt("Enter day you want to clone from");
                if (!fromPrompt) return;
                const toPrompt = prompt("Enter day you want to clone to");
                if (!toPrompt) return;
                const fromDay = Math.max(1, +fromPrompt);
                const toDay = Math.max(1, +toPrompt);

                if (toDay > mealPlanWeeks * 7)
                  return alert(
                    "target day is out of defined mealplan no. of weeks, please increase no. of weeks"
                  );

                const newState = [...state];
                newState[toDay] = _.clone(newState[fromDay]);
                setState(newState);
              }}
            >
              <FontAwesomeIcon
                style={{ marginRight: "5px" }}
                icon={faClone}
                size="1x"
                className=""
              />
              Clone day
            </label>

            <label
              type="button"
              className="clone btn btn-primary"
              variant="danger"
              onClick={() => {
                const fromPrompt = prompt("Enter week you want to clone from");
                if (!fromPrompt) return;
                //const mealplan = Object.keys(challengeNameToChallengeId)[parseInt(prompt("Clone to which meal plan \n\n" + Object.keys(challengeNameToChallengeId).map((name, i) => String(i+1)+'. '+name).join('\n'), '1')) - 1]; if(!mealplan) return
                const toPrompt = prompt("Enter week you want to clone to");
                if (!toPrompt) return;
                const fromWeek = Math.max(1, +fromPrompt);
                const toWeek = Math.max(1, +toPrompt);

                if (toWeek > mealPlanWeeks)
                  return alert(
                    "target week is out of defined mealplan no. of weeks, please increase no. of weeks"
                  );

                const newState = [...state];

                for (var i = 1; i <= 7; i++) {
                  newState[(toWeek - 1) * 7 + i] = _.clone(
                    newState[(fromWeek - 1) * 7 + i]
                  );
                }

                setState(newState);
              }}
            >
              <FontAwesomeIcon
                style={{ marginRight: "5px" }}
                icon={faClone}
                size="1x"
                className=""
              />
              Clone week
            </label>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Row>
            <Col xs={4}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                form="mp-form"
                placeholder="Enter name"
                defaultValue={props.mealPlanData.name}
                onChange={(e) => {
                  setMealPlanName(e.target.value);
                }}
                required
              />
            </Col>
            {/*<Col xs={4}>
							<Form.Label>No. of weeks</Form.Label>
							<Form.Control
								form="mp-form"
								placeholder=""
								defaultValue={props.mealPlanData.weeks}
								required
								type="number"
								min="1"
								onChange={(e) => {
									const nWeeks = e.target.value;
									const newState = [...state];
									for (var i = 1; i <= nWeeks * 7; i++) {
										if (!newState[i]) newState[i] = [];
									}
									newState.length = 1 + nWeeks * 7;
									setState(newState);
									setMealPlanWeeks(nWeeks);
								}}
							/>
						</Col>*/}
            <Col xs={4}>
              <Form.Label>Calorie</Form.Label>
              <Form.Control
                form="mp-form"
                placeholder="Calorie per day"
                defaultValue={props.mealPlanData.caloriePerDay}
                onChange={(e) => {
                  setCaloriePerDay(e.target.value);
                }}
              />
            </Col>
          </Form.Row>
          <Form.Row style={{ paddingTop: "10px" }}>
            <Col xs={8}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="meal plan description (separated by linebreaks)"
                defaultValue={props.mealPlanData.description}
                as="textarea"
                // rows="3"
                rows={
                  mealPlanDescription
                    ? Math.max(
                        3,
                        mealPlanDescription.replace(/ • /g, "").split(/\r?\n/)
                          .length
                      )
                    : 3
                }
                value={
                  mealPlanDescription
                    ? mealPlanDescription.split(/\r?\n/).join("\r • ")
                    : ""
                }
                onChange={(e) => {
                  const points = e.target.value
                    .replace(/ • /g, "")
                    .split(/\r?\n/);
                  e.target.rows = Math.max(3, points.length);

                  setMealPlanDescription(" • " + points.join("\r • "));
                }}
              />
              <br />
            </Col>
            <Col xs={4} className="calorie-ingredient-based">
              <ToggleButton
                style={{
                  background: "none",
                  border: "none",
                  textAlign: "start",
                }}
                type="checkbox"
                size="sm"
                variant="light"
                direction="ltr"
                className="answered-toggle"
                checked={showCalories}
                onChange={(e) => {
                  setShowCalories(e.target.checked);
                }}
              >
                <FontAwesomeIcon
                  icon={showCalories ? faCheckSquare : faSquare}
                  size="1x"
                  className=""
                  style={{
                    opacity: showCalories ? 1 : 0.1,
                    color: "#11bd87",
                  }}
                />{" "}
                calorie based
                <span
                  style={{ fontSize: ".8em", display: "block", opacity: 0.8 }}
                >
                  *
                  {showCalories
                    ? "show calories to users"
                    : "hide calories from users"}
                </span>
              </ToggleButton>

              <ToggleButton
                style={{
                  background: "none",
                  border: "none",
                }}
                type="checkbox"
                size="sm"
                variant="light"
                direction="ltr"
                className="answered-toggle"
                checked={showIngredient}
                onChange={(e) => {
                  setShowIngredient(e.target.checked);
                }}
              >
                <FontAwesomeIcon
                  icon={showIngredient ? faCheckSquare : faSquare}
                  size="1x"
                  className=""
                  style={{
                    opacity: showIngredient ? 1 : 0.1,
                    color: "#11bd87",
                  }}
                />{" "}
                ingredient quantities
                <span
                  style={{ fontSize: ".8em", display: "block", opacity: 0.8 }}
                >
                  *
                  {showIngredient
                    ? "show ingredient quantities"
                    : "hide ingredient quantities"}
                </span>
              </ToggleButton>
            </Col>
          </Form.Row>
        </Form.Group>

        <Form
          className="form"
          id="mp-form"
          onSubmit={(e) => {
            submitForm(e);
          }}
        >
          <Nav
            variant="pills"
            defaultActiveKey={currentWeek}
            className="justify-content-center"
            style={{ marginTop: "2em", opacity: "0.75" }}
          >
            {weeks(mealPlanWeeks).map((week) => (
              <Nav.Item>
                <Nav.Link
                  style={{ minWidth: "0", margin: "5px" }}
                  className="button button-secondary2"
                  eventKey={week.key}
                  onClick={(e) => {
                    /*const newState = [...state];
											for (var i = week.from; i <= week.to; i++) {
												if (!newState[i]) newState[i] = [];
											}
											setState(newState);*/
                    setSelectedWeek(week);
                  }}
                >
                  {/*week.week !==5 && <><span style={{opacity: .6}}>week </span><span style={{marginRight: '20px'}}>{week.week}</span></>*/}
                  {week.from} - {week.to}
                  <span style={{ opacity: 0.6, marginLeft: "7px" }}>
                    {week.month}
                  </span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <div>
            <div
              id="items-container"
              style={{
                overflow: "auto",
                marginTop: ".7em",
                // display: "grid",
                // gridTemplateRows: "repeat(7,1fr)",
                textAlign: "center",
              }}
            >
              <DragDropContext
                onDragEnd={onDragEnd}
                onBeforeDragStart={onBeforeDragStart}
              >
                {state.map(
                  (el, ind) =>
                    (ind == 0 ||
                      (ind >= selectedWeek.from && ind <= selectedWeek.to)) && (
                      <Droppable
                        key={ind}
                        droppableId={`${ind}`}
                        isDropDisabled={ind == 0}
                      >
                        {(provided, snapshot) => (
                          <div
                            id={"day-" + ind}
                            ref={provided.innerRef}
                            style={
                              ind == 0
                                ? getMenuStyle(snapshot.isDraggingOver)
                                : getListStyle(snapshot.isDraggingOver)
                            }
                            {...provided.droppableProps}
                          >
                            <span
                              title={calories(ind)}
                              style={
                                ind !== 0
                                  ? dayStyle()
                                  : { backgroundColor: "#fff" }
                              }
                            >
                              {/*ind === moment().date() && <span style={{color: '#11bd87', marginRight: '5px'}}>•</span>*/}
                              {ind === 0 ? (
                                <img
                                  src={Welnes}
                                  alt="welnes"
                                  style={{
                                    marginBottom: "1em",
                                    width: "65%",
                                    color: "#0e5171 !important",
                                  }}
                                />
                              ) : ind === moment().date() ? (
                                <span style={{ color: "#11bd87" }}>
                                  {moment().date(ind).format("D MMM")}
                                </span>
                              ) : (
                                moment().date(ind).format("D MMM")
                              )}

                              {ind && showCalories ? (
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: ".8em",
                                    opacity: "0.7",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {calories(ind)}
                                </span>
                              ) : null}
                            </span>

                            {ind === 0 && (
                              <div className="side-menu">
                                <Form.Control
                                  placeholder="search"
                                  style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    direction: "ltr",
                                    display: "inline-block",
                                    marginLeft: "1em",
                                    borderRadius: "25px",
                                    margin: "5px 0 10px",
                                    backgroundColor: "#fff",
                                    border: "solid #f0f0f0",
                                    borderWidth: "1px",
                                  }}
                                  value={searchKey}
                                  onChange={(e) => {
                                    setSearchKey(e.target.value);
                                  }}
                                ></Form.Control>
                                <hr
                                  style={{
                                    borderColor: "#f0f0f0",
                                    margin: "0.5em 0",
                                  }}
                                />
                                <h5 style={{ fontWeight: "700" }}>
                                  Filter by
                                  <Filter
                                    width="12%"
                                    style={{ margin: "0.3em" }}
                                  />
                                </h5>

                                <Form.Control
                                  as="select"
                                  placeholder="Select Type"
                                  style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    direction: "ltr",
                                    display: "inline-block",
                                    marginRight: 0,
                                    marginLeft: 0,
                                  }}
                                  value={
                                    hideWelnesRecipes === false
                                      ? "Welnes Recipes"
                                      : "My Recipes"
                                  }
                                  onChange={(e) => {
                                    if (e.target.value === "My Recipes") {
                                      setHideWelnesRecipes("My Recipes");
                                    } else {
                                      setHideWelnesRecipes(false);
                                    }
                                  }}
                                >
                                  <option value="My Recipes" key="1">
                                    My Recipes
                                  </option>
                                  <option value="Welnes Recipes" key="2">
                                    Welnes Recipes
                                  </option>
                                </Form.Control>
                                {props.recipeTypes.length > 1 ? (
                                  <Form.Control
                                    as="select"
                                    placeholder="Select Type"
                                    style={{
                                      cursor: "pointer",
                                      width: "100%",
                                      direction: "ltr",
                                      display: "inline-block",
                                      marginTop: "5px",
                                      marginRight: 0,
                                      marginLeft: 0,
                                    }}
                                    value={selectedType}
                                    onChange={(e) => {
                                      setSelectedType(e.target.value);
                                    }}
                                  >
                                    <option value={""} key={""}>
                                      All types
                                    </option>
                                    {props.recipeTypes.map((obj, i) => {
                                      return (
                                        <option value={obj} key={obj}>
                                          {obj}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                ) : null}

                                {props.recipeTags.length > 1 ? (
                                  <Form.Control
                                    as="select"
                                    placeholder="Select Tag"
                                    style={{
                                      cursor: "pointer",
                                      width: "100%",
                                      direction: "ltr",
                                      display: "inline-block",
                                      marginTop: "5px",
                                      marginRight: 0,
                                      marginLeft: 0,
                                    }}
                                    value={selectedTag}
                                    onChange={(e) => {
                                      setSelectedTag(e.target.value);
                                    }}
                                  >
                                    <option value={""} key={""}>
                                      All tags
                                    </option>
                                    {props.recipeTags.map((obj, i) => {
                                      return (
                                        <option value={obj} key={obj}>
                                          {obj}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                ) : null}
                                {tableHeaders &&
                                  tableHeaders.map((o, i) => {
                                    return (
                                      <Button
                                        size="sm"
                                        className=""
                                        variant="info"
                                        key={i}
                                        onClick={() => sortBy(o.filterField)}
                                        style={{
                                          width: "100%",
                                          marginTop: "5px",
                                          direction: "ltr",
                                          marginBottom: "5px",
                                          backgroundColor: "#0e5171",
                                          border: "none",
                                        }}
                                      >
                                        {" "}
                                        sort by <b>{o.name}</b>{" "}
                                        {sort &&
                                        sort.field === o.filterField ? (
                                          <span>
                                            <FontAwesomeIcon
                                              icon={
                                                sort.direction === "asc"
                                                  ? faArrowUp
                                                  : faArrowDown
                                              }
                                              className="ml-1"
                                            />
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </Button>
                                    );
                                  })}
                              </div>
                            )}

                            {el
                              .filter(
                                (item) => recipesById[item.split("_")[0]]
                              ) /*.filter(ind==0?filterFn:()=>true)*/
                              .map(
                                (item, index) =>
                                  (ind > 0 || index < nItems) && (
                                    <Draggable
                                      key={item + "_" + ind + "_" + index}
                                      draggableId={
                                        item + "_" + ind + "_" + index
                                      }
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            ind === 0 &&
                                              recipesById[item.split("_")[0]]
                                                .nutritionistId === "welnes"
                                          )}
                                        >
                                          <div
                                            title={
                                              (recipesById[item.split("_")[0]]
                                                .Calories ||
                                                recipesById[item.split("_")[0]]
                                                  .calories) +
                                              " Kcal \n" +
                                              recipesById[item.split("_")[0]]
                                                .ingredients +
                                              " \n" +
                                              recipesById[item.split("_")[0]]
                                                .description
                                            }
                                            style={{
                                              position: "relative",
                                            }}
                                          >
                                            <div className="meal-plan-recipe">
                                              <RecipeCard
                                                showType={true}
                                                calorie={calories}
                                                cloneRecipe={props.cloneRecipe}
                                                recipes={props.recipes}
                                                item={item}
                                                ind={ind}
                                                index={index}
                                                showMenuForName={
                                                  showMenuForName
                                                }
                                                setShowMenuForName={
                                                  setShowMenuForName
                                                }
                                                setState={setState}
                                                state={state}
                                                recipesById={recipesById}
                                                showCalories={showCalories}
                                                image={
                                                  recipesById[
                                                    item.split("_")[0]
                                                  ].image
                                                }
                                                plan={
                                                  <div className="recipe-plans">
                                                    <span>
                                                      {
                                                        recipesById[
                                                          item.split("_")[0]
                                                        ].type
                                                      }
                                                    </span>
                                                  </div>
                                                }
                                                name={
                                                  recipesById[
                                                    item.split("_")[0]
                                                  ].name
                                                }
                                              />
                                            </div>

                                            {ind > 0 ? (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  top: "0",
                                                  right: "0",
                                                  opacity: ".7",
                                                }}
                                              ></div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                              )}

                            {ind == 0 &&
                              nItems <
                                el.filter(
                                  (item) => recipesById[item.split("_")[0]]
                                ) /*.filter(ind==0?filterFn:()=>true)*/
                                  .length && (
                                <div className="loader text-center">
                                  <Spinner
                                    animation="border"
                                    size={"sm"}
                                    role="status"
                                  />
                                </div>
                              )}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    )
                )}
              </DragDropContext>
            </div>
          </div>

          <br />
          <br />
          <section className="shopping-list">
            <div className="shopping-header">
              <h4>Shopping list for this week</h4>
              <hr style={{ flex: "2", marginLeft: "7px" }} />
              <div onClick={() => setIsActive(!isActive)}>
                {isActive ? (
                  <FontAwesomeIcon
                    icon={faChevronCircleUp}
                    size="1x"
                    className="mr-2"
                    style={{ color: "#a8a8a8", cursor: "pointer" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronCircleDown}
                    size="1x"
                    className="mr-2"
                    style={{ color: "#a8a8a8", cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
            {selectedWeek && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontSize: "11px", color: "#a8a8a8" }}>
                  * it will be auto generated from recipes ingredients
                </div>
                {isActive && (
                  <ToggleButton
                    style={{
                      background: "none",
                      border: "none",
                      textAlign: "start",
                    }}
                    type="checkbox"
                    size="sm"
                    variant={arabic ? "light" : "light"}
                    className="answered-toggle"
                    direction="ltr"
                    checked={arabic}
                    onChange={(e) => {
                      setArabic(e.target.checked);
                      e.target.checked
                        ? localStorage.setItem("arabic", true)
                        : localStorage.removeItem("arabic");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={arabic ? faCheckSquare : faSquare}
                      size="1x"
                      className=""
                      style={{
                        opacity: arabic ? 1 : 0.1,
                        color: "#11bd87",
                      }}
                    />
                    {/*arabic && <FontAwesomeIcon icon={faRetweet} size="1x" className="mr-2" />*/}{" "}
                    عربى
                  </ToggleButton>
                )}
              </div>
            )}

            {isActive && (
              <div
                className="shopping-content"
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  padding: "1em",
                }}
              >
                <div
                  style={{
                    margin: "0 auto",
                    fontSize: "12px",
                    background: "#f9f3eb",
                    borderRadius: "10px",
                    // display: "inline-block",
                    padding: "2em 1em",
                    position: "relative",
                  }}
                >
                  {selectedWeek && (
                    <div style={{}}>{stringifyNumber(selectedWeek)} week </div>
                  )}

                  {mealPlanWeeks &&
                    weeks(mealPlanWeeks).map(
                      (week) =>
                        week === selectedWeek && (
                          <table style={{ margin: "0 auto", fontSize: "12px" }}>
                            <div>
                              {shoppingList &&
                                shoppingList[week] &&
                                shoppingList[week].map((item) =>
                                  item.label ? (
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          padding: "0 2em",
                                          paddingTop: "1em",
                                          paddingBottom: ".3em",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.label}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          padding: "0 2em",
                                          paddingTop: "1em",
                                          paddingBottom: ".3em",
                                        }}
                                      ></td>
                                    </tr>
                                  ) : (
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          padding: "0 2em",
                                        }}
                                      >
                                        {item.item}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          padding: "0 2em",
                                        }}
                                      >
                                        {item.amount}
                                      </td>
                                    </tr>
                                  )
                                )}
                            </div>
                          </table>
                        )
                    )}
                </div>
              </div>
            )}
          </section>

          <div className="float-right">
            {props.mealPlanData.key && (
              <Button
                variant="danger"
                style={{ borderRadius: "22px" }}
                className="submit-button mr-3 button button-disabled"
                onClick={() => {
                  deleteMealPlan();
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="1x" className="" /> Delete
              </Button>
            )}

            {props.mealPlanData.key && (
              <Button
                variant="dark"
                className="submit-button mr-3 button button-secondary"
                onClick={() => {
                  clonePlan();
                }}
              >
                {isCloneLoader ? (
                  <Spinner animation="border" size={"sm"} role="status" />
                ) : (
                  <FontAwesomeIcon icon={faClone} size="1x" className="" />
                )}{" "}
                Clone
              </Button>
            )}
            <Button
              type="submit"
              className="submit-button button button-primary"
            >
              {isSubmitLoader ? (
                <Spinner animation="border" size={"sm"} role="status" />
              ) : (
                <React.Fragment>
                  <FontAwesomeIcon icon={faCheck} size="1x" className="mr-2" />
                  Save
                </React.Fragment>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>

      <Modal show={showDeleteModal} onHide={handleDeleted}>
        <DeleteAlert
          onHide={handleDeleted}
          title={"Delete Meal Plan"}
          message={"Are you sure you want to delete meal plan?"}
          warning={"All the related recipes will be unassigned."}
          docId={docId}
          collectionName={"meal_plans"}
        />
      </Modal>
    </>
  );
}

export default AddMealPlan2;
