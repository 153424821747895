import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Spinner, Form, Row, Col } from 'react-bootstrap';
import { firestore, storage } from "../services/firebase";
import DeleteAlert from "./DeleteAlert";
import * as _ from "lodash";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { faCalendarPlus, faCalendarMinus, faPlus, faTrash, faClone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



export function AddRoom(props) {
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [isCloneLoader, setIsCloneLoader] = useState(false);
    const [roomName, setRoomName] = useState('');
    const [roomDescription, setRoomDescription] = useState('');
    const [roomImage, setRoomImage] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');

    const [isImageChanged, setIsImageChanged] = useState(false);
    const [imageFile, setImageFile] = useState(false);
    const [imageError, setImageError] = useState("");
    const inputImg = useRef(null);

    useEffect(() => {
        if (props.roomData && props.roomData.name) setRoomName(props.roomData.name);
        if (props.roomData && props.roomData.description) setRoomDescription(props.roomData.description);
        if (props.roomData && props.roomData.image) setRoomImage(props.roomData.image);
    }, []);

    function handleDeleted(dismissModal) {
        return dismissModal === 'deleted' ? props.handleClose(true) : setShowDeleteModal(false);
    }

    function onImageUpload(e) {
        fileSelectHandler(e);
    }

    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();
        if (roomName) {
            setIsSubmitLoader(true);


            var image;
            if (isImageChanged && imageFile) {
                setIsSubmitLoader(true);

                setIsSubmitLoader(true);
                const fileName = moment().format();
                const uploaded = await storage()
                    .ref()
                    .child(`/recipes/${fileName}`)
                    .put(imageFile, { cacheControl: "public,max-age=31536000" });

                const child = await storage().ref().child(uploaded.metadata.fullPath);
                image = await child.getDownloadURL();
            }
            //else if (!props.roomData.key && !props.roomData.image) {
            //    setImageError("image is required");
            //    return;
            //}


            if (props.roomData.key) {
                const addRoom = {
                    name: roomName.toLowerCase().trim(),
                    description: roomDescription,
                    datetime: new Date().toISOString(),
                    image: image || roomImage
                }
                await firestore('rooms').doc(props.roomData.key).update(addRoom);
            } else {
                const addRoom = {
                    name: roomName.toLowerCase().trim(),
                    description: roomDescription,
                    datetime: new Date().toISOString(),
                    image: image || roomImage
                }
                await firestore('rooms').add(addRoom);
            }
            setIsSubmitLoader(false);
            props.handleClose(true);
        }
    }

    async function cloneRoom() {
        if (roomName) {
            setIsCloneLoader(true);

            var new_roomName = prompt("Enter name for new (cloned) room", roomName + ' (2)');

            const addRoom = {
                name: new_roomName.toLowerCase().trim(),
                description: roomDescription,
                datetime: new Date().toISOString(),
                image: roomImage
            }
            await firestore('rooms').add(addRoom);

            setIsCloneLoader(false);
            props.handleClose(true);
        }
    }

    async function deleteRoom() {
        setDocId(props.roomData.key);
        setShowDeleteModal(true);
    }


    function fileDragHover(e) {
        var fileDrag = document.getElementById("file-drag");
        e.stopPropagation();
        e.preventDefault();
        fileDrag.className =
            e.type === "dragover" ? "hover" : "modal-body file-upload";
    }

    async function fileSelectHandler(e) {
        // Fetch FileList object
        var files = e.target.files || e.dataTransfer.files;
        // Cancel event and hover styling
        fileDragHover(e);

        // Process all File objects
        for (var i = 0, f; (f = files[i]); i++) {
            var options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 400,
            };
            try {
                const compressedFile = f; //await imageCompression(f, options);
                setImageError("");
                setImageFile(compressedFile);
                parseFile(compressedFile);
            } catch (error) {
                console.error("error fileSelectHandler", error);
                setImageError("please upload a valid image file");
            }
        }
        setIsImageChanged(true);
    }

    function parseFile(file) {
        output("<strong>" + encodeURI(file.name) + "</strong>");

        // var fileType = file.type;
        var imageName = file.name;

        var isGood = true///\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
        if (isGood) {
            document.getElementById("start").classList.add("hidden");
            document.getElementById("response").classList.remove("hidden");
            // Thumbnail Preview
            document.getElementById("file-image").classList.remove("hidden");
            //document.getElementById('file-image').src = URL.createObjectURL(file);
            setRoomImage(URL.createObjectURL(file));
        }
        // else if (!isGood) {
        //  setImageError("please upload a valid image file");
        else {
            document.getElementById("file-image").classList.add("hidden");
            document.getElementById("start").classList.remove("hidden");
            document.getElementById("response").classList.add("hidden");
            document.getElementById("file-upload-form").reset();
        }
    }

    function output(msg) {
        // Response
        var m = document.getElementById("messages");
        m.innerHTML = msg;
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{props.roomData.key ? 'Edit' : 'Add'} Room</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="form" onSubmit={(e) => {
                    submitForm(e)
                }}>
                    <Form.Group>
                    <Form.Row className="add-room-container">
                        <Col xs={6}>

                          <Form.Label>Name</Form.Label>
                          <Form.Control placeholder="Enter room title" defaultValue={props.roomData.name} onChange={e => {setRoomName(e.target.value);}} required/>
                          <br/>

                          <Form.Label>Description</Form.Label>
                          <Form.Control placeholder="Enter room description" defaultValue={props.roomData.description} onChange={e => {setRoomDescription(e.target.value);}} required as="textarea" rows="5"/>
                          <br/>
                        </Col>


                        <Col xs={6}>
                            {roomImage && (
                                <div
                                    className="remove-img"
                                    onClick={() => {
                                        setIsImageChanged(false);
                                        setRoomImage("");
                                        setImageFile(false);
                                    }}
                                >
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faTimes} size="sm" />
                                    </div>
                                </div>
                            )}
                            <div className="d-flex photo-container">
                                <div className="uploader">
                                    <input
                                        id="file-upload"
                                        type="file"
                                        name="fileUpload"
                                        accept="image/*"
                                        onChange={(e) => onImageUpload(e)}
                                    />
                                    <label
                                        htmlFor="file-upload"
                                        id="file-drag"
                                        onDragOver={(e) => {
                                            fileDragHover(e);
                                        }}
                                        onDragLeave={(e) => {
                                            fileDragHover(e);
                                        }}
                                        onDrop={(e) => {
                                            fileSelectHandler(e);
                                        }}
                                    >
                                        <img
                                            id="file-image"
                                            src={roomImage}
                                            style={{ borderRadius: "7px" }}
                                            alt=""
                                        />
                                        <div id="start" className="d-flex">
                                            {!roomImage && (
                                                <>
                                                    <div>Select an image or drag here</div>
                                                    <span
                                                        id="file-upload-btn"
                                                        className="button button-secondary"
                                                        style={{ margin: "1em 0" }}
                                                    >
                                                        Select an image
                                                    </span>
                                                </>
                                            )}
                                        </div>

                                        <div id="response" className="hidden">
                                            <div style={{ display: "none" }} id="messages"></div>
                                        </div>
                                        {imageError && (
                                            <div className="m-2 text-center text-danger">
                                                {imageError}
                                            </div>
                                        )}
                                    </label>
                                </div>
                            </div>
                        </Col>


                    </Form.Row>
                    </Form.Group>


                    <div className="float-right">
                        {props.roomData.key &&
                            <Button variant="danger" className="submit-button mr-3" onClick={() => {
                                deleteRoom()
                            }}>
                               <FontAwesomeIcon icon={faTrash} size="1x" className="" /> Delete
                            </Button>}

                        {props.roomData.key &&<Button variant="dark" className="submit-button mr-3" onClick={()=>{cloneRoom()}}>
                            
                            {isCloneLoader ? <Spinner animation="border" size={'sm'} role="status" /> : <FontAwesomeIcon icon={faClone} size="1x" className="" />} Clone
                        </Button>}

                        <Button variant="info" type="submit" className="submit-button">
                            {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : 'Submit'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
            <Modal show={showDeleteModal} onHide={handleDeleted}>
                <DeleteAlert onHide={handleDeleted} title={'Delete Room'} message={'Are you sure you want to delete room'} warning={'Members won\'t be able to view it anymore.'} docId={docId} collectionName={'rooms'} />
            </Modal>
        </>
    );
}

export default AddRoom
