import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Spinner, Col, Form } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlusCircle, faMinusCircle, faInfoCircle, faCheckSquare, faComment, faCommentDots, faMoneyBill, faClock, faImage, faHeadset, faPaperPlane, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase/compat/app';
import { firestore, storage } from "../services/firebase";
import StarRatings from 'react-star-ratings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactHtmlParser from 'react-html-parser';
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { confirm } from "./Confirmation";
import * as _ from "lodash";

import moment from 'moment';

library.add(faPlusCircle, faMinusCircle);


const replies = [

    `لاحظنا ان حضرتك بتحاول تدفع للإشتراك لكن عملية الدفع مش بتكمل
يوجد دفع عن طريق فودافون كاش على رقمنا
01014302208
بعد الدفع بتبعتلنا صورة الإيصال وهنضيفك للبرنامج`,
    `Hello <user>! As per your request, Kindly report a refund issue to Apple through this link http://reportaproblem.apple.com/ if you face any issues during the process please note you will need to contact them through email or customer support and escalate the issue with then and you will get the refund at once :)`,
    `Hello <user>! You can join the challenge through this link <link>`,
    `Hello <user>! Thanks for your patience :) Basically, The credit points are virtual coins to purchase the challenge so it's as same as the normal payment method don't worry as if you change the currency it will be the same to EGP :).`,
    `Hello <user>! Thanks for your patience, I escalated the issue with our developers and will get back to you shortly!`,
    `Hello <user>! The (i) button is not available in this version but we're working on it and will be available again in the next update :)`,
    `Hello <user>! Thanks for your patience, We're working on it and will get back to you shortly :).`,
    `Hello <user>! Thanks for your patience, Can you please update the app to the latest version from the store and let me know if you still have this issue, :)`,
    `Hello <user>! You can contact <nutritionist> for any medical/Nutritional consultations or for alternatives through the app if you go to the challenge tab and then click on questions. as we're the technical support team :).`,
    `Hello <user>! You can contact <nutritionist> for any medical/Nutritional consultations or for alternatives through the app if you go to the program tab and then click on questions. as we're the technical support team :).`,
    `Hello <user>! This is from the technical support team. I hope you're having a great day! How can i help you? `,
    `Hello <user>! Can you please tell me the exact reason for the refund to help us to improve our service?`,
    `Hello <user>! You can join the challenge through this link <link> !`,
    `Hello <user>! Can you please provide us with a screenshot showing the full transaction to let me help you professionally?`,
    `Hello <user>! If you still have this issue, Kindly send a screenshot to us showing the issue to let me help you professionally!`,
    `Hello <user>! Please make sure that the account you paid from is the same account you're contacting us from as it's not showing any payments on this account :)`,
    `Hello <user>! Thanks for your patience :) Unfortunately, our cash gateway has been changed to be only with credit cards!.`,
    `Hello <user>! When it starts you will get access to the shopping list and recipes so you can buy it and start sticking to. Each meal plan has recipes for different meals along the day so it's your call to plan your time, with each plan you will eat or each workout you do, you need to post a picture for it to increase the point during the challenge from the feed tab click on posts or workouts. you can interact with other members if you drop a comment or like their pics too, you can ask the trainer or the dr any question through the program then click on questions.`,
    `Hello <user>! Would you like me to direct your message to <nutritionist>? As per your request be informed that some consultations need 1 on 1 meeting and that's not supported in the application :)`,
    `Hello <user>! You will see the shopping list of the whole month 2 days prior to the challenge, and you will get access to the whole week's recipes when the challenge starts :)`,
    `Hello <user>! The algorithm for calculating points is being updated regularly to prevent spamming and ensure adding values to users,!`,
    `Hello <user>! Happy to see you here! you've joined the challenge now :)`,
    `Hello <user>! Usually, they do that for the first time so you need to contact them through Email / Customer support and escalate the issue then you will get the refund at once :)`,
    `Hello <user>! No worries! Please if you have any additional questions don't hesitate to text us :)`,
    `Hello <user>! We're so sorry about this error! It's fixed and you will be able to use it normally with the next new version when it's released soon :)`,
    `Hello <user>! I'm afraid all the challenges are full now! Kindly follow up with our challenges dashboard after the current challenge end to know the new rounds/challenges :)`,
    `Hello <user>! You can see the recipes for the first day for now and u will get access to the whole week's recipes when the challenge starts! you can check the shopping list if u click on program button then shopping list button above!`,
    `Hello <user>! I let you join the challenge for now and we will send u a payment link to pay later! Enjoy the challenge :)`,
    `Hello <user>! I let you join the challenge manually to enjoy it and not to miss anything there, We will send you a payment link including an invoice to pay later :)`,
    `Hello <user>! You can check the shopping list if you click on the program button down on the menu then the shopping list button`,
    `If you want to check your shopping list please click on the challenge button through the menu below then click on shopping list button. `,
    `if you want to check the daily recipes when the challenge starts please click on the challenge button. `,
    `if you want to check the info about the group and the challenge please click on the challenge button then the orange ( i ) button above.`,
    `Hello <user>! Please note that we keep updating the algorithm for calculating points to prevent spamming and ensure adding values to users, and this not for individuals but for all users to match the mentioned and the organic activity which is planned to give you the maximum and the best quality, Also this algorithm is being updated regularly according to other users behavior!`,
    `Hello <user>! we want to welcome you to the second round of our MAMA-G Program and hope you benefit the most from it. :) We just want to know if you are pregnant or breastfeeding? and if pregnant please tell us which trimester you are in so we can assign you to a suitable plan. Thank you.`,
    `Hello <user>! Thank you, You can check your shopping list now :)`,
    `Most Welcome , We are working hard to build a higher level of support for our customers. We would love to learn more about your opinion. Please give us a minute and go to https://play.google.com/store/apps/details?id=com.welnes&hl=en&gl=US to give us your feedback. Thank you.`,
    `Most Welcome , We are working hard to build a higher level of support for our customers. We would love to learn more about your opinion. Please give us a minute and go to https://apps.apple.com/eg/app/welnes/id1513854681 to give us your feedback. Thank you.`,
    `Hello <user>, we want to welcome you to this round of <challenge> and hope you benefit the most from it. :)`,
    `We just want to know if you are pregnant or breastfeeding? and if pregnant please tell us which trimester you are in so we can assign you to a suitable plan. Thank you.`,
]


export function AddSupport(props) {
    const totalDays = 30;
    const [text, setText] = useState("");
    const [messageEl, setMessageEl] = useState();
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [questionImage, setquestionImage] = useState('');
    const [image, setImage] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [imageFile, setImageFile] = useState(false);
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [isCloseLoader, setIsCloseLoader] = useState(false);
    const [assignToSupportLoader, setAssignToSupportLoader] = useState(false);
    const [assignToFollowupLoader, setAssignToFollowupLoader] = useState(false);
    const [isPendingLoader, setIsPendingLoader] = useState(false);
    const [isPaymentLoader, setIsPaymentLoader] = useState(false);
    const [showReplies, setShowReplies] = useState(false);
    const [imageError, setImageError] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [paidChallenges, setPaidChallenges] = useState({});
    const [upcomingChallenges, setUpcomingChallenges] = useState({});
    const fileInput = useRef(null);
    const initFormValue = {
        challengeId: '',
        challengeName: '',
        question: '',
        answer: '',
    };
    const [formValue, setFormValue] = useState(initFormValue);
    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem("userDetails"))
    );
    useEffect(() => {
        setFormData();

        setTimeout(()=>{ if(document.getElementById("user-info")) document.getElementById("user-info").style.display='block'; }, 1000)
        setTimeout(()=>{ if(document.getElementById("user-info")) document.getElementById("user-info").style.left=0 }, 2000)

        props.questionData.user && firestore("payments")
        //.where("challengeId", "==", challengeId)
        //.where("userId", "==", props.questionData.user.id)
        .where("userId", "==", props.questionData.key)
        .get().then(function(docs){

            var payments = []
            var paidChallenges = {}
            var upcomingChallenges = {}

            docs.forEach(function(doc){

                payments.push(doc.data())

                if(!doc.data().declined && !doc.data().refunded) paidChallenges[doc.data().challengeId] = true

                if(!doc.data().declined && !doc.data().refunded && doc.data().rounds > 1 && doc.data().rounds > doc.data().rounds - doc.data().roundsJoined.length) upcomingChallenges[doc.data().challengeId] = doc.data().rounds - doc.data().roundsJoined.length
            })

            setPaidChallenges(paidChallenges)
            setUpcomingChallenges(upcomingChallenges)

            return
        })
        .catch(function(error) {

            console.error("Error finding payment: ", error);
        });


        setTimeout(()=>{ if(document.getElementById('messageEl')) document.getElementById('messageEl').scrollIntoView({ behavior: "smooth" });}, 3000)

        window.addEventListener('paste', e => {
            
            if(!e.clipboardData.files || !e.clipboardData.files.length) return false
            e.target.files = e.clipboardData.files;
            handleFileUpload(e);
        });

        return () => {
        };
    }, []);

    const onEmojiClick = (event, emojiObject) => {

        var position = document.getElementById('messageEl').selectionStart
        setText([text.slice(0, position), emojiObject.emoji, text.slice(position)].join(''))
    };

    function setFormData() {
        let formValueClone = initFormValue;
        if (props.questionData) {
            formValueClone = props.questionData;
        }
        setFormValue(_.clone(formValueClone));
    }

    async function assignToSupport(){

        setAssignToSupportLoader(true);

        await firestore('users_support').doc(props.questionData.key).update({closed: false, supportBy: firebase.firestore.FieldValue.delete()});

        setAssignToSupportLoader(false);
        props.handleClose(true);
    }

    async function assignToFollowup(){

        setAssignToFollowupLoader(true);

        await firestore('users_support').doc(props.questionData.key).update({closed: false, supportBy: 'Yomna Tharwat'});

        setAssignToFollowupLoader(false);
        props.handleClose(true);
    }

    async function closeTicket(){

        setIsCloseLoader(true);

        await firestore('users_support').doc(props.questionData.key).update({closed: true, pending: firebase.firestore.FieldValue.delete(), ...(props.questionData.supportBy === 'Yomna Tharwat' /*|| props.questionData.supportBy === 'Abd L Rahman Diab'*/ || props.questionData.supportBy === 'Andrew Samir' || props.questionData.supportBy === 'Ranaa Kamal') && {supportBy: firebase.firestore.FieldValue.delete()}, });

        setIsCloseLoader(false);
        props.handleClose(true);
    }

    async function openTicket(){

        setIsPendingLoader(true);

        await firestore('users_support').doc(props.questionData.key).update({pending: true});

        setIsPendingLoader(false);
        props.handleClose(true);
    }

    async function paymentFailure(){

        setIsPaymentLoader(true);

        await firestore('users_support').doc(props.questionData.key).update({paymentFailure: !props.questionData.paymentFailure});

        setIsPaymentLoader(false);
        props.handleClose(true);
    }

    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();

        if(!text && !image) return alert('please type reply message')

        setIsSubmitLoader(true);
        //if (props.questionData.key) {

            //const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
            //formValue.answerBy = 'nutritionist';
            //formValue.answerById = userDetails.id;
            //console.log(text)
            var newMessage = {}
            newMessage.body = text || ''
            if(image) newMessage.image = image

            newMessage.user = {
                id: user.id, 
                name: user.name, 
                image: user.image
            }
            
            newMessage.nutritionist = {
                id: user.id, 
                name: user.name, 
                image: user.image
            }

            newMessage.sentBy = 'nutritionist'

            //newMessage.supportById = true;
            //newMessage.supportByImage = true;
            newMessage.datetime = moment.utc().format();
            newMessage.created = firebase.firestore.FieldValue.serverTimestamp();
            await firestore('messages').doc(props.questionData.q.key).collection('recipients').doc(props.questionData.user.id).collection('thread').add(newMessage);
        /*} else {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            formValue.nutritionistId = userDetails.id;
            formValue.datetime = moment().format();
            await firestore('questions').add(formValue);
        }*/
        setIsSubmitLoader(false);
        props.handleClose(true);
        // } else {
        //     setImageError('image is required');
        // }
    }

    async function deleteMessage(message){

        if (
            await confirm(
                "delete post",
                "are you sure you want to delete this message ?" +
                    "\n\n" +
                    (message.message || ""),
                "Delete"
            )
        ) {
            
            await firestore('users_support').doc(props.questionData.key).collection('messages').doc(message.id).delete();
            await firestore('users_support').doc(props.questionData.key).update({message: 'message deleted'});

            //alert("message has been deleted");

            const formValueClone = _.clone(formValue);
            formValueClone.messages = formValueClone.messages.filter(d => d.id !== message.id);
            setFormValue(formValueClone);
        }
    }

    async function handleFileUpload(e){

        e.stopPropagation();
        e.preventDefault();

        setImageLoader(true);

        console.log(e.target.files[0].name);

        const fileName = e.target.files[0].name+'-'+moment().unix();
        const imageFile = (e.target.files || e.dataTransfer.files)[0];

        const uploaded = await storage().ref().child(`/support/${fileName}`).put(imageFile, {cacheControl: 'public,max-age=31536000'});
        const child = await storage().ref().child(uploaded.metadata.fullPath);
        const image = await child.getDownloadURL();

        setImage(image)
        setImageLoader(false);
        return

        const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
        var newMessage = {}
        newMessage.body = ''
        newMessage.image = image
        //newMessage.isSupport = true;
        //newMessage.supportBy = adminDetails.name;
        newMessage.datetime = moment.utc().format();
        newMessage.created = firebase.firestore.FieldValue.serverTimestamp();
        await firestore('messages').doc(props.questionData.key).collection('recipients').doc(props.questionData.user.id).collection('thread').add(newMessage);

        setIsSubmitLoader(false);
        props.handleClose(true);
    };

    function fbImage(imageLink){
        
        if(!imageLink) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    var special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
    var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

    function stringifyNumber(n) {
      if (n < 20) return special[n];
      if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
      return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
    }

    function showPayments(userId, challengeId){

        firestore("payments")
        .where("challengeId", "==", challengeId)
        .where("userId", "==", userId)
        .get().then(function(docs){

            var payments = []

            docs.forEach(function(doc){

                payments.push(doc.data())
            })

            if(!payments.length) return alert('no payments for this user')
            //if(payments.length>1) alert('there are several payments for this user')

            payments = _.orderBy(payments, d=>moment.utc(d.datetime).format(), 'asc')

            var index = parseInt(alert('('+payments.length+') payment attempts'+'\n\n'+ 
                payments.map(function(payment, i){
                    return String(i+1)+'. '+stringifyNumber(i+1)+ ' attempt ==> '+ (payment.declined||'Successful')
                }).join('\n')//+ '\n\n check payment for:'
                
                , '1'

            )) - 1


            //prompt("userId:", payments[index].userId, "challengeId:", payments[index].challengeId, "type:", payments[index].type, "amount:", payments[index].amount);

            return
        })
        .catch(function(error) {

            console.error("Error finding payment: ", error);
        });
    }

    function replaceLinks(text){

      return (text || "").replace(
        /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
        function(match, space, url){
          var hyperlink = url;
          if (!hyperlink.match('^https?:\/\/')) {
            hyperlink = 'http://' + hyperlink;
          }
          return space + '<a href="' + hyperlink + '"  target="_blank" style="max-width: 100%;display: inline;overflow-wrap: break-word;">' + url + '</a>';
        }
      );
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{formValue.user && formValue.user.name} - Thread</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {formValue.user && <div id="user-info" style={{zIndex: 10, transition: '.3s',display:'none',left:'-400px',position:'fixed',top: '0',padding: '2em',background: 'beige',borderBottomRightRadius: '2em',border: '1px solid #aaa'}}>

                    <div style={{fontSize:'.9em', opacity: '.5', padding: '.5em 0'}}><FontAwesomeIcon icon={faInfoCircle} size="1x" className="" style={{opacity:'.3'}}/> user info </div>
                    <div>{/*<img src={fbImage(formValue.user.image)} style={{maxWidth:'60px', maxHeight:'60px'}}/>*/}{formValue.user.name}</div>
                    <div>{formValue.user.email}</div>
                    {formValue.user.mobileNumber && <div>+{formValue.user.dielCode} {formValue.user.mobileNumber}</div>}
                    {formValue.user.phone && <div>+{formValue.user.phone.callingCode} {formValue.user.phone.number}</div>}
                    {formValue.userFollowupNo && <div>{formValue.userFollowupNo}</div>}
                    {formValue.user.country && <div>{formValue.user.country}</div>}

                    <br/>
                    {formValue.user.deviceDetails && 
                        <>
                        <div style={{fontSize:'.9em', opacity: '.5', padding: '.5em 0'}}><FontAwesomeIcon icon={faInfoCircle} size="1x" className="" style={{opacity:'.3'}}/> device info </div>
                        <div>{formValue.user.deviceDetails.brand} ({formValue.user.deviceDetails.systemVersion})</div>
                        </>
                    }

                    <br/>
                    {formValue.user.lastOpened && 
                        <>
                        <div style={{fontSize:'.9em', opacity: '.5', padding: '.5em 0'}}><FontAwesomeIcon icon={faInfoCircle} size="1x" className="" style={{opacity:'.3'}}/> app info </div>
                        <div>{formValue.user.lastOpened.version}</div>
                        </>
                    }

                </div>}


                <Modal show={showReplies} onHide={() => setShowReplies(false)} style={{}}>
                    <div id="custom-replies" style={{padding: '1em',background: '#eee',border: '1px solid #aaa', fontSize: '.9em'}}>

                        {replies.map((reply, i) => <div key={i} style={{borderRadius: '3px', margin: '.5em', padding: '.5em',background: '#fff', border: '1px solid #ddd', cursor: 'pointer'}} onClick={()=>{
                            setShowReplies(false);

                            const currentChallegne = props.challenges.filter(c => c.key == formValue.user.challengeId)[0]

                            const user = formValue.user?formValue.user.name.toLowerCase().split(' ')[0]:''
                            const challenge = currentChallegne? currentChallegne.name : ''
                            const nutritionist = currentChallegne? currentChallegne.nutritionistName.split(' ')[0] : ''
                            const nutritionistFull = currentChallegne? currentChallegne.nutritionistName : ''
                            const link = 'https://welnes.app/'+nutritionistFull.replaceAll(' ','.')+'/'+challenge.replaceAll(' ','.')

                            setText(
                                reply
                                    .replace('<user>', user)
                                    .replace('<challenge>', challenge)
                                    .replace('<nutritionist>', nutritionist)
                                    .replace('<link>', link)
                            )

                            setTimeout(()=>{ document.getElementById('messageEl').scrollIntoView();}, 0)

                        }}>{reply}</div>)}
                    </div>
                </Modal>


                {!showReplies && <Form className="form support-page-form" onSubmit={(e) => { e.preventDefault(); }}>
                    {formValue.messages && formValue.messages.length? formValue.messages.map((message)=> <>
                    {message.sentBy == 'nutritionist' && Math.abs(moment().diff(moment(message.datetime), 'minutes')) <= 10 && <FontAwesomeIcon icon={faTrashAlt} size="1x" onClick={() => {deleteMessage(message)}} style={{float: 'right', marginRight: '0.7em', cursor: 'pointer'}}/>}
                    <Form.Group key={message.datetime} style={message.sentBy == 'nutritionist'? 
                        {overflow: 'hidden', borderRadius: '1em', background:'#eee', padding:'1em', marginRight:'3em'} : 
                        {overflow: 'hidden', borderRadius: '1em', background:'#42A2F9', padding:'1em', marginLeft:'3em', color: '#fff'}}>

                        <div style={{fontSize:'0.9em', opacity:'0.9', float: 'right', paddingLeft: '3px'}}>{moment.utc(message.datetime).fromNow().replace('a few seconds ago', 'now')}</div>
                        <div style={{direction:'rtl', whiteSpace: 'pre-line'}}>{ReactHtmlParser(replaceLinks(message.body))}</div>
                        {message.image && <img src={message.image} style={{maxWidth:'100%', marginTop:'1em'}}/>}

                    </Form.Group>
                    </>) : null}

                    <br/>

                    <div className="reply-dialogue" style={{display: 'flex'}}>
                        <Form.Control as="textarea" rows="3" required style={{borderColor: '#ddd'}} id="messageEl"
                            placeholder={formValue.messages && formValue.messages.length? 'reply' : 'send message to '+(formValue.user && formValue.user.name && formValue.user.name.toLowerCase())} 
                            value={text} onChange={e => {
                            setText(e.target.value);
                        }} />


                        <Button
                            variant="light"
                            type="submit"
                            className={
                                "float-right submit-button show"
                            }
                             onClick={(e) => {submitForm(e)}}
                        >
                            {isSubmitLoader ? (
                                <Spinner animation="border" size={"sm"} role="status" />
                            ) : (
                                <>
                                    <FontAwesomeIcon
                                        icon={faPaperPlane}
                                        size="1x"
                                        className=""
                                    />
                                </>
                            )}
                        </Button>

                    </div>




                    <div style={{marginTop: '1em'}}>
                        <Picker
                            onEmojiClick={onEmojiClick}
                            disableAutoFocus={true}
                            skinTone={SKIN_TONE_MEDIUM_DARK}
                            groupNames={{ smileys_people: "PEOPLE" }}
                        />
                    </div>

                    {/*<InputEmoji
                        //height = {180}
                        //as="textarea" rows="3" required
                        value={text}
                        onChange={value => {console.log(value); if(value) setText(value); setTimeout(function(){setText(text+' 1')}, 5000)}}
                        //cleanOnEnter
                        //onEnter={(value) => { addMessage(value); }}
                        placeholder={formValue.messages && formValue.messages.length? 'reply' : 'send message to '+(formValue.user && formValue.user.name.toLowerCase())} 
                    />


                    <EmojiField/>*/}

                    <br/>


                </Form>}
            </Modal.Body>
        </>
    );
}

export default AddSupport
