import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "./LoginPage.scss";

/* images */
import logoImg from "../../assets/icons/icon.png";

const uiConfig = {
  signInFlow: "popup", //redirect is not working on ios 16.x
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    "apple.com",
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
    signInFailure: (error) => {
      // Handle the error
      console.log(error);
      // Reject the Promise to avoid retrying to login
      return Promise.reject(error);
    },
  },
};




export function LoginPage() {
  return (
    <div className="page-container">
      <div className="data-container w-100 h-100 d-flex text-center justify-content-center flex-column">
        <div className="login-container shadow-lg">
          <img height="150" src={logoImg} alt="Diet Challenge" />
          <div className="welcome-message-container">Welcome to Welnes!</div>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      </div>
    </div>
  );
}
