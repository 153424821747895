import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Form,
    Modal,
    ButtonToolbar,
    Table,
    DropdownButton,
    Dropdown,
    Spinner,
    Breadcrumb
} from "react-bootstrap";
import Masonry from 'react-masonry-css'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faFilter, faPencilAlt, faCopy, faTrashAlt, faArrowUp, faArrowDown, faUndo, faCheck, faTimes, faHeadset, faArchive, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { confirm } from "../../components/Confirmation";
import { firestore } from "../../services/firebase";
import AddPromocode from '../../components/AddPromocode';
import * as _ from "lodash";
import DeleteAlert from '../../components/DeleteAlert';
import moment from 'moment';
import { NavLink, useHistory } from "react-router-dom";

import { Payments } from "./Payments";
library.add(faPlus, faFilter);


const deliveryDate = function(datetime = moment()){

    return moment(datetime)
        .add(6 - moment(datetime).day(), 'days')
        .add(+(moment(datetime).day() >= 4), 'weeks')
}


export function Orders() {
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [ordersLoader, setOrdersLoader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');
    const [sort, setSort] = useState({
        field: 'datetime',
        direction: 'desc'
    });

    const tableHeaders = [{
        name: 'Name',
        filterField: 'name',
    //}, {
    //    name: '',
    //    filterField: 'none',
    }, {
        name: 'Date',
        filterField: 'datetime',
    }, {
        name: 'Type',
        filterField: 'type',
    }, {
        name: 'Kcal',
        filterField: 'calories',
    //}, {
    //    name: 'Actions',
    //    filterField: 'none',
    }];
    const [transactions, setTransactions] = useState([]);
    const [orders, setOrders] = useState([]);
    const [promocodeData, setPromocodeData] = useState({});
    const [filterData, setFilterData] = useState({});
    const [showPromocode, setShowPromocode] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [promocodeIdToName, setPromocodeIdToName] = useState();
    const [challengeIdToName, setChallengeIdToName] = useState();
    const [weeks, setWeeks] = useState({});
    const [weekOrders, setWeekOrders] = useState();
    const [nextWeek, setNextWeek] = useState(deliveryDate().format('YYYY-MM-DD'));
    const [selectedWeek, setSelectedWeek] = useState(nextWeek);
    const [weekLoader, setWeekLoader] = useState();
    const [leads, setLeads] = useState([]);
    const [leadsLoader, setLeadsLoader] = useState();


    useEffect(() => {
        const OrdersSubscription = getOrders();
        getLeads();
        return () => {
            OrdersSubscription();
        };
    }, []);

    useEffect(() => {
            
        console.log('nextWeek', nextWeek)

        //set to current week
        if(Object.keys(weeks).length) exportOrders(nextWeek)

        return () => {

        };
    }, [weeks, nextWeek]);

    useEffect(() => {

        //set to current week
        if(Object.keys(weeks).length) exportOrders(selectedWeek)

        return () => {

        };
    }, [selectedWeek]);


    function getOrders() {
        setOrdersLoader(true);
        return firestore('payments')
            .where('boxes', '>=', 1)
            //.orderBy('datetime', 'desc')
            //.limit(500)
            .onSnapshot((querySnapshot) => {
                var weeks = {}
                let data = querySnapshot.docs.filter(d => !d.data().declined).map((documentSnapshot, i) => {

                    for(var i=0; i<documentSnapshot.data().boxes; i++){

                        var deliveryDay = deliveryDate(documentSnapshot.data().datetime).add(i, 'week').format('YYYY-MM-DD')
                        if(!weeks[deliveryDay]) weeks[deliveryDay] = []
                        weeks[deliveryDay].push(_.clone(documentSnapshot.data()))

                        console.log(documentSnapshot.data().datetime, moment(documentSnapshot.data().datetime).startOf('isoWeek').add(i, 'week').day('saturday').format('YYYY-MM-DD'))
                    }

                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });
                console.log(data)
                setOrdersLoader(false);
                //data.map(d => {if(d.nutritionistDatetime) d.datetime = d.nutritionistDatetime; return d})
                setOrders(_.orderBy(data, 'datetime', 'desc'));
                setWeeks(weeks)

            });
    }

    function getLeads() {

        if(!leads.length) setLeadsLoader(true);
        
        firestore('leads').orderBy('datetime', 'desc').get().then((querySnapshot) => {

            console.log(querySnapshot.docs)

            var contacts = []
            var uniquePhone = {}

            querySnapshot.docs.map((documentSnapshot, i) => {

                if(!uniquePhone[documentSnapshot.data().phone]) contacts.push({
                    ...documentSnapshot.data(),
                    key: documentSnapshot.id,
                });
                else{
                    const contact = contacts.filter(d => d.phone === documentSnapshot.data().phone)[0]
                    if(!contact.contactedAt) contact.contactedAt = []
                    contact.contactedAt.push(documentSnapshot.data().datetime)
                }

                uniquePhone[documentSnapshot.data().phone] = true
            })


            firestore('users').orderBy('contact', 'desc').get().then((querySnapshot) => {

                var usersByPhone = {}

                querySnapshot.docs.map((documentSnapshot, i) => {

                    usersByPhone[documentSnapshot.data().contact.phone] = documentSnapshot.id
                });

                contacts.map(contact => {

                    if(usersByPhone[contact.phone]) contact.userId = usersByPhone[contact.phone]
                })

                setLeads(contacts)
                setLeadsLoader(false);
            });
        });
    }

    async function exportOrders(week){

        setWeekLoader(true);
        
        if(!weeks[week]) weeks[week] = []

        let data = await Promise.all(weeks[week].map(async (order, i) => {
            
            if(!order.contact){

                const userDoc = await firestore("users").doc(order.userId).get();
                const userData = userDoc.data();
                const contact = userData.contact || {}

                order.contact = contact
            }

            return order
        }))

        console.log(data)
        setWeekOrders(data)

        setWeekLoader(false);
    }

    function csv(){

        function downloadCSVFile(csv, filename) {
            var csv_file, download_link;
            csv_file = new Blob(["\ufeff"+csv], {type: "text/csv"});
            download_link = document.createElement("a");
            download_link.download = filename;
            download_link.href = window.URL.createObjectURL(csv_file);
            download_link.style.display = "none";
            document.body.appendChild(download_link);
            download_link.click();
        }

        var data = [];
        var rows = document.querySelectorAll("table.week-orders tr");
                
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");
                    
            for (var j = 0; j < cols.length; j++) {
                row.push(cols[j].innerText);
            }
                    
            data.push(row.join(","));       
        }

        downloadCSVFile(data.join("\n"), selectedWeek);
    }

    async function contacted(d){

        if (await confirm(
            'Acknowledge',
            'are you sure '+d.name+' has been contacted'+' ?'
            , 'acknowledge')){
            
            await firestore('leads').doc(d.key).update({status: 'contacted'});

            //re-render
            getLeads()
        }
    }

    return (
        <>
            <Breadcrumb className="pb-3">
                <Breadcrumb.Item active>Orders</Breadcrumb.Item>
            </Breadcrumb>
            <div className="pt-5">

                <Card style={{borderRadius:"initial",padding:"0"}}>
                    <Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            Weeks
                            {/*<Button size='sm' variant="info" className="text-uppercase" onClick={() => {
                                addMealPlan();
                            }}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />Add promo code
                            </Button>*/}
                        </h3>
                    </Card.Header>
                    <Card.Body style={{padding:"40px"}}>
                        <div style={{marginBottom:"40px"}}>
                            {Object.keys(weeks).sort().map(week => 
                                <Button size="sm" variant="light" onClick={()=>{setSelectedWeek(week)}} style={{background: week == selectedWeek?'#56C378':'inherit', marginRight: '5px', borderColor: '#eee'}}>
                                    {week} {week === nextWeek && <span style={{fontSize: '.9em', opacity: .6, marginLeft: '1em'}}>(current)</span>}
                                </Button>)
                        }
                        <Button size="sm" variant="dark" onClick={csv} style={{float: 'right'}}><FontAwesomeIcon icon={faDownload} size="1x" className="mr-1"/> csv</Button>
                        </div>
                        {(!weekOrders || weekLoader) ? <div className="text-center" style={{padding: '50px'}}><Spinner animation="border" size={'lg'} role="status" style={{height: '50px', width: '50px'}}/></div> :
                        <table className="week-orders" style={{width: '100%'}}>
                            <thead>
                                <tr style={{height: '50px'}}>
                                    <th>Order Date</th>
                                    <th>Full Name</th>
                                    <th>Type</th>
                                    <th>Phone No.</th>
                                    <th>Postal Code</th>
                                    <th>Address</th>
                                    <th style={{textAlign: 'right'}}>District</th>
                                </tr>
                            </thead>
                            <tbody>
                                {weekOrders.map(d => <tr style={{height: '30px'}}>
                                    <td>{d.datetime.split('T')[0]}</td>
                                    <td>{d.contact.name}</td>
                                    <td>{d.boxType}</td>
                                    <td>{d.contact.phone}</td>
                                    <td>{d.contact.post}</td>
                                    <td>{d.contact.address}</td>
                                    <td style={{textAlign: 'right'}}>{d.contact.district}</td>
                                </tr>)}
                            </tbody>
                        </table>}
                    </Card.Body>
                </Card>

                <Card style={{borderRadius:"initial",padding:"0", marginTop: '5em'}}>
                    <Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            Orders ({orders.length})
                            {/*<Button size='sm' variant="info" className="text-uppercase" onClick={() => {
                                addMealPlan();
                            }}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />Add promo code
                            </Button>*/}
                        </h3>
                    </Card.Header>
                    <Card.Body style={{padding:"40px"}}>

                        {ordersLoader ? <div className="text-center" style={{padding: '50px'}}><Spinner animation="border" size={'lg'} role="status" style={{height: '50px', width: '50px'}}/></div> : 
                            <table id="orders" class="table components" style={{margin: 0}}>
                            <thead>
                            <tr style={{height: '50px'}}>
                                <th style={{border: 'none', verticalAlign: 'middle'}}>Date</th>
                                <th style={{border: 'none', verticalAlign: 'middle'}}>Name</th>
                                <th style={{border: 'none', verticalAlign: 'middle'}}>Boxes</th>
                                <th style={{border: 'none', verticalAlign: 'middle'}}>Type</th>
                                <th style={{border: 'none', verticalAlign: 'middle'}}>Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map(d => <tr style={{height: '30px'}}>
                                <td>{d.datetime.split('T')[0]}</td>
                                <td>{d.userName}</td>
                                <td>{d.boxes} {d.boxes === 1? 'box' : 'boxes'}</td>
                                <td>{d.boxType}</td>
                                <td>{d.amount} EGP</td>
                            </tr>)}
                        </tbody>
                        </table>}

                    </Card.Body>
                </Card>


                <Payments box/>
                <div style={{marginTop: '7em'}}></div>


                <Card style={{borderRadius:"initial",padding:"0", marginTop: '5em', marginBottom: '10em'}}>
                    <Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            Leads ({leads.length})
                            {/*<Button size='sm' variant="info" className="text-uppercase" onClick={() => {
                                addMealPlan();
                            }}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />Add promo code
                            </Button>*/}
                        </h3>
                    </Card.Header>
                    <Card.Body style={{padding:"40px"}}>

                        {leadsLoader ? <div className="text-center" style={{padding: '50px'}}><Spinner animation="border" size={'lg'} role="status" style={{height: '50px', width: '50px'}}/></div> : 
                            <table id="orders" class="table components" style={{margin: 0}}>
                            <thead>
                            <tr style={{height: '50px'}}>
                                <th style={{border: 'none', verticalAlign: 'middle'}}>Date</th>
                                <th style={{border: 'none', verticalAlign: 'middle'}}>Full Name</th>
                                <th style={{border: 'none', verticalAlign: 'middle'}}>Contacted Before</th>
                                <th style={{border: 'none', verticalAlign: 'middle'}}>Phone No.</th>
                                <th style={{border: 'none', verticalAlign: 'middle'}}>Orders</th>
                            </tr>
                            </thead>
                            <tbody>
                            {leads.map(d => {d.orders = orders.filter(o => o.userId == d.userId).length; return d})
                                //.sort((a,b) => new Date(b.datetime) - new Date(a.datetime))
                                .map(d => <tr style={{height: '30px', color: d.status === 'contacted'?'#bbb':'initial'}}>
                                <td>{d.datetime.split('T')[0]}</td>
                                <td ref={(el) => el && el.style.setProperty('max-width', '150px', "important")}>{d.name}</td>
                                <td>{d.contactedAt && d.contactedAt.length && d.contactedAt[0].split('T')[0]}</td>
                                <td>{d.phone}</td>
                                <td style={{color: !d.orders&&d.status !== 'contacted'? 'red':'inherit'}}>{d.orders}</td>
                                <td style={d.status === 'contacted'?{textAlign: 'right', color: 'green', pointerEvents: 'none'}:{opacity:0.4, transition: '.3s', textAlign: 'right', color: 'green', cursor: 'pointer'}} className="remove" onClick={()=>{contacted(d)}}><FontAwesomeIcon icon={faCheck} /></td>
                            </tr>)}
                        </tbody>
                        </table>}

                    </Card.Body>
                </Card>
                {/*<Modal show={showPromocode} onHide={handleCloseMealPlan}>
                    <AddPromocode handleClose={handleCloseMealPlan} promocodeData={promocodeData} Orders={Orders} />
                </Modal>*/}
            </div>
            {/*<Modal show={showDeleteModal} onHide={handleDeleted}>
                <DeleteAlert onHide={handleDeleted} title={'Delete Meal Plan'} message={'Are you sure you want to delete meal plan?'} warning={'All the related recipes will be unassigned.'} docId={docId} collectionName={'meal_plans'} />
            </Modal>*/}
        </>
    )
}
