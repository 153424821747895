/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner, Col, Form, Row, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlusCircle,
	faMinusCircle,
	faComment,
} from "@fortawesome/free-solid-svg-icons";
import imageCompression from "browser-image-compression";
import { firestore, storage } from "../services/firebase";
import ReactHtmlParser from "react-html-parser";
import StarRatings from "react-star-ratings";
import * as _ from "lodash";

import moment from "moment";

library.add(faPlusCircle, faMinusCircle);
export function AddQuestion(props) {
	const [planTypes, setPlanTypes] = useState("");
	const [userPlanType, setUserPlanType] = useState("");
	const [userMealPlan, setUserMealPlan] = useState();
	const [userWorkoutPlan, setUserWorkoutPlan] = useState();
	const [userSelectedChallenge, setUserSelectedChallenge] = useState('');
	const [userSelectedChallengeLoader, setUserSelectedChallengeLoader] = useState(true);
	const totalDays = 30;
	const [isImageChanged, setIsImageChanged] = useState(false);
	const [questionImage, setquestionImage] = useState("");
	const [imageFile, setImageFile] = useState(false);
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [newMessage, setNewMessage] = useState("");
	const [isSubmitCommentLoader, setIsSubmitCommentLoader] = useState(false);
	const [imageError, setImageError] = useState("");
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);
	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);
	const initFormValue = {
		challengeId: "",
		challengeName: "",
		question: "",
		answer: "",
	};
	const [formValue, setFormValue] = useState(initFormValue);
	useEffect(() => {
		setFormData();
		return () => {};
	}, []);
	const [customPlanPressed, setCustomPlanPressed] = useState(false);
	const [errorMessageManualAssigned,setErrorMessageManualAssigned] = useState(false);


	useEffect(() => {
		let unsubUserSelectedChallenge = '';
		if(props.questionData.tag !== 'MealPlan' && props.questionData.tag !== 'WorkoutPlan') return

		firestore("users")
			.doc(props.questionData.userId)
			.get()
			.then((userSelectedChallenge) => {
			const healthInfo = userSelectedChallenge.data().healthInfo;
			setUserPlanType(
			props.questionData.tag === "MealPlan"
			? healthInfo.dietType ?? "noType"
			: healthInfo.exerciseType ?? "noType"
			);
			});

		firestore("users")
			.doc(props.questionData.userId)
			.collection("selectedChallenges")
			.doc(props.questionData.challengeId)
			.onSnapshot(userSelectedChallenge => {
				setUserSelectedChallenge(userSelectedChallenge.data());
				setUserMealPlan(userSelectedChallenge.data().mealPlanId)
				setUserWorkoutPlan(userSelectedChallenge.data().workoutPlanId)
				setUserSelectedChallengeLoader(false);
			})


		return () => {
			if(unsubUserSelectedChallenge){
				unsubUserSelectedChallenge();
				unsubUserSelectedChallenge= '';
			}
		};
	}, []);



	function setFormData() {
		let formValueClone = initFormValue;
		if (props.questionData) {
			formValueClone = props.questionData;
		}
		if (
		(props.questionData.tag === "MealPlan" ||
		props.questionData.tag === "WorkoutPlan") &&
		props.challenges
		) {
			const challengeData = props.challenges.find(
			(challenge) => props.questionData.challengeId === challenge.key
			);

			if(!challengeData) return alert('challenge not found')
			if(props.questionData.tag === "MealPlan" && !challengeData.dietTypes) return alert('challenge has no diet types')
			if(props.questionData.tag === "WorkoutPlan" && !challengeData.exerciseTypes) return alert('challenge has no exercise types')

			setPlanTypes(
				Object.entries(
				props.questionData.tag === "MealPlan"
				? challengeData.dietTypes
				: challengeData.exerciseTypes
				)
				.map((key, value) => {
				return key[1] && key[0];
				})
				.filter((x) => !!x)
			);
		}
		setFormValue(_.clone(formValueClone));
	}

	function addMessage(message) {
		console.log(message);
		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		const adminDetails = JSON.parse(localStorage.getItem("adminDetails"));

		setNewMessage({
			comment: message,
			nutritionistId: userDetails.id,
			userId: adminDetails.id,
			userName: adminDetails.name,
			userImage: adminDetails.image,
		});
	}

	async function submitForm(e) {
		e.stopPropagation();
		e.preventDefault();

		setIsSubmitLoader(true);
		//if (props.questionData.key) {

		//const appDoc = await firestore('constants').doc('app').get();
		//const app = appDoc.data().value;
		//const defaultAssistantName = {welnes: 'Team Welnes', welnes: 'Team Gorilla'}[app]
		//const defaultAssistantImage = {welnes: 'Team Welnes', welnes: 'Team Gorilla'}[app]
		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		const adminDetails = JSON.parse(localStorage.getItem("adminDetails"));

		if (userDetails.id === adminDetails.id) formValue.answerBy = "nutritionist";
		else if (userDetails.collaborators && userDetails.collaborators.includes(adminDetails.id)) formValue.answerBy = "collaborator";
		else if (userDetails.assistants && userDetails.assistants.includes(adminDetails.id)) formValue.answerBy = "assistant";

		formValue.answerFrom = 'dashboard';
		formValue.answerById = adminDetails.id;
		formValue.answerByName = adminDetails.name;
		formValue.answerByImage = adminDetails.image;
		formValue.answerDatetime = moment().utc().format();
		try{await firestore("feeds").doc(props.questionData.key).update(formValue); } catch (error) {console.error(error);}
		try{await firestore("questions").doc(props.questionData.key).update(formValue); } catch (error) {console.error(error);}
		/*} else {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            formValue.nutritionistId = userDetails.id;
            formValue.datetime = moment().format();
            await firestore('questions').add(formValue);
        }*/
		setIsSubmitLoader(false);
		props.handleClose(true);
		// } else {
		//     setImageError('image is required');
		// }
	}

	async function submitComment(e) {
		e.stopPropagation();
		e.preventDefault();

		setIsSubmitCommentLoader(true);

		newMessage.datetime = moment.utc().format();
		try{await firestore("questions").doc(props.questionData.key).collection("comments").add(newMessage); } catch (error) {console.error(error);}
		try{await firestore("feeds").doc(props.questionData.key).collection("comments").add(newMessage); } catch (error) {console.error(error);}

		setIsSubmitCommentLoader(false);
		props.handleClose(true);
	}

	async function onSelectMealPlan(user, challenge, selectedPlan) {

	    await firestore("users")
	      .doc(user)
	      .set(
	        {
	          healthInfo: {
	            dietType: userPlanType,
	          },
	        },
	        { merge: true }
	      );

		await firestore("users")
			.doc(user)
			.collection("selectedChallenges")
			.doc(challenge)
			.update({mealPlanId: selectedPlan, mealPlanManuallyAssigned: true, mealPlanManuallyAssignedBy: admin.name});

		await firestore("feeds")
			.doc(props.questionData.key)
			.update({currentPlan: selectedPlan, previousPlan: userMealPlan ? 'noPlan' : userMealPlan});

		setUserMealPlan(selectedPlan);
		setErrorMessageManualAssigned(false);
	}

	async function onSelectWorkoutPlan(user, challenge, selectedPlan) {
		

	    await firestore("users")
	      .doc(user)
	      .set(
	        {
	          healthInfo: {
	            exerciseType: userPlanType,
	          },
	        },
	        { merge: true }
	      );

		await firestore("users")
			.doc(user)
			.collection("selectedChallenges")
			.doc(challenge)
			.update({workoutPlanId: selectedPlan, workoutPlanManuallyAssigned: true, workoutPlanManuallyAssignedBy: admin.name});

		await firestore("feeds")
			.doc(props.questionData.key)
			.update({currentPlan: selectedPlan, previousPlan: userWorkoutPlan ? 'noPlan' : userWorkoutPlan});
			
		setUserWorkoutPlan(selectedPlan);
	}

	function fbImage(imageLink) {
		if (!imageLink) return imageLink;
		var query = imageLink.split("asid=")[1];
		if (!query) return imageLink;
		var fbId = query.split("&")[0];
		if (!fbId) return imageLink;

		return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
	}

	function replaceLinks(text) {
		return (text || "").replace(
			/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
			function (match, space, url) {
				var hyperlink = url;
				if (!hyperlink.match("^https?://")) {
					hyperlink = "http://" + hyperlink;
				}
				return (
					space +
					'<a href="' +
					hyperlink +
					'"  target="_blank" style="max-width: 100%;display: inline;overflow-wrap: break-word;">' +
					url +
					"</a>"
				);
			}
		);
	}

    function beautify(comment) {    //revert comment to @username

        var beautified = comment;
        
        comment.split('@').map(str => {

            if(str.startsWith('[')){

                const mention = str.split(')')[0]+')'
                const userName = str.split('](')[0].split('[')[1]
                const userId = str.split('](')[1].split(')')[0]

                beautified = beautified.replace(mention, userName)
            }
        })
        
        return beautified;
    }


	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title>
					{formValue.userName}{/*props.questionData.isAnswered ? "Edit Answer" : "Answer question"*/}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					className="form d-flex flex-column"
					onSubmit={(e) => {
						submitForm(e);
					}}
				>
					<Form.Group style={{ marginBottom: 0 }}>
						<br />
						<Form.Label
							dir="rtl"
							style={{ color: "#faa620", fontWeight: "700" }}
						>
							Question
						</Form.Label>

						<div>
							{formValue.privacy && formValue.privacy !== "public" && (
								<span className="private-tag">Private</span>
							)}
							{formValue.tag && (
								<span className="question-tag">{formValue.tag}</span>
							)}
							<div
								className="question mt-2"
								style={{
									overflowWrap: "break-word",
									textAlign: "start",
									unicodeBidi: "plaintext",
								}}
							>
								{ReactHtmlParser(replaceLinks(formValue.question))}
							</div>
						</div>
						{formValue.image && <div>
							<img 
								onClick={()=>{window.open(formValue.image.url)}}
								height="auto"
								alt="question"
								src={formValue.image.url}
								style={{
									width: "100%",
									//height: "200px",
									marginTop: "1em",
									borderRadius: "18px",
									objectFit: "cover",
									maxWidth: "100%",
									maxHeight: "100%",
									cursor: 'pointer'
								}}
							/>
						</div>}
						<br />
						<br />
						<Form.Label style={{ color: "#faa620", fontWeight: "700" }}>
							Answer
						</Form.Label>
						{props.questionData.answer && <span className="answer-by" style={{marginLeft: '1em'}}>{formValue.answerByName || formValue.answerById || 'error'}</span>}
						<Form.Control
							as="textarea"
							rows="3"
							placeholder="Write your answer"
							value={formValue.answer}
							onChange={(e) => {
								const formValueClone = _.clone(formValue);
								formValueClone.answer = e.target.value;
								setFormValue(formValueClone);
							}}
						/>
					</Form.Group>
					<Button
						variant="primary"
						type="submit"
						className="mt-3 button"
						style={{
							minWidth: "170px",
							margin: "auto",
							fontWeight: "500",
							backgroundColor: "#0e5171",
						}}
					>
						{isSubmitLoader ? (
							<Spinner animation="border" size={"sm"} role="status" />
						) : (
							"Submit Answer"
						)}
					</Button>
				</Form>

				<br style={{ clear: "both" }} />

				{formValue.tag === 'MealPlan' && <div>

					<hr style={{ margin: "1rem 0" }} />
					<div style={{fontWeight: 'bold'}}>Change mealplan request</div>
					<br style={{ clear: "both" }} />

					<div>
				        Current plan Type :{" "}
				        {userPlanType}
				      </div>
				      <div>
				        Change plan Type :{" "}
				        <ButtonGroup className="mr-2" aria-label="First group">
				          <DropdownButton
				            className="mr-2"
				            variant="dark"
				            size="sm"
				            id="dropdown-basic-button"
				            title="Select Plan type"
				            onSelect={(eventKey) => {
							setUserPlanType(eventKey);
							  if(!customPlanPressed && userSelectedChallenge && !userSelectedChallenge.mealPlanManuallyAssigned){
								setErrorMessageManualAssigned(false);
								setUserSelectedChallengeLoader(true);
								firestore("users")
										.doc(formValue.userId)
										.set(
											{
											healthInfo: {
												dietType: eventKey,
											},
											},
											{ merge: true }
										);
								firestore("feeds")
										.doc(props.questionData.key)
										.update({currentPlanType: eventKey, previousPlanType: userPlanType});
							  } else if(!customPlanPressed && userSelectedChallenge && userSelectedChallenge.mealPlanManuallyAssigned){
								setErrorMessageManualAssigned(true);
							  }
				            }}
				          >
				            {planTypes &&
				              planTypes.map((plan) => {
				                return (
				                  <Dropdown.Item key={plan} eventKey={plan}>
				                    {plan}
				                  </Dropdown.Item>
				                );
				              })}
				          </DropdownButton>
				        </ButtonGroup>
				      </div>
					<br style={{ clear: "both" }} />
					{customPlanPressed ? (<>
					<div>Current plan : (<>
					{userSelectedChallengeLoader ? <Spinner animation="border" size={"sm"} role="status" /> : (<>
					{userMealPlan && props.mealPlans.filter(d => d.key === userMealPlan).length ? props.mealPlans.filter(d => d.key === userMealPlan)[0].name : '-- no plan --'}
					</>)})
					</>
						</div>

					<div>Change to : <ButtonGroup className="mr-2" aria-label="First group">
							<DropdownButton
								disabled={userSelectedChallengeLoader}
								className="mr-2"
								variant="dark"
								size="sm"
								id="dropdown-basic-button"
								title="Select Meal plan"
								onSelect={(eventKey) => {
									onSelectMealPlan(formValue.userId, formValue.challengeId, eventKey);
								}}
							>
								{props.mealPlans &&
									props.mealPlans.map((mealPlan) => {
										return (
											<Dropdown.Item
												key={mealPlan.key}
												eventKey={mealPlan.key}
											>
												{mealPlan.name}
											</Dropdown.Item>
										);
									})}
							</DropdownButton>
						</ButtonGroup>
					</div></>): null}						
				</div>}

				{formValue.tag === 'WorkoutPlan' && <div>

					<hr style={{ margin: "1rem 0" }} />
					<div style={{fontWeight: 'bold'}}>Change workout plan request</div>
					<br style={{ clear: "both" }} />
					<div>
				        Current plan Type :{" "}
				        {userPlanType}
				      </div>
				      <div>
				        Change plan Type :{" "}
				        <ButtonGroup className="mr-2" aria-label="First group">
				          <DropdownButton
				            className="mr-2"
				            variant="dark"
				            size="sm"
				            id="dropdown-basic-button"
				            title="Select Plan type"
				            onSelect={(eventKey) => {
							setUserPlanType(eventKey);
							  if(!customPlanPressed && userSelectedChallenge && !userSelectedChallenge.workoutPlanManuallyAssigned){
								setErrorMessageManualAssigned(false);
								setUserSelectedChallengeLoader(true);
								firestore("users")
										.doc(formValue.userId)
										.set(
											{
											healthInfo: {
												exerciseType: eventKey,
											},
											},
											{ merge: true }
										);
								firestore("feeds")
										.doc(props.questionData.key)
										.update({currentPlanType: eventKey, previousPlanType: userPlanType});
							  } else if(!customPlanPressed && userSelectedChallenge && userSelectedChallenge.workoutPlanManuallyAssigned){
								setErrorMessageManualAssigned(true);
							  }
				            }}
				          >
				            {planTypes &&
				              planTypes.map((plan) => {
				                return (
				                  <Dropdown.Item key={plan} eventKey={plan}>
				                    {plan}
				                  </Dropdown.Item>
				                );
				              })}
				          </DropdownButton>
				        </ButtonGroup>
				      </div>

					<br style={{ clear: "both" }} />
					{customPlanPressed ? (<>
						<div>Current plan : (<>
					{userSelectedChallengeLoader ? <Spinner animation="border" size={"sm"} role="status" /> : (<>
					{userWorkoutPlan && props.workoutPlans.filter(d => d.key === userWorkoutPlan).length ? props.workoutPlans.filter(d => d.key === userWorkoutPlan)[0].name : '-- no plan --'}
					</>)})
					</>
						</div>
						<div>Change to : <ButtonGroup className="mr-2" aria-label="First group">
							<DropdownButton
								disabled={userSelectedChallengeLoader}
								className="mr-2"
								variant="dark"
								size="sm"
								id="dropdown-basic-button"
								title="Select Workout plan"
								onSelect={(eventKey) => {
									onSelectWorkoutPlan(formValue.userId, formValue.challengeId, eventKey);
								}}
							>
								{props.workoutPlans &&
									props.workoutPlans.map((workoutPlan) => {
										return (
											<Dropdown.Item
												key={workoutPlan.key}
												eventKey={workoutPlan.key}
											>
												{workoutPlan.name}
											</Dropdown.Item>
										);
									})}
							</DropdownButton>
						</ButtonGroup>
						</div></>) : null}							
				</div>}
				{(formValue.tag === 'WorkoutPlan'|| formValue.tag === 'MealPlan') && <div onClick={()=> setCustomPlanPressed(prv=> {return !prv})}>
					<p style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>{customPlanPressed ? 'customize less' : 'customize more'}</p>
				</div>	}
				{errorMessageManualAssigned && <div>
					<p style={{ color: 'red', fontStyle: 'italic'}}>* user has been assigned manually before please customize more </p>
				</div>}
				<hr style={{ margin: "1rem 0" }} />
				<Form
					className="form"
					onSubmit={(e) => {
						submitComment(e);
					}}
				>
					<div style={{ marginBottom: "0.5rem" }}>Comments</div>
					{formValue.comments && formValue.comments.length
						? formValue.comments.map((message, i) => (
								<Form.Group
									key={i}
									style={
										message.ans
											? {
													borderRadius: "1em",
													background: "rgb(214 241 226)",
													padding: "1em",
													marginLeft: "0em",
													marginRight: "0em",
													color: "#464646",
											  }
											: {
													borderRadius: "1em",
													background: "#eee",
													padding: "1em",
													marginLeft: "0em",
													marginRight: "0em",
											  }
									}
								>
									<div className="d-flex align-items-center">
										<img
											alt="user"
											src={fbImage(message.userImage) || ""}
											height="50"
											width="50"
											style={{
												borderRadius: "50%",
												marginRight: "10px",
												objectFit: "cover",
											}}
										/>
										<div className="d-flex flex-column">
											<span>{message.userName}</span>
											<span
												style={{
													fontSize: "0.9em",
													opacity: "0.9",
													float: "right",
												}}
											>
												{moment
													.utc(message.datetime)
													.fromNow()
													.replace("a few seconds ago", "now")}
											</span>
										</div>
									</div>

									<div dir="rtl" className="mt-2 ml-2">
										{beautify(message.comment)}
									</div>
									{message.image && <div style={{width: '100%', textAlign: 'center'}}><img src={message.image.uri} style={{maxWidth: '100%', margin: '10px auto 10px'}}/></div>}
								</Form.Group>
						  ))
						: null}
					{/* <br /> */}
					<div className="d-flex flex-column">
						<Form.Control
							as="textarea"
							rows="3"
							required
							placeholder={
								formValue.comments && formValue.comments.length
									? "comment"
									: "comment"
							}
							value={newMessage.message}
							onChange={(e) => {
								addMessage(e.target.value);
							}}
						/>

						<Button
							variant="dark"
							type="submit"
							className="mt-3 button"
							style={{
								minWidth: "170px",
								margin: "auto",
								fontWeight: "500",
								backgroundColor: "#0e5171",
							}}
						>
							{isSubmitCommentLoader ? (
								<Spinner animation="border" size={"sm"} role="status" />
							) : (
								<>
									<FontAwesomeIcon
										icon={faComment}
										size="1x"
										className="mr-2"
									/>
									Post Comment
								</>
							)}
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</>
	);
}

export default AddQuestion;
