/** @format */

import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import { Spinner, Col, Row, Breadcrumb, Form, Card, Modal } from "react-bootstrap";
import { firestore, storage } from "../../services/firebase";
import Masonry from "react-masonry-css";
import firebase from 'firebase/compat/app';
import moment from "moment";
import * as _ from "lodash";

import AddPost from '../../components/AddPost';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import like from "../../assets/images/dc-like-grey.png";
import likeActive from "../../assets/images/dc-like.png";
import { confirm } from "../../components/Confirmation";
import "./Feed.scss";
import { Header } from "../../components/Header";
import { ReactComponent as VideosIcon } from "../../assets/icons/feed.svg";



const getThumbnail = (file) => {
	return new Promise((resolve) => {
		const canvas = document.createElement("canvas");
		const video = document.createElement("video");

		video.autoplay = true;
		video.muted = true;
		video.src = URL.createObjectURL(file);

		video.onloadeddata = () => {
			let ctx = canvas.getContext("2d");
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;

			ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
			video.pause();
			return resolve(canvas.toDataURL("image/png"));
		};
	});
};


export function Feed(props) {
    const [show, setShow] = useState(false);
	const [loader, setLoader] = useState(true);
	const [moreLoader, setMoreLoader] = useState(false);
	const [imageFile, setImageFile] = useState();
	const [isReadMore, setIsReadMore] = useState(true);
	const [post, setPosted] = useState();
	const [firstVisible, setFirstVisible] = useState({});
	const [lastVisible, setLastVisible] = useState({});
	const [newPosts, setNewPosts] = useState([]);
	const [shake, setShake] = useState(false);
	const [ignore, setIgnore] = useState({});
	const [rounds, setRounds] = useState(false);
	const [postData, setPostData] = useState();


	const [groups, setGroups] = useState([]);
	const [videos, setVideos] = useState([]);
	const [isVideoChanged, setIsVideoChanged] = useState(false);
	const [videoFile, setVideoFile] = useState(false);
	const [videoBlob, setVideoBlob] = useState(false);
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [videoPosted, setVideoPosted] = useState(false);
	const [VideoError, setVideoError] = useState("");
	const [caption, setCaption] = useState("");
	const [thumbnailImage, setThumbnailImage] = useState();
	const [progress, setProgress] = useState(0);
	const [videoData, setVideoData] = useState({});
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);
	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);


	const [feed, setFeed] = useState([]);
	//const [challenges, setChallenges] = useState([]);
	//const [nutritionists, setNutritioinists] = useState([]);

	const [challenge, setChallenge] = useState();
	const [selectedChallenge, setSelectedChallenge] = useState("");
	const [challengesLoading, setChallengesLoading] = useState([]);
	const [challenges, setChallenges] = useState([]);

	const [scrollTop, setScrollTop] = useState(0);
	const [holdLoadMore, setHoldLoadMore] = useState(false);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};
	useEffect(() => {
		const onScroll = (e) => {
			setScrollTop(e.target.documentElement.scrollTop);

			if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
				// you're at the bottom of the page
				loadMore();
			}
		};

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, [selectedChallenge, moreLoader, lastVisible]);

	useEffect(() => {
		getChallenges();
	}, []);

	useEffect(() => {
		var unsubscribes = subscribeCollections();

		return () => {
			console.log("Do some cleanup");
			unsubscribes.map((unsubscribe) => unsubscribe());
		};
	}, [selectedChallenge]);

	useEffect(() => {
		setLastVisible({});
		setNewPosts([]);
		setFeed([]);

		getCollections();

		return () => {};
	}, [selectedChallenge]);

	function getChallenges() {
		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		setChallengesLoading(true);
		return firestore("challenges")
			.where("nutritionistId", "==", userDetails.id)
			.get()
			.then(
				(querySnapshot) => {
					let data = querySnapshot.docs.map((documentSnapshot, i) => {
						return {
							...documentSnapshot.data(),
							key: documentSnapshot.id,
							id: documentSnapshot.id,
						};
					});
					//_.orderBy(groups, 'userCount', 'desc')
					//data = _.orderBy(data, 'price', 'desc')
					data = _.orderBy(
						data,
						(item) => moment.utc(item.date).format(),
						"desc"
					);

					var grouped = [],
						ignore = {},
						firstRound = {};
					data.map((d) => {
						if (d.previousRounds) {
							d.rounds = d.previousRounds.length + 1;
							d.previousRounds.map((id, i) => {
								ignore[id] = true;

								if (i === d.previousRounds.length - 1) firstRound[id] = true;
							});
							d.roundsIds = [d.key].concat(d.previousRounds);
						}

						if (firstRound[d.key]) {
							d.rounds = 1;
							d.roundsIds = [d.key];
						}
					});

					setIgnore(ignore);

					setChallenges(data);
					setChallengesLoading(false);

					var storageChallenge = localStorage.getItem("challengeId");
					if (
						storageChallenge &&
						data.filter((c) => c.key === storageChallenge).length
					) {
						var d = data.filter((c) => c.key === storageChallenge)[0];
						var rounds = data.filter(
							(c) => c.roundsIds && c.roundsIds.includes(d.key)
						);
						setRounds(rounds.length ? rounds[0].roundsIds : false);
						setChallenge(d);
						setSelectedChallenge(d.key);
					} else if (data.length) {
						setRounds(data[0].roundsIds);
						setChallenge(data[0]);
						setSelectedChallenge(data[0].key);
						localStorage.setItem("challengeId", selectedChallenge);
					}
				},
				(error) => {
					console.log("error", error);
				}
			);
	}

	async function getCollections() {
		setLoader(true);

		const subscribeGroups = selectedChallenge
			? firestore("groups")
					.where("challengeId", "==", selectedChallenge)
					//.where('type', '==', "Nutritionist Video")
					//.orderBy('datetime', 'desc')
					//.limit(10)
					.onSnapshot({ includeMetadataChanges: true }, function (snapshot) {
						var groups = snapshot.docs.length
							? snapshot.docs.map((d) => {
									var obj = d.data();
									obj.id = d.id;
									return obj;
							  })
							: [];

						//var groupById = {}
						//groups.map((group)=>{groupById[group.id] = group})

						setGroups(groups);

						if (loader) setLoader(false);
					})
			: () => {};

		//const nutritionistsRef = await firestore("users").where('role', 'array-contains', "nutritionist").limit(10).get()
		//var nutritionists = _.orderBy(nutritionistsRef.docs.length? nutritionistsRef.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : [], 'datetime', 'desc')

		//const challengesRef = await firestore("challenges").orderBy('datetime', 'desc').limit(10).get()
		//var challenges = _.orderBy(challengesRef.docs.length? challengesRef.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : [], 'datetime', 'desc')

		const getFeeds = selectedChallenge
			? firestore("feeds")
					//challenges.where('userId', '==', JSON.parse(localStorage.getItem('userDetails')).id)
					.where("challengeId", "==", selectedChallenge)
					//.where('type', '==', "Nutritionist Video")
					.orderBy("datetime", "desc")
					.limit(100)
					//.onSnapshot({includeMetadataChanges: true}, function(snapshot){
					.get()
					.then(function (snapshot) {
						var _feed = _.orderBy(
							snapshot.docs.length
								? snapshot.docs.map((d) => {
										var obj = d.data();
										obj.id = d.id;
										return obj;
								  })
								: [],
							"datetime",
							"desc"
						).filter(d=>d.type !== 'question'); //.filter(d=>d.image&&d.userImage/*&&d.likes.length&&d.commentCount*/)

						_feed.map((post) => {
							post.postType = post.videoLink ? "video" : "post";
						});
						//challenges.map((challenge, i) => {challenge.postType = 'challenge'; feed.splice(Math.floor(Math.random()*(feed.length-1)), 0, challenge);})
						//nutritionists.map((nutritionist, i) => {nutritionist.postType = 'nutritionist'; feed.splice(Math.floor(Math.random()*(feed.length-1)), 0, nutritionist);})

						//setFeed(_.clone(feed));
						//setFeed(_.clone(feed).concat(_feed));
						//setFeed(feed => [...feed, ..._feed]);
						setFeed(_feed);

						setLoader(false);

						if (_feed.length) setFirstVisible(_feed[0]);
						if (_feed.length) setLastVisible(_feed[_feed.length - 1]);
					})
			: () => {};

		return [subscribeGroups, getFeeds];
	}

	function subscribeCollections() {
		var initial = true;
		const subscribeFeeds = selectedChallenge
			? firestore("feeds")
					.where("challengeId", "==", selectedChallenge)
					.orderBy("datetime", "desc")
					.limit(1)
					.onSnapshot(function (snapshot) {
						//.get().then(function(snapshot){

						snapshot.docChanges().forEach(function (change) {
							if (change.type === "added") {
								var post = change.doc.data();
								post.id = change.doc.id;
								post.postType = post.videoLink ? "video" : "post";

								if(post.type == 'question') return

								if (!initial) {
									var newImg = new Image();
									newImg.onload = function () {
										//setNewPosts([post].concat(_.clone(newPosts)))
										//setNewPosts(newPosts => [post, ...newPosts])
										setNewPosts((newPosts) => [post].concat(newPosts));
										setShake(true);
										setTimeout(function () {
											setShake(false);
										}, 3000);
									};
									if (post.image) newImg.src = post.image.url;
								}

								initial = false;
							}
						});
					})
			: () => {};

		return [subscribeFeeds];
	}

	async function showNew() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
		//setFeed(_.clone(newPosts).concat(_.clone(feed)));
		//setFeed(feed => [...newPosts, ...feed])
		setFeed((feed) => newPosts.concat(feed));
		setNewPosts([]);
	}

	async function loadMore() {
		if (moreLoader) return;

		setMoreLoader(true);

		const subscribeFeeds =
			selectedChallenge && Object.keys(lastVisible).length
				? firestore("feeds")
						//challenges.where('userId', '==', JSON.parse(localStorage.getItem('userDetails')).id)
						.where("challengeId", "==", selectedChallenge)
						//.where('type', '==', "Nutritionist Video")
						.orderBy("datetime", "desc")
						.startAfter(lastVisible)
						.limit(50)
						//.onSnapshot({includeMetadataChanges: true}, function(snapshot){
						.get()
						.then(function (snapshot) {
							var _feed = _.orderBy(
								snapshot.docs.length
									? snapshot.docs.map((d) => {
											var obj = d.data();
											obj.id = d.id;
											return obj;
									  })
									: [],
								"datetime",
								"desc"
							).filter(d=>d.type !== 'question'); //.filter(d=>d.image&&d.userImage/*&&d.likes.length&&d.commentCount*/)

							_feed.map((post) => {
								post.postType = post.videoLink ? "video" : "post";
							});
							//challenges.map((challenge, i) => {challenge.postType = 'challenge'; feed.splice(Math.floor(Math.random()*(feed.length-1)), 0, challenge);})
							//nutritionists.map((nutritionist, i) => {nutritionist.postType = 'nutritionist'; feed.splice(Math.floor(Math.random()*(feed.length-1)), 0, nutritionist);})

							//setFeed(_.clone(feed).concat(_feed));
							setFeed((feed) => feed.concat(_feed));

							if (_feed.length) setLastVisible(_feed[_feed.length - 1]);

							setMoreLoader(false);
						})
				: () => {};

		return [subscribeFeeds];
	}

	async function pinPost(post) {
		if (
			await confirm(
				"pin post",
				"are you sure you want to pin this post ?" +
					"\n\n" +
					(post.caption || ""),
				"Pin"
			)
		) {
			await firestore("feeds")
				.doc(post.id)
				.update({ datetime: moment().add(1, "days").toISOString() });

			alert("pinned for a day");
		}
	}

	async function showPost(post = '') {

        var snapshot = await firestore('feeds').doc(post.id).collection('comments').orderBy('datetime', 'asc').get()
        post.comments = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

        const userDetailsStorage = JSON.parse(localStorage.getItem('userDetails'));
        const adminDetailsStorage = JSON.parse(localStorage.getItem('adminDetails'));
        post.comments.map(d => {if(userDetailsStorage.id === d.userId || adminDetailsStorage.id === d.userId) d.ans = true; return d})

        post.key = post.id
        setPostData(post);
        setShow(true);
	}





	async function postVideo(challenge, groups, targetGroups) {
		setIsSubmitLoader(true);

		const fileName = user.id + "_" + challenge.id + "_" + Number(new Date());
		const uploadTask = storage()
			.ref()
			.child(`/nutritionists/feed_photos/${fileName}`)
			.put(videoFile, { cacheControl: "public,max-age=31536000" });

		uploadTask.on(
			"state_changed",

			function (snapshot) {
				var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log("Upload is " + progress + "% done");
				setProgress(progress);
			},
			function error(err) {
				console.log("error", err);
			},
			function complete() {

				uploadTask.snapshot.ref
					.getDownloadURL()
					.then(async function (downloadURL) {
						const imageLink = downloadURL;


						var postBy = "nutritionist"; //admin

						//if(user.id === admin.id) postBy = "nutritionist"
						//else if(user.collaborators && user.collaborators.includes(admin.id)) postBy = "collaborator"
						//else if(user.assistants && user.assistants.includes(admin.id)) postBy = "assistant"

						if (admin.id === challenge.nutritionistId) postBy = "nutritionist";
						else if (
							admin.collaborate &&
							admin.collaborate.includes(challenge.nutritionistId)
						)
							postBy = "collaborator";
						else if (
							admin.assist &&
							admin.assist.includes(challenge.nutritionistId)
						)
							postBy = "assistant";

						if (
							admin.role.includes("admin") &&
							user.collaborate &&
							user.collaborate.includes(challenge.nutritionistId)
						)
							postBy = "collaborator";

						var post = {
							challengeName: challenge.name,
							challengeId: challenge.id,
							userId:
								postBy === (!admin.role.includes("admin") && "collaborator")
									? admin.id
									: user.id,
							userName:
								postBy === (!admin.role.includes("admin") && "collaborator")
									? admin.name
									: user.name,
							userImage:
								postBy === (!admin.role.includes("admin") && "collaborator")
									? admin.image
									: user.image,
							type: "Nutritionist Image",
							image: {url: imageLink},
							fileType: "photo",
							groupIds: ["none"],
							created: firebase.firestore.FieldValue.serverTimestamp(),
							datetime: new Date().toISOString(),
							postBy: postBy,
						};

						Array.prototype.push.apply(
							post.groupIds,
							groups.map((d) => d.id)
						);

						//var targetGroups = [];
						//groups.map((group) => {if(targetGroups.includes(group.id)) {targetGroups.push(group.id)}})
						//post.groups = groups.filter((group) => )

						if (caption) post.caption = caption;

						console.log(post);

						firestore("feeds")
							.add(post)
							.then(function (doc) {
								setIsSubmitLoader(false);
								//setVideoPosted('video posted to '+ post.challengeName + ' feed')
								setVideoPosted("posted to challenge feed.");
								setTimeout(function () {
									setVideoPosted(false);
								}, 10000);

								return;
							})
							.catch(function (error) {
								console.error("Error finding/updating user document: ", error);
							});
					})
			}
		)
	}

	function onVideoUpload(e) {
		fileSelectHandler(e);
	}

	function fileDragHover(e) {
		var fileDrag = document.getElementById("file-drag");
		e.stopPropagation();
		e.preventDefault();
		fileDrag.className =
			e.type === "dragover" ? "hover" : "modal-body file-upload";
	}

	async function fileSelectHandler(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;

		//make sure it s only one file
		if (files.length > 1) files.length = 1;

		// Cancel event and hover styling
		fileDragHover(e);

		var file = files[0];

		var fn = function () {
			// Process File object
			var options = {
				maxSizeMB: 50,
				//maxWidthOrHeight: 400
			};
			try {
				setVideoFile(file);
				setVideoBlob(URL.createObjectURL(file));
				parseFile(file);
			} catch (error) {
				console.error("error fileSelectHandler", error);
				setVideoError("please upload a valid video file");
			}

			setIsVideoChanged(true);
		};

		//generate thumbnail
		//const thumbnailUrl = await getThumbnail(file);
		//setThumbnailImage(thumbnailUrl);
		setThumbnailImage(file.url);

		fn();
		
		/*new FileUploadThumbnail({
			maxWidth: 500,
			maxHeight: 500,
			file: file,
			onSuccess: function (src) {
				setThumbnailImage(src);

				fn();
			},
			onError: function (error) {
				console.error("error thumbnail", error);

				fn();
			},
		}).createThumbnail();*/

		//postVideo()
	}

	function parseFile(file) {
		output("<strong>" + encodeURI(file.name) + "</strong>");
	}

	function output(msg) {
		// Response
		var m = document.getElementById("messages");
		m.innerHTML = msg;
	}



	function fbImage(imageLink) {
		if (!imageLink) return imageLink;
		var query = imageLink.split("asid=")[1];
		if (!query) return imageLink;
		var fbId = query.split("&")[0];
		if (!fbId) return imageLink;

		return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
	}

	return (
		<>
			<Header header="Feed" />

			<hr />
			<div
				id="new-float"
				className={(newPosts.length && "new-posts") + (shake ? " shake" : "")}
				onClick={showNew}
			>
				{newPosts.length} new post
			</div>

			<div className="pb-3">
				{false /*challengesLoading*/ ? (
					<div>
						<Spinner animation="border" size={"lg"} role="status" />
					</div>
				) : challenges.length ? (
					<>
						<Row
							className="challenge-row"
							style={!rounds ? { justifyContent: "initial" } : {}}
						>
							<Col xs={6}>
								<h4>Challenge</h4>
								<Form.Control
									required
									as="select"
									placeholder="Select Challenge"
									style={{
										cursor: "pointer",
										display: "inline-block",
										marginLeft: " 0px",
										width: "100%",
										border: "1px solid rgb(154, 154, 154)",
										backgroundColor: "rgb(252, 252, 252)",
									}}
									value={ignore[selectedChallenge] ? challenges.filter(c => c.previousRounds && c.previousRounds.includes(selectedChallenge))[0].key : selectedChallenge}
									onChange={(e) => {
										challenges.map((c) => {
											if (c.key == e.target.value) {
												setRounds(c.roundsIds);
												setChallenge(c);
											}
										});
										setSelectedChallenge(e.target.value);
										localStorage.setItem("challengeId", e.target.value);
									}}
								>
									{challenges.filter(c => !ignore[c.key]).map((obj, i) => {
										return (
											<option
												value={obj.key}
												key={obj.key}
												//hidden={ignore[obj.key]}
											>
												{obj.name.replace(/Round(.*)- /, "")}
											</option>
										);
									})}
								</Form.Control>
							</Col>
							<Col xs={6}>
								{rounds && (
									<>
										<h4>Rounds</h4>
										<Form.Control
											required
											as="select"
											placeholder="Select Round"
											style={{
												cursor: "pointer",
												display: "inline-block",
												marginLeft: " 0px",
												width: "100%",
												border: "1px solid rgb(154, 154, 154)",
												backgroundColor: "rgb(252, 252, 252)",
											}}
											value={selectedChallenge}
											onChange={(e) => {
												challenges.map((c) => {
													if (c.key == e.target.value) setChallenge(c);
												});
												setSelectedChallenge(e.target.value);
												localStorage.setItem("challengeId", e.target.value);
											}}
										>
											{challenges
												.filter((d) => rounds.includes(d.key))
												.map((obj, i) => {
													return (
														<option value={obj.key} key={obj.key}>
															{obj.previousRounds
																? "round " + (obj.previousRounds.length + 1)
																: "round 1"}
														</option>
													);
												})}
										</Form.Control>
									</>
								)}
							</Col>
						</Row>
					</>
				) : (
					<div></div>
				)}
			</div>

			<div>
				{challengesLoading ? (
					<div className="text-center h-100 w-100">
						<Spinner animation="border" size={"lg"} role="status" />
					</div>
				) : challenges.length ? (
					<Card>
						<div id="Feed" className="">
							<div>
								{loader ? (
									<div className="loader text-center">
										<Spinner animation="border" size={"sm"} role="status" />
									</div>
								) : (
									<div
										className="my-masonry-grid"
										columnClassName="my-masonry-grid_column"
									>

										<div>
											<div className="uploader">
												<input
													id="file-upload"
													type="file"
													name="fileUpload"
													accept="image/*"
													onChange={(e) => onVideoUpload(e)}
												/>
												<label
													htmlFor="file-upload"
													id="file-drag"
													onDragOver={(e) => {
														fileDragHover(e);
													}}
													onDragLeave={(e) => {
														fileDragHover(e);
													}}
													onDrop={(e) => {
														fileSelectHandler(e);
													}}
												>
													{videoBlob ? (
														<img
															className="video-file-container"
															id="file-video"
															src={videoBlob}
															alt="video preview"
														/>
													) : (
														<div className="video-file-container-empty">
															<VideosIcon height="50px" />
														</div>
													)}
													<div id="start">
														<div>Select an image or drag here</div>
													</div>
													<div id="response" className="hidden">
														<div id="messages"></div>
													</div>
												</label>
												{videoPosted ? <b>{videoPosted}</b> : ""}

												{
													videoFile ? (
														isSubmitLoader ? (
															<div style={{ width: "100%" }}>
																<Spinner
																	animation="border"
																	size={"sm"}
																	role="status"
																/>
																<div
																	style={{
																		display: "block",
																		width: "90%",
																		margin: "0 auto",
																		height: "18px",
																	}}
																>
																	<div
																		style={{
																			width: progress + "%",
																			height: "18px",
																			borderRadius: "4px",
																			fontSize: "12px",
																			color: "#464646",
																			background: "rgb(0, 227, 150)",
																		}}
																	>
																		{progress.toFixed(0)}
																		<span style={{ opacity: 0.5 }}>%</span>
																	</div>
																</div>
															</div>
														) : (
															<>
																<Form.Control
																	as="textarea"
																	rows="2"
																	id="caption"
																	onInput={(e) => setCaption(e.target.value)}
																	placeholder="enter caption"
																	style={{
																		borderColor: "#ddd",
																		width: "80%",
																		margin: "0 auto",
																		marginBottom: "1em",
																	}}
																/>
																<span
																	id="file-upload-btn"
																	style={{ padding: "8px 25px" }}
																	className=" submit-button button button-primary "
																	onClick={(e) => {
																		postVideo(challenge, groups, []);
																	}}
																>
																	post image
																</span>
															</>
														)
													) : (
														<span style={{ opacity: 0, pointerEvents: "none" }}>
															upload image
														</span>
													)
													//<span id="file-upload-btn" className="btn btn-primary" style={{background: '#aaa', cursor: 'default'}}>post video</span>
													//<FontAwesomeIcon icon={faPlus} size="4x" className="mr-2" style={{color: '#aaa', cursor: 'pointer'}}/>
												}
											</div>
										</div>

										{feed.map((post, i) => (
											<div key={post.id}>
												{
													{
														post: (
															<div
																style={{
																	display: "flex",
																	flexDirection: "column",
																	justifyContent: "space-between",
																	cursor: 'pointer'
																}}
																className={post.postType}
																/*onDoubleClick={() => {
																	pinPost(post);
																}}*/
																onClick={() => {
																	showPost(post);
																}}
															>
																<div>
																	{/* {console.log(post)} */}
																	{post.image && (
																		<div className="main-image">
																			<img
																				alt="post"
																				src={
																					post?.image ? post.image.url : null
																				}
																			/>
																			<span className="type">{post.type}</span>
																		</div>
																	)}
																	{post.caption && (
																		<div className="caption">
																			{post.caption?.length > 260 ? (
																				<div>
																					{isReadMore
																						? post.caption?.slice(0, 260)
																						: post.caption}
																					<span
																						style={{
																							color: "#0e5171",
																							fontWeight: "600",
																							textDecoration: "underline",
																							cursor: "pointer",
																							marginLeft: "5px",
																						}}
																						onClick={toggleReadMore}
																						className="read-or-hide"
																					>
																						{isReadMore
																							? "...read more"
																							: " show less"}
																					</span>
																				</div>
																			) : (
																				post.caption
																			)}
																		</div>
																	)}
																</div>
																<div className="user">
																	<div
																		className="user-info d-flex align-items-center"
																		style={{ whiteSpace: 'nowrap'}}
																	>
																		<span className="userImage">
																			<img
																				alt=""
																				width="40"
																				src={fbImage(post.userImage)}
																			/>
																		</span>
																		<span className="userName">
																			{post.userName}
																		</span>
																		<div style={{ marginLeft: "auto" }}>
																			<span className="likes">
																				<span className="count">
																					<FontAwesomeIcon
																						icon={faThumbsUp}
																						size="lg"
																						className="mr-1"
																					/>
																					{post.likes ? post.likes.length : 0}
																				</span>
																			</span>
																			<span className="comments">
																				<span className="count">
																					<FontAwesomeIcon
																						icon={faComment}
																						size="lg"
																						className="mr-1"
																					/>
																					{post.commentCount}
																				</span>
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														),

														video: (
															<div
																className={
																	"post d-flex flex-column justify-content-between"
																}
																onDoubleClick={() => {
																	pinPost(post);
																}}
															>
																<div>
																	<div className="main-image">
																		<img
																			className="video-file-container"
																			id="file-video"
																			src={post.videoLink}
																			alt="video preview"
																		/>
																		<span className="type">{post.type}</span>
																	</div>
																	{post.caption && (
																		<div className="caption">
																			{post.caption.length > 260 ? (
																				<div>
																					{isReadMore
																						? post.caption.slice(0, 260)
																						: post.caption}
																					<span
																						style={{
																							color: "#0e5171",
																							fontWeight: "600",
																							textDecoration: "underline",
																							cursor: "pointer",
																							marginLeft: "5px",
																						}}
																						onClick={toggleReadMore}
																						className="read-or-hide"
																					>
																						{isReadMore
																							? "...read more"
																							: " show less"}
																					</span>
																				</div>
																			) : (
																				post.caption
																			)}
																		</div>
																	)}
																</div>
																<div
																	className="user d-flex align-items-center"
																	style={{ whiteSpace: 'nowrap'}}
																>
																	<span className="userImage">
																		<img
																			alt=""
																			width="40"
																			src={fbImage(post.userImage)}
																		/>
																	</span>
																	<span className="userName">
																		{post.userName}
																	</span>

																	<div style={{ marginLeft: "auto" }}>
																		<span className="likes">
																			<span className="count">
																				<FontAwesomeIcon
																					icon={faThumbsUp}
																					size="lg"
																					className="mr-1"
																				/>
																				{post.likes ? post.likes.length : 0}
																			</span>
																		</span>
																		<span className="comments">
																			<span className="count">
																				<FontAwesomeIcon
																					icon={faComment}
																					size="lg"
																					className="mr-1"
																				/>
																				{post.commentCount}
																			</span>
																		</span>
																	</div>
																</div>
															</div>
														),

														challenge: (
															<div className={post.postType}>
																<div className="main-image">
																	<img alt="post" src={post.image} />
																</div>
																<div className="caption">
																	{post.name} (
																	{post.duration +
																		" " +
																		(post.durationType
																			? post.durationType.toLowerCase()
																			: "")}
																	)
																	<div className="author">
																		<b>by</b>{" "}
																		<span className="userName">
																			{post.nutritionistName}
																		</span>
																	</div>
																</div>
																<div className="date">
																	{moment(post.date).isAfter()
																		? "Starts " +
																		  moment(post.date)
																				.fromNow()
																				.replace("a few seconds ago", "now")
																		: "Started " +
																		  moment(post.date)
																				.fromNow()
																				.replace("a few seconds ago", "now")}
																</div>
																<div>
																	<button>Join Program</button>
																</div>
															</div>
														),

														nutritionist: (
															<div className={post.postType}>
																<div className="main-image">
																	<img alt="post" src={post.image} />
																</div>
																<div className="user">
																	<span className="userName">{post.name}</span>
																</div>
																<div>
																	<button>Follow Nutritionist</button>
																</div>
															</div>
														),
													}[post.postType]
												}
											</div>
										))}
									</div>
								) /*: (
									<div className="no-Feed text-center">no posts</div>
								)*/}
							</div>
							{moreLoader && (
								<div className="loader text-center">
									<Spinner animation="border" size={"sm"} role="status" />
								</div>
							)}
						</div>
					</Card>
				) : (
					<div className="text-center h-100 w-100">
						Please add at least one challenge
					</div>
				)}
			</div>


                <Modal show={show} onHide={() => setShow(false)}>
                    <AddPost
                        handleClose={() => setShow(false)}
                        questionData={postData}
                    />
                </Modal>
		</>
	);
}
