/** @format */

import React, { useState, useEffect, useRef } from "react";
import {
	Button,
	Card,
	Col,
	Form,
	Modal,
	Row,
	Spinner,
	Table,
} from "react-bootstrap";
import Filter from "../../assets/icons/filter.svg";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faPlus,
	faPencilAlt,
	faArrowUp,
	faArrowDown,
	faDownload
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarRatings from "react-star-ratings";

import AddReview from "../../components/AddReview";
import { firestore } from "../../services/firebase";
import AddWorkoutPlan from "../../components/AddWorkoutPlan";
import * as _ from "lodash";
import ReviewFilter from "../../components/ReviewFilter";
import DeleteAlert from "../../components/DeleteAlert";
import { Header } from "../../components/Header";

library.add(faPlus);

export function Reviews(props) {
	const [show, setShow] = useState(false);
	const [reviewLoader, setReviewLoader] = useState(false);
	const [challengesLoader, setChallengesLoader] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [loadingCsv, setLoadingCsv] = useState(false);
	const [loadingAllCsv, setLoadingAllCsv] = useState(false);
	const [csvData, setCsvData] = useState([]);
	const [csvFileName, setCsvFileName] = useState();

	const csvRef = useRef()


	const [docId, setDocId] = useState("");
	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);

	const [challengeIdToName, setChallengeIdToName] = useState({});

	const [sort, setSort] = useState({
		field: "datetime",
		direction: "desc",
	});
	const tableHeaders = [
		// {
		// 	name: "Challenge",
		// 	filterField: "challengeName",
		// },
		{
			name: "User",
			filterField: "userName",
		},
		{
			name: "before", //before image
			filterField: "none",
		},
		{
			name: "after", //after image
			filterField: "none",
		},
		{
			name: "Rating",
			filterField: "rating",
		},
		{
			name: "Caption",
			filterField: "caption",
		},
		{
			name: "Actions",
			filterField: "none",
		},
	];
	const [reviews, setReviews] = useState([]);
	const [ratings, setRatings] = useState({});
	const [reviewData, setReviewData] = useState({});
	const [selectedRecords, setSelectedRecords] = useState([]);
	const [filterData, setFilterData] = useState({});
	const [challenges, setChallenges] = useState([]);
	const [challengeData, setWorkoutPlanData] = useState({});

	const [showWorkoutPlan, setShowWorkoutPlan] = useState(false);
	const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
	const [ignore, setIgnore] = useState({});
	const [rounds, setRounds] = useState(false);
	const [selectedChallenge, setSelectedChallenge] = useState("");
	const [challenge, setChallenge] = useState();


	useEffect(() => {
		getChallenges();
		return () => {};
	}, []);

	useEffect(() => {
		if(!csvData.length) return
		csvRef.current.link.click();
		return () => {};
	}, [csvData]);


	

	useEffect(() => {
		const reviewsSubscription = getReviews();
		return () => {
			reviewsSubscription();
		};
	}, [selectedChallenge]);

	function getReviews() {
		setReviewLoader(true);
		challenges.map((d) => {
			if (d.id === selectedChallenge) setChallenge(d);
		});
		const userDetailsStorage = JSON.parse(localStorage.getItem("userDetails"));
		let reviewsRef = firestore("reviews").where(
			"challengeId",
			"==",
			selectedChallenge
		);
		// if (filterData.day) {
		// 	reviewsRef = reviewsRef.where("day", "==", Number(filterData.day));
		// }
		// if (filterData.challengeId) {
		// 	reviewsRef = reviewsRef.where(
		// 		"challengeId",
		// 		"==",
		// 		filterData.challengeId
		// 	);
		// }
		// if (filterData.duration) {
		// 	reviewsRef = reviewsRef.where(
		// 		"duration",
		// 		"==",
		// 		Number(filterData.duration)
		// 	);
		// }
		// if (filterData.sets) {
		// 	reviewsRef = reviewsRef.where("sets", "==", Number(filterData.sets));
		// }
		// if (filterData.reps) {
		// 	reviewsRef = reviewsRef.where("reps", "==", Number(filterData.reps));
		// }
		return reviewsRef.onSnapshot(
			async (querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				sortData({}, data, true);

				setReviewLoader(false);
			},
			(error) => {
				console.error("error : ", error);
			}
		);
	}

	async function getChallenges() {
		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		if (!userDetails || !userDetails.id) {
			setChallenges([]);
			setChallengesLoader(false);
			return;
		}
		setChallengesLoader(true);

		var oneToOneDoc = await firestore("one_to_one").doc(userDetails.id).get();

		var oneToOne

		if(oneToOneDoc.exists){

			oneToOne = oneToOneDoc.data()
			oneToOne.key = oneToOneDoc.id;
			oneToOne.name = 'one to one';
		}


		firestore("challenges").where("nutritionistId", "==", userDetails.id).get().then((querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot, i) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				data = _.orderBy(
					data,
					(item) => moment.utc(item.date).format(),
					"desc"
				);

				var grouped = [],
					ignore = {},
					firstRound = {};
				data.map((d) => {
					if (d.previousRounds) {
						d.rounds = d.previousRounds.length + 1;
						d.previousRounds.map((id, i) => {
							ignore[id] = true;

							if (i === d.previousRounds.length - 1) firstRound[id] = true;
						});
						d.roundsIds = [d.key].concat(d.previousRounds);
					}

					if (firstRound[d.key]) {
						d.rounds = 1;
						d.roundsIds = [d.key];
					}
				});

				setIgnore(ignore);

				if(oneToOne) data.push(oneToOne)

				setChallenges(data);
				setChallengesLoader(false);

				var storageChallenge = localStorage.getItem("challengeId");
				if (
					storageChallenge &&
					data.filter((c) => c.key === storageChallenge).length
				) {
					var d = data.filter((c) => c.key === storageChallenge)[0];
					var rounds = data.filter(
						(c) => c.roundsIds && c.roundsIds.includes(d.key)
					);
					setRounds(rounds.length ? rounds[0].roundsIds : false);
					setSelectedChallenge(d.key);
				} else if (data.length) {
					setRounds(data[0].roundsIds);
					setSelectedChallenge(data[0].key);
					localStorage.setItem("challengeId", selectedChallenge);
				}
			},
			(error) => {
				console.log("error", error);
			}
		);
		return 
	}

	const onSelect = (key, isSelect) => {
		const selectedRows = [...selectedRecords];
		if (isSelect) {
			selectedRows.push(key);
		} else {
			const index = selectedRows.findIndex((o) => o === key);
			if (index > -1) {
				selectedRows.splice(index, 1);
			}
		}
		setSelectedRecords(selectedRows);
	};

	async function onDeleteClick(key) {
		setDocId(key);
		setShowDeleteModal(true);
	}

	function editReview(review = "") {
		setReviewData(review);
		setShow(true);
	}

	function cloneReview(review = {}) {
		delete review.key;
		setReviewData(review);
		setShow(true);
	}

	function selectAllCheckbox(e) {
		setIsSelectAllSelected(e.target.checked);
		const selectedRows = [];
		if (e.target.checked) {
			reviews.forEach((o) => {
				selectedRows.push(o.key);
			});
		}
		setSelectedRecords(selectedRows);
	}

	function checkIsSelected(key) {
		if (selectedRecords.length) {
			const index = selectedRecords.findIndex((o) => o === key);
			return index > -1;
		}
		return false;
	}

	function removeFilter(key) {
		const filter = _.clone(filterData);
		delete filter[key];
		setFilterData(filter);
	}

	function displayFilter(filterKey) {
		if (filterKey === "challengeId") {
			const index = challenges.findIndex(
				(o) => o.key === filterData[filterKey]
			);
			if (index > -1) {
				return challenges[index].name;
			}
		}
		return filterData[filterKey];
	}

	function sortData(filterField = "", data = [], isInitSort = false) {
		if (filterField !== "none") {
			let sortClone = _.clone(sort);
			if (filterField && !isInitSort) {
				if (sortClone.field === filterField) {
					sortClone.direction = sortClone.direction === "asc" ? "desc" : "asc";
				} else {
					sortClone.field = filterField;
					sortClone.direction = "asc";
				}
				setSort(sortClone);
			}
			let usersClone = isInitSort ? data : reviews;
			usersClone = _.orderBy(
				_.clone(usersClone),
				sortClone.field,
				sortClone.direction
			);
			setReviews(usersClone);

			var ratings = {}
			usersClone.map(r => {
				if(!ratings[r.rating]) ratings[r.rating] = 0
				ratings[r.rating] ++
			})
			setRatings(ratings)
		}
	}

	var calculateAge = function (birthday) {
		// birthday is a date
		var ageDifMs = Date.now() - new Date(birthday).getTime();
		var ageDate = new Date(ageDifMs); // miliseconds from epoch
		return Math.abs(ageDate.getUTCFullYear() - 1970);
	};

	async function constructAllCsv(event, done){

		setLoadingAllCsv(true)

		var challengesDocs = await firestore("challenges").get()
		var oneToOneDocs = await firestore("one_to_one").get()

		var challenges = {}

		challengesDocs.forEach(doc => {

			challenges[doc.id] = doc.data()
		})
		oneToOneDocs.forEach(doc => {

			challenges[doc.id] = doc.data()
		})

		var usersDocsChallenge = await firestore("users").orderBy('joinedChallengeIds').get()
		var usersDocsOnetoone = await firestore("users").orderBy('joinedOneToOneIds').get()
		var users = {}

		usersDocsChallenge.forEach(doc => {

			users[doc.id] = doc.data()
		})
		usersDocsOnetoone.forEach(doc => {

			users[doc.id] = doc.data()
		})

		var reviewsDocs = await firestore("reviews").get()
		var reviews = []


		var csvData = [['Nutritionist name', 'Challenge name', 'Challenge starting date', 'Review date', 'Review', 'Review text', 'Review before image', 'Review after image', 'User name', 'Age', 'Gender', 'Weight', 'Target weight', 'Country', 'Signup date']]

		reviewsDocs.forEach(rev => {

			var review = rev.data()
			var user = users[review.userId]
			var challenge = challenges[review.challengeId]

			if(!review || !user || !challenge) return

			csvData.push([challenge.nutritionistName, challenge.name, challenge.date, review.datetime, review.rating, review.caption, review.beforeImage || '', review.afterImage || '', review.userName, calculateAge(user.birthday), user.gender, user.weight, user.targetWeight, user.country, user.datetime])
		})

		setCsvFileName('reviews')
		setCsvData(csvData)
		setLoadingAllCsv(false)
	}

	async function constructCsv(){

		if(!reviews.length) {alert('no reviews for this program'); return false}

		setLoadingCsv(true)

		var challengesDocs = await firestore("challenges").doc(reviews[0].challengeId).get()
		var oneToOneDocs = await firestore("one_to_one").doc(reviews[0].challengeId).get()

		var challenges = {}

		if(challengesDocs.exists) challenges[challengesDocs.id] = challengesDocs.data()
		if(oneToOneDocs.exists) challenges[oneToOneDocs.id] = oneToOneDocs.data()


		var usersDocsChallenge = await firestore("users").where('joinedChallengeIds', 'array-contains', reviews[0].challengeId).get()
		var usersDocsOnetoone = await firestore("users").where('joinedOneToOneIds', 'array-contains', reviews[0].challengeId).get()
		var users = {}

		usersDocsChallenge.forEach(doc => {

			users[doc.id] = doc.data()
		})
		usersDocsOnetoone.forEach(doc => {

			users[doc.id] = doc.data()
		})


		var csvData = [['Nutritionist name', 'Challenge name', 'Challenge starting date', 'Review date', 'Review', 'Review text', 'Review before image', 'Review after image', 'User name', 'Age', 'Gender', 'Weight', 'Target weight', 'Country', 'Signup date']]

		reviews.map(review => {

			var user = users[review.userId]
			var challenge = challenges[review.challengeId]

			if(!review || !user || !challenge) return

			csvData.push([challenge.nutritionistName, challenge.name, challenge.date, review.datetime, review.rating, review.caption, review.beforeImage || '', review.afterImage || '', review.userName, calculateAge(user.birthday), user.gender, user.weight, user.targetWeight, user.country, user.datetime])
		})

		setCsvFileName(reviews[0].challengeName+'-reviews')
		setCsvData(csvData)
		setLoadingCsv(false)
	}

	return (
		<>

			<CSVLink 
				style={{position: 'absolute', top: '15px', right: '55px', cursor: 'pointer'}}
				hidden
				data={csvData}
				filename={csvFileName+".csv"}
				ref={csvRef}
		      >
		      {loadingAllCsv ? 'Constructing...' : <span><FontAwesomeIcon icon={faDownload} className="mr-1"/>all reviews</span>}
		    </CSVLink>


			<Header header="Reviews" />
					{user && admin.role && admin.role.includes("admin") &&  <Button
						style={{position: 'absolute', top: '15px', right: '55px', cursor: 'pointer', color: '#f76e0e'}}
						size="sm"
						variant="light"
						onClick={() => constructAllCsv()}
					>
						{loadingAllCsv ? 'Constructing...' : <span><FontAwesomeIcon icon={faDownload} className="mr-1"/>all reviews</span>}
					</Button>}

			<hr />
			<Row>
				{challengesLoader ? (
					<div style={{margin: '0 auto', padding: '20px'}}>
						<Spinner animation="border" size={"lg"} role="status" />
					</div>
				) : challenges.length ? (
					<>
						<Col xs={6}>
							<h4>Challenge</h4>

							<Form.Control
								required
								as="select"
								placeholder="Select Challenge"
								style={{
									cursor: "pointer",
									height: "auto",
									display: "inline-block",
									marginLeft: "0",
									width: "100%",
									border: "1px solid #9a9a9a",
									backgroundColor: "#fcfcfc",
								}}
								value={ignore[selectedChallenge] ? challenges.filter(c => c.previousRounds && c.previousRounds.includes(selectedChallenge))[0].key : selectedChallenge}
								onChange={(e) => {
									challenges.map((c) => {
										if (c.key === e.target.value) {
											setRounds(c.roundsIds);
											setChallenge(c);
										}
									});
									setSelectedChallenge(e.target.value);
									localStorage.setItem("challengeId", e.target.value);
								}}
							>
								{challenges.filter(c => !ignore[c.key]).map((obj, i) => {
									return (
										<>
											<option
												value={obj.key}
												key={obj.key}
												//hidden={ignore[obj.key]}
											>
												{obj.name.replace(/Round(.*)- /, "")}
											</option>
										</>
									);
								})}
							</Form.Control>
						</Col>
						{rounds && selectedChallenge && (
							<Col xs={6}>
								<h4>Round</h4>
								<Form.Control
									required
									as="select"
									placeholder="Select Round"
									style={{
										cursor: "pointer",
										height: "auto",
										display: "inline-block",
										marginLeft: "0",
										width: "100%",
										border: "1px solid #9a9a9a",
										backgroundColor: "#fcfcfc",
									}}
									value={selectedChallenge}
									onChange={(e) => {
										challenges.map((c) => {
											if (c.key == e.target.value) setChallenge(c);
										});
										setSelectedChallenge(e.target.value);
										localStorage.setItem("challengeId", e.target.value);
									}}
								>
									{selectedChallenge ? (
										challenges
											.filter((d) => rounds.includes(d.key))
											.map((obj, i) => {
												return (
													<option value={obj.key} key={obj.key}>
														{obj.previousRounds
															? "round " + (obj.previousRounds.length + 1)
															: "round 1"}
													</option>
												);
											})
									) : (
										<option value={""} key={""}>
											All
										</option>
									)}
								</Form.Control>
							</Col>
						)}
					</>
				) : (
					""
				)}
			</Row>
			<div className="pt-2 reviews-page">
				<div className="my-3 d-flex justify-content-between">
					<h4>
						Reviews ({reviews.length}) 
						<span style={{display: 'inline-block', marginLeft: '5em', fontSize: '.7em', color: '#777'}}>({ratings[1] || 0}) <span style={{display: 'inline-block', marginLeft: '.2em', fontSize: '.9em', color: '#bbb'}}>1 star</span></span>
						<span style={{display: 'inline-block', marginLeft: '1em', fontSize: '.7em', color: '#777'}}>({ratings[2] || 0}) <span style={{display: 'inline-block', marginLeft: '.2em', fontSize: '.9em', color: '#bbb'}}>2 stars</span></span>
						<span style={{display: 'inline-block', marginLeft: '1em', fontSize: '.7em', color: '#777'}}>({ratings[3] || 0}) <span style={{display: 'inline-block', marginLeft: '.2em', fontSize: '.9em', color: '#bbb'}}>3 stars</span></span>
						<span style={{display: 'inline-block', marginLeft: '1em', fontSize: '.7em', color: '#777'}}>({ratings[4] || 0}) <span style={{display: 'inline-block', marginLeft: '.2em', fontSize: '.9em', color: '#bbb'}}>4 stars</span></span>
						<span style={{display: 'inline-block', marginLeft: '1em', fontSize: '.7em', color: '#777'}}>({ratings[5] || 0}) <span style={{display: 'inline-block', marginLeft: '.2em', fontSize: '.9em', color: '#bbb'}}>5 stars</span></span>
					</h4>

					<Button
						style={{cursor: 'pointer', color: '#f76e0e'}}
						size="sm"
						variant="light"
						onClick={() => constructCsv()}
					>
						{loadingCsv ? 'Constructing...' : <span><FontAwesomeIcon icon={faDownload} className="mr-1"/>challenge reviews</span>}
					</Button>

					{user && admin.role && admin.role.includes("admin") && (
						<Button
							size="sm"
							variant="primary"
							className="button button-secondary"
							onClick={() => editReview()}
						>
							<FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />
							Add Review
						</Button>
					)}
				</div>

				<Card className="my-3">
					{/* <Form.Group className="d-flex align-items-baseline">
						<h4>
							<img
								src={Filter}
								alt="filter"
								width="15"
								style={{ margin: "0.5em" }}
							/>{" "}
							<Form.Label>Filter by</Form.Label>
						</h4>

						<Form.Control
							className="w-50"
							as="select"
							placeholder="Select challenge"
							value={filterData.challengeId}
							onChange={(e) => {
								const filterDataClone = _.clone(filterData);
								filterDataClone.challengeId = e.target.value;
								console.log(filterDataClone);
								setFilterData(filterDataClone);
							}}
						>
							<option value={""} key={""}>
								All challenges
							</option>
							{challenges.map((challenge, i) => {
								return (
									<option value={challenge.key} key={challenge.key}>
										{challenge.name}
									</option>
								);
							})}
						</Form.Control>
					</Form.Group> */}

					<Card.Body className="p-0">
						<div style={{ overflow: "auto" }}>
							<Table className="m-0" responsive="xl">
								<thead>
									<tr>
										<th
											style={{
												borderTopStyle: "none",
											}}
										>
											<Form.Check
												size="sm"
												checked={isSelectAllSelected}
												onChange={(e) => selectAllCheckbox(e)}
											/>
										</th>
										{tableHeaders &&
											tableHeaders
												.filter((d) =>
													user && admin.role && admin.role.includes("admin")
														? true
														: d.name !== "Actions"
												)
												.map((o, i) => {
													return (
														<th
															style={{
																borderTopStyle: "none",
															}}
															key={i}
															onClick={() => sortData(o.filterField)}
															className="text-capitalize"
														>
															{o.name}{" "}
															{sort && sort.field === o.filterField ? (
																<span>
																	<FontAwesomeIcon
																		icon={
																			sort.direction === "asc"
																				? faArrowUp
																				: faArrowDown
																		}
																		className="ml-1"
																	/>
																</span>
															) : (
																""
															)}
														</th>
													);
												})}
									</tr>
								</thead>
								<tbody>
									{reviewLoader ? (
										<tr>
											<td
												className="text-center"
												colSpan={tableHeaders.length + 1}
											>
												<Spinner animation="border" size={"sm"} role="status" />
											</td>
										</tr>
									) : reviews.length ? (
										reviews.map((review, i) => (
											<tr key={review.key}>
												<td style={{ width: "20px" }}>
													<Form.Group className="m-0" controlId={i + 1}>
														<Form.Check
															type="checkbox"
															size="sm"
															checked={checkIsSelected(review.key)}
															onChange={(e) =>
																onSelect(review.key, e.target.checked)
															}
														/>
													</Form.Group>
												</td>
												<td
													onClick={
														user && admin.role && admin.role.includes("admin")
															? () => {
																	window.open(
																		"https://dashboard.welnes.app/support/" +
																			review.userId
																	);
															  }
															: () => {}
													}
												>
													<span style={{ fontWeight: "700" }}>
														{review.userName}
													</span>
													<span
														style={{
															display: "block",
															color: "#909090",
															fontSize: "0.65rem",
														}}
													>
														{review.challengeName}
														{/* {review.challengeId === user.id
															? user.name
															: challengeIdToName[review.challengeId]} */}
													</span>
												</td>
												<td className="text-capitalize">
													{review.beforeImage && (
														<img
															alt="before"
															style={{ maxWidth: 60, maxHeight: 60 }}
															src={review.beforeImage}
														/>
													)}
												</td>
												<td className="text-capitalize">
													{review.afterImage && (
														<img
															alt="after"
															style={{ maxWidth: 60, maxHeight: 60 }}
															src={review.afterImage}
														/>
													)}
												</td>
												<td>
													<StarRatings
														rating={review.rating}
														starDimension="20px"
														starSpacing="2px"
														starRatedColor="#ffb400"
														numberOfStars={5}
														name="rating"
													/>
												</td>
												<td style={{ whiteSpace: "inherit" }}>
													{review.caption}
												</td>
												{user && admin.role && admin.role.includes("admin") && (
													<td style={{ maxWidth: "100px" }}>
														<div
															className="challenge-controls"
															style={{
																position: "inherit",

																justifyContent: "start",
															}}
														>
															<div
																size="sm"
																className="add-btn"
																onClick={() => {
																	editReview(review);
																}}
															>
																<div className="circle">
																	<FontAwesomeIcon
																		icon={faPencilAlt}
																		className="add"
																	/>
																</div>
															</div>

															<div
																size="sm"
																className="remove-btn"
																onClick={() => {
																	onDeleteClick(review.key);
																}}
															>
																<div className="circle">
																	<FontAwesomeIcon
																		icon={faTrashAlt}
																		className="remove"
																	/>
																</div>
															</div>
														</div>
													</td>
												)}
											</tr>
										))
									) : (
										<tr>
											<td
												className="text-center"
												colSpan={tableHeaders.length + 1}
											>
												No data found
											</td>
										</tr>
									)}
								</tbody>
							</Table>
						</div>
					</Card.Body>
				</Card>
				<Modal
					animation={false}
					size="lg"
					show={show}
					onHide={() => setShow(false)}
				>
					<AddReview
						handleClose={() => setShow(false)}
						reviewData={reviewData}
						challenges={challenges}
					/>
				</Modal>
				<Modal show={showWorkoutPlan} onHide={() => setShowWorkoutPlan(false)}>
					<AddWorkoutPlan
						handleClose={() => setShowWorkoutPlan(false)}
						challengeData={challengeData}
						challenges={challenges}
					/>
				</Modal>
				<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
					<DeleteAlert
						onHide={() => setShowDeleteModal(false)}
						title={"Delete Review"}
						message={"Are you sure you want to delete review?"}
						docId={docId}
						collectionName={"reviews"}
					/>
				</Modal>
			</div>
		</>
	);
}
