import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import * as _ from "lodash";

export function ExerciseFilter(props) {
    const totalDays = 30;
    const defaultFilter = {
        workoutPlanId: '',
        workoutPlanName: '',
        day: '',
        duration: '',
        sets: '',
        reps: ''
    };
    const [filterData, setFilterData] = useState({});
    useEffect(() => {
        if (props.filterData) {
            setFilterData(props.filterData)
        } else {
            setFilterData(defaultFilter)
        }
    }, []);
    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();
        props.handleClose({ filterData });
    }
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Exercise Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="form" onSubmit={(e) => {
                    submitForm(e)
                }}>
                    {/*<Form.Group>
                        <Form.Label>Workout Plan</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" placeholder="Select a workout plan"
                                value={filterData.workoutPlanId} onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.workoutPlanId = e.target.value;
                                    // const index = props.workoutPlans.findIndex((o) => {
                                    //     return o.key === filterData.workoutPlanId;
                                    // });
                                    // if (index > -1) {
                                    //     filterDataClone.workoutPlanName = props.workoutPlans[index].name;
                                    // }
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Workout Plan</option>
                                {props.workoutPlans.map((workoutPlan, i) => {
                                    return (
                                        <option value={workoutPlan.key} key={workoutPlan.key}>{workoutPlan.name}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Day</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.day} placeholder="Day"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.day = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Day</option>
                                {Array.from(Array(totalDays), (a, i) => {
                                    return (<option value={i + 1} key={i + 1}>{i + 1}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>*/}
                    <div className="float-right">
                        <Button variant="danger" className="submit-button mr-3" onClick={() => {
                            props.handleClose({ filterData: {} })
                        }}>
                            Reset
                            </Button>
                        <Button variant="primary" type="submit" className="submit-button">
                            Apply
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </>
    );
}

export default ExerciseFilter;
