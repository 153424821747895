

import React, {useEffect, useState} from 'react';
import { Spinner, Breadcrumb, Image, Button, ToggleButton } from "react-bootstrap";
import Chart from "react-apexcharts";
import { firestore } from "./services/firebase";
import moment from 'moment';
import * as _ from "lodash";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file



function numberWithCommas(x) {
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

var barTotal = {}
const initData = {
  
    series: [/*{
      name: 'Marine Sprite',
      data: [44, 55, 41, 37, 22, 43, 21]
    }, {
      name: 'Striking Calf',
      data: [53, 32, 33, 52, 13, 43, 32]
    }, {
      name: 'Tank Picture',
      data: [12, 17, 11, 9, 15, 11, 20]
    }, {
      name: 'Bucket Slope',
      data: [9, 7, 5, 8, 6, 9, 4]
    }, {
      name: 'Reborn Kid',
      data: [25, 12, 19, 32, 25, 24, 10]
    }*/],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      stroke: {
        width: 1,
        colors: ['#464646']
      },
      //title: {
      //  text: 'Challenges'
      //},
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'center', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return (val/1000).toFixed(0)//+'k'
          },
          //offsetY: -20,
          style: {
            fontWeight: 'bold',
            fontSize: '9px',
            colors: ["#304758"]
          }
        },
      xaxis: {
        categories: [/*2008, 2009, 2010, 2011, 2012, 2013, 2014*/],
        labels: {
          //formatter: function (val) {
          //  return val + "K"
          //}
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        x: {
          //formatter: function (val) {
          //  return val + ' ..... ' + numberWithCommas(barTotal[val].toFixed(0))
          //}
        },
        y: {
          formatter: function (val) {
            return numberWithCommas(val.toFixed(0))
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
    },
  
  
  };



export function Payments(props) { 

    const [show, setShow] = useState(false);

    const [uniqueUsers, setUniqueUsers] = useState(false);
    const [users, setUsers] = useState(false);
    const [monthly, setMonthly] = useState(false);
    const [days, setDays] = useState([]);
    const [data, setData] = useState(initData);
    const [total, setTotal] = useState(0);
    const [loader, setLoader] = useState(true);
    const [payments, setPayments] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [challengeById, setChallengeById] = useState({});
    const [challengeNutritionist, setChallengeNutritionist] = useState({});
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [challengesLoading, setChallengesLoading] = useState(true);
    const [format, setFormat] = useState('MMM DD');  //YYYY-MM-DD
    const [formatMonth, setFormatMonth] = useState('MMM');  //YYYY-MM-DD
    const [nutritionists, setNutritionists] = useState({
      options: {},
      series: [],
    });


    const [selectionRange, setSelectionRange] = useState({
      startDate: monthly? moment('2020-04-01').toDate() : moment().subtract(45, "days").startOf('day').toDate(), //moment('2020-04-19').toDate()
      endDate: new Date(),
      key: 'selection',
    });
    const [preSelectionRange, setPreSelectionRange] = useState({
      startDate: monthly? moment('2020-04-01').toDate() : moment().subtract(45, "days").startOf('day').toDate(), //moment('2020-04-19').toDate()
      endDate: new Date(),
      key: 'selection',
    });

    useEffect(() => {

        setSelectionRange({
          startDate: monthly? moment('2020-04-01').toDate() : moment().subtract(45, "days").startOf('day').toDate(),
          endDate: new Date(),
          key: 'selection',
        })
        setPreSelectionRange({
          startDate: monthly? moment('2020-04-01').toDate() : moment().subtract(45, "days").startOf('day').toDate(),
          endDate: new Date(),
          key: 'selection',
        })

        return () => { }

    }, [monthly]);

    useEffect(() => {

        var auth = prompt('enter password')

        setShow(auth === 'welnespayments')

        getChallenges();

    }, []);

    useEffect(() => {

        setLoader(true)
        var unsubscribes = getCollections();

        return () => {

            console.log('Do some cleanup');
            unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, [challengeById, selectionRange, monthly, users, uniqueUsers]);


    function getChallenges() {
        setChallengesLoading(true);
        return firestore('challenges')
            .get().then((querySnapshot) => {
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id,
                    };
                });
                //_.orderBy(groups, 'userCount', 'desc')
                //data = _.orderBy(data.filter(d => d.isPublished && !d.isFree), 'price', 'desc')

                var include = []
                data.map(d => {if(d.isPublished && !d.isFree && d.previousRounds) include = include.concat(d.previousRounds); return d})

                data = _.orderBy(data.filter(d => (d.isPublished || include.includes(d.key)) && !d.isFree), item => moment.utc(item.date).format(), 'desc')
                
                var challengeById = {}, challengeNutritionist = {}
                data.map(d => {

                    challengeById[d.key] = d
                    challengeNutritionist[d.key] = d.nutritionistName
                })
                setChallengeById(challengeById)
                setChallengeNutritionist(challengeNutritionist)

                setChallenges(data);
                setChallengesLoading(false);
                //if (data && data.length && data[0].key) {
                //    setSelectedChallenge(data[0].key)
                //}
            }, error => {
                console.log('error', error)
            });
    }


    function getCollections(){

        setPaymentsLoading(true)

        const subscribePayments = firestore("payments")//.orderBy('datetime', 'asc')
            .where('datetime', '>', moment(selectionRange.startDate).format())
            //.where('datetime', '<', moment(selectionRange.endDate).format())  //ignore to enable relatime updates
            .onSnapshot(function(snapshot){
            //.get().then(function(snapshot){

            const payments = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            var total = 0
            var paymentsByDay = {}
            var paymentsByChallenge = {}
            var paymentsByNutritionist = {}

            var userIds = {}

            payments.filter(d => !d.declined).map((d) => {

                if(!challengeById[d.challengeId]) return
                const challenge = challengeById[d.challengeId].name + (challengeById[d.challengeId].previousRounds? ' - round '+(challengeById[d.challengeId].previousRounds.length+1):'')
                const date = moment(d.datetime).format(monthly? formatMonth : format)

                if(!paymentsByChallenge[challenge]) paymentsByChallenge[challenge] = {}
                if(!paymentsByChallenge[challenge][date]) paymentsByChallenge[challenge][date] = {users: 0, amount: 0}

                //fix for ramadan challenge
                if(d.challengeId === 'zNqJyCWb8ztMJLkt6zWy') d.amount = 300

                if(d.currency === 'USD') d.amount *= 16;
                if(d.type === 'credits') d.amount *= 16;

                if(uniqueUsers){ 
                  if(!userIds[d.userId]) paymentsByChallenge[challenge][date].users++
                  userIds[d.userId] = true
                } 
                else paymentsByChallenge[challenge][date].users++
                paymentsByChallenge[challenge][date].amount+= d.amount

                /*if(!paymentsByDay[day]) paymentsByDay[day] = {day: day, user: {}, users: 0, meals: 0, workout: 0, water: 0, waterDone: 0}
                paymentsByDay[day].user[d.userId] = true
                paymentsByDay[day].water += d.isDrinkWaterCompleted? 1 : 0
                paymentsByDay[day].workout += d.isWorkoutCompleted? 1 : 0
                paymentsByDay[day].waterDone += d.glassOfWaterCount
                if(d.Breakfast) paymentsByDay[day].meals ++ */

                if(d.datetime > moment(selectionRange.startDate).format() && d.datetime < moment(selectionRange.endDate).format()){
                    
                  if(!paymentsByNutritionist[challengeById[d.challengeId].nutritionistId]) 
                    paymentsByNutritionist[challengeById[d.challengeId].nutritionistId] = {amount: 0, nutritionistName : challengeById[d.challengeId].nutritionistName}
                    paymentsByNutritionist[challengeById[d.challengeId].nutritionistId].amount += d.amount
                }
            })


            setNutritionists({...nutritionists, ...{
              options: {
                labels: Object.keys(paymentsByNutritionist).map(d => paymentsByNutritionist[d].nutritionistName),
                tooltip: { y: { formatter: val => numberWithCommas(val.toFixed(0)) }}
              },
              series: Object.keys(paymentsByNutritionist).map(d => paymentsByNutritionist[d].amount)
            }})


            var a = moment(selectionRange.startDate);
            var b = moment(selectionRange.endDate);
            var days = []

            // If you want an inclusive end date (fully-closed interval)
            //for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {

            // If you want an exclusive end date (half-open interval)
            for (var m = moment(a); m.isBefore(b); m.add(1, 'days')) {
              days.push(m.format(format))
            }

            if(monthly){ days = [];

              for (var m = moment(a); m.isBefore(b); m.add(1, 'months')) {
                days.push(m.format(formatMonth))
              }
            }


            console.log(days)
            console.log(paymentsByChallenge)

            barTotal = {}
            Object.keys(paymentsByChallenge).map(key => {

                var values = []
                days.map(day => {

                    if(users){

                      values.push(paymentsByChallenge[key][day]? paymentsByChallenge[key][day].users : 0)
                      total+= paymentsByChallenge[key][day]? paymentsByChallenge[key][day].users : 0
                      if(!barTotal[day]) barTotal[day] = 0
                      barTotal[day] += paymentsByChallenge[key][day]? paymentsByChallenge[key][day].users : 0
                    }
                    else{

                      values.push(paymentsByChallenge[key][day]? paymentsByChallenge[key][day].amount : 0)
                      total+= paymentsByChallenge[key][day]? paymentsByChallenge[key][day].amount : 0
                      if(!barTotal[day]) barTotal[day] = 0
                      barTotal[day] += paymentsByChallenge[key][day]? paymentsByChallenge[key][day].amount : 0
                    }
                })

                paymentsByChallenge[key].values = values
             })

            var _data = _.clone(initData)
            _data.options.xaxis.categories = days
            //setDays(days)
            _data.series = Object.keys(paymentsByChallenge).map(key => {return {name: key, data: paymentsByChallenge[key].values}})

            _data.options.dataLabels.formatter = users? val => val : val => (val/1000).toFixed(0)//+'k'
            _data.options.tooltip.x.formatter = users? val => val + ' ..... ' + barTotal[val] + ' user' : val => val + ' ..... ' + numberWithCommas(barTotal[val].toFixed(0)) + ' EGP'

            //_data.options.annotations = addAnnotations(_data.series,  _data.options.xaxis.categories)
            _data.options = {..._data.options, ...{
                annotations: addAnnotations(_data.series,  _data.options.xaxis.categories)
            }}

            console.log(_data)
            setData(_data)
            setTotal(total)

            setPaymentsLoading(false)

            //for(var day in pointsByDay){

                //pointsByDay[day].users = Object.keys(pointsByDay[day].user).length
            //}

            //var days = _.orderBy(Object.values(pointsByDay), 'day', 'asc').map((d)=>{d.day = 'day '+d.day; return d})

            //setDays(days);
        })

        return [subscribePayments]
    }

    const getSeriesDataSumByCategoryIndex = (series, categoryIndex) => {
      return series.reduce((acc, cur) => acc + (cur.data[categoryIndex] || 0), 0);
    };
    const addAnnotations = (series, categories) => {

      try {
        var points = []
        categories.forEach((category, index) => {
          const seriesDataSum = getSeriesDataSumByCategoryIndex(series, index);

          if(seriesDataSum) points.push(
            {
              y: seriesDataSum,
              x: category,
              label: {
                text: users? seriesDataSum.toString() : (seriesDataSum/1000).toFixed(0)+'k',
                offsetY: 10,
                style:{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  colors: ["#304758"],
                  border: 'none',
                  background: 'none'
                }
              }
            }
          );
        });
        return {points: points}
      } catch (error) {
        console.log(`Add point annotation error: ${error.message}`);
      }
    };


    return (<>
        {show ? <>
        {total>0 && <div className="no-users" style={{paddingTop: '10px'}}>{numberWithCommas(+total.toFixed(0))}</div>}
        <div className="pt-5 h-100 align-self-center" style={{width: '100%'}}>

            {paymentsLoading ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
            <div id="chart">
                <Chart options={data.options} series={data.series} type="bar" height={400} />

            </div>}

            <div style={{position: 'relative', marginLeft: '50px'}}>
                <DateRangePicker
                ranges={[preSelectionRange]}
                onChange={(range) => {setPreSelectionRange(range.selection)}}
                />

                <Button style={{padding: '.5em 1em', margin: '1em', marginLeft: '5em', fontVariant: 'small-caps'}} 
                variant="dark" onClick={(range) => {setSelectionRange(preSelectionRange)}}>Apply range</Button>

                <ToggleButton style={{padding: '.5em 1em', margin: '1em', marginLeft: '1em', fontVariant: 'small-caps'}} 
                type="checkbox" className="answered-toggle" checked={monthly} onChange={e => { setMonthly(e.target.checked); }}
                variant="info">{!monthly? 'Monthly' : 'Daily'}</ToggleButton>

                <ToggleButton style={{padding: '.5em 1em', margin: '1em', marginLeft: '1em', fontVariant: 'small-caps'}} 
                type="checkbox" className="answered-toggle" checked={users} onChange={e => { setUsers(e.target.checked); }}
                variant="info">{!users? 'Users' : 'Amounts'}</ToggleButton>

                {users && <ToggleButton style={{padding: '.5em 1em', margin: '1em', marginLeft: '1em', fontVariant: 'small-caps'}} 
                type="checkbox" className="answered-toggle" checked={uniqueUsers} onChange={e => { setUniqueUsers(e.target.checked); }}
                variant="info">{!uniqueUsers? 'Unique users' : 'per challenge'}</ToggleButton>}


                <div className="donut" style={{position: 'fixed', bottom: 20, right: 10, display: 'inline-block'}}>
                  <Chart options={nutritionists.options} series={nutritionists.series} type="donut" width="360" />
                </div>
            </div>
        </div>
        </> : <div style={{fontVariant: 'small-caps', margin: '10% auto'}}>401 Unauthorized</div>}
    </>);
}