/** @format */

import React, { useEffect, useState } from "react";

// import "../../pages/auth/challenges/ChallengesPage.scss";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import DatePicker from "react-datepicker";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { firestore } from "../../services/firebase";
import ProgramCard from "./ProgramCard";
import "./Programs.scss";

library.add(faPlus, faTrash);

export default function Programs(props) {
	const [queuedUsers, setQueuedUsers] = useState([]);
	const [challengesLoader, setChallengesLoader] = useState(false);
	const [challenges, setChallenges] = useState([]);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [showCloneConfirmation, setShowCloneConfirmation] = useState(false);
	const [challengeTodelete, setChallengeTodelete] = useState(false);
	const [challengeToClone, setChallengeToClone] = useState(false);
	const [ignore, setIgnore] = useState({});
	const [rounds, setRounds] = useState(false);
	const [cloneDate, setCloneDate] = useState();
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);
	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);
	const [countChallenges, setCountChallenges] = useState(0);
	const [userCount, setUserCount] = useState(0);

	useEffect(() => {
		firestore("users")
			.doc(user.id)
			.get()
			.then((doc) => {
				if (doc.exists) setUser(doc.data());
			});

		const mealPlanSubscription = getChallenges();
		return () => {
			mealPlanSubscription();
		};
	}, []);

	useEffect(() => {
		props.setProgramMembers();

		return () => {};
	}, []);

	function getChallenges() {
		setChallengesLoader(true);
		let count = 0;
		let countArr = [];
		var subscription = firestore("programs")
			.where("nutritionistId", "==", user.id)
			.onSnapshot((querySnapshot) => {
				setCountChallenges(querySnapshot.size);
				let data = querySnapshot.docs.map((documentSnapshot, i) => {
					count += documentSnapshot.data().userCount;
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				props.setProgramMembers(count);
				setUserCount(count);
				count = 0;

				data = _.orderBy(
					data,
					(item) => moment.utc(item.datetime).format(),
					"desc"
				);

				var grouped = [],
					ignore = {};
				data.map((d) => {
					if (d.previousRounds) {
						d.rounds = d.previousRounds.length + 1;
						d.previousRounds.map((d) => {
							ignore[d] = true;
						});
						d.roundsIds = [d.key].concat(d.previousRounds);
					}
				});
				setIgnore(ignore);

				setChallengesLoader(false);
				setChallenges(data);
			});

		return subscription;
	}

	/*Confirmation box for delete*/
	async function deleteConfirmation(e, challengeData) {
		e.stopPropagation();
		e.preventDefault();
		setChallengeTodelete(challengeData);
		setShowDeleteConfirmation(true);
	}

	/* Delete challenge after confirmation */
	async function handleConfirmation(callback) {
		if (callback) {
			await firestore("programs").doc(challengeTodelete.key).delete();
		}
		setShowDeleteConfirmation(false);
	}

	var special = [
		"0th",
		"1st",
		"2nd",
		"3rd",
		"4th",
		"5th",
		"6th",
		"7th",
		"8th",
		"9th",
		"10th",
		"11th",
		"12th",
		"13th",
		"14th",
		"15th",
		"16th",
		"17th",
		"18th",
		"19th",
	];
	var deca = [
		"twent",
		"thirt",
		"fort",
		"fift",
		"sixt",
		"sevent",
		"eight",
		"ninet",
	];

	function stringifyNumber(n) {
		if (n < 20) return special[n];
		if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
		return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
	}

	return (
		<>
			<div className="pt-5-modified">
				<Col>
					<h4>Programs</h4>
					{!(
						user?.followersCount >= 1000 ||
						(admin.role && admin.role.includes("admin"))
					) ? (
						<div
							style={{
								padding: "3em 0.1em",
								color: "#464646",
								fontSize: ".9em",
							}}
						>
							<p>Get 1,000 followers on Welnes to start a new program</p>{" "}
						</div>
					) : (
						!challengesLoader && (
							<>
								<p className="number-count">
									You have <span>{countChallenges}</span> {countChallenges === 1 ? 'program' : 'programs'} , <span>{userCount}</span> {userCount === 1 ? 'client' : 'clients'}
								</p>
								<div className="add-challenge add-program">
									<NavLink
										key="/add-program"
										to="/add-program"
										exact
										activeClassName={"link-active"}
									>
										<button className="button button-secondary">
											+ Add Program
										</button>
									</NavLink>
								</div>
							</>
						)
					)}
				</Col>
				<div className="challenge-list">
					{challengesLoader ? (
						<div className="text-center h-100 w-100">
							<Spinner animation="border" size={"lg"} role="status" />
						</div>
					) : challenges
							.map((challenge, i) => {
								return (
									<div key={i}>
										<NavLink
											key={"/edit-program"}
											to={"/edit-program/" + challenge.key}
										>
											<ProgramCard
												name={challenge?.name || ""}
												image={challenge.image || user.image}
												deleteConfirmation={deleteConfirmation}
												challenge={challenge}
											/>
										</NavLink>
									</div>
								);
							})
					}
				</div>
			</div>

			<Modal
				show={showDeleteConfirmation}
				onHide={() => handleConfirmation(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Are you sure ?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					you want to delete{" "}
					<b>{challengeTodelete?.name || user.name || "One to One program"}</b>{" "}
					?
				</Modal.Body>
				{/*<Modal.Body>Are you sure you want to delete the challenge ?</Modal.Body>*/}
				<Modal.Footer>
					<Button
						className="button button-cancel"
						onClick={() => handleConfirmation(false)}
					>
						cancel
					</Button>
					<Button
						className="button button-delete"
						onClick={() => handleConfirmation(true)}
					>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
