import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  Spinner,
  Breadcrumb,
} from "react-bootstrap";

import firebase from 'firebase/compat/app';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faFilter } from "@fortawesome/free-solid-svg-icons";
import { firestore } from "../../services/firebase";
import * as _ from "lodash";
import Thread from "../../components/Thread";
import moment from "moment";
import AddMessage from "../../components/AddMessage";

library.add(faPlus, faFilter);

let recipientsUnsub = "";

export function Messages() {
  const [show, setShow] = useState(false);
  const [reportsLoader, setReportsLoader] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [reports, setReports] = useState([]);
  const [showSub, setShowSub] = useState();
  const [thread, setThread] = useState();

  useEffect(() => {
    const ReportsSubscription = getReports();
    return () => {
      ReportsSubscription();
    };
  }, []);

  function getReports() {
    setReportsLoader(true);
    return firestore("messages")
      .where("nutritionist.id", "==", user.id)
      .orderBy("created", "desc")
      .limit(1000)
      .onSnapshot((querySnapshot) => {
        let data = querySnapshot.docs.map((documentSnapshot, i) => {
          let isRead = true;
          documentSnapshot.data().recipients.forEach((rec) => {
            if (
              !rec.isRead &&
              rec.lastMessage &&
              rec.lastMessage.sentBy === "user"
            ) {
              isRead = false;
            }
          });
          return {
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
            isRead: isRead,
          };
        });

        setReportsLoader(false);
        setReports(data);
      });
  }

  async function newMessage(d) {
    setThread(true);

    return;
  }

  async function updateReplyable(docData) {
    if (showSub) {
      await firestore("messages")
        .doc(docData.q.key)
        .collection("recipients")
        .doc(docData.key)
        .update({ replyable: !docData.replyable });
    } else {
      const writeBatch = firebase.firestore().batch();
      await firestore("messages")
        .doc(docData.key)
        .update({ replyable: !docData.replyable });
      const recipientsRef = await firestore("messages")
        .doc(docData.key)
        .collection("recipients")
        .get();
      recipientsRef.docs.forEach((recip) => {
        const recipRef = firestore("messages")
          .doc(docData.key)
          .collection("recipients")
          .doc(recip.id);
        writeBatch.update(recipRef, { replyable: !docData.replyable });
      });
      writeBatch.commit();
    }
  }
  /* Subscribing to recipients documents, we need to revisit logic */
  async function openRecipients(question = "") {
    var questionData = _.clone(question);
    recipientsUnsub = firestore("messages")
      .doc(question.key)
      .collection("recipients")
      .onSnapshot((querySnapshot) => {
        var recipients = querySnapshot.docs.length
          ? querySnapshot.docs.map((d) => {
              var obj = d.data();
              obj.key = d.id;
              obj.q = questionData;
              return obj;
            })
          : [];

        setShowSub(recipients);
      });
  }

  async function editQuestion(question = "") {
    if (!question.q.recipientsIds.includes(question.key)) {
      alert("User deleted message and no longer interested");
      return;
    }
    var questionData = _.clone(question);
    var snapshot = await firestore("messages")
      .doc(question.q.key)
      .collection("recipients")
      .doc(question.user.id)
      .collection("thread")
      .get();
    questionData.messages = snapshot.docs.length
      ? snapshot.docs.map((d) => {
          var obj = d.data();
          obj.id = d.id;
          return obj;
        })
      : [];

    var snapshot = await firestore("users").doc(question.user.id).get();
    if (snapshot.exists) questionData.user = snapshot.data();

    var snapshot = await firestore("followup")
      .where("user.id", "==", question.user.id)
      .get();
    if (snapshot.exists)
      questionData.userFollowupNo = snapshot.docs[0].data().phoneNo;

    const method = function (item) {
      return moment
        .utc(item.created ? item.created.toDate() : item.datetime)
        .format();
    };
    questionData.messages = _.orderBy(questionData.messages, method, "asc");
    setQuestionData(questionData);
    setShow(true);

    await firestore("messages")
      .doc(question.q.key)
      .collection("recipients")
      .doc(question.user.id)
      .update({ isRead: true });
    if (question.q.recipients) {
      const arr = question.q.recipients.map((recipient) => {
        if (recipient.id === question.user.id) {
          return { ...recipient, isRead: true };
        }
        return recipient;
      });
      await firestore("messages")
        .doc(question.q.key)
        .update({ recipients: [...arr] });
    }
    question.isRead = true;
  }

  return (
    <>
      <Breadcrumb className="pb-3">
        <Breadcrumb.Item active>
          Messages ({reports.length === 10000 ? "10,000+" : reports.length})
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="pt-5" id="notifications">
        <Card>
          {/*<Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            Reported content ({reports.length})
                        </h3>
                    </Card.Header>*/}
          <Card.Body>
            {false /*!showSub*/ && (
              <Button
                onClick={newMessage}
                variant="success"
                size="sm"
                style={{
                  marginLeft: "3em",
                  float: "right",
                  marginBottom: "10px",
                  padding: "0.5em 2em",
                }}
              >
                + new
              </Button>
            )}
            {showSub && (
              <Button
                onClick={() => {
                  setShowSub(false);
                  if (recipientsUnsub) {
                    recipientsUnsub();
                  }
                  recipientsUnsub = "";
                }}
                variant="success"
                size="sm"
                style={{
                  marginLeft: "3em",
                  float: "right",
                  marginBottom: "10px",
                  padding: "0.5em 2em",
                  display: "block",
                  width: "100%",
                }}
                disabled={!reports.length}
              >
                back
              </Button>
            )}

            {reportsLoader ? (
              <div className="loader text-center">
                <Spinner animation="border" size={"sm"} role="status" />
              </div>
            ) : (
              <table
                id="reports"
                className="table components"
                style={{
                  fontSize: ".9em",
                  marginTop: "5em",
                  marginBottom: "0",
                }}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    {showSub ? (
                      <th>last Message</th>
                    ) : (
                      <>
                        <th>Title</th>
                        <th>Body</th>
                        <th>Image</th>
                      </>
                    )}
                    <th>Recipients</th>
                    {!showSub && <th>Type</th>}
                    <th>Reply-able</th>
                  </tr>
                </thead>
                <tbody>
                  {(showSub ? showSub : reports).map((d) => (
                    <tr
                      onClick={() =>
                        showSub ? editQuestion(d) : openRecipients(d)
                      }
                      style={{
                        cursor: "pointer",
                        fontWeight: !d.isRead ? "bold" : "normal",
                        color:
                          showSub && !d.q.recipientsIds.includes(d.key)
                            ? "red"
                            : "black",
                      }}
                    >
                      {d.created && (
                        <td>
                          {
                            new Date(
                              showSub
                                ? d.lastMessage.created.toDate()
                                : d.created.toDate()
                            )
                              .toISOString()
                              .split("T")[0]
                          }{" "}
                          <span style={{ opacity: 0.5, marginLeft: "1em" }}>
                            {moment(
                              showSub
                                ? d.lastMessage.created.toDate()
                                : d.created.toDate()
                            ).format("hh:mm")}
                          </span>
                        </td>
                      )}
                      {showSub ? (
                        <td>{d.lastMessage.body}</td>
                      ) : (
                        <>
                          <td className="notification-title">{d.title}</td>
                          <td className="notification-body">{d.body}</td>
                          <td className="notification-image">
                            {d.image && (
                              <img
                                alt=""
                                src={d.image}
                                style={{
                                  height: "20px",
                                  cursor: showSub ? "pointer" : "default",
                                }}
                                onClick={
                                  showSub
                                    ? (e) => {
                                        e.stopPropagation();
                                        window.open(d.image);
                                      }
                                    : undefined
                                }
                              />
                            )}
                          </td>
                        </>
                      )}
                      <td
                        style={{
                          color:
                            showSub && !d.q.recipientsIds.includes(d.key)
                              ? "red"
                              : "#3d7ce4",
                        }}
                      >
                        {showSub ? d.user.name : d.recipients.length}
                      </td>
                      {!showSub && (
                        <td style={{ color: "#3d7ce4" }}>{d.type}</td>
                      )}
                      <td
                        style={{
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Check
                          size="sm"
                          disabled={
                            (showSub &&
                              (d.q.type === "Reminder" ||
                                d.q.type === "Promotion")) ||
                            d.type === "Reminder" ||
                            d.type === "Promotion"
                          }
                          onClick={(e) => e.stopPropagation()}
                          checked={d.replyable}
                          onChange={(e) => {
                            if (
                              (showSub &&
                                (d.q.type === "FollowUp" ||
                                  d.q.type === "Feedback")) ||
                              d.type === "FollowUp" ||
                              d.type === "Feedback"
                            ) {
                              updateReplyable(d);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Card.Body>
        </Card>
        {/*<Modal show={showPromocode} onHide={handleCloseMealPlan}>
                    <AddPromocode handleClose={handleCloseMealPlan} promocodeData={promocodeData} Reports={Reports} />
                </Modal>*/}
      </div>
      {/*<Modal show={showDeleteModal} onHide={handleDeleted}>
                <DeleteAlert onHide={handleDeleted} title={'Delete Meal Plan'} message={'Are you sure you want to delete meal plan?'} warning={'All the related recipes will be unassigned.'} docId={docId} collectionName={'meal_plans'} />
            </Modal>*/}

      {thread && (
        <Thread
          close={() => {
            setThread(false);
          }}
        />
      )}

      <Modal show={show} onHide={() => setShow(false)}>
        <AddMessage
          handleClose={() => setShow(false)}
          questionData={questionData}
        />
      </Modal>
    </>
  );
}
