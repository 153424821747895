import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Form,
    Modal,
    ButtonToolbar,
    Table,
    DropdownButton,
    Dropdown,
    Spinner,
    Breadcrumb
} from "react-bootstrap";
import Masonry from 'react-masonry-css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faFilter, faPencilAlt, faCopy, faTrashAlt, faArrowUp, faArrowDown, faUndo, faCheck, faTimes, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { confirm } from "../../components/Confirmation";
import { firestore } from "../../services/firebase";
import AddPromocode from '../../components/AddPromocode';
import * as _ from "lodash";
import DeleteAlert from '../../components/DeleteAlert';
import moment from 'moment';

library.add(faPlus, faFilter);

export function Feedback() {
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [feedbackLoader, setFeedbackLoader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');
    const [sort, setSort] = useState({
        field: 'datetime',
        direction: 'desc'
    });

    const tableHeaders = [{
        name: 'Name',
        filterField: 'name',
    //}, {
    //    name: '',
    //    filterField: 'none',
    }, {
        name: 'Date',
        filterField: 'datetime',
    }, {
        name: 'Type',
        filterField: 'type',
    }, {
        name: 'Kcal',
        filterField: 'calories',
    //}, {
    //    name: 'Actions',
    //    filterField: 'none',
    }];
    const [transactions, setTransactions] = useState([]);
    const [feedback, setFeedback] = useState([]);
    const [promocodeData, setPromocodeData] = useState({});
    const [filterData, setFilterData] = useState({});
    const [showPromocode, setShowPromocode] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [promocodeIdToName, setPromocodeIdToName] = useState();
    const [challengeIdToName, setChallengeIdToName] = useState();
    const [oneToOneIdToName, setOneToOneIdToName] = useState();
    const [feedbackbyId, setFeedbackbyId] = useState({});

    useEffect(() => {
        const FeedbackSubscription = getFeedback();
        return () => {
            //FeedbackSubscription();
        };
    }, []);

    useEffect(() => {
        const challengeSubscription = getChallenges();
        const onetooneSubscribtion = getOneToOnes();
        return () => {
            challengeSubscription();
            // onetooneSubscribtion();
            getOneToOnes();
        };
    }, []);


    function getFeedback() {
        setFeedbackLoader(true);
        firestore('feedback').get().then((querySnapshot) => {

            const feedbackbyId = {}
            let data = querySnapshot.docs.map((documentSnapshot, i) => {
                feedbackbyId[documentSnapshot.id] = documentSnapshot.data()
                return {
                    ...documentSnapshot.data(),
                    key: documentSnapshot.id,
                };
            });


            var allusers = []

            let chunked = []
            let size = 10;

            var users = data.map(d => d.key)
            for (let i = 0;  i < users.length; i += size) {
              chunked.push(users.slice(i, i + size))
            }

            //console.log('chunked', chunked)


            var allDone = function(){

                setFeedback(_.orderBy(allusers, 'datetime', 'desc'));
                setFeedbackLoader(false);

                setFeedbackbyId(feedbackbyId)
            }

            var done = 0

            chunked.map(chunk => {

                //console.log('chunk', chunk)

                const subscribe = firestore("users")
                    //.doc(userDetails.id)
                    //.collection('followers')
                    .where('id', 'in', chunk)
                    //.onSnapshot(function(snapshot){
                    .get().then(function(snapshot){

                    const users = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; obj.status=feedbackbyId[d.id].status; if(feedbackbyId[d.id].created) obj.datetime = moment(feedbackbyId[d.id].created.toDate()).format(); obj.challengeId=feedbackbyId[d.id].challengeId; return obj}) : []

                    Array.prototype.push.apply(allusers, users)

                    if(++done === chunked.length) allDone()
                })

            })
        })
    }

    function getChallenges() {
        return firestore('challenges')
            //.where('nutritionistId', '==', userDetails.id)
            .onSnapshot((querySnapshot) => {
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id,
                    };
                });
                var _challengeIdToName = {}
                data.map((challenge)=>{_challengeIdToName[challenge.key] = challenge.nutritionistName + ' | ' + challenge.name})
                setChallengeIdToName(_challengeIdToName)
            });
    }

    function getOneToOnes() {
        return firestore('one_to_one')
            .get().then((querySnapshot) => {
            //.onSnapshot((querySnapshot) => {
                var challengeIdToName = {}
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    challengeIdToName[documentSnapshot.id] = documentSnapshot.data().nutritionistName
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });;
                setOneToOneIdToName(challengeIdToName);
            });
    }

    async function contacted(d){

        if (await confirm(
            'Acknowledge'+' '+d.name?d.name.split(' ')[0]:d.email+ ' feedback',
            'are you sure '+d.name?d.name.split(' ')[0]:d.email+' has been contacted'+' ?'
            , 'acknowledge')){
            
            await firestore('feedback').doc(d.id).update({status: 'contacted'});

            getFeedback()
        }
    }

    async function contactSupport(d){

        window.open('/support/'+d.id)
    }

    return (
        <>
            <Breadcrumb className="pb-3">
                <Breadcrumb.Item active>Feedback</Breadcrumb.Item>
            </Breadcrumb>
            <div className="pt-5">
                <Card>
                    <Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            feedback ({feedback.filter(d => d.phone).length})
                            {/*<Button size='sm' variant="info" className="text-uppercase" onClick={() => {
                                addMealPlan();
                            }}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />Add promo code
                            </Button>*/}
                        </h3>
                    </Card.Header>
                    <Card.Body>

                        {feedbackLoader ? <div className="text-center" style={{padding: '20px'}}><Spinner animation="border" size={'lg'} role="status" style={{height: '20px', width: '20px'}}/></div> : 
                        <table id="feedback" className="table components" style={{fontSize: '1em', marginTop: '2em', marginBottom: '0'}}>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Challenge</th>
                                <th>Name</th>
                                <th>Mobile</th>
                                <th colSpan="2" style={{textAlign: 'right'}}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {feedback.filter(d => d.phone).map(d => <tr style={d.status === 'contacted'?{color: '#bbb'}:{}}>
                                <td>{(d.datetime).split('T')[0]}</td>
                                <td>{(challengeIdToName && challengeIdToName[d.challengeId]) || (oneToOneIdToName && oneToOneIdToName[d.challengeId]) || d.challengeId}</td>
                                <td>{d.name}</td>
                                <td>+{d.phone?.callingCode} {d.phone?.number}</td>
                                <td style={d.status === 'contacted'?{textAlign: 'right', color: 'green', pointerEvents: 'none'}:{opacity:0.4, transition: '.3s', textAlign: 'right', color: 'green', cursor: 'pointer'}} className="remove" onClick={()=>{contacted(d)}}><FontAwesomeIcon icon={faCheck} /></td>
                                <td style={{opacity:0.4, transition: '.3s', textAlign: 'right', color: 'green', cursor: 'pointer'}} className="remove" onClick={()=>{contactSupport(d)}}><FontAwesomeIcon icon={faHeadset} /></td>
                            </tr>)}
                        </tbody>
                        </table>}

                    </Card.Body>
                </Card>
                {/*<Modal show={showPromocode} onHide={handleCloseMealPlan}>
                    <AddPromocode handleClose={handleCloseMealPlan} promocodeData={promocodeData} Feedback={Feedback} />
                </Modal>*/}
            </div>
            {/*<Modal show={showDeleteModal} onHide={handleDeleted}>
                <DeleteAlert onHide={handleDeleted} title={'Delete Meal Plan'} message={'Are you sure you want to delete meal plan?'} warning={'All the related recipes will be unassigned.'} docId={docId} collectionName={'meal_plans'} />
            </Modal>*/}
        </>
    )
}
