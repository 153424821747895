

import React, {useEffect, useState} from 'react';
import { Spinner, Breadcrumb, Image, Button, ToggleButton, Col, Row, Form, Modal } from "react-bootstrap";
import Chart from "react-apexcharts";

import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";
import { Input } from '@material-ui/core';

import { confirm } from "../../components/Confirmation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMinus, faPlus, faFolder, faFolderPlus, faTrashAlt, faClone, faShare, faShareSquare, faPencilAlt} from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


function numberWithCommas(x) {
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

var currentCategory

export function Food(props) { 

    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [itemForm, setItemForm] = useState();
    const [categoryForm, setCategoryForm] = useState();
    const [moveForm, setMoveForm] = useState();
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [searchKey, setSearchKey] = useState();
    const [allergies, setAllergies] = useState();
    const [macronutrient, setMacronutrient] = useState();
    const [selectedAllergies, setSelectedAllergies] = useState();
    const [selectedMacronutrient, setSelectedMacronutrient] = useState();

    const [formValue, setFormValue] = useState();
    
    useEffect(() => {

        currentCategory = selectedCategory

    }, [selectedCategory]);

    useEffect(() => {

        setLoader(true)
        var unsubscribes = getCollections();

        return () => {

            console.log('Do some cleanup');
            unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);


    function getCollections(){

        /*var insertItem = function(item){

            console.log(item)
            firestore("food").get(item)
        }

        food[0].map((d, i) => {

            var item = {
                allergies: d['Allergy tag']? d['Allergy tag'].split(', ') : [],
                calories_unit: d['Calories per gram or per ml'],
                calories_serving: d['Calories per serving'],
                category: d['Category'],
                category_ar: d['Category (AR)'],
                food: d['Food'],
                food_ar: d['Food (AR)'],
                serving: d['Serving'],
                unit: d['Unit'],
                unit_serving: d['gm or ml/ item'],
            }
            setTimeout(function(){ insertItem(item); }, i*1000)
        })
        console.log(food[0].length)
        */

        setLoading(true)

        const subscribe = firestore("food")
            .onSnapshot(function(snapshot){
            //.get().then(function(snapshot){

            const items = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            var categories = {}
            items.map(d=> {if(!categories[d.category]) categories[d.category] = d.category_ar})

            var allergies = {}
            items.map(d=> {if(d.allergies) d.allergies.map(a => {if(!allergies[a]) allergies[a] = true})})
            setAllergies(Object.keys(allergies).map(a => {return { value: a, label: a }}))

            var macronutrient = {}
            items.map(d=> {if(d.macronutrient) d.macronutrient.map(a => {if(!macronutrient[a]) macronutrient[a] = true})})
            setMacronutrient(Object.keys(macronutrient).map(a => {return { value: a, label: a }}))

            setItems(_.sortBy(items, ["category", "food"]))
            setCategories(_.sortBy(Object.keys(categories).map(d => {return {category: d, category_ar: categories[d]}}), "category"))
            if(!currentCategory || !categories[currentCategory]) setSelectedCategory(_.sortBy(items, ["category"])[0].category)
            setLoading(false)
        })

        return [subscribe]
    }

    async function key_up(e, d, field, field_title){
        var enterKey = 13; //Key Code for Enter Key
        if (e.which == enterKey){
            var food_name = d.food
            var org = d[field]
            d[field] = typeof d[field] === 'number'? +e.target.value : e.target.value
            console.log('change', food_name, field,'to', e.target.value)
            e.persist()
            if (await confirm(
                'Edit'+' '+'field',
                'are you sure you want to '+'change'+' '+food_name+' '+field_title+' to ' + e.target.value+' ?'
                , 'save')){

                var update = {}
                update[field] = field === 'allergies'? (e.target.value?e.target.value.split(', '):[]) : typeof d[field] === 'number'? +e.target.value : e.target.value
                await firestore('food').doc(d.id).update(update);
            }
            else{

                d[field] = org
            }
        }
    }

    async function addCategory(e){

        e.stopPropagation();
        e.preventDefault();

        var category = e.target.category.value//prompt("Please enter Category name"); if(!category) return
        var category_ar = e.target.category_ar.value//prompt("Please enter Category arabic name"); if(!category_ar) return
        var food = e.target.food.value//prompt("Please enter Ingredient name"); if(!food) return
        var food_ar = e.target.food_ar.value//prompt("Please enter arabic name"); if(!food_ar) return
        var serving = e.target.serving.value//prompt("Please enter serving", 'tsp, cup, etc..'); if(!serving) return
        var unit = e.target.unit.value//prompt("Please enter unit", 'g, ml, etc..'); if(!unit) return
        var unit_serving = e.target.unit_serving.value//prompt(unit+" per "+serving); if(!unit_serving) return
        var calories_unit = e.target.calories_unit.value//prompt("calories per "+unit); if(!calories_unit) return
        var calories_serving = e.target.calories_serving.value//prompt("calories per "+serving); if(!calories_serving) return
        var allergies = selectedAllergies//e.target.allergies.value//prompt("allergies (if any)");

        //if(!food) return alert('no name has been entered, discarding..')
        //if(isNaN(calories_unit)) return alert('calories per unit is not a number, discarding..')
        //if(isNaN(calories_serving)) return alert('calories per serving is not a number, discarding..')
        //if(isNaN(unit_serving)) return alert(unit+' per serving is not a number, discarding..')

        var item = {
            allergies: allergies?allergies.map(d => d.value):[],
            calories_unit: parseFloat(calories_unit),
            calories_serving: parseFloat(calories_serving),
            category: category.split(' ').map(d => d[0].toUpperCase()+d.slice(1)).join(' '),
            category_ar: category_ar,
            food: food.split(' ').map(d => d[0].toUpperCase()+d.slice(1)).join(' '),
            food_ar: food_ar,
            serving: serving,
            unit: unit,
            unit_serving: parseFloat(unit_serving),
        }

        if(items.filter(d => d.food === item.food).length) return alert('An ingredient with the same name already exists')

        var added = await firestore('food').add(item);

        var goToAddedEl = function(){

            var cat = document.getElementById(item.category)

            if(cat){

                setSelectedCategory(item.category)

                var div = document.getElementById(added.id)

                if(div){

                    div.classList.add("new");
                    window.scroll({top: div.offsetTop - 50, left: 0, behavior: 'smooth' });

                    setTimeout(function(){

                        div.classList.remove("new");

                    }, 3000)
                }
                else{

                    setTimeout(goToAddedEl, 1000)
                }
            }
            else{

                setTimeout(goToAddedEl, 1000)
            }
        }

        setCategoryForm(false)
        goToAddedEl()
    }

    async function addItem(e){

        e.stopPropagation();
        e.preventDefault();

        var category = e.target.category.value//prompt("Please enter Category name"); if(!category) return
        var food = e.target.food.value//prompt("Please enter Ingredient name"); if(!food) return
        var food_ar = e.target.food_ar.value//prompt("Please enter arabic name"); if(!food_ar) return
        var serving = e.target.serving.value//prompt("Please enter serving", 'tsp, cup, etc..'); if(!serving) return
        var unit = e.target.unit.value//prompt("Please enter unit", 'g, ml, etc..'); if(!unit) return
        var unit_serving = e.target.unit_serving.value//prompt(unit+" per "+serving); if(!unit_serving) return
        var calories_unit = e.target.calories_unit.value//prompt("calories per "+unit); if(!calories_unit) return
        var calories_serving = e.target.calories_serving.value//prompt("calories per "+serving); if(!calories_serving) return
        var macronutrient = (selectedMacronutrient||[]).map(d=> d.value)//e.target.macronutrient.value//prompt("macronutrient (if any)");
        var allergies = (selectedAllergies||[]).map(d=> d.value)//e.target.allergies.value//prompt("allergies (if any)");

        var fat = e.target.fat.value//prompt("calories per "+unit); if(!calories_unit) return
        var protein = e.target.protein.value//prompt("calories per "+unit); if(!calories_unit) return
        //var fibers = e.target.fibers.value//prompt("calories per "+unit); if(!calories_unit) return
        //var suger = e.target.suger.value//prompt("calories per "+unit); if(!calories_unit) return
        var carbs = e.target.carbs.value//prompt("calories per "+unit); if(!calories_unit) return

        //if(!food) return alert('no name has been entered, discarding..')
        //if(isNaN(calories_unit)) return alert('calories per unit is not a number, discarding..')
        //if(isNaN(calories_serving)) return alert('calories per serving is not a number, discarding..')
        //if(isNaN(unit_serving)) return alert(unit+' per serving is not a number, discarding..')

        var item = {
            macronutrient: macronutrient?macronutrient.map(d => d[0].toUpperCase()+d.slice(1)):[],
            allergies: allergies?allergies.map(d => d[0].toUpperCase()+d.slice(1)):[],
            calories_unit: parseFloat(calories_unit),
            calories_serving: parseFloat(calories_serving),
            category: category,//selectedCategory,
            category_ar: categories.filter(d => d.category===category)[0].category_ar,
            food: food.split(' ').map(d => d[0].toUpperCase()+d.slice(1)).join(' '),
            food_ar: food_ar,
            serving: serving,
            unit: unit,
            unit_serving: parseFloat(unit_serving),
            fat: parseFloat(fat),
            protein: parseFloat(protein),
            //fibers: parseFloat(fibers),
            //suger: parseFloat(suger),
            carbs: parseFloat(carbs),
        }

        if(!(formValue && formValue.id) && items.filter(d => d.food === item.food).length) return alert('An ingredient with the same name already exists')

        //var added = await firestore('food').add(item);
        var added = formValue && formValue.id? await firestore('food').doc(formValue.id).update(item) : await firestore('food').add(item);

        var goToAddedEl = function(){

            setSelectedCategory(item.category)

            var div = document.getElementById(formValue && formValue.id? formValue.id : added.id)

            if(div){

                div.classList.add("new");
                window.scroll({top: div.offsetTop - 50, left: 0, behavior: 'smooth' });

                setTimeout(function(){

                    div.classList.remove("new");

                }, 3000)
            }
            else{

                setTimeout(goToAddedEl, 1000)
            }
        }

        setItemForm(false)
        if(!(formValue && formValue.id)) goToAddedEl()
    }

    async function cloneItem(d){
        var food_name = d.food
        console.log('clone', food_name)

        var newName = prompt("Please enter new Ingredient name", d.food + ' (cloned)'); if(!newName) return

        if(food_name === newName) return alert('An ingredient with the same name already exists')

        if (await confirm(
            'Clone'+' '+'Ingredient',
            'create a clone of '+food_name+' named '+newName+' ?'
            , 'clone')){

            var item = _.clone(d)
            item.food = newName
            delete item.id

            var added = await firestore('food').add(item);

            var goToAddedEl = function(){

                var div = document.getElementById(added.id)

                if(div){

                    div.classList.add("new");
                    window.scroll({top: div.offsetTop - 50, left: 0, behavior: 'smooth' });

                    setTimeout(function(){

                        div.classList.remove("new");

                    }, 3000)
                }
                else{

                    setTimeout(goToAddedEl, 1000)
                }
            }

            goToAddedEl()
        }
    }

    async function moveItem(e){

        e.stopPropagation();
        e.preventDefault();

        setMoveForm(false)

        var c = categories.filter(d => d.category === e.target.category.value)[0]//prompt("Please enter Ingredient name"); if(!food) return
        var d = _.clone(moveForm)

        var food_name = d.food
        console.log('move', food_name)

        //var category = categories[parseInt(prompt("Please select new category \n" + categories.map((c, i) => String(i+1)+'. '+c.category+' ('+c.category_ar+')').join('\n'), '1')) - 1];
        //if(!category) return

        if (await confirm(
            'Move'+' '+'Ingredient',
            'are you sure you want to '+'move'+' '+food_name+' to '+c.category+' ?'
            , 'move')){

            await firestore('food').doc(d.id).update({category: c.category, category_ar: c.category_ar});

            var goToAddedEl = function(){

                var cat = document.getElementById(c.category)

                if(cat){

                    setSelectedCategory(c.category)

                    var div = document.getElementById(d.id)

                    if(div){

                        div.classList.add("new");
                        window.scroll({top: div.offsetTop - 50, left: 0, behavior: 'smooth' });

                        setTimeout(function(){

                            div.classList.remove("new");

                        }, 3000)
                    }
                    else{

                        setTimeout(goToAddedEl, 1000)
                    }
                }
                else{

                    setTimeout(goToAddedEl, 1000)
                }
            }

            goToAddedEl()
        }
    }

    async function removeItem(d){
        var food_name = d.food
        console.log('remove', food_name)
        if (await confirm(
            'Remove'+' '+'Ingredient',
            'are you sure you want to '+'remove'+' '+food_name+' ?'
            , 'remove')){

            await firestore('food').doc(d.id).delete();
        }
    }

    function isNumberKey(evt){

        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 190) return evt.preventDefault();

        return true;
    }

    return (<>

        <Breadcrumb className="pb-3">
            <Breadcrumb.Item active>Food Ingredients</Breadcrumb.Item>

            {categories &&
                <>

                    <Breadcrumb.Item active>Category</Breadcrumb.Item>
                    
                    <Breadcrumb.Item active>
                        <div className="selectWrapper" style={{position: 'absolute', top: '5px'}}>
                            <Form.Control required as="select" placeholder="Select Category" style={{padding:"8px", marginLeft:"0", cursor: 'pointer', width: 'auto', display: 'inline-block', opacity: searchKey? 0.1 : 1, pointerEvents: searchKey? 'none' : 'auto'}}
                                value={selectedCategory}
                                onChange={(e) => {
                                    setSelectedCategory(e.target.value);
                                }}>
                                {categories.map((obj, i) => {
                                    return (<option value={obj.category} key={obj.category} id={obj.category}>{obj.category} ({obj.category_ar})</option>)
                                })}
                            </Form.Control>

                            <Form.Control placeholder="search" style={{cursor: 'pointer', width: 'auto', display: 'inline-block', marginLeft: '10px',padding:"0 0 0 1em"}}
                                value={searchKey}
                                onChange={(e) => {
                                    setSearchKey(e.target.value);
                                }}>
                            </Form.Control>
                        </div>
                    </Breadcrumb.Item>

                    <div className="no-users">
                        <div style={{opacity: .8, cursor: 'pointer', display:'inline-block', marginRight:'30px'}} onClick={()=>{setSelectedMacronutrient(); setSelectedAllergies(); setFormValue(); setCategoryForm(true)}}>Add Category<FontAwesomeIcon style={{}} icon={faFolderPlus} className="ml-2"/></div>
                        <div style={{opacity: .8, cursor: 'pointer', display:'inline-block'}} onClick={()=>{setSelectedMacronutrient(); setSelectedAllergies(); setFormValue(); setItemForm(true)}}>Add Ingredient<FontAwesomeIcon style={{}} icon={faPlus} className="ml-2"/></div>
                    </div>
                </>
            }

        </Breadcrumb>


        {loading? <div className="text-center"><Spinner animation="border" size={'sm'} role="status" style={{marginTop: '10em'}}/></div> :
        <table id="transactions" className="table components" style={{marginTop: '5em'}}>
            <thead>
            <tr>
                {/*<th>Category</th>
                <th>Category (AR)</th>*/}
                <th>Food</th>
                <th>Food (AR)</th>
                <th>Serving</th>
                {/*<th>Unit per serving</th>
                <th>Unit</th>
                <th>Calories per serving</th>*/}
                <th>Cal/unit</th>
                <th>Macronutrient</th>
                <th>Allergy</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {items.filter(d => searchKey? (d.food+' '+d.food_ar).toLowerCase().includes(searchKey.toLowerCase()) : d.category === selectedCategory).map(d => <tr key={d.id} id={d.id} className="food-item">

                {/*<td>{d.category}</td>
                <td>{d.category_ar}</td>*/}
                <td><input defaultValue={d.food} onKeyUp={(e) => {key_up(e, d, 'food', 'name')}} onBlur={(e)=>{e.target.value = d.food}} editable="editable"/></td>
                <td><input defaultValue={d.food_ar} onKeyUp={(e) => {key_up(e, d, 'food_ar', 'arabic name')}} onBlur={(e)=>{e.target.value = d.food_ar}} editable="editable" style={{direction: 'rtl', textAlign: 'left'}}/></td>
                <td style={{fontFamily: 'monospace'}}><input defaultValue={d.serving} onKeyUp={(e) => {key_up(e, d, 'serving', 'serving')}} onBlur={(e)=>{e.target.value = d.serving}} editable="editable" size={d.serving.length || 3}/>(<input defaultValue={+d.unit_serving.toFixed(2)} onKeyUp={(e) => {key_up(e, d, 'unit_serving', 'unit per serving')}} onBlur={(e)=>{e.target.value = +d.unit_serving.toFixed(2)}} editable="editable" size={(+d.unit_serving.toFixed(2)).toString().length}/>{d.unit}) <span style={{color:'#777', marginLeft:'5px'}}><input style={{color:'#777'}} defaultValue={+d.calories_serving.toFixed(2)} onKeyUp={(e) => {key_up(e, d, 'calories_serving', 'calories per serving')}} onBlur={(e)=>{e.target.value = +d.calories_serving.toFixed(2)}} editable="editable" size={(+d.calories_serving.toFixed(2)).toString().length}/>cal</span></td>
                {/*<td>{d.unit_serving}</td>
                <td>{d.unit}</td>
                <td>{d.calories_serving}</td>*/}
                <td><input defaultValue={d.calories_unit.toFixed(2)} onKeyUp={(e) => {key_up(e, d, 'calories_unit', 'calories per '+d.unit)}} onBlur={(e)=>{e.target.value = d.calories_unit.toFixed(2)}} editable="editable" size={3}/><span style={{color:'#777'}}> cal/</span><input style={{color:'#777'}} defaultValue={d.unit} onKeyUp={(e) => {key_up(e, d, 'unit', 'unit')}} onBlur={(e)=>{e.target.value = d.unit}} editable="editable" size={d.unit.length || 2}/></td>
                <td><input value={(d.macronutrient||[]).join(', ')} style={{fontSize: '.9em', pointerEvents: 'none'}} readOnly/></td>
                <td><input value={(d.allergies||[]).join(', ')} style={{fontSize: '.9em', pointerEvents: 'none'}} readOnly/></td>
                <td style={{opacity:0.1, transition: '.3s'}} className="remove" onClick={()=>{setSelectedMacronutrient((d.macronutrient||[]).map(d=>{return {value: d, label: d}})); setSelectedAllergies((d.allergies||[]).map(d=>{return {value: d, label: d}})); setFormValue(d); setItemForm(true);}}><FontAwesomeIcon icon={faPencilAlt} /></td>
                <td style={{opacity:0.1, transition: '.3s'}} className="remove" onClick={()=>{cloneItem(d)}}><FontAwesomeIcon icon={faClone} /></td>
                <td style={{opacity:0.1, transition: '.3s'}} className="remove" onClick={()=>{setMoveForm(d)}}><FontAwesomeIcon icon={faShareSquare} /></td>
                <td style={{opacity:0.1, transition: '.3s'}} className="remove" onClick={()=>{removeItem(d)}}><FontAwesomeIcon icon={faTrashAlt} /></td>
            </tr>)}
        </tbody>
        </table>}

        <Modal show={itemForm} onHide={() => setItemForm(false)} size="sm">

            <Form style={{padding: '5%', fontSize: '.9em'}} onSubmit={(e) => {addItem(e)}} className="food-form">

                <label style={{display: 'block', width: '100%', color: '#777'}}>Category
                    <Form.Control required as="select" style={{display: 'block', width: '100%', color: '#777', fontSize: '.9em', marginTop: '5px', marginBottom: '5px',marginLeft:"0"}} required name="category" defaultValue={formValue? formValue.category : selectedCategory}>
                        {categories && categories.map((obj, i) => {
                            return (<option value={obj.category} key={obj.category}>{obj.category}</option>)
                        })}
                    </Form.Control>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Ingredient
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="food" defaultValue={formValue? formValue.food : ''} placeholder="Apple"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Ingredient (Arabic)
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="food_ar" defaultValue={formValue? formValue.food_ar : ''} placeholder="تفاحة"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Serving
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="serving" defaultValue={formValue? formValue.serving : ''} placeholder="Cup, tbsp, item .. etc."/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Unit
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="unit" defaultValue={formValue? formValue.unit : ''} placeholder="g or ml"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many g/ml per serving
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="unit_serving" defaultValue={formValue? formValue.unit_serving : ''} onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many calories per g/ml
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="calories_unit" defaultValue={formValue? formValue.calories_unit : ''} onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many calories per serving?
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="calories_serving" defaultValue={formValue? formValue.calories_serving : ''} onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Macronutrient (if any)
                    {/*<Input style={{display: 'block', width: '100%'}} name="allergies"/>*/}
                    <Select options={macronutrient} className="select" isMulti placeholder="none" value={selectedMacronutrient} onChange={(e) => {setSelectedMacronutrient(e)}}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Allergies (if any)
                    {/*<Input style={{display: 'block', width: '100%'}} name="allergies"/>*/}
                    <Select options={allergies} className="select" isMulti placeholder="none" value={selectedAllergies} onChange={(e) => {setSelectedAllergies(e)}}/>
                </label>

                {/*<div style={{display: 'inline-block', width: '50%', padding: '2%'}}>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Fat
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="fat" defaultValue={formValue? formValue.fat : ''} onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Protein
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="protein" defaultValue={formValue? formValue.protein : ''} onKeyDown={isNumberKey}/>
                </label>
                </div>
                <div style={{display: 'inline-block', width: '50%', padding: '2%'}}>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Suger
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="suger" defaultValue={formValue? formValue.suger : ''} onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Fibers
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="fibers" defaultValue={formValue? formValue.fibers : ''} onKeyDown={isNumberKey}/>
                </label>
                </div>*/}


                <div style={{display: 'inline-block', width: '33%', padding: '2%'}}>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Fat
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="fat" defaultValue={formValue? formValue.fat : ''} onKeyDown={isNumberKey}/>
                </label>
                </div>
                <div style={{display: 'inline-block', width: '33%', padding: '2%'}}>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Protein
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="protein" defaultValue={formValue? formValue.protein : ''} onKeyDown={isNumberKey}/>
                </label>
                </div>
                <div style={{display: 'inline-block', width: '33%', padding: '2%'}}>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Carbs
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="carbs" defaultValue={formValue? formValue.carbs : ''} onKeyDown={isNumberKey}/>
                </label>
                </div>

                <Button variant="info" type="submit" className="float-right submit-button" style={{display: 'block', width: '100%'}}>
                    {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : 'Save'}
                </Button>
            </Form>
        </Modal>

        <Modal show={categoryForm} onHide={() => setCategoryForm(false)} size="sm">

            <Form style={{padding: '5%', fontSize: '.9em'}} onSubmit={(e) => {addCategory(e)}} className="food-form">

                <label style={{display: 'block', width: '100%', color: '#777'}}>Category
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="category"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Category (Arabic)
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="category_ar"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Ingredient
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="food" placeholder="Apple"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Ingredient (Arabic)
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="food_ar" placeholder="تفاحة"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Serving
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="serving" placeholder="Cup, tbsp, item .. etc."/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>Unit
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="unit" placeholder="g or ml"/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many g/ml per serving
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="unit_serving" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many calories per g/ml
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="calories_unit" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777'}}>How many calories per serving?
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="calories_serving" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Allergies (if any)
                    {/*<Input style={{display: 'block', width: '100%'}} name="allergies"/>*/}
                    <Select options={allergies} className="select" isMulti placeholder="none" value={selectedAllergies} onChange={(e) => {setSelectedAllergies(e)}}/>
                </label>

                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Fat
                    {/*<Input style={{display: 'block', width: '100%'}} name="allergies"/>*/}
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="fat" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Protein
                    {/*<Input style={{display: 'block', width: '100%'}} name="allergies"/>*/}
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="protein" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>fibers
                    {/*<Input style={{display: 'block', width: '100%'}} name="allergies"/>*/}
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="fibers" onKeyDown={isNumberKey}/>
                </label>
                <label style={{display: 'block', width: '100%', color: '#777', marginTop: '1em'}}>Suger
                    {/*<Input style={{display: 'block', width: '100%'}} name="allergies"/>*/}
                    <Input autoComplete="off" style={{display: 'block', width: '100%'}} required name="suger" onKeyDown={isNumberKey}/>
                </label>

                <Button variant="info" type="submit" className="float-right submit-button" style={{display: 'block', width: '100%'}}>
                    {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : 'Save'}
                </Button>
            </Form>
        </Modal>

        <Modal show={moveForm} onHide={() => setMoveForm(false)}>

            <Modal.Header style={{borderBottom: 'none'}} closeButton>
                <Modal.Title style={{fontSize: '20px', textAlign: 'center', width: '100%'}}>Move ingredient to another category</Modal.Title>
            </Modal.Header>

            <Form style={{padding: '5%', fontSize: '.9em'}} onSubmit={(e) => {moveItem(e)}}>

                <Form.Control required as="select" placeholder="Select Category" style={{cursor: 'pointer', width: '100%', display: 'block', marginBottom: '10px'}}
                    name="category"
                    //value={newCategory}
                    //onChange={(e) => {
                    //    setSelectedCategory(e.target.value);
                    //}}
                    >
                    {categories && categories.map((obj, i) => {
                        return (<option value={obj.category} key={obj.category} id={obj.category}>{obj.category} ({obj.category_ar})</option>)
                    })}
                </Form.Control>

                <Button variant="info" type="submit" className="float-right submit-button" style={{display: 'block', width: '100%'}}>
                    {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : 'Move'}
                </Button>
            </Form>
        </Modal>

    </>);
}