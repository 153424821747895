/** @format */

import React, { useEffect, useState } from "react";
import {
	Spinner,
	Breadcrumb,
	Image,
	Button,
	ToggleButton,
	Col,
	Row,
	Form,
	Modal,
} from "react-bootstrap";
import Chart from "react-apexcharts";
import { firestore } from "../../services/firebase";
import moment from "moment";
import * as _ from "lodash";
import { Input } from "@material-ui/core";

import { confirm } from "../../components/Confirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMinus,
	faPlus,
	faFolder,
	faFolderPlus,
	faTrashAlt,
	faClone,
	faShare,
	faShareSquare,
	faPencilAlt,
	faLink,
} from "@fortawesome/free-solid-svg-icons";

import Select from "react-select";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//import muscle_abs from '../../assets/icons/muscles_/abs.jpg';

function numberWithCommas(x) {
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

var currentCategory;

export function Exercises(props) {
	const [loader, setLoader] = useState(false);
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [categories, setCategories] = useState();
	const [selectedCategory, setSelectedCategory] = useState();
	const [itemForm, setItemForm] = useState();
	const [categoryForm, setCategoryForm] = useState();
	const [moveForm, setMoveForm] = useState();
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [searchKey, setSearchKey] = useState();
	const [equipment, setEquipment] = useState();
	const [selectedEquipment, setSelectedEquipment] = useState();

	const [levels, setLevels] = useState();
	const [selectedLevel, setSelectedLevel] = useState();
	const [muscles, setMuscles] = useState();
	const [selectedMuscle, setSelectedMuscle] = useState();
	const [types, setTypes] = useState();
	const [selectedType, setSelectedType] = useState();

	const [formValue, setFormValue] = useState();

	useEffect(() => {
		currentCategory = selectedCategory;
	}, [selectedCategory]);

	useEffect(() => {
		setLoader(true);
		var unsubscribes = getCollections();

		return () => {
			console.log("Do some cleanup");
			unsubscribes.map((unsubscribe) => unsubscribe());
		};
	}, []);

	function getCollections() {
		/*var insertItem = function(item){

            console.log(item)
            firestore("food").get(item)
        }

        food[0].map((d, i) => {

            var item = {
                allergies: d['Allergy tag']? d['Allergy tag'].split(', ') : [],
                calories_unit: d['Calories per gram or per ml'],
                calories_serving: d['Calories per serving'],
                category: d['Category'],
                category_ar: d['Category (AR)'],
                food: d['Food'],
                food_ar: d['Food (AR)'],
                serving: d['Serving'],
                unit: d['Unit'],
                unit_serving: d['gm or ml/ item'],
            }
            setTimeout(function(){ insertItem(item); }, i*1000)
        })
        console.log(food[0].length)
        */

		setLoading(true);

		const subscribe = firestore("exercise").onSnapshot(function (snapshot) {
			//.get().then(function(snapshot){

			const items = snapshot.docs.length
				? snapshot.docs.map((d) => {
						var obj = d.data();
						obj.id = d.id;
						return obj;
				  })
				: [];

			var categories = {},
				levels = {},
				muscles = {},
				types = {};
			items.map((d) => {
				if (!categories[d.muscle]) categories[d.muscle] = true;
				if (!levels[d.level]) levels[d.level] = true;
				if (!muscles[d.muscle]) muscles[d.muscle] = true;
				if (!types[d.type]) types[d.type] = true;
			});

			var equipment = {};
			items.map((d) => {
				if (d.equipment)
					d.equipment.split(", ").map((a) => {
						if (!equipment[a]) equipment[a] = true;
					});
			});
			setEquipment(
				Object.keys(equipment).map((a) => {
					return { value: a, label: a };
				})
			);

			setItems(_.sortBy(items, ["name"]));
			setCategories(
				_.sortBy(
					Object.keys(categories).map((d) => {
						return { category: d };
					}),
					"category"
				)
			);
			if (!currentCategory || !categories[currentCategory])
				setSelectedCategory(_.sortBy(items, ["category"])[0].category);

			setLevels(_.sortBy(Object.keys(levels)));
			setMuscles(_.sortBy(Object.keys(muscles)));
			setTypes(_.sortBy(Object.keys(types)));

			setLoading(false);
		});

		return [subscribe];
	}

	async function key_up(e, d, field, field_title) {
		var enterKey = 13; //Key Code for Enter Key
		if (e.which == enterKey) {
			var food_name = d.food;
			var org = d[field];
			d[field] =
				typeof d[field] === "number" ? +e.target.value : e.target.value;
			// console.log('change', food_name, field,'to', e.target.value)
			e.persist();
			if (
				await confirm(
					"Edit" + " " + "field",
					"are you sure you want to " +
						"change" +
						" " +
						food_name +
						" " +
						field_title +
						" to " +
						e.target.value +
						" ?",
					"save"
				)
			) {
				var update = {};
				update[field] =
					field === "allergies"
						? e.target.value
							? e.target.value.split(", ")
							: []
						: typeof d[field] === "number"
						? +e.target.value
						: e.target.value;
				await firestore("food").doc(d.id).update(update);
			} else {
				d[field] = org;
			}
		}
	}

	async function addItem(e) {
		e.stopPropagation();
		e.preventDefault();

		var name = e.target.name.value;
		var level = e.target.level.value;
		var muscle = e.target.muscle.value;
		var type = e.target.type.value;
		var equipment = selectedEquipment;
		var videoLink = e.target.videoLink.value;

		var item = {
			equipment: equipment
				? equipment
						.map((d) => d.value[0].toUpperCase() + d.value.slice(1))
						.join(", ")
				: "",
			name: name
				.split(" ")
				.map((d) => d[0].toUpperCase() + d.slice(1))
				.join(" "),
			level: level,
			muscle: muscle,
			type: type,
			videoLink: videoLink,
		};

		//if(items.filter(d => d.name === item.name).length) return alert('An exercise with the same name already exists')

		setIsSubmitLoader(true);

		var added =
			formValue && formValue.id
				? await firestore("exercise").doc(formValue.id).update(item)
				: await firestore("exercise").add(item);

		var goToAddedEl = function () {
			var div = document.getElementById(
				formValue && formValue.id ? formValue.id : added.id
			);

			if (div) {
				div.classList.add("new");
				window.scroll({ top: div.offsetTop - 50, left: 0, behavior: "smooth" });

				setTimeout(function () {
					div.classList.remove("new");
				}, 3000);
			} else {
				setTimeout(goToAddedEl, 1000);
			}
		};

		setIsSubmitLoader(false);
		setItemForm(false);
		goToAddedEl();
	}

	async function cloneItem(d) {
		var food_name = d.food;
		console.log("clone", food_name);

		var newName = prompt(
			"Please enter new Ingredient name",
			d.food + " (cloned)"
		);
		if (!newName) return;

		if (food_name === newName)
			return alert("An ingredient with the same name already exists");

		if (
			await confirm(
				"Clone" + " " + "Ingredient",
				"create a clone of " + food_name + " named " + newName + " ?",
				"clone"
			)
		) {
			var item = _.clone(d);
			item.food = newName;
			delete item.id;

			var added = await firestore("food").add(item);

			var goToAddedEl = function () {
				var div = document.getElementById(added.id);

				if (div) {
					div.classList.add("new");
					window.scroll({
						top: div.offsetTop - 50,
						left: 0,
						behavior: "smooth",
					});

					setTimeout(function () {
						div.classList.remove("new");
					}, 3000);
				} else {
					setTimeout(goToAddedEl, 1000);
				}
			};

			goToAddedEl();
		}
	}

	async function moveItem(e) {
		e.stopPropagation();
		e.preventDefault();

		setMoveForm(false);

		var c = categories.filter((d) => d.category === e.target.category.value)[0]; //prompt("Please enter Ingredient name"); if(!food) return
		var d = _.clone(moveForm);

		var food_name = d.food;
		console.log("move", food_name);

		//var category = categories[parseInt(prompt("Please select new category \n" + categories.map((c, i) => String(i+1)+'. '+c.category+' ('+c.category_ar+')').join('\n'), '1')) - 1];
		//if(!category) return

		if (
			await confirm(
				"Move" + " " + "Ingredient",
				"are you sure you want to " +
					"move" +
					" " +
					food_name +
					" to " +
					c.category +
					" ?",
				"move"
			)
		) {
			await firestore("food")
				.doc(d.id)
				.update({ category: c.category, category_ar: c.category_ar });

			var goToAddedEl = function () {
				var cat = document.getElementById(c.category);

				if (cat) {
					setSelectedCategory(c.category);

					var div = document.getElementById(d.id);

					if (div) {
						div.classList.add("new");
						window.scroll({
							top: div.offsetTop - 50,
							left: 0,
							behavior: "smooth",
						});

						setTimeout(function () {
							div.classList.remove("new");
						}, 3000);
					} else {
						setTimeout(goToAddedEl, 1000);
					}
				} else {
					setTimeout(goToAddedEl, 1000);
				}
			};

			goToAddedEl();
		}
	}

	async function removeItem(d) {
		if (
			await confirm(
				"Remove" + " " + "Ingredient",
				"are you sure you want to " + "remove" + " " + d.name + " ?",
				"remove"
			)
		) {
			await firestore("exercise").doc(d.id).delete();
		}
	}

	function isNumberKey(evt) {
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (
			charCode != 46 &&
			charCode > 31 &&
			(charCode < 48 || charCode > 57) &&
			charCode != 190
		)
			return evt.preventDefault();

		return true;
	}

	function filterFn(d) {
		if (searchKey)
			return d.name.toLowerCase().includes(searchKey.toLowerCase());

		return (
			(selectedLevel ? d.level === selectedLevel : true) &&
			(selectedMuscle ? d.muscle === selectedMuscle : true) &&
			(selectedType ? d.type === selectedType : true)
		);
	}

	return (
		<>
			<Breadcrumb className="pb-3">
				<Breadcrumb.Item active>Exercises</Breadcrumb.Item>

				{levels && muscles && types && (
					<>
						<Breadcrumb.Item active>
							<div
								className="selectWrapper"
								style={{ position: "absolute", top: "5px", marginLeft: "10px" }}
							>
								<Form.Control
									required
									as="select"
									style={{
										cursor: "pointer",
										width: "auto",
										margin: "0 3px",
										display: "inline-block",
										color: "#777",
										fontSize: ".9em",
										opacity: searchKey ? 0.1 : 1,
										pointerEvents: searchKey ? "none" : "auto",
									}}
									defaultValue={""}
									onChange={(e) => {
										setSelectedLevel(e.target.value);
									}}
								>
									<option value="" disabled>
										Level
									</option>
									<option value={""} key={"all"} id={"all"}>
										{" "}
										-- All --{" "}
									</option>
									{levels.map((obj, i) => {
										return (
											<option value={obj} key={obj} id={obj}>
												{obj}
											</option>
										);
									})}
								</Form.Control>

								<Form.Control
									required
									as="select"
									style={{
										cursor: "pointer",
										width: "auto",
										margin: "0 3px",
										display: "inline-block",
										color: "#777",
										fontSize: ".9em",
										opacity: searchKey ? 0.1 : 1,
										pointerEvents: searchKey ? "none" : "auto",
									}}
									defaultValue={""}
									onChange={(e) => {
										setSelectedMuscle(e.target.value);
									}}
								>
									<option value="" disabled>
										Muscle group
									</option>
									<option value={""} key={"all"} id={"all"}>
										{" "}
										-- All --{" "}
									</option>
									{muscles.map((obj, i) => {
										return (
											<option value={obj} key={obj} id={obj}>
												{obj}
											</option>
										);
									})}
								</Form.Control>

								<Form.Control
									required
									as="select"
									style={{
										cursor: "pointer",
										width: "auto",
										margin: "0 3px",
										display: "inline-block",
										color: "#777",
										fontSize: ".9em",
										opacity: searchKey ? 0.1 : 1,
										pointerEvents: searchKey ? "none" : "auto",
									}}
									defaultValue={""}
									onChange={(e) => {
										setSelectedType(e.target.value);
									}}
								>
									<option value="" disabled>
										Type
									</option>
									<option value={""} key={"all"} id={"all"}>
										{" "}
										-- All --{" "}
									</option>
									{types.map((obj, i) => {
										return (
											<option value={obj} key={obj} id={obj}>
												{obj}
											</option>
										);
									})}
								</Form.Control>

								<Form.Control
									placeholder="search"
									style={{
										cursor: "pointer",
										width: "auto",
										display: "inline-block",
										marginLeft: "10px",
										fontSize: ".9em",
									}}
									value={searchKey}
									onChange={(e) => {
										setSearchKey(e.target.value);
									}}
								></Form.Control>
							</div>
						</Breadcrumb.Item>

						<div className="no-users">
							{/*<div style={{opacity: .8, cursor: 'pointer', display:'inline-block', marginRight:'30px'}} onClick={()=>{setSelectedEquipment(); setCategoryForm(true)}}>Add Category<FontAwesomeIcon style={{}} icon={faFolderPlus} className="ml-2"/></div>*/}
							<div
								style={{
									opacity: 0.8,
									cursor: "pointer",
									display: "inline-block",
								}}
								onClick={() => {
									setFormValue();
									setSelectedEquipment([
										{ value: "Body Only", label: "Body Only" },
									]);
									setItemForm(true);
								}}
							>
								Add Exercise
								<FontAwesomeIcon style={{}} icon={faPlus} className="ml-2" />
							</div>
						</div>
					</>
				)}
			</Breadcrumb>

			{loading ? (
				<div className="text-center">
					<Spinner
						animation="border"
						size={"sm"}
						role="status"
						style={{ marginTop: "10em" }}
					/>
				</div>
			) : (
				<table
					id="transactions"
					className="table components"
					style={{ marginTop: "5em", fontSize: "15px" }}
				>
					<thead>
						<tr>
							<th title="exercise level">Level</th>
							<th title="exercise name">Exercise</th>
							<th title="exercise link"></th>
							{/*<th></th>*/}
							<th title="main muscle group">Muscle</th>
							<th title="exercise type">Type</th>
							<th title="required equipment">Equipment</th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{items.filter(filterFn).map((d) => (
							<tr key={d.id} id={d.id} className="food-item">
								<td style={{ opacity: 0.6 }}>{d.level}</td>
								<td style={{}}>{d.name}</td>
								<td>
									{d.videoLink && (
										<FontAwesomeIcon icon={faLink} style={{ opacity: 0.5 }} />
									)}
								</td>
								{/*<td><img src={muscle_abs} height="20px" className="link-icon"/></td>*/}
								<td>{d.muscle}</td>
								<td>{d.type}</td>
								<td>{d.equipment}</td>
								<td
									style={{ opacity: 0.1, transition: ".3s" }}
									className="remove"
									onClick={() => {
										setSelectedEquipment(
											d.equipment.split(", ").map((d) => {
												return { label: d, value: d };
											})
										);
										setFormValue(d);
										setItemForm(true);
									}}
								>
									<FontAwesomeIcon icon={faPencilAlt} />
								</td>
								<td
									style={{ opacity: 0.1, transition: ".3s" }}
									className="remove"
									onClick={() => {
										var clone = _.clone(d);
										delete clone.id;
										clone.name += " (cloned)";
										setSelectedEquipment(
											clone.equipment.split(", ").map((d) => {
												return { label: d, value: d };
											})
										);
										setFormValue(clone);
										setItemForm(true);
									}}
								>
									<FontAwesomeIcon icon={faClone} />
								</td>
								{/*<td style={{opacity:0.1, transition: '.3s'}} className="remove" onClick={()=>{setMoveForm(d)}}><FontAwesomeIcon icon={faShareSquare} /></td>*/}
								<td
									style={{ opacity: 0.1, transition: ".3s" }}
									className="remove"
									onClick={() => {
										removeItem(d);
									}}
								>
									<FontAwesomeIcon icon={faTrashAlt} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}

			<Modal show={itemForm} onHide={() => setItemForm(false)} size="sm">
				<Form
					style={{ padding: "5%", fontSize: ".9em" }}
					onSubmit={(e) => {
						addItem(e);
					}}
					className="food-form"
				>
					<label
						style={{
							display: "block",
							width: "100%",
							color: "#777",
							marginBottom: "1.5em",
						}}
					>
						Exercise
						<Input
							autoComplete="off"
							style={{ display: "block", width: "100%" }}
							required
							name="name"
							placeholder="Pull Ups"
							defaultValue={formValue ? formValue.name : ""}
						/>
					</label>
					<label style={{ display: "block", width: "100%", color: "#777" }}>
						Level
						<Form.Control
							required
							as="select"
							style={{ color: "#777", fontSize: ".9em" }}
							name="level"
							defaultValue={formValue ? formValue.level : ""}
						>
							{levels &&
								levels.map((obj, i) => {
									return (
										<option value={obj} key={obj} id={obj}>
											{obj}
										</option>
									);
								})}
						</Form.Control>
					</label>
					<label style={{ display: "block", width: "100%", color: "#777" }}>
						Main Muscle Group
						<Form.Control
							required
							as="select"
							style={{ color: "#777", fontSize: ".9em" }}
							name="muscle"
							defaultValue={formValue ? formValue.muscle : ""}
						>
							{muscles &&
								muscles.map((obj, i) => {
									return (
										<option value={obj} key={obj} id={obj}>
											{obj}
										</option>
									);
								})}
						</Form.Control>
					</label>
					<label style={{ display: "block", width: "100%", color: "#777" }}>
						Type
						<Form.Control
							required
							as="select"
							style={{ color: "#777", fontSize: ".9em" }}
							name="type"
							defaultValue={formValue ? formValue.type : ""}
						>
							{types &&
								types.map((obj, i) => {
									return (
										<option value={obj} key={obj} id={obj}>
											{obj}
										</option>
									);
								})}
						</Form.Control>
					</label>
					<label
						style={{
							display: "block",
							width: "100%",
							color: "#777",
							marginTop: "1em",
						}}
					>
						Equipment
						{/*<Input style={{display: 'block', width: '100%'}} name="allergies"/>*/}
						<Select
							options={equipment}
							className="select"
							isMulti
							required
							placeholder=""
							defaultValue={selectedEquipment}
							onChange={(e) => {
								setSelectedEquipment(e);
							}}
						/>
					</label>
					<label
						style={{
							display: "block",
							width: "100%",
							color: "#777",
							marginBottom: "1.5em",
						}}
					>
						Video Link
						<Input
							autoComplete="off"
							style={{ display: "block", width: "100%" }}
							name="videoLink"
							type="url"
							placeholder="youtube link"
							defaultValue={formValue ? formValue.videoLink : ""}
						/>
					</label>

					<Button
						variant="info"
						type="submit"
						className="float-right submit-button"
						style={{ display: "block", width: "100%" }}
					>
						{isSubmitLoader ? (
							<Spinner animation="border" size={"sm"} role="status" />
						) : (
							"Save"
						)}
					</Button>
				</Form>
			</Modal>

			<Modal show={moveForm} onHide={() => setMoveForm(false)}>
				<Modal.Header style={{ borderBottom: "none" }} closeButton>
					<Modal.Title
						style={{ fontSize: "20px", textAlign: "center", width: "100%" }}
					>
						Move ingredient to another category
					</Modal.Title>
				</Modal.Header>

				<Form
					style={{ padding: "5%", fontSize: ".9em" }}
					onSubmit={(e) => {
						moveItem(e);
					}}
				>
					<Form.Control
						required
						as="select"
						placeholder="Select Category"
						style={{
							cursor: "pointer",
							width: "100%",
							display: "block",
							marginBottom: "10px",
						}}
						name="category"
						//value={newCategory}
						//onChange={(e) => {
						//    setSelectedCategory(e.target.value);
						//}}
					>
						{categories &&
							categories.map((obj, i) => {
								return (
									<option
										value={obj.category}
										key={obj.category}
										id={obj.category}
									>
										{obj.category} ({obj.category_ar})
									</option>
								);
							})}
					</Form.Control>

					<Button
						variant="info"
						type="submit"
						className="float-right submit-button"
						style={{ display: "block", width: "100%" }}
					>
						{isSubmitLoader ? (
							<Spinner animation="border" size={"sm"} role="status" />
						) : (
							"Move"
						)}
					</Button>
				</Form>
			</Modal>
		</>
	);
}
