/** @format */

import React, { useEffect, useState } from "react";
import "./AddEditChallenge.scss";

import {
	Breadcrumb,
	Button,
	Col,
	Dropdown,
	DropdownButton,
	Modal,
	Form,
	InputGroup,
	Row,
	Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imageCompression from "browser-image-compression";
import DatePicker from "react-datepicker";
import firebase from 'firebase/compat/app';
import * as _ from "lodash";
import moment from "moment";
import { NavLink } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { firestore, storage } from "../../../../services/firebase";
import {
	faPlusCircle,
	faMinusCircle,
} from "@fortawesome/free-solid-svg-icons/index";
import {
	fileDragHover,
	fileDragHover2,
	parseFile,
	parseFile2,
} from "../../../../functions/helpers";
import { Header } from "../../../../components/Header";
export function AddEditChallenge(props) {
	const [role, setRole] = useState({});
	const [isImageChanged, setIsImageChanged] = useState(false);
	const [isImageChanged2, setIsImageChanged2] = useState(false);
	const [nameError, setNameError] = useState("");
	const [durationError, setDurationError] = useState("");
	const [featuresError, setFeaturesError] = useState("");
	const [goalError, setGoalError] = useState("");
	const [bundleError, setBundleError] = useState("");
	const totalDays = 30;
	const durationTypes = ["Days", "Weeks", "Months"];
	const graceTypes = ["Days"];
	const actions = ["Add any meal", "Do any workout", "Drink any water"];

	const features = [
		{ key: "mealPlan", title: "Meal plan", required: false },
		{ key: "workoutPlan", title: "Workout plan", required: false },
		{ key: "liveSessions", title: "Live sessions", required: false },
		//{ key: 'groupSupport', title: 'Group support', required: true },
		//{ key: 'chatSupport', title: 'Chat support', required: true },
		{ key: "chatSupport", title: "Group chat", required: true },
		{ key: "askQuestions", title: "Questions", required: true },
		{ key: "leaderBoard", title: "Leaderboard", required: true },
		{ key: "statistics", title: "Performance statistics", required: true },
		{ key: "prayers", title: "Prayers", required: false },
	];

	const dietTypes = [
		{ key: "Standard", title: "Standard (I enjoy eating everything)" },
		{ key: "Plant Based", title: "Plant Based (I avoid animal products)" },
		{ key: "Pescatarian", title: "Pescatarian (I avoid meat but i eat fish)" },
		{ key: "Low Carb", title: "Low Carb (I minimize carb intake)" },
		{ key: "Keto", title: "Keto (I avoid any carb intake)" },
	];

	const exerciseTypes = [
		{ key: "home", title: "Home workout" },
		{ key: "gym", title: "Gym workout" },
	];

	const goalOptions = [
		{ key: "Weight loss", title: "Weight loss (Lose fat and build muscle)" },
		{ key: "Muscle gain", title: "Muscle gain (Build mass and strength)" },
		{
			key: "Healthy lifestyle",
			title: "Healthy lifestyle (Eat healthy and stay fit)",
		},
	];

	const bundles = [
		{ key: 1, title: "1 month", required: true },
		{ key: 2, title: "2 months" },
		{ key: 3, title: "3 months" },
		{ key: 6, title: "6 months" },
		{ key: 12, title: "1 year" },
	];

    const [bundleDiscount, setBundleDiscount] = useState({
        1: 0,
        2: 15,
        3: 27
    });

	const [imageFile, setImageFile] = useState(false);
	const [imageError, setImageError] = useState("");
	const [challengeImage, setChallengeImage] = useState("");

	const [imageFile2, setImageFile2] = useState(false);
	const [imageError2, setImageError2] = useState("");
	const [sponsorImage, setSponsorImage] = useState("");
	// const [challengeDetail, setChallengeDetail] = useState('');

	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
	const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
	const [showBundleConfirmation, setShowBundleConfirmation] = useState(false);
	const initFormValue = {
		videoLink: "",
		name: "",
		duration: "",
		durationType: "Weeks",
		grace: 0,
		image: "",
		price: null,
		price_usd: null,
		price_usd_iap: null,
		equipmentPrice: null,
		features: {},
		dietTypes: {},
		exerciseTypes: {},
		goal: {},
		reminders: [],
		questions: [],
		faq: [],
		isPublished: false,
		isPrivate: false,
		date: new Date(),
	};
	const [formValue, setFormValue] = useState(initFormValue);
	const [challengeId, setChallengeId] = useState("");
	const [webLink, setWebLink] = useState();

	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);

	useEffect(() => {
		const adminDetailsStorage = JSON.parse(
			localStorage.getItem("adminDetails")
		);
		if (adminDetailsStorage && adminDetailsStorage.role) {
			var _role = {};
			if (adminDetailsStorage.role.includes("assistant"))
				_role.assistant = true;
			if (adminDetailsStorage.role.includes("collaborator"))
				_role.collaborator = true;
			if (adminDetailsStorage.role.includes("nutritionist"))
				_role.nutritionist = true;
			if (adminDetailsStorage.role.includes("admin")) _role.admin = true;
			setRole(_role);
		}

		if (props.match.params && props.match.params.id) {
			setChallengeId(props.match.params.id);
			getChallengeData(props.match.params.id);
		} else {
			setFormData();
		}
		return () => {};
	}, []);

	useEffect(() => {
		firestore("constants")
			.doc("app")
			.get()
			.then((doc) => {
				setWebLink(
					{ welnes: "https://welnes.app", gorilla: "https://gorilla.fitness" }[
						doc.data().value
					]
				);
			});

		return () => {};
	}, []);

	async function getChallengeData(challengeId) {
		let challengeDetails;
		const querySnapshot = await firestore("challenges").doc(challengeId).get();
		challengeDetails = querySnapshot.data();
		await getFaq(challengeId, challengeDetails);
		await getQuestions(challengeId, challengeDetails);
		await getReminders(challengeId, challengeDetails);
	}

	async function getReminders(challengeId, challengeDetail) {
		firestore("challenges")
			.doc(challengeId)
			.collection("reminders")
			.onSnapshot(async (querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				challengeDetail.reminders = data;
				// setChallengeDetail(challengeDetail);
				setFormData(challengeDetail);
			});
	}

	async function getFaq(challengeId, challengeDetail) {
		firestore("challenges")
			.doc(challengeId)
			.collection("faq")
			.onSnapshot(async (querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				challengeDetail.faq = data;
				// setChallengeDetail(challengeDetail);
				setFormData(challengeDetail);
			});
	}

	async function getQuestions(challengeId, challengeDetail) {
		firestore("challenges")
			.doc(challengeId)
			.collection("questions")
			.onSnapshot(async (querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				challengeDetail.questions = data;
				// setChallengeDetail(challengeDetail);
				setFormData(challengeDetail);
			});
	}

	function setFormData(challengeDetail) {
		let formValueClone = challengeDetail ? challengeDetail : initFormValue;
		if (challengeDetail) {
			setChallengeImage(challengeDetail?.image);
			document.getElementById("file-image").classList.remove("hidden");

			setSponsorImage(challengeDetail?.sponsor);
			if (challengeDetail.sponsor && document.getElementById("file-image2"))
				document.getElementById("file-image2").classList.remove("hidden");
		} else {
			/*for (let i = 0; i < 1; i++) {
                formValueClone.reminders.push({
                    action: '',
                    day: '',
                    message: '',
                    ar: {
                        message: ''
                    }
                });
            }
            for (let i = 0; i < 1; i++) {
                formValueClone.questions.push({
                    question: '',
                    answer: '',
                });
            }*/
		}
		setFormValue(_.clone(formValueClone));
	}

	function onImageUpload(e) {
		fileSelectHandler(e);
	}
	function onImageUpload2(e) {
		fileSelectHandler2(e);
	}

	async function submitForm(e) {
		e.stopPropagation();
		e.preventDefault();

		setIsSubmitLoader(true);

		const dataToSubmit = _.cloneDeep(formValue);
		// if (isImageChanged && imageFile) {
		const fileName =
			dataToSubmit.name.replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "_") +
			"-" +
			moment().unix();
		if (isImageChanged && imageFile) {
			const uploaded = await storage()
				.ref()
				.child(`/challenges/${fileName}`)
				.put(imageFile, { cacheControl: "public,max-age=31536000" });
			const child = await storage().ref().child(uploaded.metadata.fullPath);
			dataToSubmit.image = await child.getDownloadURL();
			dataToSubmit.fullPath = uploaded.metadata.fullPath;
		} else if (!challengeId) {
			setImageError("image is required");
			setIsSubmitLoader(false);
		}
		const fileName2 = "sponsor-" + moment().format();
		if (isImageChanged2 && imageFile2) {
			const uploaded = await storage()
				.ref()
				.child(`/challenges/${fileName2}`)
				.put(imageFile2, { cacheControl: "public,max-age=31536000" });
			const child = await storage().ref().child(uploaded.metadata.fullPath);
			dataToSubmit.sponsor = await child.getDownloadURL();
			//dataToSubmit.sponsorFullPath = uploaded.metadata.fullPath;
		} else if (isImageChanged2) {
			//remove sponsor image
			dataToSubmit.sponsor = "";
		} else {
		}
		/*var atLeastOne = false;
		for (var key in dataToSubmit.features)
			if (
				dataToSubmit.features.mealPlan === true ||
				dataToSubmit.features.workoutPlan === true
			)
				atLeastOne = true;
		if (!atLeastOne) {
			setIsSubmitLoader(false);
			setFeaturesError("at least one feature is required");
			return;
		}*/
		var atLeastOne = false;
		for (var key in dataToSubmit.goal)
			if (dataToSubmit.goal[key] === true) atLeastOne = true;
		if (!atLeastOne) {
			setIsSubmitLoader(false);
			setGoalError("at least one user goal is required");
			return alert("at least one supported user goal is required");
		}

		var atLeastOne = false;
		for (var key in dataToSubmit.dietTypes)
			if (dataToSubmit.dietTypes[key] === true) atLeastOne = true;
		if (dataToSubmit.features.mealPlan && !atLeastOne) {
			setIsSubmitLoader(false);
			return alert("at least one supported diet type is required");
		}

		var atLeastOne = false;
		for (var key in dataToSubmit.exerciseTypes)
			if (dataToSubmit.exerciseTypes[key] === true) atLeastOne = true;
		if (dataToSubmit.features.workoutPlan && !atLeastOne) {
			setIsSubmitLoader(false);
			return alert("at least one supported exercise type is required");
		}

		if (dataToSubmit.isBundleable && (!dataToSubmit.bundles || Object.keys(dataToSubmit.bundles).length <= 1)) {
			setIsSubmitLoader(false);
			setBundleError("at least one more bundle is required");
			return alert("at least one more bundle is required");
		}

		if (!dataToSubmit.price && !admin.role.includes("admin")) {setIsSubmitLoader(false); return alert("please fill in challenge price");}
		if (!dataToSubmit.name) {setIsSubmitLoader(false); return alert("Name is required, please fill in name");}
		if (!dataToSubmit.image) {setIsSubmitLoader(false); return alert("Image is required");}
		if (!dataToSubmit.duration) {setIsSubmitLoader(false); return alert("Duration is required, please fill in duration");}
		if (Object.keys(dataToSubmit.goal).length === 0) {setIsSubmitLoader(false); return alert("at least one goal must be supported");}
		if (!dataToSubmit.features.mealPlan && !dataToSubmit.features.workoutPlan) {setIsSubmitLoader(false); setFeaturesError("either a meal plan or a workout plan must be supported"); return; }

		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		dataToSubmit.nutritionistId = userDetails.id;
		dataToSubmit.nutritionistName = userDetails.name;
		dataToSubmit.nutritionistImage = userDetails.image;
		dataToSubmit.isFree = !dataToSubmit.price ? true : false;
		dataToSubmit.price = dataToSubmit.price ? Number(dataToSubmit.price) : 0;
		dataToSubmit.price_usd = dataToSubmit.price_usd ? Number(dataToSubmit.price_usd) : 0;
		dataToSubmit.price_usd_iap = dataToSubmit.price_usd_iap ? Number(dataToSubmit.price_usd_iap) : 0;
		//dataToSubmit.price_usd = dataToSubmit.price ? Math.floor(Number(dataToSubmit.price)/16.5) : 0;
		dataToSubmit.equipmentPrice = dataToSubmit.equipmentPrice
			? Number(dataToSubmit.equipmentPrice)
			: 0;

		function toTimestamp(str){
			const dateString = str.split('T')[0];
			const dateParts = dateString.split('-');
			const year = parseInt(dateParts[0]);
			const month = parseInt(dateParts[1]) - 1; // Month is 0-based in JavaScript
			const day = parseInt(dateParts[2]);
			const myDate = new Date(year, month, day);
			const myTimestamp = firebase.firestore.Timestamp.fromDate(myDate);
			return myTimestamp
		}

		dataToSubmit.date = moment(dataToSubmit.date).format("YYYY-MM-DDT00:00:00");
		dataToSubmit.startDate = toTimestamp(dataToSubmit.date);
		dataToSubmit.endDate = toTimestamp(moment(dataToSubmit.date).add(dataToSubmit.duration, dataToSubmit.durationType.toLowerCase()).format("YYYY-MM-DDT00:00:00"));

		const durationType = dataToSubmit.durationType
			.replace("s", "")
			.toLowerCase();
		dataToSubmit.endDatetime = moment(dataToSubmit.date)
			.add(dataToSubmit.duration, durationType)
			.format("YYYY-MM-DDT00:00:00");
		const reminders = _.clone(dataToSubmit.reminders);
		const questions = _.clone(dataToSubmit.questions);
		const faq = _.clone(dataToSubmit.faq);
		let newChallengeId = _.clone(challengeId);
		if (dataToSubmit.reminders) {
			//delete dataToSubmit.reminders;
		}
		if (dataToSubmit.questions) {
			delete dataToSubmit.questions;
		}
		if (dataToSubmit.faq) {
			delete dataToSubmit.faq;
		}
		if (challengeId) {
			await firestore("challenges").doc(challengeId).update(dataToSubmit);
		} else {
			if(dataToSubmit.isPublished) dataToSubmit.notification = 'send';
			dataToSubmit.userCount = 0;
			dataToSubmit.groupCount = 0;
			dataToSubmit.commentCount = 0;
			dataToSubmit.weightLost = 0;
			dataToSubmit.likeCount = 0;
			dataToSubmit.mealCount = 0;
			dataToSubmit.questionCount = 0;
			dataToSubmit.exerciseCount = 0;
			dataToSubmit.waterCupCount = 0;
			dataToSubmit.datetime = moment().format();
			const newChallange = await firestore("challenges").add(dataToSubmit);
			newChallengeId = newChallange.id;
		}
		if (reminders && reminders.length) {
			const reminderRef = firestore("challenges")
				.doc(newChallengeId)
				.collection("reminders");
			await Promise.all(
				reminders.map(async (reminder) => {
					if (reminder.key) {
						await reminderRef.doc(reminder.key).update(reminder);
					} else {
						await reminderRef.add(reminder);
					}
				})
			);
		}
		if (faq && faq.length) {
			const questionRef = firestore("challenges")
				.doc(newChallengeId)
				.collection("faq");
			await Promise.all(
				faq.map(async (question) => {
					if (question.key) {
						await questionRef.doc(question.key).update(question);
					} else {
						await questionRef.add(question);
					}
				})
			);
		}
		if (questions && questions.length) {
			const questionRef = firestore("challenges")
				.doc(newChallengeId)
				.collection("questions");
			await Promise.all(
				questions.map(async (question) => {
					if (question.key) {
						await questionRef.doc(question.key).update(question);
					} else {
						await questionRef.add(question);
					}
				})
			);
		}
		setIsSubmitLoader(false);
		props.history.push("/profile");
	}

	function addMoreFaq() {
		const formValueClone = _.clone(formValue);
		if (!formValueClone.faq) formValueClone.faq = [];
		formValueClone.faq[formValueClone.faq.length] = {
			question: "",
			answer: "",
		};
		setFormValue(formValueClone);
	}

	function addMoreQuestions() {
		const formValueClone = _.clone(formValue);
		if (!formValueClone.questions) formValueClone.questions = [];
		formValueClone.questions[formValueClone.questions.length] = {
			question: "",
			answer: "",
		};
		setFormValue(formValueClone);
	}

	function addMoreReminders() {
		const formValueClone = _.clone(formValue);
		if (!formValueClone.reminders) formValueClone.reminders = [];
		formValueClone.reminders[formValueClone.reminders.length] = {
			name: "",
			every: "",
		};
		setFormValue(formValueClone);
	}

	async function fileSelectHandler(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;
		// Cancel event and hover styling
		fileDragHover(e);

		// Process all File objects
		for (var i = 0, f; (f = files[i]); i++) {
			var options = {
				maxSizeMB: 0.3,
				maxWidthOrHeight: 400,
			};
			try {
				const compressedFile = f; //await imageCompression(f, options);
				setImageError("");
				setImageFile(compressedFile);
				parseFile(compressedFile);
			} catch (error) {
				console.log("error fileSelectHandler", error);
				setImageError("please upload a valid image file");
			}
		}
		setIsImageChanged(true);
	}

	async function fileSelectHandler2(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;
		// Cancel event and hover styling
		fileDragHover2(e);

		// Process all File objects
		for (var i = 0, f; (f = files[i]); i++) {
			var options = {
				maxSizeMB: 0.3,
				maxWidthOrHeight: 400,
			};
			try {
				const compressedFile = f; //await imageCompression(f, options);
				setImageError2("");
				setImageFile2(compressedFile);
				parseFile2(compressedFile);
			} catch (error) {
				console.log("error fileSelectHandler2", error);
				setImageError2("please upload a valid sponsor image file");
			}
		}
		setIsImageChanged2(true);
	}

	async function removeFaq(i, key) {
		if (challengeId && key) {
			const reminderRef = firestore("challenges")
				.doc(challengeId)
				.collection("faq");
			await reminderRef.doc(key).delete();
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.faq.splice(i, 1);
			setFormValue(formValueClone);
		}
	}

	async function removeQuestion(i, key) {
		if (challengeId && key) {
			const reminderRef = firestore("challenges")
				.doc(challengeId)
				.collection("questions");
			await reminderRef.doc(key).delete();
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.questions.splice(i, 1);
			setFormValue(formValueClone);
		}
	}

	async function removeReminder(i, key) {
		if (challengeId && key) {
			const reminderRef = firestore("challenges")
				.doc(challengeId)
				.collection("reminders");
			await reminderRef.doc(key).delete();
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.reminders.splice(i, 1);
			setFormValue(formValueClone);
		}
	}

	async function onSelectPublish(checkBoxValue) {
		if (checkBoxValue) {
			setShowPublishConfirmation(true);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isPublished = false;
			setFormValue(formValueClone);
		}
	}

	async function onSelectClose(checkBoxValue) {
		if (checkBoxValue) {
			setShowCloseConfirmation(true);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isPrivate = false;
			setFormValue(formValueClone);
		}
	}

	async function onSelectBundle(checkBoxValue) {
		if (checkBoxValue) {
			setShowBundleConfirmation(true);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isBundleable = false;
			setFormValue(formValueClone);
		}
	}

	/* Publish challenge after confirmation */
	async function handleConfirmation(callback) {
		if (callback) {
			const formValueClone = _.clone(formValue);
			formValueClone.isPublished = callback;
			console.log("called : ", formValueClone.isPublished);

			setFormValue(formValueClone);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isPublished = false;
			console.log("called : ", formValueClone.isPublished);
			setFormValue(formValueClone);
		}
		setShowPublishConfirmation(false);
	}

	/* Close challenge after confirmation */
	async function handleConfirmationClose(callback) {
		if (callback) {
			const formValueClone = _.clone(formValue);
			formValueClone.isPrivate = callback;
			console.log("called : ", formValueClone.isPrivate);

			setFormValue(formValueClone);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isPrivate = false;
			console.log("called : ", formValueClone.isPrivate);
			setFormValue(formValueClone);
		}
		setShowCloseConfirmation(false);
	}

	/* Bundle challenge after confirmation */
	async function handleConfirmationBundle(callback) {
		if (callback) {
			const formValueClone = _.clone(formValue);
			formValueClone.isBundleable = callback;
			console.log("called : ", formValueClone.isBundleable);

			setFormValue(formValueClone);
		} else {
			const formValueClone = _.clone(formValue);
			formValueClone.isBundleable = false;
			console.log("called : ", formValueClone.isBundleable);
			setFormValue(formValueClone);
		}
		setShowBundleConfirmation(false);
	}

	return (
		<div>
			<Header
				header="Challenges"
				subHeader={formValue.datetime ? "Edit Challenge" : "Add new Challenge"}
			/>
			<hr />
			<div style={{ paddingBottom: "10em" }}>
				<Form className="form" onSubmit={(e) => submitForm(e)}>
					<h4 className="m-0 d-flex justify-content-between">
						{" "}
						Challenge Info{" "}
					</h4>

					<Form.Row>
						<Col xs={role.admin ? 6 : 12}>
							<div className="d-flex photo-container">
								<div className="uploader" style={{ marginBottom: "15px" }}>
									<h6>Challenge Image*</h6>
									<input
										id="file-upload"
										type="file"
										name="fileUpload"
										accept="image/*"
										onChange={(e) => onImageUpload(e)}
									/>
									<label
										style={{ height: "100%" }}
										htmlFor="file-upload"
										id="file-drag"
										onDragOver={(e) => {
											fileDragHover(e);
										}}
										onDragLeave={(e) => {
											fileDragHover(e);
										}}
										onDrop={(e) => {
											fileSelectHandler(e);
										}}
									>
										<div className="start-container">
											<img
												id="file-image"
												src={challengeImage}
												alt=""
												className="hidden"
												width={"auto"}
											/>
											<div id="start">
												<p style={{ marginTop: "20px" }}>
													Select an image or drag here
												</p>
												<div
													className="uploader-container"
													style={{ margin: "20px" }}
												>
													<span
														id="file-upload-btn"
														className="button button-secondary"
													>
														Select an image
													</span>
												</div>
											</div>
											{imageError && (
												<div className="m-2 text-center text-danger">
													{imageError}
												</div>
											)}
											<div id="response" className="hidden">
												<div id="messages"></div>
											</div>
										</div>
									</label>
								</div>
							</div>
						</Col>
						{role.admin ? (
							<>
								<Col xs={6}>
									<div
										className="d-flex photo-container"
										style={{ height: "94%" }}
									>
										<div className="uploader">
											<h6>Sponsor Logo</h6>
											<input
												id="file-upload2"
												type="file"
												name="fileUpload2"
												accept="image/*"
												onChange={(e) => onImageUpload2(e)}
											/>
											<label
												style={{ height: "100%" }}
												htmlFor="file-upload2"
												id="file-drag2"
												onDragOver={(e) => {
													fileDragHover2(e);
												}}
												onDragLeave={(e) => {
													fileDragHover2(e);
												}}
												onDrop={(e) => {
													fileSelectHandler2(e);
												}}
											>
												<div
													className="start2-container"
													style={{ height: "inherit" }}
												>
													<img
														id="file-image2"
														src={sponsorImage}
														alt=""
														className="hidden"
														width={"auto"}
													/>
													<div id="start2">
														<p style={{ marginTop: "20px" }}>or drag here</p>
														<div
															className="uploader-container"
															style={{ margin: "20px" }}
														>
															<span
																id="file-upload-btn2"
																className="button button-secondary"
															>
																Select Sponsor logo
															</span>

															{sponsorImage && (
																<>
																	<div
																		style={{
																			width: "100%",
																			textAlign: "center",
																			marginTop: "10px",
																		}}
																	>
																		<span
																			style={{
																				borderRadius: "25px",
																				// padding: " 0 25px",
																			}}
																			onClick={(e) => {
																				e.preventDefault();
																				setIsImageChanged2(true);
																				setSponsorImage("");
																				setImageFile2(false);
																			}}
																			className="button btn btn-dark"
																		>
																			<p>Remove Sponsor</p>
																		</span>
																	</div>
																</>
															)}
														</div>
													</div>

													<Row style={{ margin: "1.3em" }}>
														<Col xs={6}>
															<Form.Label
																style={{
																	border: "none",
																	padding: "0",
																}}
															>
																Sponsor caption
															</Form.Label>
															<Form.Control
																value={formValue.sponsorCaption}
																onChange={(e) => {
																	const formValueClone = _.clone(formValue);
																	formValueClone.sponsorCaption =
																		e.target.value;
																	setFormValue(formValueClone);
																}}
															/>
														</Col>

														<Col xs={6}>
															<Form.Label
																style={{
																	border: "none",
																	padding: "0",
																}}
															>
																Sponsor link
															</Form.Label>

															<Form.Control
																type="url"
																value={formValue.sponsorLink}
																onChange={(e) => {
																	const formValueClone = _.clone(formValue);
																	formValueClone.sponsorLink = e.target.value;
																	setFormValue(formValueClone);
																}}
															/>
														</Col>
													</Row>
													<div id="response2" className="hidden">
														<div id="messages2"></div>
													</div>
												</div>
											</label>
										</div>
									</div>
									{imageError2 && (
										<div className="m-2 text-center text-danger">
											{imageError2}
										</div>
									)}
								</Col>
							</>
						) : null}
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} md="6">
							{nameError ? (
								<Form.Label className="text-danger">{nameError}</Form.Label>
							) : (
								<Form.Label>Name*</Form.Label>
							)}
							<Form.Control
								// required
								placeholder="Fat loss challenge"
								value={formValue.name}
								onChange={(e) => {
									formValue.name !== "" && setNameError("");
									const formValueClone = _.clone(formValue);
									formValueClone.name = e.target.value;
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="3" controlId="price">
							<Form.Label>Price in EGP</Form.Label>
							<Form.Control
								placeholder="Price in EGP"
								min={admin.role.includes("admin")? "0" : "100"}
								type={"number"}
								value={formValue.price}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.price = e.target.value;
									formValueClone.price_usd = Math.ceil(formValueClone.price/49.35);
									formValueClone.price_usd_iap = Math.ceil(formValueClone.price/49.00);
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="3" controlId="price_usd">
							<Form.Label>USD Price</Form.Label>
							<Form.Control
								placeholder="Price in USD"
								type={"number"}
								value={formValue.price_usd}
								//disabled={true}
								//style={{opacity: .65}}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.price_usd = e.target.value;
									formValueClone.price = Math.floor(formValueClone.price_usd*49.35);
									formValueClone.price_usd_iap = Math.ceil(formValueClone.price_usd*49.35/49.00);
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="3">
							{durationError ? (
								<Form.Label className="text-danger">{durationError}</Form.Label>
							) : (
								<Form.Label>Duration*</Form.Label>
							)}
							<InputGroup className="inputWithDropDown">
								<Form.Control
									// required
									type={"number"}
									placeholder="Duration"
									aria-label="Duration"
									aria-describedby="basic-addon2"
									value={formValue.duration}
									onChange={(e) => {
										formValue.duration !== "" && setDurationError("");
										const formValueClone = _.clone(formValue);
										formValueClone.duration = e.target.value;
										setFormValue(formValueClone);
									}}
								/>

								<DropdownButton
									as={InputGroup.Append}
									variant="outline-secondary"
									title={formValue.durationType ? formValue.durationType : ""}
									id="input-group-dropṇṇdown-2"
								>
									{durationTypes.map((o) => {
										return (
											<Dropdown.Item
												onClick={() => {
													const formValueClone = _.clone(formValue);
													formValueClone.durationType = o;
													setFormValue(formValueClone);
												}}
												key={o}
												href="#"
											>
												{o}
											</Dropdown.Item>
										);
									})}
								</DropdownButton>
							</InputGroup>
						</Form.Group>
						<Form.Group as={Col} md="3" controlId="grace">
							<Form.Label>Grace period</Form.Label>
							<InputGroup className="inputWithDropDown">
								<Form.Control
									type={"number"}
									min="0"
									max={
										formValue.durationType === "Days"
											? formValue.duration
											: formValue.durationType === "Weeks"
											? formValue.duration * 7
											: formValue.durationType === "Months"
											? formValue.duration * 30
											: formValue.duration
									}
									value={formValue.grace}
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										formValueClone.grace = e.target.value;
										setFormValue(formValueClone);
									}}
								/>
								<DropdownButton
									as={InputGroup.Append}
									variant="outline-secondary"
									title={formValue.graceType ? formValue.graceType : "Days"}
									id="input-group-dropṇṇdown-2"
								>
									{graceTypes.map((o) => {
										return (
											<Dropdown.Item
												onClick={() => {
													const formValueClone = _.clone(formValue);
													formValueClone.graceType = o;
													setFormValue(formValueClone);
												}}
												key={o}
												href="#"
											>
												{o}
											</Dropdown.Item>
										);
									})}
								</DropdownButton>
							</InputGroup>
						</Form.Group>
						<Form.Group as={Col} md="6">
							<Form.Label>Date*</Form.Label>
							<div>
								<DatePicker
									onChange={(date) => {
										const formValueClone = _.clone(formValue);
										formValueClone.date = date;
										setFormValue(formValueClone);
									}}
									selected={new Date(formValue.date)}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									dateFormat="d MMMM Y"
								/>
							</div>
						</Form.Group>

						<Form.Group as={Col} md="12" controlId="videoLink">
							<Form.Label>Video</Form.Label>
							<Form.Control
								placeholder="Youtube link"
								type="url"
								value={formValue.videoLink}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.videoLink = e.target.value.trim();
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="12" controlId="description">
							<Form.Label>Description</Form.Label>
							<Form.Control
								placeholder="description"
								as="textarea"
								rows={5 /*features.length - 1*/}
								value={formValue.description}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.description = e.target.value;
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="3">
							<Form.Label>Features*</Form.Label>
							{featuresError && (
								<Form.Label
									style={{ display: "block" }}
									className="text-danger"
								>
									{featuresError}
								</Form.Label>
							)}
							{features.map((feature) => (
								<Form.Check
									style={{ fontSize: ".95em" }}
									key={feature.key}
									size="sm"
									type="checkbox"
									disabled={feature.required}
									checked={
										feature.required ||
										(formValue.features
											? formValue.features[feature.key]
											: false)
									}
									label={feature.title}
									className="text-left"
									onChange={(e) => {
										setFeaturesError("");
										const formValueClone = _.clone(formValue);
										//if (!formValueClone.features || formValueClone.features.mealPlan === false || formValueClone.features.workoutPlan === false) formValueClone.features = {};

										features.map((feature) => { if (feature.required) formValueClone.features[feature.key] = true;});

										formValueClone.features[feature.key] = e.target.checked;
										setFormValue(formValueClone);
									}}
								/>
							))}
						</Form.Group>

						<Form.Group as={Col} md="3">
							<Form.Label>Goal*</Form.Label>
							{goalError && (
								<Form.Label
									style={{ display: "block" }}
									className="text-danger"
								>
									{goalError}
								</Form.Label>
							)}
							{goalOptions.map((feature) => (
								<Form.Check
									style={{ fontSize: ".95em" }}
									key={feature.key}
									size="sm"
									type="checkbox"
									disabled={feature.required}
									checked={
										feature.required ||
										(formValue.goal ? formValue.goal[feature.key] : false)
									}
									label={feature.title}
									className="text-left"
									onChange={(e) => {
										formValue.goal !== "" && setGoalError("");
										const formValueClone = _.clone(formValue);
										if (!formValueClone.goal) formValueClone.goal = {};
										goalOptions.map((feature) => {
											if (feature.required)
												formValueClone.goal[feature.key] = true;
										});
										formValueClone.goal[feature.key] = e.target.checked;
										if (
											Object.values(formValueClone.goal).every(
												(v) => v === false
											)
										)
											formValueClone.goal = {};

										setFormValue(formValueClone);
									}}
								/>
							))}
						</Form.Group>
						<Form.Group
							as={Col}
							md="3"
							style={
								formValue.features.mealPlan !== true
									? { opacity: 0.5, pointerEvents: "none" }
									: {}
							}
						>
							<Form.Label>Diet types</Form.Label>
							{dietTypes.map((feature) => (
								<Form.Check
									style={{ fontSize: ".95em" }}
									key={feature.key}
									size="sm"
									type="checkbox"
									disabled={feature.required}
									checked={
										feature.required ||
										(formValue.dietTypes
											? formValue.dietTypes[feature.key]
											: false)
									}
									label={feature.title}
									className="text-left"
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										if (!formValueClone.dietTypes)
											formValueClone.dietTypes = {};
										dietTypes.map((feature) => {
											if (feature.required)
												formValueClone.dietTypes[feature.key] = true;
										});
										formValueClone.dietTypes[feature.key] = e.target.checked;
										setFormValue(formValueClone);
									}}
								/>
							))}
						</Form.Group>
						<Form.Group
							as={Col}
							md="3"
							style={
								formValue.features.workoutPlan !== true
									? { opacity: 0.5, pointerEvents: "none" }
									: {}
							}
						>
							<Form.Label>Exercise types</Form.Label>
							{exerciseTypes.map((feature) => (
								<Form.Check
									style={{ fontSize: ".95em" }}
									key={feature.key}
									size="sm"
									type="checkbox"
									disabled={feature.required}
									checked={
										feature.required ||
										(formValue.exerciseTypes
											? formValue.exerciseTypes[feature.key]
											: false)
									}
									label={feature.title}
									className="text-left"
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										if (!formValueClone.exerciseTypes)
											formValueClone.exerciseTypes = {};
										exerciseTypes.map((feature) => {
											if (feature.required)
												formValueClone.exerciseTypes[feature.key] = true;
										});
										formValueClone.exerciseTypes[feature.key] =
											e.target.checked;
										setFormValue(formValueClone);
									}}
								/>
							))}
						</Form.Group>
						<Form.Group as={Col} md="12" controlId="welcome">
							<Form.Label>
								Welcome notes (Outside challenge - before joining)
							</Form.Label>
							<Form.Control
								placeholder="welcome notes (separated by linebreaks)"
								as="textarea"
								// rows={features.length - 1}
								rows={
									formValue.welcome ? Math.max(3, formValue.welcome.length) : 3
								}
								value={
									formValue.welcome
										? " • " + formValue.welcome.join("\r • ")
										: ""
								}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.welcome = e.target.value
										.replace(/ • /g, "")
										.split(/\r?\n/);
									e.target.rows = Math.max(3, formValueClone.welcome.length);
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} md="12" controlId="guidelines">
							<Form.Label>
								Guidelines (Inside challenge - program guidelines)
							</Form.Label>
							<Form.Control
								placeholder="guidelines (separated by linebreaks)"
								as="textarea"
								// rows={features.length - 1}
								rows={
									formValue.guidelines
										? Math.max(3, formValue.guidelines.length)
										: 3
								}
								value={
									formValue.guidelines
										? " • " + formValue.guidelines.join("\r • ")
										: ""
								}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.guidelines = e.target.value
										.replace(/ • /g, "")
										.split(/\r?\n/);
									e.target.rows = Math.max(3, formValueClone.guidelines.length);
									setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
					</Form.Row>

					<Col sm="12">
						<hr />
						<Form.Group style={{ display: "flex" }}>
							<Form.Label>
								<div style={{ fontWeight: "500" }}>
									Frequently asked questions (Outside challenge - before
									joining) ({formValue.faq ? formValue.faq.length : 0})
								</div>
							</Form.Label>
							<Button
								className="button button-secondary"
								style={{
									marginLeft: "auto",
								}}
								onClick={() => {
									addMoreFaq();
								}}
							>
								<FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
								Add Question
							</Button>
						</Form.Group>

						{formValue.faq &&
							formValue.faq.map((a, i) => {
								return (
									<div key={i}>
										<Form.Row>
											<Row className="w-100">
												<Form.Group as={Col} sm="5">
													<Form.Label>Question*</Form.Label>
													<Form.Control
														as="textarea"
														required
														rows="3"
														placeholder="How many kilograms will i lose in this challenge?"
														value={formValue.faq[i].question}
														onChange={(e) => {
															const formValueClone = _.clone(formValue);
															formValueClone.faq[i].question = e.target.value;
															setFormValue(formValueClone);
														}}
													/>
												</Form.Group>
												<Form.Group as={Col} sm="6">
													<Form.Label>Answer*</Form.Label>
													<Form.Control
														as="textarea"
														required
														rows="3"
														placeholder="Each person has their own metabolic rate, genes, we can't put a specific number on that"
														value={formValue.faq[i].answer}
														onChange={(e) => {
															const formValueClone = _.clone(formValue);
															formValueClone.faq[i].answer = e.target.value;
															setFormValue(formValueClone);
														}}
													/>
												</Form.Group>
												<Col
													xs={1}
													className="text-center mb-4 align-self-center text-danger"
												>
													<FontAwesomeIcon
														icon={faMinusCircle}
														size="2x"
														className="ma button"
														style={{ cursor: "pointer", color: "#000" }}
														onClick={() => {
															removeFaq(i, formValue.faq[i].key);
														}}
													/>
												</Col>
											</Row>
										</Form.Row>
									</div>
								);
							})}
					</Col>
					<Col sm="12">
						<hr />
						<Form.Group style={{ display: "flex" }}>
							<Form.Label>
								<div style={{ fontWeight: "500" }}>
									Frequently asked questions (Inside challenge - program FAQs) (
									{formValue.questions ? formValue.questions.length : 0})
								</div>
							</Form.Label>
							<Button
								className="button button-secondary"
								style={{
									marginLeft: "auto",
								}}
								onClick={() => {
									addMoreQuestions();
								}}
							>
								<FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
								Add Question
							</Button>
						</Form.Group>
						<>
							{formValue.questions &&
								formValue.questions.map((a, i) => {
									return (
										<div key={i}>
											<Form.Row>
												<Row className="align-self-center mt-3 w-100">
													<Form.Group as={Col} sm="5">
														<Form.Label>Question*</Form.Label>
														<Form.Control
															as="textarea"
															required
															rows="3"
															placeholder="How many kilograms will i lose in this challenge?"
															value={formValue.questions[i].question}
															onChange={(e) => {
																const formValueClone = _.clone(formValue);
																formValueClone.questions[i].question =
																	e.target.value;
																setFormValue(formValueClone);
															}}
														/>
													</Form.Group>
													<Form.Group as={Col} sm="6">
														<Form.Label>Answer*</Form.Label>
														<Form.Control
															as="textarea"
															required
															rows="3"
															placeholder="Each person has their own metabolic rate, genes, we can't put a specific number on that"
															value={formValue.questions[i].answer}
															onChange={(e) => {
																const formValueClone = _.clone(formValue);
																formValueClone.questions[i].answer =
																	e.target.value;
																setFormValue(formValueClone);
															}}
														/>
													</Form.Group>
													<Col
														xs={1}
														className="text-center mb-4 align-self-center text-danger"
													>
														<FontAwesomeIcon
															icon={faMinusCircle}
															size="2x"
															className="ma button"
															style={{ cursor: "pointer", color: "#000" }}
															onClick={() => {
																removeQuestion(i, formValue.questions[i].key);
															}}
														/>
													</Col>
												</Row>
											</Form.Row>
										</div>
									);
								})}
						</>
					</Col>
					<Col sm="12">
						<hr />
						<Form.Group style={{ display: "flex" }}>
							<Form.Label>
								<div style={{ fontWeight: "500" }}>
									Reminders (
									{formValue.reminders ? formValue.reminders.length : 0})
								</div>
							</Form.Label>
							<Button
								className="button button-secondary"
								style={{
									marginLeft: "auto",
								}}
								onClick={() => {
									addMoreReminders();
								}}
							>
								<FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
								Add Reminder
							</Button>
						</Form.Group>
						<>
							{formValue.reminders &&
								formValue.reminders.map((a, i) => {
									return (
										<div key={i}>
											<Form.Row>
												<Row className="align-self-center mt-3 w-100">
													<Form.Group as={Col} sm="5">
														<Form.Label>Name*</Form.Label>
														<Form.Control
															required
															rows="3"
															placeholder="e.g Waist"
															value={formValue.reminders[i].name}
															onChange={(e) => {
																const formValueClone = _.clone(formValue);
																formValueClone.reminders[i].name =
																	e.target.value;
																setFormValue(formValueClone);
															}}
														/>
													</Form.Group>
													<Form.Group as={Col} sm="6">
														<Form.Label>Every (in days)*</Form.Label>
														<Form.Control
															type={"number"}
															required
															rows="3"
															placeholder="e.g 7"
															value={formValue.reminders[i].every}
															onChange={(e) => {
																const formValueClone = _.clone(formValue);
																formValueClone.reminders[i].every =
																	e.target.value;
																setFormValue(formValueClone);
															}}
														/>
													</Form.Group>
													<Col
														xs={1}
														className="text-center align-self-center text-danger"
													>
														<FontAwesomeIcon
															icon={faMinusCircle}
															size="2x"
															className="ma button"
															style={{ cursor: "pointer", color: "#000" }}
															onClick={() => {
																removeReminder(i, formValue.reminders[i].key);
															}}
														/>
													</Col>
												</Row>
											</Form.Row>
										</div>
									);
								})}
						</>
					</Col>
						<hr />
					<Col sm="12" style={{ /*display: "flex"*/ }}>
						<Form.Group controlId="formBasicCheckbox">
							<Form.Check
								type="checkbox"
								label="Publish for live users"
								checked={formValue.isPublished}
								onChange={async (e) => {
									await onSelectPublish(e.target.checked);
									// const formValueClone = _.clone(formValue);
									// formValueClone.isPublished = e.target.checked;
									// setFormValue(formValueClone);
								}}
							/>
						</Form.Group>
						<Form.Group
							controlId="formBasicCheckbox2"
							style={{ /*marginLeft: "1em"*/ }}
						>
							<Form.Check
								style={{display: 'inline-block'}}
								type="checkbox"
								label="Private (users with link)"
								checked={formValue.isPrivate}
								onChange={async (e) => {
									await onSelectClose(e.target.checked);
								}}
							/>
							{formValue.isPrivate && webLink && admin.role.includes("admin") && (
								<span
									disabled
									style={{
										background: "#eee",
										display: "inline",
										height: "max-content",
										padding: "2px 7px",
										fontSize: ".8em",
										margin: "0 5px",
										opacity: ".7",
										border: "5px",
									}}
								>
									{webLink}/challenge/{challengeId}
								</span>
							)}
						</Form.Group>
						<Form.Group
							controlId="formBasicCheckbox3"
							style={{/* marginLeft: "1em"*/ }}
						>
							<Form.Check
								type="checkbox"
								label="Bundles (allow users to book several rounds at once)"
								disabled={!admin.role.includes("admin")}
								title={!admin.role.includes("admin")?"only admins can edit this, please contact support":''}
								checked={formValue.isBundleable}
								onChange={async (e) => {
									await onSelectBundle(e.target.checked);
									// const formValueClone = _.clone(formValue);
									// formValueClone.isPublished = e.target.checked;
									// setFormValue(formValueClone);
								}}
							/>
						</Form.Group>

						{bundleError && (
							<Form.Label
								style={{ display: "block" }}
								className="text-danger"
							>
								{bundleError}
							</Form.Label>
						)}

						{formValue.isBundleable && <Form.Group
							as={Col}
							md="3"
							//style={
							//	!admin.role.includes("admin")
							//		? { opacity: 0.5, pointerEvents: "none" }
							//		: {}
							//}
						>
							{bundles.map((feature) => (
								<Form.Check
									style={{ fontSize: ".95em" }}
									key={feature.key}
									size="sm"
									type="checkbox"
									disabled={feature.required || !admin.role.includes("admin")}
									checked={
										feature.required ||
										(formValue.bundles
											? formValue.bundles.hasOwnProperty(feature.key)
											: false)
									}
									label={
										feature.key === 1 ? 
										feature.title + ' '+formValue.price+' EGP.' : 
										feature.title + ' ' + (formValue.bundles&&formValue.bundles[feature.key] ? ('('+formValue.bundles[feature.key] + '%)' + ' '+Math.floor(((100-formValue.bundles[feature.key])/100)*feature.key*formValue.price)+' EGP.') : '')
									}
									className="text-left"
									onChange={(e) => {
										var discount = (feature.key === 1 || !e.target.checked) ? 0 : +prompt('enter '+feature.title+' '+'discount in %');
										if(feature.key !== 1 && e.target.checked && !discount) return e.preventDefault()
										const formValueClone = _.clone(formValue);
										if (!formValueClone.bundles) formValueClone.bundles = {};
										bundles.map((feature) => {
											if (feature.required){
												formValueClone.bundles[feature.key] = 0;
											}
										});
										if(e.target.checked) formValueClone.bundles[feature.key] = discount;
										else delete formValueClone.bundles[feature.key]
										setFormValue(formValueClone);
									}}
								/>
							))}
						</Form.Group>}
						{/*<span style={{display: 'inline-block', color: '#999'}}>
							<span style={{fontSize: '12px', marginLeft: '1.5em'}}>1 month <b>{formValue.price}</b> EGP.</span>
							<span style={{fontSize: '12px', marginLeft: '0.5em'}}>2 months <b>{((100-bundleDiscount[2])/100)*2*formValue.price}</b> EGP.</span>
							<span style={{fontSize: '12px', marginLeft: '0.5em'}}>3 months <b>{((100-bundleDiscount[3])/100)*3*formValue.price}</b> EGP.</span>
						</span>*/}
					</Col>
					<Row>
						<Col sm="12">
							<Button
								type="submit"
								variant="primary"
								className="button button-primary"
								style={{ width: "97%", margin: "1em" }}
							>
								{isSubmitLoader ? (
									<Spinner animation="border" size={"sm"} role="status" />
								) : (
									"Save"
								)}
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
			<Modal
				show={showPublishConfirmation}
				onHide={() => handleConfirmation(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Are you sure ?</Modal.Title>
				</Modal.Header>
				<Modal.Body>This challenge will be live for users on save.</Modal.Body>
				<Modal.Footer>
					<div>
						<Button
							style={{ borderRadius: "22px" }}
							className="button button-cancel"
							onClick={() => handleConfirmation(false)}
						>
							Cancel
						</Button>
						<Button
							className="button button-save button-primary"
							variant="primary"
							onClick={() => handleConfirmation(true)}
						>
							Yes
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showCloseConfirmation}
				onHide={() => handleConfirmationClose(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Are you sure ?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					This challenge will be available for users with the link on save.
				</Modal.Body>
				<Modal.Footer>
					<div>
						<Button
							className="button button-cancel"
							onClick={() => handleConfirmationClose(false)}
						>
							Cancel
						</Button>
						<Button
							className="button button-save button-primary"
							onClick={() => handleConfirmationClose(true)}
						>
							Yes
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showBundleConfirmation}
				onHide={() => handleConfirmationBundle(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Are you sure ?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					This challenge will be bundleable on save
					<br />
					users will be able to pay for up to several rounds upfront
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="button button-cancel"
						onClick={() => handleConfirmationBundle(false)}
					>
						Cancel
					</Button>
					<Button
						className="button button-save button-primary"
						onClick={() => handleConfirmationBundle(true)}
					>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
