import React, { useEffect, useState } from "react";
import firebase from 'firebase/compat/app';
import { NavLink, useHistory } from "react-router-dom";
import './SideMenu.scss'
import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import { ReactComponent as Stats } from '../../assets/icons/stats.svg';
import { ReactComponent as Capsules } from '../../assets/icons/capsules.svg';
import { ReactComponent as Videos } from '../../assets/icons/videos.svg';
import { ReactComponent as MealPlans } from '../../assets/icons/meal-plans.svg';
import { ReactComponent as WorkoutPlans } from '../../assets/icons/workout-plans.svg';
import { ReactComponent as Users } from '../../assets/icons/users.svg';
import { ReactComponent as Feed } from '../../assets/icons/feed.svg';
import { ReactComponent as Questions } from '../../assets/icons/questions.svg';
import { ReactComponent as Messages } from '../../assets/icons/messages.svg';
import { ReactComponent as Reviews } from '../../assets/icons/reviews.svg';
// import { ReactComponent as Challenges } from '../../assets/icons/challenges.svg';
import { ReactComponent as Tutorials } from '../../assets/icons/tutorials.svg';
import { ReactComponent as Billing } from '../../assets/icons/billing.svg';
import { ReactComponent as Followers } from '../../assets/icons/followers.svg';
import { ReactComponent as Manage } from '../../assets/icons/manage.svg';
import { ReactComponent as Admin } from '../../assets/icons/admin.svg';
import { ReactComponent as Support } from '../../assets/icons/support.svg';
import { ReactComponent as Rules } from '../../assets/icons/rules.svg';
import { ReactComponent as Rooms } from '../../assets/icons/rooms.svg';
import { ReactComponent as Logs } from '../../assets/icons/logs.svg';
import { ReactComponent as Roles } from '../../assets/icons/roles.svg';
import { ReactComponent as Analytics } from '../../assets/icons/analytics.svg';
import { ReactComponent as Payments } from '../../assets/icons/payments.svg';
import { ReactComponent as Signups } from '../../assets/icons/signups.svg';
import { ReactComponent as Funnel } from '../../assets/icons/funnel.svg';
import { ReactComponent as Notifications } from '../../assets/icons/notifications.svg';
import { ReactComponent as Lifetime } from '../../assets/icons/lifetime.svg';
import { ReactComponent as Customers } from '../../assets/icons/customers.svg';
import { ReactComponent as Promocodes } from '../../assets/icons/promocodes.svg';
import { ReactComponent as Content } from '../../assets/icons/content.svg';
import { ReactComponent as Ingredients } from '../../assets/icons/ingredients.svg';
import { ReactComponent as Recipes } from '../../assets/icons/recipes.svg';
import { ReactComponent as Exercises } from '../../assets/icons/exercises.svg';
import { ReactComponent as Feedback } from '../../assets/icons/feedback.svg';
import { ReactComponent as Followup } from '../../assets/icons/followup.svg';
import { ReactComponent as Receipts } from '../../assets/icons/receipts.svg';
import { ReactComponent as Requests } from '../../assets/icons/requests.svg';
import { ReactComponent as Coaches } from '../../assets/icons/coaches.svg';
import { ReactComponent as Orders } from '../../assets/icons/orders.svg';
import { ReactComponent as Refunds } from '../../assets/icons/refunds.svg';
import { Button, Image } from "react-bootstrap";
import { ClearAll, rudderAnalytics } from "../../services/trackers";

export function SideMenu(props) {
    const [userDetails, setUserDetails] = useState({});
    const [adminDetails, setAdminDetails] = useState({});
    const [role, setRole] = useState({});

    const { listen } = useHistory()
    useEffect(() => {

        setUser()

        const unlisten = listen((location) => {

            setUser()
        });

        return unlisten
    }, [listen])


    var setUser = function(){

        const userDetailsStorage = JSON.parse(localStorage.getItem('userDetails'));
        if (userDetailsStorage) setUserDetails(userDetailsStorage);

        const adminDetailsStorage = JSON.parse(localStorage.getItem('adminDetails'));
        if (adminDetailsStorage) setAdminDetails(adminDetailsStorage);

        if(userDetailsStorage && userDetailsStorage.role){

            setRole({

                admin: userDetailsStorage.role.includes('admin'),
                assistant: userDetailsStorage.role.includes('assistant'),
                collaborator: userDetailsStorage.role.includes('collaborator'),
                nutritionist: userDetailsStorage.role.includes('nutritionist'),
            })
        }

        if(adminDetailsStorage && adminDetailsStorage.role){

            setRole({

                admin: adminDetailsStorage.role.includes('admin'),
                assistant: adminDetailsStorage.role.includes('assistant'),
                collaborator: adminDetailsStorage.role.includes('collaborator'),
                nutritionist: adminDetailsStorage.role.includes('nutritionist'),
            })
        }
    }

    const pages = [
        { url: '/profile', icon: Profile, displayName: 'Profile', roles: ['admin', 'nutritionist', 'assistant'] }, 
        { url: '/capsules', icon: Capsules, displayName: 'Capsules', roles: ['admin', 'nutritionist', 'assistant'] }, 
        { url: '/videos', icon: Videos, displayName: 'Videos', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] }, 
        { url: '/meal-plans', icon: MealPlans, displayName: 'Meal Plans', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] }, 
        { url: '/workout-plans', icon: WorkoutPlans, displayName: 'Workout Plans', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] }, 
        // { url: '/challenges', icon: Challenges, displayName: 'Challenges', roles: ['admin', 'nutritionist', 'assistant'] }, 
        { url: '/stats', icon: Stats, displayName: 'Stats', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] }, 
        { url: '/users', icon: Users, displayName: 'Users', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] },  
        { url: '/feed', icon: Feed, displayName: 'Feed', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] }, 
        { url: '/questions', icon: Questions, displayName: 'Questions', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] }, 
        { url: '/messages', icon: Messages, displayName: 'Messages', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] }, 
        { url: '/reviews', icon: Reviews, displayName: 'Reviews', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] }, 
        { url: '/tutorials', icon: Tutorials, displayName: 'Tutorials', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] }, 
        { url: '/billing', icon: Billing, displayName: 'Billing', roles: ['admin', 'nutritionist', 'collaborator'] }, 
        //{ url: '/followers', icon: Followers, displayName: 'Followers', roles: ['admin', 'nutritionist', 'collaborator', 'assistant'] }, 
        { url: '/support', icon: Support, displayName: 'Support', roles: ['admin'] }, 
        //{ url: '/rules', icon: Rules, displayName: 'Rules', roles: ['admin'] }, 
        { url: '/rooms', icon: Rooms, displayName: 'Rooms', roles: ['admin'] }, 
        { url: '/admin', icon: Logs, displayName: 'Logs', roles: ['admin'] }, 
        { url: '/roles', icon: Roles, displayName: 'Roles', roles: ['admin'] }, 
        { url: '/signups', icon: Signups, displayName: 'Signups', roles: ['admin'] }, 
        { url: '/funnel', icon: Funnel, displayName: 'Funnel', roles: ['admin'] },
        { url: '/notifications', icon: Notifications, displayName: 'Notifications', roles: ['admin'] },
        { url: '/analytics', icon: Analytics, displayName: 'Analytics', roles: ['admin'] }, 
        { url: '/payments', icon: Payments, displayName: 'Payments', roles: ['admin'] }, 
        { url: '/lifetime', icon: Lifetime, displayName: 'Lifetime', roles: ['admin'] }, 
        { url: '/customers', icon: Customers, displayName: 'Customers', roles: ['admin'] }, 
        { url: '/promocodes', icon: Promocodes, displayName: 'Promocodes', roles: ['admin'] }, 
        { url: '/content', icon: Content, displayName: 'Content', roles: ['admin'] }, 
        { url: '/food', icon: Ingredients, displayName: 'Ingredients', roles: ['admin'] }, 
        { url: '/recipes', icon: Recipes, displayName: 'Recipes', roles: ['admin'] }, 
        { url: '/exercises', icon: Exercises, displayName: 'Exercises', roles: ['admin'] },
        { url: '/feedback', icon: Feedback, displayName: 'Feedback', roles: ['admin'] },
        { url: '/followup', icon: Followup, displayName: 'Followup', roles: ['admin'] },
        { url: '/receipts', icon: Receipts, displayName: 'Receipts', roles: ['admin'] },
        { url: '/requests', icon: Requests, displayName: 'Requests', roles: ['admin'] },
        { url: '/coaches', icon: Coaches, displayName: 'Coaches', roles: ['admin'] },
        { url: '/orders', icon: Orders, displayName: 'Orders', roles: ['admin'] },
        { url: '/refunds', icon: Refunds, displayName: 'Refunds', roles: ['admin'] },
    ];


    function fbImage(imageLink){
        
        if(!imageLink) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    async function logout() {
        rudderAnalytics.track('User_Sign_Out');
        localStorage.clear();
        await firebase.auth().signOut();
        ClearAll();
    }

    function managePage(d){

        var managePages = ['Capsules', 'Videos', 'Users', 'Questions', 'Feed', 'Messages']
        return managePages.includes(d.displayName)
    }

    function adminPage(d){

        return d.roles.length === 1 && d.roles[0] === 'admin'
    }

    return (
        <>
            <div className="sidebar-heading">

                <NavLink key={'/profile'} to={'/profile'}>
                <img className="user-image" style={{cursor:'pointer'}}
                    height="90"
                    src={fbImage(adminDetails.image)}
                />
                </NavLink>

                <div className="user-name">{adminDetails.name}</div>
                <Button size="sm" variant="primary" onClick={() => logout()}>Sign out</Button>
            </div>
            <div className="list-group list-group-flush">
                {userDetails.name && pages.filter(page => !managePage(page) && !adminPage(page) && page.roles.some(r=> adminDetails.role&&adminDetails.role.includes(r))).map((page) => {
                    const Icon = page.icon;
                    return (
                        <NavLink key={page.url} className="d-flex list-group-item list-group-item-action" to={page.url} exact activeClassName={'link-active'}>
                            <Icon className="link-icon" /><div className="link-text">{page.displayName}</div>
                        </NavLink>
                    )
                })}
                {(role.admin || role.collaborator || role.assistant) &&
                    <NavLink key={'select-nutritionist'} className="d-flex list-group-item list-group-item-action"
                        to={'select-nutritionist'} exact
                        activeClassName={'link-active'}>

                        {userDetails&&userDetails.name? <Image src={fbImage(userDetails.image)} className="link-icon" roundedCircle /> : <Users className="link-icon" />}
                        <div className="link-text">{userDetails.name? userDetails.name : 'Select Nutritionist'}</div>
                    </NavLink>
                }

                {userDetails.name && <React.Fragment>
                    <NavLink key={'manage'} to={''} exact activeClassName={'link-active'}
                        className="d-flex list-group-item list-group-item-action manage-label" 
                        style={{opacity: .5, marginTop: '3em', pointerEvents: 'none'}}>
                        <Manage className="link-icon"/><div className="link-text">Manage</div>
                    </NavLink>
                    {pages.filter(page => managePage(page)).map((page) => { const Icon = page.icon; 
                        return (
                            <NavLink key={page.url} className="d-flex list-group-item list-group-item-action manage-item" to={page.url} exact activeClassName={'link-active'}>
                                <Icon className="link-icon"/>
                                <div className="link-text">{page.displayName}</div>
                            </NavLink>
                        )
                    })}
                </React.Fragment>}

                {role.admin && <React.Fragment>
                    <NavLink key={'admin'} to={''} exact activeClassName={'link-active'}
                        className="d-flex list-group-item list-group-item-action admin-label" 
                        style={{opacity: .5, marginTop: '3em', pointerEvents: 'none'}}>
                        <Admin className="link-icon"/><div className="link-text">Admin</div>
                    </NavLink>
                    {pages.filter(page => adminPage(page) && (page.url !== '/payments' || (adminDetails.super === true)) && (page.url !== '/lifetime' || (adminDetails.super === true))).map((page) => { const Icon = page.icon; 
                        return (
                            <NavLink key={page.url} className="d-flex list-group-item list-group-item-action admin-item" to={page.url} exact activeClassName={'link-active'}>
                                <Icon className="link-icon"/>
                                <div className="link-text">{page.displayName}</div>
                            </NavLink>
                        )
                    })}
                </React.Fragment>}
            </div>
        </>
    )
}

export default SideMenu
