/** @format */

import React, { useState, useEffect, useRef } from "react";
import {
	Button,
	Modal,
	Spinner,
	Col,
	Form,
	ToggleButton,
} from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faPlusCircle,
	faMinusCircle,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from 'firebase/compat/app';
import { firestore, storage } from "../services/firebase";
import * as _ from "lodash";
import Select from "react-select";

import moment from "moment";
import "./AddRecipe.scss";

library.add(faPlusCircle, faMinusCircle);

const recipeTagMap = {
	Affordable: "Easier on budget",
	Gourmet: "Delicious and varied",
};

function getRandomColor() {
  var letters = '0123456789AB';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}
function setColors(tags) {
  var obj = {}
  tags.map(tag =>{
  	obj[tag] = getRandomColor()
  })
  return obj;
}
function hexToRGBA(hex, opacity) {
  hex = hex.replace('#', ''); // remove the # if present
  var r = parseInt(hex.substring(0,2), 16);
  var g = parseInt(hex.substring(2,4), 16);
  var b = parseInt(hex.substring(4,6), 16);
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
}


export function AddRecipe(props) {
	const totalDays = 30;
	const [tags, setTags] = useState({});
	const [colors, setColors] = useState({});
	const [isImageChanged, setIsImageChanged] = useState(false);
	const [recipeImage, setRecipeImage] = useState("");
	const [imageFile, setImageFile] = useState(false);
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [imageError, setImageError] = useState("");
	const [value, setValue] = useState("");
	const [arabic, setArabic] = useState(
		props.recipeData && props.recipeData.hasOwnProperty("arabic")
			? props.recipeData.arabic
			: JSON.parse(localStorage.getItem("arabic"))
	);
	const inputImg = useRef(null);

	const initFormValue = {
		type: "Lunch",
		videoLink: "",
		calories: "",
		Calories: "",
		name: "",
		ingredients: "",
		Ingredients: [],
		description: "",
		image: "",
		tags: []
	};

	const [formValue, setFormValue] = useState(initFormValue);
	useEffect(() => {
		setFormData();
		return () => {};
	}, [props.recipeData]);

	useEffect(() => {
		getRecipeTags();
		return () => {};
	}, []);

    async function getRecipeTags() {
        const recipeTypesDoc = await firestore('constants').doc('recipeTypes').get();
        const recipeTagsDoc = await firestore('constants').doc('recipeTags').get();
        var tags = {
        	'meal type': {
        		values: recipeTypesDoc.data().value.map(d=> d.toLowerCase()),
        		multi: true
        	},
        	...recipeTagsDoc.data()
        }
        for(var tag in tags) {
        	const color = getRandomColor(tag)
        	tags[tag].color = hexToRGBA(color, 0.1)
        	tags[tag].Color = color
        }
        setTags(tags)
    }

	// let selectedIngredient = formValue.Ingredients.map((x) => x.food);
	let ingredientsProps = [...props.ingredients];
	/* categorize array label*/
	let ingredientsArray = ingredientsProps.map((obj) => {
		return {
			label: arabic ? obj.food + " " + obj.food_ar : obj.food,
			value: obj.food,
			category: obj.category,
		};
	});
	let category_ar = {};
	let groupLabel = ingredientsProps
		.map((obj) => {
			category_ar[obj.category] = obj.category_ar;
			return obj.category;
		})
		.filter((v, i, a) => a.indexOf(v) === i);

	let labelOptions = [];
	for (let item1 of groupLabel) {
		for (let item2 of ingredientsArray) {
			if (item1 === item2.category) {
				labelOptions.push({
					label: item1 + (arabic ? " " + category_ar[item1] : ""),
					options: [item2],
				});
			}
		}
	}
	let labelOutput = [];

	labelOptions.forEach(function (item) {
		var existing = labelOutput.filter(function (v, i) {
			return v.label === item.label;
		});
		if (existing.length) {
			var existingIndex = labelOutput.indexOf(existing[0]);
			labelOutput[existingIndex].options = labelOutput[
				existingIndex
			].options.concat(item.options);
		} else {
			if (typeof item.value == "string") item.value = [item.value];
			labelOutput.push(item);
		}
	});

	const formatGroupLabel = (labelOutput) => (
		<div>
			<span>{labelOutput.label}</span>
		</div>
	);

	/* filter ingredints array*/
	// let filteredIngredients = ingredientsArray.filter(
	// 	(item) => selectedIngredient.indexOf(item.food) === -1
	// );

	function setFormData() {
		if (document.getElementById("file-image"))
			document.getElementById("file-image").classList.remove("hidden");
		if (document.getElementById("start"))
			document.getElementById("start").classList.remove("hidden");
		if (document.getElementById("response"))
			document.getElementById("response").classList.add("hidden");
		if (document.getElementById("file-upload-form"))
			document.getElementById("file-upload-form").reset();

		setImageFile(false);
		setRecipeImage("");
		setIsImageChanged(false);

		let formValueClone = _.clone(initFormValue);
		if (props.recipeData && Object.keys(props.recipeData).length) {
			if (!props.recipeData.Ingredients) props.recipeData.Ingredients = [];
			formValueClone = props.recipeData;
			setRecipeImage(props.recipeData.image);
		} else {
			setRecipeImage("");
		}
		setFormValue(formValueClone);
	}

	function addMoreClicked() {
		formValue.ingredients[formValue.ingredients.length] = {
			name: "",
			unit: "",
			amount: "",
			ar: {
				name: "",
			},
		};
		setFormValue(formValue);
	}

	function onImageUpload(e) {
		fileSelectHandler(e);
	}

	async function submitForm(e) {
		e.stopPropagation();
		e.preventDefault();

		if (isImageChanged && imageFile) {
			setIsSubmitLoader(true);

			setIsSubmitLoader(true);
			const fileName = moment().format();
			const uploaded = await storage()
				.ref()
				.child(`/recipes/${fileName}`)
				.put(imageFile, { cacheControl: "public,max-age=31536000" });
			const child = await storage().ref().child(uploaded.metadata.fullPath);
			formValue.image = await child.getDownloadURL();
			formValue.fullPath = uploaded.metadata.fullPath;
		} else if (!props.recipeData.key && !props.recipeData.image) {
			setImageError("image is required");
			return;
		}

		formValue.arabic = arabic;
		formValue.ingredients = textIngredients(formValue.Ingredients);
		//formValue.calories = formValue.Calories

		if (props.recipeData.key) {
			formValue.datetime = moment().format();
			await firestore("recipes").doc(props.recipeData.key).update(formValue);
		} else {
			const userDetails = JSON.parse(localStorage.getItem("userDetails"));
			formValue.nutritionistId = props.welnes ? "welnes" : userDetails.id;
			formValue.nutritionistName = props.welnes ? "welnes" : userDetails.name;
			formValue.datetime = moment().format();
			await firestore("recipes").add(formValue);
			if(props.welnes) await firestore("recipes_add").add({ created: firebase.firestore.FieldValue.serverTimestamp(),date: moment().format(), user: userDetails.name, admin: JSON.parse(localStorage.getItem("adminDetails")).name, recipeData: formValue});
		}
		setIsSubmitLoader(false);
		props.handleClose(true);
	}

	function fileDragHover(e) {
		var fileDrag = document.getElementById("file-drag");
		e.stopPropagation();
		e.preventDefault();
		fileDrag.className =
			e.type === "dragover" ? "hover" : "modal-body file-upload";
	}

	async function fileSelectHandler(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;
		// Cancel event and hover styling
		fileDragHover(e);

		// Process all File objects
		for (var i = 0, f; (f = files[i]); i++) {
			var options = {
				maxSizeMB: 0.3,
				maxWidthOrHeight: 400,
			};
			try {
				const compressedFile = f; //await imageCompression(f, options);
				setImageError("");
				setImageFile(compressedFile);
				parseFile(compressedFile);
			} catch (error) {
				console.error("error fileSelectHandler", error);
				setImageError("please upload a valid image file");
			}
		}
		setIsImageChanged(true);
	}

	function parseFile(file) {
		output("<strong>" + encodeURI(file.name) + "</strong>");

		// var fileType = file.type;
		var imageName = file.name;

		var isGood = true///\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
		if (isGood) {
			document.getElementById("start").classList.add("hidden");
			document.getElementById("response").classList.remove("hidden");
			// Thumbnail Preview
			document.getElementById("file-image").classList.remove("hidden");
			//document.getElementById('file-image').src = URL.createObjectURL(file);
			setRecipeImage(URL.createObjectURL(file));
		}
		// else if (!isGood) {
		// 	setImageError("please upload a valid image file");
		else {
			document.getElementById("file-image").classList.add("hidden");
			document.getElementById("start").classList.remove("hidden");
			document.getElementById("response").classList.add("hidden");
			document.getElementById("file-upload-form").reset();
		}
	}

	function output(msg) {
		// Response
		var m = document.getElementById("messages");
		m.innerHTML = msg;
	}

	function calories(ingredients) {
		return +ingredients
			.map((d) => d.quantity * d.calories_serving)
			.reduce((a, b) => a + b, 0)
			.toFixed(2);
	}

	function textIngredients(ingredients) {
		return ingredients
			.map(
				(d) =>
					d.food +
					" " +
					(arabic ? "(" + d.food_ar + ") " : "") +
					String(Number(d.quantity.toFixed(2))) +
					" " +
					d.serving +
					" " +
					"(" +
					String(
						Number(
							Math.max(1, Math.round(d.quantity * d.unit_serving)).toFixed(2)
						)
					) +
					" " +
					d.unit +
					")"
			)
			.join("\r\n");
	}

	function reRenderIngredients() {
		const formValueClone1 = _.clone(formValue);
		const formValueClone2 = _.clone(formValue);
		formValueClone1.Ingredients = [];
		setFormValue(formValueClone1);
		setTimeout(function () {
			setFormValue(formValueClone2);
		}, 0);
	}

    async function toggleTag(property, tag, value, multi) {

        var selected = formValue[property] && formValue[property][tag] && formValue[property][tag].includes(value)

		const formValueClone = _.clone(formValue);
		if(!formValueClone[property]) formValueClone[property] = {}

		if(selected){//remove

			formValueClone[property][tag] = formValueClone[property][tag].filter(item => item !== value)
			if(!formValueClone[property][tag].length) delete formValueClone[property][tag]
		}
		else{//add

			if(multi) {
				if(formValueClone[property][tag]) formValueClone[property][tag].push(value)
				else formValueClone[property][tag] = [value]
			}
			else{
				formValueClone[property][tag] = [value]
			}
		}
		setFormValue(formValueClone);
    }

    async function addTag() {

        if(tags[tag]) return alert('Tags already has that tag')

        var tag = prompt("Please enter new tag");

        if(!tag) return

		const clone = _.clone(tags);
		clone[tag] = getRandomColor();
		setTags(clone);

        await firestore('constants').doc('recipeTags').update({value: firebase.firestore.FieldValue.arrayUnion(tag)});
    }

    async function removeTag() {

        var tag = prompt("Please enter tag you want to remove");

        if(!tag) return

        if(!tags[tag]) return alert('Tags do not have that tag')

		const formValueClone = _.clone(formValue);
		if(!formValueClone.tags) formValueClone.tags = []
		formValueClone.tags = formValueClone.tags.filter(item => item !== tag)
		setFormValue(formValueClone);

		const clone = _.clone(tags);
		delete clone[tag]
		setTags(clone);

		await firestore('constants').doc('recipeTags').update({value: firebase.firestore.FieldValue.arrayRemove(tag)});
    }

	return (
		<>
			<Modal.Header closeButton={!props.welnes}>
				<Modal.Title>
					{props.recipeData.key ? "Edit Recipe" : "Add Recipe"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={props.width === 12 ? { padding: "0", margin: "0" } : {}}
			>
				<Form
					id="file-upload-form"
					ref={inputImg}
					className="form"
					onSubmit={(e) => {
						submitForm(e);
					}}
				>
					<Form.Row className="add-recipe-container">
						<Col xs={6}>
							<Form.Group as={Col}>
								<Form.Label>Name*</Form.Label>
								<Form.Control
									required
									placeholder="Apple pie"
									value={formValue.name}
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										formValueClone.name = e.target.value;
										setFormValue(formValueClone);
									}}
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Type*</Form.Label>
								<div className="selectWrapper" style={{ paddingLeft: "0" }}>
									<Form.Control
										required
										as="select"
										placeholder="Select Type"
										value={formValue.type}
										onChange={(e) => {
											const formValueClone = _.clone(formValue);
											formValueClone.type = e.target.value;
											setFormValue(formValueClone);
										}}
									>
										{props.recipeTypes.map((type, i) => {
											return (
												<option value={type} key={type}>
													{type}
												</option>
											);
										})}
									</Form.Control>
								</div>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Portions</Form.Label>
								<Form.Control
									value={formValue.portions || 1}
									type="number"
									min="1"
									max="10"
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										formValueClone.portions = e.target.value;
										setFormValue(formValueClone);
									}}
								/>
							</Form.Group>
						</Col>
						<Col xs={6}>
							{recipeImage && (
								<div
									className="remove-img"
									onClick={() => {
										setIsImageChanged(false);
										setRecipeImage("");
										setImageFile(false);
										inputImg.current.reset();
									}}
								>
									<div className="icon">
										<FontAwesomeIcon icon={faTimes} size="sm" />
									</div>
								</div>
							)}
							<div className="d-flex photo-container">
								<div className="uploader">
									<input
										id="file-upload"
										type="file"
										name="fileUpload"
										accept="image/*"
										onChange={(e) => onImageUpload(e)}
									/>
									<label
										htmlFor="file-upload"
										id="file-drag"
										onDragOver={(e) => {
											fileDragHover(e);
										}}
										onDragLeave={(e) => {
											fileDragHover(e);
										}}
										onDrop={(e) => {
											fileSelectHandler(e);
										}}
									>
										<img
											id="file-image"
											src={recipeImage}
											className="hidden"
											style={{ borderRadius: "7px" }}
											alt=""
										/>
										<div id="start" className="d-flex">
											{!recipeImage && (
												<>
													<div>Select an image or drag here</div>
													<span
														id="file-upload-btn"
														className="button button-secondary"
														style={{ margin: "1em 0" }}
													>
														Select an image
													</span>
												</>
											)}
										</div>

										<div id="response" className="hidden">
											<div style={{ display: "none" }} id="messages"></div>
										</div>
										{imageError && (
											<div className="m-2 text-center text-danger">
												{imageError}
											</div>
										)}
									</label>
								</div>
							</div>
						</Col>
					</Form.Row>

					{!props.welnes && (
						<Form.Row style={{ marginBottom: "1em" }}>
							<Col xs={12}>
								<Form.Label>Ingredients (deprecated)</Form.Label>
								<div
									style={{
										whiteSpace: "break-spaces",
										border: "1px solid #9a9a9a",
										padding: "0.75em",
										borderRadius: "6px",
										background: "#f0f0f0",
										color: "#999",
										minHeight: "76.4%",
									}}
								>
									{formValue.ingredients}
								</div>
							</Col>
						</Form.Row>
					)}

					<Form.Row>
						<Col xs={12}>
							<Form.Group as={Col} style={{ position: "relative" }}>
								<ToggleButton
									style={{
										position: "absolute",
										right: "0",
										top: "0",
										opacity: 0.5,
										marginRight: "5px",
									}}
									type="checkbox"
									size="sm"
									variant={arabic ? "light" : "light"}
									className=""
									checked={arabic}
									onChange={(e) => {
										setArabic(e.target.checked);
										e.target.checked
											? localStorage.setItem("arabic", true)
											: localStorage.removeItem("arabic");
										reRenderIngredients();
									}}
								>
									{/*arabic && <FontAwesomeIcon icon={faRetweet} size="1x" className="mr-2" />*/}{" "}
									عربى
								</ToggleButton>
								<Form.Label>
									Ingredients{" "}
									{formValue.Ingredients.length ? (
										<span
											style={{
												color: "#777",
												marginLeft: "30px",
												fontSize: "14px",
												fontFamily: "monospace",
											}}
										>
											{formValue?.Calories || 0} kcal
										</span>
									) : null}
								</Form.Label>
								<div className="selectWrapper">
									<Select
										options={labelOutput}
										value={value}
										formatGroupLabel={formatGroupLabel}
										onChange={(e) => {
											// setValue(e.value);
											const formValueClone = _.clone(formValue);
											if (!formValueClone.Ingredients)
												formValueClone.Ingredients = [];

											if (
												formValueClone.Ingredients.filter(
													(d) => d.food === e.value
												).length
											)
												return alert(e.value + " has already been added");
											let newVal = ingredientsProps.filter(
												(val, i) => val.food === e.value
											);
											let joined = formValue.Ingredients.concat(
												JSON.parse(JSON.stringify(newVal))
											);

											formValueClone.Ingredients = joined;

											formValueClone.Ingredients =
												formValueClone.Ingredients.filter((d) => d.food);
											if (!formValueClone.Ingredients.quantity) {
												formValueClone.Ingredients.forEach((object) => {
													if (!object.quantity) object.quantity = 1;
													else return object.quantity;
												});
											}
											formValueClone.Calories = calories(
												formValueClone.Ingredients
											);

											setFormValue(formValueClone);
										}}

										// onChange={(opt) => console.log(opt.label, opt.value)}
									/>
								</div>

								{formValue.Ingredients.concat({}).map((d, i) => (
									<div key={i}>
										<Form.Row className="ingredients-container d-flex">
											{d.food && (
												<Col
													id="ingredients"
													lg={props.width === 12 ? 12 : 4}
													xs={12}
													style={{ paddingLeft: "0.4em" }}
												>
													<Form.Control
														disabled
														placeholder="ingredients"
														value={
															arabic
																? `${formValue.Ingredients[i].food} ${formValue.Ingredients[i].food_ar}`
																: formValue.Ingredients[i].food
														}
													/>
												</Col>
											)}
											{d.food && (
												<Col
													lg={props.width === 12 ? 3 : 2}
													xs={3}
													id="quantity"
												>
													<Form.Control
														required
														type="number"
														placeholder="quantity"
														min="0"
														step="any"
														className="quantity"
														value={
															formValue.Ingredients[i]
																? +formValue.Ingredients[i].quantity?.toFixed(2)
																: ""
														}
														onChange={(e, value) => {
															if (!formValue.Ingredients[i]) return;
															const formValueClone = _.clone(formValue);
															formValueClone.Ingredients[i].quantity =
																+e.target.value;
															formValueClone.Calories = calories(
																formValueClone.Ingredients
															);
															setFormValue(formValueClone);
														}}
													/>
												</Col>
											)}
											{d.quantity > 0 && d.food && (
												<span
													style={{
														color: "#333",
														whiteSpace: "nowrap",
														flex: "2 1",
													}}
												>
													{d.serving.split(" ")[0]}
												</span>
											)}
											{d.food && (
												<Col lg={props.width === 12 ? 3 : 2} xs={3} id="unit">
													<Form.Control
														required
														type="number"
														min="0"
														step="any"
														placeholder="unit"
														className="quantity"
														style={{
															fontSize: "12px",
															whiteSpace: "nowrap",
															flex: "1 1 1%",
														}}
														value={
															formValue.Ingredients[i]
																? +(d.quantity * d.unit_serving)?.toFixed(2)
																: 0
														}
														onChange={(e) => {
															if (!formValue.Ingredients[i]) return;
															const formValueClone = _.clone(formValue);
															formValueClone.Ingredients[i].quantity =
																+e.target.value / d.unit_serving;
															formValueClone.Calories = calories(
																formValueClone.Ingredients
															);
															setFormValue(formValueClone);
														}}
													/>
												</Col>
											)}
											{d.quantity > 0 && d.food && (
												<span
													style={{
														padding: "8px 5px",
														color: "#333",
														whiteSpace: "nowrap",
														fontSize: "0.825rem",
														flex: "1",
													}}
												>
													{d.unit}
												</span>
											)}
											<Col style={{ flex: 2 }}>
												{d.quantity > 0 && d.food && (
													<span
														style={{
															width: "9%",
															padding: "8px 3px",
															whiteSpace: "nowrap",
															textAlign: "right",
															fontSize: "0.825rem",
															fontWeight: "700",
															color: "#000",
														}}
													>
														{Math.round(d.quantity * d.calories_serving)}
													</span>
												)}
												{d.quantity > 0 && d.food && (
													<span
														style={{
															whiteSpace: "nowrap",
															fontSize: "0.825rem",
															fontWeight: "700",
															color: "#000",
														}}
													>
														kcal
													</span>
												)}
											</Col>
											{d.food && (
												<div
													className="remove-ingredient"
													onClick={() => {
														let index = formValue.Ingredients.indexOf(d);
														// delete formValue.Ingredients[index];
														let modifiedIngredients = [
															...formValue.Ingredients,
														];
														modifiedIngredients.splice(index, 1);
														let newCal = (formValue.Calories =
															calories(modifiedIngredients));
														setFormValue((prevValue) => {
															return {
																...prevValue,
																Ingredients: modifiedIngredients,
																Calories: newCal,
															};
														});

														// formValue.Ingredients.join("").split("");
													}}
												>
													<div>
														<FontAwesomeIcon icon={faTimes} size="sm" />
													</div>
												</div>
											)}
										</Form.Row>
									</div>
								))}
							</Form.Group>
						</Col>
					</Form.Row>
					{/*<Form.Row>
						<Col xs={12}>
							<Form.Group>
								<Form.Label>Tag</Form.Label>
								<div className="selectWrapper">
									<Form.Control
										className="select-tag"
										as="select"
										placeholder="Select Tag"
										value={formValue.tag}
										onChange={(e) => {
											const formValueClone = _.clone(formValue);
											formValueClone.tag = e.target.value;

											setFormValue(formValueClone);
										}}
									>
										{props.recipeTags.map((type, i) => {
											return (
												<option value={type} key={i} title={recipeTagMap[type]}>
													{type}
												</option>
											);
										})}
									</Form.Control>
								</div>
							</Form.Group>
						</Col>
					</Form.Row>*/}
					<Form.Row>
						<Col xs={12}>
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								rows="3"
								placeholder="Description"
								value={formValue.description}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.description = e.target.value;
									setFormValue(formValueClone);
								}}
							/>
						</Col>
					</Form.Row>
					<Form.Row>
						<Col xs="12">
							<Form.Group as={Col} controlId="videoLink">
								<Form.Label>Video Link</Form.Label>
								<Form.Control
									placeholder="Youtube link"
									type="url"
									value={formValue.videoLink}
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										formValueClone.videoLink = e.target.value.trim();
										setFormValue(formValueClone);
									}}
								/>
							</Form.Group>
						</Col>
					</Form.Row>

					<Form.Row>
						<Col xs="12">
							<Form.Label>Tags</Form.Label>

                            <div style={{maxWidth: 'initial', whiteSpace: 'normal', fontSize: '.8em', marginTop: '0px'}} 
                                >
                                <div style={{marginTop: '1em'}}>
                                    {Object.keys(tags).map(tag => 
                                        <div style={{marginBottom: '0px', display: 'flex'}}>
                                        	<span style={{marginRight: '7px', fontSize: '12px', padding: '3px 8px', whiteSpace: 'nowrap'}}>{tag} : </span> 
                                        	<span>
                                        		{tags[tag].values.map(value => <span 
		                                            className={"plan-tag"+(formValue.tags&&formValue.tags[tag]&&formValue.tags[tag].includes(value)?' selected':'')} 
		                                            style={{
		                                            	background: formValue.tags&&formValue.tags[tag]&&formValue.tags[tag].includes(value)? tags[tag].Color: tags[tag].color,
		                                            	cursor: 'pointer', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', display: 'inline-block'}} 
		                                            onClick={(e)=>{toggleTag('tags', tag, value, tags[tag].multi)}}>
		                                            {value} 
	                                        	</span>)}
                                        	</span>
                                        </div>
                                    )}
                                    {/*
                                    <span style={{border: '1px solid #ddd', color: '#222', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', cursor: 'pointer', display: 'inline-block'}} onClick={()=>{addTag()}}>+ new tag</span>
                                    <span style={{border: '1px solid #ddd', color: '#222', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', cursor: 'pointer', display: 'inline-block'}} onClick={()=>{removeTag()}}>- remove tag</span>
                                	*/}
                                </div>
                            </div>
						</Col>
					</Form.Row>

					<div style={{ margin: "2em 0" }}>
						{!props.readOnly && (
							<Button
								variant="primary"
								type="submit"
								style={{ margin: "auto", display: "flex" }}
								className=" submit-button button button-primary"
							>
								{isSubmitLoader ? (
									<Spinner animation="border" size={"sm"} role="status" />
								) : props.recipeData.key ? (
									"Save"
								) : (
									"Add"
								)}
							</Button>
						)}
					</div>
				</Form>
			</Modal.Body>
		</>
	);
}

export default AddRecipe;
