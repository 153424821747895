

import React, {useEffect, useState} from 'react';
import { Spinner, Breadcrumb, Image, Button, ToggleButton, Col, Row, Form } from "react-bootstrap";
import Chart from "react-apexcharts";

import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUndo, faDoorOpen} from '@fortawesome/free-solid-svg-icons'

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


function numberWithCommas(x) {
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function Lifetime(props) { 

    const [manualTxs, setManualTxs] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [uniqueUsers, setUniqueUsers] = useState(false);
    const [users, setUsers] = useState(false);
    const [monthly, setMonthly] = useState(false);
    const [days, setDays] = useState([]);
    const [data, setData] = useState();
    const [net, setNet] = useState(0);
    const [total, setTotal] = useState(0);
    const [nPayments, setnPayments] = useState(0);
    const [loader, setLoader] = useState(true);
    const [payments, setPayments] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [challengeById, setChallengeById] = useState({});
    const [challengeNutritionist, setChallengeNutritionist] = useState({});
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [challengesLoading, setChallengesLoading] = useState(true);
    const [challenge, setChallenge] = useState();
    const [ignore, setIgnore] = useState({});
    const [rounds, setRounds] = useState(false);
    const [format, setFormat] = useState('MMM DD');  //YYYY-MM-DD
    const [formatMonth, setFormatMonth] = useState('MMM');  //YYYY-MM-DD
    const [nutritionists, setNutritionists] = useState({
      options: {},
      series: [],
    });

    const [years, setYears] = useState([2020, 2021, 2022, 2023]);
    const [selectedYear, setSelectedYear] = useState(2023);
    const [coaches, setCoaches] = useState([]);
    const [coachesById, setCoachesById] = useState({});
    const [nActiveCoaches, setnActiveCoaches] = useState();
    const [nClientsPerCoach, setnClientsPerCoach] = useState();
    const [LTV, setLTV] = useState();
    const [usersByNutritionist, setUsersByNutritionist] = useState({});


    useEffect(() => {

        getChallenges();
        getCoaches();

    }, []);

    useEffect(() => {

        if(!Object.keys(challengeById).length || !Object.keys(coachesById).length) return

        setLoader(true)
        var unsubscribes = getCollections();

        return () => {

            console.log('Do some cleanup');
            unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, [selectedYear, challengeById, coachesById]);



    function getChallenges() {
        setChallengesLoading(true);
        return firestore('challenges')
            .get().then((querySnapshot) => {
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id,
                    };
                });
                //_.orderBy(groups, 'userCount', 'desc')
                //data = _.orderBy(data.filter(d => d.isPublished && !d.isFree), 'price', 'desc')


                const userDetailsStorage = JSON.parse(localStorage.getItem('userDetails'));
                const adminDetailsStorage = JSON.parse(localStorage.getItem('adminDetails'));

                if(!adminDetailsStorage.role.includes('admin')) data = data.filter(d => [adminDetailsStorage.id].concat(adminDetailsStorage.collaborate || []).includes(d.nutritionistId))

                var include = []
                data.map(d => {if(d.isPublished && !d.isFree && d.previousRounds) include = include.concat(d.previousRounds); return d})

                data = _.orderBy(data.filter(d => (d.isPublished || include.includes(d.key)) && !d.isFree), item => moment.utc(item.date).format(), 'desc')
                
                var challengeById = {}, challengeNutritionist = {}
                data.map(d => {

                    challengeById[d.key] = d
                    challengeNutritionist[d.key] = d.nutritionistName
                })
                setChallengeById(challengeById)
                setChallengeNutritionist(challengeNutritionist)


                var grouped = [], ignore = {}, firstRound = {}
                data.map(d => {

                    if(d.previousRounds){
                        d.rounds = d.previousRounds.length+1
                        d.previousRounds.map((id, i) => {

                            ignore[id] = true

                            if(i === d.previousRounds.length-1) firstRound[id] = true
                        })
                        d.roundsIds = [d.key].concat(d.previousRounds)
                    }

                    if(firstRound[d.key]) {
                        d.rounds = 1
                        d.roundsIds = [d.key]
                    }
                })
                
                setIgnore(ignore)
                
                setChallenges(data);
                setChallengesLoading(false);

            }, error => {
                console.log('error', error)
            });
    }

    function getCoaches() {
        return firestore('users')
            .where('role', 'array-contains', 'nutritionist')
            .get().then((querySnapshot) => {
                var coachesById = {}
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    coachesById[documentSnapshot.id] = documentSnapshot.data();
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id,
                    };
                });
                setCoaches(data);
                setCoachesById(coachesById);

            }, error => {
                console.log('error', error)
            });
    }

    function getCollections(){

        setPaymentsLoading(true)

        //challenge users - to figure out who is in without payments
        /*const subscribeUsers = firestore('users').where('joinedChallengeIds', 'array-contains', selectedChallenge)
            .onSnapshot(function(snapshot){

            const users = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            var manualTxs = []

            users.map((d) => {

                manualTxs.push({
                    manual: true,
                    date: '',
                    user: d.name,
                    userId: d.id,
                    currency: '',
                    amount: 0,
                    gateway: '-',
                    commission: 0,
                    net: 0
                })
            })

            setManualTxs(manualTxs)
        })*/


        const query = selectedYear === 'all'? firestore("payments") : firestore("payments")//.orderBy('datetime', 'asc')
            //.where('challengeId', '==', selectedChallenge)
            .where('datetime', '>=', moment(selectedYear+'-01-01').format())
            .where('datetime', '<=', moment(selectedYear+'-12-31').format())
            
        const subscribePayments = query.onSnapshot(function(snapshot){
            //.get().then(function(snapshot){

            const payments = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            var total = 0
            var nPayments = 0
            var commission = 0
            var paymentsByDay = {}
            var paymentsByChallenge = {}
            var paymentsByNutritionist = {}
            var usersByNutritionist = {}

            var userIds = {}

            var transactions = []

            payments.filter(d => !d.declined&&!d.refunded && d.amount /*&& moment(d.datetime).year === selectedYear*/).map((d) => {

                //account for 1 to 1
                if(d.months) challengeById[d.challengeId] = {

                    name: 'one to one',
                    nutritionistId: d.challengeId,
                    nutritionistName: coachesById[d.challengeId].name
                }

                //account for 1 to 1
                if(d.boxes) {
                    d.challengeId = 'box';
                    challengeById['box'] = {

                        name: 'box',
                        nutritionistId: 'box',
                        nutritionistName: 'box'
                    }
                }

                if(!challengeById[d.challengeId]) return
                const challenge = challengeById[d.challengeId].name + (challengeById[d.challengeId].previousRounds? ' - round '+(challengeById[d.challengeId].previousRounds.length+1):'')
                const date = moment(d.datetime).format(monthly? formatMonth : format)

                if(!paymentsByChallenge[challenge]) paymentsByChallenge[challenge] = {}
                if(!paymentsByChallenge[challenge][date]) paymentsByChallenge[challenge][date] = {users: 0, amount: 0}

                //fix for ramadan challenge
                if(d.challengeId === 'zNqJyCWb8ztMJLkt6zWy') d.amount = 300

                if(uniqueUsers){ 
                  if(!userIds[d.userId]) paymentsByChallenge[challenge][date].users++
                  userIds[d.userId] = true
                } 
                else paymentsByChallenge[challenge][date].users++
                paymentsByChallenge[challenge][date].amount+= d.amount


                if(!paymentsByNutritionist[challengeById[d.challengeId].nutritionistId]) 
                  paymentsByNutritionist[challengeById[d.challengeId].nutritionistId] = {amount: 0, nutritionistName : challengeById[d.challengeId].nutritionistName}
                  paymentsByNutritionist[challengeById[d.challengeId].nutritionistId].amount += d.amount


                var usdAmount;

                const appleCommission = { old: 0.32, new: 0.16 }[moment(d.datetime).isBefore('2021-01-01')?'old':'new']

                if(d.type == "credits"){

                  const amt = d.amount  //1:1 credits to usd
                  total+= amt
                  commission += (appleCommission*amt)
                  usdAmount = amt
                }
                else{

                  const amt = d.currency === 'USD'? d.amount : d.amount/16
                  total+= amt
                  commission += ((0.029*amt) + (0.3))
                  usdAmount = amt
                }


                if(!usersByNutritionist[challengeById[d.challengeId].nutritionistId]) 
                  usersByNutritionist[challengeById[d.challengeId].nutritionistId] = {users: {}, amount: 0, nutritionistName : challengeById[d.challengeId].nutritionistName, nutritionistId: challengeById[d.challengeId].nutritionistId}

                usersByNutritionist[challengeById[d.challengeId].nutritionistId].users[d.userId] = true
                usersByNutritionist[challengeById[d.challengeId].nutritionistId].amount += usdAmount


                nPayments++

                transactions.push({
                    date: d.datetime,
                    user: d.userName,
                    userId: d.userId,
                    currency: (d.type == "credits"? 'USD' : d.currency),
                    amount: d.amount,
                    gateway: d.provider,
                    commission: d.type == "credits" ? (appleCommission*d.amount) : ((0.029*d.amount) + (d.currency === 'USD'? 0.3 : 0.3 * 16)),
                    net: (d.amount - (d.type == "credits"? (appleCommission*d.amount) : ((0.029*d.amount) + (d.currency === 'USD'? 0.3 : 0.3 * 16))))
                })
            })

            payments.filter(d => d.refunded).map((d) => {

                nPayments++

                const appleCommission = { old: 0.32, new: 0.16 }[moment(d.datetime).isBefore('2021-01-01')?'old':'new']

                transactions.push({
                    refunded: true,
                    date: d.datetime,
                    user: d.userName,
                    userId: d.userId,
                    currency: (d.type == "credits"? 'USD' : d.currency),
                    amount: d.amount,
                    gateway: d.provider,
                    commission: d.type == "credits" ? (appleCommission*d.amount) : ((0.029*d.amount) + (d.currency === 'USD'? 0.3 : 0.3 * 16)),
                    net: (d.amount - (d.type == "credits"? (appleCommission*d.amount) : ((0.029*d.amount) + (d.currency === 'USD'? 0.3 : 0.3 * 16))))
                })
            })

            setTransactions(_.orderBy(transactions, d=>moment.utc(d.date).format(), 'desc'))


            setTotal(total)
            setNet(total - commission)
            setnPayments(nPayments)
            setnActiveCoaches(Object.keys(usersByNutritionist).length)
            setnClientsPerCoach(Object.keys(usersByNutritionist).map(d=> Object.keys(usersByNutritionist[d].users).length).reduce((a, b) => a + b, 0) / Object.keys(usersByNutritionist).length)
            //setLTV(Object.keys(usersByNutritionist).map(d=> usersByNutritionist[d].amount / Object.keys(usersByNutritionist[d].users).length).reduce((a, b) => a + b, 0) / Object.keys(usersByNutritionist).length)

            var totalAmount = 0
            var totalUsers = 0
            Object.keys(usersByNutritionist).map(d=> {

                totalAmount += usersByNutritionist[d].amount; 
                totalUsers += Object.keys(usersByNutritionist[d].users).length;
            })

            setLTV(totalAmount / totalUsers)

            setUsersByNutritionist(usersByNutritionist)

            setPaymentsLoading(false)

            //for(var day in pointsByDay){

                //pointsByDay[day].users = Object.keys(pointsByDay[day].user).length
            //}

            //var days = _.orderBy(Object.values(pointsByDay), 'day', 'asc').map((d)=>{d.day = 'day '+d.day; return d})

            //setDays(days);
        })

        return [subscribePayments]
    }

   return (<>

        <Breadcrumb className="pb-3">
            <Breadcrumb.Item active>lifetime value</Breadcrumb.Item>

            {false/*challengesLoading*/ ? <div><Spinner animation="border" size={'lg'} role="status" /></div> : challenges.length ?
                <>
                        
                    <Breadcrumb.Item active>
                        {years.concat(['all']).map((year, i) => 
                            <Button variant="dark" style={{background: year === selectedYear? 'antiquewhite':'#eee', borderColor:'#ccc', fontSize: '12px', padding: '3px', color: '#222', marginLeft: '10px'}} onClick={()=>{setSelectedYear(year);}}>{year}</Button>
                        )}
                    </Breadcrumb.Item>
                </> : 
            <div></div>}

        </Breadcrumb>

        <div id="billing" className="pt-5 h-100 align-self-center">

            {paymentsLoading ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
            <table>
                <tr>
                    <td className="key">No. of Coaches</td><td>⇢</td><td className="value">{coaches.filter(d=>moment(d.datetime).year() === selectedYear).length}</td>
                    <td className="space"></td>
                    <td className="key">No. of Active Coaches</td><td>⇢</td><td className="value">{nActiveCoaches}</td>
                    <td className="space"></td>
                    <td className="key">Clients/Coach</td><td>⇢</td><td className="value">{nClientsPerCoach.toFixed(0)}</td>
                    <td className="space"></td>
                    <td className="key">LTV</td><td>⇢</td><td className="value">${LTV.toFixed(0)}</td>
                </tr>
            </table>}

        </div>

        {!paymentsLoading && <table id="transactions" class="table table-striped" style={{marginTop: '5em'}}>
            <thead>
            <tr>
                <th>Coach</th>
                <th>Clients/Coach</th>
                <th>LTV</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(usersByNutritionist).sort(function(a, b){return Object.keys(usersByNutritionist[b].users).length - Object.keys(usersByNutritionist[a].users).length}).map((d,k) => <tr key={k}>
                <td>{usersByNutritionist[d].nutritionistName}</td>
                <td>{Object.keys(usersByNutritionist[d].users).length}</td>
                <td>${(usersByNutritionist[d].amount / Object.keys(usersByNutritionist[d].users).length).toFixed(0)}</td>
            </tr>)}
        </tbody>
        </table>}

    </>);
}