import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import * as _ from "lodash";

export function QuestionFilter(props) {
    const totalDays = 30;
    const defaultFilter = {
        challengeId: '',
        challengeName: '',
        day: '',
        duration: '',
        sets: '',
        reps: ''
    };
    const [filterData, setFilterData] = useState({});
    useEffect(() => {
        if (props.filterData) {
            setFilterData(props.filterData)
        } else {
            setFilterData(defaultFilter)
        }
    }, []);
    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();
        props.handleClose({ filterData });
    }
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Question Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="form" onSubmit={(e) => {
                    submitForm(e)
                }}>
                    <Form.Group>
                        <Form.Label>Challenge</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" placeholder="Select challenge"
                                value={filterData.challengeId} onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.challengeId = e.target.value;
                                    // const index = props.challenges.findIndex((o) => {
                                    //     return o.key === filterData.challengeId;
                                    // });
                                    // if (index > -1) {
                                    //     filterDataClone.challengeName = props.challenges[index].name;
                                    // }
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select challenge</option>
                                {props.challenges.map((challenge, i) => {
                                    return (
                                        <option value={challenge.key} key={challenge.key}>{challenge.name}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <div className="float-right">
                        <Button variant="danger" className="submit-button mr-3" onClick={() => {
                            props.handleClose({ filterData: {} })
                        }}>
                            Reset
                            </Button>
                        <Button variant="primary" type="submit" className="submit-button">
                            Apply
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </>
    );
}

export default QuestionFilter;
