

import React, {useEffect, useState} from 'react';
import { Spinner, Breadcrumb, Image, Button, ToggleButton, Col, Row, Form } from "react-bootstrap";
import Chart from "react-apexcharts";
import firebase from 'firebase/compat/app';
import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";

import userImg from "../../assets/images/user.png";
import { confirm } from "../../components/Confirmation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMinus, faPlus, faUserPlus, faUserTimes, faTimes, faUserFriends} from '@fortawesome/free-solid-svg-icons'

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file



function numberWithCommas(x) {
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function Roles(props) { 

    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [coachs, setCoachs] = useState([]);

    useEffect(() => {

    }, []);

    useEffect(() => {

        setLoader(true)
        var unsubscribes = getCollections();

        return () => {

            console.log('Do some cleanup');
            unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);


    function getCollections(){

        setLoading(true)

        const subscribe = firestore("users")
            .where('role', "array-contains-any", ["admin", "nutritionist", "collaborator", "assistant"])
            .onSnapshot(function(snapshot){
            //.get().then(function(snapshot){

            const users = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            var admins = []
            var coachs = []

            var collaborators = {}
            var assistants = {}

            users.map((d) => {

                if(d.role.includes('admin')) admins.push(d)
                if(d.role.includes('nutritionist')) coachs.push(d)

                if(d.role.includes('collaborator') && d.collaborate) d.collaborate.map(c => {

                    if(!collaborators[c]) collaborators[c] = [] 
                    collaborators[c].push(d)
                })

                if(d.role.includes('assistant') && d.assist) d.assist.map(c => {

                    if(!assistants[c]) assistants[c] = [] 
                    assistants[c].push(d)
                })
            })

            coachs.map((d) => {

                if(collaborators[d.id]) collaborators[d.id].map(c => {

                    if(d.collaborators && d.collaborators.includes(c.id)){

                        if(!d.cl) d.cl = []
                        d.cl.push(c)
                    }
                })

                if(assistants[d.id]) assistants[d.id].map(a => {

                    if(d.assistants && d.assistants.includes(a.id)){

                        if(!d.as) d.as = []
                        d.as.push(a)
                    }
                })
            })

            setAdmins(admins)
            setCoachs(coachs)

            setLoading(false)

            //for(var day in pointsByDay){

                //pointsByDay[day].users = Object.keys(pointsByDay[day].user).length
            //}

            //var days = _.orderBy(Object.values(pointsByDay), 'day', 'asc').map((d)=>{d.day = 'day '+d.day; return d})

            //setDays(days);
        })

        return [subscribe]
    }

    function fbImage(imageLink){

        if(!imageLink) return imageLink
        if(!imageLink.split) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    async function editRole(edit, role, user = false, targetUser = false){

        if(!user){

            var findBy = parseInt(prompt('find '+role+' by'+'\n\n'+'1. name'+'\n'+'2. email', '1')) === 1? 'name' : 'email';
            var userName = prompt("Please enter "+role+" "+findBy);

            if(!userName) return
                    
            const userDocs = await firestore("users").where(findBy, '==', userName).get();

            var userIndex = 0
            if(userDocs.size>1) {

                var msg = '('+userDocs.size+') users exist with the same name: '+userName+'\n\n'

                msg += userDocs.docs.map((doc, i) => String(i+1)+'. '+' phone no. '+doc.data().mobileNumber+ ' email: '+doc.data().email).join('\n')

                userIndex = parseInt(prompt(msg, '1')) - 1;
            }

            if(!userDocs.size) return alert('no user with that name')

            var userId = userDocs.docs[userIndex].id

            if(!userId) return alert("no user")

            user = userDocs.docs[userIndex].data()
            user.id = userId
        }

        if(targetUser && user && edit === 'add'){

            if(targetUser.collaborators && targetUser.collaborators.includes(user.id)) return alert(targetUser.name + ' already has ' + user.name + ' as collaborator')
            if(targetUser.assistants && targetUser.assistants.includes(user.id)) return alert(targetUser.name + ' already has ' + user.name + ' as assistant')
        }

        if (await confirm(
            edit+' '+role,
            'are you sure you want to '+edit+' '+role+' '+(user?user.name:'')+(targetUser?((edit==='add'?' to ':' from ') + 'nutritionist ' + targetUser.name) : '')+' ?'
            , edit)){

            var skipRole = 
                (edit === 'remove' && role === 'assistant' && user.assist && user.assist.length > 1) ||
                (edit === 'remove' && role === 'collaborator' && user.collaborate && user.collaborate.length > 1)
            
            if(!skipRole) await firestore('users').doc(user.id).update({role: edit==='add'? firebase.firestore.FieldValue.arrayUnion(role) : firebase.firestore.FieldValue.arrayRemove(role)});

            if(role === 'collaborator'){

                await firestore('users').doc(user.id).update({collaborate: edit==='add'? firebase.firestore.FieldValue.arrayUnion(targetUser.id) : firebase.firestore.FieldValue.arrayRemove(targetUser.id)});
                await firestore('users').doc(targetUser.id).update({collaborators: edit==='add'? firebase.firestore.FieldValue.arrayUnion(user.id) : firebase.firestore.FieldValue.arrayRemove(user.id)});
            }

            if(role === 'assistant'){

                await firestore('users').doc(user.id).update({assist: edit==='add'? firebase.firestore.FieldValue.arrayUnion(targetUser.id) : firebase.firestore.FieldValue.arrayRemove(targetUser.id)});
                await firestore('users').doc(targetUser.id).update({assistants: edit==='add'? firebase.firestore.FieldValue.arrayUnion(user.id) : firebase.firestore.FieldValue.arrayRemove(user.id)});
            }
        }
            
        return
    }

   return (<>

        <Breadcrumb className="pb-3">
            <Breadcrumb.Item active>Roles</Breadcrumb.Item>

        </Breadcrumb>

        <div id="roles" className="pt-5 h-100 align-self-center">

            {loading ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
            <div>
                <h3 style={{margin: '2em 2em 1em 2em', textAlign: 'center'}}>admins <FontAwesomeIcon title="add admin" onClick={() => editRole('add', 'admin')} icon={faUserPlus} size="1x" style={{color: '#ccc', cursor: 'pointer', paddingLeft: '10px', boxSizing: 'content-box'}}/></h3> 
                {admins.map(d => 
                    <div key={d.id} style={{display: 'inline-block', margin: '2em', padding: '1em', textAlign: 'center', position: 'relative'}}>
                        <FontAwesomeIcon title="remove admin" onClick={() => editRole('remove', 'admin', d)} icon={faTimes} size="1x" style={{position: 'absolute', top: 0, right: 0, color: '#ddd', cursor: 'pointer', padding: '5px', fontSize: '30px'}}/>
                        <span style={{display: 'inline-block', width: '100px', height: '100px', borderRadius: '50px', backgroundImage: 'url('+(d.image ? fbImage(d.image) : userImg)+')', backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
                        <span style={{display: 'block', color: '#464646'}}>{d.name}</span>
                    </div>
                )}

                <h3 style={{margin: '3em 2em 1em 2em', textAlign: 'center'}}>coachs <FontAwesomeIcon title="add nutritionist" onClick={() => editRole('add', 'nutritionist')} icon={faUserPlus} size="1x" style={{color: '#ccc', cursor: 'pointer', paddingLeft: '10px', boxSizing: 'content-box'}}/></h3> 
                {coachs.map(d => 
                    <div key={d.id} style={{position: 'relative', display: 'block', margin: '7em 3em', paddingBottom: '5em', textAlign: 'center', borderBottom: '1px solid #e3e3e3'}}>
                        {/*d.cl && <div style={{position: 'absolute', top: 0, left: 0}}>

                            {d.cl.map(c => 
                                <div key={c.id} style={{position: 'relative', display: 'block', padding: '40px', textAlign: 'center'}}>
                                    <FontAwesomeIcon title="remove collaborator" onClick={() => editRole('remove', 'collaborator', c, d)} icon={faTimes} size="1x" style={{position: 'absolute', top: '100px', right: 0, color: '#ddd', cursor: 'pointer', transform: 'translateY(-50%)', padding: '10px', fontSize: '16px', boxSizing: 'content-box'}}/>
                                    <span style={{display: 'inline-block', width: '120px', height: '120px', borderRadius: '10px', backgroundImage: 'url('+(d.image ? fbImage(d.image) : userImg)+')', backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
                                    <span style={{display: 'block', color: '#464646', fontSize: '.95em'}}>{c.name}</span>
                                </div>
                            )}
                        </div>*/}
                        <div>

                            <span style={{position: 'relative', display: 'inline-block', width: '200px', height: '200px', borderRadius: '20px', backgroundImage: 'url('+(d.image ? fbImage(d.image) : userImg)+')', backgroundSize: 'cover', backgroundPosition: 'center'}}>

                                <FontAwesomeIcon title="remove nutritionist" onClick={() => editRole('remove', 'nutritionist', d)} icon={faTimes} size="1x" style={{position: 'absolute', top: '-40px', left: '50%', color: '#ddd', cursor: 'pointer', transform: 'translateX(-50%)', padding: '10px', fontSize: '16px', boxSizing: 'content-box'}}/>

                                <span style={{display: 'inline-block', verticalAlign: 'sub', position: 'absolute', top: '100px', transform: 'translateY(-50%)', left: '-100px', padding: '10px', fontSize: '20px', boxSizing: 'content-box'}}>
                                    <FontAwesomeIcon title="add collaborator" onClick={() => editRole('add', 'collaborator', false, d)} icon={faUserPlus} size="1x" style={{color: '#ddd', opacity: .7, cursor: 'pointer', padding: '5px', fontSize: '30px'}}/>
                                </span>
                                <span style={{display: 'inline-block', verticalAlign: 'sub', position: 'absolute', top: '100px', transform: 'translateY(-50%)', right: '-100px', padding: '10px', fontSize: '20px', boxSizing: 'content-box'}}>
                                    <FontAwesomeIcon title="add assistant" onClick={() => editRole('add', 'assistant', false, d)} icon={faUserFriends} size="1x" style={{color: '#ddd', opacity: .7, cursor: 'pointer', padding: '5px', paddingRight: 0, fontSize: '30px'}}/>
                                </span>
                            </span>
                            <span style={{display: 'block'}}>{d.name}</span>
                            
                        </div>
                        {d.cl && <div style={{margin: '3em 1em 0em 1em'}}>

                            {d.cl.map(c => 
                                <div key={c.id} style={{display: 'inline-block', padding: '40px', textAlign: 'center', position: 'relative'}}>
                                    <FontAwesomeIcon title="remove collaborator" onClick={() => editRole('remove', 'collaborator', c, d)} icon={faTimes} size="1x" style={{position: 'absolute', top: 0, left: '50%', color: '#ddd', cursor: 'pointer', transform: 'translateX(-50%)', padding: '10px', fontSize: '16px', boxSizing: 'content-box'}}/>
                                    <span style={{display: 'inline-block', width: '120px', height: '120px', borderRadius: '10px', backgroundImage: 'url('+(c.image ? fbImage(c.image) : userImg)+')', backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
                                    <span style={{display: 'block', color: '#464646', fontSize: '.95em'}}>{c.name}</span>
                                </div>
                            )}
                        </div>}
                        {d.as && <div style={{margin: '0em 1em 0 1em'}}>

                            {d.as.map(a => 
                                <div key={a.id} style={{display: 'inline-block', padding: '40px', textAlign: 'center', position: 'relative'}}>
                                    <FontAwesomeIcon title="remove assistant" onClick={() => editRole('remove', 'assistant', a, d)} icon={faTimes} size="1x" style={{position: 'absolute', top: 0, left: '50%', color: '#ddd', cursor: 'pointer', transform: 'translateX(-50%)', padding: '10px', fontSize: '16px', boxSizing: 'content-box'}}/>
                                    <span style={{display: 'inline-block', width: '100px', height: '100px', borderRadius: '10px', backgroundImage: 'url('+(a.image ? fbImage(a.image) : userImg)+')', backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
                                    <span style={{display: 'block', color: '#464646', fontSize: '.95em'}}>{a.name}</span>
                                </div>
                            )}
                        </div>}
                    </div>
                )}
            </div>
            }

        </div>
    </>);
}