export function fbImage(imageLink) {
    if (!imageLink) return imageLink;
    if (!imageLink.split) return imageLink;
    var query = imageLink.split("asid=")[1];
    if (!query) return imageLink;
    var fbId = query.split("&")[0];
    if (!fbId) return imageLink;

    return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
}

export function numberWithCommas(x) {
    if(!x) return 0;
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
// drag and drop
export function fileDragHover(e) {
    var fileDrag = document.getElementById("file-drag");
    e.stopPropagation();
    e.preventDefault();
    fileDrag.className =
        e.type === "dragover" ? "hover" : "modal-body file-upload";
}
export function fileDragHover2(e) {
    var fileDrag = document.getElementById("file-drag2");
    e.stopPropagation();
    e.preventDefault();
    fileDrag.className =
        e.type === "dragover" ? "hover" : "modal-body file-upload";
}

function output(msg) {
    // Response
    var m = document.getElementById("messages");
    m.innerHTML = msg;
}
function output2(msg) {
    // Response
    var m = document.getElementById("messages2");
    m.innerHTML = msg;
}

export function parseFile(file) {
    output("<strong>" + encodeURI(file.name) + "</strong>");

    // var fileType = file.type;
    var imageName = file.name;

    var isGood = true///\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
    if (isGood) {
        document.getElementById("start").classList.add("hidden");
        document.getElementById("response").classList.remove("hidden");
        // Thumbnail Preview
        document.getElementById("file-image").classList.remove("hidden");
        document.getElementById("file-image").src = URL.createObjectURL(file);
    } else {
        document.getElementById("file-image").classList.add("hidden");
        document.getElementById("start").classList.remove("hidden");
        document.getElementById("response").classList.add("hidden");
        document.getElementById("file-upload-form").reset();
    }
}

export function parseFile2(file) {
    output2("<strong>" + encodeURI(file.name) + "</strong>");

    // var fileType = file.type;
    var imageName = file.name;

    var isGood = true///\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
    if (isGood) {
        document.getElementById("start2").classList.add("hidden");
        document.getElementById("response2").classList.remove("hidden");
        // Thumbnail Preview
        document.getElementById("file-image2").classList.remove("hidden");
        document.getElementById("file-image2").src = URL.createObjectURL(file);
    } else {
        document.getElementById("file-image2").classList.add("hidden");
        document.getElementById("start2").classList.remove("hidden");
        document.getElementById("response2").classList.add("hidden");
        document.getElementById("file-upload-form2").reset();
    }
}

