import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col } from 'react-bootstrap';
import * as _ from "lodash";

export function UserFilter(props) {
    const maxAge = 100;
    const maxWeight = 500;
    // const bmr = {
    //     min: 200,
    //     max: 5000
    // }
    // const getBmrList = () => {
    //     const list = [];
    //     for (let i = bmr.min; i < bmr.max; i++) {
    //         list.push(<option value={i} key={i}>{i}</option>);
    //     }
    //     return list;
    // };
    const genderList = ['Male', 'Female'];
    const languageList = ['Ar', 'En'];
    const defaultFilter = {
        name: '',
        equipment: '',
        workoutPlanId: '',
        mealPlanId: '',
        groupId: '',
        gender: '',
        country: '',
        language: '',
        allergy: '',
        previousRounds: ''
    };
    const [filterData, setFilterData] = useState({});
    useEffect(() => {
        if (props.filterData) {
            setFilterData(props.filterData)
        } else {
            setFilterData(defaultFilter)
        }
    }, []);
    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();
        props.handleClose({ filterData });
    }
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{props.settingRule? 'Select Conditions' : 'User Filter'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="form user-filter" onSubmit={(e) => {
                    submitForm(e)
                }}>
                    {!props.settingRule && <Form.Group>
                        <Form.Label>Group</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.groupId}
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.groupId = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Group</option>
                                <option value={'unassigned'} key={'unassigned'}> -- unassigned -- </option>
                                {props.groups.map((o) => {
                                    console.log(o.key,o.name)
                                    return (<option value={o.key} key={o.key}>{o.name}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>}
                    {!props.settingRule && <Form.Group>
                        <Form.Label>Meal Plan</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.mealPlanId}
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.mealPlanId = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Meal Plan</option>
                                <option value={'unassigned'} key={'unassigned'}> -- unassigned -- </option>
                                {props.mealPlans.map((o) => {
                                    return (<option value={o.key} key={o.key}>{o.name}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>}
                    {!props.settingRule && <Form.Group>
                        <Form.Label>Workout Plan</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.workoutPlanId}
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.workoutPlanId = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Workout Plan</option>
                                <option value={'unassigned'} key={'unassigned'}> -- unassigned -- </option>
                                {props.workoutPlans.map((o) => {
                                    return (<option value={o.key} key={o.key}>{o.name}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>}
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control as="input" type="text" placeholder="Name" value={filterData.name}
                            onChange={e => {
                                const filterDataClone = _.clone(filterData);
                                filterDataClone.name = e.target.value;
                                setFilterData(filterDataClone);
                            }}>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Gender</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.gender} placeholder="Gender"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.gender = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Gender</option>
                                {genderList.map((o) => {
                                    return (<option value={o} key={o}>{o}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Country</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.country} placeholder="Language"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.country = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Country</option>
                                <option value={'not-EG'} key={'not-EG'}>outside Egypt</option>
                                {Object.keys(props.countries).map((o) => {
                                    return (<option value={o} key={o}>{props.countriesObj[o]}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>
                    {/*<Form.Group>
                        <Form.Label>Language</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.language} placeholder="Language"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.language = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Language</option>
                                {languageList.map((o) => {
                                    return (<option value={o} key={o}>{o}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>*/}
                    <Form.Group>
                    <Form.Group>
                        <Form.Label>Medical Condition</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.medicalCondition}
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.medicalCondition = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Medical Condition</option>
                                {Object.keys(props.medicalConditions).map((o) => {
                                    return (<option value={o} key={o}>{o}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>
                        <Form.Label>Pregnant or Breastfeeding</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.isPregnantOrBreastfeeding} placeholder="Pregnant or Breastfeeding"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);

                                    filterDataClone.isPregnantOrBreastfeeding = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select</option>
                                <option value="Pregnant" key="Pregnant">Pregnant</option>
                                <option value="Breast feeding" key="Breastfeeding">Breast feeding</option>
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Meal Preference</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.dietType} placeholder="Diet Type"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.dietType = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Meal Preference</option>
                                <option value="Standard" key="1">Standard</option>
                                <option value="Plant Based" key="2">Plant Based</option>
                                <option value="Pescatarian" key="3">Pescatarian</option>
                                <option value="Low Carb" key="4">Low Carb</option>
                                <option value="Keto" key="5">Keto</option>
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Workout Preference</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.exerciseType} placeholder="Exercise Type"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.exerciseType = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Workout Preference</option>
                                <option value="home" key="1">home</option>
                                <option value="gym" key="2">gym</option>
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Goal</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.selectedGoal} placeholder="Goal"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.selectedGoal = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Goal</option>
                                <option value="Weight loss" key="1">Weight loss</option>
                                <option value="Muscle gain" key="2">Muscle gain</option>
                                <option value="Healthy lifestyle" key="3">Healthy lifestyle</option>
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fitness Level</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.fitnessLevel} placeholder="Fitness Level"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.fitnessLevel = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Fitness Level</option>
                                <option value="1" key="1">level 1. Beginner</option>
                                <option value="2" key="2">level 2. Intermediate</option>
                                <option value="3" key="3">level 3. Professional</option>
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Allergy</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.allergy} placeholder="Allergy"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.allergy = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Allergy</option>
                                {Object.keys(props.allergies).map((o) => {
                                    return (<option value={o} key={o}>{o}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Previous diet attempts</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.fitnessLevel} placeholder="Diet Attempts"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.dietAttempts = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select diet attempts</option>
                                <option value="None" key="1">None</option>
                                <option value="Low-calorie diet" key="2">Low-calorie diet</option>
                                <option value="Keto/Low Carb" key="3">Keto/Low Carb</option>
                                <option value="Intermittent Fasting" key="3">Intermittent Fasting</option>
                                <option value="Chemical diet" key="3">Chemical diet</option>
                            </Form.Control>
                        </div>
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} md={6}>
                            <Form.Label>Start Age</Form.Label>
                            <div className="selectWrapper">
                                <Form.Control as="select" value={filterData.startAge}
                                    onChange={e => {
                                        const filterDataClone = _.clone(filterData);
                                        filterDataClone.startAge = e.target.value;
                                        setFilterData(filterDataClone);
                                    }}>
                                    <option value={''} key={''}>Select Start Age</option>
                                    {Array.from(Array(maxAge), (a, i) => {
                                        return (<option value={i + 1} key={i + 1}>{i + 1}</option>)
                                    })}
                                </Form.Control>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                            <Form.Label>End Age</Form.Label>
                            <div className="selectWrapper">
                                <Form.Control as="select" value={filterData.endAge}
                                    onChange={e => {
                                        const filterDataClone = _.clone(filterData);
                                        filterDataClone.endAge = e.target.value;
                                        setFilterData(filterDataClone);
                                    }}>
                                    <option value={''} key={''}>Select End Age</option>
                                    {Array.from(Array(maxAge), (a, i) => {
                                        return (<option value={i + 1} key={i + 1}>{i + 1}</option>)
                                    })}
                                </Form.Control>
                            </div>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md={6}>
                            <Form.Label>Start Weight</Form.Label>
                            <div className="selectWrapper">
                                <Form.Control as="select" value={filterData.startWeight}
                                    onChange={e => {
                                        const filterDataClone = _.clone(filterData);
                                        filterDataClone.startWeight = e.target.value;
                                        setFilterData(filterDataClone);
                                    }}>
                                    <option value={''} key={''}>Select Start Weight</option>
                                    {Array.from(Array(maxWeight), (a, i) => {
                                        return (<option value={i + 1} key={i + 1}>{i + 1}</option>)
                                    })}
                                </Form.Control>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                            <Form.Label>End Weight</Form.Label>
                            <div className="selectWrapper">
                                <Form.Control as="select" value={filterData.endWeight}
                                    onChange={e => {
                                        const filterDataClone = _.clone(filterData);
                                        filterDataClone.endWeight = e.target.value;
                                        setFilterData(filterDataClone);
                                    }}>
                                    <option value={''} key={''}>Select End Weight</option>
                                    {Array.from(Array(maxWeight), (a, i) => {
                                        return (<option value={i + 1} key={i + 1}>{i + 1}</option>)
                                    })}
                                </Form.Control>
                            </div>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md={6}>
                            <Form.Label>From BMR</Form.Label>
                            <Form.Control as="input" type="number" placeholder=" start BMR" value={filterData.startBmr}
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.startBmr = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                            <Form.Label>To BMR</Form.Label>
                            <Form.Control as="input" type="number" placeholder=" end BMR" value={filterData.endBmr}
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.endBmr = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md={6}>
                            <Form.Label>From BMI</Form.Label>
                            <Form.Control as="input" type="number" placeholder=" start BMI" value={filterData.startBmi}
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.startBmi = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                            <Form.Label>To BMI</Form.Label>
                            <Form.Control as="input" type="number" placeholder=" end BMI" value={filterData.endBmi}
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.endBmi = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group>
                        <Form.Label>BMI Class</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.bmiClass} placeholder="BMI Class"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.bmiClass = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select BMI Class</option>
                                <option value="Under Weight" key="Under Weight">Under Weight</option>
                                <option value="Normal Weight" key="Normal Weight">Normal Weight</option>
                                <option value="Over Weight" key="Over Weight">Over Weight</option>
                                <option value="Obesity Class I" key="Obesity Class I">Obesity Class I</option>
                                <option value="Obesity Class II" key="Obesity Class II">Obesity Class II</option>
                                <option value="Obesity Class III" key="Obesity Class III">Obesity Class III</option>
                            </Form.Control>
                        </div>
                    </Form.Group>

                    {props.challenge && props.challenge.previousRounds && props.challenge.previousRounds.length && <Form.Row>
                        <Form.Group as={Col} md={6}>
                            <Form.Label>Min Rounds</Form.Label>
                            <Form.Control as="input" type="number" min="0" max={props.challenge.previousRounds.length} placeholder="min completed rounds" value={filterData.minRounds}
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.minRounds = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                            <Form.Label>Max Rounds</Form.Label>
                            <Form.Control as="input" type="number" min="0" max={props.challenge.previousRounds.length} placeholder="max completed rounds" value={filterData.maxRounds}
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.maxRounds = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>}

                    <div className="float-right">
                        <Button variant="danger" className="submit-button mr-3" onClick={() => {
                            props.handleClose({ filterData: {} })
                        }}>
                            Reset
                            </Button>
                        <Button variant="primary" type="submit" className="submit-button">
                            Apply
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </>
    );
}

export default UserFilter;
