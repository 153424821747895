
import moment from "moment";


export default function getDollarAtTime (datetime){
    const dollarRate = {
        old: 16,
        new: 18.5,
        new2: 18.7,
        new3: 18.94,
        new4: 19.13,
        new5: 19.43,
        new6: 23.15,
        new7: 24.4,
        new8: 26.45,
        new9: 27.2,
        new10: 30.0,
        new11: 30.28,
        new12: 30.9,
        new13: 49.35,
        new14: 49.05,
        new15: 48.80,
        new16: 48.45,
        new17: 47.80,
        new18: 47.11,
        new19: 46.90,
        new20: 46.65,
        new21: 46.50,
        new22: 46.79,
        new23: 47.40,
        new24: 47.82,
        new25: 47.85,
        new26: 48.26,
        new27: 48.50,
        new28: 48.26,
        new29: 47.91,
        new30: 48.71,
        new31: 49.25,
      }[
        moment(datetime).isBefore("2022-03-22")
          ? "old"
          : moment(datetime).isBefore("2022-06-13")
          ? "new"
          : moment(datetime).isBefore("2022-07-21")
          ? "new2"
          : moment(datetime).isBefore("2022-08-11")
          ? "new3"
          : moment(datetime).isBefore("2022-09-17")
          ? "new4"
          : moment(datetime).isBefore("2022-10-28")
          ? "new5"
          : moment(datetime).isBefore("2022-11-12")
          ? "new6"
          : moment(datetime).isBefore("2023-01-04")
          ? "new7"
          : moment(datetime).isBefore("2023-01-05")
          ? "new8"
          : moment(datetime).isBefore("2023-01-11")
          ? "new9"
          : moment(datetime).isBefore("2023-02-02")
          ? "new10"
          : moment(datetime).isBefore("2023-03-20")
          ? "new11"
          : moment(datetime).isBefore("2024-03-06 11:00")
          ? "new12"
          : moment(datetime).isBefore("2024-03-11")
          ? "new13"
          : moment(datetime).isBefore("2024-03-12")
          ? "new14"
          : moment(datetime).isBefore("2024-03-13")
          ? "new15"
          : moment(datetime).isBefore("2024-03-14")
          ? "new16"
          : moment(datetime).isBefore("2024-03-18")
          ? "new17"
          : moment(datetime).isBefore("2024-03-20")
          ? "new18"
          : moment(datetime).isBefore("2024-03-21")
          ? "new19"
          : moment(datetime).isBefore("2024-03-22")
          ? "new20"
          : moment(datetime).isBefore("2024-03-24")
          ? "new21"
          : moment(datetime).isBefore("2024-03-25")
          ? "new22"
          : moment(datetime).isBefore("2024-03-26")
          ? "new23"
          : moment(datetime).isBefore("2024-03-27")
          ? "new24"
          : moment(datetime).isBefore("2024-04-15")
          ? "new25"
          : moment(datetime).isBefore("2024-04-16")
          ? "new26"
          : moment(datetime).isBefore("2024-04-18")
          ? "new27"
          : moment(datetime).isBefore("2024-04-23")
          ? "new28"
          : moment(datetime).isBefore("2024-08-01")
          ? "new29"
          : moment(datetime).isBefore("2024-08-05")
          ? "new30"
          : "new31"
      ];

      return dollarRate;
}