import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Form,
    Modal,
    ButtonToolbar,
    Table,
    DropdownButton,
    Dropdown,
    Spinner,
    Breadcrumb
} from "react-bootstrap";
import Masonry from 'react-masonry-css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faFilter, faPencilAlt, faCopy, faTrashAlt, faArrowUp, faArrowDown, faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { confirm } from "../../components/Confirmation";
import { firestore } from "../../services/firebase";
import AddPromocode from '../../components/AddPromocode';
import * as _ from "lodash";
import DeleteAlert from '../../components/DeleteAlert';
import moment from 'moment';

library.add(faPlus, faFilter);

export function Notifications() {
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [reportsLoader, setReportsLoader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');
    const [refs, setRefs] = useState([]);
    const [sort, setSort] = useState({
        field: 'datetime',
        direction: 'desc'
    });

    const tableHeaders = [{
        name: 'Name',
        filterField: 'name',
    //}, {
    //    name: '',
    //    filterField: 'none',
    }, {
        name: 'Date',
        filterField: 'datetime',
    }, {
        name: 'Type',
        filterField: 'type',
    }, {
        name: 'Kcal',
        filterField: 'calories',
    //}, {
    //    name: 'Actions',
    //    filterField: 'none',
    }];
    const [transactions, setTransactions] = useState([]);
    const [reports, setReports] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [promocodeIdToName, setPromocodeIdToName] = useState();
    const [challengeIdToName, setChallengeIdToName] = useState();
    const [showSub, setShowSub] = useState(false);

    useEffect(() => {
        const ReportsSubscription = getReports();
        return () => {
            ReportsSubscription();
        };
    }, []);


    function getReports() {
        setReportsLoader(true);
        return firestore('notifications')
            .orderBy('created', 'desc')
            .limit(10000)
            .onSnapshot((querySnapshot) => {
                let refs = []
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    refs.push(documentSnapshot.ref)
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });

                var lastId, object
                data.map(d => {

                    if(lastId === d.payload.notification.body){

                        if(object.sub) object.sub.push(d)
                        else object.sub = [d]

                        d.remove = true
                    }
                    else{

                        lastId = d.payload.notification.body
                        object = d
                    }
                })

                setReportsLoader(false);
                setReports(data);
                setRefs(refs);
            });
    }

    async function clear(d){

        if (await confirm(
            'Clear notifications',
            'are you sure you want to '+'clear all notifications from logs ?'
            , 'clear')){
            
            refs.map(d => {d.delete();})
        }
    }

    return (
        <>
            <Breadcrumb className="pb-3">
                <Breadcrumb.Item active>Notifications ({reports.length===10000?'10,000+':reports.length})</Breadcrumb.Item>
            </Breadcrumb>
            <div className="pt-5" id="notifications">
                <Card>
                    {/*<Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            Reported content ({reports.length})
                        </h3>
                    </Card.Header>*/}
                    <Card.Body>

                        {!showSub && <Button onClick={clear} variant="danger" size='sm' style={{marginLeft: '3em', float: 'right', marginBottom: '10px', padding: '0.5em 2em'}} disabled={!reports.length}>clear</Button>}
                        {showSub && <Button onClick={()=>{setShowSub(false)}} variant="success" size='sm' style={{marginLeft: '3em', float: 'right', marginBottom: '10px', padding: '0.5em 2em', display: 'block', width: '100%'}} disabled={!reports.length}>back</Button>}

                        {reportsLoader ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
                        <table id="reports" className="table components" style={{fontSize: '.9em', marginTop: '5em', marginBottom: '0'}}>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>To</th>
                                <th>Type</th>
                                <th>Title</th>
                                <th>Body</th>
                                <th>Keep</th>
                                <th>Status</th>
                                {!showSub && <th></th>}
                                {/*<th style={{textAlign: 'right'}}>Actions</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {(showSub||reports.filter(d => !d.remove)).map(d => <tr style={{opacity: d.action?.5:1, cursor: !showSub&&d.sub?'pointer':'default'}} onClick={()=>{d.sub && setShowSub([d, ...d.sub])}}>
                                {/*<td>{moment(d.created.toDate()).fromNow()}</td>*/}
                                <td>{d.created.toDate().toISOString().split('T')[0]} <span style={{opacity: .5, marginLeft: '1em'}}>{moment(d.created.toDate()).format('hh:mm')}</span></td>
                                <td>{d.user.name}</td>
                                <td>{d.payload.data.type}</td>
                                <td className="notification-title">{d.payload.notification.title}</td>
                                <td className="notification-body" title={d.payload.notification.body}>{d.payload.notification.body}</td>
                                <td>{d.persist? 'stick' : 'lose'}</td>
                                <td style={{color: d.sent ? '#37c91d' : '#bbb'}}>{d.sent? 'sent' : !d.token? 'no token' : 'not found'}</td>
                                {/*d.action ? 
                                    <td style={{opacity:0.6, transition: '.3s', textAlign: 'right', color: 'green'}}><FontAwesomeIcon icon={faCheck} /></td> :
                                    <td style={{opacity:0.1, transition: '.3s', textAlign: 'right'}} className="remove" onClick={()=>{removeItem(d)}}><FontAwesomeIcon icon={faTrashAlt} /></td>
                                */}
                                {!showSub && <td style={{color: '#3d7ce4'}}>{d.sub && ('('+(d.sub.length+1)+')')}</td>}
                            </tr>)}
                        </tbody>
                        </table>}

                    </Card.Body>
                </Card>
                {/*<Modal show={showPromocode} onHide={handleCloseMealPlan}>
                    <AddPromocode handleClose={handleCloseMealPlan} promocodeData={promocodeData} Reports={Reports} />
                </Modal>*/}
            </div>
            {/*<Modal show={showDeleteModal} onHide={handleDeleted}>
                <DeleteAlert onHide={handleDeleted} title={'Delete Meal Plan'} message={'Are you sure you want to delete meal plan?'} warning={'All the related recipes will be unassigned.'} docId={docId} collectionName={'meal_plans'} />
            </Modal>*/}
        </>
    )
}
