/** @format */

import React, { useState, useEffect } from "react";
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import "./WorkoutPlans.scss";
import * as _ from "lodash";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faPlus,
	faArrowUp,
	faArrowDown,
	faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddExercise from "../../components/AddExercise";
import { firestore } from "../../services/firebase";
import AddWorkoutPlan from "../../components/AddWorkoutPlan";
import AddWorkoutPlan2 from "../../components/AddWorkoutPlan2";
import ExerciseFilter from "../../components/ExerciseFilter";
import DeleteAlert from "../../components/DeleteAlert";
import altImage from "../../assets/images/bg-icon.png";
import WorkoutCard from "../../components/WorkoutCard";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { Header } from "../../components/Header";

library.add(faPlus);

export function WorkoutPlans(props) {
	const [nItems, setNItems] = useState(20);
	const [scrollTop, setScrollTop] = useState(0);
	const [collaborators, setCollaborators] = useState({});
	const [show, setShow] = useState(false);
	const [exerciseLoader, setExerciseLoader] = useState(false);
	const [workoutPlansLoader, setWorkoutPlansLoader] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [docId, setDocId] = useState("");
	const [selectedType, setSelectedType] = useState();
	const [searchKey, setSearchKey] = useState();
	const [workoutPlanIdToName, setWorkoutPlanIdToName] = useState({});
	const [items, setItems] = useState();
	const [selectedSort, setSelectedSort] = useState({});

	const [sort, setSort] = useState({
		field: "name",
		direction: "asc",
	});
	const tableHeaders = [
		{
			name: "Name",
			filterField: "name",
		},
		{
			name: "Date",
			filterField: "datetime",
		},
	];
	const [selectedSortData, setSelectedSortData] = useState(tableHeaders);

	const [exercises, setExercises] = useState([]);
	const [exerciseData, setExerciseData] = useState({});
	const [selectedRecords, setSelectedRecords] = useState([]);
	const [filterData, setFilterData] = useState({});
	const [workoutPlans, setWorkoutPlans] = useState([]);
	const [workoutPlanData, setWorkoutPlanData] = useState({});

	const [showWorkoutPlan, setShowWorkoutPlan] = useState(false);
	const [showWorkoutPlan2, setShowWorkoutPlan2] = useState(false);
	const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);

	const [start, setStart] = useState();
	const [rest, setRest] = useState();
	const [end, setEnd] = useState();
	const [muscle, setMuscle] = useState([]);
	const [level, setLevel] = useState([]);
	const [type, setType] = useState([]);
	const [cardSize, setCardSize] = useState("lg");

	useEffect(() => {
		const onScroll = (e) => {
			setScrollTop(e.target.documentElement.scrollTop);

			if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
				// you're at the bottom of the page
				setNItems((nItems) => nItems + 20);
			}
		};

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	useEffect(() => {
		getCollaborators();
		const workoutPlanSubscription = getWorkoutPlans();
		const muscleFilter = getMuscle();
		return () => {
			workoutPlanSubscription();
		};
	}, []);

	useEffect(() => {
		const exercisesSubscription = getExercises();
		return () => {
			exercisesSubscription();
		};
	}, [filterData]);

	useEffect(() => {
		setTimeout(getExercise, 0);
		return () => {};
	}, []);

	async function getCollaborators() {
		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		if (userDetails.collaborators && userDetails.collaborators.length) {
			const collaborators = {};

			const docs = await firestore("users")
				.where("id", "in", userDetails.collaborators)
				.get();
			docs.forEach((doc) => {
				collaborators[doc.id] = doc.data();
			});

			setCollaborators(collaborators);
		}
	}

	function getExercise() {
		firestore("exercise")
			.get()
			.then(function (snapshot) {
				const items = snapshot.docs.length
					? snapshot.docs.map((d) => {
							var obj = d.data();
							obj.id = d.id;
							return obj;
					  })
					: [];
				setItems(_.sortBy(items, "name"));
			});
	}

	function getExercises() {
		setExerciseLoader(true);
		const userDetailsStorage = JSON.parse(localStorage.getItem("userDetails"));

		let exercisesRef = firestore("exercises").where(
			"nutritionistId",
			"==",
			userDetailsStorage.id
		);

		//.where('nutritionistId', 'in', [userDetailsStorage.id].concat(userDetailsStorage.collaborators||[]));
		if (filterData.day) {
			exercisesRef = exercisesRef.where("day", "==", Number(filterData.day));
		}
		if (filterData.workoutPlanId) {
			exercisesRef = exercisesRef.where(
				"workoutPlanId",
				"==",
				filterData.workoutPlanId
			);
		}
		if (filterData.duration) {
			exercisesRef = exercisesRef.where(
				"duration",
				"==",
				Number(filterData.duration)
			);
		}
		if (filterData.sets) {
			exercisesRef = exercisesRef.where("sets", "==", Number(filterData.sets));
		}
		if (filterData.reps) {
			exercisesRef = exercisesRef.where("reps", "==", Number(filterData.reps));
		}
		return exercisesRef.onSnapshot(
			async (querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});

				data.map((d) => {
					if (d.name === "start") setStart(d);
					if (d.name === "rest") setRest(d);
					if (d.name === "end") setEnd(d);
				});

				sortData({}, data, true);
				setExerciseLoader(false);
			},
			(error) => {
				console.error("error : ", error);
			}
		);
	}
	function getMuscle() {
		const userDetails = JSON.parse(localStorage.getItem("userDetails"));

		firestore("exercises")
			.where(
				"nutritionistId",
				"in",
				[userDetails.id].concat(userDetails.collaborators || [])
			)
			.onSnapshot((querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot, i) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				let muscleArr = [];
				data.map((d) => {
					if (d.muscle) {
						muscleArr.push(d.muscle);
					}
				});
				let unique = [...new Set(muscleArr)];
				setMuscle(unique);
			});
	}
	function getWorkoutPlans() {
		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		setWorkoutPlansLoader(true);
		return (
			firestore("workout_plans")
				//.where('nutritionistId', '==', userDetails.id)
				.where(
					"nutritionistId",
					"in",
					[userDetails.id].concat(userDetails.collaborators || [])
				)
				.onSnapshot((querySnapshot) => {
					let data = querySnapshot.docs.map((documentSnapshot, i) => {
						return {
							...documentSnapshot.data(),
							key: documentSnapshot.id,
						};
					});

					var _workoutPlanIdToName = {};
					data.map((plan) => {
						_workoutPlanIdToName[plan.key] = plan.name;
					});
					setWorkoutPlanIdToName(_workoutPlanIdToName);
					setWorkoutPlans(_.orderBy(data, "name", "asc"));
					setWorkoutPlansLoader(false);
				})
		);
	}

	function addWorkoutPlan() {
		setWorkoutPlanData({});
		setShowWorkoutPlan(true);
	}

	function editWorkoutPlan(workoutPlan) {
		setWorkoutPlanData(workoutPlan);
		if(workoutPlan.new === true) setShowWorkoutPlan2(true);
		else setShowWorkoutPlan(true);
	}

	function addWorkoutPlan2() {
		setWorkoutPlanData({});
		setShowWorkoutPlan2(true);
	}

	async function onSelectPlan(selectedWorkout) {
		await Promise.all(
			selectedRecords.map(async (o) => {
				const selectedIndex = workoutPlans.findIndex(
					(workoutPlan) => workoutPlan.key === selectedWorkout
				);
				await firestore("exercises").doc(o).update({
					workoutPlanId: selectedWorkout,
					workoutPlanName: workoutPlans[selectedIndex].name,
				});
			})
		);
		setSelectedRecords([]);
		setIsSelectAllSelected(false);
	}

	const onSelect = (key, isSelect) => {
		const selectedRows = [...selectedRecords];
		if (isSelect) {
			selectedRows.push(key);
		} else {
			const index = selectedRows.findIndex((o) => o === key);
			if (index > -1) {
				selectedRows.splice(index, 1);
			}
		}
		setSelectedRecords(selectedRows);
	};

	async function onDeleteClick(key) {
		setDocId(key);
		setShowDeleteModal(true);
	}

	function editExercise(exercise = "") {
		setExerciseData(exercise);
		setShow(true);
	}

	function cloneExercise(exercise = {}) {
		delete exercise.key;
		setExerciseData(exercise);
		setShow(true);
	}

	function selectAllCheckbox(e) {
		setIsSelectAllSelected(e.target.checked);
		const selectedRows = [];
		if (e.target.checked) {
			exercises.forEach((o) => {
				selectedRows.push(o.key);
			});
		}
		setSelectedRecords(selectedRows);
	}

	function checkIsSelected(key) {
		if (selectedRecords.length) {
			const index = selectedRecords.findIndex((o) => o === key);
			return index > -1;
		}
		return false;
	}

	function removeFilter(key) {
		const filter = _.clone(filterData);
		delete filter[key];
		setFilterData(filter);
	}

	function displayFilter(filterKey) {
		if (filterKey === "workoutPlanId") {
			const index = workoutPlans.findIndex(
				(o) => o.key === filterData[filterKey]
			);
			if (index > -1) {
				return workoutPlans[index].name;
			}
		}
		return filterData[filterKey];
	}

	function sortData(filterField = "", data = [], isInitSort = false) {
		if (filterField !== "none") {
			let sortClone = _.clone(sort);
			if (filterField && !isInitSort) {
				if (sortClone.field === filterField) {
					sortClone.direction = sortClone.direction === "asc" ? "desc" : "asc";
				} else {
					sortClone.field = filterField;
					sortClone.direction = "asc";
					if (sortClone.field == "datetime") sortClone.direction = "desc";
				}
				setSort(sortClone);
			}
			let usersClone = isInitSort ? data : exercises;
			usersClone = _.orderBy(
				_.clone(usersClone),
				(item) => {
					if (item[sortClone.field]) {
						if (sortClone.field == "day")
							return parseFloat(item[sortClone.field]);
						if (sortClone.field == "sets")
							return parseFloat(item[sortClone.field]);
						if (sortClone.field == "datetime")
							return moment.utc(item[sortClone.field]).format();
						if (item[sortClone.field].toLowerCase)
							return item[sortClone.field].toLowerCase().trim();
					} else return item[sortClone.field];
				},
				sortClone.direction
			);
			//var method = function(item) { return moment.utc(item.datetime).format() }
			setExercises(usersClone); //_.orderBy(usersClone, method, 'desc')
		}
	}

	function filterFn(exercise) {
		return (
			(selectedType ? exercise.muscle === selectedType : true) &&
			(searchKey
				? exercise.name.toLowerCase().includes(searchKey.toLowerCase())
				: true)
		); //&& !ignore[exercise.key]
	}

	function isCardFn(exercise) {
		return ["start", "rest", "end"].includes(exercise.name.toLowerCase());
	}

	return (
		<>
			<Header header="Workout Plans" />
			<hr />
			<div className="workout-plan">
				<div className="m-0 d-flex justify-content-between">
					<h4>Workout plans ({workoutPlans.length})</h4>
					<button
						className="button button-secondary"
						onClick={(e) => {
							if(e.ctrlKey) addWorkoutPlan2();
							else addWorkoutPlan();
						}}
					>
						+ Add workout plan
					</button>
				</div>
				<Card>
					<Card.Body>
						{workoutPlansLoader ? (
							<div className="text-center">
								<Spinner animation="border" size={"sm"} role="status" />
							</div>
						) : workoutPlans.length ? (
							workoutPlans.map((workoutPlan) => {
								return (
									<Button
										key={workoutPlan.key}
										disabled={collaborators[workoutPlan.nutritionistId]}
										style={
											collaborators[workoutPlan.nutritionistId]
												? { cursor: "default" }
												: {}
										}
										onClick={() => {
											editWorkoutPlan(workoutPlan);
										}}
										className="rounded-pill mr-3 mb-3"
										variant="outline-secondary"
										size="sm"
									>
										{workoutPlan.name}
										{collaborators[workoutPlan.nutritionistId] && (
											<span
												style={{
													background: "wheat",
													color: "black",
													marginLeft: "10px",
													boxShadow: "0 0 3px 5px wheat",
													borderRadius: "5px",
													fontVariant: "petite-caps",
													fontWeight: "bold",
												}}
											>
												{collaborators[workoutPlan.nutritionistId].name}
											</span>
										)}
									</Button>
								);
							})
						) : (
							<div className="text-center">No Workout plans found</div>
						)}
					</Card.Body>
				</Card>
				<div className="cards-section">
					<div
						className="m-0 d-flex justify-content-between"
						style={{ marginBottom: "25px" }}
					>
						<h4>Your Cards</h4>
					</div>

					<Card className="exercises">
						<div style={{ display: "flex" }}>
							{start ? (
								<Button
									style={{
										background: "none",
										border: "none",
									}}
									size="sm"
									variant="light"
									className="mr-2"
									onClick={() => editExercise(start)}
								>
									<img
										height="100px"
										style={{ borderRadius: "7px", marginRight: "10px" }}
										src={start.image}
										alt="start"
									/>
									Start
								</Button>
							) : (
								<Button
									style={{
										background: "none",
										border: "none",
									}}
									size="sm"
									variant="light"
									className="mr-2"
									onClick={() => {
										editExercise({ isCard: true, name: "start" });
									}}
								>
									<FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />
									Set Start
								</Button>
							)}
							{rest ? (
								<Button
									style={{
										background: "none",
										border: "none",
									}}
									size="sm"
									variant="light"
									className="mr-2"
									onClick={() => editExercise(rest)}
								>
									<img
										height="100px"
										style={{ borderRadius: "7px", marginRight: "10px" }}
										src={rest.image}
										alt="rest"
									/>
									Rest
								</Button>
							) : (
								<Button
									style={{
										background: "none",
										border: "none",
									}}
									size="sm"
									variant="light"
									className="mr-2"
									onClick={() => editExercise({ isCard: true, name: "rest" })}
								>
									<FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />
									Set Rest
								</Button>
							)}
							{end ? (
								<Button
									style={{
										background: "none",
										border: "none",
										// padding: "1em 2em",
									}}
									size="sm"
									variant="light"
									className="mr-2"
									onClick={() => editExercise(end)}
								>
									<img
										height="100px"
										style={{ borderRadius: "7px", marginRight: "10px" }}
										src={end.image}
										alt="end"
									/>
									End
								</Button>
							) : (
								<Button
									style={{
										background: "none",
										border: "none",
										// padding: "1em 2em",
									}}
									size="sm"
									variant="light"
									className="mr-2"
									onClick={() => editExercise({ isCard: true, name: "end" })}
								>
									<FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />
									Set End
								</Button>
							)}
							<div style={{ display: "flex", alignItems: "center" }}>
								<div
									className="vl"
									style={{
										borderLeft: "1px solid #f0f0f0",
										height: "100px",
										margin: "0 40px",
									}}
								></div>
								<span
									style={{
										color: "#a8a8a8",
										fontWeight: "400",
										fontStyle: "italic",
										fontSize: "0.9375rem",
									}}
								>
									Upload a picture that reflects
									<br />
									each stage of the workout
								</span>
							</div>
						</div>
					</Card>
				</div>

				<div className="m-0 d-flex justify-content-between flex-wrap">
					<h4>
						Exercises ({exercises.filter((d) => !isCardFn(d)).length})
						{filterData &&
							Object.keys(filterData).map((filterKey) => {
								return filterData[filterKey] ? (
									<Button
										key={filterKey}
										onClick={() => {
											removeFilter(filterKey);
										}}
										className="rounded-pill ml-3"
										variant="outline-secondary"
										size="sm"
									>
										{displayFilter(filterKey)}
									</Button>
								) : (
									""
								);
							})}
					</h4>
					<div className="search-bar">
						<Form.Control
							placeholder="search"
							id="search-workout"
							value={searchKey}
							onChange={(e) => {
								setSearchKey(e.target.value);
							}}
						></Form.Control>
						<FontAwesomeIcon icon={faSearch} className="ml-2" size="1x" />
					</div>
					<div className="add-workout">
						{items && (
							<Button
								size="sm"
								variant="primary"
								className="button button-secondary"
								onClick={() => editExercise()}
							>
								+ Add Exercise
							</Button>
						)}
					</div>
				</div>

				<Card className="exercises">
					<div className="recipe-section">
						<div className="m-0 d-flex" style={{ alignItems: "baseline" }}>
							{muscle.length > 1 ? (
								<>
									<h5>
										<Filter
											fill="#383838"
											width="15%"
											style={{ margin: "0.5em" }}
										/>
										Filter by
									</h5>
									<Form.Control
										style={{ minWidth: "157px" }}
										as="select"
										placeholder="Muscle"
										value={selectedType}
										onChange={(e) => {
											setSelectedType(e.target.value);
										}}
									>
										<option value={""} key={""}>
											Muscles
										</option>
										{muscle.map((obj, i) => {
											return (
												<option value={obj} key={obj}>
													{obj}
												</option>
											);
										})}
									</Form.Control>
								</>
							) : null}
							<h5
								className="sort-items"
								style={muscle.length > 1 ? { marginLeft: "auto" } : {}}
							>
								Sort by
							</h5>
							<Form.Control
								style={{ minWidth: "157px" }}
								className="select-sort"
								as="select"
								placeholder="Sort"
								value={selectedSort.name}
								onChange={(e, value) => {
									setSelectedSort(
										selectedSortData.filter(
											(obj) => obj.name === e.target.value
										)[0]
									);
									let sortValue =
										e.target.value === "Date"
											? e.target.value.toLowerCase().concat("time")
											: e.target.value.toLowerCase();

									sortData(sortValue);
								}}
							>
								{selectedSortData.map((obj, i) => {
									return (
										<option value={obj.name} key={obj.name}>
											{obj.name}
										</option>
									);
								})}
							</Form.Control>
							{/* { sort.field === selectedSort.filterField ? ( */}
							<span
								style={
									selectedSort.filterField
										? { display: "block" }
										: { display: "none" }
								}
								onClick={(e) => {
									sortData(selectedSort.filterField);
								}}
							>
								<FontAwesomeIcon
									icon={sort.direction === "asc" ? faArrowUp : faArrowDown}
									className="ml-1"
									size="lg"
								/>
							</span>
						</div>
						<hr
							style={{
								borderColor: "#f0f0f0",
							}}
						/>

						<div className="m-0" responsive="xl">
							<div>
								{exerciseLoader ? (
									<div>
										<div
											className="text-center"
											colSpan={tableHeaders.length + 1}
										>
											<Spinner animation="border" size={"sm"} role="status" />
										</div>
									</div>
								) : exercises.filter((d) => !isCardFn(d)).length ? (
									<div className="workout-section">
										{exercises
											.filter((d) => !isCardFn(d))
											.filter(filterFn)
											.map(
												(exercise, i) =>
													i < nItems && (
														<WorkoutCard
															key={i}
															exercise={exercise}
															name={exercise.name}
															image={exercise.image || altImage}
															date={moment
																.utc(exercise.datetime)
																.fromNow()
																.replace("a few seconds ago", "now")}
															muscle={exercise.muscle}
															editExercise={editExercise}
															cloneExercise={cloneExercise}
															onDeleteClick={onDeleteClick}
														/>
													)
											)}
									</div>
								) : (
									<div>
										<div
											className="text-center"
											colSpan={tableHeaders.length + 1}
										>
											No data found
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					{nItems <
						exercises.filter((d) => !isCardFn(d)).filter(filterFn).length && (
						<div className="loader text-center">
							<Spinner animation="border" size={"sm"} role="status" />
						</div>
					)}
				</Card>

				<Modal size={cardSize} show={show} onHide={() => setShow(false)}>
					<AddExercise
						setCardSize={setCardSize}
						cardSize={cardSize}
						handleClose={() => setShow(false)}
						exerciseData={exerciseData}
						exercises={items}
						workoutPlans={workoutPlans}
					/>
				</Modal>
				<Modal
					show={showWorkoutPlan}
					onHide={() => setShowWorkoutPlan(false)}
					className="edit-workoutplan"
				>
					<AddWorkoutPlan
						handleClose={() => setShowWorkoutPlan(false)}
						workoutPlanData={workoutPlanData}
						exercises={exercises.filter((d) => !isCardFn(d))}
						start={start || {}}
						rest={rest || {}}
						end={end || {}}
						workoutPlans={workoutPlans}
					/>
				</Modal>
				<Modal
					show={showWorkoutPlan2}
					onHide={() => setShowWorkoutPlan2(false)}
					className="edit-workoutplan"
				>
					<AddWorkoutPlan2
						handleClose={() => setShowWorkoutPlan2(false)}
						workoutPlanData={workoutPlanData}
						exercises={exercises.filter((d) => !isCardFn(d))}
						start={start || {}}
						rest={rest || {}}
						end={end || {}}
						workoutPlans={workoutPlans}
					/>
				</Modal>
				<Modal show={showFilter} onHide={() => setShowFilter(false)}>
					<ExerciseFilter
						handleClose={(dismissData) => {
							setShowFilter(false);
							if (dismissData.filterData) {
								setFilterData(dismissData.filterData);
							}
							getExercises();
						}}
						filterData={filterData}
						workoutPlans={workoutPlans}
					/>
				</Modal>
				<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
					<DeleteAlert
						onHide={() => setShowDeleteModal(false)}
						title={"Delete Exercise"}
						message={"Are you sure you want to delete exercise?"}
						docId={docId}
						collectionName={"exercises"}
					/>
				</Modal>
			</div>
		</>
	);
}
