/** @format */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faClone,
	faEdit,
	faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import "./ChallengesComponents/ChallengesComponent.scss";
import _ from "lodash";
import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";

export default function RecipeCard({
	recipes,
	recipe,
	image,
	plan,
	name,
	type,
	calories,
	setVariations,
	cloneRecipe,
	editRecipe,
	onDeleteClick,
	readOnly,
	showCalories,
	recipesById,
	state,
	setState,
	showMenuForName,
	setShowMenuForName,
	ind,
	index,
	item,
	variation,
	showType,
}) {
	return (
		<>
			<div
				className="challenges-card recipes-card"
				style={variation && { display: "grid", gridTemplateColumns: "1fr 1fr" }}
			>
				<div className="challenge-thumbnail">
					<div style={{ backgroundImage: `url(${image})` }}></div>
				</div>

				<div
					title={variation ? plan : ""}
					className={variation ? "recipes-variation-modal" : ""}
				>
					{!variation && (
						<div title={plan} className="recipe-plans">
							{showType && plan}
							<span className="meal-plans-tags">
								{plan !== undefined && plan.length > 0
									? plan.length === 1
										? "1 Meal Plan"
										: plan.length + " Meal Plans"
									: ""}
							</span>
						</div>
					)}

					<div
						className="challenge-name"
						onClick={() => {
							if (variation) editRecipe(recipe);
							else {
								var variations = recipes.filter((r) => r.name === recipe.name);
								if (variations.length > 1) return setVariations(variations);
								editRecipe(recipe);
							}
						}}
					>
						{name}

						<div className="challenge-calories">
							<span>{variation && type} </span>

							<span>
								{ind === 0 && (
									<span style={{ color: "#909090" }}>
										{recipesById[item.split("_")[0]].Calories
											? (
													recipesById[item.split("_")[0]].Calories /
													(recipesById[item.split("_")[0]].portions || 1)
											  ).toFixed(0) + "Kcal"
											: " - Kcal"}
									</span>
								)}
								{ind === 1000 && calories}
							</span>
						</div>
					</div>
					<div className="add-meal-plan-card">
						<div className="variations">
							{ind && showCalories ? (
								<div
									style={{
										fontVariant: "none",
										fontSize: ".8em",
										color: "#777",
										cursor: "pointer",
										marginTop: "5px",
									}}
									onClick={() => {
										//if(ind==0) return
										//if(!state[ind][index+1]) return

										var recipeName = recipesById[item.split("_")[0]].name;
										var clonedItem = _.clone(showMenuForName);
										if (!clonedItem[recipeName]) clonedItem[recipeName] = true;
										else if (clonedItem[recipeName])
											clonedItem[recipeName] = false;
										setShowMenuForName(clonedItem);

										const recipe = recipesById[item.split("_")[0]].name;

										//var variations = recipes.filter(r => r.name === recipe.name)
										//if(variations.length > 1) return setVariations(variations)
										//cloneRecipe(recipe);
									}}
								>
									{(recipesById[item.split("_")[0]].Calories
										? (
												recipesById[item.split("_")[0]].Calories /
												(recipesById[item.split("_")[0]].portions || 1)
										  ).toFixed(0)
										: "-") + " Kcal"}
									{recipesById[item.split("_")[0]].portions > 1 && (
										<div style={{ color: "#b57649" }}>
											*Recipe cooks{" "}
											<b>{recipesById[item.split("_")[0]].portions}</b> portions
										</div>
									)}
									<FontAwesomeIcon
										icon={faChevronDown}
										size="1x"
										className=""
										style={{ marginLeft: "5px" }}
									/>
								</div>
							) : null}
							{ind &&
							showCalories &&
							showMenuForName[recipesById[item.split("_")[0]].name] ? (
								<div
									style={{
										fontVariant: "none",
										fontSize: ".8em",
										color: "#777",
										cursor: "pointer",
										background: "#f0f0f0",
										borderRadius: "5px",
										marginTop: "0px",
										padding: "5px",
									}}
								>
									<div>
										{recipes
											.filter(
												(r) =>
													r.key !== item.split("_")[0] &&
													r.name === recipesById[item.split("_")[0]].name
											)
											.map((r) => (
												<div
													onClick={() => {
														const newState = [...state];
														newState[ind][index] = r.key;
														setState(newState);
													}}
												>
													{(r.Calories
														? (r.portions > 1
																? r.Calories / r.portions
																: r.Calories
														  ).toFixed(0)
														: "-") + " Kcal"}
												</div>
											))}
										<div
											onClick={() => {
												cloneRecipe(_.clone(recipesById[item.split("_")[0]]));
											}}
										>
											<FontAwesomeIcon
												icon={faPlus}
												size="1x"
												className=""
												style={{
													marginRight: "3px",
												}}
											/>
											new variation
										</div>
									</div>
								</div>
							) : null}
						</div>
						{!readOnly && ind && ind > 0 ? (
							<div className="challenge-controls">
								<div
									className="add-btn"
									onClick={() => {
										if (variation) editRecipe(recipe);
										else {
											var variations = recipes.filter(
												(r) => r.name === recipe.name
											);
											if (variations.length > 1)
												return setVariations(variations);
											editRecipe(recipe);
										}
									}}
								>
									<div className="circle">
										<FontAwesomeIcon icon={faEdit} className="add" size="sm" />
									</div>
								</div>
								{ind === 1000 ? (
									<div
										className="remove-btn"
										onClick={() => {
											if (variation) cloneRecipe(recipe);
											else {
												var variations = recipes.filter(
													(r) => r.name === recipe.name
												);
												if (variations.length > 1)
													return setVariations(variations);
												cloneRecipe(recipe);
											}
											//
										}}
									>
										<div className="circle">
											<FontAwesomeIcon icon={faClone} className="remove" />
										</div>
									</div>
								) : (
									<div
										className="remove-btn clone-btn"
										onClick={() => {
											const newState = [...state];
											var clonedItem = _.clone(item);
											clonedItem += "_" + new Date().getTime();
											newState[ind].splice(index, 0, clonedItem);
											setState(newState);
										}}
									>
										<div className="circle">
											<FontAwesomeIcon icon={faClone} className="remove" />
										</div>
									</div>
								)}

								{ind === 1000 ? (
									<div
										className="remove-btn"
										onClick={() => {
											if (variation) onDeleteClick(recipe.key);
											else {
												var variations = recipes.filter(
													(r) => r.name === recipe.name
												);
												if (variations.length > 1)
													return setVariations(variations);
												onDeleteClick(recipe.key);
											}
										}}
									>
										<div className="circle">
											<FontAwesomeIcon icon={faTrashAlt} className="remove" />
										</div>
									</div>
								) : (
									<div
										className="remove-btn"
										onClick={() => {
											const newState = [...state];
											newState[ind].splice(index, 1);
											setState(
												newState //.filter((group,i) => i<newState.length-1 || group.length)
											);
										}}
									>
										<div className="circle">
											<FontAwesomeIcon icon={faTrashAlt} className="remove" />
										</div>
									</div>
								)}
							</div>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
}
