import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

function getKey(key){

  return process.env['REACT_APP_' + key]
}

var firebaseConfig = {
  apiKey: getKey('apiKey'),
  authDomain: getKey('authDomain'),
  databaseURL: getKey('databaseURL'),
  projectId: getKey('projectId'),
  storageBucket: getKey('storageBucket'),
  messagingSenderId: getKey('messagingSenderId'),
  appId: getKey('appId'),
  measurementId: getKey('measurementId')
};

// Initialize the first Firebase app instance
firebase.initializeApp(firebaseConfig);

export const auth = () => {
    return firebase.auth();
};

export const firestore = (collection) => {
    return firebase.firestore().collection(collection);
};

export const storage = () => {
    return firebase.storage();
};


// Initialize the second Firebase app instance
var firebaseConfigPublic = { ...firebaseConfig }
firebaseConfigPublic.storageBucket = 'gs://welnes-app-public'
const appPublic = firebase.initializeApp(firebaseConfigPublic, 'appPublic');


export const storagePublic = () => {
    return appPublic.storage()
};
