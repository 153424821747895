/** @format */

import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import axios from 'axios';

import {
	Spinner,
	Form,
	Modal,
	Button,
	Col,
	Row,
	Breadcrumb,
} from "react-bootstrap";
import firebase from 'firebase/compat/app';
import { firestore, storage, storagePublic } from "../../services/firebase";
import moment, { updateLocale } from "moment";
import * as _ from "lodash";

import FileUploadThumbnail from "file-upload-thumbnail";
import imageCompression from "browser-image-compression";
import { ReactComponent as VideosIcon } from "../../assets/icons/camera.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/video-play.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faComment,
	faThumbsUp,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "./Videos.css";
import { Card } from "react-bootstrap";
import DeleteAlert from "../../components/DeleteAlert";
import { Header } from "../../components/Header";

function estimateTranscodingTime(file, averageTranscodingSpeed, outputFileSizeBytes) {
  // Extract information from the uploaded video file
  const inputFileSizeBytes = file.size; // File size in bytes
  var inputDurationSeconds = file.duration; // Duration in seconds (if available)

  // Check if duration information is available; if not, you can provide an estimated value
  if (!inputDurationSeconds) {
    // Provide an estimated duration based on the file size and a typical bitrate
    // Adjust the bitrate as needed for your use case
    const estimatedBitrate = 2000000; // 2 Mbps (adjust as needed)
    inputDurationSeconds = (inputFileSizeBytes * 8) / estimatedBitrate;
  }

  // Estimate transcoding time based on size and duration
  const bitrate = (inputFileSizeBytes * 8) / inputDurationSeconds; // Calculate bitrate in bits per second
  const sizeToTimeEstimate = outputFileSizeBytes / bitrate;

  // Estimate based on transcoding speed
  const speedToTimeEstimate = outputFileSizeBytes / averageTranscodingSpeed;

  // Take the average of both estimates
  const combinedEstimate = (sizeToTimeEstimate + speedToTimeEstimate) / 2;

  return combinedEstimate;
}


let intervalHolder = '';
let intervalHolder2 = '';

const getThumbnail = (file) => {
	return new Promise((resolve) => {
		const canvas = document.createElement("canvas");
		const video = document.createElement("video");

		video.autoplay = true;
		video.muted = true;
		video.src = URL.createObjectURL(file);

		video.onloadeddata = () => {
			let ctx = canvas.getContext("2d");
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;

			ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
			video.pause();
			return resolve(canvas.toDataURL("image/png"));
		};
	});
};


export function Videos(props) {
	const [feedData, setFeedData] = useState();
	const [videoUploaded, setVideoUploaded] = useState();
	const [jobState, setJobState] = useState();
	const [jobError, setJobError] = useState();
	const [jobId, setJobId] = useState();
	const [estimatedTime, setEstimatedTime] = useState();


	const [loader, setLoader] = useState(true);
	const [groups, setGroups] = useState([]);
	const [videos, setVideos] = useState([]);
	const [isVideoChanged, setIsVideoChanged] = useState(false);
	const [videoFile, setVideoFile] = useState(false);
	const [videoBlob, setVideoBlob] = useState(false);
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [videoPosted, setVideoPosted] = useState(false);
	const [VideoError, setVideoError] = useState("");
	const [selectedChallenge, setSelectedChallenge] = useState("");
	const [challengesLoading, setChallengesLoading] = useState([]);
	const [challenges, setChallenges] = useState([]);
	const [challenge, setChallenge] = useState(false);
	const [ignore, setIgnore] = useState({});
	const [rounds, setRounds] = useState(false);
	const [comments, setComments] = useState([]);
	const [showComments, setShowComments] = useState(false);
	const [newMessage, setNewMessage] = useState("");
	const [isSubmitCommentLoader, setIsSubmitCommentLoader] = useState(false);
	const [caption, setCaption] = useState("");
	const [thumbnailImage, setThumbnailImage] = useState();
	const [progress, setProgress] = useState(0);
	const [progress2, setProgress2] = useState(0);
	const [isReadMore, setIsReadMore] = useState(true);
	const [videoData, setVideoData] = useState({});
	const [docId, setDocId] = useState("");
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);
	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);

	useEffect(() => {
		getChallenges();
	}, []);

	useEffect(() => {
		var unsubscribes = getCollections();

		return () => {
			console.log("Do some cleanup");
			//unsubscribes.map((unsubscribe) => unsubscribe())
		};
	}, [selectedChallenge]);

	useEffect(() => {
		if (showComments) {
			firestore("feeds")
				.doc(showComments)
				.collection("comments")
				.orderBy("datetime", "asc")
				.get()
				.then((snapshot) => {
					var comments = snapshot.docs.length
						? snapshot.docs.map((d) => {
								var obj = d.data();
								obj.id = d.id;
								return obj;
						  })
						: [];

					const userDetailsStorage = JSON.parse(
						localStorage.getItem("userDetails")
					);
					const adminDetailsStorage = JSON.parse(
						localStorage.getItem("adminDetails")
					);
					comments.map((d) => {
						if (
							userDetailsStorage.id === d.userId ||
							adminDetailsStorage.id === d.userId
						)
							d.ans = true;
						return d;
					});

					setComments(comments);
				});
		}

		return () => {
			console.log("Do some cleanup");
			//unsubscribes.map((unsubscribe) => unsubscribe())
		};
	}, [showComments]);

	function addMessage(message) {
		console.log(message);
		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		const adminDetails = JSON.parse(localStorage.getItem("adminDetails"));

		setNewMessage({
			comment: message,
			nutritionistId: userDetails.id,
			userId: adminDetails.id,
			userName: adminDetails.name,
			userImage: adminDetails.image,
		});
	}

	async function submitComment(e) {
		e.stopPropagation();
		e.preventDefault();

		setIsSubmitCommentLoader(true);

		newMessage.datetime = moment.utc().format();
		await firestore("feeds")
			.doc(showComments)
			.collection("comments")
			.add(newMessage);

		setIsSubmitCommentLoader(false);
		setShowComments(false);
	}
	async function onDeleteClick(key) {
		setDocId(key);
		setShowDeleteModal(true);
	}
	function getChallenges() {
		const userDetails = JSON.parse(localStorage.getItem("userDetails"));
		const adminDetails = JSON.parse(localStorage.getItem("adminDetails"));
		if (!userDetails || !userDetails.id) {
			setChallenges([]);
			setChallengesLoading(false);
			return;
		}

		setChallengesLoading(false); //true
		return (
			firestore("challenges")
				//.where('nutritionistId', '==', userDetails.id)
				.where(
					"nutritionistId",
					"in",
					adminDetails.role.includes("admin") ||
						userDetails.id === adminDetails.id
						? [userDetails.id].concat(userDetails.collaborate || [])
						: [userDetails.id]
				)
				.get()
				.then(
					(querySnapshot) => {
						let data = querySnapshot.docs.map((documentSnapshot, i) => {
							return {
								...documentSnapshot.data(),
								key: documentSnapshot.id,
								id: documentSnapshot.id,
							};
						});
						//_.orderBy(groups, 'userCount', 'desc')
						//data = _.orderBy(data, 'price', 'desc')
						data = _.orderBy(
							data,
							(item) => moment.utc(item.date).format(),
							"desc"
						);

						var grouped = [],
							ignore = {},
							firstRound = {};
						data.map((d) => {
							if (d.previousRounds) {
								d.rounds = d.previousRounds.length + 1;
								d.previousRounds.map((id, i) => {
									ignore[id] = true;

									if (i === d.previousRounds.length - 1) firstRound[id] = true;
								});
								d.roundsIds = [d.key].concat(d.previousRounds);
							}

							if (firstRound[d.key]) {
								d.rounds = 1;
								d.roundsIds = [d.key];
							}
						});

						setIgnore(ignore);

						setChallenges(data);
						setChallengesLoading(false);

						var storageChallenge = localStorage.getItem("challengeId");
						if (
							storageChallenge &&
							data.filter((c) => c.key === storageChallenge).length
						) {
							var d = data.filter((c) => c.key === storageChallenge)[0];
							var rounds = data.filter(
								(c) => c.roundsIds && c.roundsIds.includes(d.key)
							);
							setRounds(rounds.length ? rounds[0].roundsIds : false);
							setChallenge(d);
							setSelectedChallenge(d.key);
						} else if (data.length) {
							setRounds(data[0].roundsIds);
							setChallenge(data[0]);
							setSelectedChallenge(data[0].key);
							localStorage.setItem("challengeId", selectedChallenge);
						}
					},
					(error) => {
						console.log("error", error);
					}
				)
		);
	}

	function getCollections() {
		challenges.map((d) => {
			if (d.id === selectedChallenge) setChallenge(d);
		});

		const subscribeGroups = selectedChallenge
			? firestore("groups")
					.where("challengeId", "==", selectedChallenge)
					//.where('type', '==', "Nutritionist Video")
					//.orderBy('datetime', 'desc')
					//.limit(10)
					.onSnapshot({ includeMetadataChanges: true }, function (snapshot) {
						var groups = snapshot.docs.length
							? snapshot.docs.map((d) => {
									var obj = d.data();
									obj.id = d.id;
									return obj;
							  })
							: [];

						//var groupById = {}
						//groups.map((group)=>{groupById[group.id] = group})

						setGroups(groups);

						if (loader) setLoader(false);
					})
			: () => {};

		const subscribeVideos = selectedChallenge
			? firestore("feeds")
					.where("challengeId", "==", selectedChallenge)
					.where("type", "==", "Nutritionist Video")
					//.orderBy('datetime', 'desc')
					//.limit(10)
					.onSnapshot({ includeMetadataChanges: true }, function (snapshot) {
						var videos = _.orderBy(
							snapshot.docs.length
								? snapshot.docs.map((d) => {
										var obj = d.data();
										obj.id = d.id;
										return obj;
								  })
								: [],
							"datetime",
							"desc"
						);

						setVideos(videos);

						if (loader) setLoader(false);
					})
			: () => {};

		return [subscribeGroups, subscribeVideos];
	}
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};


	async function postVideo(challenge, groups, targetGroups) {
		setIsSubmitLoader(true);

		const fileName = user.id + "_" + challenge.id + "_" + Number(new Date());
		const uploadTask = storage()
			.ref()
			.child(`/nutritionists/feed_videos/${fileName}`)
			.put(videoFile, { cacheControl: "public,max-age=31536000" });

			console.log(videoFile)
			const averageTranscodingSpeed = 1000000; // Average transcoding speed in bits per second (e.g., 1 Mbps)
			const outputFileSizeBytes = videoFile.size / 2;
			const estimatedTimeSeconds = estimateTranscodingTime(videoFile, averageTranscodingSpeed, outputFileSizeBytes);
			console.log(`Estimated Transcoding Time: ${estimatedTimeSeconds.toFixed(2)} seconds`);

			setEstimatedTime(estimatedTimeSeconds)

		uploadTask.on(
			"state_changed",

			function (snapshot) {
				var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log("Upload is " + progress + "% done");
				setProgress(progress);
			},
			function error(err) {
				console.log("error", err);
			},
			function complete() {
				uploadTask.snapshot.ref
					.getDownloadURL()
					.then(async function (downloadURL) {
						const videoLink = downloadURL;

						const thumb_uploaded =
							thumbnailImage &&
							(await storage()
								.ref()
								.child(`/nutritionists/thumbnails/${fileName}`)
								.putString(
									thumbnailImage.split("data:image/png;base64,")[1],
									"base64",
									{
										contentType: "image/jpg",
										cacheControl: "public,max-age=31536000",
									}
								));
						const thumb_child =
							thumbnailImage &&
							(await storage().ref().child(thumb_uploaded.metadata.fullPath));
						const thumbnail =
							thumbnailImage && (await thumb_child.getDownloadURL());

						var postBy = "nutritionist"; //admin

						//if(user.id === admin.id) postBy = "nutritionist"
						//else if(user.collaborators && user.collaborators.includes(admin.id)) postBy = "collaborator"
						//else if(user.assistants && user.assistants.includes(admin.id)) postBy = "assistant"

						if (admin.id === challenge.nutritionistId) postBy = "nutritionist";
						else if (
							admin.collaborate &&
							admin.collaborate.includes(challenge.nutritionistId)
						)
							postBy = "collaborator";
						else if (
							admin.assist &&
							admin.assist.includes(challenge.nutritionistId)
						)
							postBy = "assistant";

						if (
							admin.role.includes("admin") &&
							user.collaborate &&
							user.collaborate.includes(challenge.nutritionistId)
						)
							postBy = "collaborator";

						var post = {
							challengeName: challenge.name,
							challengeId: challenge.id,
							userId:
								postBy === (!admin.role.includes("admin") && "collaborator")
									? admin.id
									: user.id,
							userName:
								postBy === (!admin.role.includes("admin") && "collaborator")
									? admin.name
									: user.name,
							userImage:
								postBy === (!admin.role.includes("admin") && "collaborator")
									? admin.image
									: user.image,
							type: "Nutritionist Video",
							videoLink: videoLink,
							thumbnail: thumbnail || "",
							fileType: "video",
							fileName: fileName,
							likesCount: 0,
							groupIds: ["none"],
							created: firebase.firestore.FieldValue.serverTimestamp(),
							datetime: new Date().toISOString(),
							postBy: postBy,
						};

						Array.prototype.push.apply(
							post.groupIds,
							groups.map((d) => d.id)
						);

						//var targetGroups = [];
						//groups.map((group) => {if(targetGroups.includes(group.id)) {targetGroups.push(group.id)}})
						//post.groups = groups.filter((group) => )

						if (caption) post.caption = caption;

						console.log(post);


                        setFeedData(post);
                        //setVideoUploaded(videoLink);

						/*firestore("feeds")
							.add(post)
							.then(function (doc) {
								setIsSubmitLoader(false);
								//setVideoPosted('video posted to '+ post.challengeName + ' feed')
								setVideoPosted("posted to challenge feed.");
								setTimeout(function () {
									setVideoPosted(false);
								}, 10000);

								return;
							})
							.catch(function (error) {
								console.error("Error finding/updating user document: ", error);
							});*/

      					//add job to DB and monitor
						await firestore('transcoder_jobs').add({post: post})

						setIsSubmitLoader(false);
						setVideoPosted("video will be posted to challenge feed once transcoded.");
					});
			}
		);
	}

	function onVideoUpload(e) {
		fileSelectHandler(e);
	}

	function fileDragHover(e) {
		var fileDrag = document.getElementById("file-drag");
		e.stopPropagation();
		e.preventDefault();
		fileDrag.className =
			e.type === "dragover" ? "hover" : "modal-body file-upload";
	}

	async function fileSelectHandler(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;

		//make sure it s only one file
		if (files.length > 1) files.length = 1;

		// Cancel event and hover styling
		fileDragHover(e);

		var file = files[0];

		var fn = function () {
			// Process File object
			var options = {
				maxSizeMB: 50,
				//maxWidthOrHeight: 400
			};
			try {
				setVideoFile(file);
				setVideoBlob(URL.createObjectURL(file));
				parseFile(file);
			} catch (error) {
				console.error("error fileSelectHandler", error);
				setVideoError("please upload a valid video file");
			}

			setIsVideoChanged(true);
		};

		//generate thumbnail
		const thumbnailUrl = await getThumbnail(file);
		setThumbnailImage(thumbnailUrl);

		fn();
		
		/*new FileUploadThumbnail({
			maxWidth: 500,
			maxHeight: 500,
			file: file,
			onSuccess: function (src) {
				setThumbnailImage(src);

				fn();
			},
			onError: function (error) {
				console.error("error thumbnail", error);

				fn();
			},
		}).createThumbnail();*/

		//postVideo()
	}

	function parseFile(file) {
		output("<strong>" + encodeURI(file.name) + "</strong>");
	}

	function output(msg) {
		// Response
		var m = document.getElementById("messages");
		m.innerHTML = msg;
	}

	function fbImage(imageLink) {
		if (!imageLink) return imageLink;
		var query = imageLink.split("asid=")[1];
		if (!query) return imageLink;
		var fbId = query.split("&")[0];
		if (!fbId) return imageLink;

		return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
	}

	return (
		<div className="videos-page">
			<Header header="Videos" />
			<hr />
			<Row>
				{challengesLoading ? (
					<div>
						<Spinner animation="border" size={"lg"} role="status" />
					</div>
				) : challenges.length ? (
					<>
						<Col xs={6}>
							<h4>Challenge</h4>

							<Form.Control
								required
								as="select"
								placeholder="Select Challenge"
								style={{
									cursor: "pointer",
									height: "auto",
									display: "inline-block",
									marginLeft: "0",
									width: "100%",
									border: "1px solid #9a9a9a",
									backgroundColor: "#fcfcfc",
								}}
								value={ignore[selectedChallenge] ? challenges.filter(c => c.previousRounds && c.previousRounds.includes(selectedChallenge))[0].key : selectedChallenge}
								onChange={(e) => {
									challenges.map((c) => {
										console.log(c.key);
										if (c.key === e.target.value) {
											setRounds(c.roundsIds);
											setChallenge(c);
										}
									});
									setSelectedChallenge(e.target.value);
									localStorage.setItem("challengeId", e.target.value);
								}}
							>
								{challenges.filter(c => !ignore[c.key]).map((obj, i) => {
									return (
										<>
											<option
												value={obj.key}
												key={obj.key}
												//hidden={ignore[obj.key]}
											>
												{obj.name.replace(/Round(.*)- /, "")}
											</option>
										</>
									);
								})}
							</Form.Control>
						</Col>
						{rounds && (
							<Col xs={6}>
								<h4>Round</h4>
								<Form.Control
									required
									as="select"
									placeholder="Select Round"
									style={{
										cursor: "pointer",
										height: "auto",
										display: "inline-block",
										marginLeft: "0",
										width: "100%",
										border: "1px solid #9a9a9a",
										backgroundColor: "#fcfcfc",
									}}
									value={selectedChallenge}
									onChange={(e) => {
										challenges.map((c) => {
											if (c.key == e.target.value) setChallenge(c);
										});
										setSelectedChallenge(e.target.value);
										localStorage.setItem("challengeId", e.target.value);
									}}
								>
									{challenges
										.filter((d) => rounds.includes(d.key))
										.map((obj, i) => {
											return (
												<option value={obj.key} key={obj.key}>
													{obj.previousRounds
														? "round " + (obj.previousRounds.length + 1)
														: "round 1"}
												</option>
											);
										})}
								</Form.Control>
							</Col>
						)}
					</>
				) : (
					""
				)}
			</Row>
			<Card className="my-4">
				<div id="videos" className="h-100">
					<div>
						{loader && selectedChallenge ? (
							<div className="loader text-center">
								<Spinner animation="border" size={"sm"} role="status" />
							</div>
						) : (
							<div className="challenge" key={challenge.id}>
								<div>
									<div
										className="videos"
										style={
											videos && videos.length
												? { display: "grid" }
												: { display: "inline-block" }
										}
									>
										<div>
											<div className="uploader">
												<input
													id="file-upload"
													type="file"
													name="fileUpload"
													accept="video/*"
													onChange={(e) => onVideoUpload(e)}
												/>
												<label
													htmlFor="file-upload"
													id="file-drag"
													onDragOver={(e) => {
														fileDragHover(e);
													}}
													onDragLeave={(e) => {
														fileDragHover(e);
													}}
													onDrop={(e) => {
														fileSelectHandler(e);
													}}
												>
													{videoBlob ? (
														<ReactPlayer
															className="video-file-container"
															id="file-video"
															controls
															url={videoBlob}
															alt="video preview"
														/>
													) : (
														<div className="video-file-container-empty">
															<VideosIcon />
														</div>
													)}
													<div id="start">
														<div>Select a video or drag here</div>
													</div>
													<div id="response" className="hidden">
														<div id="messages"></div>
													</div>
												</label>
												{videoPosted ? <b>{videoPosted}</b> : ""}

												{
													videoFile ? (
														isSubmitLoader ? (
															<div style={{ width: "100%" }}>
																<Spinner
																	animation="border"
																	size={"sm"}
																	role="status"
																/>
																<div
																	style={{
																		display: "block",
																		width: "90%",
																		margin: "0 auto",
																		height: "18px",
																	}}
																>
																	<div
																		style={{
																			width: progress + "%",
																			height: "18px",
																			borderRadius: "4px",
																			fontSize: "12px",
																			color: "#464646",
																			background: "rgb(0, 227, 150)",
																			whiteSpace: 'nowrap'
																		}}
																	>
																		uploading {progress.toFixed(0)}
																		<span style={{ opacity: 0.5 }}>%</span>
																	</div>
																</div>
																{progress2 > 0 && <div
																	style={{
																		display: "block",
																		width: "90%",
																		margin: "0 auto",
																		height: "18px",
																		marginTop: '3px'
																	}}
																>
																	<div
																		style={{
																			width: progress2 + "%",
																			height: "18px",
																			borderRadius: "4px",
																			fontSize: "12px",
																			color: "#464646",
																			background: "rgb(0, 227, 150)",
																			whiteSpace: 'nowrap'
																		}}
																	>
																		transcoding {progress2.toFixed(0)}
																		<span style={{ opacity: 0.5 }}>%</span>
																	</div>
																</div>}
															</div>
														) : (
															<>
																<Form.Control
																	as="textarea"
																	rows="2"
																	id="caption"
																	onInput={(e) => setCaption(e.target.value)}
																	placeholder="enter caption"
																	style={{
																		borderColor: "#ddd",
																		width: "80%",
																		margin: "0 auto",
																		marginBottom: "1em",
																	}}
																/>
																<span
																	id="file-upload-btn"
																	style={{ padding: "8px 25px" }}
																	className=" submit-button button button-primary "
																	onClick={(e) => {
																		postVideo(challenge, groups, []);
																	}}
																>
																	post video
																</span>
															</>
														)
													) : (
														<span style={{ opacity: 0, pointerEvents: "none" }}>
															upload video
														</span>
													)
													//<span id="file-upload-btn" className="btn btn-primary" style={{background: '#aaa', cursor: 'default'}}>post video</span>
													//<FontAwesomeIcon icon={faPlus} size="4x" className="mr-2" style={{color: '#aaa', cursor: 'pointer'}}/>
												}
											</div>
										</div>

										{videos && videos.length ? (
											videos.map((video, i) => (
												<div
													className="video d-flex flex-column justify-content-between"
													style={{ position: "relative" }}
													key={video.id}
												>
													<div
														className="remove-img"
														style={{
															cursor: "pointer",
															borderRadius: "50%",
															backgroundColor: "#333",
															padding: "3px 10px",
															position: "absolute",
															right: "-1%",
															top: "-1%",
															zIndex: "2",
														}}
														onClick={() => {
															onDeleteClick(video.id);

															// inputVideo.current.value = "";
														}}
													>
														<div className="icon">
															<FontAwesomeIcon
																icon={faTimes}
																size="sm"
																color="#fff"
															/>
														</div>
													</div>
													<div>
														<ReactPlayer
															className="video-container"
															url={video.videoLink}
															controls
															light={video.thumbnail || true}
															playing={true}
															playIcon=<PlayIcon/>
														/>
														<div id="video-caption">
															{video?.caption?.length > 260 ? (
																<div>
																	{isReadMore
																		? video.caption.slice(0, 260)
																		: video.caption}
																	<span
																		style={{
																			color: "#0e5171",
																			fontWeight: "600",
																			textDecoration: "underline",
																			cursor: "pointer",
																			marginLeft: "5px",
																		}}
																		onClick={toggleReadMore}
																		className="read-or-hide"
																	>
																		{isReadMore ? "...read more" : " show less"}
																	</span>
																</div>
															) : (
																video.caption
															)}
														</div>
													</div>
													<div className="author  d-flex flex-wrap justify-content-between align-items-start">
														<div className="author-wrapper">
															<div className="author-info d-flex align-items-center">
																<img
																	alt=""
																	src={
																		fbImage(video.userImage) ||
																		"https://firebasestorage.googleapis.com/v0/b/diet-challenge-amr.appspot.com/o/images%2Fdefault-image.png?alt=media&token=4e702cb6-9c5b-438b-b542-d9b3be9275de"
																	}
																/>
																<div className="video-username d-flex flex-column">
																	<span>{video.userName}</span>
																	{moment(video.datetime)
																		.fromNow(
																			updateLocale("en", {
																				relativeTime: {
																					future: "in %s",
																					past: "%s ",
																					s: "sec",
																					m: "%d m",
																					mm: "%d m",
																					h: "%d h",
																					hh: "%d h",
																					d: "%d d",
																					dd: "%d d",
																					M: "a month",
																					MM: "%d months",
																					y: "y",
																					yy: "%d y",
																				},
																			})
																		)
																		.replace("a few seconds ago", "now")}
																</div>
															</div>

															<div className="views d-flex">
																<span style={{ margin: "0" }}>
																	<b style={{ margin: 0 }}>
																		<FontAwesomeIcon
																			icon={faThumbsUp}
																			size="lg"
																			style={{
																				marginLeft: ".5em",
																			}}
																		/>{" "}
																		{video.likes ? video.likes.length : 0}
																	</b>
																</span>
																<div
																	variant="light"
																	style={{
																		cursor: "pointer",
																	}}
																>
																	<span
																		onClick={() => {
																			setComments([]);
																			setShowComments(video.id);
																		}}
																	>
																		<FontAwesomeIcon
																			icon={faComment}
																			size="lg"
																			style={{
																				marginLeft: ".5em",
																			}}
																		/>{" "}
																		<b style={{ margin: 0 }}>
																			{video.commentCount || 0}
																		</b>
																	</span>
																</div>
															</div>
														</div>

														<div>{video.group ? video.group.name : ""}</div>
													</div>
												</div>
											))
										) : (
											<div className="no-videos text-center">no videos</div>
										)}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</Card>
			<Modal show={showComments} onHide={() => setShowComments(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Comments</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						className="form"
						style={{}}
						onSubmit={(e) => {
							submitComment(e);
						}}
					>
						{comments && comments.length
							? comments.map((message) => (
									<Form.Group
										key={message.id}
										style={
											message.ans
												? {
														borderRadius: "1em",
														background: "rgb(214 241 226)",
														padding: "1em",
														marginLeft: "0em",
														marginRight: "0em",
														color: "#464646",
												  }
												: {
														borderRadius: "1em",
														background: "#eee",
														padding: "1em",
														marginLeft: "0em",
														marginRight: "0em",
												  }
										}
									>
										<div
											style={{
												fontSize: "0.9em",
												opacity: "0.9",
												float: "right",
											}}
										>
											{moment
												.utc(message.datetime)
												.fromNow(
													updateLocale("en", {
														relativeTime: {
															future: "in %s",
															past: "%s ",
															s: "sec",
															m: "%d m",
															mm: "%d m",
															h: "%d h",
															hh: "%d h",
															d: "%d d",
															dd: "%d d",
															M: "a month",
															MM: "%d months",
															y: "y",
															yy: "%d y",
														},
													})
												)
												.replace("a few seconds ago", "now")}
										</div>

										<div>
											<img
												alt="user"
												src={fbImage(message.userImage)}
												height="40"
												width="auto"
												style={{ borderRadius: "20px", marginRight: "10px" }}
											/>
											<span>{message.userName}</span>
										</div>

										<div>{message.comment}</div>
									</Form.Group>
							  ))
							: null}

						<br />
						<Form.Control
							as="textarea"
							rows="3"
							required
							placeholder={"post comment"}
							value={newMessage.message}
							onChange={(e) => {
								addMessage(e.target.value);
							}}
						/>
						<div className="w-100 text-center">
							<Button
								variant="dark"
								type="submit"
								className=" submit-button button button-primary "
								style={{ width: "200px", padding: "8px 25px" }}
							>
								{isSubmitCommentLoader ? (
									<Spinner animation="border" size={"sm"} role="status" />
								) : (
									<>
										<FontAwesomeIcon
											icon={faComment}
											size="1x"
											className="mr-2"
										/>
										Post Comment
									</>
								)}
							</Button>
						</div>
						<br style={{ clear: "both" }} />
					</Form>
				</Modal.Body>
			</Modal>
			<Modal
				show={showDeleteModal}
				onHide={() => {
					setShowDeleteModal(false);
					setVideoData({});
				}}
			>
				<DeleteAlert
					onHide={() => {
						setShowDeleteModal(false);
						setVideoData({});
					}}
					title={"Delete Video"}
					message={"Are you sure you want to delete video?"}
					docId={docId}
					collectionName={"feeds"}
				/>
			</Modal>
		</div>
	);
}
