import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  ButtonToolbar,
  Spinner,
  Table,
  DropdownButton,
  Dropdown,
  Breadcrumb,
  ToggleButton,
  Row,
  Col,
} from "react-bootstrap";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faPencilAlt,
  faCopy,
  faTrashAlt,
  faFilter,
  faArrowUp,
  faArrowDown,
  faCommentDots,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarRatings from "react-star-ratings";

import AddQuestion from "../../components/AddQuestion";
import { firestore } from "../../services/firebase";
import * as _ from "lodash";
import QuestionFilter from "../../components/QuestionFilter";
import DeleteAlert from "../../components/DeleteAlert";
import moment from "moment";
import { Header } from "../../components/Header";
import { useParams } from "react-router-dom";

import userImg from "../../assets/images/user.png";
import "./Questions.scss";

library.add(faPlus);

export function Questions(props) {
  let { id } = useParams();

  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedTag, setSelectedTag] = useState();
  const [selectedChallenge, setSelectedChallenge] = useState();
  const [challengesLoader, setChallengesLoader] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [docId, setDocId] = useState("");
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [isLoadMoreLoader, setIsLoadMoreLoader] = useState(false);
  const [lastVisible, setLastVisible] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [showForUser, setShowForUser] = useState(id || false);

  const [challengeIdToName, setChallengeIdToName] = useState({});
  const [challengeInQuestions, setChallengeInQuestions] = useState({});

  const [sort, setSort] = useState({
    field: "datetime",
    direction: "desc",
  });
  const tableHeaders = [
    {
      name: "User",
      filterField: "none",
    },
    {
      //    name: 'Date',
      //    filterField: 'datetime',
      //}, {
      name: "Question",
      filterField: "none",
    },
    {
      name: "Answer",
      filterField: "none",
    },
  ];
  const [page, setPage] = useState(1);
  const [tags, setTags] = useState({});
  const [opened, setOpened] = useState();
  const [openedMore, setOpenedMore] = useState();
  const [questions, setQuestions] = useState([]);
  const [questionData, setQuestionData] = useState({});
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [challenges, setChallenges] = useState([]);
  const [challengeData, setWorkoutPlanData] = useState({});
  const [unanswered, setUnanswered] = useState(false);
  const [groupNameById, setGroupNameById] = useState({});
  const [groupUnanswered, setGroupUnanswered] = useState({});
  const [workoutsLoader, setWorkoutsLoader] = useState(true);
  const [mealPlans, setMealPlans] = useState([]);
  const [workoutPlans, setWorkoutPlans] = useState([]);
  const [mealplansLoader, setMealplansLoader] = useState(true);

  const [showWorkoutPlan, setShowWorkoutPlan] = useState(false);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);

  useEffect(() => {
    getGroups();
    const challengeSubscription = getChallenges();
    async function fetch() {
      getMealPlans();
      getWorkoutPlans();
    }
    fetch();
    return () => {
      //   challengeSubscription();
    };
  }, []);

  useEffect(() => {
    let questionsSubscription = () => {};
    if (challenges.length) {
      getQuestions();
    }
    return () => {
      questionsSubscription();
    };
  }, [challenges, filterData, showForUser, unanswered]);
  /*
    async function getQuestions() {
        setLoader(true);
        const userDetails = JSON.parse(await AsyncStorage.getItem('userDetails'));
        let questionsRef = FirebaseService.firestore('questions')
            .where('nutritionistId', '==', userDetails.role.includes('nutritionist') ? userDetails.id : userDetails.nutritionistId)
            .limit(10)
            .orderBy('datetime', 'desc');
        if (page > 1) {
            questionsRef = questionsRef.startAfter(lastVisible);
        }
        const newSubscriptions = [...subscriptions];
        newSubscriptions.push(await questionsRef.onSnapshot(async (querySnapshot) => {
            let data = querySnapshot.docs.map((documentSnapshot, index) => {
                if (querySnapshot.docs.length === index + 1) {
                    setLastVisible(documentSnapshot);
                }
                return {
                    ...documentSnapshot.data(),
                    key: documentSnapshot.id,
                };
            });
            data = _.uniqBy([...questions, ...data], function (e) {
                return e.key;
            });
            console.log('data==', data);
            setQuestions(_.orderBy(data, 'datetime', 'desc'));
            setIsLoadMoreLoader(false);
            setIsLoading(false);
            setIsEndOfList(data.length < (10 * page));
        }, (error) => {
            console.log('error : ', error);
        }));
        setSubscriptions(newSubscriptions);
    }
*/
  function getMealPlans() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    firestore("meal_plans_essentials")
      //.where('nutritionistId', '==', userDetails.id)
      .where(
        "nutritionistId",
        "in",
        [userDetails.id].concat(userDetails.collaborators || [])
      )
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((documentSnapshot, i) => {
          return {
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          };
        });
        setMealPlans(_.orderBy(data, (item) => item.name, "desc"));
      });
  }

  function getWorkoutPlans() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    firestore("workout_plans_essentials")
      //.where('nutritionistId', '==', userDetails.id)
      .where(
        "nutritionistId",
        "in",
        [userDetails.id].concat(userDetails.collaborators || [])
      )
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((documentSnapshot, i) => {
          return {
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          };
        });
        setWorkoutPlans(_.orderBy(data, (item) => item.name, "desc"));
      });
  }
  function getQuestions() {
    setLoader(true);
    const userDetailsStorage = JSON.parse(localStorage.getItem("userDetails"));
    const adminDetailsStorage = JSON.parse(
      localStorage.getItem("adminDetails")
    );
    if (
      userDetailsStorage.collaborate &&
      userDetailsStorage.collaborate.length >= 9
    )
      alert(
        "you are collaborating more than 10 nutritionists, cannot retreive questions, please contact admin"
      );
    let questionsRef = firestore("feeds")
      //.where('type', '==', 'question')
      .where("nutritionistId", "==", userDetailsStorage.id)
      //.where('nutritionistId', 'in', [userDetailsStorage.id].concat(userDetailsStorage.collaborate || []))
      .orderBy("datetime", "desc")
      .limit(1000);

    if (showForUser)
      questionsRef = firestore("feeds")
        //.where('type', '==', 'question')
        .where("userId", "==", showForUser)
        .where("nutritionistId", "==", userDetailsStorage.id)
        //.where('nutritionistId', 'in', [userDetailsStorage.id].concat(userDetailsStorage.collaborate || []))
        .orderBy("datetime", "desc")
        .limit(1000);

    if (unanswered)
      // This is a solution to get all unanswered questions for certain nutritionist since we had a problem with a nutritionist (Waad) and they missed a bunch of users question unanswered which made an issue so we are getting all the unanswered questions by nut and not by challenge just a quick solution for now (Eslam Ali)
      questionsRef = firestore("feeds")
        //.where('type', '==', 'question')
        .where("nutritionistId", "==", userDetailsStorage.id)
        .where("isAnswered", "==", false)
        //.where('nutritionistId', 'in', [userDetailsStorage.id].concat(userDetailsStorage.collaborate || []))
        .orderBy("datetime", "desc")
        .limit(100);

    if (page > 1) {
      questionsRef = questionsRef.startAfter(lastVisible);
    }
    if (filterData.answered) {
      questionsRef = questionsRef.where("day", "==", Number(filterData.day));
    }
    if (filterData.challengeId) {
      questionsRef = questionsRef.where(
        "challengeId",
        "==",
        filterData.challengeId
      );
      console.log(filterData);
    }
    return questionsRef.onSnapshot(
      async (querySnapshot) => {
        var opened = 0,
          openedMore = 0;
        var tags = {};
        var _challenges = {};
        let data = querySnapshot.docs.map((documentSnapshot, i) => {
          if (i < 300) tags[documentSnapshot.data().tag] = true;

          if (i < 300) _challenges[documentSnapshot.data().challengeId] = true;

          if (i < 300 && !documentSnapshot.data().answer) opened++;
          if (
            !documentSnapshot.data().answer &&
            challenges.length &&
            moment(
              challenges.find((challenge) => {
                return challenge.key === documentSnapshot.data().challengeId;
              })?.endDatetime
            ).isAfter(moment())
          )
            openedMore++;
          return {
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          };
        });
        setTags(tags);
        setOpened(opened);
        setOpenedMore(openedMore);
        setChallengeInQuestions(_challenges);
        //data = _.uniqBy([...questions, ...data], function (e) {
        //    return e.key;
        //});
        const method = function (item) {
          return moment.utc(item.datetime).format();
        };
        var questions = _.orderBy(data, method, "desc");
        if (unanswered) {
        }
        questions.map((d, i) => {
          if (i && d.challengeId !== questions[i - 1].challengeId)
            d.anotherRound = true;
        });
        setQuestions(_.orderBy(data, method, "desc"));
        setLoader(false);
      },
      (error) => {
        console.error("error : ", error);
      }
    );
  }

  async function handleLoadMore() {
    if (!isLoadMoreLoader && !isEndOfList) {
      const newPage = _.clone(page);
      setPage(newPage + 1);
      setIsLoadMoreLoader(true);
      await getQuestions();
    }
  }

  function getChallenges() {
    setLoader(true);
    const userDetailsStorage = JSON.parse(localStorage.getItem("userDetails"));
    const adminDetailsStorage = JSON.parse(
      localStorage.getItem("adminDetails")
    );
    setChallengesLoader(true);
    return (
      firestore("challenges")
        //.where('nutritionistId', '==', userDetails.id)
        .where(
          "nutritionistId",
          "in",
          [userDetailsStorage.id].concat(userDetailsStorage.collaborate || [])
        )
        .orderBy("date", "desc")
        .limit(10)
        .get()
        .then((querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });
          var _challengeIdToName = {};
          //data.map((plan)=>{_challengeIdToName[plan.key] = plan.name + (plan.previousRounds&&plan.previousRounds.length? ' round '+Number(plan.previousRounds.length+1):'')})
          data.map((plan) => {
            _challengeIdToName[plan.key] = plan.name;
          });
          setChallengeIdToName(_challengeIdToName);
          setChallenges(data);
          setChallengesLoader(false);
        })
    );
  }

  function getRooms() {
    return firestore("rooms").onSnapshot((querySnapshot) => {
      let data = querySnapshot.docs.map((documentSnapshot, i) => {
        return {
          ...documentSnapshot.data(),
          key: documentSnapshot.id,
        };
      });
      //setRooms(data);
    });
  }

  function getGroups() {
    const userDetailsStorage = JSON.parse(localStorage.getItem("userDetails"));
    const adminDetailsStorage = JSON.parse(
      localStorage.getItem("adminDetails")
    );
    return firestore("groups")
      .where(
        "nutritionistId",
        "in",
        [userDetailsStorage.id].concat(userDetailsStorage.collaborate || [])
      )
      .get()
      .then((querySnapshot) => {
        var groupNameById = {};
        let data = querySnapshot.docs.map((documentSnapshot, i) => {
          groupNameById[documentSnapshot.id] = documentSnapshot.data().name;
          return {
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          };
        });

        setGroupNameById(groupNameById);
      });
  }

  const onSelect = (key, isSelect) => {
    const selectedRows = [...selectedRecords];
    if (isSelect) {
      selectedRows.push(key);
    } else {
      const index = selectedRows.findIndex((o) => o === key);
      if (index > -1) {
        selectedRows.splice(index, 1);
      }
    }
    setSelectedRecords(selectedRows);
  };

  async function onDeleteClick(key) {
    setDocId(key);
    setShowDeleteModal(true);
  }

  async function editQuestion(question = "") {
    var snapshot = await firestore("feeds")
      .doc(question.key)
      .collection("comments")
      .orderBy("datetime", "asc")
      .get();
    question.comments = snapshot.docs.length
      ? snapshot.docs.map((d) => {
          var obj = d.data();
          obj.id = d.id;
          return obj;
        })
      : [];

    const userDetailsStorage = JSON.parse(localStorage.getItem("userDetails"));
    const adminDetailsStorage = JSON.parse(
      localStorage.getItem("adminDetails")
    );
    question.comments.map((d) => {
      if (
        userDetailsStorage.id === d.userId ||
        adminDetailsStorage.id === d.userId
      )
        d.ans = true;
      return d;
    });

    setQuestionData(question);
    setShow(true);
  }

  function cloneQuestion(question = {}) {
    delete question.key;
    setQuestionData(question);
    setShow(true);
  }

  function selectAllCheckbox(e) {
    setIsSelectAllSelected(e.target.checked);
    const selectedRows = [];
    if (e.target.checked) {
      questions.forEach((o) => {
        selectedRows.push(o.key);
      });
    }
    setSelectedRecords(selectedRows);
  }

  function checkIsSelected(key) {
    if (selectedRecords.length) {
      const index = selectedRecords.findIndex((o) => o === key);
      return index > -1;
    }
    return false;
  }

  function removeFilter(key) {
    const filter = _.clone(filterData);
    delete filter[key];
    setFilterData(filter);
  }

  function displayFilter(filterKey) {
    if (filterKey === "challengeId") {
      const index = challenges.findIndex(
        (o) => o.key === filterData[filterKey]
      );
      if (index > -1) {
        return challenges[index].name;
      }
    }
    return filterData[filterKey];
  }

  function sortData(filterField = "", data = [], isInitSort = false) {
    if (filterField !== "none") {
      let sortClone = _.clone(sort);
      if (filterField && !isInitSort) {
        if (sortClone.field === filterField) {
          sortClone.direction = sortClone.direction === "asc" ? "desc" : "asc";
        } else {
          sortClone.field = filterField;
          sortClone.direction = "asc";
        }
        setSort(sortClone);
      }
      let usersClone = isInitSort ? data : questions;
      console.log("sortClone", sortClone);
      usersClone = _.orderBy(
        _.clone(usersClone),
        sortClone.field,
        sortClone.direction
      );
      setQuestions(usersClone);
    }
  }
  function text_truncate(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }

  function fbImage(imageLink) {
    if (!imageLink) return imageLink;
    var query = imageLink.split("asid=")[1];
    if (!query) return imageLink;
    var fbId = query.split("&")[0];
    if (!fbId) return imageLink;

    return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
  }

  function challengeList(challengeIdToName) {
    var arr = [];
    var names = {};
    Object.keys(challengeIdToName)
      .filter((d) => challengeInQuestions[d])
      .map((d) => {
        if (!names[challengeIdToName[d]]) {
          names[challengeIdToName[d]] = true;
          arr.push(challengeIdToName[d]);
        }
      });

    return arr;
  }

  return (
    <>
      <Header header="Questions" />
      <hr />
      <div className="questions-page"></div>
      <div className="pt-2 questions-page">
        <Row>
          {challengeList(challengeIdToName).length > 1 ? (
            <Col md={12} xl={4}>
              <h4>Challenge</h4>
              <Form.Control
                required
                as="select"
                placeholder="Select Challenge"
                style={{
                  cursor: "pointer",
                  height: "auto",
                  display: "inline-block",
                  marginLeft: "0",
                  width: "100%",
                  border: "1px solid #9a9a9a",
                  backgroundColor: "#fcfcfc",
                }}
                value={selectedChallenge}
                onChange={(e) => {
                  setSelectedChallenge(e.target.value);
                }}
              >
                <option value={""} key={""}>
                  All challenges
                </option>
                {challengeList(challengeIdToName).map((obj, i) => {
                  return (
                    <option value={obj} key={obj}>
                      {obj}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          ) : null}
          {Object.keys(tags).length > 1 ? (
            <Col xl={4} md={8}>
              <h4>Type</h4>
              <Form.Control
                required
                as="select"
                placeholder="Select Tag"
                style={{
                  cursor: "pointer",
                  height: "auto",
                  display: "inline-block",
                  marginLeft: "0",
                  width: "100%",
                  border: "1px solid #9a9a9a",
                  backgroundColor: "#fcfcfc",
                }}
                value={selectedTag}
                onChange={(e) => {
                  setSelectedTag(e.target.value);
                }}
              >
                <option value={""} key={""}>
                  All types
                </option>
                {Object.keys(tags).map((obj, i) => {
                  return (
                    <option value={obj} key={obj}>
                      {obj
                        .replace("MealPlan", "change mealplan request")
                        .replace("WorkoutPlan", "change workout plan request")}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          ) : null}
          {!loader && (
            <Col xl={4} md={4}>
              {filterData &&
                Object.keys(filterData).map((filterKey) => {
                  return filterData[filterKey] ? (
                    <Button
                      key={filterKey}
                      onClick={() => {
                        removeFilter(filterKey);
                      }}
                      className="rounded-pill ml-3"
                      variant="outline-secondary"
                      size="sm"
                    >
                      {displayFilter(filterKey)}
                    </Button>
                  ) : (
                    ""
                  );
                })}

              <ButtonToolbar className="ml-auto" style={{ height: "100%" }}>
                {opened !== 0 && openedMore !== 0 && (
                  <ToggleButton
                    style={{
                      marginBottom: "0",
                      marginRight: "10px",
                      color: "white",
                      borderRadius: "5px",
                      padding: "0.8em 0.5em",
                      alignSelf: "end",
                    }}
                    type="checkbox"
                    size="sm"
                    variant="warning"
                    className="ml-2 answered-toggle button button-disabled"
                    checked={unanswered}
                    onChange={(e) => {
                      setUnanswered(e.target.checked);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={unanswered ? faCheckSquare : faCommentDots}
                      size="1x"
                      className="mr-2"
                    />
                    Unanswered{" "}
                    {(!loader &&
                      `(${
                        !showMore && openedMore === 0
                          ? opened
                          : selectedChallenge
                          ? selectedChallenge in groupUnanswered
                            ? groupUnanswered[selectedChallenge]
                            : 0
                          : openedMore
                      })`) ||
                      ""}
                  </ToggleButton>
                )}
                {/* {console.log(
                                    opened,
                                    openedMore,
                                    showMore,
                                    selectedChallenge,
                                    groupUnanswered[selectedChallenge],
                                    selectedChallenge in groupUnanswered,
                                    selectedTag
                                )} */}

                {showForUser && (
                  <Button
                    className="btn-sm"
                    style={{
                      marginBottom: "0",
                      borderRadius: "5px",
                      padding: "0.8em 1em",
                      alignSelf: "end",
                    }}
                    variant="success"
                    onClick={() => {
                      setShowForUser(false);
                    }}
                  >
                    {questions && questions[0] ? questions[0].userName : "back"}{" "}
                    X
                  </Button>
                )}
              </ButtonToolbar>
            </Col>
          )}
        </Row>
        <div className="pt-2">
          <Card className="my-3">
            <Card.Body className="p-0">
              <div style={{ overflowX: "auto" }}>
                <Table className="m-0 table-bordered" responsive="xl">
                  <thead style={{ fontWeight: "700" }}>
                    <tr>
                      {/*<th>
                                        <Form.Check size="sm" checked={isSelectAllSelected}
                                            onChange={(e) => selectAllCheckbox(e)} />
                                    </th>*/}
                      {tableHeaders &&
                        tableHeaders.map((o, i) => {
                          return (
                            <td
                              key={i}
                              className="text-capitalize"
                              style={{ borderTop: "none" }}
                            >
                              {o.name}{" "}
                              {sort && sort.field === o.filterField ? (
                                <span>
                                  <FontAwesomeIcon
                                    icon={
                                      sort.direction === "asc"
                                        ? faArrowUp
                                        : faArrowDown
                                    }
                                    className="ml-1"
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <tr>
                        <td
                          className="text-center"
                          colSpan={tableHeaders.length + 1}
                        >
                          <Spinner
                            animation="border"
                            size={"sm"}
                            role="status"
                          />
                        </td>
                      </tr>
                    ) : questions.length ? (
                      questions
                        .filter((q) =>
                          selectedTag ? q.tag === selectedTag : true
                        )
                        .filter((q) =>
                          selectedChallenge
                            ? challengeIdToName[q.challengeId] ===
                              selectedChallenge
                            : true
                        )
                        .filter((q) => !unanswered || !q.answer)
                        .filter((q) =>
                          !showForUser
                            ? unanswered && challenges.length
                              ? moment(
                                  challenges.find((challenge) => {
                                    return challenge.key === q.challengeId;
                                  })?.endDatetime
                                ).isAfter(moment())
                              : true
                            : true
                        )
                        .map((question, i) => (
                          <tr
                            key={question.key}
                            style={
                              showMore ? {} : i > 300 ? { display: "none" } : {}
                            }
                            className={
                              question.tag === "MealPlan"
                                ? "MealPlan"
                                : question.tag === "WorkoutPlan"
                                ? "WorkoutPlan"
                                : ""
                            }
                            // {challengeList(challengeIdToName).length <= 1 &&
                            // question.anotherRound &&
                            // "separate"}
                          >
                            <td style={{ maxWidth: "230px" }}>
                              <img
                                alt="user"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                  display: "block",
                                }}
                                src={
                                  question.userImage
                                    ? fbImage(question.userImage)
                                    : userImg
                                }
                              />
                              <div
                                style={{
                                  marginTop: "10px",
                                  // marginLeft: "10px",
                                  fontWeight: "700",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowForUser(question.userId);
                                }}
                              >
                                {question.userName}
                              </div>
                              <div
                                style={{
                                  // marginLeft: "10px",
                                  fontSize: ".8em",
                                  opacity: ".7",
                                }}
                              >
                                {moment
                                  .utc(question.datetime)
                                  .fromNow()
                                  .replace("a few seconds ago", "now")}{" "}
                              </div>
                              <div
                                style={{
                                  // marginLeft: "10px",
                                  fontSize: ".8em",
                                  opacity: ".7",
                                }}
                              >
                                {groupNameById[question.groupId]}
                              </div>
                              <div
                                style={{
                                  // marginLeft: "10px",
                                  fontSize: ".8em",
                                  opacity: ".7",
                                }}
                                className="text-capitalize"
                              >
                                {`${
                                  challengeIdToName[question.challengeId]
                                } (R${
                                  challenges.length &&
                                  challenges.find((challenge) => {
                                    return (
                                      challenge.key === question.challengeId
                                    );
                                  })?.rounds + 1
                                })`}
                              </div>
                            </td>
                            <td
                              style={{
                                whiteSpace: "normal",
                                cursor: "pointer",
                                fontSize: ".9em",
                                maxWidth: "300px",
                                width: "300px",
                              }}
                              onClick={() => {
                                editQuestion(question);
                              }}
                            >
                              <div
                                style={{ display: "flex", marginBottom: "7px" }}
                              >
                                {question.privacy &&
                                  question.privacy !== "public" && (
                                    <span className="private-tag">Private</span>
                                  )}

                                {question.tag && (
                                  <div
                                    className="question-tag"
                                    style={
                                      question.tag === "MealPlan"
                                        ? { background: "#ab2e2e" }
                                        : question.tag === "WorkoutPlan"
                                        ? { background: "#ab7f2e" }
                                        : {}
                                    }
                                  >
                                    {question.tag
                                      .replace(
                                        "MealPlan",
                                        "change mealplan request"
                                      )
                                      .replace(
                                        "WorkoutPlan",
                                        "change workout plan request"
                                      )}
                                  </div>
                                )}
                              </div>

                              {question.image && (
                                <img
                                  alt="question"
                                  src={question.image.url}
                                  style={{
                                    float: "left",
                                    height: "70px",
                                    width: "70px",
                                    objectFit: "cover",
                                    marginRight: "10px",

                                    borderRadius: "3px",
                                  }}
                                />
                              )}
                              <span dir="rtl">{question.question}</span>
                            </td>
                            <td
                              style={{
                                whiteSpace: "normal",
                                cursor: "pointer",
                                fontSize: ".8em",
                                opacity: ".8",
                              }}
                              onClick={() => {
                                editQuestion(question);
                              }}
                            >
                              <Row
                                style={{
                                  width: "auto",
                                  marginRight: "0",
                                  marginLeft: "0",
                                }}
                              >
                                <div
                                  style={{
                                    flex: "3",
                                    fontWeight: "500",
                                    color: "rgb(14, 81, 113)",
                                  }}
                                >
                                  {question.answer && (
                                    <span className="answer-by">
                                      {question.answerByName ||
                                        question.answerById ||
                                        "error"}
                                    </span>
                                  )}
                                  {question.answer && (
                                    <span dir="rtl">{question.answer}</span>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "7px",
                                  }}
                                >
                                  {question.answer ? (
                                    <>
                                      <Button
                                        className="button button-secondary"
                                        style={{
                                          fontWeight: "500",
                                          padding: "9px 11px",
                                          width: "7.5rem",
                                          maxWidth: "7.5rem",
                                          minWidth: "7.5rem",
                                          fontSize: "0.75rem",
                                        }}
                                        onClick={() => {
                                          editQuestion(question);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faPencilAlt}
                                          size="sm"
                                        />{" "}
                                        Edit Answer
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      variant="success"
                                      className="button button-edit"
                                      style={{
                                        fontWeight: "500",
                                        padding: "9px 11px",
                                        fontSize: "0.75rem",
                                        width: "7.5rem",
                                        maxWidth: "7.5rem",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        editQuestion(question);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        size="sm"
                                      />{" "}
                                      Answer
                                    </Button>
                                  )}

                                  <Button
                                    className="button button-disabled"
                                    style={{
                                      backgroundColor: "#f0f0f0",
                                      fontWeight: "500",
                                      padding: "9px 11px",
                                      maxWidth: "7.5rem",
                                      width: "7.5rem",
                                      color: "#333",
                                      borderRadius: "22px",
                                      fontSize: "0.75rem",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      onDeleteClick(question.key);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                  </Button>
                                </div>
                              </Row>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td
                          className="text-center"
                          colSpan={tableHeaders.length + 1}
                        >
                          {showForUser
                            ? "user has no questions"
                            : "no questions found"}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>

        {!loader &&
          !showMore &&
          !selectedChallenge &&
          !selectedTag &&
          !unanswered &&
          openedMore !== 0 && (
            <Button
              onClick={() => {
                setShowMore(true);
              }}
              className=""
              variant="dark"
              style={{
                marginBottom: "10em",
                marginLeft: "50%",
                transform: "translateX(-50%)",
              }}
            >
              show more
            </Button>
          )}
        <Modal show={show} onHide={() => setShow(false)}>
          <AddQuestion
            handleClose={() => setShow(false)}
            questionData={questionData}
            challenges={challenges}
            mealPlans={mealPlans}
            workoutPlans={workoutPlans}
          />
        </Modal>
        <Modal show={showFilter} onHide={() => setShowFilter(false)}>
          <QuestionFilter
            handleClose={(dismissData) => {
              setShowFilter(false);
              if (dismissData.filterData) {
                setFilterData(dismissData.filterData);
              }
              getQuestions();
            }}
            filterData={filterData}
            challenges={challenges}
          />
        </Modal>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <DeleteAlert
            onHide={() => setShowDeleteModal(false)}
            title={"Delete Question"}
            message={"Are you sure you want to delete question?"}
            docId={docId}
            collectionName={"feeds"}
          />
        </Modal>
      </div>
    </>
  );
}
