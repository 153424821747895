/** @format */

import React, { useEffect, useState } from "react";
import "./UsersPage.scss";

import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Spinner,
  Table,
  Col,
  Row,
  Accordion,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faArrowUp,
  faArrowDown,
  faInfoCircle,
  faUserPlus,
  faPlus,
  faUserTimes,
  faHeadset,
  faListAlt,
  faExchangeAlt,
  faStreetView,
  faTimes,
  faQuestionCircle,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { firestore } from "../../../services/firebase";
import * as _ from "lodash";
import AddGroup from "../../../components/AddGroup";
import Thread from "../../../components/Thread";
import UserFilter from "../../../components/UserFilter";
import { confirm } from "../../../components/Confirmation";
import * as countriesList from "./countries.json";
import firebase from 'firebase/compat/app';
import MapChart from "./MapChart";
import { NavLink, useHistory } from "react-router-dom";
import Filter from "../../../assets/icons/filter.svg";
import Star from "../../../assets/icons/Star.svg";
import Arrow from "../../../assets/icons/arrow.svg";
import moment from "moment";
import MealPlansChart from "./MealPlansChart";
import WorkoutPlansChart from "./WorkoutPlansChart";
import { Header } from "../../../components/Header";
import { rudderAnalytics } from "../../../services/trackers";

const countriesObj = {};
countriesList["default"].map((item) => {
  countriesObj[item.abbreviation] = item.country;
});

function sortByName(a, b) {
  if ((a.name || "").toLowerCase().trim() < (b.name || "").toLowerCase().trim())
    return -1;
  if ((a.name || "").toLowerCase().trim() > (b.name || "").toLowerCase().trim())
    return 1;
  return 0;
}

function getRandomColor() {
  var letters = "0123456789AB";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}
function setColors(tags) {
  var obj = {};
  tags.map((tag) => {
    obj[tag] = getRandomColor();
  });
  return obj;
}
function hexToRGBA(hex, opacity) {
  hex = hex.replace("#", ""); // remove the # if present
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);
  return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
}

export function UsersPage(props) {
  const tableHeaders = [
    {
      filterField: "name",
      name: "User",
    },
    {
      name: "Gender",
      filterField: "gender",
    },
    /*{
        name: 'Language',
        filterField: 'En',
    },*/
    // name: "Equipment",
    // filterField: "healthInfo.haveEquipment.0",

    {
      name: "Join date",
      filterField: "joinDate",
    },
    {
      name: "BMR",
      filterField: "bmr",
    },
    {
      name: "BMI",
      filterField: "bmi",
    },
    {
      name: "BMI Class",
      filterField: "bmiClass",
    },
    {
      name: "Weight",
      filterField: "weight",
    },
    {
      name: "Height",
      filterField: "height",
    },
    {
      name: "Diet type",
      filterField: "none",
    },
    {
      name: "age",
      filterField: "age",
      //}, {
      //    name: 'Allergies',
      //    filterField: 'none',
    },
    {
      name: "Group",
      filterField: "group",
    },
    {
      name: "Meal Plan",
      filterField: "none",
    },
    {
      name: "Work out",
      filterField: "none",
    },
  ];
  const [tags, setTags] = useState({});
  const [tagsEx, setTagsEx] = useState({});
  const [types, setTypes] = useState({});
  const [filterData, setFilterData] = useState({});
  const [challenge, setChallenge] = useState();
  const [selectedChallenge, setSelectedChallenge] = useState("");
  const [workoutPlans, setWorkoutPlans] = useState([]);
  const [demographics, setDemographics] = useState({});
  const [countries, setCountries] = useState({});
  const [newMessage, setNewMessage] = useState();
  const [sort, setSort] = useState({
    field: "joinDate",
    direction: "desc",
  });
  const [challengesLoading, setChallengesLoading] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [allChallenges, setAllChallenges] = useState([]);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsByPoints, setGroupsByPoints] = useState([]);
  const [mealPlans, setMealPlans] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [medicalConditions, setMedicalConditions] = useState([]);

  const [usersLoader, setUsersLoader] = useState(true);
  const [groupsLoader, setGroupsLoader] = useState(true);
  const [mealplansLoader, setMealplansLoader] = useState(true);
  const [workoutsLoader, setWorkoutsLoader] = useState(true);
  const [assignmentsLoader, setAssignmentsLoader] = useState(true);
  const [activeGroups, setActiveGroups] = useState(false);
  const [showInfo, SetShowInfo] = useState(false);
  const [userInfo, SetUserInfo] = useState(false);
  const [userInfoWeight, SetUserInfoWeight] = useState(false);
  const [userInfoStats, SetUserInfoStats] = useState(false);
  const [userInfoFollowupNo, SetUserInfoFollowupNo] = useState(false);
  const [ignore, setIgnore] = useState({});
  const [allIgnore, setAllIgnore] = useState({});
  const [rounds, setRounds] = useState(false);
  const [unassignedMsg, setUnassignedMsg] = useState();
  const [collapse, setCollapse] = useState(false);

  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);

  const [showGroup, setShowGroup] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [settingRule, setSettingRule] = useState(false);
  const [showAddRule, setShowAddRule] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [inactive, setInactive] = useState(false);

  const [groupData, setGroupData] = useState({});
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [maxUsersPerGroup, setMaxUsersPerGroup] = useState(0);
  const [mealPlansUsers, setMealPlansUsers] = useState({});
  const [workoutUsers, setWorkoutUsers] = useState({});
  const [autoAssignmments, setAutoAssignmments] = useState([]);
  // const [height, setHeight] = useState(0);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [admin, setAdmin] = useState(
    JSON.parse(localStorage.getItem("adminDetails"))
  );
  // const heightRef = useRef(null);

  const [oneToOne, setOneToOne] = useState(false);
  const [oneToOneEnabled, setOneToOneEnabled] = useState(false);
  const [formValue, setFormValue] = useState({});
  /*
	useEffect(() => {
		getRecipeTags();
		getExerciseTags();
		return () => {};
	}, []);

    async function getRecipeTags() {
        const recipeTypesDoc = await firestore('constants').doc('recipeTypes').get();
        const recipeTagsDoc = await firestore('constants').doc('recipeTags').get();
        setTypes(setColors([...new Set(recipeTypesDoc.data().value.map(d=> d.toLowerCase()))]));
        setTags(setColors([...new Set(recipeTagsDoc.data().value.map(d=> d.toLowerCase()))]));
    }

    async function getExerciseTags() {
        const recipeTagsDoc = await firestore('constants').doc('exerciseTags').get();
        setTagsEx(setColors([...new Set([...recipeTagsDoc.data().value.map(d=> d.toLowerCase())])]));
    }
*/
  const assignments = {};

  useEffect(() => {
    //setHeight(heightRef.current.clientHeight);

    getChallenges();
    const mealPlansSubscription = getMealPlans();
    // getAllergies();
    getMedicalConditions();
    const workoutPlansSubscription = getWorkoutPlans();
    return () => {
      mealPlansSubscription();
      workoutPlansSubscription();
    };
  }, []);

  useEffect(() => {
    firestore("one_to_one")
      .doc(user.id)
      .get()
      .then((doc) => {
        if (doc.exists) setOneToOneEnabled(true);
      });

    return () => {};
  }, []);

  useEffect(() => {
    const assignmentsSubscription = getAssignmentRules();
    return () => {
      if (assignmentsSubscription) {
        assignmentsSubscription();
      }
    };
  }, [selectedChallenge]);

  useEffect(() => {
    const groupsSubscription = getGroups();
    return () => {
      if (groupsSubscription) {
        groupsSubscription();
      }
    };
  }, [selectedChallenge]);

  useEffect(() => {
    setFilterData({});
    const usersSubscription = getUsers();
    return () => {
      if (usersSubscription) {
        usersSubscription();
      }
    };
  }, [selectedChallenge]);

  useEffect(() => {
    setSelectedRecords([]);
    setIsSelectAllSelected(false);
    setUsers(users);
    return () => {};
  }, [filterData]);

  useEffect(() => {
    SetUserInfoWeight();

    if (showInfo === true && userInfo.id)
      firestore("users")
        .doc(userInfo.id)
        .collection("weight")
        .get()
        .then((querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            return {
              ...documentSnapshot.data(),
            };
          });

          var startDate = moment(challenge.date);
          var endDate = moment(startDate).add(
            challenge.duration,
            challenge.durationType.toLowerCase()
          );

          data = data.filter(
            (d) =>
              moment(d.dateTime) >= startDate && moment(d.dateTime) <= endDate
          );
          data = _.orderBy(
            _.clone(data),
            (item) => moment(item.dateTime).format(),
            "asc"
          );
          data = data.map((d) => {
            d.day = moment(d.dateTime).diff(startDate, "days");
            return d;
          });

          SetUserInfoWeight(data);
        });

    return () => {};
  }, [showInfo]);

  useEffect(() => {
    SetUserInfoStats();

    if (showInfo === true && userInfo.id)
      firestore("user_statistics")
        .where("userId", "==", userInfo.id)
        .where("challengeId", "==", selectedChallenge)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size) {
            SetUserInfoStats(querySnapshot.docs[0].data());
          }
        });

    return () => {};
  }, [showInfo]);

  useEffect(() => {
    SetUserInfoFollowupNo();

    if (showInfo === true && userInfo.id)
      firestore("followup")
        .where("user.id", "==", userInfo.id)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.size) {
            SetUserInfoFollowupNo(querySnapshot.docs[0].data().phoneNo);
          }
        });

    return () => {};
  }, [showInfo]);

  function getChallenges() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setChallengesLoading(true);
    return firestore("challenges")
      .where("nutritionistId", "==", userDetails.id)
      .get()
      .then(
        (querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });

          //data = _.orderBy(data, 'price', 'desc')
          data = _.orderBy(
            data,
            (item) => moment.utc(item.date).format(),
            "desc"
          );

          var grouped = [],
            ignore = {},
            firstRound = {};
          data.map((d) => {
            if (d.previousRounds) {
              d.rounds = d.previousRounds.length + 1;
              d.previousRounds.map((id, i) => {
                ignore[id] = true;

                if (i === d.previousRounds.length - 1) firstRound[id] = true;
              });
              d.roundsIds = [d.key].concat(d.previousRounds);
            }

            if (firstRound[d.key]) {
              d.rounds = 1;
              d.roundsIds = [d.key];
            }
          });

          setIgnore(ignore);

          setChallenges(data);
          setChallengesLoading(false);

          var storageChallenge = localStorage.getItem("challengeId");
          if (storageChallenge && storageChallenge === userDetails.id) {
            firestore("one_to_one")
              .doc(storageChallenge)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  setOneToOne(true);
                  setChallenge(doc.data());
                  setSelectedChallenge(storageChallenge);
                }
              });
          } else if (
            storageChallenge &&
            data.filter((c) => c.key === storageChallenge).length
          ) {
            var d = data.filter((c) => c.key === storageChallenge)[0];
            var rounds = data.filter(
              (c) => c.roundsIds && c.roundsIds.includes(d.key)
            );
            setRounds(rounds.length ? rounds[0].roundsIds : false);
            setChallenge(d);
            setSelectedChallenge(d.key);
          } else if (data.length) {
            setRounds(data[0].roundsIds);
            setChallenge(data[0]);
            setSelectedChallenge(data[0].key);
            localStorage.setItem("challengeId", selectedChallenge);
          } else {
            firestore("one_to_one")
              .doc(user.id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  setOneToOne(true);
                  setChallenge(doc.data());
                  setSelectedChallenge(user.id);
                }
              });
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
  }

  var groupDigest;

  function getGroups() {
    if (selectedChallenge) {
      setGroupsLoader(true);
      return firestore("groups")
        .where("challengeId", "==", selectedChallenge)
        .onSnapshot((querySnapshot) => {
          var maxUserCount = 0;
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            maxUserCount = Math.max(
              maxUserCount,
              documentSnapshot.data().userCount
            );
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });
          var dataByPoints = _.orderBy(
            data,
            function (item) {
              return parseFloat(item.points);
            },
            "desc"
          );
          data = _.orderBy(
            data,
            function (item) {
              return parseFloat(item.name.replace("Group ", ""));
            },
            "asc"
          );

          var digest = JSON.stringify(
            JSON.parse(JSON.stringify(data)).map((d) => {
              delete d.points;
              return d;
            })
          );

          if (digest !== groupDigest) {
            setMaxUsersPerGroup(maxUserCount);
            setGroupsLoader(false);
            // console.log(data);
            setGroups(data.sort(sortByName).filter((d) => d.name));
            setGroupsByPoints(dataByPoints);
          }

          groupDigest = digest;
        });
    }
  }

  function getUsers() {
    if (selectedChallenge) {
      setUsersLoader(true);
      let userRef =
        selectedChallenge === user.id //one to one
          ? //firestore('users').where('joinedOneToOneIds', 'array-contains', selectedChallenge) :
            firestore("users").where(
              "joinedOneToOne." + selectedChallenge + ".to",
              ">",
              new Date()
            )
          : firestore("users").where(
              "joinedChallengeIds",
              "array-contains",
              selectedChallenge
            );

      //return userRef.get().then(async (querySnapshot) => {
      return userRef.onSnapshot(async (querySnapshot) => {
        var newUsers = false,
          noUsers = true;
        querySnapshot.docChanges().forEach(function (change) {
          noUsers = false;
          if (change.type === "added") newUsers = true;
        });

        if (!noUsers && !newUsers) return; //re render if new rows

        setTimeout(
          async function () {
            //delay a bit till auto assignments are done

            var mealPlansUsers = {},
              unassigned = 0;
            var workoutUsers = {},
              unassignedW = 0;
            var demographics = {
              male: 0,
              female: 0,
              bmr: 0,
              weight: 0,
              age: 0,
            };
            var countries = {};
            var unassignedMsg = [];
            var unassignedGroup = {};
            var unassignedMealplan = {};
            var unassignedWorkoutplan = {};

            let data = await Promise.all(
              querySnapshot.docs.map(async (documentSnapshot, i) => {
                let userData = {
                  ...documentSnapshot.data(),
                  key: documentSnapshot.id,
                };
                userData.age = calculateAge(userData.birthday);
                userData.bmr = +calculateBmr(userData).toFixed(2);
                userData.bmi = +calculateBmi(userData).toFixed(2);
                userData.bmiClass = calculateBmiClass(userData);
                userData.fitnessLevel = +calculateFitnessLevel(userData);
                userData.dietType = userData?.healthInfo?.dietType || "-";

                if (assignments[documentSnapshot.id]) {
                  userData.joinDate = assignments[documentSnapshot.id].joinDate;
                  userData.groupId = assignments[documentSnapshot.id].groupId;
                  userData.mealPlanId =
                    assignments[documentSnapshot.id].mealPlanId;
                  userData.workoutPlanId =
                    assignments[documentSnapshot.id].workoutPlanId;
                } else {
                  const challengeDoc = await documentSnapshot.ref
                    .collection("selectedChallenges")
                    .doc(selectedChallenge)
                    .get();
                  const challengeData = challengeDoc.data();

                  if (challengeData) {
                    userData.joinDate = challengeData.joinDate;
                    userData.groupId = challengeData.groupId;
                    userData.mealPlanId = challengeData.mealPlanId;
                    userData.workoutPlanId = challengeData.workoutPlanId;

                    assignments[documentSnapshot.id] = {
                      joinDate: userData.joinDate,
                      groupId: userData.groupId,
                      mealPlanId: userData.mealPlanId,
                      workoutPlanId: userData.workoutPlanId,
                    };
                  }

                  const weights = await documentSnapshot.ref
                    .collection("weight")
                    .get();
                  let data = weights.docs.map((documentSnapshot, i) => {
                    return {
                      ...documentSnapshot.data(),
                    };
                  });

                  var startDate = moment(challenge.date);
                  var endDate = moment(startDate).add(
                    challenge.duration,
                    challenge.durationType.toLowerCase()
                  );

                  data = data.filter(
                    (d) =>
                      moment(d.dateTime) >= startDate &&
                      moment(d.dateTime) <= endDate
                  );
                  data = _.orderBy(
                    _.clone(data),
                    (item) => moment(item.dateTime).format(),
                    "asc"
                  );
                  data = data.map((d) => {
                    d.day = moment(d.dateTime).diff(startDate, "days");
                    return d;
                  });
                  userData.weightChanges = "";
                  data.map((d) => {
                    userData.weightChanges += "\t" + d.day + "\t" + d.weight;
                    return d;
                  });
                  data.join();
                }

                if (!mealPlansUsers[userData.mealPlanId])
                  mealPlansUsers[userData.mealPlanId] = 0;
                mealPlansUsers[userData.mealPlanId]++;
                if (!userData.mealPlanId) unassigned++;

                if (!workoutUsers[userData.workoutPlanId])
                  workoutUsers[userData.workoutPlanId] = 0;
                workoutUsers[userData.workoutPlanId]++;
                if (!userData.workoutPlanId) unassignedW++;

                if (!userData.groupId && !oneToOne) {
                  unassignedGroup[
                    userData.name ? userData.name.split(" ")[0] : ""
                  ] = true;
                  unassignedMsg.push(
                    (userData.name || userData.email) + " is not in a group"
                  );
                }
                if (!userData.mealPlanId) {
                  unassignedMealplan[
                    userData.name ? userData.name.split(" ")[0] : ""
                  ] = true;
                  unassignedMsg.push(
                    (userData.name || userData.email) +
                      " has no assigned meal plan"
                  );
                }
                if (!userData.workoutPlanId) {
                  unassignedWorkoutplan[
                    userData.name ? userData.name.split(" ")[0] : ""
                  ] = true;
                  unassignedMsg.push(
                    (userData.name || userData.email) +
                      " has no assigned workout plan"
                  );
                }

                if (userData.gender === "male") demographics.male++;
                if (userData.gender === "female") demographics.female++;
                demographics.age += parseFloat(userData.age || 0);
                demographics.bmr += parseFloat(userData.bmr || 0);
                demographics.weight += parseFloat(userData.weight || 0);

                if (!countries[userData.country])
                  countries[userData.country] = 0;
                countries[userData.country]++;

                userData.rounds = 0;
                if (challenge && challenge.previousRounds)
                  challenge.previousRounds.map((id) => {
                    if (
                      userData.joinedChallengeIds &&
                      userData.joinedChallengeIds.includes(id)
                    )
                      userData.rounds++;
                  });

                //if(!userData.lastOpened || !userData.lastOpened.lastLoginUpdate || userData.joinDate > /*moment.utc(*/userData.lastOpened.lastLoginUpdate.toDate()/*)*/) userData.inactive = true
                if (
                  (!userData.lastOpened ||
                    !userData.lastOpened.lastLoginUpdate) &&
                  (!userData.deviceDetails ||
                    !userData.deviceDetails.lastLoginUpdate)
                )
                  userData.inactive = true;

                // console.log(
                //   userData.name +
                //     "\t" +
                //     userData.gender +
                //     "\t" +
                //     userData.age +
                //     "\t" +
                //     userData.weight +
                //     "\t" +
                //     userData.targetWeight +
                //     "\t" +
                //     userData.weightChanges
                // );
                return userData;
              })
            );

            if (unassignedMsg.length) {
              setUnassignedMsg(
                (Object.keys(unassignedGroup).length == 1
                  ? "(" +
                    Object.keys(unassignedGroup).join(", ") +
                    ") " +
                    " is not in a group"
                  : Object.keys(unassignedGroup).length > 1
                  ? "(" +
                    Object.keys(unassignedGroup).join(", ") +
                    ") " +
                    " are not in a group"
                  : "") +
                  ".  " +
                  (Object.keys(unassignedMealplan).length == 1
                    ? "(" +
                      Object.keys(unassignedMealplan).join(", ") +
                      ") " +
                      " has no assigned meal plan"
                    : Object.keys(unassignedMealplan).length > 1
                    ? "(" +
                      Object.keys(unassignedMealplan).join(", ") +
                      ") " +
                      " have no assigned meal plan"
                    : "") +
                  ".  " +
                  (Object.keys(unassignedWorkoutplan).length == 1
                    ? "(" +
                      Object.keys(unassignedWorkoutplan).join(", ") +
                      ") " +
                      " has no assigned workout plan"
                    : Object.keys(unassignedWorkoutplan).length > 1
                    ? "(" +
                      Object.keys(unassignedWorkoutplan).join(", ") +
                      ") " +
                      " have no assigned workout plan"
                    : "") +
                  "."
              );
              //setTimeout(() => {
              //alert(unassignedMsg.join("\r\n"));
              //}, 1000);
            } else {
              setUnassignedMsg();
            }

            mealPlansUsers["unassigned"] = unassigned;
            setMealPlansUsers(mealPlansUsers);

            workoutUsers["unassigned"] = unassignedW;
            setWorkoutUsers(workoutUsers);

            setUsersLoader(false);
            sortData({}, data, true);

            demographics.age /= data.length;
            demographics.bmr /= data.length;
            demographics.weight /= data.length;
            setDemographics(demographics);
            setCountries(countries);
          },
          !Object.keys(assignments).length ? 0 : 10000
        );
      });
    }
  }
  function fbImage(imageLink) {
    if (!imageLink) return imageLink;
    var query = imageLink.split("asid=")[1];
    if (!query) return imageLink;
    var fbId = query.split("&")[0];
    if (!fbId) return imageLink;

    return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
  }

  function filterUsers(users) {
    var data = _.clone(users);

    //user specific filters
    if (filterData.medicalCondition) {
      data = data.filter(
        (o) => o.medicalCondition === filterData.medicalCondition
      );
    }
    if (filterData.allergy) {
      data = data.filter((o) => o.allergy === filterData.allergy);
    }
    if (filterData.name) {
      data = data.filter(
        (o) =>
          o.name &&
          o.name.toLowerCase().trim() === filterData.name.toLowerCase().trim()
      );
    }
    if (filterData.gender) {
      data = data.filter((o) => o.gender === filterData.gender.toLowerCase());
    }
    if (filterData.selectedGoal) {
      data = data.filter(
        (o) =>
          o.healthInfo &&
          o.healthInfo.selectedGoal &&
          o.healthInfo.selectedGoal.length &&
          o.healthInfo.selectedGoal[0].trim().toLowerCase() ===
            filterData.selectedGoal.trim().toLowerCase()
      );
    }
    if (filterData.dietType) {
      if (filterData.dietType === "Standard")
        data = data.filter(
          (o) =>
            !o.healthInfo ||
            !o.healthInfo.dietType ||
            o.healthInfo.dietType.trim().toLowerCase() === "standard"
        );
      else
        data = data.filter(
          (o) =>
            o.healthInfo &&
            o.healthInfo.dietType &&
            o.healthInfo.dietType.trim().toLowerCase() ===
              filterData.dietType.trim().toLowerCase()
        );
    }
    if (filterData.exerciseType) {
      data = data.filter(
        (o) =>
          o.healthInfo &&
          o.healthInfo.exerciseType &&
          o.healthInfo.exerciseType.trim().toLowerCase() ===
            filterData.exerciseType.trim().toLowerCase()
      );
    }
    if (filterData.fitnessLevel) {
      data = data.filter((o) => +o.fitnessLevel === +filterData.fitnessLevel);
    }
    if (filterData.isPregnantOrBreastfeeding) {
      data = data.filter(
        (o) =>
          o.healthInfo &&
          o.healthInfo.isPregnantOrBreastfeeding &&
          o.healthInfo.isPregnantOrBreastfeeding.includes(
            filterData.isPregnantOrBreastfeeding
          )
      );
    }
    if (filterData.country) {
      if (filterData.country === "not-EG")
        data = data.filter((o) => o.country !== "EG");
      else data = data.filter((o) => o.country === filterData.country);
    }
    if (filterData.language) {
      data = data.filter(
        (o) => o.language === filterData.language.toLowerCase()
      );
    }
    if (filterData.startAge) {
      data = data.filter((o) => o.age >= filterData.startAge);
    }
    if (filterData.endAge) {
      data = data.filter((o) => o.age <= filterData.endAge);
    }
    if (filterData.startWeight) {
      data = data.filter((o) => +o.weight >= filterData.startWeight);
    }
    if (filterData.endWeight) {
      data = data.filter((o) => +o.weight <= filterData.endWeight);
    }
    if (filterData.startBmr) {
      data = data.filter((o) => o.bmr >= filterData.startBmr);
    }
    if (filterData.endBmr) {
      data = data.filter((o) => o.bmr <= filterData.endBmr);
    }
    if (filterData.startBmi) {
      data = data.filter((o) => o.bmi >= filterData.startBmi);
    }
    if (filterData.endBmi) {
      data = data.filter((o) => o.bmi <= filterData.endBmi);
    }
    if (filterData.bmiClass) {
      data = data.filter((o) => o.bmiClass === filterData.bmiClass);
    }
    if (filterData.minRounds) {
      data = data.filter((o) => o.rounds >= filterData.minRounds);
    }
    if (filterData.maxRounds) {
      data = data.filter((o) => o.rounds <= filterData.maxRounds);
    }
    //challenge specific filters
    if (filterData.groupId) {
      if (filterData.groupId === "unassigned")
        data = data.filter(
          (o) => !o.groupId || o.groupId === "" || !displayGroup(o.groupId)
        );
      else data = data.filter((o) => o.groupId === filterData.groupId);
    }
    if (filterData.mealPlanId) {
      if (filterData.mealPlanId === "unassigned")
        data = data.filter(
          (o) =>
            !o.mealPlanId ||
            o.mealPlanId === "" ||
            !displayMealPlan(o.mealPlanId)
        );
      else data = data.filter((o) => o.mealPlanId === filterData.mealPlanId);
    }
    if (filterData.workoutPlanId) {
      if (filterData.workoutPlanId === "unassigned")
        data = data.filter(
          (o) =>
            !o.workoutPlanId ||
            o.workoutPlanId === "" ||
            !displayWorkOutPlan(o.workoutPlanId)
        );
      else
        data = data.filter((o) => o.workoutPlanId === filterData.workoutPlanId);
    }

    return data;
  }

  var calculateAge = function (birthday) {
    // birthday is a date
    var ageDifMs = Date.now() - new Date(birthday).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  var calculateBmr = function (user) {
    var bmr;

    if (user.gender === "male")
      bmr = 66 + 13.7 * user.weight + 5 * user.height - 6.8 * user.age;
    if (user.gender === "female")
      bmr = 655 + 9.6 * user.weight + 1.8 * user.height - 4.7 * user.age;

    /*if (user.healthInfo && user.healthInfo.selectedFitness) {

            if (user.healthInfo.selectedFitness.includes('Beginner ')) bmr *= 1
            if (user.healthInfo.selectedFitness.includes('Intermediate ')) bmr *= 1.2
            if (user.healthInfo.selectedFitness.includes('Professional ')) bmr *= 1.375
        }*/

    bmr += 200;

    return bmr || 0;
  };

  var calculateBmi = function (user) {
    var bmi;

    bmi = user.weight / Math.pow(user.height / 100, 2);

    return bmi || 0;
  };

  var calculateBmiClass = function (user) {
    var bmi;

    bmi = user.weight / Math.pow(user.height / 100, 2);

    var bmiClass;

    if (bmi < 18.5) bmiClass = "Under Weight";
    else if (bmi < 25) bmiClass = "Normal Weight";
    else if (bmi < 30) bmiClass = "Over Weight";
    else if (bmi < 35) bmiClass = "Obesity Class I";
    else if (bmi < 40) bmiClass = "Obesity Class II";
    else if (bmi < 50) bmiClass = "Obesity Class III";

    return bmiClass || "";
  };

  var calculateFitnessLevel = function (user) {
    var level;

    if (
      user.healthInfo &&
      user.healthInfo.selectedFitness &&
      user.healthInfo.selectedFitness.length
    ) {
      if (user.healthInfo.selectedFitness.includes("Beginner ")) level = 1;
      if (user.healthInfo.selectedFitness.includes("Intermediate ")) level = 2;
      if (user.healthInfo.selectedFitness.includes("Professional ")) level = 3;
    }

    if (user.fitnessTestData) {
      const d = user.fitnessTestData;

      level = 0;

      if (+d["Plank"] >= 1 && +d["Plank"] <= 59) level += 1;
      if (+d["Plank"] >= 60 && +d["Plank"] <= 150) level += 2;
      if (+d["Plank"] >= 151) level += 3;

      if (+d["Push-ups"] >= 1 && +d["Push-ups"] <= 20) level += 1;
      if (+d["Push-ups"] >= 21 && +d["Push-ups"] <= 39) level += 2;
      if (+d["Push-ups"] >= 40) level += 3;

      if (+d["Squats"] >= 1 && +d["Squats"] <= 20) level += 1;
      if (+d["Squats"] >= 21 && +d["Squats"] <= 34) level += 2;
      if (+d["Squats"] >= 35) level += 3;

      if (+d["Crunches"] >= 1 && +d["Crunches"] <= 20) level += 1;
      if (+d["Crunches"] >= 21 && +d["Crunches"] <= 40) level += 2;
      if (+d["Crunches"] >= 41) level += 3;

      if (+d["Burpees"] >= 1 && +d["Burpees"] <= 10) level += 1;
      if (+d["Burpees"] >= 11 && +d["Burpees"] <= 20) level += 2;
      if (+d["Burpees"] >= 21) level += 3;

      /*offSets = [
                'Plank': {
                    1: [1, 59],
                    2: [60, 150],
                    3: [151],
                },
            ]*/

      level /= 5;

      level = Math.max(1, Math.floor(level));
    }

    return level || 0;
  };

  function getAssignmentRules() {
    if (!selectedChallenge) return;
    setAssignmentsLoader(true);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    return firestore("challenges")
      .doc(selectedChallenge)
      .collection("assignments")
      .onSnapshot((querySnapshot) => {
        let data = querySnapshot.docs.map((documentSnapshot, i) => {
          return {
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          };
        });

        setAutoAssignmments(_.orderBy(data, "datetime", "asc"));
        setAssignmentsLoader(false);
      });
  }

  function getMealPlans() {
    setMealplansLoader(true);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    return (
      firestore("meal_plans_essentials")
        //.where('nutritionistId', '==', userDetails.id)
        .where(
          "nutritionistId",
          "in",
          [userDetails.id].concat(userDetails.collaborators || [])
        )
        .onSnapshot((querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });
          setMealPlans(data.sort(sortByName).filter((d) => d.name));
          setMealplansLoader(false);
        })
    );
  }

  function getWorkoutPlans() {
    setWorkoutsLoader(true);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    return (
      firestore("workout_plans_essentials")
        //.where('nutritionistId', '==', userDetails.id)
        .where(
          "nutritionistId",
          "in",
          [userDetails.id].concat(userDetails.collaborators || [])
        )
        .onSnapshot((querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });
          setWorkoutPlans(data.sort(sortByName).filter((d) => d.name));
          setWorkoutsLoader(false);
        })
    );
  }

  function getAllergies() {
    return firestore("constants")
      .doc("allergies")
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.data();
        // console.log("data", data);
        setAllergies(data);
      });
  }

  function getMedicalConditions() {
    return firestore("constants")
      .doc("medicalConditions")
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.data();
        // console.log("data", data);
        setMedicalConditions(data);
      });
  }

  const getProgress = (val, total) => {
    const progressVal = parseInt(val);
    const totalVal = parseInt(total);
    return (progressVal * 100) / totalVal + "%";
  };

  function selectAllCheckbox(e) {
    setIsSelectAllSelected(e.target.checked);
    const selectedRows = [];
    if (e.target.checked) {
      filterUsers(users).forEach((o) => {
        selectedRows.push(o.key);
      });
    }
    setSelectedRecords(selectedRows);
  }

  const onSelect = (key, isSelect) => {
    const selectedRows = [...selectedRecords];
    if (isSelect) {
      selectedRows.push(key);
    } else {
      const index = selectedRows.findIndex((o) => o === key);
      if (index > -1) {
        selectedRows.splice(index, 1);
      }
    }
    setSelectedRecords(selectedRows);
  };

  function addGroup(group) {
    setGroupData({});
    setShowGroup(true);
  }

  function editGroup(group) {
    setGroupData(group);
    setShowGroup(true);
  }

  function checkIsSelected(key) {
    if (selectedRecords.length) {
      const index = selectedRecords.findIndex((o) => o === key);
      return index > -1;
    }
    return false;
  }

  function removeFilter(key) {
    const filter = _.clone(filterData);
    delete filter[key];
    setFilterData(filter);
  }

  function displayFilter(filterKey) {
    switch (filterKey) {
      case "groupId": {
        if (filterData[filterKey] === "unassigned") return "no group";
        const index = groups.findIndex((o) => o.key === filterData[filterKey]);
        if (index > -1) {
          return groups[index].name;
        }
        break;
      }
      case "mealPlanId": {
        if (filterData[filterKey] === "unassigned") return "no meal plan";
        const index = mealPlans.findIndex(
          (o) => o.key === filterData[filterKey]
        );
        if (index > -1) {
          return mealPlans[index].name;
        }
        break;
      }
      case "workoutPlanId": {
        if (filterData[filterKey] === "unassigned") return "no workout plan";
        const index = workoutPlans.findIndex(
          (o) => o.key === filterData[filterKey]
        );
        if (index > -1) {
          return workoutPlans[index].name;
        }
        break;
      }
      // case "equipment": {
      // 	return "equipment: " + filterData[filterKey];
      // }
      case "fitnessLevel": {
        return "Fitness Level: " + filterData[filterKey];
      }
      case "startAge": {
        return "From Age:" + filterData[filterKey];
      }
      case "endAge": {
        return "To Age:" + filterData[filterKey];
      }
      case "startWeight": {
        return "From Weight:" + filterData[filterKey];
      }
      case "endWeight": {
        return "To Weight:" + filterData[filterKey];
      }
      case "startBmr": {
        return "From BMR:" + filterData[filterKey];
      }
      case "endBmr": {
        return "To BMR:" + filterData[filterKey];
      }
      case "startBmi": {
        return "From BMI:" + filterData[filterKey];
      }
      case "endBmi": {
        return "To BMI:" + filterData[filterKey];
      }
      case "bmiClass": {
        return "BMI Class: " + filterData[filterKey];
      }
      case "minRounds": {
        return "Min Rounds:" + filterData[filterKey];
      }
      case "maxRounds": {
        return "Max Rounds:" + filterData[filterKey];
      }
      default: {
        return filterData[filterKey];
      }
    }
  }

  function sortData(filterField = "", data = [], isInitSort = false) {
    if (filterField !== "none") {
      let sortClone = _.clone(sort);
      if (filterField && !isInitSort) {
        if (sortClone.field === filterField) {
          sortClone.direction = sortClone.direction === "asc" ? "desc" : "asc";
        } else {
          sortClone.field = filterField;
          sortClone.direction = "asc";
        }
        setSort(sortClone);
      }
      let usersClone = isInitSort ? data : users;

      var method = false;
      if (filterField == "name")
        method = function (item) {
          return (item["name"] || item["email"]).trim().toLowerCase();
        };
      if (filterField == "group")
        method = function (item) {
          return displayGroup(item["groupId"])
            ? parseFloat(displayGroup(item["groupId"]).replace("Group ", ""))
            : 0;
        };
      if (filterField == "bmr")
        method = function (item) {
          return item["bmr"] ? parseFloat(item["bmr"]) : 0;
        };
      if (filterField == "bmi")
        method = function (item) {
          return item["bmi"] ? parseFloat(item["bmi"]) : 0;
        };
      if (filterField == "bmiClass")
        method = function (item) {
          return item["bmi"] ? parseFloat(item["bmi"]) : 0;
        };
      if (filterField == "weight")
        method = function (item) {
          return item["weight"] ? parseFloat(item["weight"]) : 0;
        };
      if (filterField == "joinDate")
        method = function (item) {
          return moment.utc(item.joinDate).format();
        };
      // if (filterField == "healthInfo.haveEquipment.0")
      // 	method = function (item) {
      // 		return item.healthInfo ? item.healthInfo.haveEquipment[0] || "" : "";
      // 	};
      usersClone = _.orderBy(
        _.clone(usersClone),
        method || sortClone.field,
        sortClone.direction
      );
      setUsers(usersClone);
    }
  }

  function displayGroup(filterKey) {
    const index = groups.findIndex((o) => o.key === filterKey);
    if (index > -1) {
      return groups[index].name;
    }
  }

  function displayMealPlan(filterKey) {
    const index = mealPlans.findIndex((o) => o.key === filterKey);
    if (index > -1) {
      return mealPlans[index].name;
    }
  }

  function displayWorkOutPlan(filterKey) {
    const index = workoutPlans.findIndex((o) => o.key === filterKey);
    if (index > -1) {
      return workoutPlans[index].name;
    }
  }

  async function removeAssignmentRule(rule) {
    if (
      await confirm(
        "auto assignment",
        "remove " + rule.assignment + " auto assignment rule ?",
        "Remove"
      )
    ) {
      await firestore("challenges")
        .doc(selectedChallenge)
        .collection("assignments")
        .doc(rule.key)
        .delete();
    }
  }

  async function autoAssignmmentAlgorithm(
    assignment,
    target,
    targetName,
    tags,
    types
  ) {
    if (!Object.keys(filterData).length) return;

    var conditions = JSON.parse(JSON.stringify(filterData));
    delete conditions.groupId;
    delete conditions.mealPlanId;
    delete conditions.workoutPlanId;
    if (!Object.keys(conditions).length) return;

    if (
      await confirm(
        "auto assignment",
        "use this assignment algorithm to\n auto assign new joining members ?\n\n" +
          Object.keys(conditions)
            .map(
              (key) =>
                "if user " +
                key +
                " " +
                "is" +
                " " +
                conditions[key] +
                " ==>  assign " +
                assignment +
                " : " +
                targetName
            )
            .join("\n"),
        "Save"
      )
    ) {
      await firestore("challenges")
        .doc(selectedChallenge)
        .collection("assignments")
        .add({
          assignment,
          ...(target && { target: target }),
          conditions,
          datetime: moment().format(),
          ...(tags && { tags: tags }),
          ...(types && { types: types }),
        });
    }
  }

  async function onSelectGroup(groupId) {
    await Promise.all(
      selectedRecords.map(async (o) => {
        await firestore("users")
          .doc(o)
          .collection("selectedChallenges")
          .doc(selectedChallenge)
          .update(
            selectedRecords.length === 1
              ? {
                  groupId: groupId,
                  groupManuallyAssigned: true,
                  groupManuallyAssignedBy: admin.name,
                }
              : { groupId: groupId }
          );
      })
    );
    var usersClone = _.clone(users);
    usersClone
      .filter((d) => selectedRecords.includes(d.key))
      .map((d) => {
        d.groupId = groupId;
      });
    setUsers(usersClone);
    //setSelectedRecords([]);
    //setIsSelectAllSelected(false);

    autoAssignmmentAlgorithm("group", groupId, displayGroup(groupId));
  }

  async function onSelectWorkoutPlan(selectedPlan) {
    await Promise.all(
      selectedRecords.map(async (o) => {
        await firestore("users")
          .doc(o)
          .collection("selectedChallenges")
          .doc(selectedChallenge)
          .update(
            selectedRecords.length === 1
              ? {
                  workoutPlanId: selectedPlan,
                  workoutPlanManuallyAssigned: true,
                  workoutPlanManuallyAssignedBy: admin.name,
                }
              : { workoutPlanId: selectedPlan }
          );
      })
    );
    var usersClone = _.clone(users);
    usersClone
      .filter((d) => selectedRecords.includes(d.key))
      .map((d) => {
        d.workoutPlanId = selectedPlan;
      });
    setUsers(usersClone);
    //setSelectedRecords([]);
    //setIsSelectAllSelected(false);

    reWritePlanBars();
    autoAssignmmentAlgorithm(
      "workout plan",
      selectedPlan,
      displayWorkOutPlan(selectedPlan)
    );
  }

  async function onSelectMealPlan(selectedPlan) {
    await Promise.all(
      selectedRecords.map(async (o) => {
        await firestore("users")
          .doc(o)
          .collection("selectedChallenges")
          .doc(selectedChallenge)
          .update(
            selectedRecords.length === 1
              ? {
                  mealPlanId: selectedPlan,
                  mealPlanManuallyAssigned: true,
                  mealPlanManuallyAssignedBy: admin.name,
                }
              : { mealPlanId: selectedPlan }
          );
      })
    );
    var usersClone = _.clone(users);
    usersClone
      .filter((d) => selectedRecords.includes(d.key))
      .map((d) => {
        d.mealPlanId = selectedPlan;
      });
    setUsers(usersClone);
    //setSelectedRecords([]);
    //setIsSelectAllSelected(false);

    reWritePlanBars();
    autoAssignmmentAlgorithm(
      "meal plan",
      selectedPlan,
      displayMealPlan(selectedPlan)
    );
  }

  async function onSelectDietType(selectedDietType) {
    await Promise.all(
      selectedRecords.map(async (o) => {
        await firestore("users")
          .doc(o)
          .set(
            {
              healthInfo: {
                dietType: selectedDietType,
              },
            },
            { merge: true }
          );
      })
    );
    var usersClone = _.clone(users);
    usersClone
      .filter((d) => selectedRecords.includes(d.key))
      .map((d) => {
        d.dietType = selectedDietType;
      });
    setUsers(usersClone);
    //setSelectedRecords([]);
    //setIsSelectAllSelected(false);

    reWritePlanBars();
    // autoAssignmmentAlgorithm(
    //   "meal plan",
    //   selectedPlan,
    //   displayMealPlan(selectedPlan)
    // );
  }

  async function onSendMessage() {
    var usersClone = _.clone(users);
    var usersCloneSelected = usersClone.filter((d) =>
      selectedRecords.includes(d.key)
    );

    setNewMessage(usersCloneSelected);
  }

  async function onSendMessageSingle() {
    var usersClone = _.clone(users);
    var usersCloneSelected = usersClone.filter((d) => d.key === userInfo.id);

    setNewMessage(usersCloneSelected);
  }

  function reWritePlanBars() {
    var mealPlansUsers = {},
      unassigned = 0;
    var workoutUsers = {},
      unassignedW = 0;

    users.map((userData) => {
      if (!mealPlansUsers[userData.mealPlanId])
        mealPlansUsers[userData.mealPlanId] = 0;
      mealPlansUsers[userData.mealPlanId]++;
      if (!userData.mealPlanId) unassigned++;

      if (!workoutUsers[userData.workoutPlanId])
        workoutUsers[userData.workoutPlanId] = 0;
      workoutUsers[userData.workoutPlanId]++;
      if (!userData.workoutPlanId) unassignedW++;
    });

    mealPlansUsers["unassigned"] = unassigned;
    setMealPlansUsers(mealPlansUsers);

    workoutUsers["unassigned"] = unassignedW;
    setWorkoutUsers(workoutUsers);
  }

  async function addUser() {
    var prmpt = parseInt(
      prompt(
        "find user by" +
          "\n\n" +
          "1. name" +
          "\n" +
          "2. email" +
          "\n" +
          "3. id",
        "1"
      )
    );
    var findBy =
      prmpt === 1 ? "name" : prmpt === 2 ? "email" : prmpt === 3 ? "id" : "";
    var userName = prompt("Please enter " + findBy);

    if (!userName) return;

    const userDocs = await firestore("users")
      .where(findBy, "==", userName)
      .get();

    var userIndex = 0;
    if (userDocs.size > 1) {
      var msg =
        "(" +
        userDocs.size +
        ") users exist with the same " +
        findBy +
        ": " +
        userName +
        "\n\n";

      msg += userDocs.docs
        .map(
          (doc, i) =>
            String(i + 1) +
            ". " +
            " phone no. " +
            doc.data().mobileNumber +
            " email: " +
            doc.data().email
        )
        .join("\n");

      userIndex = parseInt(prompt(msg, "1")) - 1;
    }

    if (!userDocs.size) return alert("no user with that " + findBy);

    var userId = userDocs.docs[userIndex].id;
    var challengeId = selectedChallenge;

    if (!userId) return alert("no user");
    if (!challengeId) return alert("challenge doesn't exist");

    var months;
    var rounds;
    var amount;

    if (oneToOne) {
      var msg = "please enter no. of months" + "\n\n";
      const values = [1, 2, 3];

      msg += values
        .map(
          (doc, i) =>
            String(i + 1) +
            " " +
            " month" +
            (i >= 1 ? "s" : "") +
            " (" +
            (i + 1) * 4 +
            " weeks)"
        )
        .join("\n");

      months = parseInt(prompt(msg, "1"));

      if (!values.includes(months))
        return alert(
          "entered value is not available, \nplease choose one of the values : " +
            values.join(", ")
        );

      amount = parseInt(prompt("please enter amount in (EGP)", "0"));

      if (isNaN(amount)) return alert("no amount");
    } else {
      var msg = "please enter no. of rounds" + "\n\n";
      const values = [1, 2, 3, 6, 12];

      msg += values
        .map(
          (doc, i) =>
            doc /*String(i + 1)*/ + " " + " round" + (i >= 1 ? "s" : "")
        )
        .join("\n");

      rounds = parseInt(prompt(msg, "1"));

      if (!values.includes(rounds))
        return alert(
          "entered value is not available, \nplease choose one of the values : " +
            values.join(", ")
        );

      amount = parseInt(prompt("please enter amount in (EGP)", "0"));

      if (isNaN(amount)) return alert("no amount");
    }
    var userData = "";
    firestore("users")
      .doc(userId)
      //.where("challengeId", "==", challengeId)
      //.where("userId", "==", userId)
      .get()
      .then(function (doc) {
        if (
          doc.data().joinedChallengeIds &&
          doc.data().joinedChallengeIds.includes(challengeId)
        )
          return alert("already In");
        //if(doc.data().joinedOneToOneIds && doc.data().joinedOneToOneIds.includes(challengeId)) return alert('already In');
        userData = doc.data();
        doc.ref
          .update(
            !oneToOne
              ? {
                  challengeId: challengeId,
                  joinedChallengeIds:
                    firebase.firestore.FieldValue.arrayUnion(challengeId),
                }
              : {
                  challengeId: challengeId,
                  joinedOneToOneIds:
                    firebase.firestore.FieldValue.arrayUnion(challengeId),
                  [`joinedOneToOne.${challengeId}.from`]:
                    firebase.firestore.Timestamp.fromDate(moment().toDate()),
                  [`joinedOneToOne.${challengeId}.to`]:
                    firebase.firestore.Timestamp.fromDate(
                      moment()
                        .add(+months * 4, "weeks")
                        .add(2, "days")
                        .toDate()
                    ),
                }
          )
          .then(() => {
            doc.ref
              .collection("selectedChallenges")
              .doc(challengeId)
              .set({ joinDate: new Date().toISOString() })
              .then(function (doc) {
                alert(
                  userName +
                    " joined " +
                    (oneToOne ? challenge.nutritionistName : challenge.name)
                );

                return;
              })
              .catch(function (error) {
                console.error("Error finding/updating user document: ", error);
              });
          });

        var payment = {
          provider: "manual",
          datetime: new Date().toISOString(),
          userId: userId,
          challengeId: challengeId,
          amount: amount || 0, //request.body.data.object.amount / 100,
          currency: "EGP", //request.body.data.object.currency.toUpperCase(),
        };

        if (rounds) {
          payment.rounds = +rounds;
          payment.roundsJoined = [challengeId];
        }

        if (months) {
          payment.months = +months;
        }
        if (amount) {
          const trackProperties = {
            amount: amount,
            challengeName: oneToOne
              ? challenge.nutritionistName
              : challenge.name,
            challengeId: challengeId,
            nutritionistId: challenge.nutritionistId,
            nutritionistName: challenge.nutritionistName,
            userId: userId,
            userName: userData.name,
            programType: oneToOne ? "one_to_one" : "challenge",
            rounds: rounds,
            provider: "manual",
            currency: "egp",
          };
          rudderAnalytics.track("User_Purchase", trackProperties);
        }
        firestore("payments").add(payment);

        return;
      })
      .catch(function (error) {
        console.error("Error finding user: ", error);
      });
  }

  async function transferUser() {
    SetShowInfo(false);

    //setChallengesLoading(true);
    return firestore("challenges") //.where('nutritionistId', '==', userDetails.id)
      .get()
      .then(
        (querySnapshot) => {
          let data = querySnapshot.docs.map((documentSnapshot, i) => {
            return {
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            };
          });

          data = data.filter((c) => {
            var startDate = moment(c.date);
            var endDate = moment(startDate).add(
              c.duration,
              c.durationType.toLowerCase()
            );

            //challenge is in progress or upcoming
            var isLive = endDate.isAfter();
            return c.isPublished && isLive;
          });

          //data = _.orderBy(data, 'price', 'desc')
          data = _.orderBy(
            data,
            (item) => moment.utc(item.date).format(),
            "desc"
          );

          var grouped = [],
            ignore = {},
            firstRound = {};
          data.map((d) => {
            if (d.previousRounds) {
              d.rounds = d.previousRounds.length + 1;
              d.previousRounds.map((id, i) => {
                ignore[id] = true;

                if (i === d.previousRounds.length - 1) firstRound[id] = true;
              });
              d.roundsIds = [d.key].concat(d.previousRounds);
            }

            if (firstRound[d.key]) {
              d.rounds = 1;
              d.roundsIds = [d.key];
            }
          });

          setAllIgnore(ignore);
          setAllChallenges(data);
          //setChallengesLoading(false);

          setShowTransfer(true);
        },
        (error) => {
          console.log("error", error);
        }
      );

    SetShowInfo(false);
  }

  async function transferUserTo(toChallenge) {
    var userId = userInfo.id;
    var challengeId = selectedChallenge;

    if (!userId) return alert("no user");
    if (!challengeId) return alert("challenge doesn't exist");

    if (
      await confirm(
        "transfer user",
        "transfer " +
          userInfo.name +
          " from " +
          challenge.name +
          " to " +
          toChallenge.name,
        "transfer"
      )
    ) {
      firestore("users")
        .doc(userId)
        //.where("challengeId", "==", challengeId)
        //.where("userId", "==", userId)
        .get()
        .then(function (doc) {
          if (
            !doc.data().joinedChallengeIds ||
            !doc.data().joinedChallengeIds.includes(challengeId)
          )
            return alert("user is not in the challenge");

          doc.ref
            .update({
              challengeId: firebase.firestore.FieldValue.delete(),
              joinedChallengeIds:
                firebase.firestore.FieldValue.arrayRemove(challengeId),
            })
            .then(() => {
              doc.ref
                .collection("selectedChallenges")
                .doc(challengeId)
                .delete()
                .then(function () {
                  setUsers((users) =>
                    _.clone(users).filter((d) => d.id !== userId)
                  );
                  //alert(userInfo.name+' kicked out of challenge '+challenge.name)

                  //if(doc.data().joinedChallengeIds && doc.data().joinedChallengeIds.includes(toChallenge.key)) return alert('already In');

                  doc.ref
                    .update({
                      challengeId: toChallenge.key,
                      joinedChallengeIds:
                        firebase.firestore.FieldValue.arrayUnion(
                          toChallenge.key
                        ),
                    })
                    .then(() => {
                      doc.ref
                        .collection("selectedChallenges")
                        .doc(toChallenge.key)
                        .set({ joinDate: new Date().toISOString() })
                        .then(function (doc) {
                          firestore("payments")
                            .where("challengeId", "==", challengeId)
                            .where("userId", "==", userId)
                            .get()
                            .then(function (querySnapshot) {
                              let payments = querySnapshot.docs.map(
                                (documentSnapshot, i) => {
                                  return {
                                    ...documentSnapshot.data(),
                                    key: documentSnapshot.id,
                                  };
                                }
                              );

                              payments = payments.filter((d) => !d.declined);

                              if (payments.length) {
                                var payment = payments[0];

                                firestore("payments")
                                  .doc(payment.key)
                                  .update({
                                    challengeId: toChallenge.key,
                                    transferred: true,
                                    challengeIdOrg: challengeId,
                                  })
                                  .then(function (doc) {
                                    // console.log(
                                    // 	"payment has been transferred",
                                    // 	payment.key
                                    // );

                                    return;
                                  })
                                  .catch(function (error) {
                                    console.error(
                                      "Error finding/updating payment document: ",
                                      error
                                    );
                                  });
                              }

                              return;
                            })
                            .catch(function (error) {
                              console.error("Error finding payment: ", error);
                            });

                          alert(userInfo.name + " joined " + toChallenge.name);

                          return;
                        })
                        .catch(function (error) {
                          console.error(
                            "Error finding/updating user document: ",
                            error
                          );
                        });
                    });

                  return;
                })
                .catch(function (error) {
                  console.error(
                    "Error finding/updating user document: ",
                    error
                  );
                });
            });

          return;
        })
        .catch(function (error) {
          console.error("Error finding user: ", error);
        });
    }
  }

  async function removeUser() {
    var userId = userInfo.id;
    var challengeId = selectedChallenge;

    if (!userId) return alert("no user");
    if (!challengeId) return alert("challenge doesn't exist");

    SetShowInfo(false);

    if (
      await confirm(
        "remove user",
        "kickout " +
          userInfo.name +
          " of " +
          (oneToOne
            ? challenge.nutritionistName + " one to one program"
            : challenge.name),
        "kickout"
      )
    ) {
      firestore("users")
        .doc(userId)
        //.where("challengeId", "==", challengeId)
        //.where("userId", "==", userId)
        .get()
        .then(function (doc) {
          if (
            (!doc.data().joinedChallengeIds ||
              !doc.data().joinedChallengeIds.includes(challengeId)) &&
            (!doc.data().joinedOneToOneIds ||
              !doc.data().joinedOneToOneIds.includes(challengeId))
          )
            return alert("user is not in the challenge");

          doc.ref
            .update(
              !oneToOne
                ? {
                    challengeId: firebase.firestore.FieldValue.delete(),
                    joinedChallengeIds:
                      firebase.firestore.FieldValue.arrayRemove(challengeId),
                  }
                : {
                    challengeId: firebase.firestore.FieldValue.delete(),
                    joinedOneToOneIds:
                      firebase.firestore.FieldValue.arrayRemove(challengeId),
                    [`joinedOneToOne.${challengeId}`]:
                      firebase.firestore.FieldValue.delete(),
                  }
            )
            .then(() => {
              doc.ref
                .collection("selectedChallenges")
                .doc(challengeId)
                .delete()
                .then(function (doc) {
                  setUsers((users) =>
                    _.clone(users).filter((d) => d.id !== userId)
                  );
                  //alert(userInfo.name+' kicked out of challenge '+challenge.name)

                  return;
                })
                .catch(function (error) {
                  console.error(
                    "Error finding/updating user document: ",
                    error
                  );
                });
            });

          //refund for cash payments
          firestore("payments")
            .where("challengeId", "==", challengeId)
            .where("userId", "==", userId)
            .get()
            .then(function (docs) {
              var payments = [];

              docs.forEach(function (doc) {
                if (!doc.data().declined && !doc.data().refunded)
                  payments.push({ ...doc.data(), key: doc.id });
              });

              if (!payments.length)
                return alert(
                  "no payments for this user. payment amount will not be removed from billing so contact tech team"
                );
              if (payments.length > 1)
                return alert(
                  "there are several payments for this user. payment amount will not be removed from billing so contact tech team"
                );

              payments.map(function (payment) {
                firestore("payments")
                  .doc(payment.key)
                  .update({ refunded: true });

                if (payment.provider === "manual")
                  alert(
                    'it is a manual payment, payment has been flagged as "refunded" at billing, its amount will not be counted'
                  );
                if (payment.provider === "vodafone")
                  alert(
                    'it is a vodafone payment, payment has been flagged as "refunded" at billing, its amount will not be counted'
                  );
                if (payment.provider === "apple")
                  alert(
                    "it is an apple payment, please make sure user has requested refund from apple portal"
                  );
                if (payment.provider === "stripe")
                  prompt(
                    "it is a stripe payment, please make sure user has been refunded at stripe portal",
                    "https://dashboard.stripe.com/search?query=" +
                      payment.userId +
                      "%20" +
                      payment.challengeId
                  );
                if (payment.provider === "accept")
                  prompt(
                    "it is an accept payment, please make sure user has been refunded at accept portal",
                    "https://accept.paymob.com/portal2/en/transactions  & filter by order Id: " +
                      payment.orderId
                  );
              });

              return;
            })
            .catch(function (error) {
              console.error("Error finding payment: ", error);
            });

          return;
        })
        .catch(function (error) {
          console.error("Error finding user: ", error);
        });
    }
  }

  async function addRule() {
    setShowAddRule(true);

    //if(assign === 'group') autoAssignmmentAlgorithm('group', groupId, displayGroup(groupId))
    //if(assign === 'meal plan') autoAssignmmentAlgorithm('meal plan', selectedPlan, displayMealPlan(selectedPlan))
    //if(assign === 'workout plan') autoAssignmmentAlgorithm('workout plan', selectedPlan, displayWorkOutPlan(selectedPlan))
  }

  async function addRecipeType(recipe, tag) {
    const formValueClone = _.clone(formValue);
    if (!formValueClone.types) formValueClone.types = [];
    formValueClone.types.push(tag);
    setFormValue(formValueClone);
  }

  async function removeRecipeType(recipe, tag) {
    const formValueClone = _.clone(formValue);
    if (!formValueClone.types) formValueClone.types = [];
    formValueClone.types = formValueClone.types.filter((item) => item !== tag);
    setFormValue(formValueClone);
  }

  async function addRecipeTag(recipe, tag) {
    if (recipe.tags && recipe.tags.includes(tag))
      return alert("Recipe already has that recipe tag");

    const formValueClone = _.clone(formValue);
    if (!formValueClone.tags) formValueClone.tags = [];
    formValueClone.tags.push(tag);
    setFormValue(formValueClone);
  }

  async function removeRecipeTag(recipe, tag) {
    const formValueClone = _.clone(formValue);
    if (!formValueClone.tags) formValueClone.tags = [];
    formValueClone.tags = formValueClone.tags.filter((item) => item !== tag);
    setFormValue(formValueClone);
  }

  async function addTag() {
    if (tags[tag]) return alert("Tags already has that tag");

    var tag = prompt("Please enter new tag");

    if (!tag) return;

    const clone = _.clone(tags);
    clone[tag] = getRandomColor();
    setTags(clone);

    await firestore("constants")
      .doc("recipeTags")
      .update({ value: firebase.firestore.FieldValue.arrayUnion(tag) });
  }

  async function removeTag() {
    var tag = prompt("Please enter tag you want to remove");

    if (!tag) return;

    if (!tags[tag]) return alert("Tags do not have that tag");

    const formValueClone = _.clone(formValue);
    if (!formValueClone.tags) formValueClone.tags = [];
    formValueClone.tags = formValueClone.tags.filter((item) => item !== tag);
    setFormValue(formValueClone);

    const clone = _.clone(tags);
    delete clone[tag];
    setTags(clone);

    await firestore("constants")
      .doc("recipeTags")
      .update({ value: firebase.firestore.FieldValue.arrayRemove(tag) });
  }

  async function addRecipeTagEx(recipe, tag) {
    if (recipe.tagsEx && recipe.tagsEx.includes(tag))
      return alert("Exercise already has that recipe tag");

    const formValueClone = _.clone(formValue);
    if (!formValueClone.tagsEx) formValueClone.tagsEx = [];
    formValueClone.tagsEx.push(tag);
    setFormValue(formValueClone);
  }

  async function removeRecipeTagEx(recipe, tag) {
    const formValueClone = _.clone(formValue);
    if (!formValueClone.tagsEx) formValueClone.tagsEx = [];
    formValueClone.tagsEx = formValueClone.tagsEx.filter(
      (item) => item !== tag
    );
    setFormValue(formValueClone);
  }

  async function addTagEx() {
    if (tagsEx[tag]) return alert("Tags already has that tag");

    var tag = prompt("Please enter new tag");

    if (!tag) return;

    const clone = _.clone(tagsEx);
    clone[tag] = getRandomColor();
    setTagsEx(clone);

    await firestore("constants")
      .doc("exerciseTags")
      .update({ value: firebase.firestore.FieldValue.arrayUnion(tag) });
  }

  async function removeTagEx() {
    var tag = prompt("Please enter tag you want to remove");

    if (!tag) return;

    if (!tagsEx[tag]) return alert("Tags do not have that tag");

    const formValueClone = _.clone(formValue);
    if (!formValueClone.tagsEx) formValueClone.tagsEx = [];
    formValueClone.tagsEx = formValueClone.tagsEx.filter(
      (item) => item !== tag
    );
    setFormValue(formValueClone);

    const clone = _.clone(tagsEx);
    delete clone[tag];
    setTagsEx(clone);

    await firestore("constants")
      .doc("exerciseTags")
      .update({ value: firebase.firestore.FieldValue.arrayRemove(tag) });
  }

  return (
    <>
      <Header header="Users" />
      <hr />
      <div className="user-page">
        {false /*challengesLoading*/ ? (
          <div>
            <Spinner animation="border" size={"lg"} role="status" />
          </div>
        ) : challenges.length || oneToOneEnabled ? (
          <>
            <Row>
              {challenges.length ? (
                <Col md={12} xl={6}>
                  <h4>Challenge</h4>
                  <Form.Control
                    className="select-challenge"
                    required
                    as="select"
                    placeholder="Select Challenge"
                    style={{
                      cursor: "pointer",
                      width: "auto",
                      display: "inline-block",
                    }}
                    value={
                      ignore[selectedChallenge]
                        ? challenges.filter(
                            (c) =>
                              c.previousRounds &&
                              c.previousRounds.includes(selectedChallenge)
                          )[0].key
                        : selectedChallenge
                    }
                    onChange={(e) => {
                      challenges.map((c) => {
                        if (c.key === e.target.value) {
                          setRounds(c.roundsIds);
                          setChallenge(c);
                        }
                      });
                      setOneToOne(false);
                      setSelectedChallenge(e.target.value);
                      localStorage.setItem("challengeId", e.target.value);
                    }}
                  >
                    {challenges
                      .filter((c) => !ignore[c.key])
                      .map((obj, i) => {
                        return (
                          <option
                            value={obj.key}
                            key={obj.key}
                            //hidden={ignore[obj.key]}
                          >
                            {obj.name.replace(/Round(.*)- /, "")}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Col>
              ) : null}

              {rounds && (
                <>
                  <Col xl={4} md={8}>
                    <h4>Round</h4>
                    <Form.Control
                      required
                      as="select"
                      className="select-round"
                      placeholder="Select Round"
                      style={{
                        cursor: "pointer",
                        width: "auto",
                        display: "inline-block",
                      }}
                      value={selectedChallenge}
                      onChange={(e) => {
                        challenges.map((c) => {
                          if (c.key === e.target.value) setChallenge(c);
                        });
                        setOneToOne(false);
                        setSelectedChallenge(e.target.value);
                        localStorage.setItem("challengeId", e.target.value);
                      }}
                    >
                      {challenges
                        .filter((d) => rounds.includes(d.key))
                        .map((obj, i) => {
                          return (
                            <option value={obj.key} key={obj.key}>
                              {obj.previousRounds
                                ? "round " + (obj.previousRounds.length + 1)
                                : "round 1"}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Col>
                </>
              )}

              {oneToOneEnabled === true && (
                <Col
                  xl={2}
                  md={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                  }}
                >
                  <Button
                    className="button button-disabled"
                    variant={oneToOne ? "dark" : "light"}
                    style={{ padding: "0.7em 0.5em" }}
                    onClick={() => {
                      if (oneToOne) {
                        if (challenges.length) {
                          setUsers([]);
                          setOneToOne(false);
                          setChallenge(challenges[0]);
                          setSelectedChallenge(challenges[0].key);
                          localStorage.setItem(
                            "challengeId",
                            challenges[0].key
                          );
                        }

                        return;
                      }

                      setUsers([]);
                      setOneToOne(true);
                      setSelectedChallenge(user.id);
                      localStorage.setItem("challengeId", user.id);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faStreetView}
                      size="1x"
                      className="mr-2"
                    />
                    {"One to One"}
                  </Button>
                </Col>
              )}
            </Row>
          </>
        ) : (
          <div></div>
        )}
      </div>

      <div className="pt-2 user-page-section">
        {challengesLoading ? (
          <div className="text-center h-100 w-100">
            <Spinner animation="border" size={"lg"} role="status" />
          </div>
        ) : challenges.length || oneToOneEnabled ? (
          <div>
            {!oneToOne && (
              <div id="group" className="my-4">
                <Card className="groups-section">
                  <h4>Groups ({groups.length})</h4>
                  <Row style={{ marginRight: "0" }}>
                    {groupsLoader ? (
                      <div className="text-center w-100">
                        <Spinner animation="border" size={"sm"} role="status" />
                      </div>
                    ) : groups.length ? (
                      (activeGroups ? groupsByPoints : groups).map((group) => {
                        return (
                          <>
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              xl={3}
                              className="my-3"
                              key={group.key}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                editGroup(group);
                              }}
                            >
                              <div>
                                <div id="group-section">
                                  <div className="group-card">
                                    <h5 className="group-name">{group.name}</h5>
                                    <div className="group-members">
                                      {
                                        /*group.userCount*/ users.filter(
                                          (d) => d.groupId === group.key
                                        ).length
                                      }{" "}
                                      members
                                    </div>
                                    <div className="group-highest">
                                      highest group:{" "}
                                      {
                                        /*maxUsersPerGroup*/ Math.max(
                                          ...groups.map(
                                            (group) =>
                                              users.filter(
                                                (d) => d.groupId === group.key
                                              ).length
                                          )
                                        )
                                      }
                                    </div>
                                    <div className="group-points">
                                      <img src={Star} alt="star" width="20" />
                                      {group.points}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </>
                        );
                      })
                    ) : (
                      ""
                    )}
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={3}
                      className="my-3"
                      style={{ cursor: "pointer" }}
                    >
                      {selectedChallenge ? (
                        <div className="add-group" onClick={() => addGroup()}>
                          <p style={{ margin: "0" }}>
                            <FontAwesomeIcon icon={faPlus} /> <br /> Add
                            <br /> Group
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Card>
              </div>
            )}
            {oneToOne && (
              <Row style={{ marginTop: "1.5rem" }}>
                <Col md={12} xl={6}>
                  <Card className="doughnut-card">
                    <h4 className="m-0 d-flex justify-content-between">
                      Meal plans (
                      {
                        mealPlans.filter(
                          (mealplan) => mealPlansUsers[mealplan.key]
                        ).length
                      }
                      )
                    </h4>
                    {usersLoader ? (
                      <div className="text-center">
                        <Spinner animation="border" size={"sm"} role="status" />
                      </div>
                    ) : users.length !== 0 ? (
                      mealPlans.length ? (
                        <div style={{ width: "100%", margin: "0 auto" }}>
                          <MealPlansChart
                            mealPlans={mealPlans}
                            users={users}
                            mealPlansUsers={mealPlansUsers}
                          />
                        </div>
                      ) : (
                        <div className="text-center users-page-error-message">
                          No Meal plans found
                        </div>
                      )
                    ) : (
                      <div className="users-page-error-message">
                        No users Assigned to Meal plans
                      </div>
                    )}
                  </Card>
                </Col>
                <Col md={12} xl={6}>
                  <Card className=" doughnut-card">
                    <h4 className="m-0 d-flex justify-content-between">
                      Workout plans (
                      {
                        workoutPlans.filter(
                          (workout) => workoutUsers[workout.key]
                        ).length
                      }
                      )
                    </h4>

                    {usersLoader ? (
                      <div className="text-center w-100">
                        <Spinner animation="border" size={"sm"} role="status" />
                      </div>
                    ) : users.length !== 0 ? (
                      workoutPlans.length ? (
                        <div
                          style={{ width: "100%", margin: "0 auto" }}
                          //className="text-center w-100 workouts-bar"
                          //id="workouts-bar"
                        >
                          <WorkoutPlansChart
                            workoutPlans={workoutPlans}
                            users={users}
                            workoutUsers={workoutUsers}
                          />
                        </div>
                      ) : (
                        <div className="text-center  users-page-error-message">
                          No Workout plans found
                        </div>
                      )
                    ) : (
                      <div className="users-page-error-message">
                        No users assigned to Workout plans{" "}
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            )}
            <Accordion defaultActiveKey="0">
              <Card className="my-4 assignment-card">
                <h4
                  className="m-0 d-flex justify-content-between"
                  style={{ marginLeft: "25px" }}
                >
                  Assignment rules ({autoAssignmments.length})
                  <Accordion.Toggle
                    as={Button}
                    eventKey="0"
                    size="sm"
                    variant="info"
                    style={{
                      marginLeft: "25px",
                      width: "80px",
                      backgroundColor: "grey",
                      alignSelf: 'center',
                    }}
                    onClick={() => {
                      setCollapse((prev) => {
                        return !prev;
                      });
                    }}
                  >
                    {!collapse ? "Collapse" : "Spread"}
                  </Accordion.Toggle>
                  <ButtonToolbar className="ml-auto">
                    <ButtonGroup className="mr-2" aria-label="First group">
                      <Button
                        // variant="info"

                        className="button button-secondary"
                        onClick={() => {
                          setSettingRule(true);
                          setShowFilter(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faListAlt}
                          size="1x"
                          className="mr-2"
                        />
                        Add Rule
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </h4>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="mt-2 mr-3">
                    <Row>
                      <div className="text-center w-100 assignment-rules">
                        {assignmentsLoader ? (
                          <div className="text-center w-100">
                            <Spinner
                              animation="border"
                              size={"sm"}
                              role="status"
                            />
                          </div>
                        ) : autoAssignmments.length ? (
                          autoAssignmments.map((rule) => {
                            return (
                              <>
                                <div key={rule.key} className="rule">
                                  {/*<span>if user</span>*/}
                                  <span>
                                    {Object.keys(rule.conditions).map((key) => (
                                      <>
                                        <div id="rule-key">{key}</div>

                                        <div className="rule-condition">
                                          {rule.conditions[key]}
                                        </div>
                                      </>
                                    ))}
                                    <img
                                      style={{
                                        width: "25px",
                                        marginRight: "10px",
                                      }}
                                      src={Arrow}
                                      alt="arrow"
                                    />
                                    Assign <br />
                                    {rule.assignment}
                                    {rule.assignment === "group" && (
                                      <span className="target">
                                        {displayGroup(rule.target)}
                                      </span>
                                    )}
                                    {rule.assignment === "meal plan" && (
                                      <span className="target">
                                        {displayMealPlan(rule.target)}
                                      </span>
                                    )}
                                    {rule.assignment === "workout plan" && (
                                      <span className="target">
                                        {displayWorkOutPlan(rule.target)}
                                      </span>
                                    )}
                                  </span>
                                  <Button
                                    className="button circle"
                                    onClick={() => {
                                      removeAssignmentRule(rule);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                </div>
                                <hr
                                  style={{
                                    borderColor: "#f0f0f0",
                                    margin: "15px",
                                  }}
                                />
                              </>
                            );
                          })
                        ) : (
                          <div className="text-center users-page-error-message">
                            No Auto assignment rules
                          </div>
                        )}
                      </div>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <h4 style={{ marginLeft: "25px" }}>
              Users ({filterUsers(users).length})
              {unassignedMsg && (
                <div
                  style={{
                    display: "inline-block",
                    fontSize: ".6em",
                    fontWeight: "500",
                    color: "red",
                    marginLeft: "3.5em",
                  }}
                >
                  {unassignedMsg}
                </div>
              )}
            </h4>

            <Card className="my-4">
              <h4 className="m-0 mb-3 d-flex justify-content-between align-items-center filter-users">
                <img
                  src={Filter}
                  alt="filter"
                  width="15"
                  style={{ margin: "0.5em" }}
                />{" "}
                <span>Filter by</span>
                {filterData &&
                  Object.keys(filterData).map((filterKey) => {
                    return filterData[filterKey] ? (
                      <Button
                        key={filterKey}
                        onClick={() => {
                          removeFilter(filterKey);
                        }}
                        className="rounded-pill ml-3"
                        variant="outline-secondary"
                        size="sm"
                      >
                        {displayFilter(filterKey)}
                      </Button>
                    ) : (
                      ""
                    );
                  })}
                <ButtonToolbar className="ml-auto">
                  {selectedRecords.length > 0 ? (
                    <div>
                      {!oneToOne && (
                        <ButtonGroup className="mr-2" aria-label="First group">
                          <Button
                            className="mr-2"
                            id="dropdown-basic-button"
                            variant="secondary"
                            title="Send message"
                            onClick={(eventKey) => {
                              onSendMessage(eventKey);
                            }}
                          >
                            Send message
                          </Button>
                        </ButtonGroup>
                      )}
                      {!oneToOne && (
                        <ButtonGroup className="mr-2" aria-label="First group">
                          <DropdownButton
                            className="mr-2"
                            id="dropdown-basic-button"
                            title="Select Group"
                            style={{ padding: "0" }}
                            onSelect={(eventKey) => {
                              onSelectGroup(eventKey);
                            }}
                          >
                            {groups &&
                              groups.map((group) => {
                                return (
                                  <Dropdown.Item
                                    key={group.key}
                                    eventKey={group.key}
                                    style={{ fontSize: ".85em" }}
                                  >
                                    {group.name}
                                  </Dropdown.Item>
                                );
                              })}
                          </DropdownButton>
                        </ButtonGroup>
                      )}
                      <ButtonGroup className="mr-2" aria-label="First group">
                        <DropdownButton
                          className="mr-2"
                          id="dropdown-basic-button"
                          title="Select Meal plan"
                          onSelect={(eventKey) => {
                            onSelectMealPlan(eventKey);
                          }}
                        >
                          {mealPlans &&
                            mealPlans.map((mealPlan) => {
                              return (
                                <Dropdown.Item
                                  key={mealPlan.key}
                                  eventKey={mealPlan.key}
                                  style={{ fontSize: ".85em" }}
                                >
                                  {mealPlan.name}
                                </Dropdown.Item>
                              );
                            })}
                        </DropdownButton>
                      </ButtonGroup>
                      <ButtonGroup className="mr-2" aria-label="First group">
                        <DropdownButton
                          className="mr-2"
                          id="dropdown-basic-button"
                          title="Select Diet"
                          onSelect={(eventKey) => {
                            onSelectDietType(eventKey);
                          }}
                        >
                          {challenge &&
                            Object.entries(challenge.dietTypes).map(
                              ([key, value]) => {
                                return (
                                  value && (
                                    <Dropdown.Item
                                      key={key}
                                      eventKey={key}
                                      style={{ fontSize: ".85em" }}
                                    >
                                      {key}
                                    </Dropdown.Item>
                                  )
                                );
                              }
                            )}
                        </DropdownButton>
                      </ButtonGroup>
                      <ButtonGroup className="mr-2" aria-label="First group">
                        <DropdownButton
                          className="mr-2"
                          id="dropdown-basic-button"
                          title="Select Workout plan"
                          onSelect={(eventKey) => {
                            onSelectWorkoutPlan(eventKey);
                          }}
                        >
                          {workoutPlans &&
                            workoutPlans.map((workoutPlan) => {
                              return (
                                <Dropdown.Item
                                  key={workoutPlan.key}
                                  eventKey={workoutPlan.key}
                                  style={{ fontSize: ".85em" }}
                                >
                                  {workoutPlan.name}
                                </Dropdown.Item>
                              );
                            })}
                        </DropdownButton>
                      </ButtonGroup>
                    </div>
                  ) : (
                    ""
                  )}
                  <ButtonGroup className="mr-2" aria-label="First group">
                    <Button
                      style={{
                        padding: "0 1em",
                        borderRadius: "0.5rem",
                        border: "1px solid #c98e8e",
                        color: inactive ? "white" : "#c98e8e",
                        background: inactive ? "firebrick" : "white",
                      }}
                      className="ml-2 button-filter"
                      onClick={() => setInactive(!inactive)}
                    >
                      {/*<FontAwesomeIcon icon={faUserPlus} size="1x" /> */}{" "}
                      {/*Inactive*/} Idle
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup className="mr-2" aria-label="First group">
                    <Button
                      variant="primary"
                      className="ml-2 button-filter"
                      onClick={() => setShowFilter(true)}
                      style={{ borderRadius: "0.5rem" }}
                    >
                      <FontAwesomeIcon
                        icon={faFilter}
                        size="1x"
                        className="mr-2"
                      />
                      Filter
                    </Button>
                  </ButtonGroup>
                  {admin.role && admin.role.includes("admin") && (
                    <ButtonGroup className="mr-2" aria-label="First group">
                      <Button
                        style={{
                          padding: "0 1em",
                          border: "none",
                          borderRadius: "0.5rem",
                        }}
                        className="ml-2 button-primary"
                        onClick={() => addUser()}
                      >
                        <FontAwesomeIcon icon={faUserPlus} size="1x" /> Add
                      </Button>
                    </ButtonGroup>
                  )}
                </ButtonToolbar>
              </h4>

              <Card.Body className="p-0">
                <div style={{ overflow: "auto" }}>
                  <Table
                    className="m-0 users-table"
                    responsive="xl"
                    style={{ fontSize: "12px" }}
                  >
                    <thead>
                      <tr>
                        <th>
                          <Form.Check
                            size="sm"
                            checked={isSelectAllSelected}
                            onChange={(e) => selectAllCheckbox(e)}
                          />
                        </th>
                        {tableHeaders &&
                          tableHeaders.map((o, i) => {
                            if (oneToOne && o.filterField === "group")
                              return "";
                            return (
                              <th
                                style={{ cursor: "pointer" }}
                                key={i}
                                onClick={() => sortData(o.filterField)}
                                className="text-capitalize"
                              >
                                {o.name}{" "}
                                {sort && sort.field === o.filterField ? (
                                  <span style={{ position: "absolute" }}>
                                    <FontAwesomeIcon
                                      icon={
                                        sort.direction === "asc"
                                          ? faArrowUp
                                          : faArrowDown
                                      }
                                      className="ml-1"
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {usersLoader ? (
                        <tr>
                          <td
                            className="text-center"
                            colSpan={tableHeaders.length + 1}
                          >
                            <Spinner
                              animation="border"
                              size={"sm"}
                              role="status"
                            />
                          </td>
                        </tr>
                      ) : users.length ? (
                        filterUsers(users)
                          .filter((user) => (inactive ? user.inactive : true))
                          .map((user, i) => (
                            <tr key={user.key}>
                              <td>
                                <Form.Group className="m-0" controlId={i + 1}>
                                  <Form.Check
                                    type="checkbox"
                                    size="sm"
                                    checked={checkIsSelected(user.key)}
                                    onChange={(e) =>
                                      onSelect(user.key, e.target.checked)
                                    }
                                  />
                                </Form.Group>
                              </td>
                              <td
                                className="text-capitalize"
                                onClick={() => {
                                  SetUserInfo(user);
                                  SetShowInfo(true);
                                }}
                                style={
                                  user.inactive
                                    ? { cursor: "pointer", color: "red" }
                                    : { cursor: "pointer" }
                                }
                              >
                                {user.name || user.email}
                              </td>
                              <td className="text-capitalize">{user.gender}</td>
                              {/*<td>{user.language ? user.language : 'En'}</td>*/}
                              {/* <td>
															{user.healthInfo &&
															user.healthInfo.haveEquipment ? (
																user.healthInfo.haveEquipment[0] == "yes" ? (
																	<FontAwesomeIcon
																		icon={faDumbbell}
																		size="1x"
																		className="mr-2"
																		color="#1689fc"
																	/>
																) : (
																	""
																)
															) : (
																""
															)}
														</td> */}
                              <td>
                                {user.joinDate
                                  ? oneToOne
                                    ? moment
                                        .utc(
                                          user.joinedOneToOne[
                                            selectedChallenge
                                          ].from.toDate()
                                        )
                                        .format("D MMM") +
                                      " to " +
                                      moment
                                        .utc(
                                          user.joinedOneToOne[
                                            selectedChallenge
                                          ].to.toDate()
                                        )
                                        .format("D MMM")
                                    : moment
                                        .utc(user.joinDate)
                                        .fromNow()
                                        .replace("a few seconds ago", "now")
                                  : "-"}
                              </td>
                              <td>{user.bmr ? user.bmr : "-"}</td>
                              <td>{user.bmi ? user.bmi : "-"}</td>
                              <td className="break-spaces">
                                {user.bmiClass ? user.bmiClass : "-"}
                              </td>
                              <td>{user.weight ? user.weight : "-"}</td>
                              <td>{user.height ? user.height : "-"}</td>
                              <td>{user.dietType ? user.dietType : "-"}</td>
                              <td>{user.age ? user.age : "-"}</td>
                              {/*<td>{user.allergies ? user.allergies : '-'}</td>*/}
                              {!oneToOne && (
                                <td
                                  className="break-spaces"
                                  style={!user.groupId ? { color: "red" } : {}}
                                >
                                  {user.groupId
                                    ? displayGroup(user.groupId) || "☹"
                                    : "☹"}
                                </td>
                              )}
                              <td
                                className="break-spaces"
                                style={!user.mealPlanId ? { color: "red" } : {}}
                              >
                                {user.mealPlanId
                                  ? displayMealPlan(user.mealPlanId)
                                  : "☹"}
                              </td>
                              <td
                                className="break-spaces"
                                style={
                                  !user.workoutPlanId ? { color: "red" } : {}
                                }
                              >
                                {user.workoutPlanId
                                  ? displayWorkOutPlan(user.workoutPlanId)
                                  : "☹"}
                              </td>

                              {/* <td>
                                    <FontAwesomeIcon icon={faPencilAlt} size="1x" className="mr-2" onClick={() => {
                                        edituser(user);
                                    }}/>
                                    <FontAwesomeIcon icon={faTrashAlt} size="1x" className="mr-2" onClick={() => {
                                        onDeleteClick(user.key);
                                    }}/>
                                </td>*/}
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td
                            className="text-center"
                            colSpan={tableHeaders.length + 1}
                          >
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>

            {users.length && oneToOne ? (
              <>
                <h4 style={{ marginLeft: "25px" }}>Demographics</h4>
                <Row style={{ height: "300px" }}>
                  <Col xs={8}>
                    {/* {console.log(height)} */}
                    <Card
                      className="my-2"
                      style={{ position: "relative", minHeight: "100%" }}
                    >
                      <Card.Body>
                        <div className="text-center w-100 demographics">
                          {usersLoader ? (
                            <div className="text-center w-100">
                              <Spinner
                                animation="border"
                                size={"sm"}
                                role="status"
                              />
                            </div>
                          ) : (
                            <Row>
                              <Col
                                xs={8}
                                className="countries"
                                style={{
                                  position: "absolute",
                                  left: "25px",
                                  top: "0",
                                }}
                              >
                                <MapChart
                                  data={countries}
                                  total={users.length}
                                />
                              </Col>

                              <Col
                                xs={4}
                                style={{
                                  position: "absolute",
                                  right: "3%",
                                  top: "25px",
                                  overflowY: "scroll",
                                  height: "250px",
                                }}
                              >
                                <div className="countries-header d-flex">
                                  <div>Countries</div>
                                  <div>Users</div>
                                </div>
                                <hr
                                  style={{
                                    margin: "0.3em",
                                    color: "#f0f0f0",
                                  }}
                                />

                                <div className="countries-text">
                                  {_.orderBy(
                                    Object.keys(countries),
                                    (key) => countries[key],
                                    "desc"
                                  ).map((key, i) => (
                                    <div
                                      key={key}
                                      className="country"
                                      // onHover={(e) => {}}
                                      style={{
                                        opacity:
                                          0.6 +
                                          0.4 *
                                            ((Object.keys(countries).length -
                                              i) /
                                              Object.keys(countries).length),
                                      }}
                                    >
                                      <span>{countriesObj[key]}</span>
                                      <span className="span-countries">
                                        <b>{countries[key]}</b>
                                      </span>{" "}
                                    </div>
                                  ))}
                                </div>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={4}>
                    <Card className="my-2" style={{ minHeight: "100%" }}>
                      <div className="text-center w-100 demographics">
                        {usersLoader ? (
                          <div className="text-center w-100">
                            <Spinner
                              animation="border"
                              size={"sm"}
                              role="status"
                            />
                          </div>
                        ) : (
                          <div>
                            <div className="demographics-category">
                              <div className="category">Category</div>
                              <div className="avg-num">Number</div>
                            </div>
                            <hr style={{ margin: "0.3em", color: "#f0f0f0" }} />
                            {Object.keys(demographics).map((key) => (
                              <div key={key} className="property">
                                <span className="key">
                                  {/* {console.log(key === "male")} */}
                                  {key !== "male" && key !== "female"
                                    ? "avg."
                                    : ""}{" "}
                                  {key}
                                </span>
                                <span className="value">
                                  {Number.isInteger(demographics[key])
                                    ? demographics[key]
                                    : demographics[key].toFixed(2)}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : null}

            <Modal
              show={showTransfer}
              onHide={() => setShowTransfer(false)}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Transfer user to challenge</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ padding: "5em 3em", margin: "0 auto" }}>
                <div>
                  <ButtonGroup
                    className="mr-2"
                    aria-label="First group"
                    style={{ display: "block" }}
                  >
                    {allChallenges
                      .filter((c) => !allIgnore[c.key])
                      .map((c) => (
                        <Button
                          style={{
                            width: "100%",
                            marginBottom: ".3em",
                            fontSize: ".9em",
                          }}
                          variant="light"
                          onClick={() => {
                            setShowTransfer(false);
                            transferUserTo(c);
                          }}
                        >
                          {c.nutritionistName} <b>{c.name}</b>{" "}
                          {c.previousRounds
                            ? "round " + (c.previousRounds.length + 1)
                            : "round 1"}
                        </Button>
                      ))}
                  </ButtonGroup>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={showAddRule}
              onHide={() => setShowAddRule(false)}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Select Assignment</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ padding: "5em 3em", margin: "0 auto" }}>
                <div>
                  <ButtonGroup className="mr-2" aria-label="First group">
                    <DropdownButton
                      className="mr-2"
                      id="dropdown-basic-button"
                      variant="info"
                      title="Assign Group"
                      onSelect={(eventKey) => {
                        setShowAddRule(false);
                        onSelectGroup(eventKey);
                      }}
                    >
                      {groups &&
                        groups.map((group) => {
                          return (
                            <Dropdown.Item key={group.key} eventKey={group.key}>
                              {group.name}
                            </Dropdown.Item>
                          );
                        })}
                    </DropdownButton>
                  </ButtonGroup>
                  <ButtonGroup className="mr-2" aria-label="First group">
                    <DropdownButton
                      className="mr-2"
                      id="dropdown-basic-button"
                      variant="info"
                      title="Assign Meal plan"
                      onSelect={(eventKey) => {
                        setShowAddRule(false);
                        onSelectMealPlan(eventKey);
                      }}
                    >
                      {mealPlans &&
                        mealPlans.map((mealPlan) => {
                          return (
                            <Dropdown.Item
                              key={mealPlan.key}
                              eventKey={mealPlan.key}
                            >
                              {mealPlan.name}
                            </Dropdown.Item>
                          );
                        })}
                    </DropdownButton>
                  </ButtonGroup>
                  <ButtonGroup className="mr-2" aria-label="First group">
                    <DropdownButton
                      className="mr-2"
                      id="dropdown-basic-button"
                      variant="info"
                      title="Assign Workout plan"
                      onSelect={(eventKey) => {
                        setShowAddRule(false);
                        onSelectWorkoutPlan(eventKey);
                      }}
                    >
                      {workoutPlans &&
                        workoutPlans.map((workoutPlan) => {
                          return (
                            <Dropdown.Item
                              key={workoutPlan.key}
                              eventKey={workoutPlan.key}
                            >
                              {workoutPlan.name}
                            </Dropdown.Item>
                          );
                        })}
                    </DropdownButton>
                  </ButtonGroup>
                </div>

                <div style={{ marginTop: "120px", display: "none" }}>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Meal plan{" "}
                  </Form.Label>
                  <Form.Label style={{ marginLeft: "10px" }}>
                    recipes tags
                  </Form.Label>

                  <div style={{}}></div>

                  <div
                    style={{
                      display: "inline-block",
                      width: "60%",
                      maxWidth: "initial",
                      whiteSpace: "normal",
                      fontSize: ".8em",
                      marginTop: "30px",
                      verticalAlign: "top",
                    }}
                  >
                    <div style={{ marginTop: "1em" }}>
                      {(formValue.types || []).map((tag) => (
                        <span
                          className="issue-tag-button"
                          style={{
                            background: "#eee",
                            cursor: "pointer",
                            color: "#222",
                            padding: "3px 8px",
                            borderRadius: "5px",
                            marginRight: "5px",
                            marginBottom: "5px",
                            fontSize: "12px",
                            display: "inline-block",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            removeRecipeType(formValue, tag);
                          }}
                        >
                          {tag}
                          <span style={{ marginLeft: ".2em" }}>
                            <FontAwesomeIcon
                              icon={faTimes}
                              size="1x"
                              className=""
                              style={{ fontSize: ".9em" }}
                            />
                          </span>
                        </span>
                      ))}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "inline-block",
                      width: "40%",
                      maxWidth: "initial",
                      whiteSpace: "normal",
                      fontSize: ".8em",
                      marginTop: "30px",
                      verticalAlign: "top",
                      paddingLeft: "20px",
                    }}
                    className={
                      "issue-tag" +
                      (formValue.types && formValue.types.length > 0
                        ? " filled"
                        : " empty")
                    }
                  >
                    <div style={{ marginTop: "1em" }}>
                      {(Object.keys(types) || []).map((tag) => (
                        <span
                          className="issue-tag-button"
                          style={{
                            background: "#f7f7f7",
                            cursor: "pointer",
                            color: "#222",
                            padding: "3px 8px",
                            borderRadius: "5px",
                            marginRight: "5px",
                            marginBottom: "5px",
                            fontSize: "12px",
                            display: "inline-block",
                          }}
                          onClick={(e) => {
                            addRecipeType(formValue, tag);
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "inline-block",
                      width: "60%",
                      maxWidth: "initial",
                      whiteSpace: "normal",
                      fontSize: ".8em",
                      marginTop: "30px",
                      verticalAlign: "top",
                    }}
                    className={
                      "issue-tag" +
                      (formValue.tags && formValue.tags.length > 0
                        ? " filled"
                        : " empty")
                    }
                  >
                    <div style={{ marginTop: "1em" }}>
                      {(formValue.tags || []).map((tag) => (
                        <span
                          className="issue-tag-button"
                          style={{
                            background: tags[tag],
                            cursor: "pointer",
                            color: "#eee",
                            padding: "3px 8px",
                            borderRadius: "5px",
                            marginRight: "5px",
                            marginBottom: "5px",
                            fontSize: "12px",
                            display: "inline-block",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            removeRecipeTag(formValue, tag);
                          }}
                        >
                          {tag}
                          <span style={{ marginLeft: ".2em" }}>
                            <FontAwesomeIcon
                              icon={faTimes}
                              size="1x"
                              className=""
                              style={{ fontSize: ".9em" }}
                            />
                          </span>
                        </span>
                      ))}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "inline-block",
                      width: "40%",
                      maxWidth: "initial",
                      whiteSpace: "normal",
                      fontSize: ".8em",
                      marginTop: "30px",
                      verticalAlign: "top",
                      paddingLeft: "20px",
                    }}
                    className={
                      "issue-tag" +
                      (formValue.tags && formValue.tags.length > 0
                        ? " filled"
                        : " empty")
                    }
                  >
                    <div style={{ marginTop: "1em" }}>
                      {(Object.keys(tags) || []).map((tag) => (
                        <span
                          className="issue-tag-button"
                          style={{
                            background: hexToRGBA(tags[tag], 0.1),
                            cursor: "pointer",
                            color: "#222",
                            padding: "3px 8px",
                            borderRadius: "5px",
                            marginRight: "5px",
                            marginBottom: "5px",
                            fontSize: "12px",
                            display: "inline-block",
                          }}
                          onClick={(e) => {
                            addRecipeTag(formValue, tag);
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                      <span
                        style={{
                          border: "1px solid #ddd",
                          color: "#222",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          marginRight: "5px",
                          marginBottom: "5px",
                          fontSize: "12px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          addTag();
                        }}
                      >
                        + new tag
                      </span>
                      <span
                        style={{
                          border: "1px solid #ddd",
                          color: "#222",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          marginRight: "5px",
                          marginBottom: "5px",
                          fontSize: "12px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          removeTag();
                        }}
                      >
                        - remove tag
                      </span>
                    </div>
                  </div>

                  <Button
                    className="button"
                    variant="light"
                    disabled={
                      !formValue.types ||
                      !formValue.types.length ||
                      !formValue.tags ||
                      !formValue.tags.length
                    }
                    style={{
                      borderRadius: "5px",
                      fontSize: "12px",
                      color: "#222",
                      background: "#eee",
                    }}
                    onClick={() => {
                      autoAssignmmentAlgorithm(
                        "meal plan",
                        null,
                        formValue.tags.join(", "),
                        formValue.tags,
                        formValue.types
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faListAlt}
                      size="1x"
                      className="mr-2"
                    />
                    Submit assignment rule
                  </Button>
                </div>
                <div style={{ marginTop: "120px", display: "none" }}>
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Workout plan
                  </Form.Label>
                  <Form.Label style={{ marginLeft: "10px" }}>
                    exercises tags
                  </Form.Label>

                  <div style={{}}></div>

                  <div
                    style={{
                      display: "inline-block",
                      width: "60%",
                      maxWidth: "initial",
                      whiteSpace: "normal",
                      fontSize: ".8em",
                      marginTop: "30px",
                      verticalAlign: "top",
                    }}
                    className={
                      "issue-tag" +
                      (formValue.tagsEx && formValue.tagsEx.length > 0
                        ? " filled"
                        : " empty")
                    }
                  >
                    <div style={{ marginTop: "1em" }}>
                      {(formValue.tagsEx || []).map((tag) => (
                        <span
                          className="issue-tag-button"
                          style={{
                            background: tagsEx[tag],
                            cursor: "pointer",
                            color: "#eee",
                            padding: "3px 8px",
                            borderRadius: "5px",
                            marginRight: "5px",
                            marginBottom: "5px",
                            fontSize: "12px",
                            display: "inline-block",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            removeRecipeTagEx(formValue, tag);
                          }}
                        >
                          {tag}
                          <span style={{ marginLeft: ".2em" }}>
                            <FontAwesomeIcon
                              icon={faTimes}
                              size="1x"
                              className=""
                              style={{ fontSize: ".9em" }}
                            />
                          </span>
                        </span>
                      ))}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "inline-block",
                      width: "40%",
                      maxWidth: "initial",
                      whiteSpace: "normal",
                      fontSize: ".8em",
                      marginTop: "30px",
                      verticalAlign: "top",
                      paddingLeft: "20px",
                    }}
                    className={
                      "issue-tag" +
                      (formValue.tagsEx && formValue.tagsEx.length > 0
                        ? " filled"
                        : " empty")
                    }
                  >
                    <div style={{ marginTop: "1em" }}>
                      {(Object.keys(tagsEx) || []).map((tag) => (
                        <span
                          className="issue-tag-button"
                          style={{
                            background: hexToRGBA(tagsEx[tag], 0.1),
                            cursor: "pointer",
                            color: "#222",
                            padding: "3px 8px",
                            borderRadius: "5px",
                            marginRight: "5px",
                            marginBottom: "5px",
                            fontSize: "12px",
                            display: "inline-block",
                          }}
                          onClick={(e) => {
                            addRecipeTagEx(formValue, tag);
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                      <span
                        style={{
                          border: "1px solid #ddd",
                          color: "#222",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          marginRight: "5px",
                          marginBottom: "5px",
                          fontSize: "12px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          addTagEx();
                        }}
                      >
                        + new tag
                      </span>
                      <span
                        style={{
                          border: "1px solid #ddd",
                          color: "#222",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          marginRight: "5px",
                          marginBottom: "5px",
                          fontSize: "12px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          removeTagEx();
                        }}
                      >
                        - remove tag
                      </span>
                    </div>
                  </div>

                  <Button
                    className="button"
                    variant="light"
                    disabled={!formValue.tags || !formValue.tags.length}
                    style={{
                      borderRadius: "5px",
                      fontSize: "12px",
                      color: "#222",
                      background: "#eee",
                    }}
                    onClick={() => {
                      autoAssignmmentAlgorithm(
                        "workout plan",
                        null,
                        formValue.tagsEx.join(", "),
                        formValue.tagsEx
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faListAlt}
                      size="1x"
                      className="mr-2"
                    />
                    Submit assignment rule
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={showGroup} onHide={() => setShowGroup(false)}>
              <AddGroup
                handleClose={() => setShowGroup(false)}
                groupData={groupData}
                challengeId={selectedChallenge}
              />
            </Modal>
            <Modal
              show={showFilter}
              onHide={() => {
                setSettingRule(false);
                setShowFilter(false);
              }}
            >
              <UserFilter
                handleClose={(dismissData) => {
                  setShowFilter(false);
                  if (dismissData.filterData) {
                    setFilterData(dismissData.filterData);
                  }

                  if (settingRule) {
                    setSettingRule(false);

                    var conditions = JSON.parse(
                      JSON.stringify(dismissData.filterData)
                    );
                    delete conditions.groupId;
                    delete conditions.mealPlanId;
                    delete conditions.workoutPlanId;

                    if (Object.keys(conditions).length) {
                      addRule();
                    } else {
                      alert("no conditions have been selected");
                    }
                  }
                }}
                filterData={filterData}
                mealPlans={mealPlans}
                workoutPlans={workoutPlans}
                groups={groups}
                challenge={challenge}
                settingRule={settingRule}
                allergies={allergies}
                medicalConditions={medicalConditions}
                countries={countries}
                countriesObj={countriesObj}
              />
            </Modal>

            <Modal show={showInfo} onHide={() => SetShowInfo(false)}>
              {userInfo && (
                <div
                  id="user-info"
                  style={{
                    padding: "2em",
                    background: "beige",
                    border: "1px solid #aaa",
                  }}
                >
                  <img
                    height="40px"
                    width="40px"
                    src={
                      fbImage(userInfo.image) ||
                      "https://firebasestorage.googleapis.com/v0/b/diet-challenge-amr.appspot.com/o/images%2Fdefault-image.png?alt=media&token=4e702cb6-9c5b-438b-b542-d9b3be9275de"
                    }
                    alt="user"
                    style={{
                      objectFit: "cover",
                      borderRadius: "20px",
                      marginBottom: "10px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: ".9em",
                      opacity: ".5",
                      padding: ".5em 0",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      size="1x"
                      className=""
                      style={{ opacity: ".3" }}
                    />{" "}
                    user info{" "}
                  </div>
                  <div>
                    {/*<img src={fbImage(userInfo.image)} style={{maxWidth:'60px', maxHeight:'60px'}}/>*/}
                    {userInfo.name}
                  </div>
                  <div>{userInfo.email}</div>
                  {userInfo.mobileNumber && (
                    <div>
                      +{userInfo.dielCode} {userInfo.mobileNumber}
                    </div>
                  )}
                  {userInfo.phone && (
                    <div>
                      +{userInfo.phone.callingCode} {userInfo.phone.number}
                    </div>
                  )}
                  {userInfoFollowupNo && <div>{userInfoFollowupNo}</div>}
                  <div>{user.country && userInfo.country}</div>

                  <br />
                  {userInfo.deviceDetails && (
                    <>
                      <div
                        style={{
                          fontSize: ".9em",
                          opacity: ".5",
                          padding: ".5em 0",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="1x"
                          className=""
                          style={{ opacity: ".3" }}
                        />{" "}
                        device info{" "}
                      </div>
                      <div>
                        {userInfo.deviceDetails.brand} (
                        {userInfo.deviceDetails.version})
                      </div>
                    </>
                  )}
                  <br />
                  {userInfo.rounds > 0 && (
                    <>
                      <div
                        style={{
                          fontSize: ".9em",
                          opacity: ".5",
                          padding: ".5em 0",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="1x"
                          className=""
                          style={{ opacity: ".3" }}
                        />{" "}
                        previous rounds{" "}
                      </div>
                      <div>{userInfo.rounds}</div>
                    </>
                  )}
                  <br />
                  {userInfo.height && (
                    <>
                      <div
                        style={{
                          fontSize: ".9em",
                          opacity: ".5",
                          padding: ".5em 0",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="1x"
                          className=""
                          style={{ opacity: ".3" }}
                        />{" "}
                        height{" "}
                      </div>
                      <div>{userInfo.height} cm</div>
                    </>
                  )}
                  <br />
                  {userInfoWeight && (
                    <>
                      <div
                        style={{
                          fontSize: ".9em",
                          opacity: ".5",
                          padding: ".5em 0",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="1x"
                          className=""
                          style={{ opacity: ".3" }}
                        />{" "}
                        weight change{" "}
                      </div>
                      <div>
                        {userInfoWeight && userInfoWeight.length
                          ? +(
                              userInfoWeight[userInfoWeight.length - 1].weight -
                              Number(userInfoWeight[0].weight)
                            ).toFixed(2) + " kg."
                          : 0}
                      </div>
                      <div>
                        {userInfoWeight && userInfoWeight.length
                          ? userInfoWeight.map((d) => (
                              <div>
                                day {d.day}{" "}
                                <span
                                  style={{ fontSize: ".9em", opacity: ".5" }}
                                >
                                  ({moment(d.dateTime).format("MMM Do")})
                                </span>{" "}
                                ⇢ {Number(d.weight)}
                              </div>
                            ))
                          : null}
                      </div>
                    </>
                  )}
                  <br />
                  {userInfoStats && (
                    <>
                      <div
                        style={{
                          fontSize: ".9em",
                          opacity: ".5",
                          padding: ".5em 0",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="1x"
                          className=""
                          style={{ opacity: ".3" }}
                        />{" "}
                        user stats{" "}
                      </div>
                      <div>
                        {userInfoStats.commentCount}{" "}
                        <span style={{ opacity: 0.7 }}>comments</span>
                      </div>
                      <div>
                        {userInfoStats.exerciseCount}{" "}
                        <span style={{ opacity: 0.7 }}>exercises played</span>
                      </div>
                      <div>
                        {userInfoStats.likeCount}{" "}
                        <span style={{ opacity: 0.7 }}>likes</span>
                      </div>
                      <div>
                        {userInfoStats.mealCount}{" "}
                        <span style={{ opacity: 0.7 }}>meals uploaded</span>
                      </div>
                      <div>
                        {userInfoStats.questionCount}{" "}
                        <span style={{ opacity: 0.7 }}>questions asked</span>
                      </div>
                      <div>
                        {userInfoStats.waterCupCount}{" "}
                        <span style={{ opacity: 0.7 }}>water cups</span>
                      </div>
                    </>
                  )}

                  {admin.role && admin.role.includes("admin") && !oneToOne && (
                    <span
                      style={{
                        position: "absolute",
                        top: "2em",
                        right: "10em",
                        cursor: "pointer",
                        opacity: ".75",
                      }}
                      className="remove"
                      onClick={() => {
                        onSendMessageSingle();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#44b" }}
                        icon={faPaperPlane}
                      />
                    </span>
                  )}
                  {admin.role && admin.role.includes("admin") && !oneToOne && (
                    <span
                      style={{
                        position: "absolute",
                        top: "2em",
                        right: "8em",
                        cursor: "pointer",
                        opacity: ".75",
                      }}
                      className="remove"
                      onClick={() => {
                        transferUser();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#337ba5" }}
                        icon={faExchangeAlt}
                      />
                    </span>
                  )}
                  {admin.role && !oneToOne && (
                    <NavLink
                      key={"/questions"}
                      to={"/questions/" + userInfo.id}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "2em",
                          right: admin.role.includes("admin") ? "6em" : "2em",
                          cursor: "pointer",
                          opacity: ".75",
                        }}
                        className="contact"
                      >
                        <FontAwesomeIcon
                          style={{ color: "rgb(56 132 56)" }}
                          icon={faQuestionCircle}
                        />
                      </span>
                    </NavLink>
                  )}
                  {admin.role && admin.role.includes("admin") && (
                    <NavLink key={"/support"} to={"/support/" + userInfo.id}>
                      <span
                        style={{
                          position: "absolute",
                          top: "2em",
                          right: "4em",
                          cursor: "pointer",
                          opacity: ".75",
                        }}
                        className="contact"
                      >
                        <FontAwesomeIcon
                          style={{ color: "rgb(56 132 56)" }}
                          icon={faHeadset}
                        />
                      </span>
                    </NavLink>
                  )}
                  {admin.role && admin.role.includes("admin") && (
                    <span
                      style={{
                        position: "absolute",
                        top: "2em",
                        right: "2em",
                        cursor: "pointer",
                        opacity: ".75",
                      }}
                      className="remove"
                      onClick={() => {
                        removeUser();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#a00" }}
                        icon={faUserTimes}
                      />
                    </span>
                  )}
                </div>
              )}
            </Modal>

            <Modal show={newMessage} onHide={() => setNewMessage(false)}>
              {newMessage && (
                <Thread
                  recipients={newMessage}
                  challengeId={selectedChallenge}
                  close={() => {
                    setNewMessage(false);
                  }}
                />
              )}
            </Modal>
          </div>
        ) : (
          <div className="text-center h-100 w-100">
            Please add at least one challenge or one to one program{" "}
          </div>
        )}
      </div>
    </>
  );
}
