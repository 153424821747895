import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Form,
    Modal,
    ButtonToolbar,
    Table,
    DropdownButton,
    Dropdown,
    Spinner,
    Breadcrumb
} from "react-bootstrap";
import Masonry from 'react-masonry-css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faFilter, faPencilAlt, faCopy, faTrashAlt, faArrowUp, faArrowDown, faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { confirm } from "../../components/Confirmation";
import { firestore } from "../../services/firebase";
import AddPromocode from '../../components/AddPromocode';
import * as _ from "lodash";
import DeleteAlert from '../../components/DeleteAlert';
import moment from 'moment';

library.add(faPlus, faFilter);

export function Content() {
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [reportsLoader, setReportsLoader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');
    const [sort, setSort] = useState({
        field: 'datetime',
        direction: 'desc'
    });

    const tableHeaders = [{
        name: 'Name',
        filterField: 'name',
    //}, {
    //    name: '',
    //    filterField: 'none',
    }, {
        name: 'Date',
        filterField: 'datetime',
    }, {
        name: 'Type',
        filterField: 'type',
    }, {
        name: 'Kcal',
        filterField: 'calories',
    //}, {
    //    name: 'Actions',
    //    filterField: 'none',
    }];
    const [transactions, setTransactions] = useState([]);
    const [reports, setReports] = useState([]);
    const [promocodeData, setPromocodeData] = useState({});
    const [filterData, setFilterData] = useState({});
    const [showPromocode, setShowPromocode] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [promocodeIdToName, setPromocodeIdToName] = useState();
    const [challengeIdToName, setChallengeIdToName] = useState();

    useEffect(() => {
        const ReportsSubscription = getReports();
        return () => {
            ReportsSubscription();
        };
    }, []);


    function getReports() {
        setReportsLoader(true);
        return firestore('reports')
            .onSnapshot((querySnapshot) => {
                var promocodeIdToName = {}
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });
                setReportsLoader(false);
                setReports(_.orderBy(data, 'datetime', 'desc'));
            });
    }

    async function removeItem(d){

        if (await confirm(
            'Remove'+' '+d.toName.split(' ')[0]+"\'s "+d.type,
            'are you sure you want to '+'remove the'+' '+d.type+' ?'
            , 'remove')){
            
            if(d.type === 'post') await firestore('feeds').doc(d.contentId).delete();
            if(d.type === 'comment') await firestore('feeds').doc(d.postId).collection('comments').doc(d.contentId).delete();
            if(d.type === 'question') await firestore('questions').doc(d.contentId).delete();
            if(d.type === 'chat') await firestore('group_chat').doc(d.groupId).collection('messages').doc(d.contentId).delete();

            await firestore('reports').doc(d.key).update({action: true});
        }
    }

    return (
        <>
            <Breadcrumb className="pb-3">
                <Breadcrumb.Item active>Content moderation</Breadcrumb.Item>
            </Breadcrumb>
            <div className="pt-5">
                <Card style={{padding:"0",borderRadius:"initial"}}>
                    <Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            Reported content ({reports.length})
                            {/*<Button size='sm' variant="info" className="text-uppercase" onClick={() => {
                                addMealPlan();
                            }}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />Add promo code
                            </Button>*/}
                        </h3>
                    </Card.Header>
                    <Card.Body>

                        {!reportsLoader && <table id="reports" class="table components" style={{fontSize: '1em', marginTop: '2em', marginBottom: '0'}}>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Reported by</th>
                                <th>Content owner</th>
                                <th>Type</th>
                                <th>Content</th>
                                <th style={{textAlign: 'right'}}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {reports.map(d => <tr style={d.action&&{opacity: .5}}>
                                <td>{d.datetime.split('T')[0]}</td>
                                <td>{d.byName}</td>
                                <td>{d.toName}</td>
                                <td>{d.type}</td>
                                <td>{d.content}</td>
                                {d.action ? 
                                    <td style={{opacity:0.6, transition: '.3s', textAlign: 'right', color: 'green'}}><FontAwesomeIcon icon={faCheck} /></td> :
                                    <td style={{opacity:0.1, transition: '.3s', textAlign: 'right'}} className="remove" onClick={()=>{removeItem(d)}}><FontAwesomeIcon icon={faTrashAlt} /></td>
                                }
                            </tr>)}
                        </tbody>
                        </table>}

                    </Card.Body>
                </Card>
                {/*<Modal show={showPromocode} onHide={handleCloseMealPlan}>
                    <AddPromocode handleClose={handleCloseMealPlan} promocodeData={promocodeData} Reports={Reports} />
                </Modal>*/}
            </div>
            {/*<Modal show={showDeleteModal} onHide={handleDeleted}>
                <DeleteAlert onHide={handleDeleted} title={'Delete Meal Plan'} message={'Are you sure you want to delete meal plan?'} warning={'All the related recipes will be unassigned.'} docId={docId} collectionName={'meal_plans'} />
            </Modal>*/}
        </>
    )
}
