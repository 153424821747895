/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Spinner, Row, Col, Form } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlusCircle,
	faTimes,
	faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import imageCompression from "browser-image-compression";
import { firestore, storage } from "../services/firebase";
import StarRatings from "react-star-ratings";
import * as _ from "lodash";

import moment from "moment";

library.add(faPlusCircle, faMinusCircle);
export function AddReview(props) {
	const totalDays = 30;
	const [isBeforeImageChanged, setIsBeforeImageChanged] = useState(false);
	const [isAfterImageChanged, setIsAfterImageChanged] = useState(false);
	const [reviewBeforeImage, setreviewBeforeImage] = useState("");
	const [reviewAfterImage, setreviewAfterImage] = useState("");
	const [beforeImageFile, setBeforeImageFile] = useState(false);
	const [afterImageFile, setAfterImageFile] = useState(false);
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [beforeImageError, setBeforeImageError] = useState("");
	const [afterImageError, setAfterImageError] = useState("");
	const initFormValue = {
		challengeId: "",
		challengeName: "",
		userName: "",
		userImage:
			"https://firebasestorage.googleapis.com/v0/b/diet-challenge-amr.appspot.com/o/images%2Fdefault-image.png?alt=media&token=4e702cb6-9c5b-438b-b542-d9b3be9275de",
		rating: 0,
		caption: "",
		beforeImage: "",
		afterImage: "",
	};
	const [formValue, setFormValue] = useState(initFormValue);
	const inputBeforeImg = useRef(null);
	const inputAfterImg = useRef(null);

	useEffect(() => {
		setFormData();
		return () => {};
	}, []);

	function setFormData() {
		let formValueClone = initFormValue;
		if (props.reviewData) {
			formValueClone = props.reviewData;
			setreviewBeforeImage(props.reviewData.beforeImage);
			setreviewAfterImage(props.reviewData.afterImage);
			document.getElementById("file-image-before").classList.remove("hidden");
			document.getElementById("file-image-after").classList.remove("hidden");
		}
		setFormValue(_.clone(formValueClone));
	}

	function onImageUpload(e) {
		fileSelectHandler(e);
	}
	function onImageUpload2(e) {
		fileSelectHandler2(e);
	}

	async function submitForm(e) {
		e.stopPropagation();
		e.preventDefault();

		setIsSubmitLoader(true);

		const fileName = moment().unix();

		if (isBeforeImageChanged && beforeImageFile) {
			const uploaded = await storage()
				.ref()
				.child(`/reviews/${fileName}_before`)
				.put(beforeImageFile, { cacheControl: "public,max-age=31536000" });
			const child = await storage().ref().child(uploaded.metadata.fullPath);
			formValue.beforeImage = await child.getDownloadURL();
		}
		if (isAfterImageChanged && afterImageFile) {
			const uploaded2 = await storage()
				.ref()
				.child(`/reviews/${fileName}_after`)
				.put(afterImageFile, { cacheControl: "public,max-age=31536000" });
			const child2 = await storage().ref().child(uploaded2.metadata.fullPath);
			formValue.afterImage = await child2.getDownloadURL();
		}

		if (props.reviewData.key) {
			await firestore("reviews").doc(props.reviewData.key).update(formValue);
		} else {
			const userDetails = JSON.parse(localStorage.getItem("userDetails"));
			formValue.nutritionistId = userDetails.id;
			formValue.datetime = moment().format();
			await firestore("reviews").add(formValue);
		}

		setIsSubmitLoader(false);
		props.handleClose(true);
		// } else {
		//     setImageError('image is required');
		// }
	}

	function fileDragHover(e) {
		var fileDrag = document.getElementById("file-drag-before");
		e.stopPropagation();
		e.preventDefault();
		fileDrag.className =
			e.type === "dragover" ? "hover" : "modal-body file-upload-before";
	}
	function fileDragHover2(e) {
		var fileDrag = document.getElementById("file-drag-after");
		e.stopPropagation();
		e.preventDefault();
		fileDrag.className =
			e.type === "dragover" ? "hover" : "modal-body file-upload-after";
	}

	async function fileSelectHandler(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;
		// Cancel event and hover styling
		fileDragHover(e);

		// Process all File objects
		for (var i = 0, f; (f = files[i]); i++) {
			var options = {
				maxSizeMB: 0.3,
				maxWidthOrHeight: 400,
			};
			try {
				const compressedFile = await imageCompression(f, options);
				setBeforeImageError("");
				setBeforeImageFile(compressedFile);
				parseFile(compressedFile);
			} catch (error) {
				console.error("error fileSelectHandler", error);
				setBeforeImageError("please upload a valid image file");
			}
		}
		setIsBeforeImageChanged(true);
	}
	async function fileSelectHandler2(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;
		// Cancel event and hover styling
		fileDragHover2(e);

		// Process all File objects
		for (var i = 0, f; (f = files[i]); i++) {
			var options = {
				maxSizeMB: 0.3,
				maxWidthOrHeight: 400,
			};
			try {
				const compressedFile = await imageCompression(f, options);
				setAfterImageError("");
				setAfterImageFile(compressedFile);
				parseFile2(compressedFile);
			} catch (error) {
				console.error("error fileSelectHandler", error);
				setAfterImageError("please upload a valid image file");
			}
		}
		setIsAfterImageChanged(true);
	}

	function parseFile(file) {
		output("<strong>" + encodeURI(file.name) + "</strong>");

		// var fileType = file.type;
		var imageName = file.name;

		var isGood = true///\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
		if (isGood) {
			// document.getElementById("start-before").classList.add("hidden");
			document.getElementById("response-before").classList.remove("hidden");
			// Thumbnail Preview
			document.getElementById("file-image-before").classList.remove("hidden");
			// document.getElementById("file-image-before").src =
			// 	URL.createObjectURL(file);
			setreviewBeforeImage(URL.createObjectURL(file));
		} else {
			document.getElementById("file-image-before").classList.add("hidden");
			document.getElementById("start-before").classList.remove("hidden");
			document.getElementById("response-before").classList.add("hidden");
			document.getElementById("file-upload-form-before").reset();
		}
	}
	function parseFile2(file) {
		output2("<strong>" + encodeURI(file.name) + "</strong>");

		// var fileType = file.type;
		var imageName = file.name;

		var isGood = true///\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
		if (isGood) {
			// document.getElementById("start-after").classList.add("hidden");
			document.getElementById("response-after").classList.remove("hidden");
			// Thumbnail Preview
			document.getElementById("file-image-after").classList.remove("hidden");
			// document.getElementById("file-image-after").src =
			// 	URL.createObjectURL(file);
			setreviewAfterImage(URL.createObjectURL(file));
		} else {
			document.getElementById("file-image-after").classList.add("hidden");
			document.getElementById("start-after").classList.remove("hidden");
			document.getElementById("response-after").classList.add("hidden");
			document.getElementById("file-upload-form-after").reset();
		}
	}

	function output(msg) {
		// Response
		var m = document.getElementById("messages-before");
		m.innerHTML = msg;
	}
	function output2(msg) {
		// Response
		var m = document.getElementById("messages-after");
		m.innerHTML = msg;
	}

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title>
					{props.reviewData !== "" ? "Edit Review" : "Add Review"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					className="form"
					onSubmit={(e) => {
						submitForm(e);
					}}
				>
					<Form.Group>
						<Row style={{ height: "auto", minHeight: "200px" }}>
							<Col xs={12} xl={6}>
								<div
									className="before text-center"
									style={{ fontWeight: "700", color: "#0E5171" }}
								>
									Before
								</div>

								{reviewBeforeImage && (
									<div
										className="remove-img"
										style={{
											cursor: "pointer",
											borderRadius: "50%",
											backgroundColor: "#333",
											padding: "3px 10px",
											position: "absolute",
											right: "1%",
											top: "8%",
											zIndex: "2",
										}}
										onClick={() => {
											setIsBeforeImageChanged(false);
											setreviewBeforeImage("");
											setBeforeImageFile(false);

											inputBeforeImg.current.value = "";
										}}
									>
										<div className="icon">
											<FontAwesomeIcon icon={faTimes} size="sm" color="#fff" />
										</div>
									</div>
								)}
								<div
									className="before-container d-flex"
									style={{
										border: "1px solid #9a9a9a",
										height: "100%",
										borderRadius: "8px",
										padding: "0.7rem",
									}}
								>
									<div className="uploader">
										<input
											ref={inputBeforeImg}
											id="file-upload-before"
											type="file"
											name="fileUpload-before"
											accept="image/*"
											onChange={(e) => onImageUpload(e)}
										/>
										<label
											style={{
												borderStyle: "dashed",
												borderRadius: "8px",
												height: "100%",
												margin: "0",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												flexDirection: reviewBeforeImage ? "column" : "row",
											}}
											htmlFor="file-upload-before"
											id="file-drag-before"
											onDragOver={(e) => {
												fileDragHover(e);
											}}
											onDragLeave={(e) => {
												fileDragHover(e);
											}}
											onDrop={(e) => {
												fileSelectHandler(e);
											}}
										>
											<img
												id="file-image-before"
												src={reviewBeforeImage}
												alt=""
												className="hidden"
											/>
											<div id="start-before">
												<span
													id="file-upload-btn"
													className="btn btn-primary button button-secondary"
													style={{ borderRadius: "1.2rem" }}
												>
													Select an image
												</span>
												<div>or drag here</div>
											</div>
											<div
												id="response-before"
												className="hidden"
												style={{ display: "none" }}
											>
												<div id="messages-before"></div>
											</div>
										</label>
										{beforeImageError && (
											<div className="m-2 text-center text-danger">
												{beforeImageError}
											</div>
										)}
									</div>
								</div>
							</Col>
							<Col xs={12} xl={6}>
								<div
									className="after text-center"
									style={{ fontWeight: "700", color: "#0E5171" }}
								>
									After
								</div>
								{reviewAfterImage && (
									<div
										className="remove-img"
										style={{
											cursor: "pointer",
											borderRadius: "50%",
											backgroundColor: "#333",
											padding: "3px 10px",
											position: "absolute",
											right: "1%",
											top: "8%",
											zIndex: "2",
										}}
										onClick={() => {
											setIsAfterImageChanged(false);
											setreviewAfterImage("");
											setAfterImageFile(false);
											inputAfterImg.current.value = "";
										}}
									>
										<div className="icon">
											<FontAwesomeIcon icon={faTimes} size="sm" color="#fff" />
										</div>
									</div>
								)}
								<div
									className="after-container d-flex"
									style={{
										border: "1px solid #9a9a9a",
										height: "100%",
										borderRadius: "8px",
										padding: "0.7rem",
									}}
								>
									<div className="uploader">
										<input
											ref={inputAfterImg}
											id="file-upload-after"
											type="file"
											name="fileUpload-after"
											accept="image/*"
											onChange={(e) => onImageUpload2(e)}
										/>
										<label
											htmlFor="file-upload-after"
											id="file-drag-after"
											style={{
												borderStyle: "dashed",
												borderRadius: "8px",
												height: "100%",
												margin: "0",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												flexDirection: reviewAfterImage ? "column" : "row",
											}}
											onDragOver={(e) => {
												fileDragHover2(e);
											}}
											onDragLeave={(e) => {
												fileDragHover2(e);
											}}
											onDrop={(e) => {
												fileSelectHandler2(e);
											}}
										>
											<img
												id="file-image-after"
												src={reviewAfterImage}
												alt=""
												className="hidden"
											/>
											<div id="start-after">
												<span
													id="file-upload-btn-after"
													className="btn btn-primary button button-secondary"
													style={{ borderRadius: "1.2rem" }}
												>
													Select an image
												</span>
												<div>or drag here</div>
											</div>
											<div
												id="response-after"
												className="hidden"
												style={{ display: "none" }}
											>
												<div id="messages-after"></div>
											</div>
										</label>
										{afterImageError && (
											<div className="m-2 text-center text-danger">
												{afterImageError}
											</div>
										)}
									</div>
								</div>
							</Col>
						</Row>
						<Form.Group className="mt-5">
							<Form.Label>Challenge</Form.Label>
							<div className="selectWrapper">
								<Form.Control
									required
									as="select"
									placeholder="Select challenge"
									value={formValue.challengeId}
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										formValueClone.challengeId = e.target.value;
										const index = props.challenges.findIndex((o) => {
											return o.key === formValueClone.challengeId;
										});
										if (index > -1) {
											formValueClone.challengeName =
												props.challenges[index].name;
										}
										setFormValue(formValueClone);
									}}
								>
									<option value="" key="">
										Select challenge*
									</option>
									{props.challenges.map((challenge, i) => {
										return (
											<option value={challenge.key} key={challenge.key}>
												{challenge.name}{" "}
												{challenge.previousRounds
													? "round " + (challenge.previousRounds.length + 1)
													: "round 1"}
											</option>
										);
									})}
								</Form.Control>
							</div>
						</Form.Group>

						<Form.Row>
							<Col xs={6}>
								<Form.Label>User Name*</Form.Label>
								<Form.Control
									required
									placeholder="e.g Amr Saleh"
									value={formValue.userName}
									onChange={(e) => {
										const formValueClone = _.clone(formValue);
										formValueClone.userName = e.target.value;
										setFormValue(formValueClone);
									}}
								/>
							</Col>
							<br />
							<Col xs={6}>
								<Form.Label style={{ display: "block" }}>Rating</Form.Label>
								<StarRatings
									rating={formValue.rating}
									starDimension="30px"
									starSpacing="3px"
									starRatedColor="#ffb400"
									starHoverColor="#df8400"
									changeRating={(newRating, name) => {
										const formValueClone = _.clone(formValue);
										formValueClone.rating = newRating;
										setFormValue(formValueClone);
									}}
									numberOfStars={5}
									name="rating"
								/>
								{/*<StarRatingComponent
                            name="rating" 
                            value={formValue.rating} 
                            starCount={5} 
                            onStarClick={(nextValue, prevValue, name)=>{
                                const formValueClone = _.clone(formValue);
                                formValueClone.rating = nextValue;
                                setFormValue(formValueClone);
                            }} 
                            onStarHover={(nextValue, prevValue, name)=>{}} 
                            onStarHoverOut={(nextValue, prevValue, name)=>{}} 
                            renderStarIcon={(nextValue, prevValue, name)=>{}} 
                            renderStarIconHalf={(nextValue, prevValue, name)=>{}} 
                            starColor={'#ffb400'} 
                            emptyStarColor={'#333'} 
                            editing={true} 
                        />*/}
							</Col>
							{/*<Col xs={6}>
                        <Form.Label>Count per set</Form.Label>
                        <Form.Control placeholder="no. of reps" type="number" value={formValue.reps} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.reps = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        </Col>*/}
						</Form.Row>
						<br />

						<Form.Label>Caption</Form.Label>
						<Form.Control
							as="textarea"
							rows="3"
							placeholder="review caption"
							value={formValue.caption}
							onChange={(e) => {
								const formValueClone = _.clone(formValue);
								formValueClone.caption = e.target.value;
								setFormValue(formValueClone);
							}}
						/>
					</Form.Group>
					<div className="d-flex justify-content-center">
						<Button
							variant="primary"
							type="submit"
							className="float-right button button-primary"
						>
							{isSubmitLoader ? (
								<Spinner animation="border" size={"sm"} role="status" />
							) : (
								"Submit"
							)}
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</>
	);
}

export default AddReview;
