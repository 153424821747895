import React, { useEffect, useState } from 'react';

import { Image, Col, Row, Spinner } from "react-bootstrap";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { firestore } from "../../services/firebase";

library.add(faPlus);

export function SelectNutritionist(props) {

    const [usersLoader, setUsersLoader] = useState(false);
    const [users, setUsers] = useState(false);

    useEffect(() => {
        const mealPlanSubscription = getUsers();
        return () => {
            mealPlanSubscription();
        };
    }, []);

    function getUsers() {
        setUsersLoader(true);
        return firestore('users')
            .where('role', 'array-contains', 'nutritionist')
            .onSnapshot((querySnapshot) => {
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id,
                    };
                });

                const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));

                if(adminDetails.role && 
                    adminDetails.role.includes('assistant') && 
                    !adminDetails.role.includes('admin') && 
                    adminDetails.assist && 
                    adminDetails.assist.length
                    ) 
                    data = data.filter((d)=> adminDetails.id === d.key || adminDetails.assist.includes(d.key))

                if(adminDetails.role && 
                    adminDetails.role.includes('collaborator') && 
                    !adminDetails.role.includes('admin') && 
                    adminDetails.collaborate && 
                    adminDetails.collaborate.length
                    ) 
                    data = data.filter((d)=> adminDetails.id === d.key || adminDetails.collaborate.includes(d.key))
                
                data = data.sort((a,b)=>((b.ratings||0)*(b.rating||0))-((a.ratings||0)*(a.rating||0)))

                setUsersLoader(false);
                setUsers(data);

            });
    }

    async function onSelectNutritionist(nutritionistData) {
        localStorage.setItem('userDetails', JSON.stringify(nutritionistData));
        props.history.push('/stats');
    }

    function fbImage(imageLink){
        
        if(!imageLink) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    return (
        <>
            {/*<Breadcrumb className="pb-3">*/}
            {/*<Breadcrumb.Item active>Select Nutritionist</Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}
            <div className="pt-5">
                <Row>
                    {usersLoader ?
                        <div className="text-center h-100 w-100"><Spinner animation="border" size={'lg'}
                            role="status" />
                        </div> : users.length ?
                            users.map((user, i) => {
                                return <Col xs={12} md={6} lg={4} xl={3} key={i}><a href="#"
                                    onClick={() => onSelectNutritionist(user)}>
                                    <div className="justify-content-center">
                                        <div className="m-auto"><Image src={fbImage(user.image)} height="200" width="200" roundedCircle className="d-flex m-auto mw-100" /></div>
                                        <div><h4 className="mt-4 text-center">{user.name}</h4></div>
                                    </div>
                                </a>
                                </Col>
                            }) : ''}
                </Row>
            </div>
        </>
    )
}
