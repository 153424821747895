import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import {
    Button,
    Card,
    Form,
    Modal,
    ButtonToolbar,
    Spinner,
    Table,
    DropdownButton,
    Dropdown,
    Breadcrumb,
    ToggleButton,
    Tabs, Tab
} from "react-bootstrap";

import firebase from 'firebase/compat/app';

import { useParams } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faPencilAlt, faCopy, faTrashAlt, faFilter, faArrowUp, faArrowDown, faCommentDots, faCheckSquare, faMoneyBill, faUserSlash, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StarRatings from 'react-star-ratings';

import { ReactComponent as SupportIcon } from '../../assets/icons/support.svg';
import AddSupport from '../../components/AddSupport';
import { firestore } from "../../services/firebase";
import * as _ from "lodash";
import QuestionFilter from '../../components/QuestionFilter';
import DeleteAlert from '../../components/DeleteAlert';
import ReactHtmlParser from 'react-html-parser';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';

import "./Support.scss";

library.add(faPlus);

const arabic = /[\u0660-\u0669]/;

export function Support(props) {

    const [show, setShow] = useState(false);
    const [supporters, setSupporters] = useState({});
    const [tags, setTags] = useState({});
    const [issues, setIssues] = useState({});
    const [selectedSupporter, setSelectedSupporter] = useState();
    const [selectedTag, setSelectedTag] = useState();
    const [loader, setLoader] = useState(false);
    const [opened, setOpened] = useState();
    const [failedPayments, setFailedPayments] = useState();
    const [challengesLoader, setChallengesLoader] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [isLoadMoreLoader, setIsLoadMoreLoader] = useState(false);
    const [lastVisible, setLastVisible] = useState('');
    const [support, setSupport] = useState();
    const [followup, setFollowup] = useState();

    const [challengeIdToName, setChallengeIdToName] = useState({});

    const [sort, setSort] = useState({
        field: 'datetime',
        direction: 'desc'
    });
    const tableHeaders = [{
        name: 'Ticket ID',   //image
        filterField: 'none',
    }, {
        name: 'Support By',   //image
        filterField: 'supportBy',
    }, {
        name: 'Date',
        filterField: 'datetime',
    }, {
        name: 'Tag',
        filterField: 'tag',
    }, {
        name: 'Tags',
        filterField: 'tags',
    }, {
        name: 'Last message',
        filterField: 'none',
    }, {
        //name: 'Reply',
        filterField: 'none',
    //}, {
    //    name: 'Message',
    //    filterField: 'none',
    //}, {
    //    name: 'Opened',
    //    filterField: 'none',
    //}, {
    //    name: 'Actions',
    //    filterField: 'none',
    }];
    const [page, setPage] = useState(1);
    const [questions, setQuestions] = useState([]);
    const [questionData, setQuestionData] = useState({});
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [challenges, setChallenges] = useState([]);
    const [challengeData, setWorkoutPlanData] = useState({});
    const [unanswered, setUnanswered] = useState(false);

    const [showWorkoutPlan, setShowWorkoutPlan] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);

    const [analytics, setAnalytics] = useState({});
    const [analyticsTags, setAnalyticsTags] = useState({});
    const [currentMonthCount, setCurrentMonthCount] = useState(0);
    const [prevMonthCount, setPrevMonthCount] = useState(0);
    const [todayCount, setTodayCount] = useState(0);
    const [yesterdayCount, setYesterdayCount] = useState(0);
    const [donutData, setDonutData] = useState();
    const [barData, setBarData] = useState();
    const [barDataMonthly, setBarDataMonthly] = useState();
    const [period, setPeriod] = useState('daily');

    const [format, setFormat] = useState('MMM DD');  //YYYY-MM-DD
    const [formatWeek, setFormatWeek] = useState('WW');  //YYYY-MM-DD
    const [formatMonth, setFormatMonth] = useState('MMM YY');  //YYYY-MM-DD

    let { id } = useParams();

    useEffect(() => {

        if(id){

            setTimeout(function(){

                var question = {key: id}
                editQuestion(question)
                
            }, 1000)
        }

        return () => {};
    }, []);

    useEffect(() => {
        getChallenges();

        return () => {
            //challengeSubscription();
        };
    }, []);

    useEffect(() => {
        const questionsSubscription = getQuestions();
        return () => {
            questionsSubscription();
        };
    }, [filterData]);
/*
    async function getQuestions() {
        setLoader(true);
        const userDetails = JSON.parse(await AsyncStorage.getItem('userDetails'));
        let questionsRef = FirebaseService.firestore('questions')
            .where('nutritionistId', '==', userDetails.role.includes('nutritionist') ? userDetails.id : userDetails.nutritionistId)
            .limit(10)
            .orderBy('datetime', 'desc');
        if (page > 1) {
            questionsRef = questionsRef.startAfter(lastVisible);
        }
        const newSubscriptions = [...subscriptions];
        newSubscriptions.push(await questionsRef.onSnapshot(async (querySnapshot) => {
            let data = querySnapshot.docs.map((documentSnapshot, index) => {
                if (querySnapshot.docs.length === index + 1) {
                    setLastVisible(documentSnapshot);
                }
                return {
                    ...documentSnapshot.data(),
                    key: documentSnapshot.id,
                };
            });
            data = _.uniqBy([...questions, ...data], function (e) {
                return e.key;
            });
            console.log('data==', data);
            setQuestions(_.orderBy(data, 'datetime', 'desc'));
            setIsLoadMoreLoader(false);
            setIsLoading(false);
            setIsEndOfList(data.length < (10 * page));
        }, (error) => {
            console.log('error : ', error);
        }));
        setSubscriptions(newSubscriptions);
    }
*/
    function fixDate(str, ref){

        const arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]

        for(var i=0; i<10; i++) str = str.replace(arabicNumbers[i], i);

        alert('fixing message date..')
        ref.update({datetime: str})
            
        return str;
    };

    function getRandomColor() {
      var letters = '0123456789AB';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
      }
      return color;
    }

    function getQuestions() {
        setLoader(true);
        const userDetailsStorage = JSON.parse(localStorage.getItem('userDetails'));
        let questionsRef = firestore('users_support')
            //.where('nutritionistId', '==', userDetailsStorage.id)
            .orderBy('datetime', 'desc')
            .limit(500)
        if (page > 1) {
            questionsRef = questionsRef.startAfter(lastVisible);
        }
        if (filterData.answered) {
            questionsRef = questionsRef.where('day', '==', Number(filterData.day));
        }
        if (filterData.challengeId) {
            questionsRef = questionsRef.where('challengeId', '==', filterData.challengeId);
            console.log(filterData)
        }
        return questionsRef.onSnapshot(async (querySnapshot) => {
            var opened = 0
            var failedPayments = 0
            var supporters = {}
            var tags = {}
            var issues_ = issues || {};
            var issues_new = {}
            let data = querySnapshot.docs.map((documentSnapshot) => {
                if(documentSnapshot.data().tag) tags[documentSnapshot.data().tag] = true
                if(documentSnapshot.data().tags && documentSnapshot.data().tags.length) documentSnapshot.data().tags.map(tag=> {if(!issues_[tag]) issues_[tag] = getRandomColor(); issues_new[tag] = true})
                if(documentSnapshot.data().supportBy) supporters[documentSnapshot.data().supportBy] = true
                if(!documentSnapshot.data().closed || documentSnapshot.data().pending) opened++
                if(documentSnapshot.data().paymentFailure) failedPayments++
                return {
                    ...documentSnapshot.data(),
                    key: documentSnapshot.id,
                };
            });

            for(var tag in issues_) if(!issues_new[tag]) delete issues_[tag]

            setTags(tags)
            setIssues(issues_)
            setSupporters(supporters)
            setOpened(opened)
            setFailedPayments(failedPayments)
            //data = _.uniqBy([...questions, ...data], function (e) {
            //    return e.key;
            //});
            const method = function(item) { return moment.utc(item.datetime).format() }
            setQuestions(_.orderBy(data.filter(d=>d.message), method, 'desc'));
            setLoader(false);
        }, (error) => {
            console.error('error : ', error);
        });
    }

    async function handleLoadMore() {
        if (!isLoadMoreLoader && !isEndOfList) {
            const newPage = _.clone(page);
            setPage(newPage + 1);
            setIsLoadMoreLoader(true);
            await getQuestions();
        }
    }

    function getChallenges() {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        setChallengesLoader(true);
        return firestore('challenges')
            //.where('nutritionistId', '==', userDetails.id)
            //.onSnapshot((querySnapshot) => {
            .get().then((querySnapshot) => {

                firestore('one_to_one')
                .get().then((querySnapshot2) => {
                    let data = querySnapshot.docs.concat(querySnapshot2.docs).map((documentSnapshot, i) => {
                        return {
                            ...documentSnapshot.data(),
                            key: documentSnapshot.id,
                        };
                    });
                    var _challengeIdToName = {}
                    data.map((plan)=>{_challengeIdToName[plan.key] = plan.name})
                    setChallengeIdToName(_challengeIdToName)
                    setChallenges(data);
                    setChallengesLoader(false);
                });
            });
    }

    function getTags() {

        firestore('users_support_analytics').orderBy('datetime', 'desc').limit(180).get().then((querySnapshot) => {

            var analytics = {issues: {}, followup: {}}, analyticsMonthly = {issues: {}, followup: {}}, analyticsTags = {}, currentMonthCount = 0, prevMonthCount = 0, todayCount = 0, yesterdayCount = 0

            let data = querySnapshot.docs.map((documentSnapshot, i) => {

                if(i == 0) todayCount = documentSnapshot.data().count;
                if(i == 1) yesterdayCount = documentSnapshot.data().count;

                var ticketsWithTagsCount = Object.values(documentSnapshot.data().tags).reduce((partialSum, a) => partialSum + a, 0)

                if(moment(documentSnapshot.id).isAfter(moment().subtract(30, 'days'))) {

                    analytics.followup[documentSnapshot.id] = documentSnapshot.data().count - ticketsWithTagsCount;
                    analytics.issues[documentSnapshot.id] = ticketsWithTagsCount;

                    currentMonthCount += documentSnapshot.data().count;

                    if(documentSnapshot.data().tags) for(var tag in documentSnapshot.data().tags){

                        if(!analyticsTags[tag]) analyticsTags[tag] = 0

                        analyticsTags[tag] += documentSnapshot.data().tags[tag]
                    }
                }

                else if(moment(documentSnapshot.id).isAfter(moment().subtract(60, 'days'))) {

                    prevMonthCount += documentSnapshot.data().count;
                }

                var month = moment(documentSnapshot.id).format(formatMonth)
                if(!analyticsMonthly.issues[month]) analyticsMonthly.issues[month] = 0 
                if(!analyticsMonthly.followup[month]) analyticsMonthly.followup[month] = 0 

                analyticsMonthly.followup[month] += documentSnapshot.data().count - ticketsWithTagsCount
                analyticsMonthly.issues[month] += ticketsWithTagsCount

                return {
                    ...documentSnapshot.data(),
                    key: documentSnapshot.id,
                };
            });

            analyticsTags = Object.entries(analyticsTags).sort(([,a],[,b]) => b-a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

            var total = Object.values(analyticsTags).reduce((a,b) => a + b, 0)

            setAnalytics(analytics)
            setAnalyticsTags(analyticsTags)
            setCurrentMonthCount(currentMonthCount)
            setPrevMonthCount(prevMonthCount)
            setTodayCount(todayCount)
            setYesterdayCount(yesterdayCount)

            var donutData = {

                series: Object.values(analyticsTags),
                options: {

                    chart: {
                        type: 'donut',
                    },
                    labels: Object.keys(analyticsTags).map(tag=>' '+tag+' '+(analyticsTags[tag]/total*100).toFixed(1)+'%'),
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '48%'
                            }
                        },
                    },     
                    stroke:{
                        show: false,
                        colors:['#000']
                    },                   
                    theme: {
                        mode: 'light', 
                        palette: 'palette3', 
                        monochrome: {
                            enabled: true,
                            color: '#0E5070',
                            shadeTo: 'light',
                            shadeIntensity: 1
                        },
                    },
                    responsive: [{
                        //breakpoint: 480,
                        options: {
                            legend: {
                                //position: 'bottom'
                            }
                        }
                    }]
                }
            }

            setDonutData(donutData)

            var barData = {

                series: [{name: 'issues', data: Object.values(analytics.issues).reverse()}, {name: 'followup', data: Object.values(analytics.followup).reverse()}],
                options: {

                    chart: {
                        type: 'bar',
                        stacked: true,
                        toolbar: {
                            show: false,
                        }
                    },
                    labels: Object.keys(analytics.issues).reverse().map(d => moment(d).format(format)),  
                    plotOptions: {
                        bar: {
                            borderRadius: 5,
                        }
                    },
                    grid: {
                        borderColor: '#f5f5f5',
                    },
                    colors:['#004060', '#494']
                }
            }

            setBarData(barData)

            var barDataMonthly = {

                series: [{name: 'issues', data: Object.values(analyticsMonthly.issues).reverse()}, {name: 'followup', data: Object.values(analyticsMonthly.followup).reverse()}],
                options: {

                    chart: {
                        type: 'bar',
                        stacked: true,
                        toolbar: {
                            show: false,
                        }
                    },
                    labels: Object.keys(analyticsMonthly.issues).reverse().map(d => moment(d).format(format)),  
                    plotOptions: {
                        bar: {
                            borderRadius: 5,
                        },
                    },
                    grid: {
                        borderColor: '#f5f5f5',
                    },
                    colors:['#004060', '#494']
                }
            }

            setBarDataMonthly(barDataMonthly)
        });
    }

    const onSelect = (key, isSelect) => {
        const selectedRows = [...selectedRecords];
        if (isSelect) {
            selectedRows.push(key);
        } else {
            const index = selectedRows.findIndex((o) => o === key);
            if (index > -1) {
                selectedRows.splice(index, 1);
            }
        }
        setSelectedRecords(selectedRows);
    };

    async function onDeleteClick(key) {
        setDocId(key);
        setShowDeleteModal(true);
    }

    async function newTicket() {

        var findBy = parseInt(prompt('find user by'+'\n\n'+'1. name'+'\n'+'2. email', '1')) === 1? 'name' : 'email';
        var userName = prompt("Please enter "+findBy);

        if(!userName) return

        var snapshot = await firestore('users').where(findBy, '==', userName).get()
        var userDocs = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

        if(!userDocs.length) return alert('no user with that '+findBy)
        
        var userId
        if(userDocs.length == 1) userId = userDocs[0].id

        var userIndex = 0
        if(userDocs.length>1) {

            var msg = '('+userDocs.length+') users exist with the same '+findBy+': '+userName+'\n\n'

            msg += userDocs.map((d, i) => String(i+1)+'. '+' phone no. '+d.mobileNumber+ ' email: '+d.email).join('\n')

            userIndex = parseInt(prompt(msg, '1')) - 1;
            userId = userDocs[userIndex].id
        }

        var question = {key: userId}
        editQuestion(question)
    }

    async function removeUser(){

        var prmpt = parseInt(prompt("find user by" + "\n\n" + "1. name" + "\n" + "2. email" + "\n" + "3. id", "1"));
        var findBy = prmpt === 1 ? "name" : prmpt === 2 ? "email" : prmpt === 3 ? "id" : "";
        var userName = prompt("Please enter " + findBy);

        if(!findBy || !userName) return
            
        const userDocs = await firestore("users").where(findBy, '==', userName).get();

        var userIndex = 0
        if(userDocs.size>1) {

            var msg = '('+userDocs.size+') users exist with the same '+findBy+': '+userName+'\n\n'

            msg += userDocs.docs.map((doc, i) => String(i+1)+'. '+' phone no. '+doc.data().mobileNumber+ ' email: '+doc.data().email).join('\n')

            userIndex = parseInt(prompt(msg, '1')) - 1;
        }

        if(!userDocs.size) return alert('no user with that '+findBy)

        var userId = userDocs.docs[userIndex].id

        if(!userId) return alert("no user")


        setDocId(userId);
        setShowDeleteModal(true);
    }
    
    async function editQuestion(question = '') {
        var questionData = _.clone(question)
        var snapshot = await firestore('users_support').doc(question.key).collection('messages').get()
        questionData.messages = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; if(arabic.test(obj.datetime)) obj.datetime = fixDate(obj.datetime, d.ref); return obj}) : []

        var snapshot = await firestore('users').doc(question.key).get()
        if(snapshot.exists) questionData.user = snapshot.data()
            
        var snapshot = await firestore("followup").where("user.id", "==", question.key).get()
        if(snapshot.exists) questionData.userFollowupNo = snapshot.docs[0].data().phoneNo

        const method = function(item) { return moment.utc(item.created? item.created.toDate() : item.datetime).format() }
        questionData.messages = _.orderBy(questionData.messages, method, 'asc')
        setQuestionData(questionData);
        setShow(true);
    }

    async function cloneQuestion(question = {}) {
        delete question.key
        var snapshot = await firestore('users_support').doc(question.key).collection('messages').get()
        var messages = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; if(arabic.test(obj.datetime)) obj.datetime = fixDate(obj.datetime, d.ref); return obj}) : []
        setQuestionData(messages);
        setShow(true);
    }

    function selectAllCheckbox(e) {
        setIsSelectAllSelected(e.target.checked);
        const selectedRows = [];
        if (e.target.checked) {
            questions.forEach(o => {
                selectedRows.push(o.key);
            })
        }
        setSelectedRecords(selectedRows);
    }

    function checkIsSelected(key) {
        if (selectedRecords.length) {
            const index = selectedRecords.findIndex((o) => o === key);
            return index > -1;
        }
        return false;
    }

    function removeFilter(key) {
        const filter = _.clone(filterData);
        delete filter[key];
        setFilterData(filter);
    }

    function displayFilter(filterKey) {
        if (filterKey === 'challengeId') {
            const index = challenges.findIndex(o => o.key === filterData[filterKey]);
            if (index > -1) {
                return challenges[index].name;
            }
        }
        return filterData[filterKey];
    }

    function sortData(filterField = '', data = [], isInitSort = false) {
        if (filterField !== 'none') {
            let sortClone = _.clone(sort);
            if (filterField && !isInitSort) {
                if (sortClone.field === filterField) {
                    sortClone.direction = sortClone.direction === 'asc' ? 'desc' : 'asc';
                } else {
                    sortClone.field = filterField;
                    sortClone.direction = 'asc';
                }
                setSort(sortClone);
            }
            let usersClone = isInitSort ? data : questions;
            console.log('sortClone', sortClone);
            usersClone = _.orderBy(_.clone(usersClone), sortClone.field, sortClone.direction);
            setQuestions(usersClone);
        }
    }
    function text_truncate(str, length, ending) {
        if (length == null) {
          length = 100;
        }
        if (ending == null) {
          ending = '...';
        }
        if (str.length > length) {
          return str.substring(0, length - ending.length) + ending;
        } else {
          return str;
        }
      };

    function replaceLinks(text){

      return (text || "").replace(
        /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
        function(match, space, url){
          var hyperlink = url;
          if (!hyperlink.match('^https?:\/\/')) {
            hyperlink = 'http://' + hyperlink;
          }
          return space + '<a href="' + hyperlink + '"  target="_blank" style="max-width: 100%;display: inline;overflow-wrap: break-word;">' + url + '</a>';
        }
      );
    }


    async function addTag(ticket, tag) {

        if(ticket.tags && ticket.tags.includes(tag)) alert('Ticket already has that issue tag')

        if(!tag) tag = prompt("Please enter new issue tag");

        if(!tag) return;

        await firestore('users_support').doc(ticket.key).update({tags: firebase.firestore.FieldValue.arrayUnion(tag)});
    }
    async function removeTag(ticket, tag) {

        await firestore('users_support').doc(ticket.key).update({tags: firebase.firestore.FieldValue.arrayRemove(tag)}); 
    }

    function tabSelect(key){

        if (key === 'analytics') getTags();
    }

    const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    if(!adminDetails || !adminDetails.role || !adminDetails.role.includes('admin')) return (<div>not an admin</div>)

    return (<>

        <div className="pt-5 support-page">
                            <Tabs className="tabs" defaultActiveKey="support" id="support-tabs" onSelect={tabSelect}>
                                {/*<Tab className="tab about" eventKey="ABOUT" title="About">
                                    {challenge.welcome? challenge.welcome.map((line)=><div>{line}</div>):null}
                                </Tab>*/}
                                <Tab className="tab support" eventKey="support" title="Support">




            <Card className="my-3" style={{padding:"0", borderRadius:"initial"}}>
                <Card.Header style={{ background: '#fbfbfb', height: '100px', paddingTop: '30px'}}>
                    <h3 className="m-0 d-flex justify-content-between">
                        Tickets
                        {filterData && Object.keys(filterData).map((filterKey) => {
                            return (filterData[filterKey] ? <Button key={filterKey} onClick={() => {
                                removeFilter(filterKey);
                            }} className="rounded-pill ml-3" variant="outline-secondary"
                                size='sm'>{displayFilter(filterKey)}</Button> : '')
                        })}


                        {Object.keys(supporters).length>=1 ? <Form.Control required as="select" placeholder="Select Tag" style={{cursor: 'pointer', width: 'auto', display: 'inline-block', marginLeft: '1em', height: '100%'}}
                            value={selectedSupporter}
                            onChange={(e) => {
                                setSelectedSupporter(e.target.value);
                            }}>
                            <option value={''} key={''}>by anyone</option>
                            {Object.keys(supporters).map((obj, i) => {
                                return (<option value={obj} key={obj}>{obj}</option>)
                            })}
                        </Form.Control> : null}

                        {Object.keys(tags).length>=1 ? <Form.Control required as="select" placeholder="Select Tag" style={{cursor: 'pointer', width: 'auto', display: 'inline-block', marginLeft: '1em', height: '100%'}}
                            value={selectedTag}
                            onChange={(e) => {
                                setSelectedTag(e.target.value);
                            }}>
                            <option value={''} key={''}>any tag</option>
                            {Object.keys(tags).map((obj, i) => {
                                return (<option value={obj} key={obj}>{obj}</option>)
                            })}
                        </Form.Control> : null}

                        {/*<div className="header-item" style={{marginLeft: '1em'}}><span style={{fontSize: '16px'}}>{failedPayments||''}</span><span style={{opacity:.5, marginLeft: '.5em'}}>{failedPayments? 'failed payments' : 'no failed payments'}</span></div>*/}
                        {/*<div className="header-item" style={{marginLeft: '.3em'}}><span style={{fontSize: '16px'}}>{opened||''}</span><span style={{opacity:.5, marginLeft: '.5em'}}>{opened? 'opened' : 'all closed'}</span></div>*/}

                        <ButtonToolbar className="ml-auto header-item">
                            <ToggleButton size='sm' style={{marginBottom:'0', color:'white', paddingTop: '10px', background: '#fff', color: '#464646', borderColor: '#dee2e6', marginRight: '2px'}} type="checkbox" variant="warning" className="answered-toggle" checked={support} onChange={e => {setSupport(e.target.checked); setFollowup(false);}}>
                                <FontAwesomeIcon icon={support? faCheckSquare : null} size="1x" className="mr-2" />Support
                            </ToggleButton>
                            <ToggleButton size='sm' style={{marginBottom:'0', color:'white', paddingTop: '10px', background: '#eef5ef', color: '#464646', borderColor: '#dee2e6'}} type="checkbox" variant="warning" className="mr-2 answered-toggle" checked={followup} onChange={e => {setFollowup(e.target.checked); setSupport(false);}}>
                                <FontAwesomeIcon icon={followup? faCheckSquare : null} size="1x" className="mr-2" />Followup
                            </ToggleButton>
                            <ToggleButton size='sm' style={{marginBottom:'0', color:'white', paddingTop: '10px'}} type="checkbox" variant="warning" className="mr-2 answered-toggle" checked={unanswered} onChange={e => {setUnanswered(e.target.checked);}}>
                                <FontAwesomeIcon icon={unanswered? faCheckSquare : faCommentDots} size="1x" className="mr-2" />Opened ({opened||0})
                            </ToggleButton>
                            {/*<Button size='sm' variant="primary" className="text-uppercase ml-2"
                                onClick={() => setShowFilter(true)}>
                                <FontAwesomeIcon icon={faFilter} size="1x" className="mr-2" />Filter
                            </Button>*/}
                            <Button size='sm' variant="danger" className="mr-2" onClick={() => {removeUser();}}>
                                <FontAwesomeIcon icon={faUserSlash} size="1x" className="mr-2" />Remove user
                            </Button>

                            <Button size='sm' variant="success" className="" onClick={() => {newTicket();}}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />Send to user
                            </Button>                        
                        </ButtonToolbar>
                    </h3>
                </Card.Header>

                <Card.Body className="p-0">
                    <div style={{overflow:"auto"}}>
                        <Table className="m-0" responsive="xl">
                            <thead>
                                <tr>
                                    {/*<th>
                                        <Form.Check size="sm" checked={isSelectAllSelected}
                                            onChange={(e) => selectAllCheckbox(e)} />
                                    </th>*/}
                                    {tableHeaders && tableHeaders.map((o, i) => {
                                        return <th key={i}
                                            className="text-capitalize">{o.name} {sort && sort.field === o.filterField ?
                                                <span>
                                                    <FontAwesomeIcon icon={sort.direction === 'asc' ? faArrowUp : faArrowDown} className="ml-1" />
                                                </span> : ''}
                                        </th>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {loader ? <tr>
                                    <td className="text-center" colSpan={tableHeaders.length + 1}>
                                        <Spinner animation="border" size={'sm'} role="status" />
                                    </td>
                                </tr> : questions.length ? questions.filter((q)=>!unanswered||!q.closed||q.pending).filter((q)=>{
                                    return (selectedSupporter?q.supportBy===selectedSupporter:true) && 
                                        (selectedTag? !q.paymentFailure && q.tag===selectedTag:true) && 
                                        (support? (q.supportBy !== 'Yomna Tharwat' /*&& q.supportBy !== 'Abd L Rahman Diab'*/ && q.supportBy !== 'Andrew Samir' && q.supportBy !== 'Ranaa Kamal'):true) && 
                                        (followup? (q.supportBy === 'Yomna Tharwat' /*|| q.supportBy === 'Abd L Rahman Diab'*/ || q.supportBy === 'Andrew Samir' || q.supportBy === 'Ranaa Kamal'):true)
                                }).map((question, i) => 
                                <tr key={question.key} className={(question.supportBy === 'Yomna Tharwat' /*|| question.supportBy === 'Abd L Rahman Diab'*/ || question.supportBy === 'Andrew Samir' || question.supportBy === 'Ranaa Kamal') && 'followup'} style={(question.tag && question.tag == 'coach')?{cursor: 'pointer', background: 'ivory'}:{cursor: 'pointer'}} onClick={() => {editQuestion(question);}}>
                                    {/*<td>
                                        <Form.Group className="m-0" controlId={i + 1}>
                                            <Form.Check type="checkbox" size="sm" checked={checkIsSelected(question.key)}
                                                onChange={(e) => onSelect(question.key, e.target.checked)} />
                                        </Form.Group>
                                    </td>*/}
                                    
                                    <td style={{maxWidth: 'initial', fontSize: '.8em', opacity: '.8'}}>{question.key}</td>
                                    <td style={{maxWidth: 'initial', fontSize: '.8em', opacity: '.9'}}>{question.supportBy}</td>
                                    <td style={{maxWidth: 'initial'}}>
                                        <div style={{fontSize: '.9em', opacity: '.7'}}>{moment.utc(question.datetime).fromNow().replace('a few seconds ago', 'now')}</div>
                                    </td>
                                    {question.paymentFailure ? <td style={{maxWidth: 'initial', fontSize: '.9em'}} onClick={() => {editQuestion(question);}}>

                                        {/*<span style={{display: 'inline', background: 'brown', color: '#eee', borderRadius: '5px', padding: '5px 10px'}}><FontAwesomeIcon icon={faMoneyBill} size="1x"/></span>*/}
                                        <span style={{display: 'inline', background: 'brown', color: '#eee', borderRadius: '5px', padding: '5px 10px', marginRight:'5px'}}>payment</span>

                                    </td> :
                                    <td style={{maxWidth: 'initial', fontSize: '.9em'}} onClick={() => {editQuestion(question);}}>

                                        {question.paymentFailure && <span style={{display: 'inline', background: 'brown', color: '#eee', borderRadius: '5px', padding: '5px 10px'}}><FontAwesomeIcon icon={faMoneyBill} size="1x"/></span>}
                                        {question.isWeb && <span style={{display: 'inline', background: '#1F516F', color: '#eee', borderRadius: '5px', padding: '5px 10px', marginRight:'5px'}}>web</span>}
                                        {question.tag && question.tag == 'other' && <span style={{display: 'inline', background: '#eee', color: '#777', borderRadius: '5px', padding: '5px 10px', marginRight:'5px'}}>{question.tag}</span>}
                                        {question.tag && question.tag == 'bug' && <span style={{display: 'inline', background: 'sienna', color: '#eee', borderRadius: '5px', padding: '5px 10px', marginRight:'5px'}}>{question.tag}</span>}
                                        {question.tag && question.tag == 'payment' && <span style={{display: 'inline', background: 'mediumseagreen', color: '#eee', borderRadius: '5px', padding: '5px 10px', marginRight:'5px'}}>{question.tag}</span>}
                                        {question.tag && question.tag == 'coach' && <span style={{display: 'inline', background: '#555', color: '#eee', borderRadius: '5px', padding: '5px 10px', marginRight:'5px'}}>{question.tag}</span>}

                                    </td>}
                                    <td style={{maxWidth: 'initial', whiteSpace: 'normal', fontSize: '.8em'}} 
                                        className={"issue-tag"+(question.tags&&question.tags.length > 0?" filled":" empty")} 
                                        >
                                        <span>
                                            <Form.Control required as="select" placeholder="Select Tag" style={{cursor: 'pointer', width: 'auto', fontSize: '14px', marginLeft: '0', padding: '3px'}}
                                                onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}
                                                //value={selectedTag}
                                                value=''
                                                onChange={(e) => {
                                                    addTag(question, e.target.value);
                                                }}>
                                                <option value="" selected disabled hidden>add tag</option>
                                                {Object.keys(issues).map((obj, i) => {
                                                    return (<option value={obj} key={obj}>{obj}</option>)
                                                })}
                                                <option value={''} key={''}>+ new tag</option>
                                            </Form.Control>
                                        </span>
                                        {question.tags&&question.tags.length > 0 && 
                                            <div style={{marginTop: '1em'}}>
                                                {question.tags.map(tag => 
                                                    <span 
                                                        className="issue-tag-button" 
                                                        style={{background: issues[tag], color: '#eee', padding: '3px 8px', borderRadius: '5px', marginRight: '5px', marginBottom: '5px', fontSize: '12px', display: 'inline-block'}} 
                                                        onClick={(e)=>{e.stopPropagation(); e.preventDefault(); removeTag(question, tag)}}>
                                                        {tag} 
                                                        <span style={{marginLeft: '.2em'}}>
                                                            <FontAwesomeIcon icon={faTimes} size="1x" className="" style={{fontSize: '.9em'}} />
                                                        </span>
                                                    </span>
                                                )}
                                            </div>
                                        }
                                    </td>
                                    <td style={{maxWidth: 'initial', whiteSpace: 'normal', fontSize: '.9em', direction:'rtl'}}>{ReactHtmlParser(replaceLinks(question.message))}</td>
                                    <td style={{maxWidth: 'initial', whiteSpace: 'normal', fontSize: '.9em'}}>{
                                        question.pending?<Button variant="warning" className="mr-2">Pending</Button> : 
                                        question.closed?'' : 
                                        <Button variant="info" className="mr-2">Reply</Button>}
                                    </td>
                                    {/*<td style={{whiteSpace: 'normal', fontSize: '.8em', opacity: '.8'}}>
                                        {question.isSupport?question.isSupport:<Button variant="success" className="mr-2">
                                            Reply
                                        </Button>}
                                    </td>
                                    <td>
                                        <Button className="mr-2" onClick={() => {
                                            editQuestion(question);
                                        }}>
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </Button>
                                        <Button className="mr-2" onClick={() => {
                                            cloneQuestion(question);
                                        }}>
                                            <FontAwesomeIcon icon={faCopy} />
                                        </Button>
                                        <Button variant="danger" className="mr-2" onClick={() => {
                                            onDeleteClick(question.key);
                                        }}>
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    </td>*/}
                                </tr>) : <tr>
                                            <td className="text-center" colSpan={tableHeaders.length + 1}>No data found</td>
                                        </tr>}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
            <Modal show={show} onHide={() => setShow(false)}>
                <AddSupport handleClose={() => setShow(false)} questionData={questionData}
                    challenges={challenges} />
            </Modal>
            <Modal show={showFilter} onHide={() => setShowFilter(false)}>
                <QuestionFilter handleClose={(dismissData) => {
                    setShowFilter(false);
                    if (dismissData.filterData) {
                        setFilterData(dismissData.filterData);
                    }
                    getQuestions();
                }} filterData={filterData} challenges={challenges} />
            </Modal>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <DeleteAlert onHide={() => setShowDeleteModal(false)} title={'Remove User'} message={'Are you sure you want to delete user?'} docId={docId} collectionName={'users'} />
            </Modal>


                </Tab>


                <Tab className="tab analytics" eventKey="analytics" title="Analytics">

                    <div style={{display: 'flex'}}>
                        
                        <div style={{width: '50%', background: 'white', marginRight: '7px'}} className="support-analytics-card">

                            <h4>Issues ({Object.keys(analyticsTags).length})</h4>

                            {!donutData ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
                            <div id="chart" style={{ maxWidth: '420px', margin: '0 auto'}}>
                                <ReactApexChart options={donutData.options} series={donutData.series} type="donut" />
                            </div>}
                            
                        </div>

                        <div style={{width: '50%', background: 'white', marginLeft: '7px'}} className="support-analytics-card">
                            
                            <h4>Tickets count</h4>

                            {!todayCount ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>

                                <div>
                                    <div style={{marginTop: '30px'}}>Today</div>
                                    <div style={{marginTop: '20px', background: '#DBF5F2', color: '#2588AD', padding: '0px 20px', borderRadius: '7px', display: 'inline-block', fontSize: '35px'}}>{todayCount}</div>
                                    <div style={{marginTop: '10px', color: '#2588AD'}}>
                                        <span><FontAwesomeIcon icon={todayCount>=yesterdayCount? faArrowUp:faArrowUp} size="1x" className="mr-2" /></span>
                                        {((todayCount - yesterdayCount) / yesterdayCount * 100).toFixed(1)}%
                                    </div>
                                    <div style={{fontSize: '.9em', color: '#999'}}>vs yesterday</div>

                                </div>
                                <div>
                                    
                                    <div style={{marginTop: '30px'}}>This month</div>
                                    <div style={{marginTop: '20px', background: '#B7CBD4', color: '#0E5070', padding: '0px 20px', borderRadius: '7px', display: 'inline-block', fontSize: '35px'}}>{currentMonthCount}</div>
                                    <div style={{marginTop: '10px', color: '#2588AD'}}>{((currentMonthCount - prevMonthCount) / prevMonthCount * 100).toFixed(1)}%</div>
                                    <div style={{fontSize: '.9em', color: '#999'}}>vs last month</div>
                                </div>

                            </div>}
                        </div>
                    </div>
                    <div style={{width: '100%', height: 'auto', background: 'white', marginTop: '14px'}} className="support-analytics-card">
                        
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>

                                <h4>Tickets count</h4>

                                {(barData || barDataMonthly) && <span className="tickets-count-period">
                                    <span className={"daily "+(period === 'daily' && "selected")} onClick={()=>{setPeriod('daily')}}>daily</span>
                                    <span className={"monthly "+(period === 'monthly' && "selected")} onClick={()=>{setPeriod('monthly')}}>monthly</span>
                                </span>}
                            </div>
                                

                            {period == 'daily' && (!barData ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
                            <div id="chart">
                                <ReactApexChart options={barData.options} series={barData.series} type="bar" height={'400px'}/>
                            </div>)}

                            {period == 'monthly' && (!barDataMonthly ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
                            <div id="chart">
                                <ReactApexChart options={barDataMonthly.options} series={barDataMonthly.series} type="bar" height={'400px'}/>
                            </div>)}
                    </div>

                </Tab>

            </Tabs>
        </div>
    </>)
}
