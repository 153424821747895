
import React, {useEffect, useState} from 'react';
import { Spinner, Breadcrumb, Image, Button, ToggleButton, Col, Row, Form } from "react-bootstrap";
import Chart from "react-apexcharts";
import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";

import { confirm } from "../../components/Confirmation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMinus, faPlus, faUserPlus, faUserTimes, faTimes, faUserFriends} from '@fortawesome/free-solid-svg-icons'

import userImg from '../../assets/images/user.png';


function numberWithCommas(x) {
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function Followers(props) { 

    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [followers, setFollowers] = useState([]);
    const [allFollowers, setAllFollowers] = useState([]);
    const [coachs, setCoachs] = useState([]);

    const [page, setPage] = useState(1);
    const [scrollTop, setScrollTop] = useState(0);
    const [moreLoader, setMoreLoader] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userDetails")));
    const [lastVisible, setLastVisible] = useState();

    useEffect(() => {

    }, []);

    useEffect(() => {

        const onScroll = e => {

            setScrollTop(e.target.documentElement.scrollTop);

            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {

                // you're at the bottom of the page
                setPage(page + 1)
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);

    }, [moreLoader]);

    useEffect(() => {

        setLoader(true)
        //getAllFollowers();
        getFollowers();

        return () => {

            console.log('Do some cleanup');
            //unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);


    useEffect(() => {

        if(page > 1) {

            setMoreLoader(true)
            getFollowers()
        }

    }, [page]);

    /*useEffect(() => {

        if(allFollowers.length) getFollowers()
        else setLoader(false)

    }, [allFollowers]);*/

    function getAllFollowers(){

        setLoading(true)

        const userDetails = JSON.parse(localStorage.getItem('userDetails'));

        const subscribe = firestore("users")
            .doc(userDetails.id)
            .collection('followers')
            //.where('role', "array-contains-any", ["admin"])
            //.onSnapshot(function(snapshot){
            .get().then(function(snapshot){

            const users = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return /*obj*/d.id}) : []

            setAllFollowers(users)
        })

        return [subscribe]
    }

    /*function getAllFollowers(){

        setLoading(true)

        const userDetails = JSON.parse(localStorage.getItem('userDetails'));

        const subscribe = firestore("users")
            .doc(userDetails.id)
            .collection('followers')
            //.where('role', "array-contains-any", ["admin"])
            //.onSnapshot(function(snapshot){
            .get().then(function(snapshot){

            const users = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return d.id}) : []

            setAllFollowers(users)
        })

        return [subscribe]
    }

    function getFollowers(){

        let perPage = []
        let pageSize = 100;

        for (let i = 0;  i < allFollowers.length; i += pageSize) {
          perPage.push(allFollowers.slice(i, i + pageSize))
        }


        //get per page
        var users = perPage[page-1]

        if(!users){

            return setMoreLoader(false);
        }

        var followers = []

        let chunked = []
        let size = 10;

        for (let i = 0;  i < users.length; i += size) {
          chunked.push(users.slice(i, i + size))
        }

        //console.log('chunked', chunked)


        var allDone = function(){

            setFollowers(Followers => Followers.concat(followers));

            setLoading(false)
            setMoreLoader(false);
        }

        var done = 0

        chunked.map(chunk => {

            //console.log('chunk', chunk)

            const subscribe = firestore("users")
                //.doc(userDetails.id)
                //.collection('followers')
                .where('id', 'in', chunk)
                //.onSnapshot(function(snapshot){
                .get().then(function(snapshot){

                const users = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

                Array.prototype.push.apply(followers, users)

                if(++done === chunked.length) allDone()
            })

        })

        return
    }*/


    function getFollowers() {

        let perPage = [];
        let pageSize = 100;

        const subscribe = firestore("users")
            .doc(user.id)
            .collection("followers")
            //.where('role', "array-contains-any", ["admin"])
            //.onSnapshot(function(snapshot){
            .orderBy('datetime', 'desc')
            //.startAfter(lastVisible || null)
            .limit(pageSize)
            .get()
            .then(function (snapshot) {
                const followers = snapshot.docs.length
                    ? snapshot.docs.map((d) => {
                            var obj = d.data();
                            obj.id = d.id;
                            return obj;
                      })
                    : [];

                if(snapshot.docs.length) setLastVisible(snapshot.docs[snapshot.docs.length-1]);

                setFollowers((Followers) => Followers.concat(followers) || 0);

                setLoader(false);
                setMoreLoader(false);
            });

        return;
    }

    function fbImage(imageLink){

        if(!imageLink) return imageLink
        if(!imageLink.split) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }


   return (<>

        <Breadcrumb className="pb-3">
            <Breadcrumb.Item active>Followers (<b>{user.followersCount}</b>)</Breadcrumb.Item>

        </Breadcrumb>

        <div id="Followers" className="pt-5 h-100 align-self-center">

            {loading ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
            <div>
                <h3 style={{margin: '2em 2em 1em 2em', textAlign: 'center', display: 'none'}}>followers{/*} <FontAwesomeIcon title="add admin" onClick={() => {}} icon={faUserPlus} size="1x" style={{color: '#ccc', cursor: 'pointer', paddingLeft: '10px', boxSizing: 'content-box'}}/>*/}</h3>
                {followers.map(d => 
                    <div key={d.id} style={{display: 'inline-block', margin: '2em', padding: '1em', textAlign: 'center', position: 'relative'}}>
                        {/*<FontAwesomeIcon title="remove admin" onClick={() => {}} icon={faTimes} size="1x" style={{position: 'absolute', top: 0, right: 0, color: '#ddd', cursor: 'pointer', padding: '5px', fontSize: '30px'}}/>*/}
                        <span style={{display: 'inline-block', width: '200px', height: '200px', borderRadius: '50px', backgroundColor:'#eee', backgroundImage: 'url('+(d.userImage?fbImage(d.userImage):userImg)+')', backgroundSize: 'cover', backgroundPosition: 'center'}}></span>
                        <span style={{display: 'block', color: '#464646'}}>{d.userName}</span>
                    </div>
                )}
            </div>
            }

            {moreLoader && <div className="loader text-center" style={{padding: '5em'}}><Spinner animation="border" size={'lg'} role="status" /></div>}

        </div>
    </>);
}
