/** @format */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarAlt,
	faUser,
	faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./Programs.scss";

export default function ProgramCard({
	challenge,
	image,
	name,
	cloneConfirmation,
	deleteConfirmation
}) {
	return (
		<>
			<div className="challenges-card" style={{minHeight: 'auto'}}>
				<div className="challenge-thumbnail">
					<div
						className="background-img"
						style={{ backgroundImage: `url(${image})` }}
					></div>
				</div>
				<div className="challenge-modal-section">
					<div className="challenge-name">
						<h5>{name}</h5>
					</div>
					<div className="date-time">
						<div className=" challenge-duration">
							<FontAwesomeIcon icon={faUser} /> <span>{challenge.userCount || 0} Clients</span>
						</div>
					</div>
				</div>
				<div className="rounds-controls">

					<div className="challenge-controls">
						<div
							className="remove-btn"
							onClick={(e) => {
								deleteConfirmation(e, challenge);
							}}
						>
							<div className="circle">
								<FontAwesomeIcon icon={faTrashAlt} className="remove" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
