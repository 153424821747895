/** @format */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarAlt,
	faClock,
	faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./ChallengesComponent.scss";

export default function ChallengeCard({
	challenge,
	image,
	name,
	duration,
	date,
	rounds,
	cloneConfirmation,
	deleteConfirmation,
	roundDate,
	roundNum,
}) {
	return (
		<>
			<div className="challenges-card">
				<div className="challenge-thumbnail">
					<div
						className="background-img"
						style={{ backgroundImage: `url(${image})` }}
					></div>
				</div>
				<div className="challenge-modal-section">
					<div className="round-nums">
						<span className="roundth">{roundNum} round</span> -{" "}
						<span>{roundDate}</span>
					</div>
					<div className="challenge-name">
						<h5>{name}</h5>
					</div>
					<div className="date-time">
						<div className=" challenge-duration">
							<FontAwesomeIcon icon={faClock} /> <span>{duration}</span>
						</div>
						<div className="challenge-date">
							<FontAwesomeIcon icon={faCalendarAlt} /> <span>{date}</span>
						</div>
					</div>
				</div>
				<div className="rounds-controls">
					{rounds > 1 && <h5 className="challenge-rounds">{rounds} Rounds</h5>}

					<div className="challenge-controls">
						<div
							className="add-btn"
							onClick={(e) => cloneConfirmation(e, challenge)}
						>
							<div className="circle">
								<FontAwesomeIcon icon={faPlus} className="add" size="sm" />
							</div>
						</div>
						<div
							className="remove-btn"
							onClick={(e) => {
								deleteConfirmation(e, challenge);
							}}
						>
							<div className="circle">
								<FontAwesomeIcon icon={faTrashAlt} className="remove" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
