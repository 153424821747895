/** @format */

import React, { useRef, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import { filter } from "lodash";

ChartJS.register(ArcElement, Tooltip, Legend);

function MealPlansChart({ mealPlans, mealPlansUsers, users }) {
  let mealLabels =
    mealPlans.length &&
    mealPlans
      .filter((mealplan) => mealPlansUsers[mealplan.key])
      .sort((a, b) => mealPlansUsers[b.key] - mealPlansUsers[a.key])
      .concat([{ key: "unassigned", name: "unassigned" }])
      .map(
        (mealplan) =>
          `${mealplan.name} (${mealPlansUsers[mealplan.key]}) ${(
            (mealPlansUsers[mealplan.key] / users.length) *
            100
          ).toFixed(0)}%`
      );
  let colors = [
    "#86ddd4",
    "#20336e",
    "#2588AD",
    "#EDC437",
    "#EB705d",
    "#F79745",
    "#00c0ff",
    "#00b59c",
    "#ce5270",
    "#f73131",
  ];
  let mealPlansData = Object.assign({}, mealPlansUsers);

  delete mealPlansData.undefined;

  if (mealLabels.length) {
    let unassignedLabel = mealLabels.lastIndexOf(
      mealLabels[mealLabels.length - 1]
    );
    colors[unassignedLabel] = "#a8a8a8";
  }

  var donutData = {
    series: Object.values(mealPlansData),
    options: {
      chart: {
        type: "donut",
      },
      labels: mealLabels,
      plotOptions: {
        pie: {
          donut: {
            size: "48%",
          },
        },
      },
      stroke: {
        show: false,
        colors: ["#000"],
      },
      theme: {
        mode: "light",
        palette: "palette6",
        monochrome: {
          enabled: false,
          color: "#0E5070",
          shadeTo: "light",
          shadeIntensity: 1,
        },
      },
      responsive: [
        {
          //breakpoint: 480,
          options: {
            legend: {
              //position: 'bottom'
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <ReactApexChart
        options={donutData.options}
        series={donutData.series}
        type="donut"
      />
    </>
  );
}
export default MealPlansChart;
