
import React, {useEffect, useState} from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area} from 'recharts';

import { Spinner, Breadcrumb, Form, Col, Button } from "react-bootstrap";
import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";
import StarRatings from 'react-star-ratings';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartBar, faUsers, faHeadset} from '@fortawesome/free-solid-svg-icons'

import likeBadge from '../../assets/images/badge like.png';
import mealBadge from '../../assets/images/badge meal.png';

import capsules from '../../assets/images/capsules.png';
import posts from '../../assets/images/meals.png';
import quiz from '../../assets/images/quiz.png';
import answer from '../../assets/images/answer.png';
import weight2 from '../../assets/images/weight2.png';
import exercise from '../../assets/images/exercise.png';
import glassFilled from '../../assets/images/glass-filled.png';
import meal from '../../assets/images/meal.png';
import questionmark from '../../assets/images/questionmark.png';
import dcLike from '../../assets/images/dc-like.png';
import comments from '../../assets/images/comments.png';

import logoImg from '../../assets/icons/icon.png';
import './Coaches.css';


export function Coaches(props) {

    const [selectedUser, setSelectedUser] = useState();
    const [loader, setLoader] = useState(true);
    const [days, setDays] = useState([]);
    const [daysUser, setDaysUser] = useState();
    const [coaches, setCoaches] = useState([]);
    const [stats, setStats] = useState({});
    const [challenge, setChallenge] = useState();
    const [challenges, setChallenges] = useState([]);
    const [selectedChallenge, setSelectedChallenge] = useState('');
    const [challengesLoading, setChallengesLoading] = useState(false);
    const [ignore, setIgnore] = useState({});
    const [rounds, setRounds] = useState(false);

    const [showAllUsers, setShowAllUsers] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [loaderAll, setLoaderAll] = useState(true);
    const [challengeAll, setChallengeAll] = useState();
    const [retention, setRetention] = useState([]);
    const [avgRetention, setAvgRetention] = useState();
    const [formatMonth, setFormatMonth] = useState('MMM YY');
    const [selectionRange, setSelectionRange] = useState({
      startDate: moment('2020-08-01').toDate(),
      endDate: new Date(),
      key: 'selection',
    });


    useEffect(() => {

        if(!selectedUser || !selectedUser.id) return
        console.log('user', '>>>>', selectedUser)
        loadActivityUser();

        return () => {

        }

    }, [selectedUser]);

    useEffect(() => {

        setLoader(true)
        var unsubscribes = getCollections();

        return () => {

            console.log('Do some cleanup');
            unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);


    useEffect(() => {

        if(!showAll) return

        //setLoader(true)
        var unsubscribesAll = getCollectionsAll();

        return () => {

            console.log('Do some cleanup');
            unsubscribesAll.map((unsubscribe) => unsubscribe())
        }

    }, [showAll]);


    function getCollections(){

        const subscribePoints = firestore("users")

            .where('role', 'array-contains', 'nutritionist')
            .onSnapshot({includeMetadataChanges: true}, function(snapshot){
            //.get().then(function(snapshot){

            var coaches = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            coaches = coaches.sort((a,b)=>((b.ratings||0)*(b.rating||0))-((a.ratings||0)*(a.rating||0)))

            setCoaches(coaches);

            setLoader(false);

            //loadActivity();

            //loadStats(coaches.map((d)=>d.userId));
        })

        var loadActivity = function(){

            const subscribeFeeds = firestore("user_challenges")

                .where('challengeId', '==', selectedChallenge)
                //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
                .get().then(function(snapshot){

                const user_challenges = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

                var pointsByDay = {}

                user_challenges.map((d) => {

                    const day = parseFloat(d.dietDay)

                    if(day <= 0) return

                    if(!pointsByDay[day]) pointsByDay[day] = {day: day, user: {}, users: 0, meals: 0, workout: 0, water: 0, waterDone: 0}
                    pointsByDay[day].user[d.userId] = true
                    pointsByDay[day].water += d.isDrinkWaterCompleted? 1 : 0
                    pointsByDay[day].workout += d.isWorkoutCompleted? 1 : 0
                    pointsByDay[day].waterDone += d.glassOfWaterCount
                    if(d.Breakfast) pointsByDay[day].meals ++ 
                    if(d.Dinner) pointsByDay[day].meals ++ 
                    if(d.Lunch) pointsByDay[day].meals ++ 
                    if(d.Snack) pointsByDay[day].meals ++
                    if(d.Snack2) pointsByDay[day].meals ++
                    if(d.Sohour) pointsByDay[day].meals ++ 
                    if(d.Iftar) pointsByDay[day].meals ++
                })

                for(var day in pointsByDay){

                    pointsByDay[day].users = Object.keys(pointsByDay[day].user).length
                }

                var days = _.orderBy(Object.values(pointsByDay), 'day', 'asc').map((d)=>{d.day = 'day '+d.day; return d})

                setDays(days);
            })
        }

        var loadStats = function(userIds){

            const subscribeFeeds = firestore("user_statistics")

                .where('challengeId', '==', selectedChallenge)
                //.where('userId', 'in', userIds)
                //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
                .get().then(function(snapshot){

                const user_statistics = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

                var stats = {}

                user_statistics.map((d) => {

                    console.log(d)
                    stats[d.userId] = d
                })

                setStats(stats);
            })
        }

        return [subscribePoints/*, subscribeFeeds*/]
    }


    var loadActivityUser = function(){

        const subscribeFeeds = firestore("capsules")
            .where('userId', '==', selectedUser.id)
            .where('created', '>', moment().subtract(30, "days").startOf('day').toDate())
            //.where('challengeId', '==', selectedChallenge)
            //.where('userId', '==', selectedUser.userId)
            //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
            .get().then(function(snapshot){

            const docs = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            var pointsByDay = {}

            docs.map((d) => {

                const day = moment(d.created.toDate()).format('MMM DD')

                if(!pointsByDay[day]) pointsByDay[day] = {day: day, capsules: 0}

                pointsByDay[day].capsules += 1
            })

            var days = _.orderBy(Object.values(pointsByDay), 'day', 'asc')//.map((d)=>{d.day = 'day '+d.day; return d})

            setDaysUser(days);
        })
    }


    function getCollectionsAll(){

        //const subscribeChallenge = firestore("challenges")

            //.onSnapshot({includeMetadataChanges: true}, function(snapshot){

            var globalChallenge = false
            //snapshot.docs.map(d => {
            challenges.map(d => {

                var challenge = JSON.parse(JSON.stringify(d))//.data()
                challenge.count = 1

                if(!globalChallenge){

                    globalChallenge = challenge
                }
                else {

                    Object.keys(globalChallenge).map(key => {

                        globalChallenge[key] += challenge[key]
                    })
                }
            })
            console.log(globalChallenge)
            setChallengeAll(globalChallenge)
                    
            setLoaderAll(false);

        //})

        return [/*subscribeChallenge, subscribePoints, subscribeFeeds*/]
    }

    function fbImage(imageLink){
        
        if(!imageLink) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    function numberWithCommas(x) {
        return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (<>

            <Breadcrumb className="pb-3">
                <Breadcrumb.Item active>Coaches ({coaches.length})</Breadcrumb.Item>
            </Breadcrumb>




        <div id="coaches" className="pt-5 h-100 justify-content-left align-self-left">
            <div>
                {loader ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 

                    <div className="challenge">

                        <div className="userboard">

                            {coaches&&coaches.length? coaches.map((user, i) => 

                                <div style={{display: 'flex'}}>
                                <div className="user" key={user.id} onClick={() => setSelectedUser(user)}>
                                    <div><img src={fbImage(user.image)||"https://firebasestorage.googleapis.com/v0/b/diet-challenge-amr.appspot.com/o/images%2Fdefault-image.png?alt=media&token=4e702cb6-9c5b-438b-b542-d9b3be9275de"}/></div>
                                    <div className="name">
                                        <b>{user.name}</b>
                                        <div>{user.followersCount || 0} followers</div>
                                    </div>

                                    <div className="badges">

                                        {/*{user.badges&&user.badges.length? user.badges.slice(0,6).map((badge, i)=><div key={badge.id+String(i)}>

                                            <div className="badge"><img src={{likes: likeBadge, feeds: mealBadge}[badge.field]} title={badge.description}/><div>{badge.description}</div></div>

                                        </div>) : <div></div>}*/}


                                        {/*stats[user.userId] && <div className="badge"><img src={{likes: likeBadge, feeds: mealBadge}['feeds']} title={'meals uploaded'}/><div>{stats[user.userId].mealCount} meals uploaded</div></div>}
                                        {stats[user.userId] && <div className="badge"><img src={{likes: likeBadge, feeds: mealBadge}['likes']} title={'exercises played'}/><div>{stats[user.userId].exerciseCount} exercises played</div></div>*/}

                                        {/*stats[user.userId] && <div className="badge"><img src={weight2} title={'weight lost'}/><div>{stats[user.userId].weightLost} kg lost</div></div>*/}
                                        <div className="badge" style={{color: '#666'}}>
                                            <StarRatings
                                                rating={user.rating}
                                                starDimension="15px"
                                                starSpacing = "1px"
                                                starRatedColor="#ffb400"
                                                numberOfStars={5}
                                                name='rating'
                                            />{(user.rating||0).toFixed(2)} ({user.ratings} ratings)
                                        </div>
                                        <div className="badge"><img src={posts} title={'total posts'}/><div>{user.totalPosts || 0} posts</div></div>
                                        <div className="badge"><img src={capsules} title={'total capsules'}/>{!user.totalCapsules? <div style={{color: 'brown'}}>0 capsules</div> : <div>{user.totalCapsules} capsules</div>}</div>
                                        <div className="badge"><img src={answer} title={'total posts'}/><div>{0} public answers</div></div>
                                        <div className="badge"><img src={quiz} title={'total posts'}/><div>{0} quizzes</div></div>
                                        {stats[user.userId] && <div className="badge"><img src={questionmark} title={'questions asked'}/><div>{stats[user.userId].questionCount} questions</div></div>}
                                        {stats[user.userId] && <div className="badge"><img src={dcLike} title={'likes'}/><div>{stats[user.userId].likeCount} likes</div></div>}
                                        {stats[user.userId] && <div className="badge"><img src={comments} title={'comments'}/><div>{stats[user.userId].commentCount} comments</div></div>}

                                        
                                        {/*{stats[user.userId] && <span className="badge">{stats[user.userId].exerciseCount} exercises</span>}*/}
                                    </div>

                                </div>



                                <Button style={{marginLeft: '30px', opacity: .5, paddingBottom: '20px'}} variant="light-outline" onClick={()=>{window.open('/support/'+user.id)}}><FontAwesomeIcon icon={faHeadset} size="1x"/></Button>
                                </div>
                            )

                             : null}

                        </div> 

                        {!showAllUsers && <div>

                            <Button variant="light" onClick={()=>{setShowAllUsers(!showAllUsers)}} style={{ marginLeft: '5em'}}>
                                <FontAwesomeIcon icon={faUsers} size="1x" className="mr-2" />Show all
                            </Button>

                        </div>}


                        {daysUser? <div style={{marginTop: '20px', marginLeft: '30px', marginBottom: '3em'}} className="activity-chart">
                            <div onClick={()=>setDaysUser()} className="chart-close">X</div>
                            <div className="chart-name">{selectedUser.name}<span>{selectedUser.followersCount} followers</span></div>
                            <AreaChart width={800} height={500} data={daysUser||[]}>

                                  <defs>
                                    <linearGradient id="colorMeals" x1="0" y1="0" x2="0" y2="1">
                                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorWater" x1="0" y1="0" x2="0" y2="1">
                                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorWorkout" x1="0" y1="0" x2="0" y2="1">
                                      <stop offset="5%" stopColor="#FF8042" stopOpacity={0.8}/>
                                      <stop offset="95%" stopColor="#FF8042" stopOpacity={0}/>
                                    </linearGradient>
                                  </defs>
                                  <XAxis dataKey="day" />
                                  <YAxis />
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <Tooltip />
                                  <Area type="monotone" dataKey="capsules" stroke="#8884d8" fillOpacity={1} fill="url(#colorMeals)" />
                                  {/*<Area type="monotone" dataKey="waterDone" stroke="#82ca9d" fillOpacity={1} fill="url(#colorWater)" />
                                  <Area type="monotone" dataKey="workout" stroke="#FF8042" fillOpacity={1} fill="url(#colorWorkout)" />*/}

                            </AreaChart>
                        </div> : null}

                    </div>
                }
            </div>
        </div>
    </>)
}
