/** @format */

import React, { useEffect, useState } from "react";

// import "../../pages/auth/challenges/ChallengesPage.scss";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import DatePicker from "react-datepicker";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { firestore } from "../../services/firebase";
import ChallengeCard from "./ChallengeCard";
import "./ChallengesComponent.scss";
import firebase from 'firebase/compat/app';

library.add(faPlus, faTrash);

export default function ChallengesComponent(props) {
	const [queuedUsers, setQueuedUsers] = useState([]);
	const [challengesLoader, setChallengesLoader] = useState(false);
	const [challenges, setChallenges] = useState([]);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [showContactSupport, setShowContactSupport] = useState(false);
	const [showCloneConfirmation, setShowCloneConfirmation] = useState(false);
	const [challengeTodelete, setChallengeTodelete] = useState(false);
	const [challengeToClone, setChallengeToClone] = useState(false);
	const [ignore, setIgnore] = useState({});
	const [rounds, setRounds] = useState(false);
	const [cloneDate, setCloneDate] = useState();
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);
	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);
	const [countChallenges, setCountChallenges] = useState(0);

	useEffect(() => {
		firestore("users")
			.doc(user.id)
			.get()
			.then((doc) => {
				if (doc.exists) setUser(doc.data());
			});

		const mealPlanSubscription = getChallenges();
		return () => {
			mealPlanSubscription();
		};
	}, []);

	useEffect(() => {
		props.setchallengeMembers();

		return () => {};
	}, []);

	function getChallenges() {
		setChallengesLoader(true);
		let count = 0;
		let countArr = [];
		var subscription = firestore("challenges")
			.where("nutritionistId", "==", user.id)
			.onSnapshot((querySnapshot) => {
				setCountChallenges(querySnapshot.size);
				let data = querySnapshot.docs.map((documentSnapshot, i) => {
					if (
						new Date(documentSnapshot.data().endDatetime).toISOString() >=
						new Date().toISOString()
					) {
						count += documentSnapshot.data().userCount;
					}
					props.setchallengeMembers(count);
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});
				count = 0;

				data = _.orderBy(
					data,
					(item) => moment.utc(item.date).format(),
					"desc"
				);

				var grouped = [],
					ignore = {};
				data.map((d) => {
					if (d.previousRounds) {
						d.rounds = d.previousRounds.length + 1;
						d.previousRounds.map((d) => {
							ignore[d] = true;
						});
						d.roundsIds = [d.key].concat(d.previousRounds);
					}
				});
				setIgnore(ignore);

				setChallengesLoader(false);
				setChallenges(data);
			});

		return subscription;
	}

	/*Confirmation box for delete*/
	async function deleteConfirmation(e, challengeData) {
		e.stopPropagation();
		e.preventDefault();
		if(challengeData.isPublished){
			setShowContactSupport(true);
			return;
		}
		setChallengeTodelete(challengeData);
		setShowDeleteConfirmation(true);
	}

	/*Confirmation box for clone*/
	async function cloneConfirmation(e, challengeData) {
		e.stopPropagation();
		e.preventDefault();

		if (
			moment.duration(moment().diff(challengeData?.datetime)).asMinutes() < 60 && !(admin.role && admin.role.includes("admin"))
		) {
			const minutes = moment
				.duration(moment().diff(challengeData?.datetime))
				.asMinutes();
			return alert(
				"a round has been created recently \r\nyou can create another round in " +
					(60 - minutes).toFixed(0) +
					" minutes"
			);
		}

		const durationType = challengeData.durationType
			.replace("s", "")
			.toLowerCase();
		setCloneDate(
			moment(challengeData.date)
				.add(challengeData.duration, durationType) /*.add(1, 'days')*/
				.toDate()
		);


		const MAX_BUNDLE_ROUNDS = 12
        var previousRounds = challengeData.previousRounds? challengeData.previousRounds.slice(0, MAX_BUNDLE_ROUNDS-1 -1) : []

        var arr1 = [challengeData.key].concat(previousRounds).slice(0, 10)
        var arr2 = [challengeData.key].concat(previousRounds).slice(10)

        var snapshot1 = arr1.length? await firestore('payments').where('challengeId', 'in', arr1).get() : null;
        var snapshot2 = arr2.length? await firestore('payments').where('challengeId', 'in', arr2).get() : null;

        var payments1 = snapshot1 && snapshot1.docs.length? snapshot1.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []
        var payments2 = snapshot2 && snapshot2.docs.length? snapshot2.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

        var payments = payments1.concat(payments2)
        
		var _queuedUsers = payments.filter((d) => !d.declined && !d.refunded && d.rounds && d.rounds > d.roundsJoined.length).map((d) => d.userId)

		setQueuedUsers(_queuedUsers);

		var challengeToClone = JSON.parse(JSON.stringify(challengeData))
		challengeToClone.userCount = _queuedUsers.length
		setChallengeToClone(challengeToClone);
		setShowCloneConfirmation(true);
	}

	/* Delete challenge after confirmation */
	async function handleConfirmation(callback) {
		if (callback) {
			await firestore("challenges").doc(challengeTodelete.key).delete();
		}
		setShowDeleteConfirmation(false);
		setShowContactSupport(false);
	}
	/* Clone challenge after confirmation */
	async function handleCloneConfirmation(callback) {
		if (callback) {
			challengeToClone.parent = challengeToClone.key;
			challengeToClone.previousRounds = [challengeToClone.key].concat(
				challengeToClone.previousRounds || []
			);
			delete challengeToClone.key;
			delete challengeToClone.notification;

			challengeToClone.isPublished = false;
			//challengeToClone.userCount = 0; 	//got it from "cloneConfirmation"
			challengeToClone.groupCount = 0;
			challengeToClone.weightLost = 0;
			challengeToClone.exerciseCount = 0;
			challengeToClone.waterCupCount = 0;
			challengeToClone.mealCount = 0;
			challengeToClone.questionCount = 0;
			challengeToClone.likeCount = 0;
			challengeToClone.commentCount = 0;

			challengeToClone.datetime = moment().format();
			challengeToClone.date = moment(cloneDate).format("YYYY-MM-DDT00:00:00");
			const durationType = challengeToClone.durationType
				.replace("s", "")
				.toLowerCase();
			challengeToClone.endDatetime = moment(challengeToClone.date)
				.add(challengeToClone.duration, durationType)
				.format("YYYY-MM-DDT00:00:00");

		function toTimestamp(str){
			const dateString = str.split('T')[0];
			const dateParts = dateString.split('-');
			const year = parseInt(dateParts[0]);
			const month = parseInt(dateParts[1]) - 1; // Month is 0-based in JavaScript
			const day = parseInt(dateParts[2]);
			const myDate = new Date(year, month, day);
			const myTimestamp = firebase.firestore.Timestamp.fromDate(myDate);
			return myTimestamp
		}

		challengeToClone.startDate = toTimestamp(challengeToClone.date);
		challengeToClone.endDate = toTimestamp(moment(challengeToClone.date).add(challengeToClone.duration, challengeToClone.durationType.toLowerCase()).format("YYYY-MM-DDT00:00:00"));



			//validation

			var dataToSubmit = challengeToClone

			var atLeastOne = false;
			for (var key in dataToSubmit.goal)
				if (dataToSubmit.goal[key] === true) atLeastOne = true;
			if (!atLeastOne) {
				return alert("at least one supported user goal is required");
			}

			var atLeastOne = false;
			for (var key in dataToSubmit.dietTypes)
				if (dataToSubmit.dietTypes[key] === true) atLeastOne = true;
			if (dataToSubmit.features.mealPlan && !atLeastOne) {
				return alert("at least one supported diet type is required");
			}

			var atLeastOne = false;
			for (var key in dataToSubmit.exerciseTypes)
				if (dataToSubmit.exerciseTypes[key] === true) atLeastOne = true;
			if (dataToSubmit.features.workoutPlan && !atLeastOne) {
				return alert("at least one supported exercise type is required");
			}

			if (!dataToSubmit.price && !admin.role.includes("admin")) return alert("please fill in challenge price");
			if (!dataToSubmit.name) return alert("Name is required, please fill in name");
			if (!dataToSubmit.image) return alert("Image is required");
			if (!dataToSubmit.duration) return alert("Duration is required, please fill in duration");
			if (Object.keys(dataToSubmit.goal).length === 0) return alert("at least one goal must be supported");
			if (!dataToSubmit.features.mealPlan && !dataToSubmit.features.workoutPlan) return alert("either a meal plan or a workout plan must be supported");



			const challengeAdded = await firestore("challenges").add(
				challengeToClone
			);

			/* ========= copy sub collections =========== */

			//copy reminders
			const reminderRefNew = firestore("challenges")
				.doc(challengeAdded.id)
				.collection("reminders");
			const reminderRef = firestore("challenges")
				.doc(challengeToClone.parent)
				.collection("reminders");
			const reminders = await reminderRef.get();

			Promise.all(
				reminders.docs.map(async (item) => {
					await reminderRefNew.add(item.data());
				})
			);

			//copy inside FAQs
			const questionsRefNew = firestore("challenges")
				.doc(challengeAdded.id)
				.collection("questions");
			const questionsRef = firestore("challenges")
				.doc(challengeToClone.parent)
				.collection("questions");
			const questions = await questionsRef.get();

			Promise.all(
				questions.docs.map(async (item) => {
					await questionsRefNew.add(item.data());
				})
			);

			//copy outside FAQs
			const faqRefNew = firestore("challenges")
				.doc(challengeAdded.id)
				.collection("faq");
			const faqRef = firestore("challenges")
				.doc(challengeToClone.parent)
				.collection("faq");
			const faq = await faqRef.get();

			Promise.all(
				faq.docs.map(async (item) => {
					await faqRefNew.add(item.data());
				})
			);

			//copy assignment rules
			const assignmentsRefNew = firestore("challenges")
				.doc(challengeAdded.id)
				.collection("assignments");
			const assignmentsRef = firestore("challenges")
				.doc(challengeToClone.parent)
				.collection("assignments");
			const assignments = await assignmentsRef.get();

			//addSameTargetGroupOnlyOnce
			var newGroups = {}

			await Promise.all(
				assignments.docs.map(async (item) => {
					var newItem = item.data()
					if(newItem.assignment === 'group'){

						if(!newGroups[newItem.target]){

							newGroups[newItem.target] = true

							var group = await firestore('groups').doc(newItem.target).get()
							if(!group.exists) return alert('group does not exist')

							var newGroup = group.data()
							newGroup.challengeId = challengeAdded.id
				            newGroup.points = 0;
				            newGroup.members = {};
				            newGroup.userCount = 0;
				            newGroup.dailyActiveDieters = 0;
							var newGroup = await firestore('groups').add(newGroup)
							newGroups[newItem.target] = newGroup.id
						}
					}
				})
			);

			await Promise.all(
				assignments.docs.map(async (item) => {
					var newItem = item.data()
					if(newItem.assignment === 'group'){

						newItem.target = newGroups[newItem.target]
					}
					await assignmentsRefNew.add(newItem);
				})
			);

			/* ========= copy some reviews =========== */
			/*
            var fromChallengeId = challengeToClone.parent
            var toChallengeId = challengeAdded.id//get from await firestore('challenges').add(challengeToClone);
            firestore('reviews')
            .where('challengeId', '==', fromChallengeId)
            .get().then((querySnapshot) => {
                
                console.log(querySnapshot)
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id,
                    };
                });
                console.log(data)

                data.map(d => {

                    d.challengeId = toChallengeId
                    d.datetime = moment().format();
                    firestore('reviews').add(d);
                })
            });
            */
		}
		setShowCloneConfirmation(false);
	}

	var special = [
		"0th",
		"1st",
		"2nd",
		"3rd",
		"4th",
		"5th",
		"6th",
		"7th",
		"8th",
		"9th",
		"10th",
		"11th",
		"12th",
		"13th",
		"14th",
		"15th",
		"16th",
		"17th",
		"18th",
		"19th",
	];
	var deca = [
		"twent",
		"thirt",
		"fort",
		"fift",
		"sixt",
		"sevent",
		"eight",
		"ninet",
	];

	function stringifyNumber(n) {
		if (n < 20) return special[n];
		if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
		return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
	}

	return (
		<>
			<div className="pt-5-modified">
				<Col>
					<h4>Challenges</h4>
					{!(
						user?.followersCount >= 1000 ||
						(admin.role && admin.role.includes("admin"))
					) ? (
						<div
							style={{
								padding: "3em 0.1em",
								color: "#464646",
								fontSize: ".9em",
							}}
						>
							<p>Get 1,000 followers on Welnes to start a new challenge</p>{" "}
						</div>
					) : (
						!challengesLoader && (
							<>
								<p className="number-count">
									You have <span>{countChallenges}</span> challenges
								</p>
								<div className="add-challenge add-program">
									<NavLink
										key="/add-challenge"
										to="/add-challenge"
										exact
										activeClassName={"link-active"}
									>
										<button className="button button-secondary">
											+ Add Challenge
										</button>
									</NavLink>
								</div>
							</>
						)
					)}
				</Col>
				<div className="challenge-list">
					{challengesLoader ? (
						<div className="text-center h-100 w-100">
							<Spinner animation="border" size={"lg"} role="status" />
						</div>
					) : challenges.filter((d) => !ignore[d.key]).length ? (
						challenges
							.filter((d) => !ignore[d.key])
							.map((challenge, i) => {
								return (
									<div key={i}>
										<NavLink
											key={"/edit-challenge"}
											to={"/edit-challenge/" + challenge.key}
											onClick={
												challenge.rounds
													? (e) => {
															e.preventDefault();
															setRounds(challenge.roundsIds);
													  }
													: () => {}
											}
										>
											<ChallengeCard
												name={challenge?.name || ""}
												image={challenge.image || user.image}
												duration={`${challenge.duration} ${challenge.durationType}`}
												date={moment(challenge.date).format("D MMM Y") || ""}
												rounds={
													challenge?.rounds === undefined ? 1 : challenge.rounds
												}
												deleteConfirmation={deleteConfirmation}
												cloneConfirmation={cloneConfirmation}
												challenge={challenge}
											/>
										</NavLink>
									</div>
								);
							})
					) : (
						""
					)}
				</div>
			</div>

			<Modal
				show={rounds ? true : false}
				onHide={() => setRounds(false)}
				size="lg"
				className="modal-rounds-section"
			>
				<Modal.Header closeButton>
					<Modal.Title>Challenge Rounds</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="pt-5-modified">
						<Row className="Challenge-list">
							{challengesLoader ? (
								<div className="text-center h-100 w-100">
									<Spinner animation="border" size={"lg"} role="status" />
								</div>
							) : rounds && challenges.length ? (
								challenges
									.filter((d) => rounds.includes(d.key))
									.map((challenge, i) => {
										return (
											<Col
												xs={12}
												sm={12}
												md={12}
												lg={12}
												xl={12}
												className="mb-4 img-card"
												key={i}
											>
												<NavLink
													className=" mw-50 "
													key="/edit-challenge"
													to={`/edit-challenge/${challenge.key}`}
												>
													<div className="challenge-modal">
														<ChallengeCard
															name={challenge?.name || ""}
															image={challenge.image || user.image}
															duration={`${challenge.duration} ${challenge.durationType}`}
															date={
																moment(challenge.date).format("D MMM Y") || ""
															}
															rounds={
																challenge?.rounds === undefined
																	? 1
																	: challenge.rounds
															}
															deleteConfirmation={deleteConfirmation}
															cloneConfirmation={cloneConfirmation}
															roundDate={
																moment(challenge.date).isAfter()
																	? "starts " +
																	  moment(challenge.date)
																			.fromNow()
																			.replace("a few seconds ago", "now")
																	: "started " +
																	  moment(challenge.date)
																			.fromNow()
																			.replace("a few seconds ago", "now")
															}
															roundNum={stringifyNumber(
																challenges.filter((d) => rounds.includes(d.key))
																	.length - i
															)}
															challenge={challenge}
														/>
													</div>
												</NavLink>
											</Col>
										);
									})
							) : (
								""
							)}
						</Row>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showDeleteConfirmation}
				onHide={() => handleConfirmation(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Are you sure ?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					you want to delete{" "}
					<b>{challengeTodelete?.name || user.name || "One to One program"}</b>{" "}
					?
				</Modal.Body>
				{/*<Modal.Body>Are you sure you want to delete the challenge ?</Modal.Body>*/}
				<Modal.Footer>
					<Button
						className="button button-cancel"
						onClick={() => handleConfirmation(false)}
					>
						cancel
					</Button>
					<Button
						className="button button-delete"
						onClick={() => handleConfirmation(true)}
					>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showContactSupport}
				onHide={() => handleConfirmation(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Contact Support</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					This challenge has been published to the audience, Please contact customer support for that action
				</Modal.Body>
				{/*<Modal.Body>Are you sure you want to delete the challenge ?</Modal.Body>*/}
				<Modal.Footer>
					<Button
						className="button button-cancel"
						onClick={() => handleConfirmation(false)}
					>
						Ok
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showCloneConfirmation}
				onHide={() => handleCloneConfirmation(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<b>{challengeToClone.name}</b> new round
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>set new round start date</div>
					<br />
					<div>
						<DatePicker
							onChange={(date) => {
								setCloneDate(date);
							}}
							selected={cloneDate}
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							dateFormat="d MMMM Y"
						/>
					</div>
					<br />
					{queuedUsers.length > 0 && (
						<div
							style={{
								color: "#c33",
								fontSize: ".85em",
								marginTop: "1em",
								marginBottom: "1em",
							}}
						>
							<b>* caution : </b> ({queuedUsers.length}) users will be enrolled
							in this round.{" "}
							<span style={{ opacity: 0.5, color: "black" }}>
								bundled payments
							</span>
						</div>
					)}
				</Modal.Body>
				{/*<Modal.Body>Are you sure you want to delete the challenge ?</Modal.Body>*/}
				<Modal.Footer>
					{/* TODO: all buttons style for modal */}
					<Button
						// style={{
						// 	minWidth: "138.857px",
						// 	fontWeight: 500,
						// 	padding: "7px 15px",
						// }}
						className="button button-cancel"
						onClick={() => handleCloneConfirmation(false)}
					>
						cancel
					</Button>
					<Button
						style={{ fontWeight: 500, padding: "7px 15px" }}
						className="button button-primary"
						onClick={() => handleCloneConfirmation(true)}
					>
						<FontAwesomeIcon icon={faPlus} size="sm" /> New Round
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
