/** @format */

import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import {
	Spinner,
	Breadcrumb,
	Form,
	Modal,
	Button,
	Card,
} from "react-bootstrap";
import firebase from 'firebase/compat/app';
import { firestore, storage } from "../../services/firebase";
import moment from "moment";
import * as _ from "lodash";

import FileUploadThumbnail from "file-upload-thumbnail";
import imageCompression from "browser-image-compression";
import { ReactComponent as VideosIcon } from "../../assets/icons/camera.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/video-play.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faThumbsUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import logoImg from "../../assets/icons/icon.png";
import "./Videos.css";
import { Header } from "../../components/Header";
import DeleteAlert from "../../components/DeleteAlert";



const getThumbnail = (file) => {
	return new Promise((resolve) => {
		const canvas = document.createElement("canvas");
		const video = document.createElement("video");

		video.autoplay = true;
		video.muted = true;
		video.src = URL.createObjectURL(file);

		video.onloadeddata = () => {
			let ctx = canvas.getContext("2d");
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;

			ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
			video.pause();
			return resolve(canvas.toDataURL("image/png"));
		};
	});
};


export function Capsules(props) {
	const [loader, setLoader] = useState(true);
	const [groups, setGroups] = useState([]);
	const [videos, setVideos] = useState([]);

	const [isVideoChanged, setIsVideoChanged] = useState(false);
	const [videoFile, setVideoFile] = useState(false);
	const [videoBlob, setVideoBlob] = useState(false);
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [videoPosted, setVideoPosted] = useState(false);
	const [VideoError, setVideoError] = useState("");
	const [ignore, setIgnore] = useState({});
	const [rounds, setRounds] = useState(false);
	const [comments, setComments] = useState([]);
	const [showComments, setShowComments] = useState(false);
	const [newMessage, setNewMessage] = useState("");
	const [isSubmitCommentLoader, setIsSubmitCommentLoader] = useState(false);
	const [caption, setCaption] = useState();
	const [thumbnailImage, setThumbnailImage] = useState();
	const [progress, setProgress] = useState(0);
	const [docId, setDocId] = useState("");
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [videoData, setVideoData] = useState({});

	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);
	const [admin, setAdmin] = useState(
		JSON.parse(localStorage.getItem("adminDetails"))
	);

	useEffect(() => {
		var unsubscribes = getCollections();

		return () => {
			console.log("Do some cleanup");
			//unsubscribes.map((unsubscribe) => unsubscribe())
		};
	}, []);

	useEffect(() => {
		if (showComments) {
			firestore("capsules")
				.doc(showComments)
				.collection("comments")
				.orderBy("datetime", "asc")
				.get()
				.then((snapshot) => {
					var comments = snapshot.docs.length
						? snapshot.docs.map((d) => {
								var obj = d.data();
								obj.id = d.id;
								return obj;
						  })
						: [];

					comments.map((d) => {
						if (user.id === d.userId || admin.id === d.userId) d.ans = true;
						return d;
					});

					setComments(comments);
				});
		}

		return () => {
			console.log("Do some cleanup");
			//unsubscribes.map((unsubscribe) => unsubscribe())
		};
	}, [showComments]);

	function addMessage(message) {
		console.log(message);

		setNewMessage({
			comment: message,
			nutritionistId: user.id,
			userId: admin.id,
			userName: admin.name,
			userImage: admin.image,
		});
	}

	async function submitComment(e) {
		e.stopPropagation();
		e.preventDefault();

		setIsSubmitCommentLoader(true);

		newMessage.datetime = moment.utc().format();
		await firestore("capsules")
			.doc(showComments)
			.collection("comments")
			.add(newMessage);

		setIsSubmitCommentLoader(false);
		setShowComments(false);
	}

	function getCollections() {
		const subscribeVideos = firestore("capsules")
			.where("userId", "==", user.id)
			//.where('challengeId', '==', selectedChallenge)
			//.where('type', '==', "Nutritionist Video")
			//.orderBy('datetime', 'desc')
			//.limit(10)
			.onSnapshot({ includeMetadataChanges: true }, function (snapshot) {
				var videos = _.orderBy(
					snapshot.docs.length
						? snapshot.docs.map((d) => {
								var obj = d.data();
								obj.id = d.id;
								return obj;
						  })
						: [],
					"datetime",
					"desc"
				);

				setVideos(videos);

				if (loader) setLoader(false);
			});

		return [subscribeVideos];
	}

	async function postVideo() {
		//var caption = prompt("Please enter caption");

		setIsSubmitLoader(true);

		const fileName = user.id + "_" + Number(new Date());

		const uploadTask = storage()
			.ref()
			.child(`/nutritionists/capsules/${fileName}`)
			.put(videoFile, { cacheControl: "public,max-age=31536000" });

		uploadTask.on(
			"state_changed",

			function (snapshot) {
				var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log("Upload is " + progress + "% done");
				setProgress(progress);
			},
			function error(err) {
				console.log("error", err);
			},
			async function complete() {
				const videoLink = await uploadTask.snapshot.ref.getDownloadURL()


				const thumb_uploaded =
					thumbnailImage &&
					(await storage()
						.ref()
						.child(`/nutritionists/thumbnails/${fileName}`)
						.putString(
							thumbnailImage.split("data:image/png;base64,")[1],
							"base64",
							{ contentType: "image/jpg", cacheControl: "public,max-age=31536000" }
						));
				const thumb_child =
					thumbnailImage &&
					(await storage().ref().child(thumb_uploaded.metadata.fullPath));
				const thumbnail = thumbnailImage && (await thumb_child.getDownloadURL());

				var postBy = "nutritionist"; //admin

				if (user.id === admin.id) postBy = "nutritionist";
				//else if (user.collaborators && user.collaborators.includes(admin.id)) postBy = "collaborator";
				else if (user.assistants && user.assistants.includes(admin.id)) postBy = "assistant";

				var post = {
					//challengeName: challenge.name,
					//challengeId: challenge.id,
					userId: postBy === "collaborator" ? admin.id : user.id,
					userName: postBy === "collaborator" ? admin.name : user.name,
					userImage: postBy === "collaborator" ? admin.image : user.image,
					type: "video",
					videoLink: videoLink,
					thumbnail: thumbnail || "",
					//fileType: 'video',
					//likesCount: 0,
					//groupIds: ['none'],
					created: firebase.firestore.FieldValue.serverTimestamp(),
					datetime: new Date().toISOString(),
					postBy: postBy,
				};

				//Array.prototype.push.apply(post.groupIds, groups.map((d)=>d.id))

				//var targetGroups = [];
				//groups.map((group) => {if(targetGroups.includes(group.id)) {targetGroups.push(group.id)}})
				//post.groups = groups.filter((group) => )

				if (caption) post.caption = caption;

				console.log(post);

				firestore("capsules")
					.add(post)
					.then(function (doc) {
						setIsSubmitLoader(false);
						//setVideoPosted('video posted to '+ post.challengeName + ' feed')
						setVideoPosted("posted.");
						setTimeout(function () {
							setVideoPosted(false);
						}, 10000);

						return;
					})
					.catch(function (error) {
						console.error("Error finding/updating user document: ", error);
					});
			}
		)
	}

	function onVideoUpload(e) {
		fileSelectHandler(e);
	}

	function fileDragHover(e) {
		var fileDrag = document.getElementById("file-drag");
		e.stopPropagation();
		e.preventDefault();
		fileDrag.className =
			e.type === "dragover" ? "hover" : "modal-body file-upload";
	}

	async function fileSelectHandler(e) {
		// Fetch FileList object
		var files = e.target.files || e.dataTransfer.files;

		//make sure it s only one file
		if (files.length > 1) files.length = 1;

		// Cancel event and hover styling
		fileDragHover(e);

		var file = files[0];

		if(!file) return

		var fn = function () {
			// Process File object
			var options = {
				maxSizeMB: 50,
				//maxWidthOrHeight: 400
			};
			try {
				setVideoFile(file);
				setVideoBlob(URL.createObjectURL(file));
				parseFile(file);
			} catch (error) {
				console.error("error fileSelectHandler", error);
				setVideoError("please upload a valid video file");
			}

			setIsVideoChanged(true);
		};

		//generate thumbnail
		const thumbnailUrl = await getThumbnail(file);
		setThumbnailImage(thumbnailUrl);

		fn();
		
		/*new FileUploadThumbnail({
			maxWidth: 500,
			maxHeight: 500,
			file: file,
			onSuccess: function (src) {
				setThumbnailImage(src);

				fn();
			},
			onError: function (error) {
				console.error("error thumbnail", error);

				fn();
			},
		}).createThumbnail();*/

		//postVideo()
	}

	async function onDeleteClick(key) {
		setDocId(key);
		setShowDeleteModal(true);
	}

	function parseFile(file) {
		output("<strong>" + encodeURI(file.name) + "</strong>");
	}

	function output(msg) {
		// Response
		var m = document.getElementById("messages");
		m.innerHTML = msg;
	}

	function fbImage(imageLink) {
		if (!imageLink) return imageLink;
		var query = imageLink.split("asid=")[1];
		if (!query) return imageLink;
		var fbId = query.split("&")[0];
		if (!fbId) return imageLink;

		return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
	}

	return (
		<>
			<Header header="Capsules" />

			<hr />
			<h4>Capsules</h4>

			<div id="videos" className="pt-2 h-100 ">
				<Card>
					{loader ? (
						<div className="loader text-center">
							<Spinner animation="border" size={"sm"} role="status" />
						</div>
					) : (
						<div className="challenge">
							<div>
								<div
									className="videos"
									style={
										videos && videos.length
											? { display: "grid" }
											: { display: "inline-block" }
									}
								>
									<div>
										<div className="uploader">
											<input
												id="file-upload"
												type="file"
												name="fileUpload"
												accept="video/*"
												onChange={onVideoUpload}
											/>
											<label
												htmlFor="file-upload"
												id="file-drag"
												onDragOver={(e) => {
													fileDragHover(e);
												}}
												onDragLeave={(e) => {
													fileDragHover(e);
												}}
												onDrop={(e) => {
													fileSelectHandler(e);
												}}
											>
												{videoBlob ? (
													<ReactPlayer
														className="video-file-container"
														id="file-video"
														controls
														url={videoBlob}
														alt="video preview"
													/>
												) : (
													<div className="video-file-container-empty">
														<VideosIcon />
													</div>
												)}
												<div id="start">
													<div>Select a video or drag here</div>
												</div>
												<div id="response" className="hidden">
													<div id="messages"></div>
												</div>
											</label>

											{
												videoFile ? (
													isSubmitLoader ? (<>
														<Spinner
															animation="border"
															size={"sm"}
															role="status"
														/>
														<span style={{fontWeight: 'bold', color: '#999', marginTop: '15px'}}>{progress.toFixed(0)} %</span>
													</>) : (
														<>
															<Form.Control
																as="textarea"
																maxlength="100"
																rows="2"
																id="caption"
																onInput={(e) => setCaption(e.target.value)}
																placeholder="enter caption"
																style={{
																	borderColor: "#ddd",
																	width: "80%",
																	margin: "0 auto",
																	marginBottom: "1em",
																}}
															/>
															<span
																style={{ padding: "8px 25px" }}
																id="file-upload-btn"
																className="button button-primary btn-primary"
																onClick={(e) => {
																	postVideo();
																}}
															>
																post video
															</span>
														</>
													)
												) : (
													<span style={{ opacity: 0, pointerEvents: "none" }}>
														upload video
													</span>
												)
												//<span id="file-upload-btn" className="btn btn-primary" style={{background: '#aaa', cursor: 'default'}}>post video</span>
												//<FontAwesomeIcon icon={faPlus} size="4x" className="mr-2" style={{color: '#aaa', cursor: 'pointer'}}/>
											}
										</div>

										{videoPosted ? <b>{videoPosted}</b> : ""}
									</div>

									{videos && videos.length ? (
										videos.map((video, i) => (
											<div className="video" key={video.id} style={{position: 'relative'}}>

													<div
														className="remove-img"
														style={{
															cursor: "pointer",
															borderRadius: "50%",
															backgroundColor: "#333",
															padding: "3px 10px",
															position: "absolute",
															right: "-1%",
															top: "-1%",
															zIndex: "2",
														}}
														onClick={() => {
															onDeleteClick(video.id);

															// inputVideo.current.value = "";
														}}
													>
														<div className="icon">
															<FontAwesomeIcon
																icon={faTimes}
																size="sm"
																color="#fff"
															/>
														</div>
													</div>

												<ReactPlayer
													className="video-container"
													url={video.videoLink}
													controls
													light={video.thumbnail || true}
													playing={true}
													playIcon=<PlayIcon/>
												/>
												<div className="author d-flex flex-wrap">
													<img
														alt="user"
														src={
															fbImage(video.userImage) ||
															"https://firebasestorage.googleapis.com/v0/b/diet-challenge-amr.appspot.com/o/images%2Fdefault-image.png?alt=media&token=4e702cb6-9c5b-438b-b542-d9b3be9275de"
														}
														style={{ marginLeft: "0px" }}
													/>
													<div className="video-username">
														<span>{video.userName}</span>
														<div className="time">
															{moment(video.datetime)
																.fromNow()
																.replace("a few seconds ago", "now")}
														</div>
													</div>
													<div className="views w-100 d-flex">
														<span>
															<b style={{ margin: 0 }}>
																views ({video.views || 0})
															</b>
														</span>
														<span style={{ marginLeft: "auto" }}>
															<b style={{ margin: 0 }}>
																<FontAwesomeIcon
																	icon={faThumbsUp}
																	size="lg"
																	style={{
																		marginLeft: ".5em",
																	}}
																/>{" "}
																{video.likes ? video.likes.length : 0}
															</b>
														</span>
														<div
															variant="light"
															style={{
																cursor: "pointer",
															}}
														>
															<span
																onClick={() => {
																	setComments([]);
																	setShowComments(video.id);
																}}
															>
																<FontAwesomeIcon
																	icon={faComment}
																	size="lg"
																	style={{ marginLeft: ".5em" }}
																/>{" "}
																<b style={{ margin: 0 }}>
																	{video.commentCount || 0}
																</b>
															</span>
														</div>
													</div>

													<div>{video.group ? video.group.name : ""}</div>
												</div>
											</div>
										))
									) : (
										<div className="no-videos text-center mt-2">no videos</div>
									)}
								</div>
							</div>
						</div>
					)}
				</Card>
			</div>

			<Modal show={showComments} onHide={() => setShowComments(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Comments</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						className="form"
						style={{}}
						onSubmit={(e) => {
							submitComment(e);
						}}
					>
						{comments && comments.length
							? comments.map((message) => (
									<Form.Group
										key={message.id}
										style={
											message.ans
												? {
														borderRadius: "1em",
														background: "rgb(214 241 226)",
														padding: "1em",
														marginLeft: "0em",
														marginRight: "0em",
														color: "#464646",
												  }
												: {
														borderRadius: "1em",
														background: "#eee",
														padding: "1em",
														marginLeft: "0em",
														marginRight: "0em",
												  }
										}
									>
										<div
											style={{
												fontSize: "0.9em",
												opacity: "0.9",
												float: "right",
											}}
										>
											{moment
												.utc(message.datetime)
												.fromNow()
												.replace("a few seconds ago", "now")}
										</div>

										<div>
											<img
												alt="user"
												src={fbImage(message.userImage)}
												height="40"
												width="auto"
												style={{ borderRadius: "20px", marginRight: "10px" }}
											/>
											<span>{message.userName}</span>
										</div>

										<div>{message.comment}</div>
									</Form.Group>
							  ))
							: null}

						<br />
						<Form.Control
							as="textarea"
							rows="3"
							required
							placeholder={"post comment"}
							value={newMessage.message}
							onChange={(e) => {
								addMessage(e.target.value);
							}}
						/>
						<div className="w-100 text-center">
							<Button
								variant="dark"
								type="submit"
								className=" submit-button button button-primary "
								style={{ width: "200px", padding: "8px 25px" }}
							>
								{isSubmitCommentLoader ? (
									<Spinner animation="border" size={"sm"} role="status" />
								) : (
									<>
										<FontAwesomeIcon
											icon={faComment}
											size="1x"
											className="mr-2"
										/>
										Post Comment
									</>
								)}
							</Button>
						</div>

						<br style={{ clear: "both" }} />
					</Form>
				</Modal.Body>
			</Modal>
			<Modal
				show={showDeleteModal}
				onHide={() => {
					setShowDeleteModal(false);
					setVideoData({});
				}}
			>
				<DeleteAlert
					onHide={() => {
						setShowDeleteModal(false);
						setVideoData({});
					}}
					title={"Delete Video"}
					message={"Are you sure you want to delete video?"}
					docId={docId}
					collectionName={"capsules"}
				/>
			</Modal>
		</>
	);
}
