import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faDoorOpen, faUserTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

class Confirmation extends React.Component {
  render() {
    const {
      proceedLabel,
      cancelLabel,
      title,
      confirmation,
      show,
      proceed,
      enableEscape = true
    } = this.props;
    return (
      <div className="static-modal">
        <Modal
          show={show}
          onHide={() => proceed(false)}
          backdrop={enableEscape ? true : "static"}
          keyboard={enableEscape}
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{whiteSpace: 'break-spaces'}}>{confirmation}</Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={() => proceed(false)}>{cancelLabel}</Button>
            <Button
               variant={(proceedLabel === 'kickout' || proceedLabel === 'clear')? "danger" : "success"}
              onClick={() => proceed(true)}
            >
              <FontAwesomeIcon icon={proceedLabel === 'kickout'? faDoorOpen : proceedLabel === 'clear'? faTrashAlt : faPlus} size="1x" className="mr-2" />{proceedLabel}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
};

export function confirm(
  title,
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    title,
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options
  });
}
