
import React, {useEffect, useState} from 'react';
import firebase from 'firebase/compat/app';
import { firestore, storage } from "../services/firebase";
import { Button, Image } from "react-bootstrap";

export function ContactAdmin() {

    const [admin, setAdmin] = useState(JSON.parse(localStorage.getItem('adminDetails')));
    const [nutritionistStatus, setNutritionistStatus] = useState('pending');

    useEffect(() => {

        if(admin) firestore('users').doc(admin.id).get().then(querySnapshot => {

            setNutritionistStatus(querySnapshot.data().nutritionist || '')
        })

        return () => {}

    }, []);


    async function logout() {
        localStorage.clear();
        await firebase.auth().signOut();
    }

    return (
        <div style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            fontSize: 20,
            fontWeight: 'bold',
            flexDirection: 'column',
            justifyContent: 'center',
            justifySelf: 'center',
            alignItems: 'center'
        }}>
            
            {nutritionistStatus === 'pending'? 
                'Your profile has been submitted successfully, please wait and our team will get back to you soon' :
                'You are not nutritionist please contact admin to be one.'
            }

            <br/>
            <br/>
            <br/>
            {nutritionistStatus === 'pending'? 
                <Button size="sm" variant="secondary" onClick={(e) => {window.open('https://welnes.app/download')}} style={{background: 'rgb(30, 81, 111)', borderColor: '#666', padding: '.5em 1em'}}>Download app</Button> :
                <Button size="sm" variant="primary" onClick={() => logout()} style={{padding: '.5em 1em'}}>Sign out</Button>
            }
        </div>
    )
}

export default ContactAdmin
