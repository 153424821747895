import React, { useState, useEffect } from "react";
import { Button, Modal, Form, ButtonGroup, Spinner } from 'react-bootstrap';
import * as _ from "lodash";
import DeleteAlert from "./DeleteAlert";
import { firestore } from "../services/firebase";
import { faCalendarPlus, faCalendarMinus, faPlus, faTrash, faClone, faSearch, faCheck, faCheckSquare, faSquare, faChevronDown, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function AddGroup(props) {
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');
    const [formValue, setFormValue] = useState({
        name: props.groupData && props.groupData.name ? props.groupData.name : '',
        description: props.groupData && props.groupData.description ? props.groupData.description : '',
    });
    useEffect(() => {
    }, []);
    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();
        setIsSubmitLoader(true);
        const saveData = _.clone(formValue);
        if (props.groupData.key) {
            await firestore('groups').doc(props.groupData.key).update(saveData);
        } else {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            if(userDetails && userDetails.id){
                saveData.nutritionistId = userDetails.id;
            }
            saveData.challengeId = props.challengeId;
            saveData.points = 0;
            saveData.userCount = 0;
            saveData.dailyActiveDieters = 0;
            await firestore('groups').add(saveData);
        }
        setIsSubmitLoader(false);
        props.handleClose(true);
    }
    function handleDeleted(dismissModal) {
        return dismissModal === 'deleted' ? props.handleClose(true) : setShowDeleteModal(false);
    }

    async function deleteGroup() {
        setDocId(props.groupData.key);
        setShowDeleteModal(true);
    }
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{props.groupData.key ? 'Edit' : 'Add'} Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="form" onSubmit={(e) => {
                    submitForm(e)
                }}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control required placeholder="Group 1" value={formValue.name}
                            onChange={e => {
                                const formValueClone = _.clone(formValue);
                                formValueClone.name = e.target.value;
                                setFormValue(formValueClone);
                            }} />
                            <br/>
                        <Form.Label>Description</Form.Label>
                        <Form.Control placeholder="Group description" value={formValue.description}
                            onChange={e => {
                                const formValueClone = _.clone(formValue);
                                formValueClone.description = e.target.value;
                                setFormValue(formValueClone);
                            }} />
                    </Form.Group>
                    <ButtonGroup className="float-right">
                        <div className="float-right">
                            {props.groupData.key && <Button variant="danger" className="submit-button mr-3" onClick={() => {
                                    deleteGroup()
                                }}>
                               <FontAwesomeIcon icon={faTrash} size="1x" className="" /> Delete
                            </Button>}
                            <Button variant="primary" type="submit" className="submit-button">
                                {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : 'Submit'}
                            </Button>
                        </div>
                    </ButtonGroup>
                </Form>
            </Modal.Body>
            <Modal show={showDeleteModal} onHide={handleDeleted}>
                <DeleteAlert onHide={handleDeleted} title={'Delete Group'} message={'Are you sure you want to delete group?'} warning={'All group users will be unassigned.'} docId={docId} collectionName={'groups'} />
            </Modal>
        </>
    );
}

export default AddGroup;
