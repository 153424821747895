/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner, Form, Col, Nav } from "react-bootstrap";
import { firestore } from "../services/firebase";
import DeleteAlert from "./DeleteAlert";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Welnes from "../assets/icons/welnes.svg";
import moment from "moment";

import {
  faTrash,
  faPencilAlt,
  faUndo,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import WorkoutCard from "./WorkoutCard";
import firebase from 'firebase/compat/app';

/* =============== drag and drop ======================== */
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 5;
const width = 220;

const getItemStyle = (isDragging, draggableStyle, isSuperSet, inSuperSet) => ({
  direction: "ltr",
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 1,
  textAlign: "center",
  fontSize: ".9em",
  // fontVariant: "small-caps",
  margin: isSuperSet ? `0 0 0 0` : `0 0 ${grid}px 0`,
  borderRadius: `${grid}px`,
  borderBottomLeftRadius: isSuperSet ? 0 : `${grid}px`,
  borderBottomRightRadius: isSuperSet ? 0 : `${grid}px`,
  borderTopLeftRadius: inSuperSet ? 0 : `${grid}px`,
  borderTopRightRadius: inSuperSet ? 0 : `${grid}px`,
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "",
  //background: isDragging ? "lightgreen" : (isSuperSet||inSuperSet)?"#eee":"#ddd",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#fff",
  padding: grid,
  position: "static",
  margin: "20px 0",
  paddingTop: "10px",
  // width: width + "px",
  width: "25%",
  display: "inline-block",
  verticalAlign: "top",
  borderRadius: "10px",
});

const dayStyle = () => ({
  // padding: grid + "px 0",
  // position: "absolute",
  // width: width - 2 * grid + "px",
  // transform: "translateY(-100%)",
  borderRadius: "8px",
  textAlign: "center",
  hight: "20%",
  fontWeight: "bold",
  padding: "0.5em 0",
  color: "#0E5171",
  backgroundColor: "#EFFAFF",
  marginBottom: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "baseline",
  gap: "9px",
});
const dayStyleCont = () => ({
  position: "absolute",
  width: width - 2 * grid + "px",
  transform: "translateY(-100%)",
  display: "block",
  textAlign: "center",
  fontWeight: "bold",
  color: "#464646",
  borderRadius: "5px",
  // display: "flex",
});
const dayStyleRemove = () => ({
  padding: grid + "px 0",
  width: "50%",
  display: "inline-block",
  textAlign: "center",
  fontWeight: "bold",
  color: "#464646",
  cursor: "pointer",
  background: "lightblue",
  borderTopLeftRadius: "7px",
  borderBottomLeftRadius: "7px",
  marginRight: "2px",
});
const dayStyleAdd = () => ({
  padding: grid + "px 0",
  width: "50%",
  display: "inline-block",
  textAlign: "center",
  fontWeight: "bold",
  color: "#464646",
  cursor: "pointer",
  background: "lightblue",
  borderTopRightRadius: "7px",
  borderBottomRightRadius: "7px",
  marginLeft: "2px",
});

const getMenuStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? "lightblue" : "#fff",
  background: "#fff",
  borderRight: "1px solid #aaa",
  borderLeft: "1px solid #aaa",
  padding: grid + "px",

  paddingTop: "50px",
  width: width + "px",
  transition: ".3s left",
  position: "fixed",
  top: "0",
  bottom: "0",
  left: -(width + 30) + "px",
  overflowX: "hidden",
  overflowY: "scroll",
  boxSizing: "content-box",
  direction: "rtl",
  display: "block",
  opacity: 0,
  zIndex: 1,
});
/* ========================================================== */

export function AddWorkoutPlan2(props) {
  const [currentWeek, setCurrentWeek] = useState(
    Math.ceil(moment().date() / 7)
  );
  const [nItems, setNItems] = useState(50);
  const [scrollTop, setScrollTop] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [isSubmitLoader, setIsSubmitLoader] = useState(false);
  const [isCloneLoader, setIsCloneLoader] = useState(false);
  const [workoutPlanName, setWorkoutPlanName] = useState("");
  const [workoutPlanWeeks, setWorkoutPlanWeeks] = useState(
    Math.max(
      props.workoutPlanData.weeks || 0,
      Math.ceil(Object.keys(props.workoutPlanData.exercises || {}).length / 7)
    )
  );
  const [workoutPlanEquipment, setWorkoutPlanEquipment] = useState("");
  const [workoutPlanDescription, setWorkoutPlanDescription] = useState("");
  const [workoutPlanDetails, setWorkoutPlanDetails] = useState("");
  const [workoutPlanPlan, setWorkoutPlanPlan] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [docId, setDocId] = useState("");
  const [cls, setCls] = useState("");
  const [state, setState] = useState([
    /*_.uniqBy(props.exercises, 'name')*/ props.exercises.map((d) => {
      return { exercise: d.key };
    }),
  ]);
  const [exercisesById, setExercisesById] = useState({});
  const [showDetails, setShowDetails] = useState(0);
  const [selectedType, setSelectedType] = useState();
  const [searchKey, setSearchKey] = useState();
  const [isSuperSet, setIsSuperSet] = useState({});
  const [inSuperSet, setInSuperSet] = useState({});

  useEffect(() => {
    var planExercises = {};
    if (props.workoutPlanData && props.workoutPlanData.plan) {
      const _isSuperSet = _.clone(isSuperSet);
      const _inSuperSet = _.clone(inSuperSet);

      for (const [day, arr] of Object.entries(props.workoutPlanData.plan)) {
        var dayExercises = [];

        arr.map((d, index) => {
          d.exercises.map((ex, k) => {
            var obj = {
              exercise: ex.id,
              reps: displayReps(ex.reps, ex.unit),
              unit: ex.unit,
            };
            if (k === 0) obj.sets = d.sets || "";
            if (k === d.exercises.length - 1) obj.rest = displayRest(d.rest);

            if (d.exercises.length > 1 && k !== d.exercises.length - 1)
              obj.isSS = true;
            if (d.exercises.length > 1 && k !== 0) obj.inSS = true;

            dayExercises.push(obj);
          });
        });

        dayExercises.map((d, i) => {
          if (d.isSS) _isSuperSet[day + "_" + i] = true;
          if (d.inSS) _inSuperSet[day + "_" + i] = true;
        });

        planExercises[day] = dayExercises;
      }

      setIsSuperSet(_isSuperSet);
      setInSuperSet(_inSuperSet);
    }

    if (props.workoutPlanData && props.workoutPlanData.name)
      setWorkoutPlanName(props.workoutPlanData.name);
    if (props.workoutPlanData && props.workoutPlanData.weeks)
      setWorkoutPlanWeeks(
        Math.max(
          props.workoutPlanData.weeks || 0,
          Math.ceil(Object.keys(props.workoutPlanData.exercises).length / 7)
        )
      );
    if (props.workoutPlanData && props.workoutPlanData.equipment)
      setWorkoutPlanEquipment(props.workoutPlanData.equipment);
    if (props.workoutPlanData && props.workoutPlanData.description)
      setWorkoutPlanDescription(props.workoutPlanData.description);
    if (props.workoutPlanData && props.workoutPlanData.details)
      setWorkoutPlanDetails(props.workoutPlanData.details);
    if (props.workoutPlanData && props.workoutPlanData.plan)
      setWorkoutPlanPlan(props.workoutPlanData.plan);
    if (props.workoutPlanData /*&& props.workoutPlanData.exercises*/)
      var arr = [];
    arr[0] = props.exercises.map((d) => {
      return { exercise: d.key };
    });
    var availableExercises = props.exercises.map((d) => d.key);
    for (var i = 1; i <= 31; i++) {
      if (
        props.workoutPlanData.exercises &&
        props.workoutPlanData.exercises[i] &&
        props.workoutPlanData.exercises[i].length
      )
        arr[i] = props.workoutPlanData.exercises[i]
          .filter((r) => availableExercises.includes(r))
          .map((d, index) => {
            return planExercises[i] && planExercises[i][index]
              ? planExercises[i][index]
              : { exercise: d };
          });
      else arr[i] = [];
    }
    setState(arr);

    if (props.exercises) {
      var obj = {};
      props.exercises.map((ex) => {
        obj[ex.key] = ex;
      });
      setExercisesById(obj);
    }

    //setTimeout(()=>{ if(document.getElementById("day-0")) document.getElementById("day-0").style.display='block'; }, 0)
    setTimeout(() => {
      if (document.getElementById("day-0"))
        document.getElementById("day-0").style.left = 0;
      if (document.getElementById("day-0"))
        document.getElementById("day-0").style.opacity = 1;
    }, 1000);

    setTimeout(() => {
      var workoutDescEl = document.getElementById("workout-description");
      if (workoutDescEl && workoutDescEl.value) {
        //workoutDescEl.style.height = "";
        workoutDescEl.style.height = 1.1 * workoutDescEl.scrollHeight + "px";
      }
    }, 1000);

    weeks().map((week) => {
      if (week.key === currentWeek) setSelectedWeek(week);
    });
  }, []);

  function handleDeleted(dismissModal) {
    return dismissModal === "deleted"
      ? props.handleClose(true)
      : setShowDeleteModal(false);
  }

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.scrollTop);

      if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
        // you're at the bottom of the page
        setNItems((nItems) => nItems + 20);
      }
    };

    document.getElementById("day-0").addEventListener("scroll", onScroll);

    return () =>
      document.getElementById("day-0").removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (selectedType !== undefined || searchKey !== undefined) {
      const newState = [...state];
      console.log(selectedType, searchKey, newState);
      newState[0] = props.exercises
        .map((d) => {
          return { exercise: d.key };
        })
        .filter(filterFn);
      setState(newState);
    }

    return () => {};
  }, [selectedType, searchKey]);

  function onDragEnd(result) {
    const { source, destination } = result;

    const newState = [...state];
    newState[0] = /*_.uniqBy(props.exercises, 'name')*/ props.exercises
      .map((d) => {
        return { exercise: d.key };
      })
      .filter(filterFn);
    // dropped outside the list
    if (!destination) {
      setState(newState);
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(
        newState /*.filter((group,i) => i<newState.length-1 || group.length)*/
      );
    }

    newState.map((day, ind) => {
      var index = day.length - 1;
      //if last item in day is superset ==> convert back to set
      if (day.length && isSuperSet[ind + "_" + index]) {
        const _isSuperSet = _.clone(isSuperSet);
        _isSuperSet[ind + "_" + index] = false;
        setIsSuperSet(_isSuperSet);

        const _inSuperSet = _.clone(inSuperSet);
        _inSuperSet[ind + "_" + (index + 1)] = false;
        setInSuperSet(_inSuperSet);
      }
    });

    //move sets
    /*const _sets = _.clone(sets)
    _sets[dInd+'_'+destination.index] = _sets[sInd+'_'+source.index]
    _sets[sInd+'_'+source.index] = _sets[sInd+'_'+(source.index+1)]
    setSets(_sets)*/
  }
  function onBeforeDragStart(result) {
    //return
    const { source } = result;

    const sInd = +source.droppableId;

    if (sInd === 0) {
      const newState = [...state];
      newState[sInd].splice(source.index, 0, _.clone(state[0][source.index]));
      setState(newState);
    }
  }

  function sanitizeReps(str = "") {
    var reps = parseInt(str);
    var unit = "c";

    if (str.includes(":")) {
      unit = "s";
      reps = parseInt(str.split(":")[0]) * 60 + parseInt(str.split(":")[1]);
    }
    if (str.includes("m")) unit = "m";
    if (str.includes("s")) unit = "s";

    return { reps: reps, unit: unit };
  }

  function sanitizeRest(str = "") {
    var rest = parseInt(str);

    if (str.includes(":"))
      rest = parseInt(str.split(":")[0]) * 60 + parseInt(str.split(":")[1]);

    return rest;
  }

  function displayReps(reps = 0, unit = "") {
    if (isNaN(reps)) reps = 0;

    var str = reps.toString();

    if (unit === "c") str += "";
    if (unit === "m") str += "";
    if (unit === "s") str += "";
    // if (unit === "s") str = Math.floor(reps / 60) + ":" + (reps % 60);

    return str;
  }

  function displayRest(rest = 0) {
    if (isNaN(rest)) rest = 0;

    //var str = rest.toString()
    var str = Math.floor(rest / 60) + ":" + (rest % 60);

    return str;
  }

  async function submitForm(e) {
    e.stopPropagation();
    e.preventDefault();
    if (workoutPlanName) {
      setIsSubmitLoader(true);

      /*for(var i in state){
              if(i>0){
                if(state[i].length>10){
                  alert('day '+i+' : max no of exercises per day is 10')
                  setIsSubmitLoader(false);
                  return
                }
              }
            }*/

      var obj = {};
      state.map((arr, i) => {
        if (i > 0) obj[i] = arr.map((d) => d.exercise);
      });

      var plan = {};
      state.map((arr, i) => {
        if (i === 0) return;

        plan[i] = [];
        for (var k = 0; k < arr.length; k++) {
          const item = arr[k];
          const Exercise = exercisesById[item.exercise];

          var exercise = {
            id: item.exercise,
            name: Exercise.name,
            image: Exercise.image,
            description: Exercise.description,
            videoLink: Exercise.videoLink,
            reps: sanitizeReps(item.reps).reps || 1,
            unit: item.unit || "c",
          };
          if (inSuperSet[i + "_" + k]) {
            plan[i][plan[i].length - 1].exercises.push(exercise);
            plan[i][plan[i].length - 1].rest = sanitizeRest(item.rest);
            continue;
          }

          plan[i].push({
            sets: item.sets || 1,
            exercises: [exercise],
            rest: sanitizeRest(item.rest) || 0,
          });
        }
      });

      if (props.workoutPlanData.key) {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const addWorkoutPlan = {
          nutritionistName: userDetails.name,
          nutritionistImage: userDetails.image,
          name: workoutPlanName,
          weeks: workoutPlanWeeks,
          equipment: workoutPlanEquipment,
          description: workoutPlanDescription,
          details: workoutPlanDetails,
          exercises: obj,
          plan: plan,
          start: props.start,
          rest: props.rest,
          end: props.end,
          new: true,
        };
        await firestore("workout_plans")
          .doc(props.workoutPlanData.key)
          .update(addWorkoutPlan);
        firestore("workout_plans_essentials")
          .doc(props.workoutPlanData.key)
          .update({
            name: workoutPlanName,
            weeks: workoutPlanWeeks,
            description: workoutPlanDescription,
          });
      } else {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const addWorkoutPlan = {
          nutritionistId: userDetails.id,
          nutritionistName: userDetails.name,
          nutritionistImage: userDetails.image,
          name: workoutPlanName,
          weeks: workoutPlanWeeks,
          equipment: workoutPlanEquipment,
          description: workoutPlanDescription,
          details: workoutPlanDetails,
          exercises: obj,
          plan: plan,
          start: props.start,
          rest: props.rest,
          end: props.end,
          new: true,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        };
        const res = await firestore("workout_plans").add(addWorkoutPlan);
        firestore("workout_plans_essentials").doc(res.id).set({
          nutritionistId: userDetails.id,
          name: workoutPlanName,
          weeks: workoutPlanWeeks,
          description: workoutPlanDescription,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      setIsSubmitLoader(false);
      props.handleClose(true);
    }
  }
  async function onDeleteClick() {
    setDocId(props.workoutPlanData.key);
    setShowDeleteModal(true);
  }
  function handleDeleted(dismissModal) {
    return dismissModal === "deleted"
      ? props.handleClose(true)
      : setShowDeleteModal(false);
  }

  async function clonePlan() {
    if (workoutPlanName) {
      setIsCloneLoader(true);

      var obj = {};
      state.map((arr, i) => {
        if (i > 0) obj[i] = arr.map((d) => d.exercise);
      });

      var new_workoutPlanName = prompt(
        "Enter name for new (cloned) workout plan",
        workoutPlanName + " (2)"
      );

      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const addWorkoutPlan = {
        nutritionistId: userDetails.id,
        nutritionistName: userDetails.name,
        nutritionistImage: userDetails.image,
        name: new_workoutPlanName,
        weeks: workoutPlanWeeks,
        equipment: workoutPlanEquipment,
        description: workoutPlanDescription,
        details: workoutPlanDetails,
        plan: workoutPlanPlan,
        start: props.start,
        rest: props.rest,
        end: props.end,
        exercises: obj,
        new: true,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      };
      const res = await firestore("workout_plans").add(addWorkoutPlan);
      firestore("workout_plans_essentials").doc(res.id).set({
        nutritionistId: userDetails.id,
        name: new_workoutPlanName,
        weeks: workoutPlanWeeks,
        description: workoutPlanDescription,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      });

      setIsCloneLoader(false);
      props.handleClose(true);
    }
  }

  var special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
  ];
  var deca = [
    "twent",
    "thirt",
    "fort",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet",
  ];

  function stringifyNumber(n) {
    if (n < 20) return special[n];
    if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
    return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
  }

  function weeks(n) {
    var month = moment().format("MMMM");

    var arr = [];
    for (var i = 1; i <= 5; i++) {
      arr.push({
        key: i,
        from: (i - 1) * 7 + 1,
        to: i * 7 - (i === 5 ? 4 : 0),
        month: month,
        week: i,
      });
    }

    return arr;
  }

  function filterFn(ex) {
    const exercise = exercisesById[ex.exercise];

    return (
      (selectedType ? exercise.type === selectedType : true) &&
      (searchKey
        ? exercise.name.toLowerCase().includes(searchKey.toLowerCase())
        : true)
    );
  }

  return (
    <>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ margin: "0" }}>
        <Form.Group>
          <Form.Row style={{ justifyContent: "end", alignItems: "baseline" }}>
            <h4 style={{ flex: 1 }}>
              {props.workoutPlanData.key ? "Edit" : "Add"} Workout Plan
            </h4>
            <label
              type="button"
              className="clone btn btn-primary btn-block"
              variant="danger"
              onClick={() => {
                const fromPrompt = prompt("Enter day you want to clone from");
                if (!fromPrompt) return;
                const toPrompt = prompt("Enter day you want to clone to");
                if (!toPrompt) return;
                const fromDay = Math.max(1, +fromPrompt);
                const toDay = Math.max(1, +toPrompt);

                if (toDay > workoutPlanWeeks * 7)
                  return alert(
                    "target day is out of defined workout plan no. of weeks, please increase no. of weeks"
                  );

                var newState = [...state];
                newState[toDay] = _.clone(newState[fromDay]);
                setState(newState);

                var newDetails = _.clone(workoutPlanDetails);
                if (typeof newDetails !== "object") newDetails = {};

                const from = workoutPlanDetails[fromDay];
                if (from) newDetails[toDay] = from;
                else delete newDetails[toDay];

                const _isSuperSet = _.clone(isSuperSet);
                const _inSuperSet = _.clone(inSuperSet);

                for (var key in _isSuperSet) {
                  const day = +key.split("_")[0];
                  const cont = +key.split("_")[1];

                  if (day === fromDay) _isSuperSet[toDay + "_" + cont] = true;
                }
                for (var key in _inSuperSet) {
                  const day = +key.split("_")[0];
                  const cont = +key.split("_")[1];

                  if (day === fromDay) _inSuperSet[toDay + "_" + cont] = true;
                }

                setIsSuperSet(_isSuperSet);
                setInSuperSet(_inSuperSet);

                setWorkoutPlanDetails(newDetails);
              }}
            >
              <FontAwesomeIcon
                style={{ marginRight: "5px" }}
                icon={faClone}
                size="1x"
                className=""
              />
              Clone day
            </label>

            <label
              type="button"
              className="clone btn btn-primary btn-block"
              variant="danger"
              onClick={() => {
                const fromPrompt = prompt("Enter week you want to clone from");
                if (!fromPrompt) return;
                //const mealplan = Object.keys(challengeNameToChallengeId)[parseInt(prompt("Clone to which meal plan \n\n" + Object.keys(challengeNameToChallengeId).map((name, i) => String(i+1)+'. '+name).join('\n'), '1')) - 1]; if(!mealplan) return
                const toPrompt = prompt("Enter week you want to clone to");
                if (!toPrompt) return;
                const fromWeek = Math.max(1, +fromPrompt);
                const toWeek = Math.max(1, +toPrompt);

                if (toWeek > workoutPlanWeeks)
                  return alert(
                    "target week is out of defined workout plan no. of weeks, please increase no. of weeks"
                  );

                var newState = [...state];
                var newDetails = _.clone(workoutPlanDetails);
                if (typeof newDetails !== "object") newDetails = {};

                const _isSuperSet = _.clone(isSuperSet);
                const _inSuperSet = _.clone(inSuperSet);

                for (var i = 1; i <= 7; i++) {
                  newState[(toWeek - 1) * 7 + i] = _.clone(
                    newState[(fromWeek - 1) * 7 + i]
                  );

                  const from = workoutPlanDetails[(fromWeek - 1) * 7 + i];
                  if (from) newDetails[(toWeek - 1) * 7 + i] = from;
                  else delete newDetails[(toWeek - 1) * 7 + i];

                  const fromDay = (fromWeek - 1) * 7 + i;
                  const toDay = (toWeek - 1) * 7 + i;

                  for (var key in _isSuperSet) {
                    const day = +key.split("_")[0];
                    const cont = +key.split("_")[1];

                    if (day === fromDay) _isSuperSet[toDay + "_" + cont] = true;
                  }
                  for (var key in _inSuperSet) {
                    const day = +key.split("_")[0];
                    const cont = +key.split("_")[1];

                    if (day === fromDay) _inSuperSet[toDay + "_" + cont] = true;
                  }
                }

                setIsSuperSet(_isSuperSet);
                setInSuperSet(_inSuperSet);

                setWorkoutPlanDetails(newDetails);
                setState(newState);
              }}
            >
              <FontAwesomeIcon
                style={{ marginRight: "5px" }}
                icon={faClone}
                size="1x"
                className=""
              />
              Clone week
            </label>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Row>
            <Col xs={12}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                style={{ textAlign: "center" }}
                form="wp-form"
                placeholder="Enter name"
                defaultValue={props.workoutPlanData.name}
                onChange={(e) => {
                  setWorkoutPlanName(e.target.value);
                }}
                required
              />
            </Col>
            {/*<Col xs={6}>
							<Form.Label>No. of weeks </Form.Label>
							<Form.Control
								style={{ textAlign: "center" }}
								form="wp-form"
								placeholder=""
								defaultValue={workoutPlanWeeks}
								required
								type="number"
								min="1"
								onChange={(e) => {
									const nWeeks = e.target.value;
									const newState = [...state];
									for (var i = 1; i <= nWeeks * 7; i++) {
										if (!newState[i]) newState[i] = [];
									}
									newState.length = 1 + nWeeks * 7;
									setState(newState);
									setWorkoutPlanWeeks(nWeeks);
								}}
							/>
						</Col>*/}
          </Form.Row>

          <Form.Row style={{ paddingTop: "10px" }}>
            <Col xs={12}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="workout description"
                defaultValue={workoutPlanDescription}
                id="workout-description"
                onChange={(e) => {
                  setWorkoutPlanDescription(e.target.value);
                }}
                as="textarea"
                rows={
                  workoutPlanDescription
                    ? Math.max(3, workoutPlanDescription.split(/\r*\n/).length)
                    : 3
                }
              />
              <hr style={{ borderColor: "#f0f0f0" }} />
            </Col>
          </Form.Row>
        </Form.Group>
        <Form
          className="form"
          id="wp-form"
          onSubmit={(e) => {
            submitForm(e);
          }}
        >
          <Nav
            variant="pills"
            defaultActiveKey={currentWeek}
            className="justify-content-center"
            style={{ marginTop: "2em", opacity: "0.75" }}
          >
            {weeks(workoutPlanWeeks).map((week) => (
              <Nav.Item key={week}>
                <Nav.Link
                  className="button button-secondary2"
                  style={{ minWidth: "0", margin: "5px" }}
                  eventKey={week.key}
                  onClick={(e) => {
                    /*const newState = [...state];
												for (var i = week.from; i <= week.to; i++) {
													if (!newState[i]) newState[i] = [];
												}
												setState(newState);*/
                    setSelectedWeek(week);
                  }}
                >
                  {week.from} - {week.to}
                  <span style={{ opacity: 0.6, marginLeft: "7px" }}>
                    {week.month}
                  </span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <div>
            <div
              id="items-container"
              style={{
                overflow: "auto",
                marginTop: ".7em",
                textAlign: "center",
              }}
            >
              <DragDropContext
                onDragEnd={onDragEnd}
                onBeforeDragStart={onBeforeDragStart}
              >
                {state.map(
                  (el, ind) =>
                    (ind == 0 ||
                      (ind >= selectedWeek.from && ind <= selectedWeek.to)) && (
                      <Droppable
                        key={ind}
                        droppableId={`${ind}`}
                        isDropDisabled={ind == 0}
                      >
                        {(provided, snapshot) => (
                          <div
                            id={"day-" + ind}
                            ref={provided.innerRef}
                            style={
                              ind === 0
                                ? getMenuStyle(snapshot.isDraggingOver)
                                : getListStyle(snapshot.isDraggingOver)
                            }
                            {...provided.droppableProps}
                          >
                            <span
                              style={
                                ind !== 0
                                  ? dayStyle()
                                  : { backgroundColor: "#fff" }
                              }
                            >
                              {ind > 0 && !workoutPlanDetails[ind] && (
                                <label
                                  type="button"
                                  className="btn btn-primary btn-block"
                                  variant="danger"
                                  style={{
                                    width: "initial",
                                    background: "none",
                                    border: "none",
                                    color: "#e3e3e3",
                                    marginTop: 0,
                                  }}
                                  onClick={() => {
                                    setShowDetails(ind);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    size="1x"
                                    className=""
                                  />
                                </label>
                              )}
                              {ind === 0 ? (
                                <img
                                  src={Welnes}
                                  alt="welnes"
                                  style={{
                                    marginBottom: "1em",
                                    width: "65%",
                                    color: "#0e5171 !important",
                                  }}
                                />
                              ) : ind === moment().date() ? (
                                <span style={{ color: "#11bd87" }}>
                                  {moment().date(ind).format("D MMM")}
                                </span>
                              ) : (
                                moment().date(ind).format("D MMM")
                              )}

                              {ind > 0 && (
                                <label
                                  type="button"
                                  className="btn btn-primary btn-block"
                                  variant="danger"
                                  style={{
                                    width: "initial",
                                    background: "none",
                                    border: "none",
                                    color: "#e3e3e3",
                                    marginTop: 0,
                                    fontSize: ".9em",
                                  }}
                                  onClick={() => {
                                    const fromDay = ind; //Math.max(1, +prompt("Enter day you want to clone from"));
                                    const nDays = prompt(
                                      "Repeat after how many days"
                                    );
                                    if (!nDays) return;
                                    const toDay = ind + Math.max(1, +nDays);

                                    const newState = [...state];
                                    newState[toDay] = _.clone(
                                      newState[fromDay]
                                    );
                                    setState(newState);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faUndo}
                                    size="1x"
                                    className=""
                                  />
                                </label>
                              )}
                            </span>

                            {ind === 0 && (
                              <div>
                                <Form.Control
                                  placeholder="search"
                                  style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    direction: "ltr",
                                    display: "inline-block",
                                    marginLeft: "1em",
                                    borderRadius: "25px",
                                    margin: "5px 0 10px",
                                    backgroundColor: "#fff",
                                    border: "solid #f0f0f0",
                                    borderWidth: "1px",
                                  }}
                                  value={searchKey}
                                  onChange={(e) => {
                                    setSearchKey(e.target.value);
                                  }}
                                ></Form.Control>
                              </div>
                            )}

                            {ind > 0 &&
                              (workoutPlanDetails[ind] ||
                                showDetails === ind) && (
                                <Form.Control
                                  style={{
                                    fontSize: "13px",
                                    resize: "none",
                                    overflow: "hidden",
                                    borderRadius: "8px",
                                    padding: "5px",
                                    marginBottom: "10px",
                                    borderColor: "#a8a8a8",
                                    backgroundColor: "#fff",
                                  }}
                                  defaultValue={workoutPlanDetails[ind]}
                                  onChange={(e) => {
                                    setShowDetails(0);
                                    e.target.style.height = "";
                                    e.target.style.height =
                                      1.1 * e.target.scrollHeight + "px";
                                    var clone = _.clone(workoutPlanDetails);
                                    if (typeof clone !== "object") clone = {};
                                    clone[ind] = e.target.value;
                                    setWorkoutPlanDetails(clone);
                                  }}
                                  onClick={(e) => {
                                    setShowDetails(ind);
                                    e.target.style.height = "";
                                    e.target.style.height =
                                      1.1 * e.target.scrollHeight + "px";
                                  }}
                                  as="textarea"
                                />
                              )}

                            {el
                              .filter((item) => exercisesById[item.exercise])
                              .map(
                                (item, index) =>
                                  (ind > 0 || index < nItems) && (
                                    <Draggable
                                      key={
                                        item.exercise + "_" + ind + "_" + index
                                      }
                                      draggableId={
                                        item.exercise + "_" + ind + "_" + index
                                      }
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className={
                                            "exercise-item" +
                                            (exercisesById[item.exercise].name
                                              .trim()
                                              .toLowerCase() === "rest"
                                              ? " rest"
                                              : "")
                                          }
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            isSuperSet[ind + "_" + index],
                                            inSuperSet[ind + "_" + index]
                                          )}
                                          onDoubleClick={() => {
                                            if (ind == 0) return;
                                            if (!state[ind][index + 1]) return;

                                            const ss = Boolean(
                                              isSuperSet[ind + "_" + index]
                                            );

                                            const _isSuperSet =
                                              _.clone(isSuperSet);
                                            _isSuperSet[ind + "_" + index] =
                                              !ss;
                                            setIsSuperSet(_isSuperSet);

                                            const _inSuperSet =
                                              _.clone(inSuperSet);
                                            _inSuperSet[
                                              ind + "_" + (index + 1)
                                            ] = !ss;
                                            setInSuperSet(_inSuperSet);
                                          }}
                                        >
                                          <div
                                            className="exercise-label"
                                            title={
                                              exercisesById[item.exercise]
                                                .name +
                                              "\n" +
                                              (exercisesById[item.exercise]
                                                .level
                                                ? "level: " +
                                                  exercisesById[item.exercise]
                                                    .level +
                                                  " \n"
                                                : "") +
                                              (exercisesById[item.exercise]
                                                .muscle
                                                ? "muscle: " +
                                                  exercisesById[item.exercise]
                                                    .muscle +
                                                  " \n"
                                                : "") +
                                              (exercisesById[item.exercise].type
                                                ? "type: " +
                                                  exercisesById[item.exercise]
                                                    .type +
                                                  " \n"
                                                : "") +
                                              (exercisesById[item.exercise]
                                                .equipment
                                                ? "equipment: " +
                                                  exercisesById[item.exercise]
                                                    .equipment +
                                                  " \n"
                                                : "") +
                                              (exercisesById[item.exercise]
                                                .description
                                                ? "description: " +
                                                  exercisesById[item.exercise]
                                                    .description
                                                : "")
                                            }
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              whiteSpace: "nowrap", //, overflow: 'hidden'
                                            }}
                                          >
                                            <WorkoutCard
                                              ex={exercisesById}
                                              ind={ind}
                                              item={item}
                                              isSuperSet={isSuperSet}
                                              inSuperSet={inSuperSet}
                                              setState={setState}
                                              state={state}
                                              index={index}
                                              sideMenu={true}
                                              image={
                                                exercisesById[item.exercise]
                                                  .image
                                              }
                                              name={
                                                exercisesById[item.exercise]
                                                  .name
                                              }
                                              muscle={
                                                exercisesById[item.exercise]
                                                  .muscle
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                              )}

                            {ind === 0 &&
                              Object.keys(exercisesById).length === 0 && (
                                <div style={{ marginTop: "20px" }}>
                                  No Exercises Found
                                  <br />
                                  Please Add Exercises First
                                </div>
                              )}

                            {ind === 0 &&
                              nItems <
                                el.filter(
                                  (item) => exercisesById[item.exercise]
                                ) /*.filter(ind==0?filterFn:()=>true)*/
                                  .length && (
                                <div className="loader text-center">
                                  <Spinner
                                    animation="border"
                                    size={"sm"}
                                    role="status"
                                  />
                                </div>
                              )}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    )
                )}
              </DragDropContext>
            </div>
          </div>

          <div className="float-right">
            {props.workoutPlanData.key && (
              <Button
                variant="danger"
                style={{ borderRadius: "22px" }}
                className="submit-button mr-3 button button-disabled"
                onClick={() => {
                  onDeleteClick();
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="1x" className="" /> Delete
              </Button>
            )}

            {props.workoutPlanData.key && (
              <Button
                variant="dark"
                className="submit-button mr-3  button button-secondary"
                onClick={() => {
                  clonePlan();
                }}
              >
                {isCloneLoader ? (
                  <Spinner animation="border" size={"sm"} role="status" />
                ) : (
                  <FontAwesomeIcon icon={faClone} size="1x" className="" />
                )}{" "}
                Clone
              </Button>
            )}
            <Button
              style={{
                border: "none",
                borderRadius: "25px",
                padding: "9px 30px",
              }}
              type="submit"
              className=" mr-3 submit-button button-primary"
            >
              {isSubmitLoader ? (
                <Spinner animation="border" size={"sm"} role="status" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faCheck} size="1x" className="mr-2" />
                  Save
                </>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal show={showDeleteModal} onHide={handleDeleted}>
        <DeleteAlert
          onHide={handleDeleted}
          title={"Delete Workout Plan"}
          message={"Are you sure you want to delete workout plan?"}
          warning={"All the related exercises will be unassigned."}
          docId={docId}
          collectionName={"workout_plans"}
        />
      </Modal>
    </>
  );
}

export default AddWorkoutPlan2;
