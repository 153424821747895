import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import * as _ from "lodash";

export function RecipeFilter(props) {
    const totalDays = 30;
    const defaultFilter = {
        type: '',
        mealPlanId: '',
        mealPlanName: '',
        day: ''
    };
    const [filterData, setFilterData] = useState({});
    useEffect(() => {
        if (props.filterData) {
            setFilterData(props.filterData)
        } else {
            setFilterData(defaultFilter)
        }
    }, []);
    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();
        props.handleClose({ filterData });
    }
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Recipe Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="form" onSubmit={(e) => {
                    submitForm(e)
                }}>
                    <Form.Group controlId="name" defaultValue={filterData.name} onChange={e => {
                        const filterDataClone = _.clone(filterData);
                        filterDataClone.type = e.target.value;
                        setFilterData(filterDataClone);
                    }}>
                        <Form.Label>Type</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" placeholder="Select Type"
                                value={filterData.type} onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.type = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Type</option>
                                {props.recipeTypes.map((type, i) => {
                                    return (<option value={type} key={type}>{type}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>
                    {/*<Form.Group>
                        <Form.Label>Day</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" value={filterData.day} placeholder="Day"
                                onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.day = e.target.value;
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Day</option>
                                {Array.from(Array(totalDays), (a, i) => {
                                    return (<option value={i + 1} key={i + 1}>{i + 1}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Meal Plan</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" placeholder="Select a meal plan"
                                value={filterData.mealPlanId} onChange={e => {
                                    const filterDataClone = _.clone(filterData);
                                    filterDataClone.mealPlanId = e.target.value;
                                    // const index = props.mealPlans.findIndex((o) => {
                                    //     return o.key === filterData.mealPlanId;
                                    // });
                                    // if (index > -1) {
                                    //     filterDataClone.mealPlanName = props.mealPlans[index].name;
                                    // }
                                    setFilterData(filterDataClone);
                                }}>
                                <option value={''} key={''}>Select Meal Plan</option>
                                {props.mealPlans.map((mealPlan, i) => {
                                    return (
                                        <option value={mealPlan.key} key={mealPlan.key}>{mealPlan.name}</option>)
                                })}
                            </Form.Control>
                        </div>
                    </Form.Group>*/}
                    <div className="float-right">
                        <Button variant="danger" className="submit-button mr-3" onClick={() => {
                            props.handleClose({ filterData: {} })
                        }}>
                            Reset
                            </Button>
                        <Button variant="primary" type="submit" className="submit-button">
                            Apply
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </>
    );
}

export default RecipeFilter
