/** @format */

import React, { useEffect, useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { firestore as Firestone } from "../../services/firebase";

import { Breadcrumb, Button, Col, Spinner } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";
import * as _ from "lodash";
import "./ChallengesComponent.scss";

export default function OneToOneComponent(props) {
	const features = [
		{ key: "mealPlan", title: "Meal plan" },
		{ key: "workoutPlan", title: "Workout plan" },
	];

	const exerciseTypes = [
		{ key: "home", title: "Home workout" },
		{ key: "gym", title: "Gym workout" },
	];

	const [challengesLoader, setChallengesLoader] = useState(false);
	const [challenges, setChallenges] = useState([]);
	const [oneToOne, setOneToOne] = useState(false);
	const [ignore, setIgnore] = useState({});
	const [oneToOneUsers, setOneToOneUsers] = useState(0);

	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);

	useEffect(() => {
		if (!oneToOne) return;

		var users = Firestone("users")
			.where("joinedOneToOne." + oneToOne.key + ".to", ">", new Date())
			.limit(10000)
			.get()
			.then((snapshot) => {
				setOneToOneUsers(snapshot.size);
			});

		return () => {};
	}, [oneToOne]);

	const getChallenges = useCallback(() => {
		setChallengesLoader(true);

		var subscription = Firestone("one-to-one")
			.where("nutritionistId", "==", user.id)
			.onSnapshot((querySnapshot) => {
				let data = querySnapshot.docs.map((documentSnapshot, i) => {
					return {
						...documentSnapshot.data(),
						key: documentSnapshot.id,
					};
				});

				data = _.orderBy(
					data,
					(item) => moment.utc(item.date).format(),
					"desc"
				);

				var grouped = [],
					ignore = {};
				data.map((d) => {
					if (d.previousRounds) {
						d.rounds = d.previousRounds.length + 1;
						d.previousRounds.map((d) => {
							ignore[d] = true;
						});
						d.roundsIds = [d.key].concat(d.previousRounds);
					}
				});
				setIgnore(ignore);

				setChallengesLoader(false);
				setChallenges(data);
			});

		Firestone("one_to_one")
			.doc(user.id)
			.onSnapshot((querySnapshot, i) => {
				if (!querySnapshot.data()) return setOneToOne();

				let data = querySnapshot.data();
				data.key = querySnapshot.id;
				data.oneToOne = true;

				setChallengesLoader(false);
				setOneToOne(data);
			});

		return subscription;
	}, [user.id]);

	useEffect(() => {
		Firestone("users")
			.doc(user.id)
			.get()
			.then((doc) => {
				if (doc.exists) setUser(doc.data());
			});

		const mealPlanSubscription = getChallenges();
		return () => {
			mealPlanSubscription();
		};
	}, [getChallenges, user.id]);

	return (
		<>
			<div className={oneToOne ? "followup-section-one" : "followup-section"}>
				<div className="followup">
					<h4>
						1 to 1 follow up{" "}
						{oneToOne && (
							<span>
								<FontAwesomeIcon icon={faCheckCircle} size="sm" />
							</span>
						)}
					</h4>
					<p className="number-count">
						You have <span>{oneToOneUsers}</span> clients
					</p>
				</div>
				<div className={oneToOne ? "followup-details" : "add-program"}>
					{challengesLoader ? (
						<div className="text-center h-100 w-100">
							<Spinner animation="border" size={"lg"} role="status" />
						</div>
					) : challenges.concat(oneToOne ? [oneToOne] : []).length ? (
						challenges
							.concat(oneToOne ? [oneToOne] : [])
							.map((challenge, i) => {
								return (
									<>
										<Col key={i}>
											<div className="description">
												<h4>Description</h4>
												<p>{challenge.description}</p>
											</div>
										</Col>
										<Col>
											<div
												className="card"
												style={{ borderColor: "#000", height: "100%" }}
											>
												<div className="program features">
													<h6>Features</h6>
													<p style={{ fontWeight: "600" }}>
														{challenge.features &&
															Object.keys(challenge.features).map(
																(key) =>
																	challenge.features[key] &&
																	features.map((fkey) =>
																		fkey.key === key ? fkey.title + ", " : ""
																	)
															)}
													</p>
												</div>
												<div className="program program-types">
													<h6>program types</h6>
													<p style={{ fontWeight: "600" }}>
														{challenge.goal &&
															Object.keys(challenge.goal)
																.filter((key) => challenge.goal[key])
																.join(", ")}
													</p>
												</div>
												{challenge.features?.mealPlan === true && (
													<div className="program supported-meal-plans">
														<h6>supported meal plans</h6>
														<p style={{ fontWeight: "600" }}>
															{challenge.dietTypes &&
																Object.keys(challenge.dietTypes)
																	.filter((key) => challenge.dietTypes[key])
																	.join(", ")}
														</p>
													</div>
												)}
												{challenge.features?.workoutPlan === true && (
													<div className="program supported-workout-plans">
														<h6>supported workout plans</h6>
														<p style={{ fontWeight: "600" }}>
															{challenge.exerciseTypes &&
																Object.keys(challenge.exerciseTypes).map(
																	(key) =>
																		challenge.exerciseTypes[key] &&
																		exerciseTypes.map((ekey) =>
																			ekey.key === key ? ekey.title + ", " : ""
																		)
																)}
														</p>
													</div>
												)}
											</div>
										</Col>
										<div className="edit-one-to-one">
											<NavLink
												key={`/edit-oneToOne/${challenge.key}`}
												to={`/edit-oneToOne/${challenge.key}`}
												exact
												activeClassName={"link-active"}
											>
												<button className="button button-edit">
													<FontAwesomeIcon
														icon={faEdit}
														size="1x"
														style={{ margin: "0 5px" }}
													/>
													Edit
												</button>
											</NavLink>
										</div>
									</>
								);
							})
					) : (
						""
					)}

					{!oneToOne && !challengesLoader && (
						<Col>
							<div className="add-program">
								<NavLink
									key="/add-oneToOne"
									to="/add-oneToOne"
									exact
									activeClassName={"link-active"}
								>
									<button className="button button-secondary">
										+ Add Program
									</button>
								</NavLink>
							</div>
						</Col>
					)}
				</div>
			</div>
		</>
	);
}
