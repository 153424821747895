/** @format */

import React, { useState } from "react";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import EditBio from "./forms/EditBio";
function BioCard({ image, name, title, assistantName, bio, getUserData }) {
	const [modalShow, setModalShow] = useState(false);

	return (
		<div className="bio-card">
			<div className="bio-info">
				<div className="details">
					{/* <div
						className="background-img"
						id="bio-image"
						style={{
							backgroundImage: `url(${image})`,
						}}
					></div> */}
					<img className="background-img" id="bio-image" src={image} alt="" />
					<div>
						<h4 className="coach-name">
							<Link
								to={{
									pathname:
										"https://welnes.app/" +
										name.replaceAll(" ", ".") /*+'?src=coach'*/,
								}}
								target="_blank"
								rel="noopener"
							>
								{name}
							</Link>
						</h4>
						<p className="coach-title">{title}</p>
					</div>
				</div>
				<div className="assistant">
					<p className="assistant-name">Assistant Name:</p>
					<h5>{assistantName || `Team Dr. ${name.split(" ").slice(0, 1)}`}</h5>
				</div>
			</div>
			<div className="bio-description">
				<h5>Bio</h5>
				<p>{bio}</p>
			</div>
			<div className="edit-btn">
				<button
					className="button button-edit"
					onClick={() => setModalShow(true)}
				>
					<FontAwesomeIcon
						icon={faEdit}
						size="1x"
						style={{ margin: "0 5px" }}
					/>
					Edit
				</button>
				<EditBio
					show={modalShow}
					onHide={() => setModalShow(false)}
					setmodalshow={setModalShow}
					getUserData={getUserData}
				/>
			</div>
		</div>
	);
}

export default BioCard;
