/** @format */

import { useEffect, useRef, useState } from "react";
import { CloseButton, Modal, Spinner } from "react-bootstrap";
import * as _ from "lodash";
import moment from "moment";
import { firestore, storage } from "../../services/firebase";
import { fbImage } from "../../functions/helpers";
import "./EditBio.scss";

function EditBio(props) {
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("userDetails"))
	);
	const initFormValue = {
		image: "",
		name: "",
		nutritionistTitle: "",
		nutritionistBio: "",
		assistantName: "",
	};

	const [isImageChanged, setIsImageChanged] = useState(false);
	const [isSubmitLoader, setIsSubmitLoader] = useState(false);
	const [formValue, setFormValue] = useState(initFormValue);
	const [imageFile, setImageFile] = useState(false);
	const [imagePreview, setImagePreview] = useState("");
	const [titleError, setTitleError] = useState("");
	const imageUploader = useRef(null);

	useEffect(() => {
		getUserData();
		return () => {};
	}, []);

	async function getUserData() {
		const querySnapshot = await firestore("users").doc(user.id).get();

		setUser(querySnapshot.data());
		setFormValue({
			image: querySnapshot.data().image,
			nutritionistTitle: querySnapshot.data().nutritionistTitle,
			nutritionistBio: querySnapshot.data().nutritionistBio,
			assistantName: querySnapshot.data().assistantName,
		});
	}

	function parseFile(file) {
		var imageName = file.name;
		var isGood = true; ///\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
		if (isGood) {
			setImagePreview(URL.createObjectURL(file));
		}
	}

	async function fileSelectHandler(e) {
		// Fetch FileList object
		var files = e.target.files[0] || e.dataTransfer.files[0];

		try {
			const compressedFile = files; //await imageCompression(f, options);
			setImageFile(compressedFile);
			parseFile(compressedFile);
			const formValueClone = _.clone(formValue);
			formValueClone.image = compressedFile;
			setFormValue(formValueClone);
		} catch (error) {
			console.log("error fileSelectHandler", error);
		}

		setIsImageChanged(true);
	}

	function onImageUpload(e) {
		fileSelectHandler(e);
	}

	async function submitForm(e) {
		setIsSubmitLoader(true);
		e.stopPropagation();
		e.preventDefault();
		const dataToSubmit = {};
		const fileName =
			imagePreview.replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "_") +
			"-" +
			moment().unix();

		if (isImageChanged && imageFile) {
			const uploaded = await storage()
				.ref()
				.child(`/users/${fileName}`)
				.put(imageFile, { cacheControl: "public,max-age=31536000" });
			const child = await storage().ref().child(uploaded.metadata.fullPath);
			dataToSubmit.image = await child.getDownloadURL();
			dataToSubmit.fullPath = uploaded.metadata.fullPath;
			// user.image = formValue.image;
			document.getElementById("bio-image").src = dataToSubmit.image;
			document.getElementsByClassName("user-image")[0].src = dataToSubmit.image;
		}

		if (formValue.nutritionistTitle === "" || !formValue.nutritionistTitle) {
			e.stopPropagation();
			e.preventDefault();
			setmodalshow(true);
			setTitleError("Title is required");
		} else {
			dataToSubmit.nutritionistTitle = formValue.nutritionistTitle;
		}
		dataToSubmit.nutritionistBio = formValue.nutritionistBio || "";
		dataToSubmit.assistantName =
			formValue.assistantName === ""
				? `Team Dr. ${user.name.split(" ").slice(0, 1)}`
				: formValue.assistantName;
		await firestore("users").doc(user.id).update(dataToSubmit);

		//update nutritionist image in one to one program
		var oneToOne = await firestore("one_to_one").doc(user.id).get()
		if (oneToOne.exists && dataToSubmit.image) await firestore("one_to_one").doc(user.id).update({nutritionistImage: dataToSubmit.image});

		setIsSubmitLoader(false);

	}
	const { setmodalshow, ...rest } = props;
	return (
		<Modal
			{...rest}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop="static"
		>
			<Modal.Body>
				<CloseButton
					onClick={() => {
						setmodalshow(false);
						setFormValue(getUserData);
					}}
				/>
				<form onSubmit={(e) => submitForm(e)}>
					<div className="edit-bio">
						<div id="info">
							<h4>Coach Info</h4>
						</div>
						<div id="coach-img">
							<div className="change-img">
								<div
									className="button button-primary"
									onClick={() => imageUploader.current.click()}
								>
									Change
								</div>
								<div
									className="background-img"
									style={{
										backgroundImage: `url(${fbImage(
											imagePreview || user.image
										)})`,
									}}
								></div>
								<input
									ref={imageUploader}
									id="file-upload"
									type="file"
									name="fileUpload"
									accept="image/*"
									onChange={(e) => onImageUpload(e)}
									style={{
										display: "none",
									}}
								/>
							</div>
						</div>

						<div className="coach-name">
							<label htmlFor="coach-name">Name</label>
							<input id="coach-name" type="text" value={user.name} disabled />
						</div>
						<div className="coach-title">
							<label htmlFor="coach-title">
								{titleError ? (
									<span className="text-danger">{titleError}</span>
								) : (
									"Title"
								)}
							</label>
							<input
								id="coach-title"
								type="text"
								placeholder="e.g Certified Fitness Coach"
								value={formValue.nutritionistTitle}
								onChange={(e) => {
									setTitleError(null);
									const formValueClone = _.clone(formValue);
									formValueClone.nutritionistTitle = e.target.value;
									setFormValue(formValueClone);
								}}
							/>
						</div>

						<div id="coach-assistant">
							<label htmlFor="assistant">Assistant Name</label>
							<input
								type="text"
								placeholder="e.g Team Welnes"
								value={formValue.assistantName}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.assistantName = e.target.value;
									setFormValue(formValueClone);
								}}
							/>
						</div>
						<div id="coach-bio">
							<label htmlFor="name">Bio</label>
							<textarea
								name="bio"
								id="bio"
								placeholder="brief biography"
								value={formValue.nutritionistBio}
								onChange={(e) => {
									const formValueClone = _.clone(formValue);
									formValueClone.nutritionistBio = e.target.value;
									setFormValue(formValueClone);
								}}
							></textarea>
						</div>
						<button
							type="submit"
							onClick={() => {
								setmodalshow(false);
								props.getUserData();
							}}
							className="button button-primary"
						>
							{" "}
							{isSubmitLoader ? (
								<Spinner animation="border" size={"sm"} role="status" />
							) : (
								"Save"
							)}
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
}

export default EditBio;
