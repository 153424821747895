import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Form,
    Modal,
    ButtonToolbar,
    Table,
    DropdownButton,
    Dropdown,
    Spinner,
    Breadcrumb
} from "react-bootstrap";
import Masonry from 'react-masonry-css'

import firebase from 'firebase/compat/app';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faFilter, faPencilAlt, faCopy, faTrashAlt, faArrowUp, faArrowDown, faUndo, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { confirm } from "../../components/Confirmation";
import { firestore } from "../../services/firebase";
import AddPromocode from '../../components/AddPromocode';
import * as _ from "lodash";
import DeleteAlert from '../../components/DeleteAlert';
import moment from 'moment';

library.add(faPlus, faFilter);

export function Requests() {
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [requestsLoader, setRequestsLoader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [docId, setDocId] = useState('');
    const [sort, setSort] = useState({
        field: 'datetime',
        direction: 'desc'
    });

    const tableHeaders = [{
        name: 'Name',
        filterField: 'name',
    //}, {
    //    name: '',
    //    filterField: 'none',
    }, {
        name: 'Date',
        filterField: 'datetime',
    }, {
        name: 'Type',
        filterField: 'type',
    }, {
        name: 'Kcal',
        filterField: 'calories',
    //}, {
    //    name: 'Actions',
    //    filterField: 'none',
    }];
    const [transactions, setTransactions] = useState([]);
    const [requests, setRequests] = useState([]);
    const [promocodeData, setPromocodeData] = useState({});
    const [filterData, setFilterData] = useState({});
    const [showPromocode, setShowPromocode] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [promocodeIdToName, setPromocodeIdToName] = useState();
    const [challengeIdToName, setChallengeIdToName] = useState();

    useEffect(() => {
        const RequestsSubscription = getRequests();
        return () => {
            RequestsSubscription();
        };
    }, []);


    function getRequests() {
        setRequestsLoader(true);
        return firestore('users')
            .where('nutritionist', 'in', ['pending', 'approved', 'rejected'])
            .onSnapshot((querySnapshot) => {
                var promocodeIdToName = {}
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id
                    };
                });console.log(data)
                setRequestsLoader(false);
                data.map(d => {if(d.nutritionistDatetime) d.datetime = d.nutritionistDatetime; return d})
                setRequests(_.orderBy(data, 'datetime', 'desc'));
            });
    }

    async function approve(d){

        if (await confirm(
            'Approve'+' '+d.name.split(' ')[0],
            'are you sure you want to '+'approve'+' '+d.name.split(' ')[0]+' ?'
            , 'approve')){
            
            await firestore('users').doc(d.key).update({nutritionist: 'approved', role: firebase.firestore.FieldValue.arrayUnion('nutritionist')});
        }
    }

    async function reject(d){

        if (await confirm(
            'Reject'+' '+d.name.split(' ')[0],
            'are you sure you want to '+'reject'+' '+d.name.split(' ')[0]+' ?'
            , 'reject')){
            
            await firestore('users').doc(d.key).update({nutritionist: 'rejected', role: firebase.firestore.FieldValue.arrayRemove('nutritionist')});
        }
    }

    return (
        <>
            <Breadcrumb className="pb-3">
                <Breadcrumb.Item active>Requests</Breadcrumb.Item>
            </Breadcrumb>
            <div className="pt-5">
                <Card>
                    <Card.Header>
                        <h3 className="m-0 d-flex justify-content-between">
                            Join requests ({requests.length})
                            {/*<Button size='sm' variant="info" className="text-uppercase" onClick={() => {
                                addMealPlan();
                            }}>
                                <FontAwesomeIcon icon={faPlus} size="1x" className="mr-2" />Add promo code
                            </Button>*/}
                        </h3>
                    </Card.Header>
                    <Card.Body>

                        {requestsLoader ? <div className="text-center" style={{padding: '100px'}}><Spinner animation="border" size={'lg'} role="status" style={{height: '100px', width: '100px'}}/></div> :  
                        <table id="requests" class="table components" style={{fontSize: '1em', marginTop: '2em', marginBottom: '0'}}>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Title</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th>Certificates</th>
                                <th>Status</th>
                                <th colspan="2" style={{textAlign: 'right'}}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {requests.map(d => <tr style={(d.nutritionist === 'approved' || d.nutritionist === 'rejected')?{color: '#bbb', height: '50px'}:{height: '50px'}}>
                                <td>{(d.nutritionistDatetime || d.datetime).split('T')[0]}</td>
                                <td>{d.name}</td>
                                <td className="request-title">{d.nutritionistTitle}</td>
                                <td>{d.mobileNumber}</td>
                                <td><Button style={{fontSize: '.9em', color:'#555'}} variant="light" onClick={()=>{navigator.clipboard.writeText(d.email);}}><FontAwesomeIcon icon={faCopy} style={{marginRight: '.5em'}}/>{d.email}</Button></td>
                                <td className="request-certificates">{(d.certificates||[]).map(d=> <img src={d} height="auto" alt="" style={{height:'50px', cursor: 'pointer', marginRight: '7px', borderRadius: '5px'}} onClick={()=>window.open(d)}/>)}</td>
                                <td style={d.nutritionist === 'approved'?{color: 'green'}:d.nutritionist === 'rejected'?{color: 'red'}:{color: '#bd8a2d'}}>{d.nutritionist}</td>
                                <td style={d.nutritionist === 'rejected'?{textAlign: 'right', color: 'red', pointerEvents: 'none'}:{opacity:0.1, transition: '.3s', textAlign: 'right', color: 'red', cursor: 'pointer'}} className="remove" onClick={()=>{reject(d)}}><FontAwesomeIcon icon={faTimes} /></td>
                                <td style={d.nutritionist === 'approved'?{textAlign: 'right', color: 'green', pointerEvents: 'none'}:{opacity:0.1, transition: '.3s', textAlign: 'right', color: 'green', cursor: 'pointer'}} className="remove" onClick={()=>{approve(d)}}><FontAwesomeIcon icon={faCheck} /></td>
                            </tr>)}
                        </tbody>
                        </table>}

                    </Card.Body>
                </Card>
                {/*<Modal show={showPromocode} onHide={handleCloseMealPlan}>
                    <AddPromocode handleClose={handleCloseMealPlan} promocodeData={promocodeData} Requests={Requests} />
                </Modal>*/}
            </div>
            {/*<Modal show={showDeleteModal} onHide={handleDeleted}>
                <DeleteAlert onHide={handleDeleted} title={'Delete Meal Plan'} message={'Are you sure you want to delete meal plan?'} warning={'All the related recipes will be unassigned.'} docId={docId} collectionName={'meal_plans'} />
            </Modal>*/}
        </>
    )
}
