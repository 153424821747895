

import React, {useEffect, useState} from 'react';
import { Spinner, Breadcrumb, Image, Button, ToggleButton, Col, Row, Form, Tabs, Tab, Card, Modal } from "react-bootstrap";
import Chart from "react-apexcharts";

import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUndo, faDoorOpen, faSave, faEdit, faCheckSquare} from '@fortawesome/free-solid-svg-icons'

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file



export function Funnel(props) { 

    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState({});
    const [funnel, setFunnel] = useState({});
    const [challengeIdToName, setChallengeIdToName] = useState({});
    const [nutritionistsIdToName, setNutritionistsIdToName] = useState({});
    const [format, setFormat] = useState('MMM DD');  //YYYY-MM-DD
    const [byChallenge, setByChallenge] = useState({});
    const [byProgram, setByProgram] = useState({});
    const [bySrc, setBySrc] = useState({});
    const [byProduct, setByProduct] = useState({});
    const [challengeName, setChallengeName] = useState({});
    const [programName, setProgramName] = useState({});
    const [selectedChallenge, setSelectedChallenge] = useState();
    const [selectedProgram, setSelectedProgram] = useState();
    const [selectedSrc, setSelectedSrc] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [challenges, setChallenges] = useState([]);
    const [programs, setPrograms] = useState([]);

    useEffect(() => {

        getChallenges();
        getNutritionists();
        getCollections();

    }, []);


    function getNutritionists() {
        return firestore('users').where('role', 'array-contains', 'nutritionist')
            .get().then((querySnapshot) => {
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id,
                    };
                });
                var _nutritionistsIdToName = {}
                data.map((plan)=>{_nutritionistsIdToName[plan.key] = plan.name})
                setNutritionistsIdToName(_nutritionistsIdToName)
            });
    }

    function getChallenges() {
        return firestore('challenges')
            //.where('nutritionistId', '==', userDetails.id)
            .onSnapshot((querySnapshot) => {
                let data = querySnapshot.docs.map((documentSnapshot, i) => {
                    return {
                        ...documentSnapshot.data(),
                        key: documentSnapshot.id,
                    };
                });
                var _challengeIdToName = {}
                data.map((plan)=>{_challengeIdToName[plan.key] = plan.name})
                setChallengeIdToName(_challengeIdToName)
            });
    }

    function getCollections(){

        setLoading(true)

        const subscribe = firestore("events")
            .where('created', '>=', moment().subtract(1, 'week').startOf().toDate())
            //.where('datetime', '<=', moment('2020-09-01').format())
            .orderBy('created', 'desc')
            //.limit(100)
            .onSnapshot(function(snapshot){
            //.get().then(function(snapshot){

            var byDay = {}
            var bySrc = {}
            var byProduct = {}
            var byChallenge = {}
            var byProgram = {}
            var challengeName = {}
            var programName = {}

            const events = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            events.map(e => {

                e.title = (e.box && 'box') || (e.nutritionist && e.nutritionist.name) || (e.challenge && e.challenge.name) || (e.user && e.user.type) || e.title || ''
            })


            var type = {}
            events.map(e => {

                if(e.challenge && (e.page=='challenge' || e.page=='program')) {type[e.challenge.id] = e.page; e.product = e.page}
                if(e.page == 'box' || e.box) e.product = 'box'
            })

            //var pages = ['challenges', 'nutritionist', 'challenge', 'program', 'checkout', 'payment', 'success']

            events.map(e => {

                const day = moment(e.datetime).format(format);
                if(moment(e.datetime) < moment().subtract(1, 'week').startOf()) return
                if(!byDay[day]) byDay[day] = {day: day, challenges: [], nutritionist: [], box: [], challenge: [], program: [], login: [], signin: [], signup: [], checkout: [], payment: [], success: []};
                if(byDay[day][e.page] && !byDay[day][e.page].includes(e.user?e.user.id:e.id)) byDay[day][e.page].push(e.user?e.user.id:e.id);
                if(e.page === 'payment' && e.success === true){
                    if(!byDay[day].success.includes(e.user.id)) byDay[day].success.push(e.user.id)
                }

                if(e.product){

                    var by = byProduct

                    if(!by[e.product]) by[e.product] = {};
                    if(!by[e.product][day]) by[e.product][day] = {day: day, challenges: [], nutritionist: [], box: [], challenge: [], program: [], login: [], signin: [], signup: [], checkout: [], payment: [], success: []};
                    if(by[e.product][day][e.page] && !by[e.product][day][e.page].includes(e.user?e.user.id:e.id)) by[e.product][day][e.page].push(e.user?e.user.id:e.id);
                    if(e.page === 'payment' && e.success === true){
                        if(!by[e.product][day].success.includes(e.user.id)) by[e.product][day].success.push(e.user.id)
                    } 
                }

                if(e.challenge){

                    challengeName[e.challenge.id] = e.challenge.name

                    var by = type[e.challenge.id] === 'challenge'? byChallenge : byProgram

                    if(!by[e.challenge.id]) by[e.challenge.id] = {};
                    if(!by[e.challenge.id][day]) by[e.challenge.id][day] = {day: day, challenges: [], nutritionist: [], box: [], challenge: [], program: [], login: [], signin: [], signup: [], checkout: [], payment: [], success: []};
                    if(by[e.challenge.id][day][e.page] && !by[e.challenge.id][day][e.page].includes(e.user?e.user.id:e.id)) by[e.challenge.id][day][e.page].push(e.user?e.user.id:e.id);
                    if(e.page === 'payment' && e.success === true){
                        if(!by[e.challenge.id][day].success.includes(e.user.id)) by[e.challenge.id][day].success.push(e.user.id)
                    } 
                }

                if(e.src){

                    var by = bySrc

                    if(!by[e.src]) by[e.src] = {};
                    if(!by[e.src][day]) by[e.src][day] = {day: day, challenges: [], nutritionist: [], box: [], challenge: [], program: [], login: [], signin: [], signup: [], checkout: [], payment: [], success: []};
                    if(by[e.src][day][e.page] && !by[e.src][day][e.page].includes(e.user?e.user.id:e.id)) by[e.src][day][e.page].push(e.user?e.user.id:e.id);
                    if(e.page === 'payment' && e.success === true){
                        if(!by[e.src][day].success.includes(e.user.id)) by[e.src][day].success.push(e.user.id)
                    } 
                }
            })

            setEvents(events)
            setFunnel(Object.values(byDay));
            setByChallenge(byChallenge);
            setByProgram(byProgram);
            setBySrc(bySrc);
            setByProduct(byProduct);
            setChallengeName(challengeName);
            setProgramName(programName);
            setChallenges(Object.keys(byChallenge));
            setPrograms(Object.keys(byProgram));

            setLoading(false)
        })

        return [subscribe]
    }


   return (<>

        <Breadcrumb className="pb-3" style={{height: '7em'}}>
            <Breadcrumb.Item active>Conversion funnel</Breadcrumb.Item>
        </Breadcrumb>

        {loading ? <div style={{margin: '5em 10em'}}><Spinner animation="border" size={'lg'} role="status" /></div> :
        <div>
            <h4>all programs</h4>
            <div style={{padding: '2em 1em'}}>
                <Button type="submit" variant="light" style={{margin: '.3em'}} className="edit-save funnel-btn" onClick={()=>{setSelectedChallenge();setSelectedProgram();setSelectedSrc();setSelectedProduct();}}>All programs</Button>
                <Button type="submit" variant="light" style={{margin: '.3em'}} className="edit-save funnel-btn" onClick={()=>{setSelectedChallenge();setSelectedProgram();setSelectedSrc();setSelectedProduct('box');}}>Box</Button>
            </div>
            <h4>challenges</h4>
            <div style={{padding: '2em 1em'}}>
                {challenges.map(d => <Button className='funnel-btn' type="submit" variant="light" style={{margin: '.3em'}} onClick={()=>{setSelectedChallenge(d);setSelectedProgram();setSelectedSrc();setSelectedProduct();}}>{challengeName[d]}</Button>)}
            </div>
            <h4>one to one</h4>
            <div style={{padding: '2em 1em'}}>
                {programs.map(d => <Button className='funnel-btn' type="submit" variant="light" style={{margin: '.3em'}} onClick={()=>{setSelectedChallenge();setSelectedProgram(d);setSelectedSrc();setSelectedProduct();}}>{challengeName[d]}</Button>)}
            </div>
            <h4>source</h4>
            <div style={{padding: '2em 1em'}}>
                <Button className='funnel-btn' type="submit" variant="light" style={{margin: '.3em'}} onClick={()=>{setSelectedChallenge();setSelectedProgram();setSelectedSrc('facebook');setSelectedProduct();}}>facebook</Button>
            </div>
        </div>
        }

        <h3 style={{marginTop: '1em'}}>Conversion</h3>

        {loading ? <div style={{margin: '10em'}}><Spinner animation="border" size={'lg'} role="status" /></div> :
        <table id="funnel" class="table" style={{marginTop: '2em'}}>
            <thead>
            <tr>
                <th>Day</th>
                <th>Challenges</th>
                <th>Nutritionist</th>
                <th>Box</th>
                <th>Challenge</th>
                <th>One to one</th>
                <th>Login</th>
                <th>Signin</th>
                <th>Signup</th>
                <th>Checkout</th>
                <th>Payment</th>
                <th>Success</th>
            </tr>
            </thead>
            <tbody>
            {(selectedProduct? Object.values(byProduct[selectedProduct]) : selectedSrc? Object.values(bySrc[selectedSrc]) : selectedChallenge? Object.values(byChallenge[selectedChallenge]) : selectedProgram? Object.values(byProgram[selectedProgram]) : funnel).map(d => <tr>
                <td>{d.day}</td>
                <td>{d.challenges.length}</td>
                <td>{d.nutritionist.length}</td>
                <td>{d.box.length}</td>
                <td>{d.challenge.length}</td>
                <td>{d.program.length}</td>
                <td>{d.login.length}</td>
                <td>{d.signin.length}</td>
                <td>{d.signup.length}</td>
                <td>{d.checkout.length} <span style={{opacity: .5, marginLeft: '1em'}}>({d.checkout.length/(d.challenge.length + d.program.length + d.box.length)? ((d.checkout.length/(d.challenge.length + d.program.length + d.box.length))*100).toFixed(0)+'%' : '0%'})</span></td>
                <td>{d.payment.length} <span style={{opacity: .5, marginLeft: '1em'}}>({d.payment.length/d.checkout.length? ((d.payment.length/d.checkout.length)*100).toFixed(0)+'%' : '0%'})</span></td>
                <td>{d.success.length} <span style={{opacity: .5, marginLeft: '1em'}}>({d.success.length/d.payment.length? ((d.success.length/d.payment.length)*100).toFixed(0)+'%' : '0%'})</span></td>
            </tr>)}
        </tbody>
        </table>}

        <h3 style={{marginTop: '5em'}}>Events</h3>

        {loading ? <div style={{margin: '10em'}}><Spinner animation="border" size={'lg'} role="status" /></div> :
        <table id="funnel" class="table" style={{marginTop: '3em'}}>
            <thead>
            <tr>
                <th>Date</th>
                <th>Page</th>
                <th>Title</th>
                <th>User</th>
            </tr>
            </thead>
            <tbody>
            {(selectedProduct? events.filter(e=> e.product === selectedProduct) : selectedSrc? events.filter(e=> e.src === selectedSrc) : selectedChallenge ? events.filter(e=> e.challenge && e.challenge.id === selectedChallenge) : selectedProgram ? events.filter(e=> e.challenge && e.challenge.id === selectedProgram) : events).map(d => <tr>
                <td>{d.datetime.split('T')[0]} <span style={{opacity: .5, marginLeft: '1em'}}>{moment(d.datetime).format('hh:mm')}</span></td>
                <td>{d.page}</td>
                <td>{d.title}</td>
                <td>{d.user?d.user.name:''}</td>
            </tr>)}
        </tbody>
        </table>}

    </>);
}