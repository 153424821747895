

import React, {useEffect, useState} from 'react';
import { Spinner, Breadcrumb, Image, Button, ToggleButton, Form } from "react-bootstrap";
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts'

import { firestore } from "../../services/firebase";
import moment from 'moment';
import * as _ from "lodash";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import getDollarAtTime from '../../constants/DollarRate';


let count = 0;
function numberWithCommas(x) {
    return x.toLocaleString()//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

var barTotal = {}
const initData = {
  
    series: [/*{
      name: 'Marine Sprite',
      data: [44, 55, 41, 37, 22, 43, 21]
    }, {
      name: 'Striking Calf',
      data: [53, 32, 33, 52, 13, 43, 32]
    }, {
      name: 'Tank Picture',
      data: [12, 17, 11, 9, 15, 11, 20]
    }, {
      name: 'Bucket Slope',
      data: [9, 7, 5, 8, 6, 9, 4]
    }, {
      name: 'Reborn Kid',
      data: [25, 12, 19, 32, 25, 24, 10]
    }*/],
    options: {
      chart: {
        id: 'payments-chart',
        type: 'bar',
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      stroke: {
        width: 1,
        colors: ['#464646']
      },
      //title: {
      //  text: 'Challenges'
      //},
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'center', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return (val/1000).toFixed(0)//+'k'
          },
          //offsetY: -20,
          style: {
            fontWeight: 'bold',
            fontSize: '9px',
            colors: ["#304758"]
          }
        },
      xaxis: {
        categories: [/*2008, 2009, 2010, 2011, 2012, 2013, 2014*/],
        labels: {
          //formatter: function (val) {
          //  return val + "K"
          //}
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
        labels: {
          formatter: function (val) {
            return numberWithCommas(+val.toFixed(0))
          }
        }
      },
      tooltip: {
        x: {
          //formatter: function (val) {
          //  return val + ' ..... ' + numberWithCommas(barTotal[val].toFixed(0))
          //}
        },
        y: {
          formatter: function (val) {
            return numberWithCommas(val.toFixed(0))
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
        onItemClick: {
            toggleDataSeries: false
        },
        onItemHover: {
            highlightDataSeries: true
        },
      }
    },
  
  
  };



export function Payments(props) { 


    const [selectedChallenge, setSelectedChallenge] = useState();
    const [paymentsChallenges, setPaymentsChallenges] = useState([]);
    const [uniqueUsers, setUniqueUsers] = useState(false);
    const [period, setPeriod] = useState('daily');
    const [value, setValue] = useState('amounts');
    const [users, setUsers] = useState('perChallenge');
    const [currency, setCurrency] = useState('EGP');
    const [breakdown, setBreakdown] = useState(true);
    const [days, setDays] = useState([]);
    const [data, setData] = useState(initData);
    const [total, setTotal] = useState(0);
    const [egpTotal, setEGPTotal] = useState(0);
    const [usdTotal, setUSDTotal] = useState(0);
    const [loader, setLoader] = useState(true);
    const [payments, setPayments] = useState([]);
    const [challengeList, setChallengeList] = useState({});
    const [challengeById, setChallengeById] = useState({});
    const [challengeNutritionist, setChallengeNutritionist] = useState({});
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [challenges, setChallenges] = useState([]);
    const [challengesLoading, setChallengesLoading] = useState(true);
    const [format, setFormat] = useState('DD MMM YY');  //YYYY-MM-DD
    const [formatWeek, setFormatWeek] = useState('WW YYYY');  //YYYY-MM-DD
    const [formatMonth, setFormatMonth] = useState('MMM YY');  //YYYY-MM-DD

    const [nutritionists, setNutritionists] = useState({
      options: {},
      series: [],
    });


    const [selectionRange, setSelectionRange] = useState({
      startDate: period==='weekly'? moment().subtract(180, "days").startOf('day').toDate() : period==='monthly'? moment().subtract(180, "days").startOf('day').toDate() : moment().subtract(45, "days").startOf('day').toDate(), //moment('2020-04-19').toDate()
      endDate: new Date(),
      key: 'selection',
    });
    const [preSelectionRange, setPreSelectionRange] = useState({
      startDate: period==='weekly'? moment().subtract(180, "days").startOf('day').toDate() : period==='monthly'? moment().subtract(180, "days").startOf('day').toDate() : moment().subtract(45, "days").startOf('day').toDate(), //moment('2020-04-19').toDate()
      endDate: new Date(),
      key: 'selection',
    });

    useEffect(() => {

        setSelectionRange({
          startDate: period==='weekly'? moment().subtract(180, "days").startOf('day').toDate() : period==='monthly'? moment().subtract(180, "days").startOf('day').toDate() : moment().subtract(45, "days").startOf('day').toDate(),
          endDate: new Date(),
          key: 'selection',
        })
        setPreSelectionRange({
          startDate: period==='weekly'? moment().subtract(180, "days").startOf('day').toDate() : period==='monthly'? moment().subtract(180, "days").startOf('day').toDate() : moment().subtract(45, "days").startOf('day').toDate(),
          endDate: new Date(),
          key: 'selection',
        })

        return () => { }

    }, [period]);

    useEffect(() => {

        getChallenges();

    }, []);

    useEffect(() => {

        if(!Object.keys(challengeById).length) return

        setLoader(true)
        var unsubscribes = getCollections();

        return () => {

            console.log('Do some cleanup');
            unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, [challengeById, selectionRange, value, users, currency, selectedChallenge, breakdown]);


    async function getChallenges() {
        setChallengesLoading(true);

        var querySnapshot = await firestore('challenges').get()
        var querySnapshot2 = await firestore('one_to_one').get()

        let data = querySnapshot.docs.concat(querySnapshot2.docs).map((documentSnapshot, i) => {
            return {
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
            };
        });

        data.map(d => {if(!d.name) d.name = 'one to one with '+d.nutritionistName})

        //_.orderBy(groups, 'userCount', 'desc')
        //data = _.orderBy(data.filter(d => d.isPublished && !d.isFree), 'price', 'desc')

        var include = []
        data.map(d => {if(d.isPublished && !d.isFree && d.previousRounds) include = include.concat(d.previousRounds); return d})

        data = _.orderBy(data.filter(d => (d.isPublished || include.includes(d.key)) && !d.isFree), item => moment.utc(item.date).format(), 'desc')

        //add box
        data.push({
          key: 'box',
          name: 'box',
          nutritionistName: 'box'
        })
        
        var challengeById = {}, challengeNutritionist = {}
        data.map(d => {

            challengeById[d.key] = d
            challengeNutritionist[d.key] = d.nutritionistName
        })
        setChallengeById(challengeById)
        setChallengeNutritionist(challengeNutritionist)

        setChallenges(data);
        setChallengesLoading(false);
        //if (data && data.length && data[0].key) {
        //    setSelectedChallenge(data[0].key)
        //}
    }


    function getCollections(){ 

        setTotal(0)
        setEGPTotal(0)
        setUSDTotal(0)

        setPaymentsLoading(true)

        const subscribePayments = (selectedChallenge&&selectedChallenge!=='all'? firestore("payments").where('challengeId', '==', selectedChallenge) : props.box? firestore("payments").where('boxes', 'in', [1, 2, 4]): firestore("payments"))//.orderBy('datetime', 'asc')
            .where('datetime', '>', moment(selectionRange.startDate).format())
            //.where('datetime', '<', moment(selectionRange.endDate).format())  //ignore to enable relatime updates
            .onSnapshot(function(snapshot){
            //.get().then(function(snapshot){

            const payments = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

            //setPayments(payments)

            var total = 0, egpTotal = 0, usdTotal = 0
            var paymentsByDay = {}
            var paymentsByChallenge = {}
            var paymentsByNutritionist = {}
            var _challengeList = {}

            var userIds = {}

            payments.filter(d => !d.declined).map((d) => {

                if(d.boxes) d.challengeId = 'box'
                if(!challengeById[d.challengeId]) return console.error('missing challenge', d.challengeId)
                const challenge = !breakdown? 'payments':challengeById[d.challengeId].name + (challengeById[d.challengeId].previousRounds? ' - round '+(challengeById[d.challengeId].previousRounds.length+1):'')
                const date = moment(d.datetime).format(period==='weekly'? formatWeek : period==='monthly'? formatMonth : format)

                if(!paymentsByChallenge[challenge]) paymentsByChallenge[challenge] = {}
                if(!paymentsByChallenge[challenge][date]) paymentsByChallenge[challenge][date] = {users: 0, amount: 0}

                //fix for ramadan challenge
                if(d.challengeId === 'zNqJyCWb8ztMJLkt6zWy') d.amount = 300

                if(!_challengeList[d.challengeId]) _challengeList[d.challengeId] = challenge

                const dollarRate_iap = 16.67

                const dollarRate = getDollarAtTime(d.datetime);
                if(d.currency=='EGP') egpTotal += d.amount
                else usdTotal += d.amount

                if(currency==='EGP'){ 
                  if(d.type === 'credits') d.amount *= dollarRate_iap;
                  else if(d.currency === 'USD') d.amount *= dollarRate;
                }
                else if(currency==='USD'){ 
                  if(d.currency === 'EGP') d.amount /= dollarRate;
                }

                if(users==='uniqueUsers'){ 
                  if(!userIds[d.userId]) paymentsByChallenge[challenge][date].users++
                  userIds[d.userId] = true
                } 
                else paymentsByChallenge[challenge][date].users++
                paymentsByChallenge[challenge][date].amount+= d.amount

                /*if(!paymentsByDay[day]) paymentsByDay[day] = {day: day, user: {}, users: 0, meals: 0, workout: 0, water: 0, waterDone: 0}
                paymentsByDay[day].user[d.userId] = true
                paymentsByDay[day].water += d.isDrinkWaterCompleted? 1 : 0
                paymentsByDay[day].workout += d.isWorkoutCompleted? 1 : 0
                paymentsByDay[day].waterDone += d.glassOfWaterCount
                if(d.Breakfast) paymentsByDay[day].meals ++ */

                if(d.datetime > moment(selectionRange.startDate).format() && d.datetime < moment(selectionRange.endDate).format()){
                    
                  if(!paymentsByNutritionist[challengeById[d.challengeId].nutritionistId]) 
                    paymentsByNutritionist[challengeById[d.challengeId].nutritionistId] = {amount: 0, nutritionistName : challengeById[d.challengeId].nutritionistName}
                    paymentsByNutritionist[challengeById[d.challengeId].nutritionistId].amount += d.amount
                }
            })


            setNutritionists({...nutritionists, ...{
              options: {
                labels: Object.keys(paymentsByNutritionist).map(d => paymentsByNutritionist[d].nutritionistName),
                tooltip: { y: { formatter: val => numberWithCommas(val.toFixed(0)) }}
              },
              series: Object.keys(paymentsByNutritionist).map(d => paymentsByNutritionist[d].amount)
            }})


            var a = moment(selectionRange.startDate);
            var b = moment(selectionRange.endDate);
            var days = []

            // If you want an inclusive end date (fully-closed interval)
            //for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {

            // If you want an exclusive end date (half-open interval)
            for (var m = moment(a); m.isBefore(b); m.add(1, 'days')) {
              days.push(m.format(format))
            }

            if(period==='weekly'){ days = [];

              for (var m = moment(a); m.isBefore(b); m.add(1, 'weeks')) {
                days.push(m.format(formatWeek))
              }
            }

            if(period==='monthly'){ days = [];

              for (var m = moment(a); m.diff(b, 'months') <= 0; m.add(1, 'months')) {
              //for (var m = moment(a); m.isBefore(b); m.add(1, 'months')) {
                days.push(m.format(formatMonth))
              }

              days.shift()
            }


            console.log(days)
            console.log(paymentsByChallenge)

            barTotal = {}
            Object.keys(paymentsByChallenge).map(key => {

                var values = []
                days.map(day => {

                    if(value==='users'){

                      values.push(paymentsByChallenge[key][day]? paymentsByChallenge[key][day].users : 0)
                      total+= paymentsByChallenge[key][day]? paymentsByChallenge[key][day].users : 0
                      if(!barTotal[day]) barTotal[day] = 0
                      barTotal[day] += paymentsByChallenge[key][day]? paymentsByChallenge[key][day].users : 0
                    }
                    else{

                      values.push(paymentsByChallenge[key][day]? paymentsByChallenge[key][day].amount : 0)
                      total+= paymentsByChallenge[key][day]? paymentsByChallenge[key][day].amount : 0
                      if(!barTotal[day]) barTotal[day] = 0
                      barTotal[day] += paymentsByChallenge[key][day]? paymentsByChallenge[key][day].amount : 0
                    }
                })

                paymentsByChallenge[key].values = values
             })

            var _data = _.clone(initData)
            _data.options.xaxis.categories = days
            //setDays(days)
            _data.series = Object.keys(paymentsByChallenge).map(key => {return {name: key, data: paymentsByChallenge[key].values}})



            _data.options.dataLabels.formatter = value==='users'? val => val : val => (val/1000).toFixed(0)//+'k'
            _data.options.tooltip.x.formatter = value==='users'? val => val + ' ..... ' + barTotal[val] + ' user' : val => val + ' ..... ' + numberWithCommas(barTotal[val].toFixed(0)) + ' '+currency

            //_data.options.annotations = addAnnotations(_data.series,  _data.options.xaxis.categories)
            _data.options = {..._data.options, ...{
                annotations: addAnnotations(_data.series,  _data.options.xaxis.categories)
            }}

            /*_data.options.chart.events = {
              legendClick: (chartContext, seriesIndex, config) => {

                //const update = chartContext.update
                //chartContext.update = () => {}

                const chart = ApexCharts.getChartByID('payments-chart')
                //_data.series.forEach((series) => chart.hideSeries(series.name));
                //_data.series.filter((series, i) => seriesIndex !== i).map((series) => {chart.hideSeries(series.name)});
                //_data.series.filter((series, i) => seriesIndex === i).map((series) => {chart.showSeries(series.name)});

                //_data.series = Object.keys(paymentsByChallenge).map((key, i) => {return {name: key, data: seriesIndex === i ? paymentsByChallenge[key].values : 0}})

                chart.updateSeries(
                  Object.keys(paymentsByChallenge).map((key, i) => {
                    return {
                      name: key, 
                      data: seriesIndex === i ? paymentsByChallenge[key].values : paymentsByChallenge[key].values.map(d => 0)
                    }
                  }), true)

                //setData(_data)
                //setTotal(0)

                //chartContext.update = update
                //chartContext.update()
              },
            }*/

            setData(_data)
            setTotal(total)
            setEGPTotal(egpTotal)
            setUSDTotal(usdTotal)
            if(!Object.keys(challengeList).length) setChallengeList(_challengeList)

            setPaymentsLoading(false)

            //for(var day in pointsByDay){

                //pointsByDay[day].users = Object.keys(pointsByDay[day].user).length
            //}

            //var days = _.orderBy(Object.values(pointsByDay), 'day', 'asc').map((d)=>{d.day = 'day '+d.day; return d})

            //setDays(days);
        })

        return [subscribePayments]
    }

    const getSeriesDataSumByCategoryIndex = (series, categoryIndex) => {
      return series.reduce((acc, cur) => acc + (cur.data[categoryIndex] || 0), 0);
    };
    const addAnnotations = (series, categories) => {

      try {
        var points = []
        categories.forEach((category, index) => {
          const seriesDataSum = getSeriesDataSumByCategoryIndex(series, index);

          if(seriesDataSum) points.push(
            {
              y: seriesDataSum,
              x: category,
              label: {
                text: value==='users'? seriesDataSum.toString() : (seriesDataSum/1000).toFixed(0)+'k',
                offsetY: 10,
                style:{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  colors: ["#304758"],
                  border: 'none',
                  background: 'none'
                }
              }
            }
          );
        });
        return {points: points}
      } catch (error) {
        console.log(`Add point annotation error: ${error.message}`);
      }
    };


   return (<>
        <Breadcrumb className="pb-3">
            <Breadcrumb.Item active>payments</Breadcrumb.Item>

                  {Object.keys(challengeList).length ? <Form.Control
                    required
                    as="select"
                    placeholder="Select Challenge"
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginLeft: "50px",
                      //width: "100%",
                      height: 'auto',
                      border: "1px solid #eee",
                      backgroundColor: "rgb(252, 252, 252)",
                      position: 'absolute',
                      top: '5px',
                      left: '100px',
                      padding: '5px',
                      fontSize: '14px',
                    }}
                    onChange={(e) => {
                      setSelectedChallenge(e.target.value);
                    }}
                  >
                        <option
                          value={'all'}
                          key={'all'}
                          //hidden={ignore[obj.key]}
                        >
                          -- all challenges --
                        </option>
                    {Object.keys(challengeList).map((key, i) => {
                      return (
                        <option
                          value={key}
                          key={key}
                          //hidden={ignore[obj.key]}
                        >
                          {challengeList[key]}
                        </option>
                      );
                    })}
                  </Form.Control> : null}

            {total>0 && <div className="no-users">
              <span style={{marginLeft: '30px', fontSize: '13px'}}> <span style={{opacity:.5}}>from egypt : </span>{numberWithCommas(+egpTotal.toFixed(0))} EGP</span> 
              <span style={{marginLeft: '30px', fontSize: '13px'}}> <span style={{opacity:.5}}>foreign payments : </span>{numberWithCommas(+usdTotal.toFixed(0))} USD</span> 
              <span style={{marginLeft: '30px', fontSize: '13px'}}> <span style={{opacity:.5}}>total : </span>{numberWithCommas(+total.toFixed(0))} {value==='users'? 'users' : currency=== 'USD'? 'USD' : 'EGP'}</span> 
            </div>}
        </Breadcrumb>
        <div className="pt-5 h-100 align-self-center">

            {paymentsLoading ? <div className="loader text-center" style={{padding: '10em'}}><Spinner animation="border" size={'sm'} role="status" /></div> : 
            <div id="chart" style={{height: '450px'}}>
                <Chart options={data.options} series={data.series} type="bar" height={'100%'}/>

            </div>}

            <div style={{position: 'relative', marginLeft: '0px', display: 'flex'}}>
                <div style={{display: 'inline-block', marginTop: '1em'}}>
                  <DateRangePicker ranges={[preSelectionRange]} onChange={(range) => {setPreSelectionRange(range.selection)}}/>
                </div>

                <div style={{position: 'relative', marginLeft: '0px', width: '100%'}}>
                    <Button style={{padding: '.5em', margin: '1em', marginLeft: '1em', marginRight: '1em', fontVariant: 'small-caps'}} 
                    variant="dark" onClick={(range) => {setSelectionRange(preSelectionRange)}}>Apply range</Button>

                    <ToggleButton style={{padding: '.5em', margin: '1em .25em', fontVariant: 'small-caps', opacity: period==='daily'? 1:.5}} 
                    type="checkbox" className="answered-toggle" checked={period==='daily'} onChange={e => { if(!e.target.checked) return; setPeriod('daily'); }}
                    variant="info">Daily</ToggleButton>

                    <ToggleButton style={{padding: '.5em', margin: '1em .25em', fontVariant: 'small-caps', opacity: period==='weekly'? 1:.5}} 
                    type="checkbox" className="answered-toggle" checked={period==='weekly'} onChange={e => { if(!e.target.checked) return; setPeriod('weekly'); }}
                    variant="info">Weekly</ToggleButton>

                    <ToggleButton style={{padding: '.5em', margin: '1em .25em', fontVariant: 'small-caps', opacity: period==='monthly'? 1:.5}} 
                    type="checkbox" className="answered-toggle" checked={period==='monthly'} onChange={e => { if(!e.target.checked) return; setPeriod('monthly'); }}
                    variant="info">Monthly</ToggleButton>

                    <ToggleButton style={{padding: '.5em', margin: '1em .25em', marginLeft: '1em', fontVariant: 'small-caps', opacity: value==='amounts'? 1:.5, color: '#ffffff'}} 
                    type="checkbox" className="answered-toggle" checked={value==='amounts'} onChange={e => { if(!e.target.checked) return; setValue('amounts'); }}
                    variant="warning">Amounts</ToggleButton>

                    <ToggleButton style={{padding: '.5em', margin: '1em .25em', fontVariant: 'small-caps', opacity: value==='users'? 1:.5, color: '#ffffff'}} 
                    type="checkbox" className="answered-toggle" checked={value==='users'} onChange={e => { if(!e.target.checked) return; setValue('users'); }}
                    variant="warning">Users</ToggleButton>

                    {value === 'users' && <ToggleButton style={{padding: '.5em', margin: '1em .25em', marginLeft: '1em', fontVariant: 'small-caps', opacity: users==='perChallenge'? 1:.5, color: '#ffffff'}} 
                    type="checkbox" className="answered-toggle" checked={users==='perChallenge'} onChange={e => { if(!e.target.checked) return; setUsers('perChallenge'); }}
                    variant="danger">All</ToggleButton>}

                    {value === 'users' && <ToggleButton style={{padding: '.5em', margin: '1em .25em', fontVariant: 'small-caps', opacity: users==='uniqueUsers'? 1:.5, color: '#ffffff'}} 
                    type="checkbox" className="answered-toggle" checked={users==='uniqueUsers'} onChange={e => { if(!e.target.checked) return; setUsers('uniqueUsers'); }}
                    variant="danger">Unique</ToggleButton>}

                    <ToggleButton style={{padding: '.5em', margin: '1em .25em', marginLeft: '1em', fontVariant: 'small-caps', opacity: currency==='EGP'? 1:.5, color: '#ffffff'}} 
                    type="checkbox" className="answered-toggle" checked={currency==='EGP'} onChange={e => { if(!e.target.checked) return; setCurrency('EGP'); }}
                    variant="primary">EGP</ToggleButton>

                    <ToggleButton style={{padding: '.5em', margin: '1em .25em', fontVariant: 'small-caps', opacity: currency==='USD'? 1:.5, color: '#ffffff'}} 
                    type="checkbox" className="answered-toggle" checked={currency==='USD'} onChange={e => { if(!e.target.checked) return; setCurrency('USD'); }}
                    variant="primary">USD</ToggleButton>

                    <ToggleButton style={{padding: '.5em', margin: '1em .25em', marginLeft: '1em', fontVariant: 'small-caps', opacity: breakdown? 1:.5, color: '#ffffff', background: '#9898dd'}} 
                    type="checkbox" className="answered-toggle" checked={breakdown} onChange={e => { if(!e.target.checked) return; setBreakdown(true); }}
                    variant="light">Stacked</ToggleButton>

                    <ToggleButton style={{padding: '.5em', margin: '1em .25em', fontVariant: 'small-caps', opacity: !breakdown? 1:.5, color: '#ffffff', background: '#9898dd'}} 
                    type="checkbox" className="answered-toggle" checked={!breakdown} onChange={e => { if(!e.target.checked) return; setBreakdown(false); }}
                    variant="light">Combined</ToggleButton>


                    <div className="donut" style={{float: 'right', bottom: 20, right: 10, display: 'inline-block', marginTop: '100px'}}>
                      <Chart options={nutritionists.options} series={nutritionists.series} type="donut" width="500" />
                    </div>
                </div>
            </div>
        </div>
    </>);
}