import React, { useEffect, useState } from "react";
import { scaleLinear } from "d3-scale";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
  Marker,
  Annotation
} from "react-simple-maps";

const geoUrl =
  'https://unpkg.com/world-atlas@2.0.2/countries-110m.json';

const colorScale = scaleLinear()
  .domain([0, 1])
  .range(["#ffedea", "#ff5233"]);

const MapChart = (props) => {

  return (
    <ComposableMap
      projectionConfig={{
        rotate: [-10, 0, 0],
        scale: 185
      }}
      style={{ height: '80%', width: '100%'}}
    >
      <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
      <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
      {Object.keys(props.data).length > 0 && (
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => {
              const centroid = geoCentroid(geo);
              
              return (<>
                <Geography
                  key={geo.rsmKey}
                  className={geo.properties.ISO_A2}
                  geography={geo}
                  fill={props.data[geo.properties.name] ? colorScale(props.data[geo.properties.name].count/props.total) : "#F5F4F6"}
                />
                {props.data[geo.properties.name] && <g key={geo.rsmKey + "-name"}>
                  <Marker coordinates={centroid}>
                    <text y="2" fontSize={10} textAnchor="middle" title="test">
                      {props.data[geo.properties.name].count}
                    </text>
                  </Marker>
                </g>}
              </>);
            })
          }
        </Geographies>
      )}
    </ComposableMap>
  );
};

export default MapChart;
